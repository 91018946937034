<div class="row no-gutters align-items-center card-rate" [class.waiting]="waiting">
  <div class="col-7">

    <div class="row no-gutters align-items-center" *ngFor="let rate of rates" >
      <div class="col-3">
        <div class="quantity">{{rate.value}}</div>
      </div>
      <div class="col-9">
        <div class="rate_type">
          <div class="type">
            <span>{{rate.translatePrefix+'.'+rate.label | translate }}<i *ngIf="rate.tooltip" container="body"  class="description-tooltip fas fa-question-circle" [ngbTooltip]="tooltipDescription"></i></span>

            <ng-template #tooltipDescription>
              <div [innerHtml]="(rate.tooltip | translate )"></div>
            </ng-template>
          </div>
          <button class="btn btn-link" (click)="openDialog(modalDetail,rate)">{{'commons.see_details' | translate }}</button>
        </div>

      </div>
    </div>


  </div>
  <div class="col-5">
    <div class="rate_icon">
      <div class="label">
        {{labelRate | translate }}
      </div>
      <div class="icon">
        <i class="far fa-tachometer"></i>
      </div>
      <div class="number">
        {{ratePercent}}
      </div>
      <div class="formula">
        ({{totalAnswered}}/{{totalSents}}) <i *ngIf="tooltipRate" container="body" placement="left" class="description-tooltip fas fa-question-circle" [ngbTooltip]="tooltipDescription"></i>
        <ng-template #tooltipDescription>
          <div [innerHtml]="(tooltipRate | translate:({totalAnswered:totalAnswered,totalSents:totalSents}) )"></div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template #modalDetail>
  <div class="modal-header">
    <h4 class="title" [innerHTML]="(modalTitle | translate)"></h4>
    <div class="close" (click)="closeDialog()">
      <i class="far fa-times"></i>
    </div>
  </div>

  <div class="modal-body">
    <ng-content></ng-content>
  </div>
</ng-template>


