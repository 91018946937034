<div class="row no-gutters ">
  <div class="content_options col-xl-auto {{viewType}} {{style}}" [class.responsive]="size==='responsive'">
    <mat-form-field class="mat-form-field" [ngClass]="{'line-off': viewType==='button' && !autocomplete, 'w-100': autocomplete}" [class.responsive]="size==='responsive'">
      <label *ngIf="label">
        {{prefixTranslate+label | translate }} 
        <i *ngIf="tooltip" class="fas fa-info-circle" [ngbTooltip]="tooltip"></i>
      </label>
      <ng-container *ngIf="!autocomplete">
        <mat-select class="mat-select {{viewType}}" [panelClass]="'bottom_button'" [value]="selected" [disabled]="disabled">
          <div class="label_action" *ngIf="labelAction">{{labelAction}}</div>
          <mat-option *ngFor="let item of options" [value]="item.value" (click)="changeMetric(item)">
            <mat-radio-button *ngIf="viewType==='button'"   [name]="item.value" [checked]="(selected===item.value)" ></mat-radio-button>
            {{item.name | translate}}
          </mat-option>
        </mat-select>
      </ng-container>
      <ng-container *ngIf="autocomplete">
        <input
          matInput
          class="form-control {{viewType}}"
          [matAutocomplete]="auto"
          [formControl]="autoControl"
          [disabled]="disabled"
          >
        <mat-autocomplete
          autoActiveFirstOption
          #auto="matAutocomplete"
          (optionSelected)="changeMetric($event.option.value)"
          [displayWith]="displayFn.bind(this)">
          <mat-option *ngFor="let item of filteredOptions | async" [value]="item.name">
            {{item.name | translate}}
          </mat-option>
        </mat-autocomplete>
      </ng-container>
    </mat-form-field>
    <i *ngIf="description && description !== ''" container="body"  class="description fas fa-question-circle" [ngbTooltip]="tooltipDescription"></i>
  </div>
</div>

<ng-template #tooltipDescription>
  <div [innerHtml]="description"></div>
</ng-template>

