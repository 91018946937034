import {Component, Input} from '@angular/core';
import {UtilService} from "../../../shared/util.service";
import {MentionsService} from './mentions.service';
import {AppComponent} from "../../../layouts/app/app-component";
import {DataMention,DataTheme,DataEntity,DataPhrase} from './data-mention';
import {plainToClass} from "class-transformer";
import {Subscription} from "rxjs";

@Component({
  selector: 'mh-semantic-mentions',
  templateUrl: './mentions.component.html',
  styleUrls: ['../../../../assets/scss/shared/semantic/mentions.scss'],
  providers:[MentionsService]
})

export class SemanticMentionsComponent extends AppComponent{
  @Input()
  startDate;
  @Input()
  endDate;
  @Input()
  productId;

  @Input()
  top:string="none";
  topQuantity=5;

  dataMentions:DataMention[] | any = [];
  subscription: Subscription;

  ngOnChanges() {
    this.loadData();
  }



  constructor(private utilService:UtilService, private mentionsService:MentionsService) {
    super();
    this.subscription = this.utilService.isCustomerChanged().subscribe(hotel => { this.loadData(); });
    this.dataMentions = [
    new DataMention("themes",[]),
      new DataMention("entities",[]),
      new DataMention("phrases",[])
    ];
  }

  loadData(){
    this.setWaiting(true);
    for(let mention of this.dataMentions){
      this.mentionsService.getSemantic(mention.type,{"startDate":this.startDate,"endDate":this.endDate}, this.utilService.currentHotel.id, this.productId).subscribe(response=>{
        if(mention.type==="themes")   mention.data   =  plainToClass(DataTheme,  this.filterData(response));
        if(mention.type==="entities") mention.data   =  plainToClass(DataEntity, this.filterData(response));
        if(mention.type==="phrases")  mention.data   =  plainToClass(DataPhrase, this.filterData(response));
        mention.waiting = false;
        this.setWaiting(false);
      })
    }
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  isTop(){
    return this.top !== "none";
  }

  filterData(response){
    if(this.isTop()){
      let data = response.filter(x=>x[this.top] > 0);
      data = this.utilService.sortArray(data, this.top, "DESC");
      return data.slice(0,this.topQuantity);
    }else{
      return response;
    }

  }
}


