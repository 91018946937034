import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { User } from '../_models/index';
import { environment } from 'src/environments/environment';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {

    params: HttpParams;

    constructor(private http: HttpClient) {
      this.params = new HttpParams();
    }

    getAll() {
        return this.http.get('/api/users', {params: this.params})
          .pipe(
              map(response=>response)
          );
    }

    getById(id: number) {
        return this.http.get(`/api/users/${id}`, {params: this.params})
          .pipe(
              map(response=>response)
          );
    }

    getByCustomerId(customer_id: string) {
      return this.http.get(`${environment.customerPath}users`, {params: <Params>{customer_id}})
    }

    create(user: User) {
        return this.http.post('/api/users', user)
          .pipe(
              map(response=>response)
          );
    }

    update(user: User) {
        return this.http.put(`/api/users/${user.id}`, user)
          .pipe(
              map(response=>response)
          );
    }

    delete(id: number) {
        return this.http.delete(`/api/users/${id}`, {params: this.params}).pipe(
            map(response=>response)
        );
    }

    getUsersForCases(customer_id: string): Observable<User[]> {
      let params: HttpParams = new HttpParams().set('customer_id', customer_id);
      return this.http.get<User[]>(`${environment.customerPath}/v3/users/forcases`, { params }).pipe(map((response) => response));
    }
}
