<div
  class="tw-flex tw-items-center tw-justify-center tw-py-6 tw-px-2 tw-w-full tw-rounded-xl tw-border tw-border-gray-400 {{ customClassContainer }}"
  [ngClass]="{'!tw-w-fit tw-justify-start': source === 'detail'}"
>
  <ng-container *ngIf="!isMobileOrTablet; else mobileTemplate">
    <ng-container *ngIf="!loading; else loaderTemplate">
      <div class="tw-flex tw-flex-col tw-px-4">
        <span class="tw-font-medium">{{
          (associatedGuest && !case.product_item ? 'commons.guest' : productName) | translate : { ota: item?.ota?.name }
        }}</span>
        <span class="tw-italic" *ngIf="type !== 'review'; else reviewTime">{{ fullName }}</span>
        <ng-template #reviewTime>
          <span class="tw-italic">{{
            item?.publication_date | calculateDays : language : 'sql' | translate | async
          }}</span>
        </ng-template>
      </div>
  
      <hr class="tw-border tw-border-solid tw-border-gray-300 tw-h-14" />
  
      <ng-container *ngIf="hasRoom || associatedGuest?.guest_room">
        <div class="tw-flex tw-flex-col tw-px-4">
          <span class="tw-font-medium">{{ 'core-lib.input.dialog_cases.room' | translate }}</span>
          <span class="tw-italic">{{ item?.guest?.room || associatedGuest?.guest_room }}</span>
        </div>
  
        <hr class="tw-border tw-border-solid tw-border-gray-300 tw-h-14" />
      </ng-container>
  
      <ng-container *ngIf="type !== 'review'">
        <div class="tw-flex tw-flex-col tw-px-4">
          <span class="tw-font-medium">{{
            'core-lib.input.dialog_cases.' + (type === 'order' || type === 'precheckin' || type === 'pre-checkin' ? 'checkin' : 'checkout')
              | translate
          }}</span>
          <span class="tw-italic" *ngIf="associatedGuest; else noExternalGuestTemplate">{{
            associatedGuest?.guest_checkout | calculateDays : language | async
          }}</span>
          <ng-template #noExternalGuestTemplate>
            <span class="tw-italic">
              {{
                item?.guest?.check_out_date || item?.guest?.check_in_date || item?.guest?.checkout || item?.checkin
                  | calculateDays : language : (source === 'detail' ? 'sql' : 'js')
                  | translate
                  | async
              }}
            </span>
          </ng-template>
        </div>
  
        <hr
          *ngIf="isPrecheckin && (!associatedGuest || case.product_item)"
          class="tw-border tw-border-solid tw-border-gray-300 tw-h-14"
        />
      </ng-container>
  
      <ng-container *ngIf="type === 'nps' || type === 'review' || type === 'survey-answer'">
        <div class="tw-px-4">
          <span *ngIf="type === 'nps'; else reviewRating" class="tw-font-normal tw-text-4xl">{{
            item?.satisfaction?.score || item?.answered_survey?.satisfaction?.score || '-'
          }}</span>
          <ng-template #reviewRating>
            <span class="tw-font-normal tw-text-4xl">{{ item?.ota_rating || '-' }}</span>
          </ng-template>
        </div>
  
        <hr class="tw-border tw-border-solid tw-border-gray-300 tw-h-14" />
      </ng-container>
  
      <ng-container *ngIf="type === 'order'">
        <div class="tw-flex tw-flex-col tw-px-4">
          <span class="tw-font-medium">{{ 'cases.create_modal.amount_order' | translate }}</span>
          <span class="tw-italic">${{ item?.order?.total_price || item?.totals[0]?.total_price }}</span>
        </div>
      </ng-container>
  
      <div class="tw-px-4" *ngIf="type === 'nps' || type === 'review'">
        <div
          class="tw-text-white tw-px-4 tw-py-1 tw-rounded-xl tw-text-xs tw-font-semibold"
          [ngClass]="{
            'tw-bg-red-500':
              item?.nps?.nps === 'detractor' || item?.nps === 'detractor' || item?.answered_survey?.nps === 'detractor',
            'tw-bg-passives':
              item?.nps?.nps === 'passive' || item?.nps === 'passive' || item?.answered_survey?.nps === 'passive',
            'tw-bg-promoters':
              item?.nps?.nps === 'promoter' || item?.nps === 'promoter' || item?.answered_survey?.nps === 'promoter'
          }"
        >
          {{ ('answer_survey.' + (item?.nps?.nps || item?.nps || item?.answered_survey?.nps) | translate) || '-' }}
        </div>
      </div>
  
      <ng-container *ngIf="source === 'detail' && (!associatedGuest || case.product_item)">
        <hr class="tw-border tw-border-solid tw-border-gray-300 tw-h-14" />
  
        <div class="tw-px-4">
          <span class="tw-text-sky-500 tw-font-medium tw-cursor-pointer tw-uppercase" (click)="goToProduct()">{{
            'cases.commons.see_product' | translate: { product: 'cases.commons.' + type | translate }
          }}</span>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #mobileTemplate>
    <div class="tw-flex tw-flex-col tw-px-4">
      <span class="tw-font-medium">{{
        (associatedGuest ? 'commons.guest' : productName) | translate : { ota: item?.ota?.name }
      }}</span>
      <span class="tw-italic" *ngIf="type !== 'review'; else reviewTime">{{ fullName }}</span>
      <ng-template #reviewTime>
        <span class="tw-italic">{{
          item?.publication_date | calculateDays : language : 'sql' | translate | async
        }}</span>
      </ng-template>
    </div>

    <hr class="tw-border tw-border-solid tw-border-gray-300 tw-h-14" />

    <ng-container *ngIf="hasRoom || associatedGuest?.guest_room">
      <div class="tw-flex tw-flex-col tw-px-4">
        <span class="tw-font-medium">{{ 'core-lib.input.dialog_cases.room' | translate }}</span>
        <span class="tw-italic">{{ item?.guest?.room || associatedGuest?.guest_room || '-' }}</span>
      </div>

      <hr *ngIf="!associatedGuest" class="tw-border tw-border-solid tw-border-gray-300 tw-h-14" />
    </ng-container>

    <ng-container *ngIf="source === 'detail' && (!associatedGuest || case.product_item)">
      <div class="tw-px-4">
        <span class="tw-text-sky-500 tw-font-medium tw-cursor-pointer tw-uppercase" (click)="goToProduct()">{{
          'cases.commons.see_product' | translate: { product: 'cases.commons.' + type | translate }
        }}</span>
      </div>
    </ng-container>
  </ng-template>
  
  
  <ng-template #loaderTemplate>
    <mh-core-lib-loader-wrapper class="tw-text-5xl" [type]="'spinner'"></mh-core-lib-loader-wrapper>
  </ng-template>
</div>