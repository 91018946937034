import { Pipe, PipeTransform } from '@angular/core'

import { SemanticComment } from 'app/shared/model/semantic-comment.model';
import { SemanticCommentFilter } from 'app/states/semantic/semantic.state'

@Pipe({ name: 'semanticProductsFilter' })
export class SemanticProductsFilterPipe implements PipeTransform {

  transform(value: SemanticComment[], filters: SemanticCommentFilter): SemanticComment[] {
    if(filters.products?.length > 0) {
      return value.filter(
        (comment) => filters.products.includes(comment.product_id)
      );
    } else
      return value
  }
}
