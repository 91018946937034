<div class="box_heavy">
  <div class="spinner">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>
  <div class="legend">
    {{'waiting.messages.still_process' | translate}}
  </div>
  <div class="legend" *ngFor="let message of messagesWaiting">{{'waiting.messages.'+message | translate}}</div>
</div>