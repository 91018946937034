import { Component, Input } from '@angular/core';

@Component({
  selector: 'mh-core-lib-generic-circular-chart',
  templateUrl: './generic-circular-chart.component.html',
  styleUrls: ['./generic-circular-chart.component.scss'],
})
export class CoreLibGenericCircularChartComponent {
  @Input() data: { quantity: number; rate: number };
}
