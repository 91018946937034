import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TrendLegendData,TrendLegendColors } from './trend-legend.model';

@Component({
  selector: 'widgets-trends-legend',
  templateUrl: './trends-legend.component.html',
  styleUrls: ['./trends-legend.component.scss']
})
export class TrendsLegendComponent implements OnInit {

  @Input()
  lineChartData: TrendLegendData[] = [];

  @Input()
  lineChartColors: TrendLegendColors[] = [];

  @Input()
  displayBtn: string = '';

  @Output()
  filterCustomerChange: EventEmitter<TrendLegendData[]> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }


  toggleLegend(data: TrendLegendData){
    data.hidden = !data.hidden
    this.emitChanges();
  }

  selectedAll(status){
    this.lineChartData.map(x=>x.hidden = !status);
    this.emitChanges()
  }

  emitChanges(){
    this.filterCustomerChange.emit(this.lineChartData);
  }

}
