import { Component, OnInit } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import * as moment from 'moment';
//internal
import { UtilService } from 'app/layouts/main';
import { HtmlHelper } from 'app/_helpers';
import { LogsService } from './services/logs.service'
import { UsersService } from './services/users.service'
import { getUserIds, getCustomerIds, transformLogs, transformUsers } from './utils'
import { CustomerService } from 'app/shared/customer.service';
//types
import { Log, User } from 'app/shared/logs/types'
import { Filterable, Filter } from 'app/material/widgets/filters/generic-filter/generic-filter.component';
import { Hotel } from 'app/shared/model';
//fixed values
import FILTERS from './utils/filters'
import { FormControl } from '@angular/forms';
import { DateService } from 'app/shared/services/date.service';

@Component({
  selector: 'mh-logs-timeline',
  templateUrl: './logs-timeline.component.html',
  styleUrls: ['./logs-timeline.component.scss'],
  providers: [LogsService, UsersService, CustomerService],
})
export class LogsTimelineComponent implements OnInit {
  filters: Filter[] = FILTERS;

  //filters
  dateRange: {startDate: Date, endDate: Date} | any = {
    startDate: (moment().subtract(30, 'days')).toDate(),
    endDate: moment().toDate(),
  }
  filterValues: Object = {};

  // ui vars
  loading: Boolean = true;
  error: Boolean = false;

  //logs
  allLogs: Log[] = [];
  logs: Log[] = [];

  //subscriptions
  customerSubscription: Subscription;
  showHotelChanges = new FormControl(true);
  hotelChangeSubs: Subscription;

  constructor(
    private _htmlHelper: HtmlHelper,
    private userService: UsersService,
    private logsService: LogsService,
    private utilService: UtilService,
    private customerService: CustomerService,
    private dateService: DateService
  ) { }

  ngOnInit() {
    this.customerSubscription = this.utilService.isCustomerChanged()
      .subscribe(() => this.onCustomerChange());
    this.hotelChangeSubs = this.showHotelChanges.valueChanges
      .subscribe(value => {
        this.logs = [];
        this.fetchLogs(!value);
      });
  }

  ngOnDestroy() {
    this.customerSubscription.unsubscribe();
    this.hotelChangeSubs.unsubscribe();
  }

  setDateFilter(dates?: {startDate: Date, endDate: Date}) {
    this.dateRange = dates

    this.fetchLogs();
  }

  fetchLogs(getCustomerData = false) {
    if ( this.currentDates.startDate && this.currentDates.endDate) {
      const requests = (getCustomerData) ?
        [this.logsService.getAllCustomersLogs(this.getCustomerParams()),
          this.logsService.getAllUserLogs(this.getUserParams())] :
            [this.logsService.getAllCustomersLogs(this.getCustomerParams())];

          forkJoin(requests)
          .subscribe(([rawLogsCustomer, rawLogsUser]: any[]) => {
            const rawLogs = [...(rawLogsCustomer || []), ...(rawLogsUser || [])]

            if(rawLogs.length === 0) {
              this.setValues()
            } else {
              forkJoin([
                this.userService.getByIds({users_ids: getUserIds(rawLogs)}),
                this.customerService.findByIds(getCustomerIds(rawLogs).join(','))
              ]).subscribe(([rawUsers, rawCustomers]: any[]) => {
                const users = transformUsers(rawUsers)
                const customers = rawCustomers.map(c => Hotel.createFromApi(c))
                const logs: Log[] = transformLogs(rawLogs, users, customers, this.utilService.currentUser.isSuperAdmin())
                this.logs = logs;
                this.setValues(logs, users)
              }, err => this.handleError(err))
            }
          }, err => this.handleError(err))
    }
    this.loading = true;
    this.error = false;
  }

  setValues(logs: Log[] = [], users: User[] = []) {
    this.loading = false;
    this.allLogs = logs.sort((log1, log2) =>
      log2.timestamp.getTime() - log1.timestamp.getTime()
    );
    const filter: any = this.filters.find((f: any) => f.name === 'user');
    filter.options = this.getUserOptions(users);
  }

  getUserOptions(users: User[]): Filterable[] {
    return users.map(user => <Filterable>({
      value: user.id,
      text: `${user.firstName} ${user.lastName}`
    }))
  }

  updateFitleredLogs() {
    this.logs = this.allLogs.filter((log: Log) => {
      return Object.keys(this.filterValues)
        .reduce((acumBool, key) => {
          const filteredField = typeof log[key] === 'string' ? log[key] : log[key].id

          return acumBool && this.filterValues[key].includes(filteredField)
        }, true)
    })
  }

  getCustomerParams() {
    return {
      customers_ids: [this.utilService.getCurrentHotel().id],
      ...this.getDateParams(),
    }
  }

  getUserParams() {
    return {
      users_ids: [this.utilService.getCurrentUser().id.toString()],
      ...this.getDateParams(),
    }
  }

  getDateParams() {
    return {
      start_date: this.currentDates.startDate,
      end_date: this.currentDates.endDate
    }
  }

  onFiltersChange(filteredValues: ({string: string[]})) {
    this.filterValues = Object.keys(filteredValues)
      .reduce((acum, key) => ({
        ...acum,
        ...(
          filteredValues[key].length > 0 ?
            {[key]: filteredValues[key]} :
            {}
        ),
      }), {})
    this.updateFitleredLogs();
  }

  onCustomerChange() {
    this.fetchLogs();
  }

  handleError(err: any) {
    this.loading = false
    this.error = true;
  }

  handleDatepickerUpdated($event) {
    this.dateService.setCurrentFilter($event.currentFilter);
    this.dateService.setCurrentDates($event.currentDates)
    this.fetchLogs();
  }

  get currentDates() {
    return this.dateService.currentDates;
  }

  get currentFilterDate() {
    return this.dateService.currentFilter;
  }

  get htmlHelper() { return this._htmlHelper };
  get language() { return this.utilService.currentLanguage; }
}

