import { AbstractControl, ValidatorFn } from '@angular/forms';

export function phoneValidator(phonePatterns: { [key: string]: RegExp }): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const parent = control.parent;
        if (!parent) return null;
    
        const countryCodeControl = parent.get('cellphoneCountryCode');
        const cellphoneValue = control.value;
        const countryCodeValue = countryCodeControl ? countryCodeControl.value?.trim() : '';
        if( !countryCodeValue ) return null;
        if( !cellphoneValue ) return { 'invalidPhoneNumber': true }; 
    
       
        const cleanedCountryCode = countryCodeValue?.replace(/^\+/, '');
    
        const pattern = phonePatterns[cleanedCountryCode];
        if( !pattern ) return { 'invalidCountryCode': true };
        
    
        if (pattern && cellphoneValue && !pattern.test(cellphoneValue)) {
          return { 'invalidPhoneNumber': true }; 
        }
    
        return null;
      };
  }