<section class="filters">
  <div class="row align-items-center">
    <div class="col-8">
      <div class="selection" *ngFor="let type of filtersTypes">
        <widget-list-options
          [labelAction]="('commons.select_one_variable' | translate )"
          [name]="'chain.excutive.'+type"
          [list]="filters[type]"
          [selected]="filterSelected[type]"
          [viewType]="'button'"
          [size]="'responsive'"
          [prefixTranslate]="type==='areas' ? type : 'inter-chain.executive.table'"
          (select)="setSelectlValue($event,type)">
        </widget-list-options>
      </div>
    </div>
    <div class="col-4 align-right">
      <widget-modal-faq
        *ngIf="productId===3"
        [faqs]="faqs"
        [prefixTranslate]="prefixTranslateFAQ"
        [showWeightedAverage]="showWeightedAverage"
      ></widget-modal-faq>
    </div>
  </div>


</section>

<div class="waiting">
  <mh-spinner-classic [hidden]="!waiting"></mh-spinner-classic>
</div>

<div class="tables-result" *ngIf="!waiting">
  <div *ngFor="let factor of ['brands','regions']" class="{{factor}}">
    <mh-box-element [title]="('inter-chain.executive.table.'+factor | translate)">
      <mh-executive-table
        [areaSelected]="filterSelected['areas']"
        [totals]="totals[factor]"
        [factor]="factor"
        [currentYear]="currentYear"
        [previousYear]="previousYear"
        [selected]="filterSelected['metrics']"
        [dataSource]="(factor === 'brands' ? dataSourceBrands : dataSourceRegions)"
      ></mh-executive-table>
    </mh-box-element>
  </div>
</div>

