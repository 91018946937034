import {Hotel, Flags} from './hotel';
import {plainToClass} from "class-transformer";
import {Access} from './access.model';

const DEFAULT_PHOTO_URL = 'https://statics.myhotel.io/uploads/fidelity-suite/users/default_user.png';

export class User{
  id:number;
  email:string='';
  name:string='';
  lastName:string='';
  userName:string='';
  currentClient:number;
  languageCode:number;
  language:string;
  companyPosition: string='';
  admin:boolean=false;
  superAdmin: boolean=false;
  profile:any={};
  customers:Hotel[]=[];
  flags:any[] = [];
  access:Access[] | any = [];
  fakeUser: User;
  superAdminProperties:SuperAdminProperties;
  role:string = "normal"
  send_mail_new_user:boolean=false;
  profilePhoto: string = DEFAULT_PHOTO_URL;
  languages = {
    1: 'es',
    2: 'en',
    7: 'pt'
  }

  static createFromApi(object:any):User {
    return (new User()).createFromApi(object);
  }

  constructor(){
    this.profile = {
      'company_position' : '',
      'phone' : ''
    }
  }

  createFromApi(object):User{
    let profile          = object["profile"]
    this.profile         = profile;
    this.admin           = object["admin"];
    this.superAdmin      = object["super_admin"] === undefined ? object["superAdmin"] : object["super_admin"];
    this.id              = object["id"];
    this.email           = profile["email"];
    this.name            = profile["name"];
    this.lastName        = profile["last_name"]  === undefined ? profile["lastName"] : profile["last_name"];
    this.userName        = object["user_name"]   === undefined ? object["userName"] : object["user_name"];
    this.currentClient   = object["currentClient"];
    this.languageCode    = profile["language"]["id"];
    this.language        = this.languages[this.languageCode];
    this.companyPosition = profile["company_position"];
    this.flags           = object["flags"]
    this.access          = object["access"]
    this.role            = object["role"];
    this.profilePhoto    = object.profile_photo || DEFAULT_PHOTO_URL;
    for(let customer of object["customers"]){
      let hotel = new Hotel();
      this.customers.push(hotel.createFromApi(customer));
    }

    return this;
  }

  displayName(){
    let name = this.name ? this.name.toLowerCase() : '';
    let lastName = this.lastName ? this.lastName.toLowerCase() : '';
    return name+' <span>'+lastName+'</span>';
  }

  isAdmin(){
    return this.admin
  }

  isSuperAdmin():boolean{
    return this.superAdmin
  }

  isBasicUser(){
    if(this.admin) return false;
    if(this.superAdmin) return false;
    return true;
  }

  isDemo(){
    return this.superAdminProperties && this.superAdminProperties.live_demo;
  }

  hasInterChain():boolean{
    let customer = (this.isSuperAdmin() && this.fakeUser) ? this.fakeUser.customers : this.customers;
    //TODO cambiar el true por el fake user
    return this.isSuperAdmin() ? true : (customer.length > 1);
  }

  getAccessHotels(): Hotel[]{
    return this.customers;
  }

  getAccess(id){
    let access:Flags = plainToClass<Flags, Object>(Flags, this.access.find(x=>x.id === id));
    return access;
  }

  getFlagsTo(hotelId){
    if(this.isSuperAdmin()) return this.access;
    let hotel = this.customers.find(x => x.id === hotelId);
    let accessIds = hotel?.accessIds;
    let access = accessIds?.map(x => this.getAccess(x));
    return access;
  }

  initialesName(): string{
    let name = this.name ? this.name[0] : '';
    let lastName = this.lastName ? this.lastName[0] : '';
    return name+'<span>'+lastName+'</span>';
  }



}

export class SuperAdminProperties{
  id_fake_customer:string;
  id_fake_user:number;
  id_user:number;
  live_demo:boolean = false;
  user_fake : User;
}
