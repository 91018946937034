<div *ngIf="showFilters">
  <div class="row align-items-center row-filters" [class.none]="lists.trend.length===0" *ngIf="!waiting">
    <div class="other-filters col-6" *ngIf="showOtherFilters">
      <ng-container *ngFor="let key of ['trend','areas']" >
        <div class="inline list-metric" *ngIf="lists[key].length>0"  >
          <widget-list-options
            [labelAction]="('commons.select_one_variable' | translate )"
            [name]="'wdiget.trends.'+key"
            [list]="lists[key]"
            [selected]="key === 'trend' ? trendSelected : areaSelected"
            [viewType]="'button'"
            [prefixTranslate]="(key==='segmentedBy' ? 'commons' : (key==='areas' ? 'areas' : prefixTranslateFilters))"
            (select)="setSelectlValue($event,key)">
          </widget-list-options>
        </div>
      </ng-container>
    </div>
    <div class="col-12 filter-customer" *ngIf="chain.length>2 || !showDefaultLegends">
      <widgets-trends-legend
        (filterCustomerChange)="filterCustomers($event)"
        [lineChartData]="lineChartData"
        [lineChartColors]="lineChartColors"
        [displayBtn]="(!showOtherFilters) ? 'show-left' : ''"
        ></widgets-trends-legend>
    </div>
  </div>
</div>

<div class="explain" *ngIf="trendSelected==='totalPrice'" >
  * {{'inter-chain.prestay.explain_usd' | translate }}
</div>
<div class="align-center">
  <mh-spinner-classic [hidden]="!waiting"></mh-spinner-classic>
</div>

<ng-container *ngIf="!waiting">
  <div class="content_graph" style="display: block;">
    <canvas baseChart  height="400"
      (chartClick)="chartClicked($event)"
      [datasets]="lineChartData"
      [labels]="lineChartLabels"
      [options]="lineChartOptions"
      [colors]="lineChartColors"
      [legend]="lineChartLegend"
      [chartType]="lineChartType"></canvas>
  </div>

  <div class="content-table">
    <widget-trends-table #tableTrend
      (detailSelected)="emitDetailSelected($event)"
      [formatScore] = "['note','ranking_areas'].includes(trendSelected)"
      [showDetail]="showDetail"
      [firstColumn]="firstColumn"
      [trendSelected]="trendSelected"
      [lineChartData]="lineChartData"
      [lineChartLabels]="lineChartLabels">
    <ng-content></ng-content>
    </widget-trends-table>
  </div>

</ng-container>
