import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {environment} from 'src/environments/environment';
import { DownloadPageService } from './download-page.service';
@Component({
  selector: 'mh-download-page',
  templateUrl: './download-page.component.html',
})
export class DownloadPageComponent implements OnInit {

  filename: string | null = null;
  urlToDownload: string;
  waiting = true;
  fileExist = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private downloadPageService: DownloadPageService,
  ) { }

  ngOnInit(): void {
    this.getFilename();
  }

  getFilename() {
    const subs = this.activatedRoute.params.subscribe((params: any) => {
      if(params && params?.filename) {
        this.filename = params.filename;
        this.checkFileExists();
        this.downloadFile();
      }
    });
    subs.unsubscribe();
  }

  downloadFile() {
    const url = `https://s3.sa-east-1.amazonaws.com/${this.urlBucket}/reports/output/xslx/${this.filename}`;
    this.urlToDownload = url;
  }

  checkFileExists() {
    const filePath = `reports/output/xslx/${this.filename}`;
    this.downloadPageService.checkFileExists(filePath).subscribe((result: { data: boolean }) => {
        this.fileExist = result?.data;
        this.waiting = false;
    });
  }

  goToMyHotel() {
    const hostUrl = window.location.origin;
    window.location.href = hostUrl;
  }

  get envName() {
    return environment.envName;
  }

  get urlBucket() {
    return (this.envName === 'production') ?
      'mh-statics' :
      'mh-statics-dev-new';
  }

}
