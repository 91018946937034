import {Component, Input} from '@angular/core';
import {UtilService} from "../../../shared/util.service";
import {CategoriesService} from './categories.service';
import {AppComponent} from "../../../layouts/app/app-component";
import {plainToClass} from "class-transformer";
import {Subscription} from "rxjs";
import {Hotel, HotelDto} from 'app/shared/model';

// TABLE IMPORTS
import {ViewChild} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HotelService } from 'app/shared/hotel/hotel.service';

@Component({
  selector: 'mh-semantic-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
  providers:[CategoriesService, HotelService],
})

export class SemanticCategoriesComponent extends AppComponent{


  @Input()
  productId;

  customer: Hotel;
  hotelDto: HotelDto;

  firstCall = true;
  surveySubscribe: Subscription;
  dateSubscribe: Subscription;
  modalSubscription: Subscription;
  customerSubscribe: Subscription;

  filtersAll:string[] = ["negative","positive","neutral"];
  headerTableAll: string[] = ["area","total"].concat(this.filtersAll);
  headerTable: string[]    = ["area","total"];
  dataTable: CellTable[]   = [];

  filters: Filter[] = [];
  allData;
  showFilter:boolean=false;
  dataSource: any = new MatTableDataSource([]);
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  surveyId:number;


  constructor(
    private utilService:UtilService,
    private categoriesService:CategoriesService,
    private hotelService: HotelService) {
    super();
    this.setFilters();

    this.modalSubscription = this.utilService.isCurrenModalChange().subscribe(state => {
      this.reopenModal(state);
    });

    this.dateSubscribe = this.utilService.isCurrentDateChange().subscribe(response=>{
      if(!this.firstCall) this.loadData();
    })

    this.surveySubscribe = this.utilService.isCurrentSurveyChanged().subscribe(surveId => {
      this.loadData();
    })

    this.customerSubscribe = this.utilService.isCustomerChanged().subscribe(response=>{
      if(!this.firstCall && parseInt(this.productId)===3) this.loadData();
    })

    this.customer = this.utilService.currentHotel;
    this.hotelService.getHotel(this.customer.id).subscribe((response: any) => {
      this.hotelDto = response;
    });

  }

  ngOnInit(): void {
    if(parseInt(this.productId)===3) this.loadData();
  }

  ngOnDestroy(){
    this.surveySubscribe.unsubscribe();
    this.dateSubscribe.unsubscribe();
    this.customerSubscribe.unsubscribe();
  }


  reset(){
    this.dataTable = []
  }

  setFilters(){
    this.filters = [];
    for(let option of this.filtersAll){
      let value = option !== 'neutral'
      this.filters.push(new Filter(option,value));
    }
  }

  loadData(){
    this.setWaiting(true);
    let language = this.utilService.getLanguageId();
    this.surveyId = parseInt(this.productId)!==3 ? this.utilService.getCurrentSurvey().ids : null;

    this.categoriesService.getAreas({"startDate":this.startDate,"endDate":this.endDate}, this.customer.id,this.productId,language,this.surveyId).subscribe(response=>{
      this.allData = response;
      this.createDataTable(true);
      this.setWaiting(false);
      this.firstCall = false;
    })

  }
  refreshTotalsFilter(){
    for(let filter of this.filters){
      let data     = this.dataTable.map(x=>x[filter.type]);
      filter.total = data.reduce(function(a, b) { return a + b.value; },0);
    }
  }
  createDataTable(refreshTotals){
    this.reset();
    let areas = plainToClass(Area, this.allData);
    for(let area of areas){
      let positive = new DataArea(area.positives, area.positives_percentage);
      let neutral  = new DataArea(area.neutrals, area.neutrals_percentage);
      let negative = new DataArea(area.negatives, area.negatives_percentage);
      this.dataTable.push(
        new CellTable(area, positive, neutral, negative)
      );
    }

    if(refreshTotals){
      this.refreshTotalsFilter();
    }

    this.headerTable = ["area","total"].concat(this.getActivesFilters());

    this.dataTable = this.dataTable.sort(function(a,b) {return (a['negative']['value'] < b['negative']['value']) ? 1 : ((b['negative']['value'] < a['negative']['value']) ? -1 : 0);} );
    this.dataSource = new MatTableDataSource(this.dataTable);
    this.dataSource.sortingDataAccessor = function (data, sortHeaderId) {
      let value = data[sortHeaderId];
      switch (sortHeaderId) {
        case "area":
          value = data[sortHeaderId].area;
          break;
        default:
          value = data[sortHeaderId] ? data[sortHeaderId].value : 0;
          break;
      }
      if (typeof value === 'string' && !value.trim()) {
        return value;
      }
      return isNaN(+value) ? value : +value;
    };
    this.dataSource.sort = this.sort;
  }

  getActivesFilters(){
    let filters = this.filters.filter(x=>x.state);
    return filters.map(x=>x.type);
  }

  commentOrReview(){
    return parseInt(this.productId)===3 ? 'review' : 'comment';
  }

  reopenModal($event){
  }

  setActives($event){
    this.createDataTable(false);
  }

  totalRow(element){
    let total = 0;
    let filters = this.getActivesFilters()
    for(let key of filters){
      total = total + element[key].value;
    }
    return total;
  }

  calculatePercent(element, key){
    return (element[key].value/this.totalRow(element))*100;
  }

  getTotalColumn(key){
    if(this.dataTable === undefined || this.dataTable.length === 0) return 0;
    return this.dataTable.map(x=>x[key]).reduce((acc, item) => acc + item.value, 0);
  }

  toggleFilters(){
    this.showFilter = !this.showFilter;
  }

  get startDate(){ return this.utilService.startDate}
  get endDate(){ return this.utilService.endDate}
  get language(){
    return this.utilService.getCurrentLanguage();
  }

}

export class Area{
  area:string;
  average:number;
  id_area:number;
  list_areas:any[];
  negatives:number;
  negatives_percentage:number;
  neutrals:number;
  neutrals_percentage:number;
  positives:number;
  positives_percentage:number;
  sentiment_score:number;
  total:number;
  translates:any[];
  nameArea(){
    return 'areas.'+this.id_area;
  }
  getTranslates(){
    return this.translates;
  }

}

export class CellTable{
  area:Area;
  positive:DataArea;
  neutral:DataArea;
  negative:DataArea;
  total:DataArea;

  constructor(area:Area, positive:DataArea,neutral:DataArea,negative:DataArea){
    this.area     = area;
    this.positive = positive;
    this.negative = negative;
    this.neutral  = neutral;
    this.total    = new DataArea((this.positive.value + this.neutral.value + this.negative.value),1);
  }


}

export class DataArea{
  value:number;
  percentage:number;
  constructor(value:number, percentage:number){
    this.value = value;
    this.percentage = percentage;
  }
}


export class Filter {
  type: string;
  state:boolean;
  total = 0;
  constructor(type,state){
    this.type = type;
    this.state = state;
  }
}
