import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { UtilService } from 'app/shared/util.service';

@Component({
  selector: 'mh-lang-selector',
  templateUrl: './lang-selector.component.html'
})
export class LangSelectorComponent implements OnInit {

  @Input() type: 'button' | 'input' = 'button';
  
  isOpen = false;
  availableLanguages = [];

  #_selectedLang = 'es';
  @Input() set selectedLang(value: string) {
    this.#_selectedLang = value;
    this.utilService.setCurrrentLanguage(this.#_selectedLang);
  };

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  constructor(
    private utilService: UtilService,
    private eRef: ElementRef,
  ) { }

  ngOnInit(): void {
    this.availableLanguages = this.utilService.translateLanguages;
  }
  
  trackByLanguage(index, item) {
    return item.id;
  }

  selectLang(lang) {
    this.selectedLang = lang;
    this.utilService.setCurrrentLanguage(lang);
    this.utilService.ga('language','click', lang);
    this.isOpen = !this.isOpen;
  }

  get selectedLang() {
    return this.#_selectedLang;
  }
}
