<ng-container *ngIf="viewType==='normal'" >
  <section id="filter_chain" class="title_filter" [class.show]="showFilter">
    <h1 class="title" (click)="toggleFilters()">
      <mat-icon class="inline">filter_list</mat-icon>
      <span>{{'shared.filters.chain.title' | translate }}</span>
    </h1>
      <div class="content_filters">
      <div class="row no-gutters">
        <div class="col-lg-3 col-md-4" *ngFor="let active of customerActives">
          <div class="content_hotel">
            <mat-checkbox (change)="setActives($event)" [(ngModel)]="active.value">
              <div class="name_customer">
                {{active.customer.name}}
              </div>
            </mat-checkbox>
          </div>
        </div>

        <div class="col-lg-3 col-md-4">
          <div class="content_hotel ellipsis">
            <mat-checkbox (change)="showAll($event)"[(ngModel)]="all">
              {{'commons.all' | translate }}
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>

<ng-container *ngIf="viewType==='button'" >
  <div #dropDown ngbDropdown class="d-inline-block" (clickOutside)="closeDropdown()">
    <button class="btn btn-icon-left btn-filter" id="listCustomers" ngbDropdownToggle container="body">
      <mat-icon class="inline">filter_list</mat-icon>
      <span>{{'shared.filters.chain.select' | translate }}</span>
    </button>
    <div ngbDropdownMenu aria-labelledby="listCustomers">
      <div class="legend">
        {{'shared.filters.chain.select_one_or_more' | translate }}
      </div>
      <div ngbDropdownItem *ngFor="let active of customerActives">
        <div class="content_hotel">
          <mat-checkbox (change)="setActives($event)" [(ngModel)]="active.value">
            <div class="name_customer">
              {{active.customer.name}}
            </div>
          </mat-checkbox>
        </div>
      </div>
      <div class="close-dropdown">
        <button class="btn btn-icon-left" (click)="closeDropdown()">
          <i class="fal fa-times"></i>
          <span>{{ 'commons.close' | translate }}</span>
        </button>
      </div>

    </div>


  </div>

</ng-container>

  
  
  