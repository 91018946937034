<section class="tw-relative tw-cursor-pointer" id="{{inputId}}" (click)="toggleBox()" [formGroup]="formGroup">
  <input class="tw-w-full tw-rounded-xl focus-visible:tw-border-border-main focus-visible:tw-outline-0 tw-py-2 tw-px-5 tw-border-border-light-gray tw-text-main-text focus-visible:tw-border-2 tw-border disabled:tw-bg-white tw-cursor-pointer {{customClasses}}" [placeholder]="placeholder" [value]="displayValue" [ngClass]="{'!tw-bg-second-disabled': disabled}" readonly />
  <input [formControlName]="keyControl" hidden/>
  <div class="tw-absolute tw-flex tw-justify-center tw-right-[10px] tw-inset-y-0 tw-w-[20px] tw-h-[20px] tw-m-auto tw-text-main" [@rotate]="openBox ? '180' : 0">
    <i class="{{ iconClass }}"></i>
  </div>
</section>

<mh-core-lib-generic-box id="optionsMenu" customClass='tw-mt-2 !tw-py-2' *ngIf="openBox">
  <section class="tw-py-2 tw-max-h-[160px] tw-overflow-auto">
    <ng-container *ngFor="let option of options">
      <div class="tw-py-1.5 tw-text-main-text tw-cursor-pointer hover:tw-text-main-orange tw-transition-all select-option" (click)="selectOption(option)">
        {{displayFn(option) | titlecase}}
      </div>
    </ng-container>
  </section>
</mh-core-lib-generic-box>
