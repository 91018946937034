import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class SummaryCollectService {

  constructor(private http:HttpClient) { }

  public getSummaryCollect(dates:any,customerIds:string,ota_id){
    const url=`${environment.interChaiCoverageCollect}`;
    const params:HttpParams= new HttpParams()
      .set("start_date",dates.startDate)
      .set("end_date",dates.endDate)
      .set("customer_ids",customerIds)
      .set("ota_id",ota_id);

    return this.http.get(url, {params: params})
      .pipe(
        map(response=>response)
      )
  }
  
  getEmailsSents(customerIds: string, dates: any) {
    const url=`${environment.corporativeChaiCollect}/sent/emails`;
    const params = new HttpParams()
      .set('start_date', dates.startDate)
      .set("end_date",dates.endDate)
      .set("customer_ids",customerIds)

    return this.http.get(url, {params});
  }

  getSummaryCollectByOta(customerIds: string, dates: any) {
    const url=`${environment.corporativeChaiCollect}/otas/summary`;
    const params = new HttpParams()
      .set('start_date', dates.startDate)
      .set("end_date",dates.endDate)
      .set("customer_ids",customerIds)

    return this.http.get(url, {params});
  }
}
