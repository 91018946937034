<div *ngIf="message"
  class="alert alert-{{error ? 'danger' : 'success'}} alert-dismissible fade show"
  role="alert"
>
  <span [innerHTML]="(message | translate)"></span>
  <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="message = null">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<ng-container *ngIf="case">
  <div class="row main-content">
    <div class="col-12 col-sm-9">
      <mh-case-header-info
        [case]="case"
        [language]="language"
        [backgroundColor]="'gray'"
      ></mh-case-header-info>
      <ng-container *ngIf="!editing; else caseFormTemplate">
        <div class="row">
          <div class="col">
            <h3>{{case.title}}</h3>
          </div>
          <div *ngIf="canEdit" class="text-right col-auto">
            <button class="btn btn-white" (click)="handleEdit()">{{'commons.edit' | translate}}</button>
          </div>
        </div>
        <div>
          <mh-user-mention [text]="case.description" [users]="users"></mh-user-mention>
        </div>
      </ng-container>
      <div>
        <h4>{{('cases.commons.info_guest' | translate: {guest: (titleTranslate | translate)})}}</h4>
        <mh-case-product [productItem]="case.product_item"></mh-case-product>
      </div>
      <div class="activities">
        <mh-case-activity
          [caseId]="case.id"
          [users]="users"
          [lastChange]="lastChange"
          (commentAdded)="fetchSubscribers()"
        ></mh-case-activity>
      </div>
    </div>
    <div class="col-sm-3 side-info">
      <section>
        <div class="created-by">
          {{ 'cases.commons.created_by' | translate }}
          <strong>{{case.author?.first_name}}&nbsp;{{case.author?.last_name}}</strong>
        </div>
        <div>
          <div>{{ 'cases.commons.assigned_to' | translate }}</div>
          <ng-select
            class="custom"
            bindLabel="displayName"
            bindValue="id"
            [items]="usersList"
            [formControl]="assignedControl"
            [searchFn]="customSearchFn"
            [clearable]="isSelectClearable"
            (clear)="assignedControl.setValue(currentAssignedId)">
            <ng-template ng-option-tmp let-item="item">
              <b>{{ item.first_name }} {{ item.last_name }}</b> ({{currentUser.id === item.id ?
              ('cases.commons.current_user' | translate) :
              item.company_position
              }})<br/>
              <small>{{ item.email }}</small>
            </ng-template>
          </ng-select>
        </div>
        <div>
          <div>{{'cases.commons.status' | translate}}</div>
          <mat-select
            [value]="case.state"
            [compareWith]="areEqual"
            [disabled]="!canChangeState"
            (selectionChange)="handleStateChange($event)"
            class="gray-bg"
            >
            <mat-option *ngFor="let option of stateOptions" [value]="option">
              <span mhFindTranslate
                    [language]="language"
                    [translates]="option.translates"
                    [keyTranlsate]="'name'"
                    [capitalize]="true"
                    ></span>
            </mat-option>
          </mat-select>
        </div>
        <div>
          <div>{{'cases.commons.case_types' | translate}}</div>
          <mat-select
            [value]="case.case_type || ''"
            [compareWith]="areEqual"
            (selectionChange)="handleCaseTypeChange($event)"
            class="gray-bg"
            >
            <mat-option [value]="''"> - </mat-option>
            <mat-option *ngFor="let option of caseTypesOptions" [value]="option">
              <span mhFindTranslate
                    [language]="language"
                    [translates]="option.translates"
                    [keyTranlsate]="'name'"
                    [capitalize]="true"
                    ></span>
            </mat-option>
          </mat-select>
        </div>

        <div>
          <div>{{'cases.commons.priority' | translate}}</div>
          <mat-select
            [value]="case.priority || ''"
            [compareWith]="areEqual"
            [disabled]="!canEdit"
            (selectionChange)="handlePriorityChange($event)"
            class="gray-bg"
            >
            <mat-option [value]="''"> - </mat-option>
            <mat-option *ngFor="let option of priorityOptions" [value]="option">
              <div mhFindTranslate
                   [language]="language"
                   [translates]="option.translates"
                   [keyTranlsate]="'name'"
                   [capitalize]="true"
                   ></div>
            </mat-option>
          </mat-select>
        </div>
        <div>
          <mat-form-field class="areas-chip-list">
            <mat-label>{{'areas.title' | translate}}</mat-label>
            <mat-chip-list #chipList aria-label="Area selection">
              <mat-chip
                *ngFor="let area of areasChips"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove(area)">
                <ng-container *ngIf="language === 'es'">
                  {{area.clasification_text}}
                </ng-container>
                <ng-container *ngIf="language === 'en'">
                  {{area.clasification_text_en}}
                </ng-container>
                <ng-container *ngIf="language === 'pt'">
                  {{area.clasification_text_pt}}
                </ng-container>
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input
                #areaInput
                [formControl]="areaControl"
                [matAutocomplete]="autoArea"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
            <mat-autocomplete #autoArea="matAutocomplete" (optionSelected)="selected($event)">
              <mat-option *ngFor="let area of filteredAreas | async" [value]="area">
                <ng-container *ngIf="language === 'es'">
                  {{area.clasification_text}}
                </ng-container>
                <ng-container *ngIf="language === 'en'">
                  {{area.clasification_text_en}}
                </ng-container>
                <ng-container *ngIf="language === 'pt'">
                  {{area.clasification_text_pt}}
                </ng-container>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div>
          <div>{{'cases.commons.subscribed' | translate}}</div>
          <div class="subscribed-div gray-bg">
            <div *ngFor="let subscriber of subscribers">
              {{subscriber.first_name}} {{subscriber.last_name}}
            </div>
          </div>
        </div>
        <ng-container *ngIf="!isCurrentUserInvolved">
          <ng-container *ngIf="isCurrentUserSubscribed; else subscribe">
            <button class="mr-3 btn btn-white"
                    [disabled]="subscribeLoading"
                    (click)="handleUnsubscribe()"
                    >
                    <ng-container *ngIf="!subscribeLoading else loadingTemplate">
                      {{'cases.commons.unsubscribe' | translate}}
                    </ng-container>
            </button>
          </ng-container>

          <ng-template #subscribe>
            <button class="mr-3 btn btn-white"
                    [disabled]="subscribeLoading"
                    (click)="handleSubscribe()"
                    >
                    <ng-container *ngIf="!subscribeLoading else loadingTemplate">
                      {{'cases.commons.subscribe' | translate}}
                    </ng-container>
            </button>
          </ng-template>
        </ng-container>
        <button class="btn btn-white"
                [disabled]="subscribeLoading"
                (click)="subscribeUserOpenModal()">
          <ng-container *ngIf="!subscribeLoading else loadingTemplate">
            {{'cases.commons.subscribe_users' | translate}}
          </ng-container>
        </button>
      </section>
    </div>
  </div>
</ng-container>

<div *ngIf="case == null">{{'waiting.messages.wait_please' | translate}}</div>


<ng-template #caseFormTemplate>
  <form *ngIf="canEdit" [formGroup]="caseForm" class="case-form">
    <div>
      <mat-form-field appearance="outline">
        <mat-label>{{'cases.commons.title' | translate}}</mat-label>
        <input formControlName="title" matInput>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline">
        <mat-label>{{'cases.commons.description' | translate}}</mat-label>
        <textarea formControlName="description" matInput></textarea>
      </mat-form-field>
    </div>
    <div class="text-center col-auto">
      <button class="btn btn-white" (click)="handleEditCancel()">{{'forms.cancel' | translate}}</button>
      <button class="btn btn-white" (click)="handleEditSave()">{{'forms.accept' | translate}}</button>
    </div>
  </form>
</ng-template>


<ng-template #loadingTemplate>
  <mh-spinner-classic></mh-spinner-classic>
</ng-template>
