import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogRef } from 'app/core-lib/dialogs/modal/dialog-ref';
import { DIALOG_DATA } from 'app/core-lib/dialogs/modal/dialog-tokens';
import { CoreLibModalComponent } from 'app/core-lib/dialogs/modal/modal.component';
import { UsersManagmentService } from 'app/core-lib/services/users-managment/users-managment.service';
import { strengthValidator } from 'app/shared/validators/strength-password.validator';

@Component({
  selector: 'mh-restore-access',
  templateUrl: './restore-access.component.html',
  styleUrls: ['./restore-access.component.scss']
})
export class RestoreAccessComponent extends CoreLibModalComponent implements OnInit {
  #_state = 0;
  showPassword = false;
  showRepeatPassword = false;
  newPassword;
  title;
  message;
  passwordMask;
  possibleErrors = ['hasUpperCase', 'hasLowerCase', 'hasNumber', 'hasSpecialCharacters', 'hasValidLength', 'exception'];

  toggle = [false];
  toggleColors = ['tw-bg-main'];

  changePassForm = this.fb.group({
    password: ['', [Validators.required, strengthValidator()]],
    repeatPassword: ['', [Validators.required]],
  });

  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private usersManagmentService: UsersManagmentService,
    private translateService: TranslateService
  ) {
    super(dialogRef, data);
  }

  ngOnInit(): void {
    this.title = this.translateService.instant('user_management.actions.restore_access.change_password');
    if (this.data?.state) {
      this.selectAction(this.data?.state);
    }
  }

  selectAction(state) {
    this.#_state = state;
  }

  get state() {
    return this.#_state;
  }

  changePasswordFn() {
    if (!this.changePassForm.valid || this.passwordControl?.value !== this.repeatPasswordControl?.value) {
      return;
    }

    if (this.passwordControl?.value === this.repeatPasswordControl?.value) {
      this.usersManagmentService.changePassword(this.data.userIds, this.passwordControl.value).subscribe((value) => {
        this.title = this.translateService.instant('user_management.actions.restore_access.new_password');
        this.message = this.translateService.instant('user_management.actions.restore_access.new_password_message', {username: `${this.data?.user?.first_name} ${this.data?.user?.last_name}`, newPassword: this.passwordControl?.value});
        this.newPassword = this.passwordControl?.value;
        this.passwordMask = '*'.repeat(this.passwordControl?.value?.length);
        this.#_state = 3;
        if (this.toggle[0] && this.data?.user?.id) {
          this.usersManagmentService.invalidatePassword(this.data?.user?.id).subscribe();
        }
      });
    }
  }

  restoreAccessFn() {
    this.usersManagmentService[this.data?.action](this.data?.userIds || '').subscribe(() => {
      this.title = this.translateService.instant('user_management.actions.restore_access.accesses_sent');
      this.message = this.translateService.instant('user_management.actions.restore_access.accesses_sent_message', {email: this.data?.user?.email});
      this.#_state = 3;
    });
  }

  get passwordControl() {
    return this.changePassForm.get('password');
  }

  get repeatPasswordControl() {
    return this.changePassForm.get('repeatPassword');
  }
}
