import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { UtilService} from "../util.service";
import { NavbarNode } from '../model/index';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NavbarService } from 'app/_services/navbar.service';

@Component({
  selector: 'mh-navbar-item',
  templateUrl: './navbar-item.component.html',
  styleUrls: ['./navbar-item.component.scss'],
  providers: []
})
export class NavbarItemComponent implements OnInit, OnDestroy {
  @Input()
  item:NavbarNode;

  @Input()
  statusNavbar: number;

  @Output()
  isInterchainClicked: EventEmitter<any> = new EventEmitter<any>();

  sideBarCollapsed = false;
  toggleNavbarSubs: Subscription;

  constructor(private utilService: UtilService, public router: Router, public navbarService: NavbarService) {}

  ngOnInit(): void {
    this.toggleNavbarSubs = this.utilService.isToggleNavbar().subscribe((value) => {
      const msTime = value ? 0 : 400;
      setTimeout(() => {
        this.sideBarCollapsed = value
      }, msTime);
    });
  }

  changeRoute(){
    this.utilService.setGeneralFade(true)
    this.utilService.topScrollMainContent();
    this.utilService.toggleNavbar(false);
  }

  handleInterchainClicked() {
    const interchainStatus = 3;
    this.isInterchainClicked.emit(interchainStatus);
  }

  get hasChildren() { return this.item.children.length>0}

  get type(){
    if (this.utilService.customerIsRetail) return 'retail';
    if (this.utilService.customerIsClinic) return 'clinic';
    if (this.utilService.customerIsAirline) return 'airlines';
    if (this.utilService.customerIsRestaurant) return 'restaurant';
    return 'default'
  }
  get isSuperAdmin() { return this.utilService.currentUser.isSuperAdmin(); }

  ngOnDestroy(): void {
    this.toggleNavbarSubs.unsubscribe();
  }
}
