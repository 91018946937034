import { Component, Input, Output, OnInit, EventEmitter, ViewChild, HostListener, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { animation } from 'app/shared/utils/animation-custom';
import { Subscription, of } from 'rxjs';
import { startWith, map, debounceTime, filter } from 'rxjs/operators';

@Component({
  selector: 'mh-core-lib-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.scss'],
  animations: [ animation.rotate ]
})
export class CoreLibAutocompleteInputComponent implements OnInit {

  autocompleteControl = new FormControl('');

  filteredOptions;
  #options;
  subs: Subscription = new Subscription();

  @Input()
  hasFirstTimeOptions = true;

  @Input()
  showIcon = true;

  @Input()
  showOptions = true;

  @Input()
  openBox = false;

  @Input()
  filterFn;

  @Input()
  displayOptionsFn;

  @Input()
  displayOptionsEmailFn = null;

  @Input()
  showGlassIcon = false;

  @Input()
  iconClass = 'far fa-angle-up';

  @Input()
  customClassInput = '';

  @Input()
  customClassBox = '';

  @Input()
  customClassOptions = '';

  @Input()
  placeholder = '';

  @Input()
  selectedOption = null;

  @Input()
  disabled = false;

  @Output()
  optionSelected = new EventEmitter();

  @Output()
  writtenText = new EventEmitter();

  @ViewChild('autocompleteInput') input;

  @Input() set clearControl(value: string) {
    if (value) {
      this.autocompleteControl.reset();
    }
  }

  @Input() set options(values) {
    this.#options = values;
    if (values) {
      this.filteredOptions = of(this.#options);
      this.listenerControl();
      return;
    }
    if (!this.hasFirstTimeOptions && this.#options.length > 0) {
      this.filteredOptions = of(this.#options);
      this.listenerEmptyControl();
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event){
    const result = this.checkClickInside(event.target);
    if ( !result ) {
      this.openBox = false;
    }
  }

  constructor() { }

  ngOnInit(): void {
    if (this.disabled) {
      this.autocompleteControl.disable();
    }
    this.setFocusOnInput();
    if ( this.selectedOption ) {
      this.selectOption(this.selectedOption, false);
    }
    this.autocompleteControl.valueChanges.subscribe((value) => {
      this.writtenText.emit(value);
    });
  }

  ngOnChanges({ selectedOption, clearControl }: SimpleChanges) {
    if (selectedOption) {
      this.selectOption(selectedOption.currentValue, false);
    }
  }

  setFocusOnInput() {
    if ( this.openBox ) {
      setTimeout(_ => {
        this.input.nativeElement.focus();
      }, 0)
    }
  }

  listenerEmptyControl() {
    this.subs.add(
      this.autocompleteControl.valueChanges
        .pipe(
          debounceTime(700),
          filter((text) => text.length > 3),
        )
        .subscribe((text) => {
          this.writtenText.emit(text);
        }),
    );
  }

  listenerControl() {
    this.filteredOptions = this.autocompleteControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filter(value || '')),
    );
  }

  filter(value) {
    return this.filterFn(this.#options, value);
  }

  selectOption(option, emit = true) {
    this.autocompleteControl.setValue(this.displayOptionsFn(option));
    this.openBox = false;
    if ( emit ) {
      this.optionSelected.emit(option);
    }
  }

  toggleBox() {
    if (!this.disabled) {
      this.openBox = !this.openBox
    }
  }

  handleFocus() {
    if (!this.disabled) {
      this.openBox = true;
    }
  }

  checkClickInside(target) {
    const idsToCheck = [
      'menuAutocomplete',
      'autocompleteInput'
    ];
    const classesToCheck = [
      'autocomplete-option',
      'autocomplete-container-input'
    ]
    if ( idsToCheck.includes(target.id) || classesToCheck.some(clas => target.className?.includes(clas))) {
      return true;
    } else if ( target.parentNode ) {
      return this.checkClickInside(target.parentNode)
    } else {
      return false;
    }
  }

  get displayOptions() {
    return this.#options;
  }

}
