import { Component, Input } from '@angular/core';
import { PeriodItemDetails } from 'app/shared/model';
import { UtilService } from 'app/shared/util.service';
const VAR_IN_PERCENT = ['coverage'];

@Component({
  selector: 'mh-table-period',
  templateUrl: './table-period.component.html',
  styleUrls: ['../../../../../../assets/scss/shared/elements/table-period.scss'],
})

export class TablePeriodComponent{

  @Input()
  type:string;

  @Input()
  element:PeriodItemDetails;

  constructor(private utilService:UtilService){}

  getValue(period){
    let value = this.element[this.type].details[0][period].value;
    if(VAR_IN_PERCENT.includes(this.type)) return this.utilService.formatPercent(value,true,false,true);
    return value;
  }

  actualValue(){
    return this.getValue('actual')
  }
  previousValue(){
    return this.getValue('previous')
  }

  variationValue(){
    return this.element[this.type].details[0].variation.value;
  }

  variationClass(){
    let actualValue = this.actualValue();
    let previousValue = this.previousValue()
    if( actualValue===previousValue ||
        actualValue===undefined ||
        previousValue===undefined ||
        actualValue === 0 ||
        previousValue === 0) return 'no_change';
    let response = ((actualValue > previousValue)) ? 'up' : 'down';
    return response;
  }
}
