<div class="datepicker" *ngIf="showCalendar">
  <ngb-datepicker  #dp ngModel
    (select)="onDateSelection($event)"
    [displayMonths]="displayMonths"
    [dayTemplate]="t"
    [startDate]="fromDate"
    [maxDate]="maxDate"
    [minDate]="minDate"
    >
  </ngb-datepicker>

  <ng-template #t let-date="date" let-focused="focused">
    <span class="custom-day"
          [class.focused]="focused"
          [class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
          [class.faded]="isHovered(date) || isInside(date)"
          (mouseenter)="type=='range' ? hoveredDate = date : ''"
          (mouseleave)="hoveredDate = null"
          (click)="onDateChange(date)">
      {{ date.day }}
    </span>
  </ng-template>
</div>
