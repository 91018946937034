<div class="semantic_panel">
  <mat-accordion>
    <mat-expansion-panel (opened)="getDetail($event)" [disabled]="!showDetail">
      <mat-expansion-panel-header>
        <mat-panel-description>
          <div class="title_box">
            <i container="body" [ngbTooltip]="tooltipNegative" class="far fa-frown"></i>
            <span [innerHtml]="('online.reviews.qualify_semantic' | translate:({qualify: ('shared.semantic.type_comment.has_negative' | translate ) }) )"></span>
            <span class="see_detail" [hidden]="!hasAreas()" *ngIf="showDetail">({{'commons.see_detail' | translate }})</span>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="row no-gutters" *ngIf="!waitDetail">
        <div class="box_detail_semantic col-12" *ngFor="let semantic of semanticAreas">
          <h4 class="title">{{'areas.'+semantic.id_area | translate }}</h4>
          <div class="row no-gutters">
            <div class="col-sm-12 col-md-6 col-lg-6 side_detail">
              <div class="legend_box">{{'shared.semantic.categories.distribution' | translate }}</div>
              <mh-semantic-bar
                [positivePercentage]="getDetailMention(semantic).positivePercentage"
                [neutralPercentage]="getDetailMention(semantic).neutralPercentage"
                [negativePercentage]="getDetailMention(semantic).negativePercentage"
                [positiveLabel]="getDetailMention(semantic).positiveLabel"
                [neutralLabel]="getDetailMention(semantic).neutralLabel"
                [negativeLabel]="getDetailMention(semantic).negativeLabel"
                ></mh-semantic-bar>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 side_detail">
              <div class="legend_box">{{'commons.themes' | translate }}</div>
              <ul>
                <li *ngFor="let category of semantic.categories">
                  <span>{{'lexalytics.'+category.category | translate }}</span>
                  <i class="{{getIcon(category.sentiment_polarity)}}"></i>
                </li>
              </ul>
            </div>
          </div>

        </div>

      </div>

    </mat-expansion-panel>
  </mat-accordion>

  <ng-template #tooltipNegative>
    <div [innerHtml]="('shared.semantic.sentiment.negative' | translate)"></div>
  </ng-template>
</div>
