import { Pipe, PipeTransform } from '@angular/core'

import { SemanticComment } from 'app/shared/model/semantic-comment.model';
import { SemanticCommentFilter } from 'app/states/semantic/semantic.state'

@Pipe({ name: 'semanticAreaFilter' })
export class SemanticAreaFilterPipe implements PipeTransform {

  transform(value: SemanticComment[], filters: SemanticCommentFilter): SemanticComment[] {
    if(filters.areas?.length > 0) {
      return value.filter((comment) =>
        comment.themes.some(themeInput =>
          themeInput.sub_types.some(areaInput =>
            filters.areas.some(area =>
              area.name.toLowerCase() === areaInput.name.toLowerCase()
            )
          )
        )
      );
    } else
      return value
  }
}
