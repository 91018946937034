import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fidelityDeskAuthor'
})
export class FidelityDeskAuthorPipe implements PipeTransform {

  transform(cases, filters) {
    let filteredCases = cases;
    if(filters) {
      const filter = filters.find(filter => filter.name === 'author');
      const filtering = filter.options.some(option => option.isFiltering);
      if(filter && filtering) {
        const options = filter.options.filter(option => option.isFiltering).map(option => option.value);
        filteredCases = cases.filter(item => options.includes(item.author.id))
      }
    }
    return filteredCases;
  }

}
