<div *ngIf="item">
  <section *ngIf="showTitle" class="tw-w-full tw-py-3 tw-flex tw-justify-center tw-bg-background-modal-title lg:tw-bg-white tw-rounded-t-[10px]">
    <h1 class="tw-font-medium tw-text-main-text">
      {{'core-lib.widgets.cases.associated' | translate}}
    </h1>
  </section>

  <section *ngIf="isReview">
    <div>
      <mh-core-lib-generic-box
          customClass="!tw-p-0"
        >
        <mh-core-lib-review-case
            [review]="item"
            [source]="source"
            [customerId]="customerId"
            ></mh-core-lib-review-case>
      </mh-core-lib-generic-box>
    </div>
  </section>

  <section *ngIf="isPrestayPrecheckin">
    <div>
      <mh-core-lib-generic-box>
        <mh-core-lib-prestay-case
            [prestay]="item"
            [source]="source"
            ></mh-core-lib-prestay-case>
      </mh-core-lib-generic-box>
    </div>
  </section>

  <section *ngIf="isPrestayOrder">
    <div>
      <mh-core-lib-generic-box>
        <mh-core-lib-order-case
            [order]="item"
            [source]="source"
            [customerId]="customerId"
            ></mh-core-lib-order-case>
      </mh-core-lib-generic-box>
    </div>
  </section>

  <section *ngIf="isNps">
    <div class="md:tw-hidden">
      <mh-core-lib-nps-case
          [survey]="item"
          [source]="source"
          [customerId]="customerId"
          [product]="caseProduct"
          ></mh-core-lib-nps-case>
    </div>
    <div class="tw-hidden md:tw-block">
      <mh-core-lib-generic-box >
        <mh-core-lib-nps-case
            [survey]="item"
            [source]="source"
            [customerId]="customerId"
            [product]="caseProduct"
            ></mh-core-lib-nps-case>
      </mh-core-lib-generic-box>
    </div>
  </section>
</div>
