export class FilterSearchItem{
  public _type:string;
  public _value:any;
  public _label:string;
  public _checked:boolean = false;
  public translate:boolean = true;

  constructor(type:string, value:any, label:string, checked:boolean, translate=true){
    this._type = type;
    this._value = value;
    this._label = label;
    this._checked = checked;
    this.translate = translate;
  }

  get value(){
    return this._value;
  }
  set value(v : any) {
    this._value = v;
  }
  get label():string{
    return this._label;
  }
  set label(v : string) {
    this._label = v;
  }
  get checked():boolean{
    return this._checked;
  }
  set checked(v : boolean) {
    this._checked = v;
  }
  get type():string{
    return this._type;
  }
}