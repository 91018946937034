import { Component } from '@angular/core';
import {ReportsService} from './reports.service'
import {CustomerService} from '../shared/customer.service';
@Component({
  selector: 'mh-reports',
  templateUrl: './reports.component.html',
  providers:[ReportsService,CustomerService]
})
export class ReportsComponent {
  reportLink:string='';
  public hotelList:any;
  public idhotel:string="32";
  public endpoint:string="";
  
  constructor(){}
}
