import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

export class SendObject{
  from_name:string;
  email_recipient:string;
  email_subject: string;
  html_body:string;
  from_email:string;
  reply_email:string;
  attachments:any[];

  constructor(from_name,email_recipient,email_subject,html_body,from_email?,reply_to?,attachments?){
    this.from_name       = from_name;
    this.email_recipient = email_recipient;
    this.email_subject   = email_subject;
    this.html_body       = html_body;
    this.from_email      = from_email;
    this.reply_email        = reply_to;
    this.attachments     = attachments;
  }
}
@Injectable()
export class EmailsService {

  constructor(private http:HttpClient) { }

  public send(to:string,from_title:string,subject:string,body:string,from_email?:string,reply_to?:string,attachments?:any[]){
    const url=`${environment.email}/send`;
    const data = new SendObject(from_title, to, subject, body, from_email, reply_to, attachments);
    return this.http.post(url,data)
      .pipe(
        map(response=>response)
      )
  }
}
