<div class="alert_form {{class}}" [class.invalid-tooltip]="element.invalid" *ngIf="element.invalid && (element.dirty || element.touched)" >
  <div *ngIf="element.errors.required">
    {{'forms.attributes.not_empty' | translate}}
  </div>
  <div *ngIf="element.errors.minlength">
    {{'forms.attributes.min_lenght' | translate:({length: element.errors.minlength.requiredLength})}}
  </div>

  <div *ngIf="element.errors.maxlength">
    {{'forms.attributes.max_lenght' | translate:({length: element.errors.maxlength.requiredLength})}}
  </div>

  <div *ngIf="element.errors.email">
    {{'forms.attributes.email' | translate}}
  </div>

  <div *ngIf="element.errors.url_ota">
    <ul>
      <li *ngIf="element.errors.url_ota.check_has_com" [innerHTML]="('errors.url_ota.check_has_com' | translate)" ></li>
      <li *ngIf="element.errors.url_ota.valid_domain" [innerHTML]="('errors.url_ota.valid_domain' | translate)" ></li>
      <li *ngIf="element.errors.url_ota.start_url" [innerHTML]="('errors.url_ota.start_url' | translate:({errors: element.errors.url_ota }))" ></li>
      <li *ngIf="element.errors.url_ota.end_url" [innerHTML]="('errors.url_ota.end_url' | translate:({errors: element.errors.url_ota }) )" ></li>
      <li *ngIf="element.errors.url_ota.check_special_char" [innerHTML]="('errors.url_ota.check_special_char' | translate:({errors: element.errors.url_ota }) )" ></li>
      <li *ngIf="element.errors.url_ota.invalid_extension_language" [innerHTML]="('errors.url_ota.invalid_extension_language' | translate:({errors: element.errors.url_ota }) )" ></li>
      <li *ngIf="element.errors.url_ota.check_invalid_domain_dot_com" [innerHTML]="('errors.url_ota.check_invalid_domain_dot_com' | translate:({errors: element.errors.url_ota }) )" ></li>

    </ul>

  </div>

  <div *ngIf="element.errors.existChainInArray">
      {{'errors.duplicate.chain' | translate}}
  </div>

  <div *ngIf="element.errors.pattern">
      {{'errors.pattern.not_valid' | translate}}
  </div>

</div>
