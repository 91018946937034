<ng-container *ngTemplateOutlet="guestSummary;context:{showButtonDeatil: true}"></ng-container>

<ng-template #guestSummary let-showButtonDeatil="showButtonDeatil">
  <div class="row align-items-center">
    <div class="col-2">
      <div class="guest_info">
        <widget-guest-info
          [id]="guest?.id"
          [name]="guest?.name"
          [countryCode]="guest?.country_code"
          [lastName]="guest?.lastname"
          [email]="guest?.email"
          [product]="'prestay'"
          [isFlagUp]="true"
          [isFlagDown]="false"
          >
        </widget-guest-info>
        <button *ngIf="showButtonDeatil"  class="btn btn-link" (click)="openDialog()">{{'commons.see_detail' | translate }}</button>
      </div>
    </div>

    <div class="col-10">
      <div class="row">
        <div class="text-center col" *ngFor="let key of keys" >
          <ng-container [ngSwitch]="key">
            <ng-template [ngSwitchCase]="dateKeys.includes(key) ? key : false">
              <widget-calendar-date [label]="('commons.'+key | translate )" [date]="guest[key]" ></widget-calendar-date>
            </ng-template>

            <ng-template [ngSwitchCase]="'checkin_done'">
              <widgets-arrow-label
                [label]="('commons.stay_nights.stay' | translate)"
                [days]="guest | diffDays"
                ></widgets-arrow-label>
            </ng-template>

            <ng-template [ngSwitchCase]="'stay_nights'">
              <widget-guest-stay-nights [days]="guest.stay_nights" [text]="('commons.room' | translate)" [value]="guest.room"></widget-guest-stay-nights>
            </ng-template>

            <ng-template ngSwitchDefault>
              <widget-label-value-single [label]="('models.preckeckin-guest.'+key | translate)" [value]="guest[key]"></widget-label-value-single>
              <ng-container *ngIf="key === 'reservation_id'">
                <mh-guest-icons [guest]="guest"></mh-guest-icons>
              </ng-container>
            </ng-template>
          </ng-container>
        </div>
        <div class="col">
          <div class="h-100">
            <ng-template *ngTemplateOutlet="guest.cases?.length > 0 ? showCasesButton : showCreateCase; context: { guest }"></ng-template>
            <ng-template #showCreateCase let-guest="guest">
              <mh-core-lib-actions-wrapper slug="prestay_orders_case_create">
                <mh-core-lib-button *ngIf="showCases" [isDefault]="true" [text]="'cases.titles.form' | translate" (clicked)="handleCreateCase(guest)"></mh-core-lib-button>
              </mh-core-lib-actions-wrapper>
            </ng-template>
            <ng-template #showCasesButton let-guest="guest">
              <mh-core-lib-button
                [isSecondary]="true"
                [text]="'cases.commons.show_cases' | translate"
                (clicked)="handleOverlayCasesClick(triggerActions)"
                cdkOverlayOrigin
                #triggerActions="cdkOverlayOrigin"
              ></mh-core-lib-button>
          
              <ng-template
                cdkConnectedOverlay
                [cdkConnectedOverlayOrigin]="triggerActionsCardOrigin"
                [cdkConnectedOverlayOpen]="cardActionsOpen"
                [cdkConnectedOverlayOffsetY]="10"
                [cdkConnectedOverlayPanelClass]="['tw-mr-3', 'trigger-actions-card']"
                (overlayOutsideClick)="cardActionsOpen = false"
              >
                <ul class="tw-bg-white tw-rounded-lg tw-shadow-1 tw-relative tw-py-3 tw-min-w-[220px] tw-divide-y">
                  <li *ngFor="let cases of guest.cases || []" class="tw-cursor-pointer tw-px-4 tw-py-1 tw-font-semibold tw-text-main-text hover:tw-text-main-orange tw-transition-all">
                    <a [href]="'/cases/'+ ((cases?.closed === true || cases?.closed === undefined) ? 'history' : 'active') +'?id='+cases.public_id" target="_blank">{{cases.title}}</a>
                  </li>
                  <li class="tw-px-4 tw-py-1 tw-font-semibold tw-text-main-text hover:tw-text-main-orange tw-transition-all">
                    <mh-core-lib-actions-wrapper slug="prestay_orders_case_create">
                      <button type="button" (click)="handleCreateCase(guest); cardActionsOpen = false">{{'cases.titles.form' | translate}}</button>
                    </mh-core-lib-actions-wrapper>
                  </li>
                </ul>
              </ng-template>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    
  </div>
  <div class="d-flex justify-content-center" *ngIf="!showButtonDeatil" >
    <mh-core-lib-actions-wrapper slug="prestay_precheckin_pdf">
      <div class="download">
        <button class="btn btn-link btn-icon-left" (click)="downloadPDF()" [attr.disabled]="downloadingPDF ? true : null">
          <mat-icon>cloud_download</mat-icon>
          <span>{{'shared.survey-answer-modal.download' | translate}}</span>
        </button>
        <div class="wait_pdf">
          <mh-spinner-classic [hidden]="!downloadingPDF"></mh-spinner-classic>
        </div>
      </div>
    </mh-core-lib-actions-wrapper>

  </div>

</ng-template>

<ng-template #detailModal let-c="close" let-d="dismiss">
  <div class="content_modal">
    <div class="close" (click)="closeDialog()">
      <i class="far fa-times"></i>
    </div>
    <div class="detail_guest">
      <div class="guest_info">
        <ng-container *ngTemplateOutlet="guestSummary;context:{showButtonDeatil: false}"></ng-container>
      </div>
      <mh-spinner-classic [hidden]="!waiting"></mh-spinner-classic>
      <mh-precheckin-guest-detail *ngIf="!waiting" [questions]="questions" [answers]="answers" [waitingDownload]="downloadingFile" (file)="downloadFile($event)"></mh-precheckin-guest-detail>
    </div>
  </div>
</ng-template>



