import { animate, state, style, transition, trigger } from '@angular/animations';

export const animation = {
  slideInDownFade: trigger('slideInDownFade', [
    state('void', style({ opacity: 0, transform: 'translateY(-20px)' })),
    state('*', style({ opacity: 1, transform: 'translateY(0)' })),
    transition('void => *', animate('0.2s ease-in')),
    transition('* => void', animate('0.2s ease-in')),
  ]),
  slideInLeftFade: trigger('slideInLeftFade', [
    state('void', style({ opacity: 0, transform: 'translateX(-20px)' })),
    state('*', style({ opacity: 1, transform: 'translateX(0)' })),
    transition('void => *', animate('0.2s ease-in')),
    transition('* => void', animate('0.2s ease-in')),
  ]),
  slideInRightFade: trigger('slideInRightFade', [
    state('void', style({ opacity: 0, transform: 'translateX(20px)' })),
    state('*', style({ opacity: 1, transform: 'translateX(0)' })),
    transition('void => *', animate('0.2s ease-in')),
    transition('* => void', animate('0.2s ease-in')),
  ]),
  slideInParamsFade: trigger('slideInParamsFade', [
    state('void', style({ opacity: 0, transform: 'translateX({{from}})' }), { params: { from: '0' } }),
    state('*', style({ opacity: 1, transform: 'translateX(0)' })),
    transition('void => *', animate('0.3s ease-in')),
    transition('* => void', animate('0.3s ease-in')),
  ]),
  widthExpand: trigger('widthExpand', [
    state('void', style({ opacity: 0, width: 0 })),
    state('*', style({ opacity: 1, width: '*' })),
    transition('void => *', animate('0.2s ease-in')),
    transition('* => void', animate('0.2s ease-in')),
  ]),
  heightExpand: trigger('heightExpand', [
    state('void', style({ height: 0, opacity: 0, overflow: 'hidden' })),
    state('*', style({ height: '*', opacity: 1, overflow: 'hidden' })),
    transition('void => *', animate('0.2s ease-in')),
    transition('* => void', animate('0.2s ease-in')),
  ]),
  heightDefinedExpand: trigger('heightDefinedExpand', [
    state('collapsed', style({ height: '{{height}}', overflow: 'hidden' }), { params: { height: '100px' } }),
    state('expanded', style({ height: '*', overflow: 'none' })),
    transition('collapsed <=> expanded', animate('0.2s ease-in-out')),
  ]),
  fade: trigger('fade', [
    state('void', style({ opacity: 0 })),
    state('*', style({ opacity: 1 })),
    transition('void => *', animate('0.2s ease-in')),
    transition('* => void', animate('0.2s ease-in')),
  ]),
  zoom: trigger('zoom', [
    state('void', style({ opacity: 0, transform: 'scale(0)' })),
    state('*', style({ opacity: 1, transform: 'scale(1)' })),
    transition('void => *', animate('0.2s cubic-bezier(.31,.68,.59,1.23)')),
    transition(
      '* => void',
      animate(
        '0.2s cubic-bezier(.47,-0.37,.67,.24)',
        style({
          opacity: 0,
          transform: 'scale(0)',
          position: 'absolute',
        }),
      ),
    ),
  ]),
  rotate: trigger('rotate', [
    state(
      '0',
      style({
        transform: 'rotate(0deg)',
      }),
    ),
    state(
      '90',
      style({
        transform: 'rotate(90deg)',
      }),
    ),
    state(
      '180',
      style({
        transform: 'rotate(180deg)',
      }),
    ),
    state(
      '270',
      style({
        transform: 'rotate(270deg)',
      }),
    ),

    // Negative rotations
    state(
      '-180',
      style({
        transform: 'rotate(-180deg)',
      }),
    ),
    // We can add more if we need it
    transition('0 => 90', animate('200ms ease-out')),
    transition('90=> 0', animate('200ms ease-out')),
    transition('0 => 180', animate('200ms ease-out')),
    transition('180 => 0', animate('200ms ease-out')),
    transition('0 => 270', animate('200ms ease-out')),
    transition('270=> 0', animate('200ms ease-out')),

    // Negative transitions
    transition('0 => -180', animate('200ms ease-out')),
    transition('-180 => 0', animate('200ms ease-out')),
  ]),
  collapsedState: trigger('collapsedState', [
    state(
      'expand',
      style({
        width: '180px',
      }),
    ),
    state(
      'collapse',
      style({
        width: '70px',
      }),
    ),
    transition('expand => collapse', animate('400ms ease-out')),
    transition('collapse => expand', animate('400ms ease-in')),
  ]),
  widthExpandHeader: trigger('widthExpandHeader', [
    state('void', style({ opacity: 0, width: 0 })),
    state('*', style({ opacity: 1, width: '*' })),
    transition('void => *', animate('350ms ease-out')),
    transition('* => void', animate('350ms ease-in')),
  ]),
  collapsedHeightState: trigger('collapsedHeightState', [
    state(
      'collapse',
      style({
        height: 0,
        display: 'none',
        opacity: 0,
      }),
    ),
    state(
      'expand',
      style({
        height: 'fit-content',
        display: 'block',
        opacity: 1,
      }),
    ),
    transition('expand => collapse', animate('100ms ease-out')),
    transition('collapse => expand', animate('100ms ease-in')),
  ]),
  expandDescriptionTextState: trigger('expandDescriptionTextState', [
    state(
      'collapsed',
      style({
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
        'max-height': '3em',
      }),
    ),
    state(
      'expanded',
      style({
        display: 'block',
        overflow: 'none',
        '-webkit-box-orient': 'unset',
        '-webkit-line-clamp': 100,
        'max-height': '100%',
      }),
    ),
    transition('collapsed => expanded', animate('500ms ease-in')),
    transition('expanded => collapsed', animate('500ms ease')),
  ]),
};
