import {Component, Input, Output, EventEmitter} from '@angular/core';
import {UtilService}    from "../../../shared/util.service";
import {SummaryCollectService} from "./summary-collect.service";
import {Period}   from "../../../shared/model/index";
import {AppComponent} from "../../../layouts/app/app-component";

// TABLE IMPORTS
import {ViewChild} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import {CustomerService} from "../../../shared/customer.service";
import {ChainService} from '../../inter-chain.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { FollowupPropertiesService } from 'app/inter-chain/followup/properties/followup-properties.service';
import { Select, Store } from '@ngxs/store';
import { CustomersState } from 'app/states/customers/customers.state';

@Component({
  selector: 'mh-chain-collect-summary',
  templateUrl: './summary-collect.component.html',
  styleUrls: ['./summary-collect.component.scss'],
  providers:[SummaryCollectService,FollowupPropertiesService,CustomerService,ChainService]
})


export class SummaryCollectComponent extends AppComponent{

  @Select(CustomersState.relativeCurrentChain) customersReady$: Observable<any[]>;

  public months = [];

  @Input()
  chain=[];

  @Input()
  headerKeys = [];

  @Input()
  currentDates = null;

  actives:number[] = [];
  allData = {};
  headerTableCustomer: string[] = [];
  availableAreas=[];

  areas=[]
  productId=1;

  headerTable: string[] = [];
  chainActives = [];
  data;
  dataPMS;
  dataRanking;

  dataTable: any[] = [];
  dataSource: any = new MatTableDataSource([]);
  waiting = true;

  totals: any = {};

  tooltipHeaders = [
    'pms',
    'total_sent',
    'total_comments',
    'promoter_comments',
    'detractor_comments',
    'coverage'
  ]


  emailsSent: any = [];
  customersData: any = [];

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @Output()
  isReady: EventEmitter<boolean> = new EventEmitter();

  firstCall = true;
  surveySubscribe: Subscription;
  customersStateSubscribe: Subscription;

  constructor(
    private utilService: UtilService,
    private summaryCollectService:SummaryCollectService,
    private summaryFollowupService:FollowupPropertiesService,
    private store: Store
  ) {
    super();

    this.surveySubscribe = this.utilService.isCurrentSurveyChanged().subscribe(surveId => {
      if ( this.currentDates ) {
        this.init();
      }
    })
    this.customersStateSubscribe = this.customersReady$.subscribe(customers => {
      if ( this.currentDates ) {
        this.init();
      }
    });
  }

  ngOnDestroy(){
    this.surveySubscribe.unsubscribe();
    this.customersStateSubscribe.unsubscribe();
  }

  ngOnChanges({currentDates}) {
    this.chainActives = this.chain;

    if(!this.utilService.changingCurrentSurvey && currentDates){
      this.init();
    }
  }

  reset(){
    this.dataTable = [];
    this.isReady.emit(false);
    this.dataSource = new MatTableDataSource([]);
    this.totals = {};
  }

  init() {

    this.waiting = true;
    const survey    = this.utilService.getCurrentSurvey(this.chain.map((x: any) => parseInt(x.id)));
    const customers = this.store.selectSnapshot(state => state.customers.relativeCurrentChain).map(c => c.id).join(',');
    const date = {"startDate": this.startDate, "endDate": this.endDate};

    if (customers.length) {
      forkJoin([
        this.summaryFollowupService.getPMS(date, customers,this.productId,survey.ids),
        this.summaryCollectService.getEmailsSents(customers, date),
        this.summaryCollectService.getSummaryCollectByOta(customers, date)

      ]).subscribe(responseCollect => {
        this.dataPMS = responseCollect[0];
        this.emailsSent = responseCollect[1];
        this.customersData = responseCollect[2];
        this.waiting = false;
        this.createTable();
        this.firstCall = false;
        this.isReady.emit(true);
      })
    }
  }

  createTable() {
    this.reset()
    const dynamicHeaders = this.customersData.reduce((prev, current) => {
      const summary = current.ota_summary;
      const otasSurvey = summary.map(ota => `${ota.ota.name}-survey-reviews`).filter(ota => !prev.includes(ota));
      const otasEmails = summary.map(ota => `${ota.ota.name}-email-reviews`).filter(ota => !prev.includes(ota));
      return [
        ...otasEmails,
        ...otasSurvey,
        ...prev
      ]
    }, []);

    const cleanHeaders = dynamicHeaders.filter(key => !this.headerKeys.includes(key));

    const headerKeys = this.headerKeys.concat(cleanHeaders);
    const customers = this.store.selectSnapshot(state => state.customers.relativeCurrentChain);

    customers.forEach(customer => {

      const cell = {};
      headerKeys.forEach(key => {

        switch (key) {
          case 'customer':
            cell[key] = customer
            break;

          case 'email_sent':
            const emailSent = this.emailsSent.find((emails: any) => customer.id === emails.customer_id)
            cell[key] = emailSent.sent_emails;
            break;

          case 'pms':
             if (typeof this.dataPMS.values !== 'undefined' && typeof this.dataPMS.values !== null) {
                const index = this.dataPMS.values.findIndex(data => data.customer_id === customer.id);
                cell[key] = this.dataPMS.values[index]['email_rate'] ? this.dataPMS.values[index]['email_rate'] : new Period();
              }else{
                cell[key] = new Period();
              }
             break;

          default:
            const customerData = this.customersData.find(data => data.customer_id === customer.id).ota_summary;
            const ota = customerData.find(ota => key.toLowerCase().includes(ota.ota.name.toLowerCase()));
            if (ota) {
              if (key.includes('survey-reviews')) {
                cell[key] = ota.survey_reviews;
              } else {
                cell[key] = ota.email_reviews;
              }
            } else {
              cell[key] = { actual: 0, previous: 0, variation: 0 };
            }
            break;
        }
      })
      this.dataTable.push(cell)
    });

    this.headerKeys = headerKeys;
    this.dataSource = new MatTableDataSource(this.dataTable);
    this.isReady.emit(true);
  }


  filterCustomers($event){
    this.actives = $event;
    this.createTable()
  }
  get startDate(){ return this.currentDates.startDate}
  get endDate(){ return this.currentDates.endDate}
}
