import { Component } from '@angular/core';

@Component({
  selector: 'mh-gestor-usuarios',
  templateUrl: './gestor-usuarios.component.html',
  styleUrls: ['./gestor-usuarios.component.scss'],
})
export class GestorUsuariosComponent {

}
