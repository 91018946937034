<ul class="order_list">
  <ng-container *ngFor="let order of orders">
    <li *ngIf="(viewType==='guests') || (['orders','services'].includes(viewType) && orderSelected.id===order.id)"  >
      <mh-prestay-order-detail-reservation [order]="order" [products]="products" [money]="money" [guest]="guest"></mh-prestay-order-detail-reservation>
    </li>
  </ng-container>
</ul>



