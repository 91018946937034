<mh-alerts-wrapper>

  <section class="flex flex-row justify-between">
    <div>
      <mh-core-lib-datepicker
        [currentDates]="currentDates"
        [currentFilter]="currentFilterDate"
        (datepickerUpdated)="handleDatepickerUpdated($event)">
      </mh-core-lib-datepicker>
    </div>
    <div>
      <mh-filter-date></mh-filter-date>
    </div>
  </section>

  <ng-container>
    <div class="filter-widget" [class.float]="surveyWrapper.showFilter">
      <div class="row align-items-center">
        <div class="col-8">
          <widget-filter-chain-zone [productId]="productId" (chainChanged)="setChain($event)" [disabled]="!isReady"></widget-filter-chain-zone>
        </div>
        <div class="col-4 align-right">
          <widget-modal-faq
            [faqs]="faqs"
            [prefixTranslate]="prefixTranslateFAQ">
          </widget-modal-faq
          >
        </div>
      </div>

    </div>

    <div class="content-wrapper">
      <mh-survey-wrapper [productId]="productId" [chain]="chainFilter" [listenCustomerSubscribe]="false" #surveyWrapper>
        <section id="summary" *ngIf="isReady">
          <div class="row align-items-center">
            <div class="col-lg-3 col-md-6" *ngIf="fidelityPermissions.includes('interchain_followup_summary_email_rate')">
              <mh-chain-followup-email-rate
                [productId]="productId"
                [chain]="chainFilter"
                [currentDates]="currentDates"
              ></mh-chain-followup-email-rate>
            </div>
            <div class="col-lg-3 col-md-6" *ngIf="fidelityPermissions.includes('interchain_followup_summary_response_rate')">
              <mh-chain-followup-response-rate
                [currentDates]="currentDates"
                [productId]="productId"
                [chain]="chainFilter"
              ></mh-chain-followup-response-rate>
            </div>
            <div class="col-lg-3 col-md-6" *ngIf="fidelityPermissions.includes('interchain_followup_summary_nps')">
              <mh-chain-followup-nps
                [currentDates]="currentDates"
                [productId]="productId"
                [chain]="chainFilter"
              ></mh-chain-followup-nps>
            </div>
            <div class="col-lg-3 col-md-6" *ngIf="fidelityPermissions.includes('interchain_followup_summary_semantic')">
              <mh-chain-followup-semantic-index
                [productId]="productId"
                [startDate]="currentDates.startDate"
                [endDate]="currentDates.endDate"
                [chain]="chainFilter"
              ></mh-chain-followup-semantic-index>
            </div>

            <div class="col-12 areas" *ngIf="fidelityPermissions.includes('interchain_followup_summary_areas')">
              <mh-chain-followup-areas
                [currentDates]="currentDates"
                [productId]="productId"
                [chain]="chainFilter"
              ></mh-chain-followup-areas>
            </div>
          </div>
        </section>
      </mh-survey-wrapper>
    </div>
  </ng-container>

</mh-alerts-wrapper>
