import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { InvoiceService } from '../services/invoice.service';
import { InvoiceData } from '../models/invoice.model';
import { FilterModel } from '../models/filter-model';
import { Observable, Subscription } from 'rxjs';
import { Select } from '@ngxs/store';
import { CustomersState } from 'app/states/customers/customers.state';
import { Customer } from 'app/shared/customer.model';

@Component({
  selector: 'mh-invoice-table',
  templateUrl: './invoice-table.component.html',
  styleUrls: ['./invoice-table.component.scss'],
})
export class InvoiceTableComponent implements OnInit, OnChanges, OnDestroy {
  @Select(CustomersState.currentCustomer) customers$: Observable<Customer>;

  @Input()
  currentDates = null;

  @Input()
  currentHotelId: string = null;

  @Input()
  state: any = null;

  public waiting = false;
  public showTableFooter = false;
  public currentPage = 0;
  public sortOrder = 'ASC';
  public columnsToSort: Columns[] = [
    { key: 'invoice_number', sort: null, sort_icon: 'fa-sort' },
    { key: 'invoice_date', sort: null, sort_icon: 'fa-sort' },
    { key: 'invoice_due_date', sort: null, sort_icon: 'fa-sort' },
    { key: 'invoice_fully_paid_date', sort: null, sort_icon: 'fa-sort' },
    { key: 'status', sort: null, sort_icon: 'fa-sort' },
  ];

  public startPage = 0;
  public pageSize = 10;
  public totalElements = 10;
  public invoices: InvoiceData[] = [];
  public subs: Subscription;

  constructor(private invoiceService: InvoiceService) {}

  ngOnInit(): void {
    this.subs = this.customers$.subscribe((customer: Customer) => {
      if (customer) {
        this.getAllInvoices();
      }
    });
  }

  ngOnChanges({ currentDates, state }: SimpleChanges) {
    if (currentDates || state) {
      this.getAllInvoices();
    }
  }

  async getAllInvoices(column_name?: string, sort = 'DESC') {
    this.waiting = true;
    const payload = {
      start_date: this.currentDates.startDate,
      end_date: this.currentDates.endDate,
      page: this.startPage,
      page_size: this.pageSize,
      status: this.getStates() ? this.getStates() : null,
      column_name: column_name || null,
      sort: sort,
    };

    const res = await this.invoiceService.getInvoices(this.currentHotelId, payload).toPromise();
    const { info, page_number, total_of_elements } = res;

    this.invoices = info;
    this.totalElements = total_of_elements;
    this.currentPage = page_number;

    this.waiting = false;
  }

  paginationSizeChange(pagSize: number) {
    this.pageSize = pagSize;
    this.getAllInvoices();
  }

  pageChange(pageNumber: number) {
    this.startPage = pageNumber;
    this.getAllInvoices();
  }

  getStates() {
    return this.state
      ? this.state
          .filter((filter: FilterModel) => filter.selected && filter.value !== 'ALL')
          .map((item: FilterModel) => item.value)
      : null;
  }

  getInvoicesBy(columnName: string) {
    this.sortOrder = this.sortOrder === 'ASC' ? 'DESC' : 'ASC';
    this.setSortedColumns(columnName);
    this.getAllInvoices(columnName, this.sortOrder);
  }

  setSortedColumns(columnName: string) {
    this.columnsToSort.forEach((col) => {
      if (col.key === columnName) {
        col.sort = this.sortOrder;
        col.sort_icon = this.sortOrder === 'ASC' ? 'fa-sort-up' : 'fa-sort-down';
      } else {
        col.sort = null;
        col.sort_icon = 'fa-sort';
      }
    });
  }

  async showInvoice(invoice: InvoiceData) {
    this.waiting = true;
    const { invoice_id } = invoice;
    try {
      const res = await this.invoiceService.getUrlInvoice(this.currentHotelId, invoice_id).toPromise();
      const invoice_url = res.online_invoice[0]['online_invoice_url'];
      window.open(invoice_url, '_blank');
    } catch (error) {
      console.error(error);
      this.waiting = false;
    }
    this.waiting = false;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

export interface Columns {
  key: string;
  sort: string;
  sort_icon: string;
}
