import {Injectable} from '@angular/core';
import {NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  i18n;

  constructor(private translate: TranslateService) {
    super();
    this.translate.get(['datepicker']).subscribe((res: string) => {
      this.i18n = res;
    })
  }

  getWeekdayLabel(weekday: number): string {
    return '';
  }

  getWeekdayShortName(weekday: number): string {
    return this.i18n.datepicker.weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return this.i18n.datepicker.months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.i18n.datepicker.months[month - 1];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}
