import { Component, OnInit } from '@angular/core';
import { UtilService } from '../shared/util.service';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { DateTime } from 'luxon';
import { SessionService } from 'app/_services';

@Component({
  selector: 'mh-root-dashboard',
  templateUrl: './root-dashboard.component.html',
  styleUrls: ['./root-dashboard.component.scss'],
})
export class RootDasboardComponent implements OnInit {
  widget: SafeResourceUrl;
  localTime: number = DateTime.now().hour;
  greeting: string;

  constructor(
    private utilService: UtilService,
    private sanitizer: DomSanitizer,
    private sessionService: SessionService,
  ) {
    this.utilService.isLanguageChanged().subscribe(() => {
      this.refresWidgetUrl();
    });
  }
  ngOnInit() {
    this.refresWidgetUrl();
    this.greeting = this.getGreeting();
  }

  refresWidgetUrl() {
    const currentLanguage = this.utilService.getCurrentLanguage();
    const url = `${environment.widgets}` + currentLanguage + '/bienvenido-a-fidelity-' + currentLanguage;
    this.widget = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getGreeting() {
    if (this.localTime >= 5 && this.localTime < 12) {
      return 'dashboard.good_morning';
    }
    if (this.localTime >= 12 && this.localTime < 20) {
      return 'dashboard.good_afternoon';
    }
    return 'dashboard.good_night';
  }

  get currentUser() {
    return this.sessionService.getCurrentUser();
  }
}
