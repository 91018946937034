import { InputType,InputTranslateLanguage } from "../form/input.model";

export class PSPropertiesForm{
  id:number;
  customer_id:number;
  active_mailing=false;
  active_portal=true;
  active_web_checkin_redirection = false;
  header_color = "#FFFFFF";
  button_text_color = "#FFFFFF";
  button_color = "#23c362";
  pre_stay_start_hours = 96;
  end_check_in_hours = 24;
  end_services_hours = 0;
  contact_info : PSPropertiesFormInfo[] = [];
  footer_texts : InputTranslateLanguage[] = [];
  orders_contact_emails = "";
  checkin_contact_emails = "";
  incentive_from_mail: "";
  incentive_from_name: "";
  order_from_mail: "";
  order_from_name: "";
  reminder_from_mail: "";
  reminder_from_name: "";
  checkin_from_mail: ""
  checkin_from_name: "";
  service_active = true;
  checkin_active = true;
  max_companions = 10
}

export class PSPropertiesFormInfo{
  id: number;
  type: InputType = new InputType();
  value: string = "";
}


