import { Action, State, Selector, StateContext } from '@ngxs/store';
import { FidelityDeskStateModel } from './models';
import {
  StoreCasesList,
  ChangeStatusFilter,
  ChangePriorityFilter,
  ChangeProductFilter,
  ChangeAuthorFilter,
  ChangeAssignedFilter,
  ChangeCaseTypeFilter,
  FilterClear,
  ChangeAreasFilter,
  ChangeBase,
  ChangeStageFilter,
  ChangeSeenFilter,
  ChangeTeamFilter,
} from './fidelity-desk.actions';
import { FidelityDeskStatusFilter } from 'app/pipes/fidelity-desk/fidelity-desk-status.pipe';
import { FidelityDeskPriorityPipe } from 'app/pipes/fidelity-desk/fidelity-desk-priority.pipe';
import { FidelityDeskAuthorPipe } from 'app/pipes/fidelity-desk/fidelity-desk-author.pipe';
import { FidelityDeskAssignedPipe } from 'app/pipes/fidelity-desk/fidelity-desk-assigned.pipe';
import { FidelityDeskProductPipe } from 'app/pipes/fidelity-desk/fidelity-desk-product.pipe';
import { FidelityDeskTypePipe } from 'app/pipes/fidelity-desk/fidelity-desk-type.pipe';
import { FidelityDeskAreasPipe } from 'app/pipes/fidelity-desk/fidelity-desk-areas.pipe';
import { FidelityDeskStagePipe } from 'app/pipes/fidelity-desk/fidelity-desk-stage.pipe';
import { FidelityDeskSeenPipe } from 'app/pipes/fidelity-desk/fidelity-desk-seen.pipe';
import { FidelityDeskTeamPipe } from 'app/pipes/fidelity-desk/fidelity-desk-team.pipe';

const DEFAULT_FILTERS = [
  {
    name: 'state',
    options: [],
    titleTranslate: 'cases.commons.stage',
  },
  {
    name: 'priority',
    options: [],
    titleTranslate: 'cases.commons.priority',
  },
  {
    name: 'stage',
    options: [],
    titleTranslate: 'cases.commons.status',
  },
  {
    name: 'team',
    options: [],
    titleTranslate: 'cases.commons.team',
  },
  {
    name: 'seen',
    options: [
      { value: true, text: 'seen' },
      { value: false, text: 'unseen' },
    ],
    titleTranslate: 'cases.commons.seen',
  },
  {
    name: 'product_item.product_id',
    options: [
      { value: 1, text: 'followup' },
      { value: 2, text: 'onsite' },
      { value: 3, text: 'online' },
      { value: 4, text: 'prestay' },
      { value: 5, text: 'unassigned' },
      { value: 6, text: 'guest' },
    ],
    titleTranslate: 'filters.products.title',
    optionTranslatePrefix: 'filters.products.options.',
    optionTranslateSuffix: '',
  },
  {
    name: 'author',
    options: [],
    titleTranslate: 'filters.author.title',
  },
  {
    name: 'assigned',
    options: [],
    titleTranslate: 'filters.assigned.title',
  },
  {
    name: 'type',
    options: [],
    titleTranslate: 'filters.assigned.type',
  },
  {
    name: 'areas',
    options: [],
    titleTranslate: 'areas.title',
  },
];

const deskStatusFilterPipe = new FidelityDeskStatusFilter();
const deskStageFilterPipe = new FidelityDeskStagePipe();
const deskSeenFilterPipe = new FidelityDeskSeenPipe();
const deskTeamFilterPipe = new FidelityDeskTeamPipe();
const deskPriorityFilterPipe = new FidelityDeskPriorityPipe();
const deskAuthorFilterPipe = new FidelityDeskAuthorPipe();
const deskAssignedFilterPipe = new FidelityDeskAssignedPipe();
const deskProductFilterPipe = new FidelityDeskProductPipe();
const deskTypeFilterPipe = new FidelityDeskTypePipe();
const deskAreasFilterPipe = new FidelityDeskAreasPipe();

@State<FidelityDeskStateModel>({
  name: 'desk',
  defaults: {
    cases: null,
    filters: DEFAULT_FILTERS,
    base: null,
  },
})
export class FidelityDeskState {
  @Selector()
  static commentsSorted(state: FidelityDeskStateModel) {
    return deskAreasFilterPipe.transform(
      deskStageFilterPipe.transform(
        deskSeenFilterPipe.transform(
          deskTeamFilterPipe.transform(
            deskTypeFilterPipe.transform(
              deskStatusFilterPipe.transform(
                deskPriorityFilterPipe.transform(
                  deskAuthorFilterPipe.transform(
                    deskAssignedFilterPipe.transform(
                      deskProductFilterPipe.transform(state.cases, state.filters),
                      state.filters,
                    ),
                    state.filters,
                  ),
                  state.filters,
                ),
                state.filters,
              ),
              state.filters,
            ),
            state.filters,
          ),
          state.filters,
        ),
        state.filters,
      ),
      state.filters,
    );
  }

  @Selector()
  static casesTotal(state: FidelityDeskStateModel) {
    return state.cases;
  }

  @Selector()
  static filters(state: FidelityDeskStateModel) {
    return state.filters;
  }

  @Selector()
  static base(state: FidelityDeskStateModel) {
    return state.base;
  }

  @Action(StoreCasesList)
  storeCasesList(context: StateContext<FidelityDeskStateModel>, action: StoreCasesList) {
    context.patchState({
      cases: action.cases,
    });
  }

  @Action(ChangeStatusFilter)
  changeStatusFilter(context: StateContext<FidelityDeskStateModel>, action: ChangeStatusFilter) {
    let filters: any = { ...context.getState().filters };
    filters = Object.values(filters);
    const filter = { ...filters.find((filter) => filter.name === 'state') };
    const index = filters.findIndex((filter) => filter.name === 'state');
    filter.options = action.status;
    filters[index] = filter;
    context.patchState({
      filters,
    });
  }

  @Action(ChangePriorityFilter)
  changePriorityFilter(context: StateContext<FidelityDeskStateModel>, action: ChangePriorityFilter) {
    let filters: any = { ...context.getState().filters };
    filters = Object.values(filters);
    const filter = { ...filters.find((filter) => filter.name === 'priority') };
    const index = filters.findIndex((filter) => filter.name === 'priority');
    filter.options = action.priority;
    filters[index] = filter;
    context.patchState({
      filters,
    });
  }

  @Action(ChangeTeamFilter)
  changeTeamFilter(context: StateContext<FidelityDeskStateModel>, action: ChangeTeamFilter) {
    let filters: any = { ...context.getState().filters };
    filters = Object.values(filters);
    const filter = { ...filters.find((filterState) => filterState.name === 'team') };
    const index = filters.findIndex((filterState) => filterState.name === 'team');
    filter.options = action.team;
    filters[index] = filter;
    context.patchState({
      filters,
    });
  }

  @Action(ChangeStageFilter)
  changeStageFilter(context: StateContext<FidelityDeskStateModel>, action: ChangeStageFilter) {
    let filters: any = { ...context.getState().filters };
    filters = Object.values(filters);
    const filter = { ...filters.find((filterState) => filterState.name === 'stage') };
    const index = filters.findIndex((filterState) => filterState.name === 'stage');
    filter.options = action.stage;
    filters[index] = filter;
    context.patchState({
      filters,
    });
  }

  @Action(ChangeSeenFilter)
  changeSeenFilter(context: StateContext<FidelityDeskStateModel>, action: ChangeSeenFilter) {
    let filters: any = { ...context.getState().filters };
    filters = Object.values(filters);
    const filter = { ...filters.find((filterState) => filterState.name === 'seen') };
    const index = filters.findIndex((filterState) => filterState.name === 'seen');
    filter.options = action.seen;
    filters[index] = filter;
    context.patchState({
      filters,
    });
  }

  @Action(ChangeProductFilter)
  changeProductFilter(context: StateContext<FidelityDeskStateModel>, action: ChangeProductFilter) {
    let filters: any = { ...context.getState().filters };
    filters = Object.values(filters);
    const filter = { ...filters.find((filter) => filter.name === 'product_item.product_id') };
    const index = filters.findIndex((filter) => filter.name === 'product_item.product_id');
    filter.options = action['product'];
    filters[index] = filter;
    context.patchState({
      filters,
    });
  }

  @Action(ChangeAuthorFilter)
  changeAuthorFilter(context: StateContext<FidelityDeskStateModel>, action: ChangeAuthorFilter) {
    let filters: any = { ...context.getState().filters };
    filters = Object.values(filters);
    const filter = { ...filters.find((filter) => filter.name === 'author') };
    const index = filters.findIndex((filter) => filter.name === 'author');
    filter.options = action.author;
    filters[index] = filter;
    context.patchState({
      filters,
    });
  }

  @Action(ChangeAssignedFilter)
  changeAssignedFilter(context: StateContext<FidelityDeskStateModel>, action: ChangeAssignedFilter) {
    let filters: any = { ...context.getState().filters };
    filters = Object.values(filters);
    const filter = { ...filters.find((filter) => filter.name === 'assigned') };
    const index = filters.findIndex((filter) => filter.name === 'assigned');
    filter.options = action.assigned;
    filters[index] = filter;
    context.patchState({
      filters,
    });
  }

  @Action(ChangeCaseTypeFilter)
  changeCaseTypeFilter(context: StateContext<FidelityDeskStateModel>, action: ChangeCaseTypeFilter) {
    let filters: any = { ...context.getState().filters };
    filters = Object.values(filters);
    const filter = { ...filters.find((filter) => filter.name === 'type') };
    const index = filters.findIndex((filter) => filter.name === 'type');
    filter.options = action.caseType;
    filters[index] = filter;
    context.patchState({
      filters,
    });
  }

  @Action(ChangeAreasFilter)
  changeAreasFilter(context: StateContext<FidelityDeskStateModel>, action: ChangeAreasFilter) {
    let filters: any = { ...context.getState().filters };
    filters = Object.values(filters);
    const filter = { ...filters.find((filter) => filter.name === 'areas') };
    const index = filters.findIndex((filter) => filter.name === 'areas');
    filter.options = action.areas;
    filters[index] = filter;
    context.patchState({
      filters,
    });
  }

  @Action(FilterClear)
  filterClear(context: StateContext<FidelityDeskStateModel>, action: FilterClear) {
    context.patchState({
      filters: DEFAULT_FILTERS,
    });
  }

  @Action(ChangeBase)
  changeBase(context: StateContext<FidelityDeskStateModel>, action: ChangeBase) {
    context.patchState({
      base: action.base,
    });
  }
}
