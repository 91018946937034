import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { uniqBy } from 'lodash';

import { environment } from '../../../environments/environment';
import { UtilService } from 'app/shared/util.service';
import { SemanticCommentRes } from 'app/shared/model/semantic-comment.model';

@Injectable({
  providedIn: 'root',
})
export class SummarySemanticService {
  currentRanking: 'theme' | 'area';
  themesFilter: any[] = [];
  areasFilter: any[] = [];
  notationFilter = { positive: false, negative: false };

  constructor(private http: HttpClient, private utilService: UtilService) {}

  getTotalComments(customer_id: number, start_date: string, end_date: string) {
    const url = `${environment.customerPath}customers/${customer_id}/semantic/analysed/comments`;
    const params = new HttpParams().set('start_date', start_date).set('end_date', end_date);

    return this.http.get(url, { params });
  }

  getSemanticIndex(customer_id: number, start_date: string, end_date: string) {
    const url = `${environment.customerPath}customers/${customer_id}/semantic/index`;
    const params = new HttpParams().set('start_date', start_date).set('end_date', end_date);

    return this.http.get(url, { params });
  }

  getSemanticIndexVariation(customerId: number, start_date: string, end_date: string, productId: number) {
    const url = `${environment.customerPath}customers/semantic/index`;
    const params = new HttpParams()
      .set('customers_ids', customerId)
      .set('start_date', start_date)
      .set('end_date', end_date)
      .set('product_id', productId)
      .set('previous_period', true);

    return this.http.get(url, { params });
  }

  getCommentIndex(customer_id: number, start_date: string, end_date: string) {
    const url = `${environment.customerPath}customers/${customer_id}/semantic/acid-index`;
    const params = new HttpParams().set('start_date', start_date).set('end_date', end_date);

    return this.http.get(url, { params });
  }

  getRankingThemesAreas(customer_id: number, start_date: string, end_date: string, sentiment: string, type: string) {
    const url = `${environment.customerPath}customers/${customer_id}/semantic/ranking/${sentiment}/${type}`;
    const params = new HttpParams().set('start_date', start_date).set('end_date', end_date);

    return this.http.get(url, { params });
  }

  getCommentsList(customer_id: number, start_date: string, end_date: string, languageId: any) {
    const url = `${environment.customerPath}customers/${customer_id}/semantic/comments`;
    const params = new HttpParams()
      .set('start_date', start_date)
      .set('end_date', end_date)
      .set('language_id', languageId);
    return this.http.get<SemanticCommentRes[]>(url, { params });
  }

  getReportSemantic(start_date, end_date, language, type, customerId, email = '') {
    const url = `${environment.reportsPath}reports/semantic/customers/${customerId}/${type}`;

    let params: any = {
      start_date: start_date,
      end_date: end_date,
      language_id: language,
    };

    if (email) {
      params = {
        ...params,
        is_async: 'true',
        email,
      };
    }

    let httpParams = new HttpParams();

    Object.keys(params).forEach((k) => {
      httpParams = httpParams.set(k, params[k]);
    });

    return this.http.get(url, { params: httpParams });
  }

  getProductsAvailable() {
    const filter = ['followup', 'online', 'onsite'];
    const currentHotel = this.utilService.getCurrentHotel();
    return Object.keys(currentHotel)
      .filter((key) => filter.includes(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: currentHotel[key],
        };
      }, {});
  }

  setRankingFilter(rankings, length: number) {
    let translates: any[] = [];
    Array(length)
      .fill(null)
      .forEach((val, index) => {
        if (rankings[index]) {
          translates.push(...rankings[index].ranking);
        }
      });

    return this.filterRanking(translates);
  }

  filterRanking(arrayDuplicate: any[]) {
    return uniqBy(arrayDuplicate, 'name');
  }

  setRankingsFilter(input, action) {
    if (action === 'add') {
      this.addRankingFilter(input);
    } else {
      this.removeRankingFilter(input);
    }
  }

  addRankingFilter(input) {
    if (this.currentRanking === 'theme') {
      this.themesFilter.push(input);
      this.themesFilter = this.filterRanking(this.themesFilter);
    } else {
      this.areasFilter.push(input);
      this.areasFilter = this.filterRanking(this.areasFilter);
    }
  }

  //TODO: Eliminar la opción de la lista desplegable de los filtros de ranking para evitar bugs*****
  removeRankingFilter(input) {
    let ranking = this.currentRanking === 'theme' ? this.themesFilter : this.areasFilter;
    const index = ranking.findIndex((ranking) => ranking.name === input.name);
    if (index >= 0) {
      ranking.splice(index, 1);
    }
  }

  cleanFilters() {
    this.themesFilter = [];
    this.areasFilter = [];
  }

  changeRankingFilter(input, ranking: 'theme' | 'area', action: 'add' | 'remove') {
    this.currentRanking = ranking;
    this.setRankingsFilter(input, action);
  }

  updatePolarity(body, customerId) {
    const url = `${environment.customerPath}customers/${customerId}/semantic/comment/${body.comment_id}`;
    return this.http.patch(url, body.changes);
  }

  get themesFilterList() {
    return this.themesFilter;
  }

  get areasFilterList() {
    return this.areasFilter;
  }
}
