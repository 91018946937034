import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WidgetDashboard } from 'app/dashboard/models/WidgetDashboard.model.component';

@Component({
  selector: 'mh-core-lib-dashboard-widget',
  templateUrl: './core-lib-dashboard-widget.component.html',
  styleUrls: ['./core-lib-dashboard-widget.component.scss'],
})
export class CoreLibDashboardWidgetComponent {
  @Input() widget: WidgetDashboard;
  @Input() currentLanguage: string;
  @Output() widgetClicked: EventEmitter<string> = new EventEmitter();

  handleClick(event) {
    this.widgetClicked.emit(event);
  }
}
