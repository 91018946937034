<div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="error">
  {{'logs.error' | translate}}
  <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="error = false">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="page-wrapper">
  <mh-box-element [title]="'logs.title' | translate">
    <mh-core-lib-datepicker
      [currentDates]="currentDates"
      [currentFilter]="currentFilterDate"
      (datepickerUpdated)="handleDatepickerUpdated($event)">
    </mh-core-lib-datepicker>
    <mh-filters
      [values]="filterValues"
      [dates]="dateRange"
      [includeDates]="false"
      [filters]="filters"
      (onDateChange)="setDateFilter($event)"
      (onUpdate)="onFiltersChange($event)"
    ></mh-filters>
    <mat-checkbox class="mx-4" [formControl]="showHotelChanges">{{ 'logs.see_changes_hotel' | translate }}</mat-checkbox>
    <mh-logs-list [logs]="logs" [language]="language"></mh-logs-list>
  </mh-box-element>

  <mh-spinner-classic [hidden]="!loading"></mh-spinner-classic>
  <ng-container *ngIf="!loading && logs.length === 0">
    <ng-container *ngTemplateOutlet="noResults"></ng-container>
  </ng-container>
</div>

<ng-template #noResults>
  <div>No results</div>
</ng-template>
