import { Component,Input, OnInit } from '@angular/core';
import {UtilService} from "../../../../shared/util.service";
import {TrendsService} from "../../../followup/trends/trends.service";
import { Hotel } from 'app/shared/model';
import { DateService } from 'app/shared/services/date.service';

@Component({
  templateUrl: './trends-section.component.html',
  selector: 'mh-chain-followup-trends',
  styleUrls: ['./trends-section.component.scss'],
  providers: [TrendsService]
})
export class TrendsSectionComponent implements OnInit {


  @Input()
  productId = 1;

  @Input()
  showAreas = true;

  @Input()
  showFilters = true;

  chainFilter: Hotel[] = [];
  isReady:boolean=false;
  report = '';
  util;

  fidelityPermisions:string[] = [];

  constructor(
    private utilService:UtilService,
    private dateService: DateService
  ) {
    this.util = this.utilService
  }

  ngOnChanges(): void {
    this.report = this.productId===1 ? 'chainFollowupTrends' : 'chainOnsiteFollowupTrends';
  }

  ngOnInit(): void {
    this.fidelityPermisions = this.utilService.getFidelityConfigurationsByProduct('interchain', 'interchain_followup');
    this.report = this.productId===1 ? 'chainFollowupTrends' : 'chainOnsiteFollowupTrends';
  }

  setChain(chain){
    this.chainFilter = chain;
  }

  dataIsReady(state){
    setTimeout(_ => {
      this.isReady = state;
    },200);
  }

  handleDatepickerUpdated($event) {
    this.dateService.setTrendCurrentFilter($event.currentFilter);
    this.dateService.setTrendCurrentDates($event.currentDates)
  }

  get currentDates() {
    return this.dateService.trendCurrentDates;
  }

  get startDate() {
    return this.currentDates.startDate;
  }

  get endDate() {
    return this.currentDates.endDate;
  }

  get currentFilterDate() {
    return this.dateService.trendCurrentFilter;
  }

  get reportSlug() {
    return this.productId === 1
      ? 'corporative_followup_trends_report'
      : 'corporative_onsite_trends_report';
  }
}

