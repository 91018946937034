import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {  Subject, Subscription } from 'rxjs';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  // 0 not products, 1 config, 2 manager
  private status: number = 0;
  private subjectStatus = new Subject<any>();
  private subscription = new Subscription();
  constructor(
    private router: Router,
  ) {
    this.initRouterEvents();
  }

  initRouterEvents() {
    const corporativeRoute = 'intercadena';
    const adminRoute = '/admin';
    const configProductRoute = '/configuracion';
    this.subscription = this.router.events.pipe(filter(e => e instanceof NavigationEnd))
    .subscribe((s: NavigationEnd) => {
      if (s.url.includes(corporativeRoute)) {
        this.setStatus(3);
      } else if (s.url.includes(adminRoute) || s.url.includes('gestor-usuarios-global')) {
        this.setStatus(2);
      } else if (s.url.includes(configProductRoute) && s.url !== '/customer/configuracion/hotel/edit') {
        this.setStatus(1);
      } else {
        this.setStatus(0);
      }
    });
  }

  isStatusChanged() {
    return this.subjectStatus.asObservable();
  }

  setStatus(status = 0) {
    this.status = status;
    this.subjectStatus.next(status);
  }

  getStatus() {
    return this.status;
  }

  unsubscribeNavigationEvent() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
