<div [formGroup]="formGroup" *ngIf="!withMentions" class="{{ customContainerClass }}">
  <textarea
    #textAreaInput
    class="focus:tw-ring-0 focus-visible:tw-border-2 tw-rounded-xl focus:tw-border-border-main tw-w-full tw-border-border-light-gray {{
      inputClass
    }}"
    [placeholder]="placeholder"
    [formControlName]="keyControl"
    [rows]="rows"
    [attr.maxLength]="maxLength ? maxLength : null"
    >{{ value }}</textarea
  >
</div>

<div [formGroup]="formGroup" *ngIf="withMentions" class="{{ customContainerClass }}">
  <textarea
    #textAreaInput
    class="focus-visible:tw-border-2 focus:tw-ring-0 tw-rounded-xl focus:tw-border-border-main tw-w-full tw-border-border-light-gray {{
      inputClass
    }}"
    [placeholder]="placeholder"
    [formControlName]="keyControl"
    [rows]="rows"
    [mentionConfig]="mentionConfig"
    [attr.maxLength]="maxLength ? maxLength : null"
    (input)="formatContent($event)"
    (itemSelected)="selectMention($event)"
  ></textarea>
</div>
