<div class="filter_competitor" [hidden]="util.isMobile()">
  <div class="label_filter">{{'shared.filters.competitors.title' | translate }}</div>
  <div class="filter">
    <ng-container>
      <mat-form-field>
        <mat-select [(value)]="selected">
          <mat-option *ngFor="let competitor of competitors" [value]="competitor.id" (click)="selectCompetitor()">
            {{competitor.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>
</div>


