<article class="tw-flex tw-flex-col tw-relative tw-max-h-[85vh] tw-h-[700px] md:tw-max-h-[85vh] md:tw-h-[800px]" *ngIf="!loading else loader">
  <i class="far fa-times tw-cursor-pointer tw-absolute tw-right-4 tw-top-3" (click)="close()"></i>
  <section class="tw-w-full tw-py-3 tw-flex tw-justify-center">
    <h1 class="tw-font-medium tw-text-main-text">
      {{'followup.summary.surveys-areas.table.detail' | translate}}
    </h1>
  </section>

  <section class="tw-bg-main tw-text-white tw-font-medium tw-flex tw-justify-center tw-py-1">
    {{area.translates | translateObject: 'name'}}
  </section>

  <section class="tw-p-5 md:tw-flex tw-justify-center">
    <core-lib-widget-qualification [areaDetail]="area"></core-lib-widget-qualification>
  </section>

  <section class="tw-bg-background-gray tw-text-main-text tw-font-medium tw-flex tw-justify-center tw-py-1">
    {{'forms.questions' | translate}}
  </section>

  <section class="tw-p-5 tw-overflow-y-auto">
    <ng-container *ngFor="let detail of details">
      <mh-core-lib-widget-question-qualification
          [detail]="detail"
          ></mh-core-lib-widget-question-qualification>
    </ng-container>
  </section>

</article>

<ng-template #loader>
  <article class="tw-flex tw-justify-center tw-h-full tw-items-center">
    <mh-spinner-load></mh-spinner-load>
  </article>
</ng-template>
