<article>
  <div class="modal-header">
    <h4 class="title">{{'forms.review' | translate}}</h4>
  </div>
  <div class="modal-body">
    <div class="dialog_options">
      <div class="row">
        <div class="col-12">
          <article>
            <section class="py-2" *ngIf="isFromPublicGuest">
              <h5>{{usernameGuest}}</h5>
            </section>
            <section class="px-3 py-2 mb-5 review-response">
              <div class="title" *ngIf="review.title">
                <span>{{review.title}}</span>
              </div>
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>{{'forms.review' | translate}}</mat-label>
                <textarea matInput [rows]="review.description.length >= 200 ? 5 : 3" [readonly]="true">{{review.description}}</textarea>
              </mat-form-field>
            </section>
            <form [formGroup]="formGroup" (submit)="replyReview()" ngbAutofocus>
              <section>
                <mat-form-field class="w-100 field" floatLabel="always" appearance="outline">
                  <mat-label>{{'commons.answer' | translate}}</mat-label>
                  <textarea matInput [rows]="5" formControlName="comment"></textarea>
                </mat-form-field>
              </section>
              <section class="col" *ngIf="!replying && (!success && !failed) else loader">
                <div class="centered-button">
                  <button class="btn btn-orange" [disabled]="formGroup.invalid">{{'online.reviews.publish_in' | translate: {ota: otaName} }}</button>
                </div>
              </section>
            </form>
          </article>
        </div>
      </div>
    </div>
  </div>
</article>

<ng-template #loader>
  <section class="d-flex justify-content-center" *ngIf="replying else successTemplate">
    <mh-spinner-classic></mh-spinner-classic>
  </section>
</ng-template>

<ng-template #successTemplate>
  <section class="d-flex justify-content-center" *ngIf="success else failedTemplate">
    <span class="success">{{'online.reviews.ota_reply.success' | translate}} <i class="far fa-check-circle"></i></span>
  </section>
</ng-template>

<ng-template #failedTemplate>
  <section class="d-flex justify-content-center px-2">
    <span class="failed"><i class="far fa-exclamation-circle"></i> {{'online.reviews.ota_reply.failed' | translate}}</span>
  </section>
</ng-template>
