import { Component, Input } from '@angular/core';


@Component({
  selector: 'mh-spinner-load',
  templateUrl: './spinner_load.component.html',
  styleUrls: ['../../../../../../assets/scss/shared/elements/spinner_load.scss'],
})
export class SpinnerLoadComponent{
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  @Input()
  size=50;

  constructor(){
    this.color = "lightblue";
  }
}
