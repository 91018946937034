<button
  class="tw-rounded-full md:tw-w-fit tw-w-14 tw-h-14 tw-bg-white tw-text-main tw-shadow-1 tw-flex tw-justify-center md:tw-px-7 md:tw-py-2 focus:tw-outline-0"
  (click)="isOpen = !isOpen" type="button" cdkOverlayOrigin #trigger="cdkOverlayOrigin"
  [class.!tw-bg-main]="isOpen"
  [class.!tw-text-white]="isOpen"
  >
  <i class="fas fa-align-left tw-self-center tw-text-lg md:tw-text-base"></i>
  <span class="tw-hidden tw-ml-2 md:tw-inline-block tw-text-base tw-font-medium tw-self-center tw-text-main-text" [class.!tw-text-white]="isOpen">
    {{'voices.opinions.filter_origins' | translate}}:
  </span>
</button>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
>
  <div class="tw-mt-2 tw-w-80">
    <ul
      class="tw-content-around tw-justify-around tw-shadow-2 tw-rounded-xl tw-bg-fff tw-cursor-pointer tw-select-none tw-overflow-auto tw-max-h-64 tw-p-2 tw-pl-1"
    >
      <li class="tw-flex tw-flex-row tw-py-1 tw-px-5" *ngFor="let item of originList; trackBy:trackByOrigin" (click)="activate(item.id)">
        <input type="checkbox" class="tw-border-2 tw-rounded-sm" [checked]="item.checked"/><p class="tw-text-small tw-ml-1">{{ item.value }}</p>
      </li>
    </ul>
  </div>
</ng-template>
