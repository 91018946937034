<div id="modal_payments" *ngIf="type">
  <div class="modal-header">
    <h4 class="modal-title"><mat-icon>warning</mat-icon>{{type.translate[0].title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="greating" [innerHtml]="('notifications.greeting' | translate:{user:util.currentUser})"></div>
    <div class="enphasis" [innerHtml]="type.translate[0].emphasis+' '+(distance>0? '<b>'+distance+' Días.</b>' : '') "></div>
    
    <div class="reemember" [innerHtml]="type.translate[0].remember"></div>

    <ng-container *ngIf="type.show_account_number">
      <div id="payment_warning">

        </div>
    </ng-container>

    <div class="row no-gutters account_mh" [hidden]="!type.show_account_number">

      <div class="col-6">
        <label>{{('notifications.account_title_national' | translate)}}</label>
        <div class="account" [innerHtml]="('notifications.account' | translate)"></div>
      </div>

      <div class="col-6">
        <label>{{('notifications.account_title_international' | translate)}}</label>
        <div class="account" [innerHtml]="('notifications.account_international' | translate)"></div>
      </div>

    </div>


      <div class="greeting_mh">
          <div [innerHtml]="('notifications.atte' | translate)"></div>
          <img class="logo" src="assets/images/myhotel_logo.png" alt="logo">
      </div>

      <div class="foot" [innerHtml]="type.translate[0].note"></div>

  </div>
</div>