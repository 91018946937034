export class CurrentSurvey {
  ids: string = '';
  survey: any[] = [];
  constructor(ids: string = '', surveys: any[] = []) {
    this.ids = ids;
    this.survey = surveys;
  }
}

export interface CurrentSurveyModel {
  currentSurvey: CurrentSurvey;
}
