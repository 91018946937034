import { Component } from '@angular/core';
import { RecoveryService } from './recovery.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../_services/index';
import { strengthValidator } from '../../shared/validators/strength-password.validator';

export class SetPassword {
  new_password: string;
  confirm_password: string;
}

@Component({
  templateUrl: 'recovery.component.html',
  styleUrls: ['../../../assets/scss/login/recovery.scss'],
  providers: [RecoveryService],
})
export class RecoveryComponent {
  showPassword = false;
  showRepeatPassword = false;
  notToken = false;
  hasError = false;
  showForm = true;
  errorTag: string;
  waiting = true;
  user;
  token;
  setForm: FormGroup;
  newPassword: SetPassword = new SetPassword();
  validates = ['new_password', 'confirm_password'];
  token_alive_time = 2;
  possibleErrors = ['hasUpperCase', 'hasLowerCase', 'hasNumber', 'hasSpecialCharacters', 'hasValidLength', 'exception'];

  constructor(
    private recoveryService: RecoveryService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private alertService: AlertService,
    private router: Router,
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params['token']) {
        this.validateToken(params['token']);
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

  formatForm() {
    this.setForm = this.fb.group({
      email: new FormControl(this.user.email, []),
      new_password: new FormControl('', [Validators.required, strengthValidator()]),
      confirm_password: new FormControl(this.newPassword.confirm_password, [Validators.required]),
    });
  }

  get email() {
    return this.setForm.get('email');
  }
  get new_password() {
    return this.setForm.get('new_password');
  }
  get confirm_password() {
    return this.setForm.get('confirm_password');
  }

  validateToken(token) {
    this.waiting = true;
    if (token) {
      this.token = token;
      this.recoveryService.validateToken(token).subscribe((response) => {
        if (response['tag']) {
          this.hasError = true;
          this.token_alive_time = response['token_alive_time'];
          this.errorTag = 'errors.recovery.' + response['tag'];
        } else {
          this.user = response;
          this.formatForm();
        }
        this.waiting = false;
      });
    } else {
      this.notToken = true;
      this.waiting = false;
    }
  }

  isEmpty($val): boolean {
    if ($val.value === '' || $val.value === null || $val.value === undefined) return true;
    return false;
  }

  changePassword() {
    this.waiting = true;
    let form = this.setForm.value;
    this.newPassword.new_password = form.new_password;
    this.showForm = false;
    this.recoveryService.changePassword(this.token, this.user, this.newPassword.new_password).subscribe(
      (response: any) => {
        if (response.code && response.code === 200) {
          this.alertService.success('success.update_login_password');
        } else {
          this.alertService.error('errors.login.something_went_wrong');
        }
        this.waiting = false;
      },
      () => {
        this.alertService.error('errors.login.something_went_wrong');
        this.waiting = false;
      },
    );
  }
}
