import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mh-core-lib-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.scss']
})
export class CoreLibMainContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
