export class Language{
  id:number;
  code:string;

  constructor()
  constructor(id, code)
  constructor(id?, code?){
    this.id   = id || 0;
    this.code = code || 0;
  }
}
