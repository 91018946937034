import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReviewService {
  constructor(private http: HttpClient) {}

  public findReviewsV2(
    dates: any,
    customerId: string,
    languageId: string,
    page: number,
    page_size: number,
    paged = true,
    body = null,
  ) {
    const url = `${environment.onlineListReviewsUrl}/v2`;
    let params: HttpParams = new HttpParams()
      .set('start_date', dates.startDate)
      .set('end_date', dates.endDate)
      .set('customer_id', customerId)
      .set('language_id', languageId)
      .set('paged', paged)
      .set('page', page)
      .set('page_size', page_size);

    if (body) {
      params = params
        .set('otas', body?.otas || '')
        .set('answered_flag', body?.answered_flag === 0 ? 0 : body?.answered_flag || '')
        .set('nps', body?.nps || '')
        .set('classification', body?.calification || '')
        .set('traveler_type', body?.traveler_type || '')
        .set('language_list', body?.language_list || '')
        .set('cases', body?.cases || '');
    }

    return this.http.get(url, { params: params }).pipe(map((response) => response));
  }

  public findReviewById(reviewId: string, languageId: string) {
    const url = `${environment.onlineReviews}/${reviewId}`;
    const params: HttpParams = new HttpParams().set('language_id', languageId);

    return this.http.get(url, { params: params }).pipe(map((response) => response));
  }

  public findReviews(dates: any, customerId: string, reviewId = '/', cache: boolean = true, languageId) {
    let url = `${environment.onlineListReviewsUrl}`;
    if (reviewId !== '/') url = url + '/' + reviewId;
    const params: HttpParams = new HttpParams()
      .set('start_date', dates.startDate)
      .set('end_date', dates.endDate)
      .set('customer_id', customerId)
      .set('language_id', languageId);

    return this.http.get(url, { params: params }).pipe(map((response) => response));
  }

  public translateReview(reviewId, sourceLanguage, targetLanguage, productId, title, description) {
    const url = `${environment.customerPath}translate`;
    const body = {
      source_language_id: sourceLanguage,
      target_language_id: targetLanguage,
      product_id: productId,
      comment_id: reviewId,
      title: title,
      description: description,
    };

    return this.http.post(url, body).pipe(map((response) => response));
  }

  public setReview(reviewId) {
    let url = `${environment.onlineReviews}`;
    if (reviewId !== '/') url = url + '/' + reviewId;

    const body = {
      flag_answered: 1,
    };

    return this.http.put(url, body).pipe(map((response) => response));
  }

  public deleteReview(reviewId) {
    let url = `${environment.onlineReviews}`;
    if (reviewId !== '/') url = url + '/' + reviewId;

    return this.http.delete(url).pipe(map((response) => response));
  }

  public getReviewsDummy() {
    const params: HttpParams = new HttpParams();
    return this.http.get(`${environment.onlineListReviewsJson}`, { params: params }).pipe(map((response) => response));
  }

  replyReview(reviewId, comment, customer_id) {
    const url = `${environment.onlineReviews}/${reviewId}/reply`;
    const headers = new HttpHeaders().set('skip', 'skip');
    const body = {
      comment,
      customer_id,
    };
    return this.http.post(url, body, { headers });
  }
}
