import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HotelService {

  constructor(private http:HttpClient) { }

  getBase(){
    const url=`${environment.customerPath}customers/base`;
    const params:HttpParams= new HttpParams();
    return this.http.get(url,{params: params})
  }

  getHotel(hotel_id){
    const url=`${environment.customerPath}customers/${hotel_id}/show`;
    return this.http.get(url)
  }

  getCities(contry_code:string){
    const url=`${environment.customerPath}commons/country/${contry_code}/cities`;
    const params:HttpParams= new HttpParams();
    return this.http.get(url, {params: params})
  }

  getCity(cityId:string){
    const url=`${environment.customerPath}commons/city/${cityId}`;
    const params:HttpParams = new HttpParams();
    return this.http.get(url, {params: params})
  }

  updateHotel(hotel){
    const url=`${environment.customerPath}customers/${hotel.id}/edit`;
    return this.http.put(url,hotel)
  }

  createHotel(hotel){
    const url=`${environment.customerPath}customers/new`;
    return this.http.post(url,hotel)
  }

  availableCurrencies(currencies:Currency[]){
    const filter = currencies.filter(x=> !x.symbol.includes('?') && x.symbol!=="");
    return filter;
  }

  createChain(name){
    const url=`${environment.customerPath}commons/chain/name`;
    return this.http.post(url,name)
  }
}

export interface Currency{
  id:number;
  country:string;
  name: string;
  symbol: string;
  code: string;
  unit: string;
}
