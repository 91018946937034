import { Component, Inject, OnInit } from '@angular/core';
import { DialogRef } from '../modal/dialog-ref';
import { DIALOG_DATA } from '../modal/dialog-tokens';
import { CoreLibModalComponent } from '../modal/modal.component';
import { RankingAreasService } from 'app/followup/summary/survery-areas/ranking-areas.service';

@Component({
  selector: 'mh-core-lib-ranking-area-modal',
  templateUrl: './ranking-area-modal.component.html',
  styleUrls: ['./ranking-area-modal.component.scss']
})
export class CoreLibRankingAreaModalComponent extends CoreLibModalComponent implements OnInit {

  area: any = null;
  details: any = null;
  loading = true;

  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private rankingAreasService: RankingAreasService,
  ) { 
    super(dialogRef, data);
  }

  ngOnInit(): void {
    this.fetchArea();
  }

  fetchArea() {
    const {
      dates,
      customerId,
      productId,
      surveysIds,
      area
    } = this.data;
    this.area = area;
    this.rankingAreasService.getRankingAreaDetailV2(
      dates,
      customerId,
      productId,
      surveysIds,
      area.id
    ).subscribe((response: any) => {
      if ( response && response.length > 0 ) {
        this.details = response;
      }
      this.loading = false;
    })

  }

  close() {
    this.dialogRef.close();
  }

}
