<ul class="list_customers" [class.all]="showAll">
  <li class="customer_item" *ngFor="let customer of customers">
    <div class="row no-gutters">

      <div class="col-md-4 col-xl-3 align-self-center">
        <div class="customer_name">
          <div class="name">{{customer.name}}</div>
          <div class="customer_actions" [hidden]="true">
          <!--
            Configuración escondida en caso de que se necesite
          -->
            <ul>
              <li>
                <mh-configuration-edit-user [type]="'summaries'" [user]="user" [customer]="customer" [icon]="'far fa-pencil-alt'" [title]="'configuration.users.modal.summaries.title_top'" [style]="'link-icon'"></mh-configuration-edit-user>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-md-8 col-xl-9">
        <div class="products">
          <mh-user-products-show [customer]="customer" [user]="user"></mh-user-products-show>
        </div>
      </div>
    </div>
  </li>
</ul>

<div class="show_all_customers" [hidden]="showAll">
  <span (click)="toggleShowAll()">{{'configuration.users.show_all' | translate }}</span>
</div>
