<div class="box_pie_chart" [class.waiting]="waiting">
  <div class="row">
    <div class="col-12 text-align-left">
       <div class="pie_chart" >
        <ng-container *ngIf="!waiting">
          <canvas #pieChart baseChart width="550"
            [datasets]="datasets"
            [labels]="pieLabels"
            [colors]="colors"
            [options]="pieOptions"
            (chartClick)="chartClicked($event)"
            chartType="pie"
          >
          </canvas>
        </ng-container>
      </div>

    </div>


  </div>


</div>
