<div class="total_box full-width {{style}}">
  <mh-box-score
    [value]="formatNumber(actualValue)"
    [type]="type"
    [type_number]="type_number"
    [previous_value]="variation"
    [previous]="('inter-chain.summary.table.previous_value' | translate:{score: formatNumber(previousValue)})"
    [style]="style"
  ></mh-box-score>
</div>

