<div class="tw-flex tw-flex-col">
  <div class="tw-flex tw-justify-end">
    <i class="far fa-times tw-cursor-pointer" (click)="close()"></i>
  </div>

  <div class="tw-flex tw-justify-center tw-font-medium">
    <h3 class="tw-text-modal-title tw-text-3sm">{{ 'user_management.' + (userToEdit ? 'actions.edit.modal_title' : 'create_user.title') | translate }}</h3>
  </div>

  <mh-stepper [stepNames]="steps" [disableNextBtn]="!personalInfoValid || disableNextBtn" [showSaveBtn]="showSave || isFromProfilePage || isInternalUser" [footerMT]="footerMT" [isFromProfilePage]="isFromProfilePage || isInternalUser" (saveEmit)="saveUser()">
    <cdk-step><mh-personal-information [isEdit]="!!userToEdit" [data]="userToEdit ? userToEdit : data?.userResult" (validForm)="updateNextBtnState($event)" (updateUser)="updateUser($event)"></mh-personal-information></cdk-step>
    <cdk-step *ngIf="!isFromProfilePage && !isInternalUser"><mh-accesses-table height="tw-h-[410px]" [userCustomers]="userToEdit?.customers_ids" [dataCustomers]="data?.customers" [chains]="data?.chains" (updateHotelsSelected)="updateHotelsSelected($event)" (updateHotelsToDelete)="updateHotelsToDelete($event)"></mh-accesses-table></cdk-step>
  </mh-stepper>
</div>
