import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'onlineReviewsQualificationFilter'
})
export class OnlineReviewsQualificationFilterPipe implements PipeTransform {

  transform(reviews, filters) {
    let filteredReviews = [...reviews];
    if ( filteredReviews && filteredReviews.length > 0 ) {
      const filter = filters.find(filter => filter.name === 'qualification')
      const filtering = filter.options.some(option => option.isFiltering);
      if(filter && filtering) {
        const options = filter.options.filter(option => option.isFiltering).map(option => option.value);
        filteredReviews = filteredReviews.filter(item => {
          return options.includes(Math.trunc(item.my_hotel_rating));
        })
      }
    }
    return filteredReviews;
  }

}
