import { Component, OnInit, Inject } from '@angular/core';
import { CoreLibModalComponent } from '../modal/modal.component';
import { DialogRef } from '../modal/dialog-ref';
import { DIALOG_DATA } from '../modal/dialog-tokens';

@Component({
  selector: 'mh-core-lib-limit-cases',
  templateUrl: './limit-cases.component.html',
  styleUrls: ['./limit-cases.component.scss']
})
export class CoreLibLimitCasesModalComponent extends CoreLibModalComponent implements OnInit {

  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
  ) { 
    super(dialogRef, data);
  }

  ngOnInit(): void {
  }

  goToUpsellLink() {
    window.open(this.upsellLink, '_blank');
  }

  get upsellLink() {
    return this.data.upsellLink;
  }

  get user() {
    return this.data.user;
  }

  get monthlyLimit() {
    return this.data.monthlyLimit;
  }

  get hotel() {
    return this.data.hotel;
  }

}
