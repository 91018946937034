import { Component, Input } from '@angular/core';


@Component({
  selector: 'mh-heavy-query',
  templateUrl: './heavy_query.component.html',
  styleUrls: ['../../../../../../assets/scss/shared/elements/heavy_query.scss'],
})
export class HeavyQueryComponent{

  @Input()
  messages:string[]= [];

  @Input()
  index:number= 0;

  messagesWaiting=[]

  ngOnChanges() {
    //this.messagesWaiting = this.messages.slice(0,this.index);
  }


}
