<div [formGroup]="parentForm" *ngIf="hotelDto">
  <div formGroupName="chain_property" class="row align-items-center">
    <ng-container *ngFor="let key of hotel_chain_property">
      <div class="col-3">
        <ng-container [ngSwitch]="key">
          <ng-template [ngSwitchCase]="(['chain'].includes(key) ? key : false )">

            <widget-list-options [label]="('models.hotel_dto.chain_property.chain' | translate)" [cache]="false"
              [list]="listOptions['chain']" [selected]="chain_property.value[key].id" [keyValue]="'id'"
              [keyName]="'name'" [disabled]="!canEdit" (select)="setSelectlValue($event,key)">
            </widget-list-options>


          </ng-template>

          <ng-template ngSwitchDefault>

            <label for="{{key}}">{{'models.hotel_dto.chain_property.'+key | translate}}</label>
            <input type="text" id="{{key}}" class="form-control" formControlName="{{key}}">

          </ng-template>
        </ng-container>
      </div>
    </ng-container>
  </div>

</div>
<div class="col-12 mt-3">
  <mh-forms-dialog #dialogAddChain [title]="('customer.configurations.hotel.form.add_chain' | translate)"
    [actionClass]="'blue'" [actionIcon]="'far fa-plus-circle'"
    [actionText]="('customer.configurations.hotel.form.add_chain' | translate)" [showButtonYes]="false"
    [showButtonNo]="false"
    *ngIf="canEdit" >
    <div class="modal_content">
      <div class="modal_content">
        <form [formGroup]="controlFormAddChain" (ngSubmit)="sendAddChain()" class="form-horizontal mat-form">
          <div class="inner-form input-data">
            <div class="row">
              <div class="col-sm-8">

                <mat-form-field class="full_width">
                  <input matInput type="text" formControlName="name"
                    [placeholder]="('customer.configurations.hotel.form.enter_chain' | translate)">
                  <mh-form-alerts [element]="controlFormAddChain['controls'].name" class="mt-1"></mh-form-alerts>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="btn-actions mt-1">
            <div class="row">
              <div class="col">
                <button class="btn btn-orange"
                  [disabled]="(controlFormAddChain.invalid || waitingAddChain)">{{ 'customer.configurations.hotel.form.add_chain' | translate }}</button>
              </div>

              <div class="col-12 mt-2" [hidden]="!waitingAddChain">
                <mh-spinner-classic></mh-spinner-classic>
              </div>
            </div>

          </div>
        </form>
      </div>
    </div>
  </mh-forms-dialog>
</div>

<ng-template #formAlerts let-element="element">
  <small class="alert-danger">
    <ng-container *ngIf="element.invalid && (element.dirty || element.touched)">
      <div *ngIf="element.errors.required">
        {{'forms.attributes.not_empty' | translate}}
      </div>
      <div *ngIf="element.errors.minlength">
        {{'forms.attributes.min_lenght' | translate:({length: element.errors.minlength.requiredLength})}}
      </div>

      <div *ngIf="element.errors.maxlength">
        {{'forms.attributes.max_lenght' | translate:({length: element.errors.maxlength.requiredLength})}}
      </div>
    </ng-container>
  </small>
</ng-template>

<ng-template #formTypes let-data="data">

</ng-template>
