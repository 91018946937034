import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Review } from 'app/shared/model';

@Injectable()
export class ReviewsService {

  constructor(private http:HttpClient) { }

  public findReviews(dates:any,customerId:string, reviewId='/', cache:boolean=true,languageId){
    let url=`${environment.onlineListReviewsUrl}`;
    if(reviewId!=='/') url = `${url}/${reviewId}`;
    const params:HttpParams= new HttpParams()
      .set("start_date",dates.startDate)
      .set("end_date",dates.endDate)
      .set("customer_id",customerId)
      .set("language_id",languageId);
    return this.http.get(url, {params: params})
      .pipe(
        map(response=>response)
      )
  }

  filterReviewsWithCoverage(reviews:Review[] = []){
    const filter  = reviews.filter(x=>{
      if(![4,6,7,8].includes(x.ota.id)){
        if(!(x.ota.id===2 && x.description==="")){
          return x;
        }
      }
    });
    return filter;
  }
}
