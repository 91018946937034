<mh-card-user-thumbnail [action]="log" [titleKey]="'company_position'">
  <div class="action-detail" header>
    <ng-container *ngIf="!isReviewLog else headerTitle">
      {{'logs.actions.'+log.type | translate}}: <strong>{{'logs.resources.' + log.resourceName + '.title' | translate}}</strong>
    </ng-container>
  </div>
  <ng-container body>
    {{'logs.changes_description' | translate}}:
    <ng-container
      *ngTemplateOutlet="changesList, context: { changes: log.changes, log: log }"
    >
    </ng-container>
  </ng-container>
</mh-card-user-thumbnail>

<ng-template #headerTitle>
  <ng-container *ngIf="isReviewLog">
    <ng-container *ngTemplateOutlet="headerReview"></ng-container>
  </ng-container>
  <ng-container *ngIf="isReviewManaged || isSurveyAnsweredDeleted">
    <ng-container *ngTemplateOutlet="manageAnsweredSurvey"></ng-container>
  </ng-container>
</ng-template>

<ng-template #headerReview>
  {{'logs.actions.replied' | translate}}: <strong>{{'logs.resources.' + log.resourceName + '.title' | translate}} - {{log.changes[0].review_id }}</strong>
</ng-template>

<ng-template #manageAnsweredSurvey>
  {{'logs.actions.'+ (isReviewManaged ? 'managed' : 'deleted') | translate}}: <strong>{{'logs.resources.answered_survey.title' | translate}} - {{log.changes[0].answered_survey_id}}</strong>
</ng-template>




<ng-template #changesList
  let-changes="changes"
  let-log="log">
  <ul>
    <ng-container *ngFor="let change of changes">
      <li *ngIf="!EXCLUDED_FIELDS.includes(change?.name)">
        <ng-container *ngIf="change.actual?.translates && !isSentiment; else baseChange">
          <ng-template *ngTemplateOutlet="commonLog, context: { change, log, language }"></ng-template>        
        </ng-container>
        <ng-template #baseChange>
          <ng-container *ngIf="log.resourceName !== 'review_reply' && change.name !== 'translation'; else translation">
            <ng-container *ngIf="isReviewManaged || isSurveyAnsweredDeleted || isReprocessSurvey || isSentiment; else base">
              <div *ngIf="isReviewManaged" [innerHtml]="'logs.actions.managed' | translate:{name: userManage, date: (dateManage | date:'dd-MM-y'), answeredId: surveyIdManage}"></div>
              <div *ngIf="isSurveyAnsweredDeleted" [innerHtml]="'logs.actions.deleted' | translate:{name: userManage, answeredId: surveyIdManage}"></div>
              <div *ngIf="isReprocessSurvey" [innerHtml]="'logs.actions.reprocessed' | translate: { surveyName: change.surveyTypeName, startDate: (change.startDate | date: 'dd-MM-y'), endDate: (change.endDate | date: 'dd-MM-y')  }"></div>
              <div *ngIf="isSentiment">
                <ng-template *ngTemplateOutlet="sentimentLog, context: { change, log, language }"></ng-template>
              </div>
            </ng-container>
            <ng-template #base>
              {{'logs.resources.' + log.resourceName + '.fields.' + change.name | translate}}:
              <span
                [innerHtml]="('logs.changes.' + change.operation + '.' + change.type | translate: (getTranslateParam(change)))"
                >
              </span>
            </ng-template>
          </ng-container>
          <ng-template #translation>
            <ng-container *ngIf="log.resourceName !== 'review_reply' else translateReviewLog">
              {{'logs.resources.' + log.resourceName + '.fields.' + change.actual.fieldName | translate}}:
              <span
                [innerHtml]="('logs.changes.' + change.operation + '.' + change.type | translate: (getTranslateParamForTranslation(change)))"
                >
              </span>
            </ng-container>
          </ng-template>
          <ng-template #translateReviewLog>
              {{'logs.resources.' + log.resourceName + '.review_replied' | translate}}:
              <span>
                {{change.description}}
              </span>
          </ng-template>
        </ng-template>
      </li>
    </ng-container>
  </ul>
</ng-template>

<ng-template #sentimentLog let-change="change" let-log="log" let-language="language">
  <strong mhFindTranslate
    [language]="language"
    [translates]="change.actual?.translates"
    [keyTranlsate]="'text'"
    [capitalize]="true"
  ></strong>:
  <span
    [innerHtml]="('logs.changes.' + change.operation + '.' + change.type | translate: (getTranslateParam(change)))"
  ></span>
</ng-template>

<ng-template #commonLog let-change="change" let-log="log" let-language="language">
  {{'logs.resources.' + log.resourceName + '.fields.' + change.name | translate}}:
  <strong mhFindTranslate
    [language]="language"
    [translates]="change.actual?.translates"
    [keyTranlsate]="'name'"
    [capitalize]="true"
  ></strong>
</ng-template>
