import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'widget-card-rate-summary',
  templateUrl: './card-rate-summary.component.html',
  styleUrls: ['./card-rate-summary.component.scss']
})
export class CardRateSummaryComponent implements OnInit {

  @Input()
  title;

  @Input()
  percentRate: number;

  @Input()
  formulaRate: string;

  @Input()
  tooltipRate: string;

  @Input()
  height = 155;

  @Input()
  waiting = true;

  constructor() { }

  ngOnInit() {
  }

  get style(){
    return {
      'height' : `${this.height}px`
    }
  }

}
