import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'widgets-arrow-label',
  templateUrl: './arrow-label.component.html',
  styleUrls: ['./arrow-label.component.scss']
})
export class ArrowLabelComponent implements OnInit {

  @Input()
  label:string;

  @Input()
  isAirline = false;

  @Input()
  labelInNigths = true;

  @Input()
  days: number;

  @Input()
  direction: 'right'|'left' = 'right';

  @Input()
  layoutView: 'list-view' | 'card-view'

  constructor() { }

  ngOnInit() {
  }

  get isCardView() { return this.layoutView === 'card-view'}
  get classArrow() {
    if(this.layoutView === 'list-view'){
      return 'text-center'
    }
    if(this.layoutView === 'card-view'){
      return 'adjust-card-view'
    }
  }

  get classText() {
    if(this.layoutView === 'card-view'){
      return 'col-5 adjust-text'
    }
  }
}
