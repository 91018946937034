<section class="tw-relative">

  <div *ngIf="isBlocking && !isPaid" class="tw-absolute tw-w-full tw-h-full tw-z-50 tw-bg-gradient-to-b tw-from-transparent tw-via-white tw-to-white"></div>
  
  <section class="header">
    <ng-content select="[head]"></ng-content>
  </section>


  <section class="content">
    <ng-content select="[main-content]"></ng-content>
  </section>

</section>
