<mh-alerts-wrapper>

  <section class="flex flex-row justify-between">
    <div>
      <mh-core-lib-datepicker
          [currentDates]="currentDates"
          [currentFilter]="currentFilterDate"
          (datepickerUpdated)="handleDatepickerUpdated($event)">
      </mh-core-lib-datepicker>
    </div>
    <div>
      <mh-filter-date
          [startDate]="startDate"
          [endDate]="endDate"
          [report]="fidelityPermisions.includes('interchain_followup_properties_report') ? report : null"
          [reportActiveEmail]="true"
          [chain]="chainToReport"
          [checkPermissions]="true"
          [slug]="reportSlug">
      </mh-filter-date>
    </div>
  </section>
  <ng-container *ngIf="!waiting">
    <mh-box-element [title]="('inter-chain.summary.title' | translate )" *ngIf="fidelityPermisions.includes('interchain_followup_properties_result')">
      <div class="filter-widget" [class.float]="surveyWrapper.showFilter">
        <div class="row align-items-center">
          <div class="col-8">
            <widget-filter-chain-zone [productId]="productId" (chainChanged)="setChain($event)" [disabled]="!isReady"></widget-filter-chain-zone>
          </div>
          <div class="col-4 align-right">
            <widget-modal-faq
              [faqs]="faqs"
              [prefixTranslate]="prefixTranslateFAQ"
              [showWeightedAverage]="showWeightedAverage"
            >
            </widget-modal-faq>
          </div>
        </div>
      </div>
      <div class="content-wrapper" >
        <mh-survey-wrapper [productId]="productId" [chain]="chainFilter" [listenCustomerSubscribe]="false" #surveyWrapper>
          <mh-followup-properties [currentDates]="currentDates" [chain]="chainFilter" [productId]="productId" [headerKeys]="headerTable" (isReady)="dataIsReady($event)" (filteredChain)="setChainToReport($event)"></mh-followup-properties>
        </mh-survey-wrapper>
      </div>
    </mh-box-element>
  </ng-container>

</mh-alerts-wrapper>
