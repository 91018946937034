<cdk-accordion class="tw-block">
  <cdk-accordion-item
    *ngFor="let item of items; let index = index"
    #accordionItem="cdkAccordionItem"
    class="tw-block tw-bg-white even:tw-bg-gray-100"
    role="button"
    tabindex="0"
    [expanded]="item.expanded"
    [attr.id]="'accordion-header-' + index"
    [attr.aria-expanded]="accordionItem.expanded"
    [attr.aria-controls]="'accordion-body-' + index"
  >
    <div
      class="tw-flex tw-content-center tw-justify-between tw-p-4 hover:tw-cursor-pointer hover:tw-bg-gray-200 {{ headerCustomClass }}"
      (click)="accordionItem.toggle()"
    >
      <div *ngIf="isCustom">
        <table class="reports-table tw-w-full tw-table-fixed tw-text-base tw-font-bold tw-text-left tw-text-gray-900">
          <tbody>
            <tr>
              <td scope="col" class="tw-pr-6 tw-font-bold">
                {{ 'configuration.profile.' + item.title | translate }}
              </td>
              <td scope="col" *ngFor="let toggle of options; let i = index" class="tw-pr-6 tw-pl-2">
                <mh-core-lib-toggle
                  *ngIf="toggle.enable"
                  [value]="item.allChecked"
                  [id]="i"
                  (onClick)="onMassiveClick($event)"
                >
                </mh-core-lib-toggle>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="isSimple">
        <span class="tw-text-base tw-font-medium tw-text-main-text">{{ item.translate | translate }}</span>
      </div>
      <span class="tw-text-sm tw-text-gray-400">
        <i *ngIf="accordionItem.expanded; else accordionItemClose" class="fas fa-chevron-up tw-text-xl"></i>
        <ng-template #accordionItemClose>
          <i class="fas fa-chevron-down tw-text-xl"></i>
        </ng-template>
      </span>
    </div>

    <ng-container *ngIf="isSimple">
      <div
        class="{{ bodyCustomClass }}"
        [style.display]="accordionItem.expanded ? '' : 'none'"
        [attr.id]="'accordion-body-' + index"
        [attr.aria-labelledby]="'accordion-header-' + index"
      >
        <ng-content></ng-content>
      </div>
    </ng-container>

    <ng-container *ngIf="isCustom">
      <div
        *ngFor="let subItem of item.items; let index = index"
        class="tw-bg-white even:tw-bg-gray-100"
        [style.display]="accordionItem.expanded ? '' : 'none'"
        [attr.id]="'accordion-body-' + index"
        [attr.aria-labelledby]="'accordion-header-' + index"
      >
      <ng-container *ngIf="subItem.enable">
        <table class="reports-table tw-w-full tw-table-fixed tw-text-base tw-font-bold tw-text-left tw-text-gray-900">
          <tbody>
            <tr>
              <td scope="col" class="tw-pr-6 tw-pl-8 tw-py-5 tw-font-normal">
                {{ 'configuration.profile.' + subItem.value | translate }}
              </td>
               <td scope="col" *ngFor="let toggle of options; let i = index" class="tw-pr-6 tw-py-5">
                <mh-core-lib-toggle
                  *ngIf="toggle.enable"
                  [value]="subItem.check"
                  [id]="index"
                  [isDisabled]="!toggle.enable"
                  (onClick)="onClick($event)"
                >
                </mh-core-lib-toggle>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
      
      </div>
    </ng-container>
  </cdk-accordion-item>
</cdk-accordion>
