import { Component, Inject } from '@angular/core';
import { DialogRef } from '../modal/dialog-ref';
import { DIALOG_DATA } from '../modal/dialog-tokens';
import { CoreLibModalComponent } from '../modal/modal.component';

@Component({
  selector: 'mh-core-lib-pms-integration-status-modal',
  templateUrl: './pms-integration-status-modal.component.html',
  styleUrls: ['./pms-integration-status-modal.component.scss'],
})
export class CoreLibPmsIntegrationStatusModalComponent extends CoreLibModalComponent {
  constructor(protected dialogRef: DialogRef, @Inject(DIALOG_DATA) public data: any) {
    super(dialogRef, data);
  }

  handleClose() {
    this.dialogRef.close();
  }

  get lastUpdate() {
    return this.data.lastUpdate;
  }

  get currentLanguage() {
    return this.data.currentLanguage;
  }
}
