import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'mh-core-lib-searcher',
  templateUrl: './searcher.component.html',
  styleUrls: ['./searcher.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreLibSearcherComponent),
      multi: true
    }
  ],
})
export class CoreLibSearcherComponent implements OnInit, ControlValueAccessor {

  @Input()
  placeholder: string | null = null;

  @Input()
  widthPx: string = '';

  value: string = '';
  isDisabled: boolean;
  onChange = (_:any) => { }
  onTouch = () => { }

  constructor() { }

  ngOnInit(): void {
  }

  onInput(value: string) {
    this.value = value;
    this.onTouch();
  }

  updateValue() {
    this.onTouch();
    this.onChange(this.value);
  }

  writeValue(value: any): void {
    this.value = value || '';
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
