import { Injectable } from '@angular/core';
import { Hotel,Language, User, Guest } from 'app/shared/model';
import { plainToClass } from "class-transformer";
import { TranslateService } from '@ngx-translate/core';
import { PSPropertiesForm } from 'app/shared/model/prestay/ps-properties-form.model';
import { FidelityProperty } from 'app/shared/model/fidelity-property.model';
import { EMAIL_DOMAIN_BLACKLIST } from 'app/shared/utils/statics/blacklist.static';
import { HotelService } from 'app/shared/hotel/hotel.service';
import {Store} from '@ngxs/store';
import {StoreDefaultLanguage} from 'app/states/default-language/default-language.actions';
import { DefaultLanguageState } from 'app/states/default-language/default-language.state';
import { ConfigCasesService } from 'app/cases/services/config-cases.service';
@Injectable({
  providedIn: 'root',
})
export class SessionService {

  currentCustomer: Hotel;
  currentLanguage: string;
  currentUser:User;
  currentFakeUser:User;
  currentSurveyId = 0;
  currentJWT;


  currentSurvey: number;

  availableLanguages = [
    new Language(1, 'es'),
    new Language(2, 'en'),
    new Language(3, 'zh'),
    new Language(4, 'hi'),
    new Language(5, 'ar'),
    new Language(6, 'bn'),
    new Language(7, 'pt'),
    new Language(8, 'ru'),
    new Language(9, 'ja'),
    new Language(10, 'de'),
    new Language(11, 'fr'),
    new Language(12, 'it'),
    new Language(14, 'other'),
    new Language(15, 'hr'),
    new Language(16, 'sq'),
    new Language(17, 'el'),
    new Language(18, 'tr'),
    new Language(19, 'bg'),
    new Language(20, 'mk'),
    new Language(21, 'ro'),
    new Language(22, 'hu'),
    new Language(23, 'sk'),
    new Language(24, 'sl'),
    new Language(25, 'cs'),
    new Language(26, 'uk'),
    new Language(27, 'pl'),
    new Language(28, 'lt'),
    new Language(29, 'lv'),
    new Language(30, 'et'),
    new Language(31, 'fi'),
    new Language(32, 'sv'),
    new Language(33, 'no'),
    new Language(34, 'he'),
    new Language(35, 'vi'),
    new Language(36, 'tl'),
    new Language(37, 'da'),
    new Language(38, 'nl'),
    new Language(39, 'af'),
    new Language(40, 'sw'),
    new Language(41, 'ml'),
    new Language(42, 'ko'),
    new Language(43, 'th'),
  ];

  constructor(
    private translate: TranslateService,
    private hotelService: HotelService,
    private store: Store,
    private configCasesService: ConfigCasesService,
  ){}

  getCurrentCustomer():Hotel{
    const hotel  = this.getCurrentCustomerRAW();
    this.currentCustomer = hotel;
    if(this.isLiveDemo()){
      this.currentCustomer.name = this.setDemoName(this.currentCustomer,1);
    }
    return this.currentCustomer;
  }

  getCurrentCustomerRAW():Hotel{
    const currentHotel: any = localStorage.getItem('currentHotel');
    const hotel = plainToClass<Hotel, Object>(Hotel, JSON.parse(currentHotel));
    return hotel;
  }

  setCurrentCustomer(customer:Hotel){
    localStorage.setItem('currentHotel', JSON.stringify(customer));
    localStorage.setItem('lastHotelUsed', JSON.stringify(customer));
    this.configCasesService.getCasesCurrentConfig(customer.id).subscribe((result) => {
      const flagExpired = result?.flag_expired;
      this.configCasesService.setFlagExpired(flagExpired);
    });

    this.setDefaultLanguage(customer.id);
    this.currentCustomer = customer;
    return this.currentCustomer;
  }

  refreshCurrentCustomer(){
    localStorage.setItem('currentHotel', JSON.stringify(this.currentCustomer));
  }

  setDefaultLanguage(hotelId) {
    const currentLangCode = localStorage.getItem('currentLanguage');
    if (currentLangCode) {
      const currentLangCodeId = this.getLanguageId(currentLangCode) as number;
      const settedLangId = this.store.selectSnapshot(DefaultLanguageState.language)?.languageId || null;
      if (currentLangCodeId !== settedLangId) {
        this.store.dispatch(new StoreDefaultLanguage(currentLangCode, currentLangCodeId));
      }
    } else {
      this.hotelService.getHotel(hotelId).subscribe((hotel: any)=> {
        const code = hotel.language.code;
        const id = hotel.language.id;
        this.store.dispatch(new StoreDefaultLanguage(code, id));
      });
    }
  }

  getCurrentLanguage(){
    return localStorage.getItem('currentLanguage') || 'es';
  }

  getLanguageId(code=null){
    const code_language = code || this.getCurrentLanguage();
    const language = this.availableLanguages.find(lang => lang.code === code_language) || null;
    return (language) ? language.id : language;
  }

  setCurrrentLanguage(language){
    localStorage.setItem('currentLanguage', language);
    this.translate.setDefaultLang(language);
    this.currentLanguage = language;
    return this.currentLanguage;
  }

  getCurrentUser():User{
    const currentUser: any = localStorage.getItem('currentUser');
    const user        = plainToClass<User, Object>(User, JSON.parse(currentUser));
    this.currentUser  = user;
    return this.currentUser;
  }

  refreshCurrentUser(){
    localStorage.setItem('currentUser',  JSON.stringify(this.currentUser));
  }

  getCurrentFakeUser():User{
    const currentFakeUserJson: any = localStorage.getItem('currentFakeUser');
    let user = plainToClass<User, Object>(User, JSON.parse(currentFakeUserJson));
    let currentFakeUser = this.currentFakeUser===undefined ? user : this.currentFakeUser;
    this.currentFakeUser = currentFakeUser;
    return currentFakeUser;
  }

  setCurrenFakeUser(user:User){
    localStorage.setItem('currentFakeUser', JSON.stringify(user));
    this.currentUser.fakeUser = user;
    this.refreshCurrentUser();
    return this.currentUser.fakeUser;
  }

  isLiveDemo(){
    return (this.currentUser && this.currentUser.isSuperAdmin() && this.currentUser.superAdminProperties.live_demo);
  }

  setDemoName(hotel, index){
    return "Demo myHotel "+index;
  }

  setCurrenHotelPS(hotel:Hotel){
    localStorage.setItem('currentPrestayHotel', JSON.stringify(hotel));
  }

  getCurrentGuest(){
    const currentPrestayGuest: any = localStorage.getItem('currentPrestayGuest');
    let guest = plainToClass<Guest, Object>(Guest, JSON.parse(currentPrestayGuest));
    if(guest && guest.checkin && guest.checkout){
      guest.checkin  = new Date(Date.parse(guest.checkin.toString()));
      guest.checkout = new Date(Date.parse(guest.checkout.toString()));
      guest = this.checkGuestEmailDomain(guest) as Guest
    }
    return guest;
  }

  checkGuestEmailDomain(guest: Guest) {
    return {
      ...guest,
      email: EMAIL_DOMAIN_BLACKLIST.some(b => guest.email.includes(b)) ? '' : guest.email
    }
  }

  getCurrentHotelPS(){
    const currentPrestayHotel: any = localStorage.getItem('currentPrestayHotel');
    let hotel = plainToClass<Hotel, Object>(Hotel, JSON.parse(currentPrestayHotel));
    return hotel;
  }

  setCurrentHotelPSConfig(config:PSPropertiesForm){
    localStorage.setItem('currentPrestayHotelConfigurations', JSON.stringify(config));
  }

  removeCurrentHotelPSConfig() {
    localStorage.removeItem('currentPrestayHotelConfigurations');
  }

  getCurrentHotelPSConfig(){
    const currentPrestayHotelConfigs: any = localStorage.getItem('currentPrestayHotelConfigurations');
    let config = plainToClass<PSPropertiesForm, Object>(PSPropertiesForm, JSON.parse(currentPrestayHotelConfigs));
    return config;
  }

  setCurrenGuest(guest:Guest){
    localStorage.setItem('currentPrestayGuest', JSON.stringify(guest));
  }

  getGuestLoginToken() {
    return localStorage.getItem('currentGuestLoginToken');
  }

  setGuestLoginToken(token) {
    localStorage.setItem('currentGuestLoginToken', token);
  }

  getGuestToken(){
    return localStorage.getItem('currentGuestToken');
  }

  setGuestToken(token){
    localStorage.setItem('currentGuestToken', token);
  }

  getCurrentSurveys(){
    const currentSurvey: any = localStorage.getItem('currentSurvey')
    return JSON.parse(currentSurvey);
  }

  setCurrentSurvey(ids:string,surveys:any[]){
    const obj = {
      ids:ids,
      survey: surveys
    }
    localStorage.setItem('currentSurvey', JSON.stringify(obj));
  }

  setFidelityProperties(properties:FidelityProperty){
    localStorage.setItem('fidelityProperties', JSON.stringify(properties));
  }

  getFidelityProperties(){
    const fidelityProperties: any = localStorage.getItem('fidelityProperties');
    let config = plainToClass<FidelityProperty, Object>(FidelityProperty, JSON.parse(fidelityProperties));
    return config;
  }

  setResponseAlert(alerts:[] = []) {
    localStorage.setItem('responseAlert', JSON.stringify(alerts));
  }

  // parte del hack para updetear el usuario despues de editar un perfil
  // la sesion debiese preocuparse de estar parte, pero primero hay que reordenar
  // el login
  setCurrentUserProfile(UserProfile: Object) {
    this.currentUser = <User>{
      ...this.currentUser,
      ...UserProfile,
    }
    this.refreshCurrentUser()
  }
}
