import {Component, ViewChild, ElementRef,Output,EventEmitter} from '@angular/core';
import {UtilService} from "../../../shared/util.service";
import {CategoriesService} from './categories.service';
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {ReviewsService} from "../../../online/reviews/reviews.service";
import {plainToClass} from "class-transformer";
import {Review, Visit, Survey} from "../../../shared/model/index";
import {CommentaryFeedService} from "../../../followup/summary/survey-comments/commentary-feed.service";

export class SemanticDetail{
  average:number;
  id_review:number;
  sentiment_polarity:string;
  sentiment_score:number;
  waiting:boolean = true;
  review;
  comments:CommentDetail[] = [];

  isComment(){
    return this.comments[0] !== undefined;
  }
  isReview(){
    return this.review !== undefined;
  }
}

export class CommentDetail{
  survey_id:number;
  comment:string;
  question_id:number;
  constructor(survey_id, comment, question_id){
    this.survey_id = survey_id;
    this.comment = comment;
    this.question_id = question_id;
  }
  link(){
    return "/followup/huespedes?guest="+this.survey_id;
  }
}

export class Theme{
  category:string;
  total:number;
}

@Component({
  templateUrl: './categories-modal.component.html',
  providers:[CategoriesService,ReviewsService,CommentaryFeedService],
  styleUrls: ['./categories-modal.component.scss'],
})
export class CategoryModal{

  waiting = true;
  customerId;
  dates;
  area:any;
  filter:string;
  result:AreaDetail[]=[];
  panelOpenState = false;
  productId:string;
  reviewsLoaded={}
  listReviews: Review[] = [];
  listSurveys: Survey[] = [];
  listResults: any[] = [];
  themes: Theme[] = [];
  modalRef;
  surveyId;
  language;


  filterSelected='all';
  sentiment:string;

  public barChartOptions:any = {
    scaleShowVerticalLines: true,
    responsive: false
  };
  public barChartLabels:string[] = [];
  public barChartType:string = 'horizontalBar';
  public barChartLegend:boolean = true;

  public barChartData:any[] = [];

  @ViewChild('resultSurvey', { static: false }) resultSurvey:ElementRef;



  constructor(public activeModal: NgbActiveModal,private categoriesService: CategoriesService, private utilService:UtilService, private reviewsService:ReviewsService, private commentaryFeedService:CommentaryFeedService) {

  }

  reset(){
    this.listReviews = [];
    this.listSurveys = [];
    this.listResults = [];
    this.themes = [];
  }

  public setModal(element,key) {
    this.reset();
    this.waiting = true;
    this.sentiment = key;
    let languageId = this.utilService.getLanguageId();
    this.language  = this.utilService.getCurrentLanguage();

    this.categoriesService.getAreaDetail(this.dates, this.customerId, element.area.id_area, this.productId, key,languageId,this.surveyId).subscribe((response:any)=>{
      this.result = [];
      this.result = response[this.getKeyData()];
      this.createListData();
      this.themes = plainToClass(Theme, response.summary);
      this.themes = this.utilService.sortArray(this.themes,'total', "DESC");
      this.waiting = false;
    })
  }

  getKeyData(){
    if(parseInt(this.productId)===3){
      return "reviews";
    }else{
      return "comments"
    }
  }

  closeModal(){
    this.activeModal.close();
  }

  parseHashtag(word:string){
    return this.utilService.parseToHashtag(word);
  }

  getReview($event, review){
    let language = this.utilService.getLanguageId();
    this.reviewsService.findReviews(this.dates, this.customerId,review.id_review,false,language).subscribe((response:any)=>{
      let rawReview = response.reviews[0];
      let visit = new Visit(
        rawReview.guest.username,
        this.utilService.stringDateToDate(rawReview.guest.stay_date),
        rawReview.guest.country,
        rawReview.guest.language
      );
      let publicationDate = this.utilService.stringDateToDate(rawReview.publication_date);
      let newReview       = new Review(publicationDate,visit);
      newReview.createFromApi(rawReview)
      review.review = newReview;
      review.waiting = false;
    })
  }

  getComment($event, survey){
    survey.comments = [];
    this.commentaryFeedService.getSurvey(survey.id_review,this.productId).subscribe((response:any)=>{
      let comments: any[] = [];
      let filter = response.questions.filter(x=>x.alternatives.length===1)
      for(let question of filter){
        for(let alternative of question.alternatives){
          if(alternative.alternative_type_id===5) comments.push(question)
        }
      }

      for(let comment of comments){
        if(response.answers[comment.id]){
          let text = response.answers[comment.id][0].sub_alternative.text;
          survey.comments.push(new CommentDetail(survey.id_review, text, comment.id));
        }
      }
      survey.waiting = false;
    })
  }

  getDetail($event, data){
    if(parseInt(this.productId)===3){
      this.getReview($event, data)
    }else{
      this.getComment($event, data);
    }
  }

  commentOrReview(){
    return parseInt(this.productId)===3 ? 'review' : 'comment';
  }

  createListData(){
    let key = this.getKeyData();
    this.listResults = key==='reviews' ? this.createListReviews() : this.createListSurveys();
  }

  createListReviews(){
    for(let review of this.result){
      let publicationDate = this.utilService.stringDateToDate(review["publication_date"]);
      let visit = new Visit(
        review["guest"]["username"],
        this.utilService.stringDateToDate(review["guest"]["stay_date"]),
        review["guest"]["country"],
        review["guest"]["language"]
      );
      let newReview = new Review(publicationDate,visit);
      newReview.createFromApi(review)
      this.listReviews.push(newReview);
    }
    return this.listReviews;
  }

  createListSurveys(){
    for(let data of this.result){
      let survey:Survey=new Survey();
      survey.createFromApi(data)
      this.listSurveys.push(survey);
    }
    return this.listSurveys;
  }


  showThisElement(data){
    if(this.filterSelected === 'all') return true;
    let info = data.comments===undefined ? data.allThemes : data.comments[0].allThemes;
    return info[this.sentiment].includes(this.filterSelected);
  }

  changeThemeFilter(filter){
    this.filterSelected = filter;
  }

  resetThemeFileter(){
    this.filterSelected = 'all';
  }

  toggleModal($event){
    this.utilService.setCurrentModal($event,'surveyModal')
    this.closeModal();
  }

}

@Component({
  selector: 'mh-semantic-categories-modal',
  template: ''
})

export class SemanticCategoriesModalComponent{

  @Output()
  showingSurvey: EventEmitter<boolean> = new EventEmitter();

  constructor(private modalService: NgbModal,  private utilService: UtilService){}


  showModal(element,key, productId,surveyId,hotelDto,customer){
    this.showingSurvey.emit(true);
    const modalRef = this.modalService.open(CategoryModal,{
      windowClass: 'modal-xl modal-tables'
    });
    modalRef.componentInstance.customerId = this.utilService.currentHotel.id;
    modalRef.componentInstance.dates      = {"startDate":this.utilService.startDate,"endDate":this.utilService.endDate}
    modalRef.componentInstance.area       = element;
    modalRef.componentInstance.filter     = key;
    modalRef.componentInstance.productId  = productId;
    modalRef.componentInstance.surveyId  = surveyId;
    modalRef.componentInstance.hotelDto  = hotelDto;
    modalRef.componentInstance.customer  = customer;
    modalRef.componentInstance.setModal(element,key)

    modalRef.result.then((result) => {
    }, (reason) => {
      this.showingSurvey.emit(false);
    });
  }


}

export interface AreaDetail{
  average:number;
  description:string;
  list_areas:SemanticDetail[];
  sentiment_score:number;
}
