<ng-container *ngIf="type === 'button'; else inputType">
  <button
    class="tw-rounded-full tw-w-12 tw-h-12 tw-bg-white tw-text-main tw-shadow-1 tw-flex tw-place-content-center tw-flex-wrap focus:tw-outline-0"
    (click)="isOpen = !isOpen" type="button" cdkOverlayOrigin #trigger="cdkOverlayOrigin"
    [class.!tw-bg-main]="isOpen"
    [class.!tw-text-white]="isOpen"
    >
    {{ selectedLang | uppercase }}
  </button>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
  >
    <div class="tw-mt-2">
      <ul
        class="tw-content-around tw-justify-around tw-shadow-2 tw-rounded-xl tw-bg-fff tw-cursor-pointer tw-select-none tw-overflow-auto tw-max-h-64 tw-p-2 tw-pl-1"
      >
        <li class="tw-flex tw-flex-row tw-py-1 tw-text-main" *ngFor="let language of availableLanguages; trackBy:trackByLanguage">
          <button class="tw-w-full tw-px-5" (click)="selectLang(language)">{{ language | uppercase }}</button>
        </li>
      </ul>
    </div>
  </ng-template>
</ng-container>

<ng-template #inputType>
  <article class="tw-relative tw-w-18">
    <input class="tw-rounded-full" type="text" [value]="selectedLang | uppercase" [disabled]="true" cdkOverlayOrigin #triggerInput="cdkOverlayOrigin" />
    <i class="fas tw-absolute tw-right-4 tw-top-4" (click)="isOpen = !isOpen" [ngClass]="{'fa-chevron-down': !isOpen, 'fa-chevron-up': isOpen}"></i>
  </article>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="triggerInput"
    [cdkConnectedOverlayOpen]="isOpen"
  >
    <div class="tw-mt-2">
      <ul
        class="tw-content-around tw-justify-around tw-shadow-2 tw-rounded-xl tw-bg-fff tw-cursor-pointer tw-select-none tw-overflow-auto tw-max-h-64 tw-p-2 tw-pl-1"
      >
        <li class="tw-flex tw-flex-row tw-py-1 tw-text-main" *ngFor="let language of availableLanguages; trackBy:trackByLanguage">
          <button class="tw-w-full tw-px-5" (click)="selectLang(language)">{{ language | uppercase }}</button>
        </li>
      </ul>
    </div>
  </ng-template>
</ng-template>


