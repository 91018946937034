<mh-alerts-wrapper>

  <section class="flex flex-row justify-between">
    <div>
      <mh-core-lib-datepicker
        [currentDates]="currentDates"
        [currentFilter]="currentFilterDate"
        (datepickerUpdated)="handleDatepickerUpdated($event)">
      </mh-core-lib-datepicker>
    </div>
    <div>
      <mh-filter-date
          [startDate]="startDate"
          [endDate]="endDate"
          [reportActiveEmail]="true"
          [report]="fidelityPermisions.includes('interchain_executive_summary_report') ? 'chainOnlineExecutive' : null"
          [checkPermissions]="true"
          slug="corporative_online_ejecutive_report">
      </mh-filter-date>
    </div>
  </section>
  <mh-box-element [title]="('inter-chain.executive.title' | translate)">
    <mh-chain-executive
      [currentDates]="currentDates"
      [productId]="productId"
      [metrics]="filters"
      [chain]="chain"
      *ngIf="fidelityPermisions.includes('interchain_executive_summary_result')"></mh-chain-executive>
  </mh-box-element>

</mh-alerts-wrapper>
