import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from "../../../../environments/environment";
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';


@Injectable()
export class LogsService {

  constructor(private http: HttpClient) {}

  getAllCustomersLogs(params?: {[param: string]: string | string[]}): Observable<any[]> {
    const url = `${environment.customerPath}customers/audits`;
    return this.http.get(url, {params})
      .pipe(map(response => <any[]>response));
  }

  getAllUserLogs(params?: {[param: string]: string | string[]}): Observable<any[]> {
    const url = `${environment.customerPath}users/audits`;
    return this.http.get(url, {params})
      .pipe(map(response => <any[]>response));
  }
}
