<div class="modal-header text-center">
  <button type="button" class="close" aria-label="Close" (click)="modal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-wrapper p-2">
  <admin-configuration-otas *ngIf="!waiting" [competitor]="competitor" [otasConfigurations]="otasConfigurations"></admin-configuration-otas>
  <div class="text-center" *ngIf="message" [ngClass]="messageClass">
    {{(message | translate )}}
  </div>


</div>
