import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mh-core-lib-guest-widget',
  templateUrl: './core-lib-guest-widget.component.html',
  styleUrls: ['./core-lib-guest-widget.component.scss']
})
export class CoreLibGuestWidgetComponent implements OnInit {

  @Input()
  guest: any;

  @Input()
  comments: any = [];

  @Input()
  answer: any;

  @Input()
  currentLanguage = '';

  @Output()
  seeSurveyClicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  handleClick() {
    this.seeSurveyClicked.emit();
  }

  ngOnChanges(changes) {
  }

  get commentDate() {
    return this.answer.date || this.answer.sent_date;
  }

  get countryFlag() {
    return this.guest.country.code?.toLowerCase() || '';
  }

  get countryName() {
    return this.guest.country.name;
  }

  get areCountry() {
    return this.countryFlag !== 'xx';
  }


}
