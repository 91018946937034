import {Component, OnInit} from '@angular/core';
import {UtilService}    from "../../../../shared/util.service";
import {CoverageService} from '../interchain-online-coverage.service';
import { Hotel, Period, FilterDate } from '../../../../shared/model/index';
import {ViewChild} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, forkJoin } from 'rxjs';
import { AdminCustomerService } from 'app/admin/customers/admin-customer.service';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CustomersState } from 'app/states/customers/customers.state';
import { DateService } from 'app/shared/services/date.service';

@Component({
  selector: 'mh-interchain-coverage-hotel',
  templateUrl: './interchain-online-coverage-hotel.component.html',
  styleUrls: ['./interchain-online-coverage-hotel.component.scss'],
  providers:[CoverageService, AdminCustomerService]
})

export class InterchainOnlineCoverageHotel implements OnInit{

  @Select(CustomersState.relativeCurrentChain) customersReady$: Observable<any[]>;

  chain: Hotel[] = [];
  chainActives:Hotel[] = [];
  isReady = false;
  waiting = true;
  activesOtas = [];

  fidelityPermisions:string[] = [];
  productId = 3;
  data = {values: [], average: []}
  totals = {};
  dateSubscribe: Subscription;
  customersStateSubscribe: Subscription;

  headerTable: any[] = []
  dataTable: any[] = [];
  dataSource: any = new MatTableDataSource([]);
  prefixTranslateFAQ = 'online.coverage.by_hotel.faq';
  faqs = (new Array(2).fill(0));
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private utilService:UtilService,
    private adminCustomerService: AdminCustomerService,
    private coverageService:CoverageService,
    private store: Store,
    private dateService: DateService
  ){
    this.customersStateSubscribe = this.customersReady$.subscribe(customers => this.init());
  }

  ngOnInit(){
    this.fidelityPermisions = this.utilService.getFidelityConfigurationsByProduct('interchain', 'interchain_online');
    this.chain = this.utilService.getChain('online');
    this.chainActives = this.chain;
  }

  reset(){
    this.headerTable = [
      'customer',
      'total_coverage',
      'detractors_coverage',
      'passives_coverage',
      'promoters_coverage'
    ];
    this.totals = {};
    this.dataTable = [];
    this.dataSource = new MatTableDataSource([])
  }

  init(){
    if ( this.startDate && this.endDate ) {

      const date = new FilterDate(this.startDate, this.endDate);
      const customers = this.store.selectSnapshot(state => state.customers.relativeCurrentChain).map(c => c.id).join(',');
      this.waiting = true;

      if (customers.length) {
        forkJoin([
          this.adminCustomerService.getOtas(this.utilService.currentHotel.id),
          this.coverageService.getPerCustomers(date,customers)
        ]).subscribe((result:any)=>{
          this.activesOtas = result[0].filter(x=>x.active);
          this.isReady     = true;
          this.waiting     = false;
        this.data        = result[1];
        this.createTable();
        })
      }
    }
  }

  ngOnDestroy(){
    this.customersStateSubscribe.unsubscribe();
  }

  createTable(){
    this.reset();
    this.chainActives.forEach(customer=>{
      const data = this.data.values.find((x: any) => x.customer_id === customer.id);
      const cell = this.getData(customer,data);
      this.dataTable.push(cell);
    })

    this.headerTable.forEach(key=> {
      if(key!=='customer') {
        this.totals[key] = this.getTotal(key);
      }
    });

    this.activesOtas.forEach((ota: any) =>{
      const otaData    = this.data.average['otas_details'].find((x: any) =>x.ota_id===ota.id)
      const key        = `ota_${ota.id}`;
      const total      = otaData && otaData.total_coverage ? otaData.total_coverage : new Period();
      this.totals[key] = total;
      this.headerTable.push(key)
    })

    this.dataSource = new MatTableDataSource(this.dataTable);
  }

  getData(customer,data){
    const cell = {};
    this.headerTable.forEach(key=>{
      if(key==='customer'){
        cell[key] = customer;
      }else{
        cell[key] = data && data[key] ? data[key] : new Period();
      }
    });
    this.activesOtas.forEach((ota: any)=>{
      const otaData         = data.otas_details.find((x: any)=>x.ota_id===ota.id)
      cell[`ota_${ota.id}`] = otaData && otaData['total_coverage'] ? otaData['total_coverage'] : new Period();
    })
    return cell;
  }

  getTotal(key){
    return this.data.average[key] ? this.data.average[key] : new Period();
  }


  setChain(chain){
    this.chainActives = chain;
    const currentIds = this.chain.map(x=>x.id);
    if(chain.length>0 && !currentIds.includes(chain[0].id) ){
      this.chain = chain;
    }else{
      this.createTable();
    }
  }

  dataIsReady(state){
    setTimeout(_ => {
      this.isReady = state;
    },200);
  }

  filterCustomers($event){
    this.chainActives = this.chain.filter(x=>$event.includes(x.id));
    this.createTable();
  }

  handleDatepickerUpdated($event) {
    this.dateService.setCurrentFilter($event.currentFilter);
    this.dateService.setCurrentDates($event.currentDates)
    this.init();
  }

  get currentDates() {
    return this.dateService.currentDates;
  }

  get currentFilterDate() {
    return this.dateService.currentFilter;
  }

  get startDate() {
    return this.dateService.currentDates.startDate;
  }

  get endDate() {
    return this.dateService.currentDates.endDate;
  }

  get titleTranslatePath(): string {
    return `inter-chain.online.coverage.customer.title${this.suffix}`;
  }

  get suffix(): string {
    return (this.isRetail && '_retail') ||
      (this.isClinic && '_clinic') ||
      '';
  }
  get isRetail() { return this.utilService.customerIsRetail; }
  get isClinic() { return this.utilService.customerIsClinic; }
}
