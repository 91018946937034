import { Component, OnInit } from '@angular/core';

import { AdminCustomerService } from 'app/admin/customers/admin-customer.service';
import { UtilService } from 'app/layouts/main';
import { OtasErrors } from '../model/admin/ota.model';
import { MANUAL_GOOGLE_FB } from 'app/shared/utils/statics/manual-links.static'

@Component({
  selector: 'mh-otas-errors',
  templateUrl: './otas-errors.component.html',
  styleUrls: ['./otas-errors.component.scss'],
  providers: [AdminCustomerService]
})
export class OtasErrorsComponent implements OnInit {

  otasErrors: OtasErrors[] | null
  showOtasErrors: boolean

  constructor(
    private adminCustomerService: AdminCustomerService,
    private utilService: UtilService,
  ) {
    this.utilService.isCustomerChanged().subscribe(_=> {
      this.loadOtasErrors()
    })
   }

  get currentCustomer() {
    return this.utilService.getCurrentCustomer()
  }

  get currentLanguage() {
    return this.utilService.getCurrentLanguage()
  }

  get currentUser() {
    return this.utilService.getCurrentUser()
  }

  get manualLink() {
    return MANUAL_GOOGLE_FB.find(m => m.language === this.currentLanguage)?.link
  }

  ngOnInit() {
    this.loadOtasErrors()
  }

  private async loadOtasErrors() {
    this.otasErrors = null
    const response = await this.adminCustomerService.getOtasErrors(this.currentCustomer.id).toPromise()
    if(response) {
      this.showOtasErrors = response.some(o => o.error.public_error)
      if((this.showOtasErrors && this.currentUser.admin) || this.currentUser.superAdmin) {
        this.otasErrors = response
      }
    }
  }

  getTranslateTypeError(ota: OtasErrors) {
    return `admin.otas.error.${ota.error.public_error ? 'public' : 'internal'}`
  }

}
