import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { animation } from 'app/shared/utils/animation-custom';

enum CONNOTATION {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  NEUTRAL = 'neutral',
}

@Component({
  selector: 'mh-core-lib-semantic-box',
  templateUrl: './core-lib-semantic-box.component.html',
  styleUrls: ['./core-lib-semantic-box.component.scss'],
  animations: [animation.heightExpand],
})
export class CoreLibSemanticBoxComponent implements OnInit {
  @Input()
  review;

  @Input()
  language = 'es';

  @Input()
  languageId;

  @Input()
  getProductByIdFn;

  @Input()
  translateFn = null;

  @Output()
  updatedPolarity = new EventEmitter();

  collapsed = true;
  edit = false;
  changes = [];
  reviewToDisplay = null;

  translatedTitle = null;
  translatedDescription = null;
  translateWaiting = false;

  rawAreas;

  ngOnInit() {
    this.reviewToDisplay = JSON.parse(JSON.stringify(this.review));
    this.rawAreas = this.review.themes.reduce((prev, curr) => {
      const currArea = curr;
      curr.sub_types.forEach((area) => {
        const findArea = prev.find((areaSaved) => areaSaved.name === area.name);
        if (findArea) {
          if (currArea.polarity === 'positive') {
            findArea.positive += 1;
          } else {
            findArea.negative += 1;
          }
        } else {
          let areaObj = {
            area,
            polarity: currArea.polarity,
            name: area.name,
            negative: 0,
            positive: 0,
          };
          if (currArea.polarity === 'positive') {
            areaObj = {
              ...areaObj,
              positive: 1,
            };
          } else {
            areaObj = {
              ...areaObj,
              negative: 1,
            };
          }
          prev.push(areaObj);
        }
      });
      return [...prev];
    }, []);
  }

  toggleDetails() {
    this.collapsed = !this.collapsed;
  }

  toggleEdit() {
    if (this.edit && this.changes?.length > 0) {
      this.updatedPolarity.emit({
        changes: this.changes,
        comment_id: this.review.comment_id,
      });
    }
    this.edit = !this.edit;
  }

  handleUpdatePolarity($event, themeIndex, deletePolarity = false) {
    const changeIndexFound = this.changes.findIndex(
      (change) => change.ranking_object_id === $event.semantic_ranking_object_id,
    );
    let polarity;
    if (changeIndexFound >= 0) {
      const change = this.changes[changeIndexFound];
      polarity = this.getPolarity(change, deletePolarity);
      this.changes[changeIndexFound].polarity = polarity;
      this.reviewToDisplay.themes[themeIndex].polarity = polarity;
    } else {
      polarity = this.getPolarity($event, deletePolarity);
      const event = {
        ranking_object_id: $event.semantic_ranking_object_id,
        polarity,
      };
      this.changes.push(event);
      this.reviewToDisplay.themes[themeIndex].polarity = polarity;
    }

    const areasToChange = $event.sub_types.map((area) => area.name);
    areasToChange.forEach((item) => {
      const area = this.rawAreas.find((checkArea) => checkArea.name === item);
      if (polarity === CONNOTATION.POSITIVE) {
        area.positive += 1;
        area.negative -= 1;
      } else {
        area.positive -= 1;
        area.negative += 1;
      }
    });
  }

  getPolarity($event, deletePolarity) {
    return deletePolarity
      ? CONNOTATION.NEUTRAL
      : $event.polarity === CONNOTATION.POSITIVE
      ? CONNOTATION.NEGATIVE
      : CONNOTATION.POSITIVE;
  }

  handleTranslateComment() {
    this.translateWaiting = true;
    this.translateFn(this.review).subscribe((response) => {
      if (response) {
        this.translatedTitle = response.title;
        this.translatedDescription = response.description;
      }
      this.translateWaiting = false;
    });
  }

  get positiveThemeCount() {
    return this.reviewToDisplay.themes.filter((theme) => theme.polarity === CONNOTATION.POSITIVE).length;
  }

  get negativeThemeCount() {
    return this.reviewToDisplay.themes.filter((theme) => theme.polarity === CONNOTATION.NEGATIVE).length;
  }

  get areas() {
    return this.rawAreas;
  }
}
