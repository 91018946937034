import { Component, OnInit, Input } from '@angular/core';
import { SurveyCommentsService } from './survey-comments.service';
import { UtilService } from 'app/shared/util.service';
import { ResponseComment } from './survey-guest-comment/survey-guest-comment.component';

@Component({
  selector: 'mh-survey-comments-box',
  templateUrl: './survey-comments.component.html',
  styleUrls: ['./survey-comments.component.scss'],
  providers: [SurveyCommentsService]
})
export class SurveyCommentsComponent implements OnInit {

  @Input()
  showAll = false;

  @Input()
  surveyId: number | null = null;

  @Input()
  survey;

  @Input()
  productId: number;

  @Input()
  questionId: number | null = null;

  waiting = false;
  comments: ResponseComment[] = [];
  firstCall = false;


  constructor(
    private utilService: UtilService,
    private surveyCommentsService: SurveyCommentsService
  ) { }

  ngOnInit() {}

  toggleShowComments(){
    this.showAll = !this.showAll;
    if(this.comments.length === 0 && !this.waiting && this.surveyId && this.questionId) this.loadComments()
  }

  loadComments(){
    const dates = this.utilService.getCurrentDates();
    this.waiting = true;
    this.comments = [];
    this.surveyCommentsService.getComments(dates, this.utilService.currentHotel.id, this.surveyId, this.questionId).subscribe((response:any)=>{
      this.comments  = response;
      this.waiting   = false;
      this.firstCall = true;
    })
  }

  get isEmpty(){
    return (this.comments === null || this.comments === undefined) || (this.comments.length === 0 && this.firstCall);
  }

}
