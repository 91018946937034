import { Pipe, PipeTransform } from '@angular/core'

import { SemanticComment } from 'app/shared/model/semantic-comment.model';
import { SemanticCommentFilter } from 'app/states/semantic/semantic.state'

@Pipe({ name: 'semanticThemeFilter' })
export class SemanticThemeFilterPipe implements PipeTransform {

  transform(value: SemanticComment[], filters: SemanticCommentFilter): SemanticComment[] {
    if(filters.themes?.length > 0) {
      return value.filter((comment) =>
        comment.themes.some(themeInput => filters.themes.some(theme => theme.name.toLowerCase() === themeInput.name.toLowerCase()))
      );
    } else
      return value
  }
}
