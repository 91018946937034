<p *ngIf="survey.type" class="mt-4">
  Encuesta: <strong>{{survey.type}}</strong> ({{survey.surveyId}})
  <span *ngIf="showStatusChildren"> - Estado: <strong [ngClass]="processStatusClass(survey.status)">{{survey.status}}</strong></span>
</p>
<table class="table table-bordered table-sm">
  <thead class="thead-light">
    <tr>
      <th class="text-center">Estado</th>
      <th>Mensaje</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of survey.messages">
      <td class="text-center" [ngClass]="processStatusClass(item.status)">
        {{item.status}}
      </td>
      <td>{{item.text}}</td>
    </tr>
  </tbody>
</table>
