import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'fidelityDeskStage',
})
export class FidelityDeskStagePipe implements PipeTransform {
  transform(cases, filters) {
    let filteredCases = cases;
    if (filters) {
      const filter = filters.find((filterState) => filterState.name === 'stage');
      const filtering = filter.options.some((option) => option.isFiltering);
      if (filter && filtering) {
        const options = filter.options
          .filter((option) => option.isFiltering)
          .map((option) => option.subcategory_state_generic);
        filteredCases = cases.filter((item) => options.includes(item.time_management.subcategory_state_generic));
      }
    }
    return filteredCases;
  }
}
