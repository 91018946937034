import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { animation } from 'app/shared/utils/animation-custom';

@Component({
  selector: 'mh-origin-selector',
  templateUrl: './origin-selector.component.html',
  animations: [
    animation.rotate,
  ],
})
export class OriginSelectorComponent implements OnInit, OnChanges {
  
  @Input()
  origins;

  @Output()
  selectedEmitter = new EventEmitter();

  value;
  isOpen = false;

  originList = [];

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target) && !event.target.hasAttribute('type') && event.target.tagName.toLowerCase() !== 'p' && event.target.tagName.toLowerCase() !== 'li') {
      this.isOpen = false;
    }
  }

  constructor(
    private eRef: ElementRef,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.origins?.currentValue) {
      this.originList = [
        { value: this.translateService.instant('commons.all'), id: 0, checked: true},
        ...changes.origins.currentValue.map((origin) => ({ value: origin.origin_name, id: origin.origin_id, checked: true})),
      ];
    }
  }

  trackByOrigin(index, item) {
    return item.id;
  }

  activate(originId) {
    if (originId === 0) {
      this.originList[0].checked = !this.originList[0].checked;
      this.originList = this.originList.map((origin) => ({...origin, checked: this.originList[0].checked}));
    } else {
      const originSelected = this.originList.find((origin) => origin.id === originId);
      originSelected.checked = !originSelected.checked;
  
      if (this.originList.filter((origin) => origin.id !== 0 && origin.checked).length) {
        this.originList[0].checked = false;
      }
    }
    this.selectedEmitter.next(this.originList.filter((origin)=> origin.checked).map((origin)=> origin.id).join(','));
  }
}
