import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mh-core-lib-widget-question-qualification',
  templateUrl: './widget-question-qualification.component.html',
  styleUrls: ['./widget-question-qualification.component.scss']
})
export class CoreLibWidgetQuestionQualificationComponent implements OnInit {

  @Input()
  detail: any;

  constructor() { }

  ngOnInit(): void {
  }

}
