import { Component, OnInit } from '@angular/core';
import { PubNubAngular } from 'pubnub-angular2';
import { SocketService } from './socket.service';
import { environment } from '../../../environments/environment';
import { AdminAlertsService } from 'app/admin/alerts/admin-alerts.service';
import { SessionService } from 'app/_services/session.service';
import { UtilService } from '../util.service';
import { Subscription } from 'rxjs';



interface Message{
  type: 'ACTION' | 'NOTIFICATION';
  notification: string;
  action: 'sync-alerts';
  timestamp: string;
}

@Component({
  selector: 'mh-socket',
  template: '',
  providers: [SocketService, PubNubAngular]
})

export class SocketComponent implements OnInit {

  customerSubscribe: Subscription;

  pubnub: PubNubAngular;
  pubNubChannels: string[] = [];
  message:Message;

  constructor(
    private pubnubAngular: PubNubAngular,
    private socketService: SocketService,
    private adminAlertsService: AdminAlertsService,
    private utilService: UtilService,
    private sessionService: SessionService
  ) {
    this.pubnub = this.pubnubAngular;
    this.pubnub.init({
      publishKey: `${environment.pubnubPublishKey}`,
      subscribeKey: `${environment.pubnubSuscribeKey}`
    });
    this.customerSubscribe = this.utilService.isCustomerChanged().subscribe(_ => {
      this.pubnubService();
    });
  }

  ngOnInit() {
    this.pubnubService();
  }

  ngOnDestroy() {
    this.customerSubscribe.unsubscribe();
  }


  pubnubService() {
    this.socketService.getChannels('customer', this.currentHotel.id).subscribe((channel:any) => {
      if(channel) {
        this.pubNubChannels.push(channel.channel_id);
        this.pubnubInit();
      }
    });
  }

  pubnubInit() {
    this.pubNubSubscribe();
    this.messageHandler();
  }

  pubNubSubscribe() {
    this.pubnub.subscribe({
      channels  : this.pubNubChannels,
      withPresence: true,
      triggerEvents: true
    });
  }

  messageHandler() {
    this.pubnub.getMessage(this.pubNubChannels, response => {
      this.message = response.message;
      if(this.message) {
        if(this.message.type === 'ACTION') {
          this.actionHandler();
        } else {
          this.notificationHandler();
        }
      }
    });
  }

  actionHandler() {
    switch(this.message.action) {
      case 'sync-alerts':
        this.syncAlerts();
        break;
    }
  }

  notificationHandler() {

  }

  private syncAlerts() {
    this.adminAlertsService.refreshAlerts();
    this.adminAlertsService.getAlert(this.currentHotel.id, this.utilService.getLanguageId()).subscribe((response:any) => {
      this.sessionService.setResponseAlert(response);
    });
  }

  get currentHotel() {
    return this.utilService.currentHotel;
  }
}
