import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class OnlineSummaryService {

  constructor(private http:HttpClient) { }

  public getSummary(dates:any,customerIds:string){
    const url=`${environment.onlinePath}interchains/main/summary`;
    const params:HttpParams= new HttpParams()
      .set("customer_ids",customerIds)
      .set("start_date",dates.startDate)
      .set("end_date",dates.endDate);
    return this.http.get(url, {params: params})
      .pipe(
        map(response=>response)
      )
  }
}
