// This file includes polyfills needed by Angular 2 and is loaded before
// the app. You can add your own extra polyfills to this file.
/** IE9, IE10 and IE11 requires all of the following polyfills. **/

// import 'core-js/es6/symbol';
// import 'core-js/es6/object';
// import 'core-js/es6/function';
// import 'core-js/es6/parse-int';
// import 'core-js/es6/parse-float';
// import 'core-js/es6/number';
// import 'core-js/es6/math';
// import 'core-js/es6/string';
// import 'core-js/es6/date';
// import 'core-js/es6/array';
// import 'core-js/es6/regexp';
// import 'core-js/es6/map';
// import 'core-js/es6/set';
// import 'core-js/es6/reflect';

// import 'core-js/es7/reflect';
// import 'zone.js';

// import 'core-js/es7/array';

import 'reflect-metadata';
import '@angular/localize/init'
import 'zone.js';  // Included with Angular CLI.
