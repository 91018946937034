<div class="tw-flex tw-flex-col">
  <div class="tw-flex tw-justify-center tw-my-5">
    <i class="fas fa-check-circle fa-3x tw-text-main-green-success"></i>
  </div>

  <div class="tw-flex tw-justify-center tw-font-medium">
    <h3 class="tw-text-modal-title tw-text-3sm">{{ 'user_management.create_user.user_created.title' | translate }}</h3>
  </div>

  <div class="tw-my-2 tw-flex tw-justify-center">
    <div class="tw-flex tw-flex-col tw-text-center tw-w-full">
      <p class="tw-text-modal-title tw-text-3sm tw-font-bold">{{ data.first_name + ' ' + data.last_name }}</p>
      <p class="tw-text-modal-title tw-text-3sm tw-font-bold">{{ data.email }}</p>
      <div class="tw-flex tw-flex-row tw-justify-center tw-w-full {{ !data?.new_password ? 'tw-hidden' : '' }}">
        <p class="tw-text-modal-title tw-text-3sm tw-font-bold ">{{ showPassword ? data?.new_password || '' : passwordMask }}</p>
        <i class="tw-mx-3 fas fa-eye{{ showPassword ? '-slash' : '' }} tw-py-1" (click)="showPassword = !showPassword"></i>
      </div>
    </div>
  </div>

  <div class="tw-my-10 tw-flex tw-flex-col tw-w-full">
    <div class="tw-flex tw-flex-row tw-relative tw-my-2 tw-gap-4 tw-justify-center">
      <p class="tw-text-modal-title tw-text-3sm">{{ 'user_management.create_user.user_created.welcome_mail' | translate }}</p>
      <input type="checkbox" class="tw-border-2 tw-rounded-sm tw-mt-1" [(ngModel)]="checkboxes[0]"/>
    </div>
    <div class="tw-flex tw-flex-row tw-relative tw-gap-5 tw-justify-center">
      <p class="tw-text-modal-title tw-text-3sm">{{ 'user_management.create_user.user_created.request_password_change' | translate }}</p>
      <input type="checkbox" class="tw-border-2 tw-rounded-sm tw-mt-1" [(ngModel)]="checkboxes[1]"/>
    </div>

  </div>

  <div class="tw-flex tw-justify-center">
    <mh-core-lib-button [isDefault]="true" [text]="('user_management.create_user.user_created.finish' | translate)" (clicked)="confirm()"></mh-core-lib-button>
  </div>
</div>
