import { RankingThemeArea, SemanticComment } from '../../shared/model/semantic-comment.model';
import { Survey } from 'app/survey-public/models';

export namespace Semantic {
  export class StoreCommentList {
    static readonly type = '[Semantic] Store Comment List'
    constructor(public comments: SemanticComment[]) { }
  }

  export class CommentListClear {
    static readonly type = '[Semantic] Comment List Clear'
  }

  export class ChangeSurveyFilter {
    static readonly type = '[Semantic] Change Survey Filter'
    constructor(public surveys: Survey[]) { }
  }

  export class ChangeProductsFilter {
    static readonly type = '[Semantic] Change Products Filter'
    constructor(public products: number[]) { }
  }

  export class ChangeNotationsFilter {
    static readonly type = '[Semantic] Change Notations Filter'
    constructor(public notations: {positive: boolean, negative: boolean}) { }
  }

  export class ChangeThemeFilter {
    static readonly type = '[Semantic] Change Themes Filter'
    constructor(public themes: RankingThemeArea[]) { }
  }

  export class ChangeAreaFilter {
    static readonly type = '[Semantic] Change Areas Filter'
    constructor(public areas: RankingThemeArea[]) { }
  }

  export class FilterClear {
    static readonly type = '[Semantic] Filter Clear'
  }

  export class StoreAreasUniqRanking {
    static readonly type = '[Semantic] Store AreasUniq Ranking'
    constructor(public areas: RankingThemeArea[]) { }
  }

  export class StoreAreasPositivesRanking {
    static readonly type = '[Semantic] Store AreasPositives Ranking'
    constructor(public areas: RankingThemeArea[]) { }
  }

  export class StoreAreasNegativesRanking {
    static readonly type = '[Semantic] Store AreasNegatives Ranking'
    constructor(public areas: RankingThemeArea[]) { }
  }

  export class StoreThemesUniqRanking {
    static readonly type = '[Semantic] Store ThemesUniq Ranking'
    constructor(public themes: RankingThemeArea[]) { }
  }

  export class StoreThemesPositivesRanking {
    static readonly type = '[Semantic] Store ThemesPositives Ranking'
    constructor(public themes: RankingThemeArea[]) { }
  }

  export class StoreThemesNegativesRanking {
    static readonly type = '[Semantic] Store ThemesNegatives Ranking'
    constructor(public themes: RankingThemeArea[]) { }
  }
}
