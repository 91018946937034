import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'fidelityDeskStatusFilter'
})
export class FidelityDeskStatusFilter implements PipeTransform {
  transform(cases, filters) {
    let filteredCases = cases;
    if(filters) {
      const filter = filters.find(filter => filter.name === 'state');
      const filtering = filter.options.some(option => option.isFiltering);
      if(filter && filtering) {
        const options = filter.options.filter(option => option.isFiltering).map(option => option.id);
        filteredCases = cases.filter(item => options.includes(item.state.id))
        if ( options.includes(5) ) {
          const stagnantCases = cases.filter(item => item.stagnant);
          filteredCases = filteredCases.concat(stagnantCases);
        }
      }
    }
    return filteredCases;
  }
}
