import { UtilService } from 'app/shared/util.service';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'mh-footer-total-label',
  templateUrl: './footer-total-label.component.html',
  styleUrls: ['../../../../../assets/scss/shared/elements/footer-total-label.scss'],
})
export class FooterTotalLabelComponent{

  @Input()
  actualValue:any;

  @Input()
  numberType = '';

  @Input()
  previousValue:any;

  @Input()
  style: 'normal' | 'light' = 'normal';


  type = 'up';
  type_number = 'previous';
  variation;

  constructor(private utilService: UtilService){}

  ngOnChanges() {
    this.actualValue   = this.castValue(this.actualValue);
    this.previousValue = this.castValue(this.previousValue);
    const typeVariation = this.numberType=== 'nps' ? 'nps' : 'percentage' ;
    this.variation = this.utilService.getVaration(this.actualValue, this.previousValue,typeVariation);
    this.getType();
  }

  castValue(value){
   return ['nps','pms','percentage','email_rate','order_rate'].includes(this.numberType) ? parseInt(value) : value;
  }

  getType(){
    if(this.actualValue > this.previousValue){
      this.type = 'up';
    }else{
      this.type = 'down'
    }
    if(this.actualValue===this.previousValue) this.type = 'equals';
  }

  formatNumber(value){
    if(['percentage','pms','email_rate','order_rate'].includes(this.numberType)) return this.utilService.formatPercent(value);
    if(['average'].includes(this.numberType)) return this.utilService.formatNumber(value,'average');
    if(this.numberType==='nps') return this.utilService.formatNPS(value);
    return value;
  }

}

