<mh-alerts-wrapper>

  <section class="flex flex-row justify-between">
    <div>
      <mh-core-lib-datepicker
          [currentDates]="currentDates"
          [currentFilter]="currentFilterDate"
          (datepickerUpdated)="handleDatepickerUpdated($event)">
      </mh-core-lib-datepicker>
    </div>
    <div>
      <mh-filter-date
          [startDate]="startDate"
          [endDate]="endDate"
          [report]="fidelityPermisions.includes('interchain_online_coverage_ranking_report') ? 'chainOnlineCoverageRatings' : null "
          [reportActiveEmail]="true"
          [checkPermissions]="true"
          slug="corporative_online_coverage_ratings_report">
      </mh-filter-date>
    </div>
  </section>
  <mh-box-element [title]="('inter-chain.online.coverage.customer.title' | translate )" *ngIf="fidelityPermisions.includes('interchain_online_coverage_ranking_result')">

    <div class="row align-items-center">
      <div class="col align-right">
        <widget-modal-faq
        [faqs]="faqs"
        [prefixTranslate]="prefixTranslateFAQ"
        >
        </widget-modal-faq>
      </div>
    </div>

    <mh-spinner-classic [hidden]="!waiting"></mh-spinner-classic>

    <ng-container *ngIf="!waiting">
      <widgets-table-periods
        [dataSource]="dataSource"
        [headerTable]="headerTable"
        [totals]="totals"
        [prefixTranslate]="'inter-chain.online.coverage.ranking.table.labels'"
      ></widgets-table-periods>
    </ng-container>

  </mh-box-element>

</mh-alerts-wrapper>
