export class FilterDate{
  startDate: string;
  endDate: string;

  constructor(startDate?, endDate?)
  constructor(startDate, endDate){
    this.startDate = startDate;
    this.endDate = endDate;
  }

}
