<div class="container-fluid p-5">
  <div class="row justify-content-center">
    <div class="col">
      <div class="d-flex justify-content-center">
        <img src="assets/images/myhotel_logo.png">
      </div>
    </div>
  </div>
  <div class="row mt-5 justify-content-center" *ngIf="!waiting else spinner">
    <div class="col" *ngIf="fileExist else doesntExists">
      <div class="d-flex justify-content-center">
        <a class="btn btn-orange btn-icon-right" [href]="urlToDownload">Download <i class="fal fa-download"></i></a>
      </div>
    </div>

  </div>
</div>

<ng-template #spinner>
  <mh-spinner-classic></mh-spinner-classic>
</ng-template>

<ng-template #doesntExists>
  <div class="tw-flex tw-flex-col tw-justify-center tw-gap-10 tw-mt-8">
    <h1 class="tw-text-xl">{{ 'reports.downloads.doesnt_exists' | translate }}</h1>
    <mh-core-lib-button
      [text]="'commons.go_to_myhotel' | translate"
      [isDefault]="true"
      (click)="goToMyHotel()"
    ></mh-core-lib-button>
  </div>
</ng-template>
