import { Component, OnInit, Input } from '@angular/core';
import { FollowupPropertiesService } from '../../properties/followup-properties.service';
import { FilterDate } from 'app/shared/model/filters/filter-date.model';
import { UtilService } from 'app/shared/util.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { Hotel, Period } from 'app/shared/model';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CustomersState } from 'app/states/customers/customers.state';

export interface RateResponse{
  answered: Period;
  rate: Period;
  email_surveys: SurveyType;
  open_surveys: SurveyType;
}

export interface SurveyType {
  active: boolean;
  answered: Period;
  opened: Period;
  rate: Period;
  sent: Period;
}

@Component({
  selector: 'mh-chain-followup-response-rate',
  templateUrl: './chain-followup-response-rate.component.html',
  styleUrls: ['./chain-followup-response-rate.component.scss'],
  providers: [FollowupPropertiesService]
})
export class ChainFollowupResponseRateComponent implements OnInit {

  @Select(CustomersState.relativeCurrentChain) customersReady$: Observable<any[]>;

  @Input()
  productId = 1;

  @Input()
  chain: Hotel[] = [];

  @Input()
  currentDates = null;

  waiting = true;
  data: RateResponse;

  firstCall = true;
  subscriptions: Subscription[] = [];

  metrics: string[] = ['rate', 'email_surveys', 'open_surveys'];
  submetrics: string[] = ['rate', 'active', 'sent', 'opened', 'answered'];

  translate = 'followup.summary.summary-survey.';

  currentTooltip = '';
  paramsTooltip;

  constructor(
    private utilService: UtilService,
    private followupPropertiesService: FollowupPropertiesService,
    private store: Store
  ) {}

  ngOnDestroy() {
    this.subscriptions.forEach((subs) => subs.unsubscribe());
  }

  ngOnInit() {

    this.subscriptions.push(this.utilService.isCurrentSurveyChanged().subscribe(() => {
      this.init();
    }));

    this.subscriptions.push(this.customersReady$.subscribe(customers => this.init()));
  }

  ngOnChanges({currentDates}) {
    if ( currentDates ) {
      this.init();
    }
  }

  init(){
    this.waiting = true;
    const date      = new FilterDate(this.currentDates.startDate, this.currentDates.endDate);
    const survey    = this.utilService.getCurrentSurvey(this.chain.map(x=>parseInt(x.id)));
    const surveyIds = survey.ids;
    const version = survey.survey[0].version;
    const customers = this.chain ?this.chain.map((c) => c.id).join(',') : this.store.selectSnapshot(CustomersState.relativeCurrentChain).map((c) => c.id).join(',');

    if (customers.length) {
      this.followupPropertiesService.getRate(date, customers,this.productId, surveyIds,false,version)
      .subscribe((response:any)=>{
        this.data = response.average;
        this.waiting = false;
        this.firstCall = false;
      });
    }
  }

  setTooltip(key) {
    this.currentTooltip = key;
    this.paramsTooltip = (key === 'rate') ?
    {
      eAns: this.data.email_surveys.answered.actual,
      eSen: this.data.email_surveys.sent.actual,
      sAns: this.data.open_surveys.answered.actual,
      sOpe: this.data.open_surveys.opened.actual,
    } : null;
  }

  get tooltipLabel() {
    return this.currentTooltip;
  }

  get formulaRate(){
    return this.data ? `(${this.data.answered.actual}/${this.data.email_surveys.sent.actual})` : null;
  }

  get metrictsWithoutRate() {
    return this.metrics.filter((metric) => metric !== 'rate');
  }

  get subMetrictsWithoutRate() {
    return this.submetrics.filter((metric) => !['rate', 'active'].includes(metric));
  }
}
