import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

enum Option {
  All,
}

@Component({
  selector: 'mh-core-lib-tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss'],
})
export class CoreLibTagInputComponent implements OnInit, OnDestroy {
  allOption = Option.All;

  chips: any[] = [];

  optionsCopy;
  originalOptions;
  inputControl = new FormControl('');
  subscription: Subscription;
  optionsReady = false;

  @Input()
  options: any[];

  @Input()
  selectedOptions: any[];

  @Input()
  displayChipsFn;

  @Input()
  displayChipsListFn;

  @Input()
  displayCheckInput = true;

  @Input()
  allSelected = true;

  @Input()
  checkedAllOption = true;

  @Input()
  showAutocompleteInput = false;

  @Input()
  inputTagFilter;

  @Input()
  boxOpen = true;

  @Input()
  placeholder = '';

  @Input()
  parentCustomClass = '';

  @Input()
  menuCustomClass = '';

  @Input()
  idType = 'id';

  @Input()
  filterType = 'name';

  @Input()
  hasAutocompleteFilter = false;

  @Input()
  dynamicFilterType = false;

  @Input()
  inputSelectedOptions = [];

  @Input()
  showCloseBtn = true;

  @Input()
  customClasses;

  @Input()
  showCloseBtnFn = () => true;

  @Output()
  optionClicked = new EventEmitter();

  @ViewChild('inputTag')
  inputTag: ElementRef;

  @HostListener('document:click', ['$event'])
  onClick(event) {
    const result = this.checkClickInside(event.target);
    if (!result) {
      this.boxOpen = false;
    }
  }

  ngOnInit(): void {
    this.setInputFilter();
    if (!this.optionsReady) {
      this.setOptions(this.allSelected);
    }
  }

  ngOnChanges(changes) {
    if (changes && changes.options) {
      this.originalOptions = [...this.options];
      if (this.originalOptions && (!this.inputTagFilter || this.hasAutocompleteFilter)) {
        this.setOptions(this.allSelected);
        if (this.allSelected) {
          this.setChips(this.allSelected);
        }
        if (this.selectedOptions && this.selectedOptions.length > 0) {
          this.setSelectedOptions();
        }
      }
    }
    if (changes.inputSelectedOptions && changes?.inputSelectedOptions?.currentValue?.length > 0) {
      this.chips = [];
      changes.inputSelectedOptions.currentValue.forEach((option) => {
        this.setOptions(this.allSelected);
        this.handleClick(option, false, false);
      });
    }
  }

  setInputFilter() {
    if (this.inputTagFilter) {
      this.subscription = this.inputControl.valueChanges.subscribe((value: any) => {
        if (this.dynamicFilterType) {
          const isNotANumber = isNaN(value);
          this.filterType = isNotANumber ? 'name' : 'id';
        }
        this.optionsCopy = this.inputTagFilter(this.originalOptions, value, this.filterType);
      });
    }
  }

  setSelectedOptions() {
    this.setOptions(false);
    this.setChips(false);
    this.selectedOptions.forEach((selected) => {
      const result = this.optionsCopy.find((option) => option.id === selected.id);
      if (result) {
        this.markOption(result.id, true);
        this.chips.push(result);
      }
    });
    this.checkedAllOption = this.optionsCopy.length === this.selectedOptions.length;
  }

  setOptions(checked = false) {
    this.optionsCopy = this.originalOptions.map((option) => {
      return { ...option, checked };
    });
    this.optionsReady = true;
  }

  markOption(idOption, checked: boolean) {
    this.optionsCopy = this.optionsCopy.map((option) => {
      return idOption === option[this.idType] ? { ...option, checked } : { ...option };
    });
  }

  removeChip(idOption) {
    this.chips = this.chips.filter((chip) => {
      return chip[this.idType] !== idOption;
    });
  }

  setChips(allChips = false) {
    this.chips = [];
    if (allChips) {
      this.chips.push(...this.optionsCopy);
    }
  }

  handleClick(option, emit = true, focusInputTag = true) {
    if (option === Option.All) {
      if (this.checkedAllOption) {
        this.setOptions();
        this.checkedAllOption = false;
        this.setChips();
      } else {
        this.setOptions(true);
        this.checkedAllOption = true;
        this.setChips(true);
      }
    } else {
      const optionCopy = { ...option };
      const result = this.chips.find((chip: any) => chip[this.idType] === optionCopy[this.idType]);
      if (!result) {
        this.chips.push(option);
        this.markOption(option[this.idType], true);
        if (this.chips.length === this.optionsCopy.length) {
          this.checkedAllOption = true;
        }
        if (this.inputTagFilter) {
          const idx = this.optionsCopy.findIndex((optionCopy) => optionCopy[this.idType] === option[this.idType]);
          this.originalOptions.splice(idx, 1);
        }
      } else {
        this.removeChip(result[this.idType]);
        this.markOption(result[this.idType], false);
        this.checkedAllOption = false;
        if (this.inputTagFilter) {
          this.originalOptions.push(option);
        }
      }
    }
    this.inputControl.setValue('', { emitEvent: false });
    if (this.inputTag && focusInputTag) {
      this.inputTag.nativeElement.focus();
    }
    const chipsCopy = [...this.chips];
    if (emit) {
      this.optionClicked.emit(chipsCopy);
    }
  }

  checkClickInside(target) {
    if (
      target.id === 'menuInputTag' ||
      target.id === 'inputTag' ||
      target.id === 'surveySelector' ||
      target.className?.includes('input-tag-option')
    ) {
      return true;
    } else if (target.parentNode) {
      return this.checkClickInside(target.parentNode);
    } else {
      return false;
    }
  }

  trackByItem(index, item) {
    return item.id;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
