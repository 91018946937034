import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {environment} from "../../../environments/environment";
import { map } from 'rxjs/operators';
@Injectable()
export class RecoveryService {

  constructor(private http:HttpClient) { }

  public validateToken(token){
    const url=`${environment.customerPath}users/recovery`;
    const params:HttpParams= new HttpParams()
      .set("token",token);

    return this.http.get(url, {params: params})
      .pipe(
        map(response=>response)
      );
  }

  public changePassword(token,user,password){
    const url=`${environment.customerPath}users/recovery/${token}/password`;
    const data={
      user: user,
      password: password.toString()
    }
    return this.http.put(url,data)
      .pipe(
        map(response=>response)
      );
  }
}
