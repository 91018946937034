<mh-alerts-wrapper>
    <mh-core-lib-content>
      <section head class="tw-flex tw-flex-col tw-justify-center">
      </section>
  
      <ng-container main-content>
        <router-outlet></router-outlet>
      </ng-container>
    </mh-core-lib-content>
  </mh-alerts-wrapper>
  