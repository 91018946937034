import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'widget-accordion-translate',
  templateUrl: './accordion-translate.component.html',
  styleUrls: ['./accordion-translate.component.scss']
})
export class AccordionTranslateComponent implements OnInit {

  @Input()
  language = 'es';

  @Input()
  invalid = false;

  @Input()
  translates = [];

  @Input()
  keyTranlsate = 'label';

  @Input()
  label: string;

  translateText: string

  constructor() { }

  ngOnInit() {
  }

  checkEmptyInputs(values, key) {
    return values.some(field => field[key] === '')
  }

  onInputTranslate(ev) {
    this.translateText = ev
  }

}
