export const MANUAL_GOOGLE_FB = [
  {
    language: 'es',
    link: 'https://mh-statics.s3.amazonaws.com/foto-encuesta/Autenticaci%C3%B3n%20Facebook%20y%20Google%20my%20Business%20%282%29%20%281%29.pdf'
  },
  {
    language: 'en',
    link: 'https://mh-statics.s3.amazonaws.com/foto-encuesta/Facebook%20and%20Google%20my%20Business%20Authentication%20%282%29.pdf'
  },
  {
    language: 'pt',
    link: 'https://mh-statics.s3.amazonaws.com/foto-encuesta/Autentica%C3%A7%C3%A3o%20Facebook%20e%20Google%20MyBusiness%20%283%29.pdf'
  }
]
