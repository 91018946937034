<div class="progress_box tw-flex tw-justify-center tw-items-center">
  <div class="tw-absolute tw-z-10 tw-grid tw-text-center">
    <span class="tw-text-2xl tw-font-bold">{{ data?.rate | number : '1.0-0' }}%</span>
  </div>
  <mat-progress-spinner
    class="graph graph_box"
    [ngClass]="{ green: data?.rate >= 80, yellow: data?.rate >= 50 && data?.rate < 80, red: data?.rate < 50 }"
    [strokeWidth]="8"
    [diameter]="'95'"
    [mode]="'determinate'"
    [value]="data?.rate.toFixed()"
  >
  </mat-progress-spinner>
  <mat-progress-spinner
    class="graph total_graph"
    [strokeWidth]="8"
    [diameter]="'95'"
    [mode]="'determinate'"
    [value]="100"
  >
  </mat-progress-spinner>
</div>
