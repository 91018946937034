<ng-container *ngTemplateOutlet="guestSummary;context:{showButtonDeatil: true, type: 'list'}"></ng-container>

<ng-template #guestSummary
  let-showButtonDeatil="showButtonDeatil"
  let-type="type"
>
  <div class="row no-gutters align-items-center">
    <div class="col-2">
      <div class="guest_info">
        <widget-guest-info
          [countryCode]="item.guest?.country_code"
          [name]="item.guest?.name"
          [lastName]="item.guest?.lastname"
          [email]="item.guest?.email"
          [product]="'prestay'"
          [isFlagUp]="true"
          [isFlagDown]='false'
          >
        </widget-guest-info>
        <button *ngIf="showButtonDeatil"  class="btn btn-link" (click)="openDialog()">{{(viewType==='guests' ? 'commons.see_orders' : 'commons.see_order') | translate }}</button>
      </div>
    </div>
    <div class="col-10">
      <div class="row additional_info">
        <ng-container *ngFor="let key of keys; let last = last;">
          <div class="col" *ngIf="!(['order_quanity','total_quantity'].includes(key) && type==='modal')"  >
            <ng-container [ngSwitch]="key">
              <ng-template [ngSwitchCase]="['checkin','checkout'].includes(key) ? key : false">
                <widget-calendar-date [label]="('models.order.'+key | translate )" [date]="item.guest[key]" ></widget-calendar-date>
              </ng-template>

              <ng-template [ngSwitchCase]="['created_at'].includes(key) ? key : false">
                <div class="text_date">{{('models.order.'+key | translate )}}</div>
                <widget-calendar-date class="text-center" [date]="item.order?.confirmation" ></widget-calendar-date>
              </ng-template>

              <ng-template [ngSwitchCase]="['order_id'].includes(key) ? key : false">
                <widget-label-value-single [value]="'MH-'+item.order.id" [label]="('models.order.'+key | translate)"></widget-label-value-single>
              </ng-template>

              <ng-template [ngSwitchCase]="['order_quanity'].includes(key) ? key : false">

                <widget-label-value-single [value]="(item.orders.length)" [label]="('models.order.'+key | translate)"></widget-label-value-single>
              </ng-template>

              <ng-template [ngSwitchCase]="['total_price'].includes(key) ? key : false">
                <widget-label-value-single [value]="((viewType==='guests' ? item[key] : item.order[key]) | currency:money.code:(money.code+' '+money.symbol))" [label]="('models.order.'+key | translate)"></widget-label-value-single>
              </ng-template>

              <ng-template [ngSwitchCase]="['room','reservation_id'].includes(key) ? key : false">
                <widget-label-value-single *ngIf="item.guest && item.guest[key]" [value]="(item.guest[key] || '-')" [label]="('models.order.'+key | translate)"></widget-label-value-single>
              </ng-template>

              <ng-template [ngSwitchCase]="['quantity'].includes(key) ? key : false">
                <widget-label-value-single [value]="(item.reservation[key] || '-')" [label]="('models.order.'+key | translate)"></widget-label-value-single>
              </ng-template>

              <ng-template [ngSwitchCase]="['total_quantity'].includes(key) ? key : false">
                <widget-label-value-single [value]="(viewType==='guests' ? item[key] : item.order.total_quantity)" [label]="('models.order.'+key | translate)"></widget-label-value-single>
              </ng-template>

              <ng-template [ngSwitchCase]="['name'].includes(key) ? key : false">
                <div class="box_number">
                  <div class="text">{{('models.order.'+key | translate )}}</div>
                  <div class="value">
                    <span  mhFindTranslate [language]="language" [translates]="item.product.translates" [keyTranlsate]="'name'"></span>
                  </div>
                </div>
              </ng-template>

              <ng-template [ngSwitchCase]="['price'].includes(key) ? key : false">
                <div class="box_number">
                  <div class="text">{{('models.order.'+key | translate )}}</div>
                  <div class="value">
                    <mh-prestay-product-price [properties]="item.product.properties[0]" [currency]="money.code" [reservation_unit_price]="item.reservation.product_property.reservation_unit_price" ></mh-prestay-product-price>
                  </div>
                </div>

              </ng-template>

              <ng-template ngSwitchDefault>
                <widget-label-value-single [value]="item[key]" [label]="('models.order.'+key | translate)"></widget-label-value-single>
              </ng-template>
            </ng-container>
          </div>
        </ng-container>
        <div class="col actions">
          <ng-template *ngTemplateOutlet="item.order?.cases.length > 0 ? showCasesButton : showCreateCase; context: { item }"></ng-template>
          <ng-template #showCreateCase let-item="item">
            <mh-core-lib-actions-wrapper slug="prestay_orders_case_create">
              <mh-core-lib-button *ngIf="showCases" [isSecondary]="true" [text]="'cases.titles.form' | translate" (clicked)="handleCreateCase(item)"></mh-core-lib-button>
            </mh-core-lib-actions-wrapper>
          </ng-template>
          <ng-template #showCasesButton let-item="item">
            <mh-core-lib-button
              [isSecondary]="true"
              [text]="'cases.commons.show_cases' | translate"
              (clicked)="handleOverlayCasesClick(triggerActions)"
              cdkOverlayOrigin
              #triggerActions="cdkOverlayOrigin"
            ></mh-core-lib-button>

            <ng-template
              cdkConnectedOverlay
              [cdkConnectedOverlayOrigin]="triggerActionsCardOrigin"
              [cdkConnectedOverlayOpen]="cardActionsOpen"
              [cdkConnectedOverlayOffsetY]="10"
              [cdkConnectedOverlayPanelClass]="['tw-mr-3', 'trigger-actions-card']"
              (overlayOutsideClick)="cardActionsOpen = false"
            >
              <ul class="tw-bg-white tw-rounded-lg tw-shadow-1 tw-relative tw-py-3 tw-min-w-[220px] tw-divide-y">
                <li *ngFor="let cases of item.order?.cases" class="tw-cursor-pointer tw-px-4 tw-py-1 tw-font-semibold tw-text-main-text hover:tw-text-main-orange tw-transition-all">
                  <a [href]="'/cases/'+ ((cases?.closed === true || cases?.closed === undefined) ? 'history' : 'active') +'?id='+cases.public_id" target="_blank">{{cases.title}}</a>
                </li>
                <li class="tw-px-4 tw-py-1 tw-font-semibold tw-text-main-text hover:tw-text-main-orange tw-transition-all">
                  <mh-core-lib-actions-wrapper slug="prestay_orders_case_create">
                    <button type="button" (click)="handleCreateCase(item); cardActionsOpen = false">{{'cases.titles.form' | translate}}</button>
                  </mh-core-lib-actions-wrapper>
                </li>
              </ul>
            </ng-template>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #detailModal let-c="close" let-d="dismiss">
  <div class="content_modal">
    <div class="close close-cirlce" (click)="closeDialog()">
      <i class="far fa-times"></i>
    </div>
    <div class="detail_guest">
      <div class="info_modal">
        <ng-container *ngTemplateOutlet="guestSummary;context:{showButtonDeatil: false, type:'modal'}"></ng-container>
      </div>
      <div class="content-detail">
        <mh-prestay-order-detail
          [orders]="item.orders"
          [orderSelected]="item.order"
          [guest]="item.guest"
          [viewType]="viewType"
          [products]="products"
          [money]="money"
        ></mh-prestay-order-detail>
      </div>
    </div>
  </div>
</ng-template>
