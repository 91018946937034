<button (click)="openModal(setPassword)" class="{{style}}">
  {{title | translate}}
</button>

<ng-template #setPassword let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{title | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="general_set_form" (ngSubmit)="changePassword()" [formGroup]="setForm" #formDir="ngForm">
      <div [hidden]="formDir.submitted">
        <div *ngFor="let key of validates" class="form-group">
          <label for="{{key}}">{{'forms.'+ (key === 'password' ? 'current_password' : key) | translate}}</label>

          <ng-container [ngSwitch]="key">
            <ng-template [ngSwitchCase]="'new_password'">
              <input type="password" id="{{key}}" class="form-control" formControlName="{{key}}"
                [ngClass]="{
                  'is-valid'   : confirm_password.valid
                }"
                required >
            </ng-template>

            <ng-template [ngSwitchCase]="'confirm_password'">
              <input type="password" id="{{key}}" class="form-control" validateEqual="new_password" reverse="false" formControlName="{{key}}"
                [ngClass]="{
                  'is-valid'   : confirm_password.valid,
                  'is-invalid' : (!isEmpty(confirm_password) && !confirm_password.valid)
                }"
                required >
            </ng-template>

            <ng-template ngSwitchDefault>
              <input type="password" id="{{key}}" class="form-control"  formControlName="{{key}}"   required >
            </ng-template>

          </ng-container>

          <div>
            <ng-container *ngIf="key!=='confirm_password'">

              <ng-container  *ngIf="setForm.controls[key].invalid && (setForm.controls[key].dirty || setForm.controls[key].touched)" >
                <div *ngIf="setForm.controls[key].errors.required" class="tw-text-red-500">
                  {{'forms.attributes.not_empty' | translate}}
                </div>
                <div *ngIf="setForm.controls[key].errors.minlength" class="tw-text-red-500">
                  {{'forms.attributes.min_lenght' | translate:({length: setForm.controls[key].errors.minlength.requiredLength})}}
                </div>
              </ng-container>

            </ng-container>
          </div>
        </div>
        <!-- Validation Errors -->
        <mh-strength-password 
            [possibleErrors]="possibleErrors" 
            [newPassControl]="new_password" 
            [confirmControl]="confirm_password">
        </mh-strength-password>
        <div class="submit_form">
          <button type="submit" [disabled]="setForm.invalid || ['invited'].includes(currentUser.role)" class="btn submit">{{'forms.save_changes' | translate}}</button>
          <label *ngIf="['invited'].includes(currentUser.role)">{{'errors.user.role_no_permission' | translate }}</label>
        </div>

      </div>
    </form>

    <div class="submitted-message" *ngIf="formDir.submitted">
      <mh-spinner-classic *ngIf="waiting"></mh-spinner-classic>
      <div class="dialog" [hidden]="waiting">
        <p class="align-center">{{'configuration.profile.modals.change_password.title_'+status_change | translate}}</p>
        <small class="align-center">{{'configuration.profile.modals.change_password.'+status_change | translate}}</small>
      </div>
    </div>
  </div>
</ng-template>