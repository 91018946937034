<div class="tw-flex tw-flex-col tw-h-full">
  <div class="tw-flex tw-justify-end">
    <i class="far fa-times tw-cursor-pointer" (click)="close()"></i>
  </div>

  <div class="tw-flex tw-justify-center tw-font-medium">
    <h3 class="tw-text-modal-title tw-text-3sm">{{ title }}</h3>
  </div>

  <ng-container [ngSwitch]="state">
    <ng-container *ngSwitchCase="0">
      <ng-container *ngTemplateOutlet="main"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="1">
      <ng-container *ngTemplateOutlet="restoreAccess"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <ng-container *ngTemplateOutlet="changePassword"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="3">
      <ng-container *ngTemplateOutlet="success"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #main>
  <div class="tw-flex tw-flex-wrap tw-justify-around tw-my-8 tw-content-center tw-h-full">
    <mh-core-lib-button [isSecondary]="true" [width]="200" [text]="('user_management.actions.restore_access.reset_password' | translate)" (click)="selectAction(1)"></mh-core-lib-button>
    <mh-core-lib-button [isSecondary]="true" [width]="200" [text]="('user_management.actions.restore_access.edit_password' | translate)" (click)="selectAction(2)"></mh-core-lib-button>
  </div>
</ng-template>

<ng-template #restoreAccess>
  <div class="tw-flex tw-flex-wrap tw-justify-around tw-mt-3 tw-content-center tw-h-full">
    <p class="tw-text-modal-title tw-text-3sm tw-text-center tw-my-5" [innerHTML]="'user_management.actions.restore_access.send_email_message' | translate:{email: this.data?.user.email}"></p>
    <mh-core-lib-button class="tw-mt-5" [isDefault]="true" width=200 [text]="('user_management.actions.restore_access.send_email' | translate)" (click)="restoreAccessFn()"></mh-core-lib-button>
  </div>
</ng-template>

<ng-template #changePassword>
  <form class="tw-my-6" [formGroup]="changePassForm">
    <div class="tw-flex tw-flex-col tw-justify-center tw-my-5">
      <div class="tw-flex tw-flex-row tw-relative">
        <div class="tw-w-full">
          <input [type]="showPassword ? 'text' : 'password'" class="tw-w-full tw-rounded-md tw-border-2 tw-border-input-border focus:tw-ring-0" formControlName="password" [placeholder]="'user_management.actions.change_password.new_password' | translate">
        </div>
        <div class="tw-absolute tw-top-3 tw-right-5 tw-cursor-pointer" *ngIf="passwordControl.value.length > 0">
          <i class="fas fa-eye{{showPassword ? '-slash' : ''}}" (click)="showPassword = !showPassword"></i>
        </div>
      </div>
      <div class="tw-flex tw-flex-row tw-relative tw-mt-2">
        <div class="tw-w-full">
          <input [type]="showRepeatPassword ? 'text' : 'password'" class="tw-w-full tw-rounded-md tw-border-2 tw-border-input-border focus:tw-ring-0" formControlName="repeatPassword" [placeholder]="'user_management.actions.change_password.confirm_password' | translate">
        </div>
        <div class="tw-absolute tw-top-3 tw-right-5 tw-cursor-pointer" *ngIf="repeatPasswordControl.value.length > 0">
          <i class="fas fa-eye{{showRepeatPassword ? '-slash' : ''}}" (click)="showRepeatPassword = !showRepeatPassword"></i>
        </div>
      </div>
    </div>
    <div class="tw-flex tw-flex-row tw-relative tw-my-5">
      <p class="tw-text-modal-title tw-text-3sm">{{ 'user_management.create_user.user_created.request_password_change' | translate }}</p>
      <div class="tw-absolute tw-right-0">
        <mh-core-lib-toggle [value]="toggle[0]" (onClick)="toggle[0] = !toggle[0]"></mh-core-lib-toggle>
      </div>
    </div>
    <mh-strength-password 
        [possibleErrors]="possibleErrors" 
        [newPassControl]="passwordControl" 
        [confirmControl]="repeatPasswordControl">
    </mh-strength-password>
  </form>
  <div class="tw-flex tw-justify-center">
    <mh-core-lib-button [isDefault]="true" width=200 [isDisabled]="!changePassForm.valid || passwordControl?.value !== repeatPasswordControl?.value" [text]="('user_management.actions.change_password.title' | translate)" (click)="changePasswordFn()"></mh-core-lib-button>
  </div>
</ng-template>

<ng-template #success>
  <div class="tw-flex tw-justify-center tw-my-8">
    <i class="fas fa-check-circle fa-3x tw-text-main-green-success"></i>
  </div>

  <div class="tw-flex tw-flex-col tw-justify-center tw-text-center tw-text-main-text tw-text-2sm tw-my-6">
    <p [innerHTML]="message"></p>
    <ng-container *ngIf="!!newPassword">
      <div class="tw-flex tw-flex-row tw-justify-center tw-w-full {{ !newPassword ? 'tw-hidden' : '' }}">
        <p class="tw-text-modal-title tw-text-3sm tw-font-bold ">{{ showPassword ? newPassword || '' : passwordMask }}</p>
        <i class="tw-mx-3 fas fa-eye{{ showPassword ? '-slash' : '' }} tw-py-1" (click)="showPassword = !showPassword"></i>
      </div>
    </ng-container>
  </div>

  <div class="tw-flex tw-justify-center tw-mt-2">
    <mh-core-lib-button [isDefault]="true" [text]="('forms.accept' | translate)" (click)="close()"></mh-core-lib-button>
  </div>
</ng-template>
