<mh-box-element [title]="('prestay.summary.email_rate.title' | translate)">
  <div class="box">
    <widget-half-pie
      [legend]="('commons.valids_emails' | translate)"
      [percent]="percent"
      [tooltip]="('prestay.summary.email_rate.tooltip' | translate:{guest: (guestType | translate )})"
      [modalTitle]="('prestay.summary.email_rate.modal_title' | translate)"
      [qualify]="qualify"
      [showModal]="false"
      [waiting]="waiting">
  </widget-half-pie>
  </div>
</mh-box-element>

