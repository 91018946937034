import { Pipe, PipeTransform } from '@angular/core'

import { SemanticComment } from 'app/shared/model/semantic-comment.model';
import { SemanticCommentFilter } from 'app/states/semantic/semantic.state'

@Pipe({ name: 'semanticSurveysFilter' })
export class SemanticSurveysFilterPipe implements PipeTransform {

  transform(value: SemanticComment[], filters: SemanticCommentFilter): SemanticComment[] {
    if(filters.surveys?.length > 0) {
      return value.filter(
        (comment) => filters.surveys?.some(s => s.translates === comment.survey_name)
      );
    } else
      return value
  }
}
