import { Component, Input } from '@angular/core';
import { map } from 'rxjs/operators';
import { UtilService } from 'app/shared/util.service';
import { CaseProductService } from './case-product.service';
import { ProductItem } from 'app/shared/cases/types';
import { Survey, Visit, Review } from 'app/shared/model';

@Component({
  selector: 'mh-case-product',
  templateUrl: './case-product.component.html',
  styleUrls: ['./case-product.component.scss'],
  providers: [CaseProductService],
})
export class CaseProductComponent {
  FOLLOWUP_SURVEY:string = '1_1';
  ONSITE_SURVEY:string = '2_1';
  ONLINE_REVIEW:string = '3_2';
  PRESTAY_PRECHECKIN:string = '4_3';
  PRESTAY_ORDER:string = '4_4';

  PRESTAY_ORDER_KEYS = [ 'checkin', 'checkout', 'reservation_id', 'order_quanity',
      'total_quantity', 'total_price', 'room' ]

  @Input() private productItem: ProductItem;
  @Input("item") private itemInput: any;
  @Input() survey: any;

  item: any
  productType: string | null;
  money: any;
  hotelDto: any = {};

  constructor(
    private utilService: UtilService,
    private caseProductService: CaseProductService,
  ) {}

  ngOnInit() {
    this.initMoney();
    this.initProduct();
  }

  initMoney() {
    this.money = this.utilService.currentHotel.currency;
  }

  initProduct() {
    if(this.itemInput) return this.setItem(this.itemInput);
    if(!this.productItem) return;

    const { product_id, type: { id } } = this.productItem
    this.productType = `${product_id}_${id}`;
    (this.getFetcher())().subscribe(this.setItem);
  }

  setItem = (item: any) => {
    if (!item) this.productType = null;

    this.item = item;
  }

  getFetcher = () : any => {
    switch(this.productType) {
      case this.FOLLOWUP_SURVEY:
        return this.fetchFollowupAnswer;
      case this.ONSITE_SURVEY:
        return this.fetchOnsiteAnswer;
      case this.ONLINE_REVIEW:
        return this.fetchOnlineReview;
      case this.PRESTAY_PRECHECKIN:
        return this.fetchPrestayPrecheckin;
      case this.PRESTAY_ORDER:
        return this.fetchPrestayOrder;
    }
  }

  fetchFollowupAnswer = () => {
    return this.caseProductService.getFollowupAnswer(this.itemId)
      .pipe(map((data) => Survey.createFromApi(data)));
  }

  fetchOnsiteAnswer = () => {
    return this.caseProductService.getOnsiteAnswer(this.itemId)
      .pipe(map((data) => Survey.createFromApi(data)));
  }

  fetchOnlineReview = () => {
    return this.caseProductService.getOnlineReview(this.itemId)
      .pipe(map(data => {
        const publicationDate = this.utilService.stringDateToDate(data.publication_date);
        const visit = new Visit(
          data.guest.username,
          this.utilService.stringDateToDate(data.guest.stay_date),
          data.guest.country,
          data.guest.language
        )

        return (new Review(publicationDate, visit))
          .createFromApi(data);
      }));
  }

  fetchPrestayPrecheckin = () => {
    return this.caseProductService.getPrestayPrecheckin(this.customerId, this.itemId)
      .pipe(map((item: any) => item && item.guest));
  }

  fetchPrestayOrder = () => {
    const totalQty = (item:any) => item.orders[0].reservations.reduce(
      (acum:number, res:any) => acum + res.quantity, 0);
    const totalPrice = (item:any) => item.orders[0].reservations.reduce(
      (acum:number, res:any) => acum + res.quantity * res.unit_price, 0);

    return this.caseProductService.getPrestayOrder(this.customerId, this.itemId)
      .pipe(map((data) => ({
        ...data,
        order: {
          total_quantity: totalQty(data),
          total_price: totalPrice(data),
        },
      })));
  }

  get productLink() {
    if (!this.item) return;
    switch(this.productType) {
      case this.FOLLOWUP_SURVEY:
        return '/followup/huespedes';
      case this.ONSITE_SURVEY:
        return '/onsite/huespedes';
      case this.ONLINE_REVIEW:
        return '/online/resenas';
      case this.PRESTAY_PRECHECKIN:
        return '/prestay/precheckin';
      case this.PRESTAY_ORDER:
        return '/prestay/ordenes';
    }
  }

  get productParams() {
    if (!this.item) return;
    const formatDateString = (date:string) => date.split(' ')[0]
    const formatDate = (date:Date) =>
      formatDateString(date.toISOString().replace('T', ' '))

    switch(this.productType) {
      case this.FOLLOWUP_SURVEY:
        return {
          guest: this.item.guest.id,
          customer: this.customerId,
          date: formatDateString(this.item.answer.date),
        };
      case this.ONSITE_SURVEY:
        return {
          guest: this.item.guest.id,
          customer: this.customerId,
          date: formatDateString(this.item.answer.date),
        };
      case this.ONLINE_REVIEW:
        return {
          review: this.item.id,
          customer: this.customerId,
          date: formatDate(this.item.publicationDate),
        };
      case this.PRESTAY_PRECHECKIN:
        return {
          guest: this.item.id,
          customer: this.customerId,
          date: formatDateString(this.item.checkin),
        };
      case this.PRESTAY_ORDER:
        return {
          order: this.item.orders[0].id,
          customer: this.customerId,
          date: formatDateString(this.item.orders[0].created_at),
        };
    }
  }

  get customer() { return this.utilService.currentHotel; }
  get customerId() { return this.customer.id; }
  get itemId() { return this.productItem.item_id; }
}
