import {Ota} from './index';
import {AddSemantic} from './extends/semantic.extend';

export class Review extends AddSemantic{
  id:number;
  customerId:number;
  title:string;
  description:string;
  flagAnswered:number;
  flagReadFidelity: number;
  guest:Visit;
  myHotelRaiting:number;
  ota:Ota;
  otaRaiting: number;
  publicationDate: Date;
  typeTraveler:TypeTraveler;
  url:string;
  nps:string;
  deleted:boolean = false;
  cases: any[] = [];
  answer: any;


  constructor(publicationDate:Date, visit:Visit){
    super();
    this.publicationDate = publicationDate;
    this.guest = visit;
  }

  createFromApi(review){
    this.id = review.id;
    this.customerId = review.customer_id;
    this.title = review.title;
    this.description = review.description;
    this.flagAnswered = review.flag_answered;
    this.flagReadFidelity = review.flag_read_fidelity;
    this.myHotelRaiting = review.my_hotel_rating;
    this.ota = review.ota;
    this.otaRaiting = review.ota_rating;
    this.typeTraveler = review.traveler_type;
    this.url = review.url;
    this.nps = review.nps
    this.semanticRating = review.semantic_rating;
    this.semanticAreas = review.semantic_areas
    this.deleted = false;
    this.setExcerpt(this.description);
    this.themeList = this.getThemes();
    this.cases = review.cases || [];
    this.answer = review.answer || null;
    return this;
  }

  getOtaScore(){
    return this.otaRaiting*this.ota.scale/5;
  }

  isCommentable(){
    return [0,1].includes(this.flagAnswered);
  }

  isAnswered(){
    return this.flagAnswered===1;
  }



}

export class TypeTraveler{
  id:number;
  nameEn:string;
  nameEs:string;
}

export class Visit{
  username: string;
  stayDate:Date;
  country:any;
  language:any;

  constructor(username,stayDate,country, language){
    this.username = username;
    this.stayDate = stayDate;
    this.country = country;
    this.language = language;
  }
}

