import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fidelityDeskPriority'
})
export class FidelityDeskPriorityPipe implements PipeTransform {

  transform(cases, filters) {
    let filteredCases = cases;
    if(filters) {
      const filter = filters.find(filter => filter.name === 'priority');
      const filtering = filter.options.some(option => option.isFiltering);
      if(filter && filtering) {
        const options = filter.options.filter(option => option.isFiltering).map(option => option.id);
        filteredCases = cases.filter(item => options.includes(item.priority.id))
      }
    }
    return filteredCases;
  }

}
