import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'widget-period-indicator',
  templateUrl: './period-indicator.component.html',
  styleUrls: ['./period-indicator.component.scss']
})
export class PeriodIndicatorComponent implements OnInit {

  @Input()
  currentValue = null;

  @Input()
  previousValue = null;

  @Input()
  variation = null;

  @Input()
  currentFormat;

  @Input()
  previousFormat;

  @Input()
  variationFormat;

  @Input()
  inTooltip = false;

  @Input()
  tooltip = null;

  @Input()
  invisible = false;

  @Input()
  style: 'normal' | 'light' = 'normal';

  @Input()
  format: string;

  constructor() { }

  ngOnInit() {

  }

  get hasInformation(){
    return this.currentValue || this.previousValue;
  }



}
