import { Component, Input } from '@angular/core';

export interface RankingPeriod{
  actualPeriod: {ranking: number, positions: number}
  customerId: number
  previousPeriod: {ranking: number, positions: number}
}

@Component({
  selector: 'widget-period-formater-ranking',
  templateUrl: './period-formater-ranking.component.html',
  styleUrls: ['./period-formater-ranking.component.scss']
})
export class PeriodFormaterRankingComponent{

  @Input()
  period;

  constructor() { }

  formatRanking(value){
    return `#${value.actual}<span class="small">/${this.period.total}</span>`
  }

  get currentValue(){
    return this.period.actual ? this.formatRanking(this.period) : '-';
  }

  get previousValue(){
    return this.period.previous ? `#${this.period.previous}` : '-';
  }

  get totalValue() {
    return this.period.total ? `#${this.period.total}` : '-';
  }

  get variation(){
    return this.period.variation;
  }

  get invisible(){
    return this.period && this.period.invisible && this.period.invisible;
  }

  get variationFormat(){
    const variation = this.variation
    return variation === 0 ? '-' : variation;
  }

}
