import { Component, OnInit, Input } from '@angular/core';
import { FollowupPropertiesService } from '../../properties/followup-properties.service';
import { FilterDate } from 'app/shared/model/filters/filter-date.model';
import { UtilService } from 'app/shared/util.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { Hotel, Period } from 'app/shared/model';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CustomersState } from 'app/states/customers/customers.state';

export interface RateResponse {
  email_rate: Period;
  total_guests: Period;
  valid_emails: Period;
}

@Component({
  selector: 'mh-chain-followup-email-rate',
  templateUrl: './chain-followup-email-rate.component.html',
  styleUrls: ['./chain-followup-email-rate.component.scss'],
  providers: [FollowupPropertiesService, FollowupPropertiesService],
})
export class ChainFollowupEmailRateComponent implements OnInit {
  @Select(CustomersState.relativeCurrentChain) customersReady$: Observable<any[]>;

  @Input()
  productId = 1;

  @Input()
  chain: Hotel[] = [];

  @Input()
  currentDates = null;

  waiting = true;
  data: RateResponse;

  firstCall = true;
  surveySubscribe: Subscription;
  customersStateSubscribe: Subscription;

  constructor(
    private utilService: UtilService,
    private followupPropertiesService: FollowupPropertiesService,
    private store: Store,
  ) {
    this.surveySubscribe = this.utilService.isCurrentSurveyChanged().subscribe((surveId) => {
      if (this.currentDates) {
        this.init();
      }
    });
    this.customersStateSubscribe = this.customersReady$.subscribe((customers) => {
      if (this.currentDates) {
        this.init();
      }
    });
  }

  ngOnDestroy() {
    this.surveySubscribe.unsubscribe();
    this.customersStateSubscribe.unsubscribe();
  }

  ngOnInit() {}

  ngOnChanges({ currentDates }) {
    if (currentDates) {
      this.init();
    }
  }

  init() {
    this.waiting = true;
    const date = new FilterDate(this.currentDates.startDate, this.currentDates.endDate);
    const survey = this.utilService.getCurrentSurvey(this.chain.map((x) => parseInt(x.id)));
    const surveyIds = survey.ids;
    const customers = this.chain ?this.chain.map((c) => c.id).join(',') : this.store.selectSnapshot(CustomersState.relativeCurrentChain).map((c) => c.id).join(',');

    if (customers.length) {
      this.followupPropertiesService.getPMS(date, customers, this.productId, surveyIds).subscribe((response: any) => {
        this.data = response.average;
        this.firstCall = false;
        this.waiting = false;
      });
    }
  }

  get guestType() {
    if (this.isRetail || this.isRestaurant) return 'commons.clients';
    if (this.isClinic) return 'commons.patients';

    return 'commons.guests';
  }

  get isRetail() {
    return this.utilService.customerIsRetail;
  }
  get isClinic() {
    return this.utilService.customerIsClinic;
  }
  get isRestaurant() {
    return this.utilService.customerIsRestaurant;
  }

  get titlePie() {
    return 'qualify.good';
  }

  get qualify() {
    return this.utilService.qualifyPercent(this.percent);
  }

  get percent() {
    return this.data ? this.data.email_rate.actual : null;
  }
}
