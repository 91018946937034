import { Component, Input } from '@angular/core';
import { Case } from 'app/shared/cases/types';

@Component({
  selector: 'mh-case-header-info',
  templateUrl: './case-header-info.component.html',
  styleUrls: ['./case-header-info.component.scss']
})
export class CaseHeaderInfoComponent {
  // debiese estar en un util que no sea UTILSERVICE
  PRODUCT_NAMES = { 1: 'followup', 2: 'onsite', 3: 'online', 4: 'prestay' }

  @Input() case: Case;
  @Input() language: string;
  @Input() backgroundColor:string = 'white';

  public getProductName(id:number) { return this.PRODUCT_NAMES[id]}

  get isStagnant() {
    return this.case.stagnant;
  }

  get isClosed() {
    return this.case.state.closed && this.case.last_state_change;
  }
}
