import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from "../../environments/environment";
import { map } from 'rxjs/operators';
@Injectable()
export class ForgotPasswordService {

  constructor(private http:HttpClient) { }

  public recoveryPassword(email:string){
    const url=`${environment.customerUser}/forgot/password`;
    const data = {}
    data["user_email"]= email

    return this.http.put(url,data)
      .pipe(
        map(response=>response)
      );
  }
}
