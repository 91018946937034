import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { v4 as uuid } from 'uuid';
import {Question, Alternative} from 'app/survey-public/models';

/*
* A little bit of context of my thinking process
* I'm setting all the conditionals in a dictionary
* then, on change, just updating the active dictionary
*
* Before: Recreated the conditionals array, which caused all components to be recreated
*/
@Component({
  selector: 'mh-survey-question-grid',
  templateUrl: './survey-question-grid.component.html',
  styleUrls: ['./survey-question-grid.component.scss']
})
export class SurveyQuestionGridComponent implements OnInit {
  @Input() questionId:number;
  @Input() question: any;
  @Input() alternatives:any[]
  @Input() answers:any[]
  @Input('conditionalQuestions') inputConditionalQuestions:any[]
  @Input() language:string
  @Input() answered:boolean;
  @Input() conditionalId:number;
  @Input() preview = false;
  @Input() surveyLanguageId;
  @Input() languageId;
  @Input() productId;
  @Input() answersComments;

  @Output() answer:EventEmitter<any> = new EventEmitter()

  conditionalQuestions: {[subAlternativeId:number]: Question[]} = { }
  activeSubAlternatives: {[subAlternativeId:number]: boolean} = { }

  constructor() { }

  ngOnInit() {
    this.setConditionalQuestions();
    this.updateConditionalQuestions();
  }

  ngOnChanges() {
    this.updateConditionalQuestions();
  }

  ngOnDestroy() {
    if (!this.conditionalId)
      return;

    this.alternatives.forEach(alternative => {
      const answer = this.getAlternativeAnwser(alternative);

      if (answer.selected)
        this.answer.emit(this.createAnswer(alternative))
    })
  }

  handleChange(alternative:any, subAlternative:any) {
    const answer = this.createAnswer(alternative, subAlternative, true);

    this.answer.emit(answer)
  }

  createAnswer(alternative: any, subAlternative: any = {}, selected = false) {
    const prevAnswer = this.getAlternativeAnwser(alternative);

    return {
      conditional_alternative_id: this.conditionalId,
      answer_alternative_id: alternative.id,
      sub_alternative_id: subAlternative.id || prevAnswer.sub_alternative_id,
      score: subAlternative.score || prevAnswer.score,
      selected,
      question_id: this.questionId,
      tempId: prevAnswer.tempId || uuid(),
      id: prevAnswer.id,
    }
  }

  isSelected(sub:any, alternative:any) {
    return this.getAlternativeAnwser(alternative).sub_alternative_id === sub.id
  }

  isDisabled(alternative:any) {
    if (this.preview) return true;

    const alternativeAnswer = this.answers
      .find(a => a.answer_alternative_id === alternative.id) || {};

    return alternativeAnswer.waiting;
  }

  getAlternativeAnwser(alternative:any) {
    return this.answers.find(a => (
      a.answer_alternative_id === alternative.id &&
      (!this.conditionalId || this.conditionalId === a.conditional_alternative_id)
    )) || {};
  }

  setConditionalQuestions() {
    this.conditionalQuestions = {};
    this.alternatives.forEach(this.setConditionalQuestionsForAlternative.bind(this))
  }

  setConditionalQuestionsForAlternative(alternative:Alternative) {
    alternative.sub_alternatives.forEach(sub => {
      this.activeSubAlternatives[sub.id] = false;

      this.conditionalQuestions[sub.id] = sub.conditional_questions
        .map((q) => this.inputConditionalQuestions.find(q2 => q2.id === q.conditional_question_id))
        .filter(Boolean)
        .sort((obj1:any, obj2:any) => obj1.conditional_order - obj2.conditional_order);
    })
  }

  updateConditionalQuestions() {
    const answeredSubAlternativeIds = this.getAnsweredSubAlternativeIds();

    this.alternatives.forEach(alt => {
      alt.sub_alternatives.forEach(sub => {
        if (!this.conditionalQuestions[sub.id]) return;

        this.activeSubAlternatives[sub.id] = answeredSubAlternativeIds.includes(sub.id);
      })
    })
  }

  get hasMinMaxTexts() {
    return this.question.translates.some(translate => {
      if(translate.min_text && translate.max_text) {
        return translate.min_text !== '' && translate.max_text !== '';
      }
    })
  }

  getAnsweredSubAlternativeIds() {
    const alternativeIds = this.alternatives.map(alt => alt.id);
    return this.answers.filter(ans => alternativeIds.includes(ans.answer_alternative_id))
    .map(ans => ans.sub_alternative_id)
  }

  get isOneRowGrid() {
    return this.alternatives.length === 1;
  }

  get isNpsGrid() {
    return this.alternatives.length === 1 &&
      this.alternatives[0].translates.every(t => t.min_text && t.max_text);
  }
}
