import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mh-cover-mask-loading',
  templateUrl: './cover-mask-loading.component.html',
  styleUrls: ['./cover-mask-loading.component.scss']
})
export class CoverMaskLoadingComponent implements OnInit {

  @Input() showLoading

  constructor() { }

  ngOnInit() {
  }

}
