<div class="calendar" [class.row]="isCardView" [ngClass]="classCalendar">
  <div [class.col-2]="isCardView" class="text-center">
    <i [ngClass]="type==='enphasis' ? 'far fa-calendar-check' : 'far fa-calendar-alt'"></i>
  </div>
  <div class="text" [class.col-5]="isCardView" [ngClass]="classCalendar">{{label}}</div>
  <div class="date" [class.col-5]="isCardView" [ngClass]="classDateCalendar" *ngIf="date; else elseEmpty" [innerHtml]="(date | formatDate)"></div>
</div>
<ng-template #elseEmpty>
  <span [ngClass]="{'pl-3': isCardView}">-</span>
</ng-template>
