import { Component, Input } from '@angular/core';


@Component({
  selector: 'mh-wait-table',
  templateUrl: './wait_table.component.html',
  styleUrls: ['../../../../../../assets/scss/shared/elements/wait_table.scss'],
})
export class WaitTableComponent{
  @Input()
  isHeavyQuery:boolean=false;

  @Input()
  numberColumns:number=2;

  @Input()
  numberRows:number=1;

  @Input()
  messages:string[]= [];

  @Input()
  index:number= 0;
}
