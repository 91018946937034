<cdk-accordion class="tw-block">
  <ng-container *ngFor="let item of items; let index = index">
    <cdk-accordion-item
      #accordionItem="cdkAccordionItem"
      class="tw-block tw-bg-white tw-mt-4"
      role="button"
      tabindex="0"
      [attr.id]="'accordion-header-' + index"
      [attr.aria-expanded]="accordionItem.expanded"
      [attr.aria-controls]="'accordion-body-' + index"
      *ngIf="item.items?.length > 0"
    >
      <div
        class="tw-flex tw-content-center tw-justify-between tw-p-4 hover:tw-cursor-pointer hover:tw-bg-gray-200"
        (click)="accordionItem.toggle()"
      >
        <div>
          <table class="reports-table tw-w-full tw-table-fixed tw-text-base tw-font-bold tw-text-left tw-text-gray-900">
            <tbody>
              <tr>
                <td scope="col" class="tw-pr-6 tw-font-bold">
                  {{ item.title }}
                </td>
                <ng-container *ngIf="!hideMassiveActions">
                  <td scope="col" *ngFor="let toggle of options; index as idx" class="tw-pr-6">
                    <mh-core-lib-toggle
                      *ngIf="toggle.enable"
                      [value]="item.allChecked[idx]"
                      [id]="item.id"
                      [isDisabled]="!toggle.enable"
                      [mhTooltip]="(item.id === 6 && idx === 1) ? ('notifications.manager.desk_alerts' | translate) : null"
                      position="above"
                      [ngClass]="{ 'tw-pl-4': idx === 1 }"
                      [preventClick]="item.id === 6 && idx === 1"
                      (onClick)="onMassiveClick($event, idx, item)"
                    >
                    </mh-core-lib-toggle>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
        <span class="tw-text-sm tw-text-gray-400">
          <i *ngIf="accordionItem.expanded; else accordionItemClose" class="fas fa-chevron-up tw-text-xl"></i>
          <ng-template #accordionItemClose>
            <i class="fas fa-chevron-down tw-text-xl"></i>
          </ng-template>
        </span>
      </div>
      <div
        role="region"
        [style.display]="accordionItem.expanded ? '' : 'none'"
        [attr.id]="'accordion-body-' + index"
        [attr.aria-labelledby]="'accordion-header-' + index"
      >
        <ng-container *ngIf="item.type === 'simple'">
          <div
            *ngFor="let subItem of item.items; let index = index"
            class="tw-bg-white"
            [style.display]="accordionItem.expanded ? '' : 'none'"
            [attr.id]="'accordion-body-' + index"
            [attr.aria-labelledby]="'accordion-header-' + index"
          >
            <table class="reports-table tw-w-full tw-table-fixed tw-text-base tw-font-bold tw-text-left tw-text-gray-900">
              <tbody>
                <tr>
                  <td scope="col" class="tw-pr-6 tw-pl-8 tw-py-5 tw-font-normal">
                    {{ subItem?.translation ? (subItem.translationLabel | translate) : subItem.name }}
                  </td>
                  <td scope="col" *ngFor="let toggle of options; let i = index" class="tw-pr-6 tw-py-5">
                    <mh-core-lib-toggle
                      *ngIf="toggle.enable"
                      [value]="subItem?.channels ? subItem.channels[i].check : subItem.check"
                      [id]="index"
                      [isDisabled]="!toggle.enable"
                      [preventClick]="item.id === 6 && i === 1"
                      [mhTooltip]="(item.id === 6 && i === 1) ? ('notifications.manager.desk_alerts' | translate) : null"
                      position="above" 
                      (onClick)="onClick($event, i, item, subItem)"
                    >
                    </mh-core-lib-toggle>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
  
        <ng-container *ngIf="item.type === 'nested' && item.items.length > 0">
          <cdk-accordion class="tw-block">
            <cdk-accordion-item
              *ngFor="let subItem of item.items; let index = index"
              #accordionItem="cdkAccordionItem"
              class="tw-block tw-bg-main-gray-table"
              role="button"
              tabindex="0"
              [attr.id]="'accordion-header-' + index"
              [attr.aria-expanded]="accordionItem.expanded"
              [attr.aria-controls]="'accordion-body-' + index"
            >
              <div
                class="tw-flex tw-content-center tw-justify-between tw-p-4 hover:tw-cursor-pointer hover:tw-bg-gray-200"
                (click)="accordionItem.toggle()"
              >
                <span class="tw-ml-2" [ngClass]="{'tw-font-semibold': accordionItem.expanded}">{{ subItem.name }}</span>
                <span class="tw-text-sm tw-text-gray-400">
                  <i *ngIf="accordionItem.expanded; else accordionItemClose" class="fas fa-chevron-up tw-text-xl"></i>
                  <ng-template #accordionItemClose>
                    <i class="fas fa-chevron-down tw-text-xl"></i>
                  </ng-template>
                </span>
              </div>
              <div
                *ngFor="let option of npsOptions"
                class="tw-bg-white"
                role="region"
                [style.display]="accordionItem.expanded ? '' : 'none'"
                [attr.id]="'accordion-body-' + index"
                [attr.aria-labelledby]="'accordion-header-' + index"
              >
                <table
                  class="reports-table tw-w-full tw-table-fixed tw-text-base tw-font-bold tw-text-left tw-text-gray-900"
                >
                  <tbody>
                    <tr>
                      <td scope="col" class="tw-pr-6 tw-pl-10 tw-py-5 tw-font-normal">
                        {{ 'collect.configuration.filters.' + option.value | translate }}
                      </td>
                      <td scope="col" *ngFor="let toggle of options; let i = index" class="tw-pr-6 tw-py-5">
                        <mh-core-lib-toggle
                          *ngIf="toggle.enable"
                          [value]="subItem.channels[i][option.value]"
                          [name]="option.value"
                          [id]="index"
                          [isDisabled]="!toggle.enable"
                          (onClick)="onClick($event, option, item, subItem.channels[i])"
                        >
                        </mh-core-lib-toggle>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </cdk-accordion-item>
          </cdk-accordion>
        </ng-container>
      </div>
    </cdk-accordion-item>
  </ng-container>
</cdk-accordion>
