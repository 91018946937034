<div class="current_survey" *ngIf="!waiting" id="resultSurvey" #resultSurvey>
  <div class="modal-header">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 text-center">
        <span class="title-text">{{titleBox | translate}}</span>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-8 col-lg-8 d-flex">
        <div>
        <span class="title-text flex-grow-1">{{surveySelected?.translates | translateObject:'title':language}}</span>
        </div>
        <div class="header-actions">
          <mh-case-product-actions
            [productItem]="getProductItem(survey.answer.id)"
            [cases]="survey.cases"
            [sourceWindow]="from"
            [showFlex]="true"
            [survey]="survey"
            class="mr-5"
            [ngStyle]="{'right':'1vw'}"
            data-html2canvas-ignore
            [createCase]="createCase"
          ></mh-case-product-actions>
          <i class="close far fa-times" (click)="closeDialog()" data-html2canvas-ignore></i>
        </div>
      </div>
    </div>
  </div>
  <div class="row no-gutters">
    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 line-vertical">
      <div class="row no-gutters guest-info">
        <div class="col">
          <mh-survey-summary
            [survey]="survey"
            [productId]="productId"
            [viewType]="'modal'"
            [showCases]="true"
            [from]="fromTag"
            [layoutView]="'card-view'"
            [showQualify]="false"
          ></mh-survey-summary>
        </div>
      </div>
      <div class="row no-gutters survey-info">
        <div class="col">
          <div class="title-section text-center">
            <span class="title-text">{{'commons.info_survey' | translate}}</span>
          </div>
          <widget-guest-qualify
            [survey]="survey"
            [layoutView]="'card-view'">
          </widget-guest-qualify>
          <div class="my-3">
            <mh-survey-info
              [survey]="survey"
              [layoutView]="'card-view'"
              >
            </mh-survey-info>
          </div>
          <div class="body-actions items-center-center">
            <button class="btn btn-orange" (click)="downloadPDF()" data-html2canvas-ignore>
              <div [hidden]="generatingPDF" class="action_download">
                <span>{{'shared.survey-answer-modal.export' | translate}}</span>
              </div>
              <div *ngIf="generatingPDF" class="spinner_wait">
                <mh-spinner-classic [color]="'white'"></mh-spinner-classic>
              </div>
            </button>
          </div>

        </div>
      </div>
    </div>

    <!-- DETAIL OF ANSWERS (basically, disbaled answered survey) -->
    <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8">
      <div class="row no-gutters line-horizontal">
        <div class="col">
          <div class="comment-section">
            <div class="comment-header">
              <div class="subtitle-text">
                {{('commons.comments' | translate) | titlecase}}
              </div>
              <div class="show-comments-area">
                <a href="javascript:void(0)" (click)="onToggle()" *ngIf="comments?.length > 0">
                  {{'commons.see_comments' | translate}}
                  <span class="chevron" [class.open]="open"><i class="fas fa-chevron-down"></i></span>
                </a>
                <span *ngIf="!comments || comments?.length === 0">0 {{('commons.comments' | translate)}}</span>
              </div>
            </div>
            <div class="comment-section-detail" *ngIf="comments && open" @heightExpand>
              <ng-container *ngFor="let comment of comments">
                <mh-survey-question-public
                  [question]="comment.question"
                  [answerId]="survey.answer.id"
                  [conditionalQuestions]="survey.conditional_questions || []"
                  [language]="language"
                  [languageId]="languageId"
                  [surveyLanguageId]="surveyLanguage"
                  [answers]="answersComments"
                  [answered]="false"
                  [preview]="true"
                  [productId]="surveySelected.product_id"
                ></mh-survey-question-public>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col">
          <div class="survey-section pb-0">
            <div class="subtitle-text">
              {{'commons.survey' | translate}}
            </div>
            <div
              class="survey-section-detail"
              [style.height]="open ? '47vh' : '69vh'"
              *ngIf="this.survey.version === 2; else legacyDetail">
              <mh-survey-answer-public
                [surveyId]="survey.id"
                [answerId]="survey.answer.id"
                [surveyLanguageId]="surveyLanguage"
              >
              </mh-survey-answer-public>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #legacyDetail>
  <ng-container *ngIf="!loading; else spinner">
    <div class="answer" *ngFor="let question of allQuestions; let number=index" [class.loading]="loading" >
      <h3 class="question_title" [attr.data-id]="question.id">{{question.order}}. {{question.statement}}</h3>
      <div class="row no-gutters">
        <div class="col-12">
          <ng-container *ngTemplateOutlet="typeGrid;context:{question: question}"></ng-container>
        </div>
        <div class="col-12" *ngIf="question.is_conditional">
          <div class="conditional_question">
            <!-- I should add here somehow -->
            <ng-container *ngIf="question.type==7; else singleQuestion">
              <ng-container *ngFor="let sub_question of question.sub_questions">
                <ng-container *ngFor="let conditional of sub_question.alternatives">
                  <ng-container *ngTemplateOutlet="conditionalQuestion;context:{question: question, conditional: conditional,sub_question:sub_question}"></ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-template #singleQuestion>
              <ng-container *ngFor="let conditional of question.alternatives">
                <ng-container *ngTemplateOutlet="conditionalQuestion;context:{question: question, conditional: conditional}"></ng-container>
              </ng-container>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="row no-gutters mt-2 ml-3" *ngIf="question.type==5">

        <div class="translate col-12" (click)="translate(getAnswers(question),number)"
          *ngIf="surveyLanguage!=currentLanguage && getAnswers(question)!= ''">
          <i class="fal fa-language"></i>
          <span>{{'commons.translate' | translate }}</span>
        </div>

        <div class="col-12"
          *ngIf="surveyLanguage!=currentLanguage && getAnswers(question)!= '' && commentsTraslates[survey.answer.id+number] != undefined ">
          <div class="type_comment">
              <textarea class="form-control aqua" rows="4" readonly="">
                {{ commentsTraslates[survey.answer.id+number] }}
              </textarea>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>


<ng-template #typeGrid let-question="question">
  <div class="area_form" [ngSwitch]="question.type" >
    <ng-template [ngSwitchCase]="[2,3].includes(question.type) ? question.type : -1">
      <div class="type_radio">
        <ul>
          <li *ngFor="let alternative of question.alternatives" [class.selected]="getAnswers(question,alternative.id)">
            <span [attr.data-id]="alternative.id">{{alternative.statement}}</span>
          </li>
        </ul>
      </div>
    </ng-template>


    <ng-template [ngSwitchCase]="([7,8].includes(question.type) ? question.type : -1)">

      <div class="type_multi_radio">
        <table class="table table-striped table-sm">
          <thead>
            <ng-container *ngFor="let sub_questions of question.sub_questions; let i=index">
              <ng-container *ngIf="i<1">
                <tr>
                  <th></th>
                  <th class="align-center" *ngFor="let alternative of sub_questions.alternatives" >{{alternative.statement}}</th>
                </tr>
              </ng-container>
            </ng-container>
          </thead>
          <tbody>
            <tr *ngFor="let sub_questions of question.sub_questions">
              <th>{{sub_questions.statement}}</th>
              <td class="align-center" *ngFor="let alternative of sub_questions.alternatives" [class.selected]="getAnswers(question,alternative.id,sub_questions)">
                <i *ngIf="getAnswers(question,alternative.id,sub_questions)" class="icon-fas-circle"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-template>

    <ng-template ngSwitchDefault>
      <div class="type_comment">
        <textarea [name]="question.id" [id]="question.id" class="form-control" rows="4" readonly="">{{getAnswers(question) || ''}}</textarea>
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-template #conditionalQuestion let-question="question" let-conditional="conditional" let-sub_question="sub_question">
  <ng-container *ngIf="getAnswers(question,conditional.id,sub_question)">

    <ng-container *ngFor="let conditional_question of conditional.conditional_questions; let i=index">
      <h5 class="conditional_title" *ngIf="i==0"> {{'shared.survey.conditional_title' | translate }}</h5>
      <h3 class="question_title" [attr.data-id]="conditional_question.id" >{{conditional_question.statement}}</h3>
      <ng-container *ngTemplateOutlet="typeGrid;context:{question: conditional_question }"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #spinner>
  <mat-progress-spinner
    class="example-margin"
    [mode]="'indeterminate'">
  </mat-progress-spinner>
</ng-template>
