import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'mh-core-lib-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class CoreLibTextInputComponent {
  @Input()
  formGroup: FormGroup;

  @Input()
  hasIcon = false;

  @Input()
  onlyNumber = false;

  @Input()
  iconClass;

  @Input()
  keyControl;

  @Input()
  placeholder = '';

  @Input()
  className = '';

  @Input()
  minDate;

  @Input()
  type = 'text';

  @Input()
  maxLength: number;

  @Input() set clearControl(value: string) {
    if (value) {
      this.formGroup.get(this.keyControl).reset();
    }
  }

  @Output()
  clicked = new EventEmitter();

  handleClick() {
    this.clicked.emit();
  }
}
