import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ReportPeriodBase } from 'app/configuration/profile/Profile.model';

@Component({
  selector: 'mh-core-lib-nested-accordion',
  templateUrl: './nested-accordion.component.html',
  styleUrls: ['./nested-accordion.component.scss'],
})
export class CoreLibNestedAccordionComponent {
  @Input() items: ReportPeriodBase[];
  @Input() options: number;
  @Input() npsOptions: ReportPeriodBase[];
  @Input() hideMassiveActions = false;
  @Output() nestedDataChanged: EventEmitter<any> = new EventEmitter();

  expandedIndex = 0;

  onClick(e, option, alertItem, alertSubItem) {
    if (alertSubItem.type === 'simple') {
      if (alertItem.id === 6) {
        alertSubItem.channels[option].check = e.value;
        if (!e.value) {
          alertItem.allChecked[option] = false;
        } else {
          alertItem.allChecked[option] = alertItem.items.every((item) =>
            item.channels
              .filter((channel) => channel.id_alert_channel === option + 1)
              .every((channel) => channel.check),
          );
        }
        this.nestedDataChanged.emit({ alertItem, alertSubItem: alertSubItem.channels[option] });
        return;
      } else {
        if (this.hideMassiveActions) {
          alertSubItem.channels[option].check = e.value;
        } else {
          alertSubItem.items[e.id].check = e.value;
        }
      }
    } else {
      alertSubItem[e.name] = e.value;
      alertSubItem.allChecked = alertItem.items
        .map((item) => item.channels[alertSubItem.id_alert_channel - 1])
        .every((item) => item.detractor && item.passive && item.promoter);
      alertItem.allChecked[alertSubItem.id_alert_channel - 1] = alertSubItem.allChecked;
    }

    this.nestedDataChanged.emit({ alertItem, alertSubItem });
  }

  onMassiveClick(e, idx, item) {
    if (idx === 1 && item.id === 6) {
      return;
    }
    item.allChecked[idx] = e.value;
    item.items.forEach((product) => {
      const channelFound = product.channels.find((channel) => channel.id_alert_channel === idx + 1);
      if (channelFound) {
        if (e.id !== 6) {
          channelFound.allChecked = e.value;
          ['detractor', 'passive', 'promoter'].forEach((option: string) => {
            channelFound[option] = e.value;
          });
        } else {
          channelFound.check = e.value;
        }
      }
    });
    this.nestedDataChanged.emit({ alertItem: item });
  }
}
