import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable()
export class UsersService {

  constructor(private http: HttpClient) {}


  getByIds(rawParams: { [param: string]: string | string[] }): Observable<any> {
    const url = `${environment.customerPath}/users/by_ids`
    const params: HttpParams = new HttpParams({ fromObject: this.transformParams(rawParams) })

    return this.http.get(url, { params })
  }

  transformParams(params: object): {[param: string]: string} {
    return Object.keys(params).reduce((acum, key: string) => {
      return {
        ...acum,
        [key]: Array.isArray(params[key]) ?
        (<Array<string>>params[key]).join(',') :
        <string>params[key]
      }
    }, {})
  }
}

