import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersStatesHandlerComponent } from './users-states-handler/users-states-handler.component';

@NgModule({
  declarations: [
    UsersStatesHandlerComponent
  ],
  exports: [
    UsersStatesHandlerComponent
  ],
  imports: [
    CommonModule
  ]
})
export class StatesHandlersModule { }
