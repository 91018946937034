import { Component, OnDestroy, OnInit } from '@angular/core';
import { UtilService } from '../../shared/util.service';
import { SafeResourceUrl } from '@angular/platform-browser';
import { DateTime } from 'luxon';
import jsonData from './widget-data.json';
import { SessionService } from '../../_services';
import { WidgetDashboard } from '../models/WidgetDashboard.model.component';
import { FollowupPropertiesService } from 'app/inter-chain/followup/properties/followup-properties.service';
import { DateService } from 'app/shared/services/date.service';
import { Select, Store } from '@ngxs/store';
import { CurrentSurveyState } from 'app/states/survey/survey.state';
import { Observable, Subject, Subscription, forkJoin, of } from 'rxjs';
import { CustomersState } from 'app/states/customers/customers.state';
import { OnlinePropertiesService } from 'app/inter-chain/online/properties/online-properties.service';
import { InterChainPrestayService } from 'app/inter-chain/prestay/inter-chain-prestay.service';
import { SurveyWrapperService } from 'app/shared/surveys/survey-wrapper/survey-wrapper.service';
import { CasesPropertiesService } from 'app/inter-chain/cases/services/cases-properties.service';
import { PSGeneralConfigurationService } from 'app/prestay/configuration/general/prestay-general-configuration.service';
import { VoicesService } from 'app/new-fs/voices/voices.service';
import { CreateCaseDialogService } from 'app/shared/core-lib-modal-services/cases/create-case-dialog.service';
import { catchError, map, scan } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { MenusState } from 'app/states/menus/menus.state';

@Component({
  selector: 'mh-new-root-dashboard',
  templateUrl: './new-root-dashboard.component.html',
  styleUrls: ['./new-root-dashboard.component.scss'],
  providers: [FollowupPropertiesService, InterChainPrestayService],
})
export class NewRootDasboardComponent implements OnInit, OnDestroy {
  @Select(CustomersState.currentCustomer) customersReady$: Observable<any[]>;
  @Select(CurrentSurveyState.currentSurvey) surveys$: Observable<any>;
  @Select(MenusState.currentMenuSidebar) sidebarMenu$;
  subscriptions = new Subscription();
  widget: SafeResourceUrl;
  today: string = DateTime.now().toFormat('yyyy-MM-dd');
  monthAgo: string = DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd');
  localTime: number = DateTime.now().hour;
  greeting: string;
  data: WidgetDashboard[] = jsonData;
  customer;
  allProducts = ['prestay', 'prestay-orders', 'onsite', 'followup', 'online', 'cases', 'semantic', 'voices'];
  productsData: any = [];
  productsToFetch = [];
  availableWidgets;
  unavailableWidgets;
  prestayProperties;
  loading = false;
  takeFirst = false;
  currentLanguage = 'es';
  sidebarMenu;

  private error$ = new Subject<any>();
  public errorObservable$ = this.error$.asObservable().pipe(scan((acc, curr) => (acc = acc.concat(curr)), []));

  constructor(
    private utilService: UtilService,
    private sessionService: SessionService,
    private dateService: DateService,
    private followupOnsiteNpsService: FollowupPropertiesService,
    private onlinePropertiesService: OnlinePropertiesService,
    private interChainPrestayService: InterChainPrestayService,
    private surveysService: SurveyWrapperService,
    private casesService: CasesPropertiesService,
    private psConfigService: PSGeneralConfigurationService,
    private voicesService: VoicesService,
    private createCaseDialogService: CreateCaseDialogService,
    private route: ActivatedRoute,
    private store: Store,
  ) {}

  ngOnInit() {
    this.sidebarMenu = this.store.selectSnapshot(MenusState.currentMenuSidebar).sidebar.map((item) => item.slug);
    this.allProducts = this.allProducts.filter((product) => {
      if (['prestay', 'prestay-orders'].includes(product) && this.sidebarMenu.includes('prestay')) {
        return true;
      } else if ('cases' === product && this.sidebarMenu.includes('desk')) {
        return true;
      } else {
        return this.sidebarMenu.includes(product);
      }
    });
    this.subscriptions
      .add(
        this.customersReady$.subscribe((customer) => {
          if (customer && !this.takeFirst) {
            this.checkCreateCaseParam();
            this.takeFirst = true;
            this.customer = customer;
            this.prestayProperties = this.psConfigService.getPSConfigValue();
            this.getAvailableProducts();
            this.getSurveys();
          }
        }),
      )
      .add(
        this.utilService.isLanguageChanged().subscribe(() => {
          this.refresWidgetUrl();
        }),
      )
      .add(
        this.sidebarMenu$.subscribe((menus) => {
          if (menus.sidebar?.length > 0) {
            this.sidebarMenu = menus.sidebar.map((item) => item.slug);
            this.allProducts = [
              'prestay',
              'prestay-orders',
              'onsite',
              'followup',
              'online',
              'cases',
              'semantic',
              'voices',
            ];
            this.allProducts = this.allProducts.filter((product) => {
              if (['prestay', 'prestay-orders'].includes(product) && this.sidebarMenu.includes('prestay')) {
                return true;
              } else if ('cases' === product && this.sidebarMenu.includes('desk')) {
                return true;
              } else {
                return this.sidebarMenu.includes(product);
              }
            });
          }
        }),
      );

    this.refresWidgetUrl();
    this.greeting = this.getGreeting();
  }

  checkCreateCaseParam() {
    this.subscriptions.add(
      this.route.queryParams.subscribe((params) => {
        if (params?.create_case) {
          this.createCaseDialogService.openModal(this.utilService.currentUser);
        }
      }),
    );
  }

  getSurveys() {
    this.surveysService.getCustomerSurveys([], 1, false, this.customer);
  }

  getAvailableProducts() {
    const entries = Object.entries(this.customer);
    const availableProductsNames = entries
      .filter(([key, value]) => this.allProducts.includes(key) && value)
      .map((product) => product[0]);

    const unavailableProductsNames = entries
      .filter(([key, value]) => this.allProducts.includes(key) && !value)
      .map((product) => product[0]);

    if (unavailableProductsNames.includes('prestay')) {
      unavailableProductsNames.splice(unavailableProductsNames.indexOf('prestay') + 1, 0, 'prestay-orders');
    }

    if (availableProductsNames.includes('prestay')) {
      if (this.prestayProperties?.service_active) {
        availableProductsNames.splice(availableProductsNames.indexOf('prestay') + 1, 0, 'prestay-orders');
      } else {
        unavailableProductsNames.splice(unavailableProductsNames.indexOf('prestay') + 1, 0, 'prestay-orders');
      }
      if (!this.prestayProperties?.checkin_active) {
        availableProductsNames.splice(availableProductsNames.indexOf('prestay'), 1);
        unavailableProductsNames.push('prestay');
      }
    }

    this.availableWidgets = this.data.filter((product) => availableProductsNames.includes(product.key));
    this.unavailableWidgets = this.data
      .filter((product) => unavailableProductsNames.includes(product.key))
      .map((product) => ({ ...product, enable: false }));
    this.getWidgetData(availableProductsNames);
  }

  async getWidgetData(products) {
    try {
      this.loading = true;
      this.productsData = [];
      const date = { startDate: this.monthAgo, endDate: this.today };
      const customerId = this.customer.id;
      const version = 2;
      const languageId = this.utilService.getCurrentLanguageId();
      const followupSurveys: any = await this.surveysService.getChainCustomerSurveys(customerId, 1, false).toPromise();
      const onsiteSurveys: any = await this.surveysService.getChainCustomerSurveys(customerId, 2, false).toPromise();
      const followupSurveyIds = products.includes('followup') ? followupSurveys?.map((item) => item.id) || [] : null;
      const onsiteSurveyIds = products.includes('onsite') ? onsiteSurveys?.map((item) => item.id) || [] : null;

      const observables = {
        prestay: this.interChainPrestayService
          .getSummaryCheckin(customerId, date)
          .pipe(this.handleRequest().bind(this)),
        'prestay-orders': this.interChainPrestayService
          .getSummarySales(customerId, date)
          .pipe(this.handleRequest().bind(this)),
        onsite: this.followupOnsiteNpsService
          .getNPS(date, customerId, 2, onsiteSurveyIds, version)
          .pipe(this.handleRequest().bind(this)),
        followup: this.followupOnsiteNpsService
          .getNPS(date, customerId, 1, followupSurveyIds, version)
          .pipe(this.handleRequest().bind(this)),
        online: this.onlinePropertiesService
          .getSummary(date, customerId, 3, languageId)
          .pipe(this.handleRequest().bind(this)),
        cases: this.casesService.getDeskCorpByStatus(customerId, date).pipe(this.handleRequest().bind(this)),
        semantic: this.followupOnsiteNpsService
          .getAllSemanticIndex(date, customerId)
          .pipe(this.handleRequest().bind(this)),
        voices: this.voicesService
          .getOpinionsByCustomerId(customerId, date.startDate, date.endDate, 0, 10, '')
          .pipe(this.handleRequest().bind(this)),
      };

      this.productsToFetch = Object.keys(observables).filter((product) => products.includes(product));
      const observablesArray = Object.entries(observables)
        .filter(([key, value]) => {
          if (this.productsToFetch.includes(key)) {
            return value;
          }
        })
        .map((item) => ({ [item[0]]: item[1] }));

      const newObj = Object.assign({}, ...observablesArray);
      forkJoin([...Object.entries(newObj).map((item) => item[1])])
        .pipe(map((result) => Object.values(result)))
        .subscribe(
          (data) => {
            this.productsData = data;
          },
          (err) => {
            console.error(err);
            this.setWidgetData(this.productsData);
            this.loading = false;
          },
          () => {
            this.setWidgetData(this.productsData);
            this.loading = false;
          },
        );
    } catch (error) {
      console.error(error);
    }
  }

  handleRequest() {
    return function (observable: Observable<any>) {
      return observable.pipe(
        map((result) => result),
        catchError((err) => {
          this.error$.next(err);
          return of(null);
        }),
      );
    };
  }

  setWidgetData(products) {
    const productData: any = {};
    for (const [index, value] of products.entries()) {
      productData[this.productsToFetch[index]] = value;
    }
    this.availableWidgets.forEach((widget) => {
      switch (widget.key) {
        case 'prestay':
          if (productData.prestay?.length > 0) {
            widget.value = productData.prestay[0].checkins.actual;
            widget.variation = productData.prestay[0].checkins.variation;
            widget.variation_type = productData.prestay[0].checkins.variation > 0 ? 'positive' : 'negative';
            return;
          }
          widget.error = true;
          break;
        case 'prestay-orders':
          if (productData['prestay-orders'][0]) {
            widget.value = productData['prestay-orders'][0].total_price.actual;
            widget.variation = productData['prestay-orders'][0].total_price.variation;
            widget.variation_type =
              productData['prestay-orders'][0].total_price.variation > 0 ? 'positive' : 'negative';
            return;
          }
          widget.error = true;
          break;
        case 'onsite':
          if (productData.onsite) {
            widget.value = productData.onsite.average.nps.actual;
            widget.variation = productData.onsite.average.nps.variation;
            widget.variation_type = productData.onsite.average.nps.variation > 0 ? 'positive' : 'negative';
            return;
          }
          widget.error = true;
          break;
        case 'followup':
          if (productData.followup) {
            widget.value = productData.followup.average.nps.actual;
            widget.variation = productData.followup.average.nps.variation;
            widget.variation_type = productData.followup.average.nps.variation > 0 ? 'positive' : 'negative';
            return;
          }
          widget.error = true;
          break;
        case 'online':
          if (productData.online) {
            widget.value = productData.online.average.qualification.actual;
            widget.variation = productData.online.average.qualification.variation;
            widget.variation_type = productData.online.average.qualification.variation > 0 ? 'positive' : 'negative';
            return;
          }
          widget.error = true;
          break;
        case 'cases':
          if (productData.cases) {
            widget.value = `${productData.cases.average.details.actual.total_cases}/${productData.cases.average.details.actual.total_opened}`;
            widget.variation = productData.cases.average.details.variation.total_cases;
            widget.variation_type =
              productData.cases.average.details.variation.total_cases > 0 ? 'positive' : 'negative';
            return;
          }
          widget.error = true;
          break;
        case 'semantic':
          if (productData.semantic) {
            widget.value = productData.semantic.average.semantic_index.actual;
            widget.variation = productData.semantic.average.semantic_index.variation;
            widget.variation_type = productData.semantic.average.semantic_index.variation > 0 ? 'positive' : 'negative';
            return;
          }
          widget.error = true;
          break;
        case 'voices':
          if (productData.voices) {
            widget.value = productData.voices.total_of_elements;
            return;
          }
          widget.error = true;
          break;

        default:
          break;
      }
    });
    this.takeFirst = false;
  }

  refresWidgetUrl() {
    this.currentLanguage = this.utilService.getCurrentLanguage();
  }

  getGreeting() {
    if (this.localTime >= 5 && this.localTime < 12) {
      return 'dashboard.good_morning';
    }
    if (this.localTime >= 12 && this.localTime < 20) {
      return 'dashboard.good_afternoon';
    }
    return 'dashboard.good_night';
  }

  handleWidgetClick(type: string) {
    if (type === 'cases') {
      this.createCaseDialogService.openModal(this.utilService.currentUser);
    }
  }

  get currentUser() {
    return this.sessionService.getCurrentUser();
  }
  get currentDates() {
    return this.dateService.currentDates;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
