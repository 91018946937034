import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Log } from './Profile.model';
@Injectable()
export class ProfileService {
  constructor(private http: HttpClient) {}

  public SaveProfileData(userId, data) {
    const url = `${environment.customerUser}/${userId}/profile`;
    return this.http.put(url, data).pipe(map((response) => response));
  }

  public getSuperAdminData(user_id: string) {
    const url = `${environment.superAdminProperties}`;
    const params: HttpParams = new HttpParams().set('user_id', user_id);
    return this.http.get(url, { params: params }).pipe(map((response) => response));
  }

  public usersInCustomer(customerId) {
    const url = `${environment.customerUsers}`;
    const params: HttpParams = new HttpParams().set('customer_id', customerId);
    return this.http.get(url, { params }).pipe(map((response) => response));
  }

  public saveSuperAdminData(superAdminProperties) {
    const url = `${environment.superAdminProperties}`;
    return this.http.put(url, superAdminProperties).pipe(map((response) => response));
  }

  public changePassword(userId, body) {
    const url = `${environment.customerUser}/${userId}/profile_password`;
    return this.http.put(url, body).pipe(map((response) => response));
  }

  logUser(userId, customerId, clientIp) {
    const url = `${environment.customerById}/access/log`;
    const data: Log = {};
    data.userId = userId + '';
    data.customerId = customerId + '';
    data.ip = clientIp + '';

    return this.http.post(url, data).pipe(map((response) => response));
  }

  getClientIp() {
    const url = 'https://api.ipify.org?format=jsonp';

    return this.http.jsonp(url, 'callback').pipe(map((response) => response));
  }

  public setPeriodReport(payload) {
    const url = `${environment.customerPath}automatic-reports/customers/${payload.customer_id}/config`;
    const params: HttpParams = new HttpParams()
      .append('user_id', payload.user_id)
      .append('flag_daily', payload.flag_daily)
      .append('flag_weekly', payload.flag_weekly)
      .append('flag_monthly', payload.flag_monthly);

    return this.http.put(url, {}, { params }).pipe(map((response) => response));
  }

  public getPeriodReport(payload) {
    const url = `${environment.customerPath}automatic-reports/customers/${payload.customer_id}/users/${payload.user_id}`;

    return this.http.get(url).pipe(map((response) => response));
  }
}
