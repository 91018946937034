import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Invoice, UrlInvoice, TotalInvoice } from '../models/invoice.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private http: HttpClient) { }

  getInvoices(customerId: string, payload: any): Observable<Invoice> {
    const url = `${environment.customerPath}customers/${customerId}/invoices`;
    const { start_date, end_date, page, page_size, status, column_name, sort } = payload;
    const params = new HttpParams()
      .set('start_date', start_date)
      .set('end_date', end_date)
      .set('page', page)
      .set('page_size', page_size)
      .set('status', status)
      .set('column_name', column_name)
      .set('sort', sort)
    return this.http.get<Invoice>(url, {params});
  }

  getUrlInvoice(customerId: string, invoiceId: string): Observable<UrlInvoice> {
    const url = `${environment.customerPath}customers/${customerId}/invoices/${invoiceId}/landing`;
    return this.http.get<UrlInvoice>(url);
  }

  getTotalAmount(customerId: string, payload: any): Observable<TotalInvoice> {
    const url = `${environment.customerPath}customers/${customerId}/invoices/totals`;
    const { start_date, end_date } = payload;
    const params = new HttpParams()
      .set('start_date', start_date)
      .set('end_date', end_date)
    return this.http.get<TotalInvoice>(url, {params});
  }
}
