import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'app/logs/logs-timeline/services/users.service';
import { Log } from 'app/shared/logs/types';

@Component({
  selector: 'mh-log-card',
  templateUrl: './log-card.component.html',
  styleUrls: ['./log-card.component.scss'],
  providers: [UsersService]
})
export class LogCardComponent implements OnInit {
  @Input() log: Log;
  @Input() language: string;
  @Input() commercialExecutivesList = [];

  userManage: string;
  dateManage: string;
  surveyIdManage: string | number;

  EXCLUDED_FIELDS = ['updated_at', 'id', 'deleted', 'created_at', 'lastActivity', 'typeid', ',item_id', 'product_id'];

  constructor(private usersService: UsersService, private translate: TranslateService) {}

  ngOnInit() {
    this.getCommercialExecutiveNames();
    this.setDataManaged();
  }

  getValueOrDash(value: any) {return value === false ? false : value || '-' }

  getTranslateParam(change: any) {
    return {
      actual: this.getValueOrDash(this.getAreasValue(change, 'actual') || this.getAreasValue(change, 'tkpi')),
      previous: this.getValueOrDash(this.getAreasValue(change, 'previous')|| this.getAreasValue(change, 'tkpi_previous'))
    }
  }

  // el for Translation hace referencia al nombre del cambio
  getTranslateParamForTranslation(change: any) {
    return {
      actual: this.getValueOrDash(change.actual && change.actual.fieldValue),
      previous: this.getValueOrDash(change.previous && change.previous.fieldValue),
    }
  }

  getAreasValue(change, type) {
    if (change && !change.name) return '-';

    if (type === 'actual' && !['related_area', 'polarity'].includes(change.name)) return change.actual;
    if (type === 'previous' && !['related_area', 'polarity'].includes(change.name)) return change.previous;
    if (type === 'tkpi' && change.name !== 'related_area') return change.tkpi_value_dto.actual;
    if (type === 'tkpi_previous' && change.name !== 'related_area') return change.tkpi_value_dto.previous;

    if (type === 'actual' && change.name === 'related_area') {
      return (this.language === 'es') ?
        change.actual?.area_entity.clasification_text || '-':
        change.actual?.area_entity.clasification_text_en || '-';
    }

    if (type === 'previous' && change.name === 'related_area') {
      return (this.language === 'es') ?
        change.previous?.area_entity.clasification_text || '-':
        change.previous?.area_entity.clasification_text_en || '-';
    }

    if (change.name === 'polarity') {
      return { name: this.translate.instant('shared.semantic.type_comment.' + change[type]?.value) || '' };
    }

    if (type === 'tkpi' && change.name === 'related_area') {
      return (this.language === 'es') ?
        change.tkpi_value_dto.actual?.area_entity.clasification_text || '-':
        change.tkpi_value_dto.actual?.area_entity.clasification_text_en || '-';
    }

    if (type === 'tkpi_previous' && change.name === 'related_area') {
      return (this.language === 'es') ?
        change.tkpi_value_dto.previous?.area_entity.clasification_text || '-':
        change.tkpi_value_dto.previous?.area_entity.clasification_text_en || '-';
    }

  }

  getCommercialExecutiveNames() {
    if (this.commercialExecutivesList.length) {
      this.log.changes.map((change) => {
        if (change.name === "commercial_executive") {
          const prevExecutive = this.commercialExecutivesList.find((executive) => change.previous === executive.id);
          change.previous = prevExecutive ? `${prevExecutive['first_name']}  ${prevExecutive['last_name']}` : change.previous;

          const currExecutive = this.commercialExecutivesList.find((executive) => change.actual === executive.id);
          change.actual = currExecutive ? `${currExecutive['first_name']}  ${currExecutive['last_name']}` : change.actual;
        }
      });
    }
  }

  setDataManaged() {
    if ('answered_survey' === this.log.resourceName) {
      this.getUserName(''+this.log.changes[0].user_id);
      this.dateManage = this.log.changes[0].action_date;
      this.surveyIdManage = this.log.changes[0].answered_survey_id;
    } else if ('surveys' === this.log.resourceName) {
      this.getUserName(''+this.log.changes[0].customerId);
      this.surveyIdManage = this.log.changes[0].surveyId;
    }
  }

  get isReviewLog() {
    return this.log.resourceName === 'review_reply';
  }

  get isReviewManaged() {
    return this.log.resourceName === 'answered_survey';
  }

  get isSentiment() {
    return this.log.resourceName === 'modify_semantic_comments';
  }

  get isSurveyAnsweredDeleted() {
    return this.log.resourceName === 'surveys';
  }

  get isReprocessSurvey() {
    return this.log.resourceName === 'recalculate_stats';
  }

  getUserName(users_ids) {
    this.usersService.getByIds({users_ids}).subscribe((result) => {
      this.userManage = result[0].complete_name;
    });
  }

}
