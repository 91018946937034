import { Pipe, PipeTransform } from '@angular/core'

import { Survey } from "app/shared/model/index";
import { FollowupOnsiteGuestFilter } from 'app/states/followup-onsite/followup-onsite.state';

@Pipe({ name: 'followupOnsiteContactedSurveyFilter' })
export class FollowupOnsiteContactedFilterPipe implements PipeTransform {

  transform(value: Survey[], filters: FollowupOnsiteGuestFilter): Survey[] {
    if(filters.contacted) {

      if(!filters.contacted.contacted && !filters.contacted.non_contacted) return value;
      const filtered = value.filter(survey =>
        (survey?.guest?.managed && filters.contacted.contacted) ||
        (!survey?.guest?.managed && filters.contacted.non_contacted)
      );
      return filtered;
    } else {
      return value;
    }
  }
}
