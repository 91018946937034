import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from "../../environments/environment";
import { TranslateGenericRes } from './model/customer.model';
import { Customer } from './customer.model';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {

  constructor(private http: HttpClient) { }

  findAll(flag: string, showPrivates = false): Observable<Customer[]> {
    const url = `${environment.customersByFlag}` + flag;
    let params: HttpParams = new HttpParams()
    if (showPrivates) params = params.set("show_privates", 'true');
    return this.http.get<Customer[]>(url, { params: params })
  }

  findHotelByNickname(nickname) {
    const url = `${environment.customerPath}nick-id/` + nickname;
    const params: HttpParams = new HttpParams();
    return this.http.get(url, { params: params })
  }

  findByIds(ids: string) {
    const url = `${environment.customerByIds}`;
    const params: HttpParams = new HttpParams()
      .set("ids", ids);
    return this.http.get(url, { params: params })
  }

  findHotel(id) {
    const url = `${environment.customerById}` + id;
    const params: HttpParams = new HttpParams();
    return this.http.get(url, { params: params })
  }

  findCustomer(id) {
    return this.findHotel(id);
  }

  checkIntegrationStatus(customer_id) {
    const url = `${environment.customerById}` + customer_id + '/integration/state';
    const params: HttpParams = new HttpParams();
    return this.http.get(url, { params: params })
  }

  getListUTC() {
    const url = `${environment.customerById}commons/utc/list`;
    const params: HttpParams = new HttpParams();
    return this.http.get(url, { params: params })
      .pipe(
        map(response => response)
      )
  }

  getListChain() {
    const url = `${environment.customerById}commons/chain/name/list`;
    const params: HttpParams = new HttpParams();
    return this.http.get(url, { params: params })
      .pipe(
        map(response => response)
      )
  }

  getCommons() {
    let list_utc = this.getListUTC();
    let list_chain = this.getListChain();
    forkJoin([list_utc, list_chain]).subscribe(results => {
      console.log(results);
    }, () => {

    });
  }

  getContactTypes() {
    const url = `${environment.customerById}commons/contact/type/list`;
    const params: HttpParams = new HttpParams();
    return this.http.get(url, { params: params })
  }

  getApiKey(customer_id) {
    const url = `${environment.customerPath}customers/${customer_id}/encryptor`;
    const params: HttpParams = new HttpParams();
    return this.http.get(url, { params: params })
  }

  findByWord(words, clientType, showPrivates) {
    const url = `${environment.customerPath}find/`;
    const params: HttpParams = new HttpParams()
      .set("query", words)
      .set("type", clientType)
      .set("show_privates", showPrivates);
    return this.http.get(url, { params: params })
      .pipe(
        map(response => response)
      )
  }

  findCorporativeClientById(customer_id, user_id?) {
    const url = `${environment.customerPath}customers/${customer_id}/corporative`
    const params = (user_id) ?
      new HttpParams().set('user_id', user_id) :
      new HttpParams();
    return this.http.get(url, {params});
  }

  getPrestayCustomers() {
    const url = `${environment.prestayPath}customer/active`;
    return this.http.get(url);
  }

  getTranslateSourceToTarget(data: { text: string }, sourceId: string, targetIds: string) {
    let params = new HttpParams()
    params = params.append('source_language_id', sourceId)
    params = params.append('target_language_ids', targetIds)
    return this.http.post<TranslateGenericRes[]>(`${environment.customerPath}translate/generic`, data, { params })
      .toPromise()
  }

  async getIsChain(currentHotel, currentUser) {
    let corporativeData;
    if (currentHotel && currentUser) {
      try {
        corporativeData = await this.findCorporativeClientById(currentHotel.id, currentUser.id).toPromise();
      } catch(error) {
        console.error(error.message);
      }
    }

    return corporativeData?.is_chain || false;
  }
}
