import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private snackbar: MatSnackBar, private translateService: TranslateService) {}

  async handleAlert(
    message: string,
    duration = 2000,
    verticalPosition = 'top',
    horizontalPosition = 'center',
    panelClass = 'snackbar-panel',
    fromComponent = null,
    data?,
  ) {
    const messageTranslate =
      !data?.translateKey && !data?.translateValue
        ? await this.translateService.get(message).toPromise()
        : await this.translateService.get(message, { [data.translateKey]: data.translateValue }).toPromise();

    const snackbarOptions = <MatSnackBarConfig>{
      duration: duration,
      panelClass,
      verticalPosition: verticalPosition,
      horizontalPosition,
      data: data ? { message: messageTranslate, ...data } : null,
    };

    if (fromComponent) {
      this.snackbar.openFromComponent(fromComponent, snackbarOptions);
    } else {
      this.snackbar.open(messageTranslate, undefined, snackbarOptions);
    }
  }
}
