<ng-container *ngIf="progressRate">
  <div class="tw-flex tw-justify-between">
    <div>
      <p class="tw-text-xl tw-font-semibold" [innerHTML]="'user_management.create_user.accesses_title' | translate"></p>
    </div>
    <div class="tw-flex tw-gap-1">
      <div class="tw-mb-5 tw-h-2 tw-rounded-full tw-bg-gray-200 tw-w-80 tw-mt-[10px]">
        <div class="tw-h-2 tw-rounded-full tw-bg-[#0F93CC] tw-w-[50%]"></div>
      </div>
      <p class="tw-text-xl">{{ progressRate }}%</p>
    </div>
  </div>
  
  <div class="tw-flex tw-my-3">
    <p class="tw-text-xl tw-text-notification-text-time tw-font-semibold">{{ 'user_management.create_user.accesses_sub_title' | translate }}</p>
  </div> 
</ng-container>

<div class="tw-flex">
  <div class="tw-flex tw-flex-col tw-w-1/2">
    <div class="tw-flex tw-my-5">
      <mh-hotel-search-accesses [customers]="customers || []" [hotelsSelected]="hotelsSelecteds" (customersUpdated)="setSelectedHotels($event)" ></mh-hotel-search-accesses>
      <mh-hotel-search-accesses class="tw-mx-10" [customers]="userChains || []" [hotelsSelected]="chainSelecteds" placeholder="add_chain" (customersUpdated)="setChainSelected($event)" *ngIf="customers?.length"></mh-hotel-search-accesses>
    </div>
    
    <div class="{{height}} container-table tw-overflow-y-auto">
      <mh-core-lib-main-content>
        <mh-core-lib-table [showPaginator]="false" [showFooter]="!hotels?.length">
          <ng-container thead>
            <ng-container *ngTemplateOutlet="head"></ng-container>
          </ng-container>
          <ng-container tbody>
            <ng-container *ngTemplateOutlet="body"></ng-container>
          </ng-container>
        </mh-core-lib-table>
      </mh-core-lib-main-content>
    </div>
  </div>
 
  <div class="tw-w-1/2 tw-self-start tw-flex tw-justify-center">
    <img src="https://mh-statics.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/user-manager/hotel.png"/>
  </div>
</div>

<div class="tw-flex tw-justify-center tw-mt-40 tw-gap-5" *ngIf="progressRate; else saveChanges">
  <mh-core-lib-button [isSecondary]="true" [isDisabled]="false" [text]="('commons.back' | translate)" (click)="updateIndexEmit(false)"></mh-core-lib-button>
  <mh-core-lib-button [isDefault]="true" [isDisabled]="hotelsSelecteds?.length === 0" [text]="('user_management.create_user.next' | translate)" (click)="updateIndexEmit(true)"></mh-core-lib-button>
</div>

<ng-template #saveChanges>
  <div class="tw-flex tw-justify-center tw-mt-10">
    <mh-core-lib-button [isDefault]="true" [text]="('commons.save_changes' | translate)" (clicked)="updateIndexEmit(true)"></mh-core-lib-button>
  </div>
</ng-template>

<ng-template #head>
  <tr>
    <th>
      {{ 'user_management.actions.assign_properties.table.property' | translate }}
    </th>
    <th>
      {{ 'commons.chain' | translate }}
    </th>
    <th>
      {{ 'models.hotel_dto.chain_property.region' | translate }}
    </th>
    <th>
      &nbsp;
    </th>
  </tr>
</ng-template>

<ng-template #body>
  <ng-container *ngFor="let hotel of hotels">
    <tr *ngIf="hotel">
      <td>
        {{ hotel?.name }}
      </td>
      <td>{{ hotel?.chain_property?.chain_id?.name }}</td>
      <td>{{ hotel?.chain_property?.region }}</td>
      <td><i class="fal fa-trash-alt tw-cursor-pointer" (click)="deleteHotel(hotel)"></i></td>
    </tr>
  </ng-container>
</ng-template>