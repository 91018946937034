import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mh-survey-question-public',
  templateUrl: './survey-question.component.html',
  styleUrls: ['./survey-question.component.scss']
})
export class SurveyQuestionComponent implements OnInit {
  // Deuda tecnica, este componente debiese recibir una pregunnta y su respuesta nomas
  @Input() question:any;
  @Input() answerId: number;
  @Input() answers:any[];
  @Input('conditionalQuestions') conditionalQuestionsInput:any[];
  @Input() language:string;
  @Input() languageId:number;
  @Input() surveyLanguageId:number;
  @Input() answered:boolean;
  @Input() preview:boolean;
  @Input() productId:number;
  @Input() answersComments;

  @Input() conditionalId?:number;

  @Output() answer:EventEmitter<any> = new EventEmitter();

  conditionalQuestions: {questions:any[], alternativeId:number, active: boolean}[] = []

  constructor() { }

  ngOnInit() {
    this.setConditionalQuestions();
    this.updateConditionalQuestions();
  }

  ngOnChanges() {
    this.updateConditionalQuestions();
  }

  setConditionalQuestions() {
    this.conditionalQuestions = this.question.alternatives.map((alt) => ({
      alternativeId: alt.id,
      active: false,
      questions: alt.conditional_questions
        .map(({conditional_question_id}) => conditional_question_id)
        .map((condId:number) => this.conditionalQuestionsInput.find(({id}) => id === condId))
        .filter(Boolean)
        .sort((obj1:any, obj2:any) => obj1.conditional_order - obj2.conditional_order)
    }))
  }

  handleAnswer(answer:any) {
    this.answer.emit(answer)
  }

  updateConditionalQuestions() {
    this.conditionalQuestions.forEach(conditional => {
      conditional.active = this.getAnsweredAlternativeIds().includes(conditional.alternativeId)
    })
  }

  getAnsweredAlternativeIds() {
    return this.answers
      .filter(({question_id, selected}) => question_id === this.question.id && selected)
      .map(a => a.answer_alternative_id)
  }

  getActiveConditionalQuestions() {
    const selectedAlternativeAnswers = this.answers
      .filter(({question_id, selected}) => question_id === this.question.id && selected)
    const selectedAlternatives = this.question.alternatives
      .filter(({id}) => (
        selectedAlternativeAnswers.map(({answer_alternative_id}) => answer_alternative_id)
          .includes(id)
      ))
    return selectedAlternatives
      .reduce((acum:any[], alt:any) => {
        return [
          ...acum,
          {
            alternativeId: alt.id,
            questions: alt.conditional_questions
              .map(({conditional_question_id}) => conditional_question_id)
              .map((condId:number) => this.conditionalQuestionsInput.find(({id}) => id === condId))
              .filter(Boolean)
              .sort((obj1:any, obj2:any) => obj1.conditional_order - obj2.conditional_order)
          }
        ]
      }, [])
      .filter(q => q.questions.length)
  }

  get isOneRowGrid() {
    return this.question.alternatives.lenght === 1;
  }

  get isGrid() {
    return this.question.alternatives[0].alternative_type.name === 'grid';
  }

  get isInvalid() {
    return !this.question.valid;
  }
}
