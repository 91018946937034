import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class TrendsService {

  constructor(private http:HttpClient) { }

  public getHistoric(url: string, dates:any,customerIds:string, productId:any,languageId: any=undefined, segmented_by="months", surveysIds){
    let params:HttpParams= new HttpParams()
      .set("start_date",dates.startDate)
      .set("end_date",dates.endDate)
      .set("customers_ids",customerIds)
      .set("customer_ids",customerIds)
      .set("product_id",productId)
      .set("segmented_by",segmented_by)
      .set("surveys_ids", surveysIds)
    if(languageId) params = params.set("language_id",languageId);
    return this.http.get(url,{params: params})
      .pipe(
        map(response=>response)
      )
  }

  getHistoricNPS(dates:any,customerIds:string, productId:any,language, segmented_by="months", surveysIds, version = 1){
    const url = (version === 1)
      ? `${environment.followupPath}interchains/nps/metricts/historic/data`
      : `${environment.followupPath}corporative/nps/trends`;
    return this.getHistoric(url, dates, customerIds, productId, language, segmented_by,surveysIds);
  }

  getHistoricPMS(dates:any,customerIds:string, productId:any,language, segmented_by="months", surveysIds, version = 1){
    const url = `${environment.followupPath}interchains/pms/emails/historic/data`;
    return this.getHistoric(url, dates, customerIds, productId,language, segmented_by,surveysIds);
  }

  getHistoricArea(dates:any,customerIds:string, productId:any,language, segmented_by="months", surveysIds, version = 1){
    const url = (version === 1)
      ? `${environment.followupPath}interchains/ranking/areas/historic/data`
      : `${environment.followupPath}corporative/areas/trends`

    const format = (version === 1) ? r => r : this.formatResultV2ToV1;

    return this.getHistoric(url, dates, customerIds, productId,language,segmented_by,surveysIds)
      .pipe(map(format))
  }

  getEmailsArea(dates:any,customerIds:string, productId:any,language, segmented_by="months", surveysIds, version = 1){
    const url = (version === 1)
      ? `${environment.followupPath}interchains/surveys/emails/historic/data`
      : `${environment.followupPath}corporative/surveys/response/rate/trends`;
    return this.getHistoric(url, dates, customerIds, productId,language, segmented_by,surveysIds);
  }

  getTripAdvisor(dates:any,customerIds:string, productId:any,language, segmented_by="months", surveysIds, version = 1){
    const url=`${environment.onlinePath}interchains/tripadvisor/historic/data`;
    return this.getHistoric(url, dates, customerIds, productId,language, segmented_by,surveysIds);
  }

  private formatResultV2ToV1(detail) {
    function formatPeriod(period:any) {
      return {
        ...period,
        data: {
          ranking_areas: {
            areas: period.data.areas.map(area => ({
              ...area,
              id_area: area.area_id,
              note: area.score,
            })),
          },
        },
      }
    }

    return {
      average: {
        periods: detail.average.periods.map(formatPeriod),
      },
      values: detail.values.map(val => ({
        ...val,
        periods: val.periods.map(formatPeriod),
      })),
    }
  }

  getScore(dates, customerIds, productId, language, segmented_by="months", surveysIds, version) {
    const url = `${environment.followupPath}corporative/surveys/qualification/trends`;
    return this.getHistoric(url, dates, customerIds, productId, language, segmented_by, surveysIds);
  }

  getSemanticIndex(dates, customerIds, productId, language, segmented_by="months", surveysIds, version) {
    const url = `${environment.customerPath}customers/semantic/index/trends`;
    return this.getHistoric(url, dates, customerIds, productId, language, segmented_by, surveysIds);
  }
}
