import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {environment} from "src/environments/environment";
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SurveyWrapperService {

  constructor(private http:HttpClient) { }

  getCustomerSurveys(customerId, productId, showDetail = false, surveyTypeId=1){
    const url=`${environment.surveysPath}customers/${customerId}/surveys`;
    let params = new HttpParams({
      fromObject : {
        product_id: productId,
        show_detail: showDetail.toString()
      }
    });

    if (surveyTypeId !== undefined && surveyTypeId !== null) {
      params = params.append("survey_type_id", surveyTypeId)
    }

    return this.http.get(url, {params: params})
      .pipe(
        map(response=>response)
      );
  }


  getChainCustomerSurveys(customersIds:string, productId, showDetail = false, surveyTypeId: any =null){
    const url=`${environment.surveysPath}customers/surveys`;
    let params:HttpParams = new HttpParams()
      .set("product_id",productId)
      .set("show_detail",showDetail.toString());
    if(surveyTypeId) params = params.set("survey_type_id",surveyTypeId.toString());
    params = params.set("customers_ids",customersIds);

    return this.http.get(url, {params: params})
      .pipe(
        map(response=>response)
      );
  }
}
