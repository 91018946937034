<ng-container *ngFor="let data of groupDataTable; let step=index">
  <h5 *ngIf="data.values.length > 0">{{'prestay.precheckin.form.'+(step+1) | translate }}</h5>
  <div class="mb-3" *ngIf="data.step !== 3">
    <mh-precheckin-guest-detail-table [dataTable]="data.values" [waitingDownload]="waitingDownload"
    (file)="downloadFile($event)"></mh-precheckin-guest-detail-table>
  </div>
  <div class="mb-3" *ngIf="data.step === 3 && data.values.length > 0">
    <div class="mb-2" *ngFor="let companion of data.values; let i=index">
      <h6>{{('models.preckeckin-guest.companion_label' | translate)}} {{i+1}}</h6>
      <mh-precheckin-guest-detail-table [dataTable]="companion" [waitingDownload]="waitingDownload"
      (file)="downloadFile($event)"></mh-precheckin-guest-detail-table>
    </div>
  </div>
</ng-container>
