import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  Observable,
  throwError as ObservableThrowError,
} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from "src/environments/environment";
import { CheckSetup, CheckSetupRes } from 'app/shared/model/check-setup.model';
import { IOauthStatusResult } from 'app/shared/model/check-oauth-status.model';

@Injectable()
export class OnlineService {

  constructor(
    private http:HttpClient,
  ) { }

  private _handleError(err: HttpErrorResponse | any): Observable<any> {
    const errorMsg = err.message || 'Error: No se pudo completar la petición';
    return ObservableThrowError(errorMsg);
  }

  getSetupByCustomerId(customerId: string) {
    const url = `${environment.onlinePath}customers/${customerId}/check/setup`;
    return this.http.get<CheckSetupRes>(url)
      .pipe(
        map(resp => CheckSetup.createFromResponse(resp)),
        catchError(error => this._handleError(error))
        )
      .toPromise()
  }

  getOauthStatus(customerId: string, otaId: string): Promise<IOauthStatusResult> {
    const url = `${environment.onlinePath}customers/${customerId}/otas/${otaId}/config/oauth/status`;
    return this.http.get<IOauthStatusResult>(url).toPromise();
  }


  getLastOtaRead(customerId, otaId) {
    const url = `${environment.onlinePath}customers/${customerId}/otas/${otaId}/last/read`;
    return this.http.get(url);
  }

  sendConfiguration(customerId, otaId, body) {
    const url = `${environment.onlinePath}customers/${customerId}/otas/${otaId}/oauth`;
    return this.http.post(url, body);
  }

}
