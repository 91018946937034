<mh-semantic-categories-modal #categoryModal></mh-semantic-categories-modal>
<mh-box-element [title]="('online.semantic.categories.title' | translate)">

  <section class="title_filter" [class.show]="showFilter">
    <h1 class="title" (click)="toggleFilters()">
      <mat-icon class="inline">filter_list</mat-icon>
      <span>{{'commons.filters' | translate }}</span>
    </h1>
    <div class="content_filters">
      <div class="row no-gutters">
        <div class="col-lg-3 col-md-4" *ngFor="let active of filters">
          <mat-checkbox (change)="setActives($event)" [(ngModel)]="active.state">
            <div class="name_customer">
              {{'shared.semantic.type_'+commentOrReview()+'.'+active.type | translate}} ({{active.total}})
            </div>
          </mat-checkbox>
        </div>
      </div>
    </div>
  </section>

  <mh-wait-table *ngIf="waiting" [isHeavyQuery]="isHeavyQuery" [index]="messageIndex" [messages]="messagesWaiting" [numberColumns]="5"></mh-wait-table>

  <div class="container_table" [class.waiting]="waiting">
    <mat-table  class="table-general center_info semantic-table" #table [dataSource]="dataSource" matSortActive="negative" matSortDisableClear matSortDirection="desc" matSort>
      <!-- Position Column -->
      <ng-container *ngFor="let key of headerTable" matColumnDef="{{key}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'shared.semantic.type_'+commentOrReview()+'.'+key | translate }}
        </mat-header-cell>


        <mat-cell *matCellDef="let element" [ngClass]="{'fixed-cell' : key==='customer'}">

          <div class="cell_data">
            <div [ngSwitch]="key">
              <ng-template [ngSwitchCase]="'area'">
                <div class="box_cell area">
                  {{element[key].nameArea() | translate }} ({{element['total'].value}})
                </div>
              </ng-template>

              <ng-template [ngSwitchCase]="'total'">
                <div class="box_cell total">
                  d
                  <span class="value">
                    {{ totalRow(element) }}
                  </span>
                </div>
              </ng-template>


              <ng-template ngSwitchDefault>
                <div class="box_cell number" (click)="categoryModal.showModal(element,key,productId,surveyId,hotelDto,customer)">

                  <span class="value">
                    {{ element[key].value }}
                  </span>

                  <span class="percentage" [hidden]="element[key].value==0">
                    ({{ calculatePercent(element,key) | formatPercent }})
                  </span>



                </div>
              </ng-template>

            </div>
          </div>

        </mat-cell>

        <mat-footer-cell *matFooterCellDef>
          <div [ngSwitch]="key">
            <ng-template [ngSwitchCase]="'area'">
              <div class="box_cell area">
                total
              </div>
            </ng-template>

            <ng-template ngSwitchDefault>
              <div class="box_cell total">
                {{getTotalColumn(key)}}
              </div>
            </ng-template>
          </div>
        </mat-footer-cell>




      </ng-container>
      <mat-header-row *matHeaderRowDef="headerTable"></mat-header-row>
      <mat-row *matRowDef="let row; columns: headerTable;"></mat-row>
      <mat-footer-row *matFooterRowDef="headerTable"></mat-footer-row>
    </mat-table>



  </div>


</mh-box-element>