import { CityCountry } from './city-country.model'

export class City {
  id : number;
  name : string;
  country_code : string | any;
  district : string | any;
  population : number | any
  latitude : number | any;
  longitude : number | any;
  time_zone_id : string | any;
  utc_offset : number | any;
  country: CityCountry;


  constructor()
  constructor(
    id?:number,
    name?:string,
    country_code?:string,
    district?:string,
    population?:number,
    latitude?:number,
    longitude?:number,
    time_zone_id?:string,
    utc_offset?:number,
    country?: CityCountry) {

      this.id = id || 0;
      this.name = name || "";
      this.country_code = country_code || null;
      this.district = district || null;
      this.population = population || null;
      this.latitude = latitude || null;
      this.longitude = longitude || null;
      this.time_zone_id = time_zone_id || null;
      this.utc_offset = utc_offset || null;
      this.country = country || new CityCountry();

  }
}
