<section id="trends">
  <widget-trends
    [waiting]="waiting"
    [chain]="chain"
    [trendKeys]="trendKeys"
    [showFilters]="true"
    [trendSelected]="'totalPrice'"
    [segmentedSelected]="segmentedBy"
    [lineChartData]="lineChartData"
    [lineChartLabels]="lineChartLabels"
    [lineChartColors]="lineChartColors"
    (filterTypeChange)="setSelectlValue($event.option,$event.key)"
    (filterCustomerChange)="filterCustomers($event)"
  ></widget-trends>

</section>
