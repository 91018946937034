import { Injectable } from '@angular/core';
import { Item } from './item';
import { HttpClient } from '@angular/common/http';
import { ProductsService } from '../../_services/index'
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Injectable()
export class NavbarService {

  currentSelected:string;
  currentSection:string="";
  currentUrl:string="";
  itemSubject = new Subject<any>();

  productActive:Item;
  itemMenuActive:Item;
  optionParent:Item | any;
  seconBarItems:Item[]=[];

  currentNode;


  constructor(
    private http:HttpClient,
    private productsService:ProductsService) { }


  public getConfig(){
    return this.http.get('package.json').pipe(
      map(response=>response)
    );
  }

  loadNavbar(){
    this.productsService.getNavbarItems();
  }

  getNavbarNodes(){

  }

  refresh(){
    this.itemSubject.next();
  }

  checkOptions(){
    this.optionParent = undefined;
    if(this.itemMenuActive && this.itemMenuActive.hasOptions()){
      this.itemMenuActive = this.itemMenuActive.subOptions[0];
    }
    if(this.itemMenuActive && this.itemMenuActive.type==="option"){
      this.optionParent.currentOption = this.itemMenuActive;
    }

  }
}
