import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Hotel } from 'app/shared/model';
import { AppComponent } from 'app/layouts/app/app-component';
import { UtilService } from 'app/shared/util.service';
import { InterChainPrestayService } from '../inter-chain-prestay.service';
import { Subscription, forkJoin } from 'rxjs';
import { Period } from '../../../shared/model/index';
import { MatTableDataSource } from '@angular/material/table';
import {CustomerService} from '../../../shared/customer.service';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CustomersState } from 'app/states/customers/customers.state';

export interface SummarySale{
  customer_id: number;
  total_price: Period;
  quantity: Period;
}
export interface SummaryCheckin{
  customer_id: number;
  checkins: Period;
}

export interface SummaryEmailRate{
  customer_id: number;
  email_rate: Period;
  total_guests: Period;
  with_email: Period;
  valid_emails: Period;
}

export interface SummaryService{
  customer_id: number;
  sent_emails: Period;
  unique_orders: Period;
  order_rate: Period;
}

@Component({
  selector: 'mh-chain-prestay-summary',
  templateUrl: './prestay-summary.component.html',
  styleUrls: ['./prestay-summary.component.scss'],
  providers: [InterChainPrestayService, CustomerService]
})
export class PrestaySummaryComponent extends AppComponent implements OnInit {

  @Select(CustomersState.relativeCurrentChain) customersReady$: Observable<any[]>;

  @Input()
  chain : Hotel[] = [];

  @Input()
  currentDates = null;

  actives:any[] = [];
  summarySales:     SummarySale[] = [];
  summaryCheckin:   SummaryCheckin[] = [];
  summaryEmailRate: SummaryEmailRate[] = [];
  summaryService:   SummaryService[] = [];
  hasActiveServices = false;
  hasActiveWebcheckin = false;

  @Output()
  isReady: EventEmitter<boolean> = new EventEmitter();

  customerSubscribe: Subscription;
  customersStateSubscribe: Subscription;

  dataSource: any = new MatTableDataSource([]);
  dataTable: any[]  = [];

  firstCall = true;


  constructor(
    private utilService: UtilService,
    private interChainPrestayService:InterChainPrestayService,
    private customerService: CustomerService,
    private store: Store
  ) {
    super();
    this.customerSubscribe = this.utilService.isCustomerChanged().subscribe(response=>{
      if(!this.firstCall) this.init();
    })

    this.customersStateSubscribe = this.customersReady$.subscribe(customers => this.init());
  }

  ngOnInit() {
  }

  ngOnChanges({currentDates}): void {
    if ( currentDates ) {
      this.init();
    }
  }

  ngOnDestroy(){
    this.customerSubscribe.unsubscribe();
    this.customersStateSubscribe.unsubscribe();
  }


  init(){
    this.setWaiting(true);
    this.isReady.emit(false);
    this.resetInterchain();
    this.actives = this.chain.map(x=>x.id)
    this.startChunkInterchain(this.actives)
    if(this.canLoadWidget()){
      if(this.actives.length>0) this.loadData();
    }
  }

  loadData(){
    const date      = {"startDate": this.currentDates.startDate,"endDate": this.currentDates.endDate};
    this.dataTable = [];
    this.summarySales = [];
    this.summaryCheckin = [];
    this.summaryEmailRate = [];
    this.summaryService = [];
    const customers = this.store.selectSnapshot(state => state.customers.relativeCurrentChain).map(c => c.id).join(',');

    if (customers.length) {
      forkJoin([
        this.interChainPrestayService.getSummarySales(customers,date),
        this.interChainPrestayService.getSummaryCheckin(customers,date),
        this.interChainPrestayService.getSummaryEmailsRate(customers,date),
        this.interChainPrestayService.getSummaryServices(customers,date),
        this.interChainPrestayService.getActiveModules(customers)
      ]).subscribe((response:any)=>{
        this.summarySales     = this.summarySales.concat(response[0]);
        this.summaryCheckin   = this.summaryCheckin.concat(response[1]);
        this.summaryEmailRate = this.summaryEmailRate.concat(response[2]);
        this.summaryService   = this.summaryService.concat(response[3]);
        this.setServicesWebCheckin(response[4]);

        this.nextChain();
        if(this.callingInterchain === this.chunkInterchain.length){
          this.customerService.findByIds(this.chain.map(x=>x.id).join(',')).subscribe(response=>{
            this.setCustomerDetail(response);
            this.createTable();
            this.setWaiting(false);
            this.isReady.emit(true);
            this.firstCall = false;
            setTimeout(_ => {
              this.utilService.forceResize();
            },3000);
          })

        }else{
          setTimeout(_ => {
            this.loadData();
          },100);
        }
      })
    }

  }

  setServicesWebCheckin(customers) {
    this.hasActiveServices = customers.some(customer => customer.services);
    this.hasActiveWebcheckin = customers.some(customer => customer.checkin);
  }

  canLoadWidget(){
    if(!this.utilService.currentUser.isSuperAdmin()) return true;
    if(this.utilService.currentUser.fakeUser) return true;
    return false;
  }

  filterCustomers($event){
    this.actives = $event;
    this.createTable()
  }

  createTable(){

    this.dataTable = [];
    this.dataSource = new MatTableDataSource([]);

    this.chain.forEach(customer=>{
      if(this.actives.includes(customer.id)){
        const summarySales: any      = this.summarySales.find(x=>x.customer_id===parseInt(customer.id));
        const summaryCheckin: any    = this.summaryCheckin.find(x=>x.customer_id===parseInt(customer.id));
        const summaryEmailRate: any  = this.summaryEmailRate.find(x=>x.customer_id===parseInt(customer.id));
        const summaryService: any    = this.summaryService.find(x=>x.customer_id===parseInt(customer.id));

        this.dataTable.push({
          'customer' :      customer,
          'total_price' :   summarySales.total_price,
          'quantity' :      summarySales.quantity,
          'checkins' :      summaryCheckin.checkins,
          'email_rate' :    summaryEmailRate.email_rate,
          'total_guests' :   summaryEmailRate.total_guests,
          'with_email' :    summaryEmailRate.with_email,
          'valid_emails' :  summaryEmailRate.valid_emails,
          'sent_emails' :   summaryService.sent_emails,
          'unique_orders' : summaryService.unique_orders,
          'order_rate':     summaryService.order_rate
        })
      }
    })

    this.dataSource = new MatTableDataSource(this.dataTable);
    this.dataSource.sortingDataAccessor = function (data, sortHeaderId) {
      let value = data[sortHeaderId];
      if(sortHeaderId==='customer'){
        value = value.name;
      }else{
        value = value.actual
      }

      if (typeof value === 'string' && !value.trim()) {
        return value;
      }
      return isNaN(+value) ? value : +value;
    };

  }





}
