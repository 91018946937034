<div class="row">
  <div class="col-md-6">
    <mh-box-element [title]="'Alertas Followup'">

      <div class="row row_alert list_users" *ngFor="let user of followupUsers">

        <div class="col-md-8 block">
          <div class="box_user">
            <div class="name ellipsis">{{user.name}} <span>{{user.last_name}}</span></div>
            <div class="username"></div>
            <div class="email ellipsis">{{user.email}}</div>
          </div>
        </div>

        <div class="col-md-4 block">
          <div class="box_survey">
            <ul class="list_suvey_alerts">
              <li class="survey_alert" *ngFor="let alert of followupAlertsType">
                <div class="alert_added type_{{alert.id}}">
                  <mat-slide-toggle
                    [color]="color"
                    [checked]="hasFollowupAlert(user, alert.id)"
                    [disabled]="disabled">
                    {{alert.description}}
                </mat-slide-toggle>
                </div>
              </li>
            </ul>
          </div>

        </div>

        <div class="delete_user before-inline">
          <i class="fa fa-trash"></i>
        </div>
      </div>

    </mh-box-element>


  </div>


  <div class="col-md-6">
    <mh-box-element [title]="'Alertas On Line'">

      <div class="row row_alert list_users" *ngFor="let user of onlineUsers">

        <div class="col-md-6 block">
          <div class="box_user">
            <div class="name ellipsis">{{user.name}} <span>{{user.last_name}}</span></div>
            <div class="username"></div>
            <div class="email ellipsis">{{user.email}}</div>
          </div>
        </div>

        <div class="col-md-6 block">
          <div class="box_ota">
            <ul class="list_alerts_otas">
              <li class="alert_otas" *ngFor="let ota of onlineOtas">

                <div class="ota type_{{ota.id}}" >
                  <img (click)="showModal(user, ota)" class="logo" src="http://statics.myhotel.io/logos/otas/square/{{ota.id}}.png" alt="ota.name">
                  <mat-slide-toggle
                    [color]="color"
                    [checked]="hasOnlineOtaAlert(user, ota.id)"
                    [disabled]="disabled">
                  </mat-slide-toggle>

                </div>

                <div class="alert_added type_{{ota.id}}">
                  <div class="ota_name">{{ota.name}}</div>
                </div>

              </li>

            </ul>
          </div>
        </div>
        <div class="delete_user before-inline">
          <i class="fa fa-trash"></i>
        </div>


      </div>

    </mh-box-element>
  </div>
</div>


