import { Component } from '@angular/core';


@Component({
  selector: 'mh-interchain-sections',
  templateUrl: './interchain-sections.component.html',
})
export class InterchainSectionsComponent{
  constructor() { }
}

