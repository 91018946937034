<div class="form-group" [formGroup]="form">
  <mat-form-field class="full_width">
    <mat-select [placeholder]="('support.list_items' | translate )" formControlName="items">
      <mat-option>{{'support.list_items' | translate}}</mat-option>
      <ng-container *ngFor="let tag of tagsName">
        <mat-option [value]="tag">
          <span>{{'navbar.product.'+clientType+'.'+tag | translate}}</span>
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
</div>