import { UtilService } from '../../../../shared/util.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WidgetCardRate } from '../../interfaces/widget-card-rate.interface';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'widget-card-rate',
  templateUrl: './card-rate.component.html',
  styleUrls: ['./card-rate.component.scss']
})
export class CardRateComponent implements OnInit {

  modal:NgbModalRef;

  @Input()
  rates: WidgetCardRate[] = [];  //ej ['rates','orders','sents'];

  @Input()
  totalSents    = 1;

  @Input()
  totalAnswered = 1;

  @Input()
  tooltipRate;

  @Input()
  waiting = true;

  @Input()
  labelRate = 'followup.summary.summary-survey.title_box.rate';

  modalTitle:string;
  ratePercent;

  @Output()
  clickRate: EventEmitter<WidgetCardRate> = new EventEmitter();

  constructor(private modalService: NgbModal, private utilService: UtilService) { }


  ngOnInit(){

  }
  ngOnChanges() {
    this.ratePercent = this.utilService.formatPercent(this.totalAnswered/this.totalSents*100);
  }


  openDialog(content,rate:WidgetCardRate){
    this.modalTitle = rate.translatePrefix+'.modal_'+rate.label;
    this.clickRate.emit(rate);
    this.modal = this.modalService.open(content, {
      size: 'lg',
      windowClass: ''
    })
  }

  closeDialog(){
    if(this.modal) this.modal.close();
  }

}
