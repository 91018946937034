<ng-container *ngIf="isOtaObject; else otaNoLink">
  <ng-container *ngIf="!ota.oauth else otaButtonOAuth">
    <div class="d-flex">
      <a class="ota-link link" [href]="ota.param" target="_blank">
        <ng-container *ngTemplateOutlet="otaBrand"></ng-container>
      </a>
      <div class="align-self-center" *ngIf="isDespegar">
        <mat-icon [matTooltip]="'online.otas.ranking_table.tooltips.Despegar.com' | translate">
          info
        </mat-icon>
      </div>
      <div class="align-self-center" *ngIf="isExpedia">
        <mat-icon [matTooltip]="'online.otas.ranking_table.tooltips.Expedia' | translate">
          info
        </mat-icon>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #otaNoLink>
  <ng-container *ngTemplateOutlet="otaBrand"></ng-container>
</ng-template>

<ng-template #otaButtonOAuth>
      <ng-template *ngTemplateOutlet="linkRef"></ng-template>
</ng-template>

<ng-template #otaBrand>
  <div class="card-ota {{size}} {{viewStyle}} align-{{align}}">
    <img class="ota-logo" [src]="logo" alt="Ota Logo">
    <div class="ota-name">
      {{'shared.filters.names.otas.'+id | translate }}
    </div>
  </div>
</ng-template>

<ng-template #linkRef>
  <ng-container *ngIf="otaId !== 30">
    <a class="ota-link link" [href]="ota.param" target="_blank">
      <ng-container *ngTemplateOutlet="otaBrand"></ng-container>
    </a>
  </ng-container>

  <ng-container *ngIf="otaId === 30">
    <a class="ota-link link" [routerLink]="ota.param">
      <ng-container *ngTemplateOutlet="otaBrand"></ng-container>
    </a>
  </ng-container>
</ng-template>
