<div class="row no-gutters">
  <div class="col-md-12">
    <ng-content select="mh-filter-date"></ng-content>
  </div>
</div>
<mh-spinner-classic [hidden]="!waiting"></mh-spinner-classic>

<div class="row align-items-center" *ngIf="showFilter && showSurveySelector">


  <div class="content_options col-{{isChain ? 6 : 12}} offset-{{isChain ? 6 : 0}}" [class.chain]="isChain">
    <mat-form-field class="line-off">
      <mat-label>{{'followup.survey.types.name' | translate }}</mat-label>
      <mat-select class="mat-select button" [(value)]="selectedSurvey" (valueChange)="setCurrentSurvey($event)">
        <mat-option [value]="0" *ngIf="showOptionAll">{{'commons.alls' | translate }}</mat-option>

        <ng-container *ngIf="!isChain">
          <mat-option *ngFor="let survey of listSurveys" [value]="survey.id">

            <ng-container [ngSwitch]="survey.version">
              <ng-container *ngSwitchCase="1">
                {{'survey.types.'+survey.type.id | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="2">
                <span mhFindTranslate [language]="language" [translates]="survey.translates" [keyTranlsate]="'title'"></span>
              </ng-container>
            </ng-container>

          </mat-option>
        </ng-container>

        <ng-container *ngIf="isChain">
          <mat-option *ngFor="let type of listTypes" [value]="type.type">
            <ng-container [ngSwitch]="type.version">
              <ng-container *ngSwitchCase="1">
                {{'survey.types.'+type.type | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="2">
                <span mhFindTranslate [language]="language" [translates]="type.translates" [keyTranlsate]="'title'"></span>
              </ng-container>
            </ng-container>

          </mat-option>
        </ng-container>

      </mat-select>
    </mat-form-field>
  </div>



</div>
<ng-content></ng-content>
