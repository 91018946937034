<div class="row no-gutters list_products">
  <div *ngFor="let product of activesProducts" class="col-4 item_product {{product}}">


    <div class="icon_product {{product}}">
      <div class="content_icon">
        <i class="{{getConfigProduct(product).icon}}"></i>
      </div>
    </div>


    <div class="info_product">
      <div class="product_name">
        <div class="action_label">{{'configuration.users.configuration' | translate }}</div>
        <span>{{product}}</span>
      </div>
      <ul class="actions">
        <li *ngFor="let action of actions" class="ellipsis {{action.label}}">
          <mh-user-products-edit [action]="action" [customer]="customer" [user]="user" [product]="product"></mh-user-products-edit>
        </li>
      </ul>
    </div>


  </div>
</div>