export class FidelityProperty{
  products  = {};
}
export class Menus{
  menu = {};
}

export class Widgets{
  widgets: any[] = [];
 }
