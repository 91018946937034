import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core'
import { MatMenuTrigger } from '@angular/material/menu';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {Subscription} from 'rxjs';
import {UtilService} from 'app/shared/util.service';
import { FilterDateService } from 'app/shared/filters/date/filter-date-service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {map, startWith} from 'rxjs/operators';


const INIT = moment().startOf('year').format('MM/DD/YYYY');
const todayMoment = ():moment.Moment => {
  const date = moment((new Date()).toISOString().split('T')[0])
  return date
}

const RANGES = {
  today: [todayMoment(), todayMoment()],
  yesterday: [todayMoment().subtract(1, 'days'), todayMoment().subtract(1, 'days')],
  lastSevenDays: [todayMoment().subtract(7, 'days'), todayMoment()],
  lastThirtyDays: [todayMoment().subtract(30, 'days'), todayMoment()],
  thisMonth: [todayMoment().startOf('month'), todayMoment()],
  lastMonth: [
    todayMoment().subtract(1, 'month').startOf('month'),
    todayMoment().subtract(1, 'month').endOf('month').startOf('day'),
  ],
  lastThreeMonths: [
    todayMoment().subtract(3, 'month').startOf('month'),
    todayMoment().subtract(1, 'month').endOf('month').startOf('day'),
  ],
  yearToDate: [moment(INIT), todayMoment()]
};

@Component({
  selector: 'mh-filters',
  templateUrl: './generic-filter.component.html',
  styleUrls: ['./generic-filter.component.scss'],
})
export class GenericFilterComponent {
  DEFAULT_RANGE:moment.Moment[] = RANGES.yearToDate;

  @Input() values: ({[param: string]: string[]}) = {};
  @Input() filters: Filter[]
  @Input() optionsList = [];
  @Input() dates: {startDate?: Date, endDate?: Date}
  @Input() includeDates = true;

  @Output() menuOpen = new EventEmitter<string>()
  @Output() onUpdate = new EventEmitter<{[param:string]: string[] | number[] | Date}>()
  @Output() onDateChange = new EventEmitter<{startDate: Date, endDate: Date}>()

  ranges = {};

  selectedDates: any;
  selectedDatesTranslatePath: string;

  translateSubcription: Subscription;

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  optionControl = new FormControl();
  filteredOptions: any;
  options: any[] = [];
  //allOptions = []

  @ViewChild('optionInput') optionInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  @ViewChild('trigger', {read: MatMenuTrigger, static: false}) protected trigger : MatMenuTrigger;

  constructor(
    private translateService: TranslateService,
    private utilService: UtilService,
    private filterDateService: FilterDateService
  ) {
  }

  ngOnInit() {
    const datesMoments = this.filterDateService.getRangeMoment();
    const [startDate, endDate] = this.filterDateService.getRangeMoment();
    this.setDates({startDate, endDate}, this.getTranslateKey(datesMoments));

    this.translateRanges();

    this.translateSubcription = this.translateService.onDefaultLangChange
      .subscribe(this.translateRanges.bind(this))
    this.initFilter();
  }

  ngOnChanges() {
    this.initFilter();
  }

  ngOnDestroy() {
    this.translateSubcription.unsubscribe();
  }

  onMenuOpen(type:string) {
    this.menuOpen.emit(type)
  }

  translateRanges() {
    const translatePaths = Object.keys(RANGES).map(key => `filters.dates.${key}`)

    this.translateService.get(translatePaths)
      .subscribe(translates => {
        this.ranges = Object.keys(translates)
          .reduce((acum, key) => {
            const originalKey = key.split('.')[2]

            return {
              ...acum,
              [translates[key]]: RANGES[originalKey]
            }
          }, {});
      })
  }

  handleUpdate(name:string, event: any): void {
    let { _value: value } = event?.value || event?.id || event.source;
    if (name === 'areas') {
      value = this.options
    }

    this.onUpdate.emit({
      ...this.values,
      [name]: value,
    })
  }

  cleanFilters() {
    this.options = [];
    this.initFilter();
    this.onUpdate.emit({})
  }

  handleRangeClick(event: {dates: moment.Moment[], label: string}) {
    const [startDate, endDate] = event.dates;
    const dates = this.transformRange(event.dates);
    const selectedKey = this.getTranslateKey(event.dates);

    this.filterDateService.setDateRange(event)
    this.setDates({startDate, endDate}, selectedKey);
    this.trigger.closeMenu();
    this.onDateChange.emit(dates)
  }

  setDates(dates, key) {
    this.selectedDatesTranslatePath = `filters.dates.${key}`;
    this.selectedDates = dates;
  }

  getTranslateKey(dates: moment.Moment[]) {
    const areDatesEqual = (d1: any, d2: any): boolean =>
      d1.toISOString() === d2.toISOString();
    const areRangesEqual = (r1: any[], r2: any[]) =>
      r1.reduce((acum, date, idx) => acum && areDatesEqual(date, r2[idx]), true);

    return Object.keys(RANGES)
      .find((key: string) => areRangesEqual(RANGES[key], dates)) ||
      'custom';
  }

  handleCustomDates(dates: any): void {
    const fromDate = moment(dates.startDate).format('YYYY-MM-DD');
    const toDate = moment(dates.endDate).format('YYYY-MM-DD');

    this.filterDateService.setCustomDate({fromDate, toDate});
    this.selectedDates = dates;
    this.selectedDatesTranslatePath = 'filters.dates.custom';
    this.trigger.closeMenu();
    this.onDateChange.emit(dates)
  }

  transformRange(event: any) {
    const [startDate, endDate] = event.map(d => d.toDate());
    return {startDate, endDate};
  }

  toggleElement(array: string[], element: string): string[] {
    return array.includes(element) ?
      array.filter(el => el !== element) :
      [...array, element]
  }

  isSelected(filter: Filter, option: string | Filterable): boolean {
    if(Object.keys(this.values).length === 0) {
      console.warn('here')
      return false;
    }
    if (typeof option === 'string') return (<any[]>(this.values[filter.name] || [])).includes(this.optionValue(option));

    return (<any[]>(this.values[filter.name] || [])).includes(option.value);
  }

  optionText(option: string | Filterable) {
    if(!option) return ""
    if (typeof option === 'string') return option
    return (<Filterable>option).text
  }

  optionValue(option: string | Filterable) {
    if(!option) return ""
    if (typeof option === 'string') return option
    return (<Filterable>option).value
  }

  getDatesFilterTitle() {
    const { startDate, endDate } = this.selectedDates
    return `${this.getDateString(startDate)} - ${this.getDateString(endDate)}`;
  }

  getDateString = (date: moment.Moment) => date.format('DD/MM/YY')

  getMomentObject(date: Date) {
    return moment(date);
  }

  initFilter() {
    this.filteredOptions = this.optionControl.valueChanges.pipe(
        startWith(''),
        map((area: any) => this._filter(area)));
  }

  add(event: MatChipInputEvent): void {
    const inputValue = (event.value || '').trim();
    const value = this.options.find(area => {
      return (area.clasification_text === inputValue)
        ? true
        : area.clasification_text_en === inputValue
    });

    if (value) {
      this.options.push(value);
    }
    this.optionInput.nativeElement.value = '';
    this.optionControl.setValue(null);
    this.handleUpdate('areas', event)
  }

  remove(option: string): void {
    const index = this.options.indexOf(option);

    if (index >= 0) {
      this.options.splice(index, 1);
    }
    this.handleUpdate('areas', option)
  }

  selected(event): void {
    const inputValue = event.option.value;
    const value = this.optionsList.find(area => area.clasification_text === inputValue.clasification_text);
    if (value) {
      this.options.push(value);
    }
    this.optionInput.nativeElement.value = '';
    this.optionControl.setValue(null);
    this.handleUpdate('areas', event)
  }

  private _filter(value: any): string[] {
    const availableAreas = this.optionsList.map(area => {
      const chip = this.options.find(chip => chip.id === area.id);
      if ( !!!chip ) return area
    }).filter(area => area !== undefined)
    if (value) {
      const filterValue = (typeof(value) === 'string') ?
        value.toLowerCase() :
        (this.language === 'es') ? value.clasification_text : value.clasification_text_en;
      return availableAreas.filter(option => {
        if (this.language === 'es') {
          const { clasification_text } = option;
          return clasification_text.toLowerCase().includes(filterValue)
        }
        const { clasification_text_en } = option;
        return clasification_text_en.toLowerCase().includes(filterValue)
      });
    } else {
      return availableAreas.slice();
    }

  }

  get language() { return this.utilService.getCurrentLanguage();}
  get hasFilterActive() {
    return Object.values(this.values)
      .reduce((acum, arr: any[]) => acum || (arr && !!arr.length), false)
  }
}

export interface Filter {
  name: string;
  options: any;
  titleTranslate: string;
  optionTranslatePrefix: string;
  optionTranslateSuffix: string;
}

export interface Filterable {
  value: string | number;
  text: string;
}
