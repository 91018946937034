import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class RankingAreasService {

  constructor(private http:HttpClient) { }

  public getRankingByArea(dates:any,customerId:string,productId,languageId, surveysIds, version){
    if (version === 2)
      return this.getRankingByAreaV2(dates, customerId, productId, languageId, surveysIds);

    const url=`${environment.followupPath}summary/surveys/ranking/by_areas`;
    const params:HttpParams= new HttpParams()
      .set("start_date",dates.initDate)
      .set("end_date",dates.endDate)
      .set("customer_id",customerId)
      .set("product_id",productId)
      .set("language_id",languageId)
      .set("surveys_ids", surveysIds);

    return this.http.get(url,{params: params})
    .pipe(
      map(response=>response)
    )
  }

  public getRankingByAreaV2(dates, customerId, productId, languageId, surveysIds) {
    const url=`${environment.followupPath}customers/${customerId}/surveys/areas`;
    const params:HttpParams = new HttpParams()
      .set("start_date",dates.initDate)
      .set("end_date",dates.endDate)
      .set("product_id",productId)
      .set("surveys_ids", surveysIds);

    return this.http.get(url,{params: params})
      .pipe(map((response:any) => [{
          ...response,
          areas: response.areas.map((area:any) => ({
            ...area,
            id_area: area.area_id,
            note: area.score,
          }))
        }]))
  }

  public getRankingByAreaAverages(dates:any,customerId:string,productId,languageId, surveysIds, version){
    if (version === 1) {
      return this.getLegacyRankingByAreaAverages(dates,customerId,productId,languageId, surveysIds);
    }

    const url = `${environment.followupPath}customers/${customerId}/surveys/areas/averages`
    const params:HttpParams = new HttpParams()
      .set("start_date",dates.initDate)
      .set("end_date",dates.endDate)
      .set("surveys_ids",surveysIds);

    return this.http.get(url, {params}).pipe(
      map(response => this.formatAreaAverages(response))
    )
  }

  private formatAreaAverages(areasResponse) {
    return {
        actual: (areasResponse || []).map(area => ({
        ...area,
        top_ten_average_note: area.top_ten_score,
        general_average_note: area.average_score,
        id_area: area.area_id,
      }))
    }
  }

  public getLegacyRankingByAreaAverages(dates:any,customerId:string,productId,languageId, surveysIds) {
    const url=`${environment.followupPath}summary/surveys/ranking/areas/averages`;
    const params:HttpParams= new HttpParams()
      .set("start_date",dates.initDate)
      .set("end_date",dates.endDate)
      .set("customer_id",customerId)
      .set("product_id",productId)
      .set("language_id",languageId)
      .set("surveys_ids",surveysIds);

    return this.http.get(url,{params})
  }

  getRankingAreaDetailV2(dates:any,customerId:string,productId,surveysIds, areaId) {
    const url=`${environment.followupPath}customers/${customerId}/surveys/areas/${areaId}`;
    const params:HttpParams= new HttpParams()
      .set("start_date",dates.initDate)
      .set("end_date",dates.endDate)
      .set("customer_id",customerId)
      .set("product_id",productId)
      .set("surveys_ids",surveysIds);

    return this.http.get(url,{params: params})
  }
}
