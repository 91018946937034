import { Component } from '@angular/core';

@Component({
  templateUrl: './trends-onsite-section.component.html',
})
export class TrendsOnsiteSectionComponent {

  showFilters = true;

  constructor() {}

}
