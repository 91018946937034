import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EditProductService } from "../../edit-product.service";
import { UtilService } from "../../../../shared/util.service";
import { UserProfile,CustomerProfile, Access } from "../../../../shared/model/index";


@Component({
  selector: 'mh-edit-product-access',
  templateUrl: './edit-product-access.component.html',
  styleUrls: ['../../../../../assets/scss/configuration/product-edit.scss'],
  providers:[EditProductService]
})
export class EditProductAccess{

  @Input()
  customer:CustomerProfile;

  @Input()
  user:UserProfile;

  @Input()
  product:string;

  @Output() waitingState = new EventEmitter<boolean>();

  waiting:boolean=false;

  constructor(private utilService:UtilService, private editProductService:EditProductService){

  }

  ngOnInit(){

  }
  ngOnChanges() {
    this.waitingState.emit(false);
  }

  setAccessSate($slide){
    let index = this.customer.access.findIndex(x=>x.description === $slide.source.id);
    this.customer.access[index].value = $slide.checked;
  }

  showSlide(access:Access){
    let prefix = this.editProductService.getProductPrefix(this.product);
    return access.description.includes(prefix);
  }

  saveChanges(){
    this.waiting = true;
    this.waitingState.emit(this.waiting);
    this.editProductService.saveAccess(this.user.id,this.formatDataAccessToUpdate()).subscribe((response:any)=>{
      this.waiting = false;
      if(response.description && response.description === 'success'){
        let currentUser = this.utilService.getCurrentUser();
        if(this.user.id === currentUser.id){
          this.utilService.currentUser.access     = this.customer.access
          this.utilService.currentHotel.accessIds = this.customer.access.filter(x=>x.value).map(x=>x.id);
          this.utilService.refreshCurrentUser();
          this.utilService.refreshCurrentHotel();
        }
      }
      this.waitingState.emit(this.waiting);
    })
  }


  formatDataAccessToUpdate(){
    return {
      "access": this.customer.access,
      "customer": {
        "id": this.customer.id
      },
      "id": this.user.id,
      "profile" : this.user.profile
    }
  }

}


