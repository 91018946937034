import { Component, Input } from '@angular/core';

@Component({
  selector: 'mh-core-lib-loader-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class CoreLibProgressBarComponent {
  @Input() isInfinite = true;
  @Input() isReversed = false;
  @Input() progress: number;
  @Input() classColor = 'tw-bg-sky-500';
}
