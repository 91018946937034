import { Component, Inject, OnInit } from '@angular/core';
import { DialogRef } from '../modal/dialog-ref';
import { DIALOG_DATA } from '../modal/dialog-tokens';
import { CoreLibModalComponent } from '../modal/modal.component';
@Component({
  selector: 'mh-core-lib-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class CorecLibSuccessModalComponent extends CoreLibModalComponent implements OnInit {

  title;
  body;

  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
  ) {
    super(dialogRef, data);
  }

  ngOnInit(): void {
    this.title = this.data?.title;
    this.body = this.data?.body;
  }

}
