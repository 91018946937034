import { Component, Input, ViewChild } from '@angular/core';
import {  MatSort } from '@angular/material/sort';
import { TranslateObjectPipe } from 'app/shared/pipe';
import {DateService} from 'app/shared/services/date.service';
import { UtilService } from 'app/shared/util.service';
import { HtmlHelper } from 'app/_helpers/html-helper.service';

@Component({
  selector: 'widgets-table-periods',
  templateUrl: './table-periods.component.html',
  styleUrls: ['./table-periods.component.scss']
})
export class TablePeriodsComponent {

  @Input()
  headerTable = [];

  @Input()
  chain = [];

  @Input()
  totals = {}

  @Input()
  matSortActive = 'customer';

  @Input()
  prefixTranslate = 'inter-chain.summary.table';

  @Input()
  tooltipHeaders = [];

  @Input()
  formatByLabels = false;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  percentKeys = [
    'iro',
    'coverage',
    'mh_rate_response',
    'email_surveys',
    'open_surveys',
    'negative_percentage',
    'positive_percentage',
    'promoter_percentage',
    'detractor_percentage',
    'total_coverage',
    'detractors_coverage',
    'passives_coverage',
    'promoters_coverage',
    'semantic_index'
  ];

  averageKeys = [
    'qualification',
    'cleaning',
    'service',
    'location',
    'value',
    'rooms'
  ];

  inverseKeys = [
    'negative_comments',
    'negative_percentage'
  ]

  rankingKeys = [
    'tripAdvisorRanking',
    'ranking'
  ];

  #_dataSource;
  @Input() set dataSource(ds) {
    this.#_dataSource = ds;
    if (ds) {
      this.#_dataSource.sortingDataAccessor = function (data, sortHeaderId) {
        let value = data[sortHeaderId];
        if(['customer'].includes(sortHeaderId)){
          if(value)
            value = value.name
        }else if(['area'].includes(sortHeaderId)){
          value = value;
        }else if(sortHeaderId === 'tripAdvisorRanking'){
          value = value?.actualPeriod?.ranking || value.actual;
        }else{
          if(value)
            value = value.actual || value.rating?.actual || null;
        }
        if (typeof value === 'string' && !value.trim()) {
          return value;
        }
        return isNaN(+value) ? value : +value;
      };
      this.#_dataSource.sort = this.sort;
    }
  }

  get dataSource() {
    return this.#_dataSource;
  }

  constructor(
    private htmlHelper: HtmlHelper,
    private utilService: UtilService,
    private translateObject: TranslateObjectPipe,
    private dateService: DateService
    ) { }

  processTranslates(translates: any[]) {
    if (!translates) return '-';
    return this.translateObject.transform(translates,  'name')
  }

  get isRetail(){ return this.utilService.customerIsRetail; }
  get isClinic(){ return this.utilService.customerIsClinic; }
  get isRestaurant(){ return this.utilService.customerIsRestaurant; }
  get typeCustomer() {
    return (this.isRetail && 'commons.store') ||
      (this.isClinic && 'commons.clinic') ||
      'commons.hotel';
  }
  get tableAreaStyle(){ return this.htmlHelper.getTableAreaStyle(this.utilService.screenHeight);}
  get tableContentStyle(){return this.htmlHelper.getTableContentStyle(this.headerTable);}

  get activeSort() {
    switch(this.utilService.getProductNameFromUrl()) {
      case 'Corp-Online-Propiedades':
        return 'qualification'
      case 'Corp-Online-TripAdvisor':
        return 'total_period'
      case 'Corp-Online-Cobertura-Hotel':
        return 'total_coverage'
      case 'Corp-Online-Cobertura-Calificaciones':
        return 'coverage'
      case 'Corp-OnSite':
        return 'nps'
      case 'Online-Otas':
        return 'rating'
    }
  }

  getPrefixTranslate(key, type = 'header') {
    if (type === 'tooltip')
      return `${this.prefixTranslate}.tooltips.${key}${
        this.isRestaurant && key.includes('guests_quantity') ? '_restaurant' : ''
      }`;

    if (type === 'header') {
      if (key.includes("area_")) {
        const area = key.split("_")
        return `areas.${area[1]}`
      }
      if (key.includes("ota_")) {
        const ota = key.split("_");
        return `shared.filters.names.otas.${ota[1]}`;
      }
      if (key === 'customer') {
        return `${this.prefixTranslate}.customer`
      }

      if (this.isRestaurant && key.includes('guests_quantity')) {
        return `inter-chain.summary.table.${key}_restaurant`;
      }

      const transKey = key + (key === 'ota' && this.utilService.customerIsClinic ? '_clinic' : '');
      return `${this.prefixTranslate}.${transKey}`;
    }
  }

  get translateParams(){
    return {
      currentYear: this.dateService.currentDates.endDate.split('-')[0]
    }
  }

  get showTotals(){
    return Object.keys(this.totals).length>0;
  }

  getFormatKey(element,key){
    return element && this.formatByLabels && element['label'] && key!=='label' ? element['label'] : key;
  }

  getFormatPeriod(element,key){
    const formatKey = this.getFormatKey(element,key);
    if(this.percentKeys.includes(formatKey)) return 'percent';
    if(this.averageKeys.includes(formatKey)) return 'average';
    return formatKey;
  }

  showRanking(element,key){
    const formatKey = this.getFormatKey(element,key);
    return this.rankingKeys.includes(formatKey);
  }
}
