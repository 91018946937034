import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Hotel,User} from '../shared/model/index';
import { environment } from "../../environments/environment";
import { SessionService } from './session.service';
declare var gtag: Function;


@Injectable()
export class GoogleAnalyticsService{

  currentIdTrack : string;
  currentIdProperty : string;

  constructor(public router: Router, private sessionService: SessionService) {
    this.router.events.subscribe(event => {
      try {
        if (typeof gtag === 'function') {
          if (event instanceof NavigationEnd) {
            const url = event.urlAfterRedirects;
            this.basicInfo(url);
          }
        }
      } catch (e) {
        console.log(e);
      }
    });

  }
   /**
   * Emit google analytics event
   * Fire event example:
   * this.emitEvent("testCategory", "testAction", "testLabel", 10);
   * @param {string} eventCategory
   * @param {string} eventAction
   * @param {string} eventLabel
   * @param {number} eventValue
   */
  public emitEvent(
    eventCategory: string,
    eventAction: string,
    eventLabel: string | null = '',
    eventValue: number | string | null = 0,
  ) {
    console.log("GA: "+eventCategory+" - "+eventAction + " - "+eventLabel)
    if (typeof gtag === 'function') {
      gtag('event', eventAction, {
        'event_category': eventCategory,
        'event_label': eventLabel,
        'value': eventValue
      });
    }
  }

  basicInfo(url){
    const user     = this.getCurrentUser();
    const customer = this.getCurrentCustomer();
    const isPublic = environment.googleAnalyticsGuest.includes(this.currentIdProperty);

    if(!this.isProduction()) {
      isPublic ? this.sendPublicInfo(url) : this.sendInfo(customer,user,url)
    }else{
      if(user===undefined || user===null){
        isPublic ? this.sendPublicInfo(url) : this.sendInfo(customer,user,url)
      }else{
        if(!user.isSuperAdmin()){
          isPublic ? this.sendPublicInfo(url) : this.sendInfo(customer,user,url)
        }
      }
    }


  }

  sendInfo(customer,user,url){
    if(customer) gtag('set', {'dimension1': customer.name});
    if(user)     gtag('set', {'dimension2': user.email});

    gtag('event', 'page_view', {
      'page_title': url
    });
    console.log('%%% Google Analytics page view event %%%');
  }

  sendPublicInfo(url){
    const customer  = this.sessionService.getCurrentHotelPS();
    const guest     = this.sessionService.getCurrentGuest();
    if(customer && guest && guest.id!==0){
      gtag('set', {'dimension2': customer.name});
      gtag('set', {'dimension5': guest.id});
      gtag('event', 'page_view', {
        'page_title': url
      });
    }

    console.log('%%% Google Analytics PUBLIC page view event %%%');
  }

  sendInfoSurvey(guestId, hotelName) {
    if (!this.isProduction() || !hotelName || !guestId || guestId === 1) return;

    const publicSurveyCode = 'G-E309GY39LS';

    this.appendGaTrackingCode(publicSurveyCode, guestId)
    gtag('set', {'dimension2': hotelName});
    gtag('set', {'dimension5': guestId});
    gtag('event', 'page_view');
  }

  getCurrentUser():User{
    return this.sessionService.getCurrentUser();
  }

  getCurrentCustomer():Hotel{
    return this.sessionService.getCurrentCustomer();
  }

  isProduction(){
    return `${environment.production}` === 'true';
  }

  appendGaTrackingCode(idGA, user_id?: any){
    const options = { send_page_view: false }
    if(user_id) options['userId'] = user_id;

    this.currentIdTrack = idGA;
    const trackSplit       = this.currentIdTrack.split('-');
    this.currentIdProperty = trackSplit[1];
    try {
      const asyncScript = document.createElement('script');
      asyncScript.async = true;
      asyncScript.src = `https://www.googletagmanager.com/gtag/js?id=${idGA}`;

      const script = document.createElement('script');
      script.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${idGA}', ${JSON.stringify(options)});
      `;

      document.head.appendChild(asyncScript);
      document.head.appendChild(script);
    } catch (ex) {
     console.error('Error appending google analytics');
     console.error(ex);
    }
  }


}
