import { Component, Inject } from '@angular/core';
import { CoreLibModalComponent } from '../modal/modal.component';
import { DialogRef } from '../modal/dialog-ref';
import { DIALOG_DATA } from '../modal/dialog-tokens';

@Component({
  selector: 'mh-core-lib-doubt-collection-modal',
  templateUrl: './doubt-collection-modal.component.html',
  styleUrls: ['./doubt-collection-modal.component.scss'],
})
export class CoreLibDoubtCollectionModalComponent extends CoreLibModalComponent {
  constructor(protected dialogRef: DialogRef, @Inject(DIALOG_DATA) public data: any) {
    super(dialogRef, data);
    console.log(this.data.user);
  }

  handleClose() {
    this.dialogRef.close();
  }

  handleInvoiceRedirect() {
    this.handleClose();
    this.navigateInvoice();
  }

  navigateInvoice() {
    return this.data.navigateInvoice();
  }

  get name() {
    return this.data.user.name;
  }

  get isAdmin() {
    return this.data.user.admin || this.data.user.superAdmin;
  }

  get isWarning() {
    const WARNING_ID = 4;
    return this.data.type.translate[0].alert_id === WARNING_ID;
  }

  get isSuspension() {
    const SUSPENSION_ID = 5;
    return this.data.type.translate[0].alert_id === SUSPENSION_ID;
  }
}
