import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mh-semantic-header',
  templateUrl: './semantic-header.component.html',
  styleUrls: ['../../../../assets/scss/shared/semantic/detail.scss'],
})
export class SemanticHeaderComponent implements OnInit {

  @Input() commentId: string
  @Input() productId: string
  queryParams
  routerSemantico = '/semantic/comments'

  constructor(
  ) { }

  ngOnInit() {
    this.queryParams = {productId: this.productId, commentId: this.commentId}
  }

}
