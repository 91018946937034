<div class="satisfaction_{{survey.nps}}">
  <div class="qualify-icon" *ngIf="!isCardView">
    <ng-container [ngSwitch]="survey.nps">
      <ng-template [ngSwitchCase]="'promoter'">
        <i class="far fa-smile"></i>
      </ng-template>
      <ng-template [ngSwitchCase]="'passive'">
        <i class="far fa-meh"></i>
      </ng-template>
      <ng-template [ngSwitchCase]="'detractor'">
        <i class="far fa-frown"></i>
      </ng-template>
      <ng-template ngSwitchDefault>
        -
      </ng-template>
    </ng-container>
    {{(survey.nps ? ('qualifys.'+survey.nps | translate) : '' )| titlecase}}
  </div>

  <div class="qualify" *ngIf="isCardView">
    {{survey.nps ? ('qualifys.'+survey.nps | translate) : '' }}
  </div>
</div>
