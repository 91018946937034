import { ErrorHandler } from '@angular/core';
import { Notifier } from '@airbrake/browser';
import { environment } from '../environments/environment';


export class AirbrakeErrorHandler implements ErrorHandler {
  INCLUDED_URL_REGEX = [
    /survey-public/,
    /\/prestay\/(login|precheckin|services)/
  ];
  airbrake: Notifier;

  constructor() {
    this.airbrake = new Notifier({
      projectId: 266189,
      projectKey: '90e4dfbb3e8f1a69c10e128bb42c6c19',
      environment: environment.envName,
    });
    this.setFilters();
  }

  handleError(error: any): void {
    console.error({...error, error: error.message, stack: error.stack });
    this.airbrake.notify(JSON.stringify({...error, error: error.message, stack: error.stack }));
  }

  setFilters() {
    // enabled modules
    this.airbrake.addFilter(notice => {
      if (!this.INCLUDED_URL_REGEX.some(reg => reg.test(notice.context.url)))
        return null;

      return notice;
    })

    // environment
    this.airbrake.addFilter(notice => {
      if (notice.context.environment !== 'production') return null;

      return notice
    })
  }
}
