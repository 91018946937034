<mh-box-element [title]="('semantic.summary.semantic_index.title' | translate)"
  [ngbTooltip]="tooltips"
  container="body">
  <div class="index_box tw-relative -tw-top-1 tw-h-[155px] tw-max-h-[155px] tw-px-4 tw-py-0 tw-bg-white tw-rounded-[7px] tw-shadow-[0_0_5px_rgb(221,221,221,1)]">
    <ng-container *ngIf="waiting">
      <widget-wrapper-wait [width]="3" [drawBottom]="[1,0,1]" [height]="25">
        <ng-container *ngTemplateOutlet="widgetSemanticIndex"></ng-container>
      </widget-wrapper-wait>
    </ng-container>
    <ng-container *ngIf="!waiting">
      <ng-container *ngTemplateOutlet="widgetSemanticIndex"></ng-container>
    </ng-container>
  </div>
</mh-box-element>

<ng-template #widgetSemanticIndex>
  <div class="graph_draw tw-h-[90px] tw-w-[150px] tw-mx-auto tw-my-0 tw-relative tw-top-[15%]">
    <div class="graph_info tw-relative tw-text-center tw-top-[10px]">
      <div class="step tw-text-main-text tw-text-[33px] tw-font-bold tw-mt-3">{{semanticIndex | formatPercent: true:false:true }}</div>
    </div>
    <div class="progress_box tw-flex tw-justify-center tw-items-center -tw-rotate-90">
      <mat-progress-spinner class="graph graph_box !tw-absolute tw-z-10"
        [strokeWidth]="15"
        [diameter]="120"
        [mode]="'determinate'"
        [value]="semanticIndex / 2">
      </mat-progress-spinner>

      <mat-progress-spinner class="graph total_graph !tw-absolute tw-z-0"
        [strokeWidth]="15"
        [diameter]="120"
        [mode]="'determinate'"
        [value]="50">
      </mat-progress-spinner>
    </div>
  </div>

  <div class="sentiments_scores">
    <div class="row no-gutters scores tw-absolute tw-w-[calc(100%-32px)] tw-bottom-[5%]">
      <ng-container *ngFor="let item of sentimentsScore">
        <div class="col-6"
        ngbTooltip="{{('semantic.summary.semantic_index.tooltips.'+item.polarity | translate:{mentions: item.total_mentions.actual, total:totalMentions})}}">
          <div class="mr-2">
            <div class="data_info {{item.polarity}} tw-text-center tw-py-0 tw-px-1 tw-mx-auto" container="body">
              <div class="percent tw-text-main-text">
                <span class="tw-relative tw-top-[3px] tw-text-main tw-text-2xl">{{item.total_mentions.actual}}</span> ({{item.percentage.actual | number:'1.0-0'}}%)
            </div>
              <div 
                class="title tw-text-base tw-leading-4 tw-border-b-[3px] tw-pb-1" 
                [ngClass]="{
                    'tw-text-main-orange-active tw-border-main-orange-active': item.polarity === 'negative',
                    'tw-text-main-green-success tw-border-main-green-success': item.polarity === 'positive'
                }"
                [innerHTML]="('semantic.summary.semantic_index.mentions.'+item.polarity | translate)">
            </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #tooltips>
  <span>{{('semantic.summary.semantic_index.tooltips.title' | translate)}}</span>
  <br>
  <span>{{('semantic.summary.semantic_index.tooltips.title_2' | translate)}}</span>
</ng-template>
