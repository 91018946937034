<div class="tw-overflow-x-auto">
  <table class="tw-border-separate tw-border-spacing-0 {{ className }} tw-min-w-full tw-overflow-hidden">
    <thead class="tw-bg-main-gray-table tw-text-table-font {{ customCssClasses }}" *ngIf="showHeader">
      <ng-content select="[thead]"></ng-content>
    </thead>
    <tbody>
      <ng-content select="[tbody]"></ng-content>
    </tbody>
    <tfoot class="tw-bg-main-gray-table" [style.height.px]="footerHeight" *ngIf="showFooter">
      <ng-content select="[tfooter]"></ng-content>
    </tfoot>
  </table>
</div>
<mh-core-lib-pagination-selector
  *ngIf="showPaginator"
  [pagination]="DefaultPaginationSize"
  [currentPage]="currentPage"
  [totalElements]="totalElements"
  (paginationSize)="setPagination($event)"
  (pageChanged)="pageChanged.emit($event)"
>
</mh-core-lib-pagination-selector>
