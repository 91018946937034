<mh-alerts-wrapper>

  <mh-filter-date [report]="fidelityPermisions.includes('interchain_online_summary_report') ? 'chainOnlineSummary' : null " [reportActiveEmail]="true"></mh-filter-date>
  <mh-box-element [title]="('inter-chain.online.summary.title' | translate )" *ngIf="fidelityPermisions.includes('interchain_online_summary_result')">
    <div class="filter_zone">
      <widget-filter-chain-zone [productId]="productId" (chainChanged)="setChain($event)" [disabled]="!isReady"></widget-filter-chain-zone>
    </div>
    <mh-inter-chain-online-summary [headerKeys]="headerTable" [startDate]="util.startDate"  [endDate]="util.endDate" [chain]="chainFilter" (isReady)="dataIsReady($event)"></mh-inter-chain-online-summary>
  </mh-box-element>

</mh-alerts-wrapper>
