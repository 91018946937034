import { Component } from '@angular/core';
import { UtilService } from "../../../../shared/util.service";
import { Hotel } from 'app/shared/model';
import { DateService } from 'app/shared/services/date.service';


@Component({
  selector: 'mh-online-executive-section',
  templateUrl: './online-executive-section.component.html',
})
export class OnlineExecutiveSectionsComponent{

  productId = 3;

  filters = [
    'iro',
    'nps',
    'average_rating',
    'reviews_quantity',
    'promoter_percentage',
    'detractors_percentage'
  ];

  fidelityPermisions:string[] = [];
  chain: Hotel[] = [];

  constructor(
    private utilService: UtilService,
    private dateService: DateService
  ){

  }

  ngOnInit(): void {
    this.chain = this.utilService.getInnerChain(false,'online');
    this.fidelityPermisions = this.utilService.getFidelityConfigurationsByProduct('interchain', 'interchain_online');
  }

  handleDatepickerUpdated($event) {
    this.dateService.setCurrentFilter($event.currentFilter);
    this.dateService.setCurrentDates($event.currentDates)
  }

  get currentDates() {
    return this.dateService.currentDates;
  }

  get startDate() {
    return this.currentDates.startDate;
  }

  get endDate() {
    return this.currentDates.endDate;
  }

  get currentFilterDate() {
    return this.dateService.currentFilter;
  }
}



