<section class="tw-w-full">
    <div class="tw-flex tw-justify-end">
        <i class="fal fa-times tw-text-3xl tw-text-gray-500 tw-cursor-pointer" (click)="closeModal()"></i>
    </div>

    <article class="tw-flex tw-flex-col tw-text-center">
        <div>
            <p *ngIf="!success; else successTitle" class="tw-font-semibold tw-text-2xl">{{ 'notifications.central.mute_notifications' | translate }}</p>
            <ng-template #successTitle>
                <p class="tw-font-semibold tw-text-2xl">{{ 'notifications.central.done' | translate }}</p>
            </ng-template>
        </div>
    
        <div class="tw-my-0 tw-mx-auto">
            <img src="../../../../../assets/images/easy-pose.png" alt="Pose yoga">
        </div>

        <div class="tw-w-2/3 tw-my-0 tw-mx-auto">
            <p class="tw-text-lg" *ngIf="!success; else successContent">
                {{ 'notifications.central.before_msg' | translate }} <span class="tw-font-medium">{{ 'notifications.central.your_phone' | translate }}</span> {{ 'notifications.central.after_msg' | translate }}
            </p>
            <ng-template #successContent>
                <p class="tw-text-lg tw-mb-8">
                    {{ 'notifications.central.success_msg' | translate: { hours: hourSelected } }}
                </p>
            </ng-template>
            
        </div>
    </article>

    <ng-container *ngIf="!success">
        <article class="tw-flex tw-justify-evenly tw-mt-8">
            <div *ngFor="let option of muteOptions; let i = index" (change)="handleChange(option)">
                <input class="tw-border-gray-300 tw-border-4 tw-w-6 tw-h-6" type="radio" name="radioOptions" id="{{'radioOption' + i}}" [value]="option">
                <label class="tw-text-lg ml-2 tw-lowercase tw-text-black tw-font-normal" for="{{'radioOption' + i}}">{{ option }} horas</label>
            </div>
        </article>
    
        <article class="tw-flex tw-justify-center tw-my-8">
            <mh-core-lib-button 
                [text]="'notifications.central.mute_notifications' | translate" 
                [isSecondary]="true"
                [width]="200"
                (click)="muteNotifications()"
                customClass="tw-bg-product-prestay tw-text-white">
            </mh-core-lib-button>
        </article>
    </ng-container>

</section>