import { Component, ChangeDetectorRef, OnInit, AfterContentInit } from '@angular/core';
import { UtilService } from "../../shared/util.service";
import { User, UserProfile } from '../../shared/model/index';
import { UsersService } from "./users.service";
import { AppComponent } from "../../layouts/app/app-component";
import { EditUserService } from '../edit/edit-user.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-configuration-users',
  templateUrl: './users.component.html',
  styleUrls: ['../../../assets/scss/configuration/users.scss'],
  providers: [EditUserService]
})
export class UsersComponent extends AppComponent implements OnInit, AfterContentInit {

  products: any[] = [];
  users: UserProfile[] = [];
  waitingDelete = false;
  waitingReset = false;
  userToEdit:UserProfile;
  modalReference: NgbModalRef;
  currentUser: User;
  routeSub: Subscription;
  selectedTab = 0;

  readonly USER_CONFIGURATION_PAGES = {
    'gestor-usuarios': 0,
    'registro-ingresos': 1
  }

  constructor (
    private utilService: UtilService,
    private usersService: UsersService,
    private editUserService: EditUserService,
    private modalService: NgbModal,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    super()
    this.utilService.isUsersReload().subscribe(response => {
      this.initUsers(response)
    })
    this.utilService.isCustomerChanged().subscribe(response => {
      this.initUsers(false);
    })
  }

  ngAfterContentInit() {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    this.initUsers(false);
    this.currentUser = this.utilService.getCurrentUser();
    this.activatedRoute.params
      .pipe(take(1))
      .subscribe((params) => {
        this.selectedTab = +this.USER_CONFIGURATION_PAGES[params['page']];
      });
  }

  initUsers(newUser: UserProfile | boolean) {
    this.setWaiting(true);
    this.users = [];
    this.usersService.getUsers(this.utilService.getCurrentHotel().id).subscribe((response: any) => {
      this.users = response;
      this.users = this.users.sort(function (a, b) { return (a.first_name > b.first_name) ? 1 : ((b.first_name > a.first_name) ? -1 : 0); })
      if (newUser !== false) {
        let indexNewUser = this.users.findIndex(x => x.username === newUser["username"])
        if (indexNewUser >= 0) {
          let userFirts = this.users[indexNewUser]
          this.users.splice(indexNewUser, 1);
          this.users = [userFirts].concat(this.users);
        }

      }
      this.setWaiting(false);
    })
  }

  handleUserUpdated() {
    this.initUsers(false);
  }

  userType(user) {
    return user.admin ? "admin" : "user";
  }


  deleteUser(user, content) {
    this.userToEdit = user;
    this.waitingDelete = false;
    this.modalReference = this.modalService.open(content, {
      windowClass: 'modal-confirm'
    });
  }

  resetPassword(user, content) {
    this.userToEdit = user;
    this.waitingReset = false;
    this.modalReference = this.modalService.open(content, {
      windowClass: 'modal-confirm'
    });
  }

  closeDialog() {
    this.modalReference.close();
  }

  confirmDelete() {
    this.waitingDelete = true;
    this.usersService.deleteUser(this.userToEdit.id).subscribe(
      (response: any) => {
        this.waitingDelete = false;
        if (response === 1) {
          let index = this.users.findIndex(x => x.id === this.userToEdit.id);
          this.users.splice(index, 1);
        }
        this.closeDialog();
      }
    )

  }

  confirmReset() {
    this.waitingReset = true;
    this.editUserService.resetPassword(this.userToEdit).subscribe((response: any) => {
      this.waitingReset = false;
      if (response.update) {
        let index = this.users.findIndex(x => x.id === this.userToEdit.id);
        this.users.splice(index, 1);
      }
      this.closeDialog();
    })

  }

  setPassword(user, content) {
    this.userToEdit = user;
    this.waitingReset = false;
    this.modalReference = this.modalService.open(content, {
      windowClass: 'modal-confirm'
    });
  }

  tabChanged(event: MatTabChangeEvent) {
    const url = this.router.createUrlTree([`../${Object.keys(this.USER_CONFIGURATION_PAGES)[event.index]}`], {relativeTo: this.activatedRoute}).toString();
    this.router.navigateByUrl(url);
  }
}


