<!--
  NOTE: Sacamos las `mat-cards` porque quitaban espacio (como 16px por lado)
  las mat-card nos quitaban como el porte de un radio mas por fila
-->
<!-- <ng-container *ngIf="!conditionalId; else content">
  <mat-card [class.invalid-question]="answered && isInvalid" class="question-spacing">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </mat-card>
</ng-container> -->
<ng-container *ngTemplateOutlet="content"></ng-container>

<ng-template #content>
  <div class="question-spacing">
    <div [class.invalid-question]="answered && isInvalid"> <!-- include && !conditionalId -->
      <h3 class="question-title">
        <span>{{!conditionalId ? question.order : '*'}}. </span>
        {{question.translates | translateObject:'text':language}}
      </h3>
      <div class="container question-body"
        [class.container]="question.alternatives[0].alternative_type.name !=='grid'"
      >
        <div class="flex-row d-flex" *ngIf="isGrid && isOneRowGrid">
          <div>{{question.translates | translateObject:'min_text':language}}</div>
          <div class="ml-auto">{{question[0].translates | translateObject:'max_text':language}}</div>
        </div>
        <ng-container [ngSwitch]="question.alternatives[0].alternative_type.name">
          <mh-survey-question-radio *ngSwitchCase="'radiobutton'"
            [questionId]="question.id"
            [alternatives]="question.alternatives"
            [language]="language"
            [answers]="answers"
            [conditionalId]="conditionalId"
            [preview]="preview"
            (answer)="handleAnswer($event)"
          ></mh-survey-question-radio>
          <mh-survey-question-checkbox *ngSwitchCase="'checkbox'"
            [questionId]="question.id"
            [alternatives]="question.alternatives"
            [language]="language"
            [answers]="answers"
            [conditionalId]="conditionalId"
            [preview]="preview"
            (answer)="handleAnswer($event)"
          ></mh-survey-question-checkbox>
          <mh-survey-question-comment *ngSwitchCase="'comentario'"
            [questionId]="question.id"
            [answerId]="answerId"
            [alternatives]="question.alternatives"
            [language]="language"
            [languageId]="languageId"
            [surveyLanguageId]="surveyLanguageId"
            [answers]="answers"
            [conditionalId]="conditionalId"
            [preview]="preview"
            [productId]="productId"
            [answersComments]="answersComments"
            (answer)="handleAnswer($event)"
          ></mh-survey-question-comment>
          <mh-survey-question-text *ngSwitchCase="'texto'"
            [questionId]="question.id"
            [alternatives]="question.alternatives"
            [language]="language"
            [answers]="answers"
            [conditionalId]="conditionalId"
            [preview]="preview"
            (answer)="handleAnswer($event)"
          ></mh-survey-question-text>
          <mh-survey-question-grid *ngSwitchCase="'grid'"
            [questionId]="question.id"
            [question]="question"
            [alternatives]="question.alternatives"
            [language]="language"
            [answers]="answers"
            [answered]="answered"
            [conditionalId]="conditionalId"
            [conditionalQuestions]="conditionalQuestionsInput"
            [preview]="preview"
            [surveyLanguageId]="surveyLanguageId"
            [languageId]="languageId"
            [productId]="productId"
            [answersComments]="answersComments"
            (answer)="handleAnswer($event)"
          ></mh-survey-question-grid>
          <mh-survey-question-date *ngSwitchCase="'fecha'"
            [questionId]="question.id"
            [alternatives]="question.alternatives"
            [language]="language"
            [answers]="answers"
            [conditionalId]="conditionalId"
            [preview]="preview"
            [flagDefaultValue]="question.flag_default_value"
            (answer)="handleAnswer($event)"
            ></mh-survey-question-date>
          <mh-survey-question-single-pick-list *ngSwitchCase="'singlePickList'"
              [questionId]="question.id"
              [alternatives]="question.alternatives"
              [language]="language"
              [answers]="answers"
              [conditionalId]="conditionalId"
              [preview]="preview"
              (answer)="handleAnswer($event)">
          </mh-survey-question-single-pick-list>
          <div *ngSwitchCaseDefault>
            NOT IMPLEMENTED
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- TODO: implement proper styles -->
  <div *ngFor="let condQuestion of conditionalQuestions">
    <ng-container *ngIf="condQuestion.active && condQuestion.questions.length">
      <div class="conditional-container">
        <mh-survey-question-public *ngFor="let question of condQuestion.questions"
          [question]="question"
          [answers]="answers"
          [answered]="answered"
          [conditionalQuestions]="[]"
          [language]="language"
          [conditionalId]="condQuestion.alternativeId"
          [preview]="preview"
          [surveyLanguageId]="surveyLanguageId"
          [languageId]="languageId"
          [productId]="productId"
          (answer)="handleAnswer($event)"
        ></mh-survey-question-public>
      </div>
    </ng-container>
  </div>
</ng-template>
