<div class="tw-rounded tw-py-4 tw-px-4 tw-grid tw-bg-[#203641] tw-gap-y-1 tw-gap-x-1 tw-w-[150px] tw-max-w-[150px] tw-mx-auto tw-items-center" [ngClass]="{'tw-w-[150px]': !sideBarCollapsed, 'tw-w-[55px]': sideBarCollapsed, 'tw-grid-cols-2': !sideBarCollapsed, 'tw-place-content-center': sideBarCollapsed}">
  <div class="tw-col-start-1 tw-text-gray-300 tw-font-bold tw-flex tw-gap-2 tw-items-center">
    <ng-container *ngIf="sideBarCollapsed">
      <div mhTooltip [mhTooltip]="'OTAS'" [width]="'tw-w-fit'" [position]="'right'"  class="tw-w-[16px] tw-h-[16px] tw-rounded-full" [ngClass]="{'tw-bg-[#2bbb39]': !areOtasErrors, 'tw-bg-[#ff6f5c]': areOtasErrors}" *ngIf="!loading"></div>
    </ng-container>
    <ng-container *ngIf="!sideBarCollapsed">
      <div class="tw-w-[16px] tw-h-[16px] tw-rounded-full" [ngClass]="{'tw-bg-[#2bbb39]': !areOtasErrors, 'tw-bg-[#ff6f5c]': areOtasErrors}" *ngIf="!loading"></div>
    </ng-container>
    <i class="fas fa-spinner-third tw-animate-spin" *ngIf="loading"></i>
    <span class="tw-transition-all tw-text-sm tw-font-normal" [ngClass]="{'tw-hidden': sideBarCollapsed}">OTAS</span>
  </div>
  <div class="tw-place-self-end tw-transition-all" [ngClass]="{'tw-hidden': sideBarCollapsed}" *ngIf="!loading && areOtasErrors">
    <button type="button" (click)="handleOtasClick()" class="tw-rounded tw-px-2 tw-py-[2px] focus:tw-outline-0 tw-bg-table-font tw-text-white tw-text-center tw-text-xs">
      {{'commons.check' | translate}}
    </button>
  </div>

  <ng-container *ngIf="statusNavbar === 0">
    <div class="tw-col-start-1 tw-text-gray-300 tw-font-bold tw-flex tw-gap-2 tw-items-center">
      <i class="fas fa-spinner-third tw-animate-spin" *ngIf="loading"></i>
      <ng-container *ngIf="sideBarCollapsed">
        <div mhTooltip [mhTooltip]="'PMS'" [width]="'tw-w-fit'" [position]="'right'" class="tw-w-[16px] tw-h-[16px] tw-rounded-full" [ngClass]="{'tw-bg-[#2bbb39]': isIntegrationStatusOk, 'tw-bg-[#ff6f5c]': !isIntegrationStatusOk}" *ngIf="!loading"></div>
      </ng-container>
      <ng-container *ngIf="!sideBarCollapsed">
        <div class="tw-w-[16px] tw-h-[16px] tw-rounded-full" [ngClass]="{'tw-bg-[#2bbb39]': isIntegrationStatusOk, 'tw-bg-[#ff6f5c]': !isIntegrationStatusOk}" *ngIf="!loading"></div>
      </ng-container>
      <span class="tw-transition-all tw-text-sm tw-font-normal" [ngClass]="{'tw-hidden': sideBarCollapsed}">PMS</span>
    </div>
    <div class="tw-place-self-end tw-transition-all" [ngClass]="{'tw-hidden': sideBarCollapsed}" *ngIf="!isIntegrationStatusOk && !loading">
      <button type="button" (click)="handlePmsClick()" class="tw-rounded tw-px-2 tw-py-[2px] focus:tw-outline-0 tw-bg-table-font tw-text-white tw-text-center tw-text-xs">
        {{'commons.check' | translate}}
      </button>
    </div>
  </ng-container>
</div>
