import {QuestionComposite} from "./question.composite.interface";
export class Answer{
  private _option:number[];
  private _id:any;
  private  _idRelateQuestion:number;
  private _description:any;
  private _question:QuestionComposite;
  private _answerType:any;
  public _date: any;
  excerpt:string;
  cut=150;
  constructor( description?: string, answerType?: number)
  constructor( description?: string, answerType?: number, id?: number)
  constructor( description?: string, answerType?: number, id?: number, date?: any) {
    this._id = id;
    this._description = description;
    this._answerType = answerType;
    this._date = date;
    this.setExcerpt();
  }

  setExcerpt(){
    this.excerpt = this.hasExcerpt() ? this.description.substring(0,this.cut)+'...' : this.description;
  }

  hasExcerpt(){
    return this.description && (this.description.length > this.cut && (this.description.length - this.cut) > 80);
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }


  get idRelateQuestion(): number {
    return this._idRelateQuestion;
  }

  set idRelateQuestion(value: number) {
    this._idRelateQuestion = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get answerType(): number {
    return this._answerType;
  }

  set answerType(value: number) {
    this._answerType = value;
  }

  get date(): any {
    return this._date;
  }

  set date(value: any) {
    this._date = value;
  }


}
