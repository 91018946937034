import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  Observable,
  throwError as ObservableThrowError,
} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from "src/environments/environment";
import { CheckSetup, CheckSetupRes } from 'app/shared/model/check-setup.model';

@Injectable()
export class PrestayService {

  constructor(
    private http:HttpClient,
  ) { }

  private _handleError(err: HttpErrorResponse | any): Observable<any> {
    const errorMsg = err.message || 'Error: No se pudo completar la petición';
    return ObservableThrowError(errorMsg);
  }

  getSetupByCustomerId(customerId: string) {
    const url = `${environment.prestayPath}customers/${customerId}/check/setup`;
    return this.http.get<CheckSetupRes>(url)
      .pipe(
        map(resp => CheckSetup.createFromResponse(resp)),
        catchError(error => this._handleError(error))
        )
      .toPromise()
  }

}
