<div class="semantic_panel">
  <div class="title_box">
    <i container="body" [ngbTooltip]="tooltipNegative" class="far fa-frown"></i>
    <span
      [innerHtml]="('online.reviews.qualify_semantic' | translate:({qualify: ('shared.semantic.type_comment.has_negative' | translate ) }) )"></span>
    <small>&nbsp;<a href="javascript:void(0)" class="see_detail" [routerLink]="routerSemantico" [queryParams]="queryParams" target="_blank">({{'commons.see_detail' | translate }})</a></small>
  </div>
  <ng-template #tooltipNegative>
    <div [innerHtml]="('shared.semantic.sentiment.negative' | translate)"></div>
  </ng-template>
</div>
