<div class="tw-justify-between row no-gutters" (clickOutside)="closeOutsideSidenav()">
  <div class="col-{{!util.isMobile() && report ? 10 : 12 }} tw-hidden">
    <div class="filter_date" *ngIf="!waiting" [class.visible]="visible" >
      <div class="date_selected" (click)="toggleVisble($event)" >
        <i class="far fa-calendar-alt"></i>
        <div class="start_date" [innerHtml]="(util.stringDateToDate(startDate) | formatDate)"></div>
        <div class="end_date" [innerHtml]="(isToday ? (util.stringDateToDate(endDateReal) | formatDate) : (util.stringDateToDate(endDate) | formatDate))"></div>
        <div class="filter_name">{{'shared.filters.date.filters.'+filterSelected | translate}}</div>
        <div class="bar_state">
          <i class="fal fa-chevron-{{visible ? 'up' : 'down'}}"></i>
        </div>
      </div>

      <div class="area_line" [class.visible]="visible" >
        <ul class="range_options">
          <li *ngFor="let filter of filterOptions" [class.selected]="filter['name']==filterSelected" (click)="changeFilter(filter)" [style.width]="filterWidth">
            <div class="selection">
              {{'shared.filters.date.filters.'+filter['name'] | translate}}
            </div>
            <div class="date" *ngIf="filter['name'] != 'custom'">
              <div class="init" [innerHtml]="(util.stringDateToDate(filterCalculate(filter['name'])['init']) | formatDate )"></div>
              <div class="end" [innerHtml]="(util.stringDateToDate(filterCalculate(filter['name'])['end']) | formatDate)"></div>
            </div>

            <div class="date custom" *ngIf="filter['name'] == 'custom'" >
              <div class="init" [innerHtml]="(util.stringDateToDate(startDate) | formatDate)"></div>
              <div class="end" [innerHtml]="(util.stringDateToDate(endDateReal)    | formatDate)"></div>
            </div>
          </li>
        </ul>

        <mh-datepicker
          (dateChange)="onDateChange($event)"
          [showCalendar]="showCalendar"
          [startDate]="startDate"
          [endDate]="endDate"
        ></mh-datepicker>

      </div>
    </div>
  </div>
  <div>
  </div>
  <ng-container *ngIf="report">
    <ng-container *ngIf="checkPermissions else dontCheck">
      <mh-core-lib-actions-wrapper [slug]="slug">
        <ng-template *ngTemplateOutlet="downloadExcel"></ng-template>
      </mh-core-lib-actions-wrapper>
    </ng-container>
    <ng-template #dontCheck>
      <ng-template *ngTemplateOutlet="downloadExcel"></ng-template>
    </ng-template>
  </ng-container>

</div>

<ng-template #downloadExcel>
  <div class="" [hidden]="util.isMobile()">
    <div class="download_excel">
      <mh-download-excel
        [type]="report"
        [chain]="chain"
        [initDate]="startDate"
        [endDate]="endDate"
        [activeEmail]="reportActiveEmail"
        [isSingleCustomer]="isSingleCustomer"
        [currentSurveyId]="currentSurveyId"
        [disableReport]="disableReport">
      </mh-download-excel>
    </div>
  </div>
</ng-template>