import { Component, inject, Inject, OnInit } from '@angular/core';
import { DIALOG_DATA } from '../modal/dialog-tokens';
import { DialogRef } from '../modal/dialog-ref';
import { CoreLibModalComponent } from '../modal/modal.component';
import { Case } from 'app/new-fs/desk/models/Case.model';
import { UtilService } from 'app/shared/util.service';

@Component({
  selector: 'mh-core-lib-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss'],
})
export class CoreLibShareModalComponent extends CoreLibModalComponent implements OnInit {
  public case: Case;
  readonly utilService = inject(UtilService);

  constructor(protected dialogRef: DialogRef, @Inject(DIALOG_DATA) public data) {
    super(dialogRef, data);
  }

  ngOnInit(): void {
    this.case = this.data?.case;
  }

  handleShare(source: string) {
    source === 'email'
        ? this.utilService.ga('', 'desk-click-share-email')
        : this.utilService.ga('', 'desk-click-share-whatsapp');
    const text = `${
      source === 'email'
        ? this.case?.time_management?.share_case_text
        : this.case?.time_management?.share_case_text_whats_app
    }\n${source === 'email' ? this.case?.url_to_share_mail : this.case?.url_to_share_whats_app}`;

    const encodedText = encodeURIComponent(text);
    if (source === 'ws') {
      const url = `https://wa.me/?text=${encodedText}`;
      window.open(url, '_blank');
    } else {
      window.location.href = `mailto:?body=${encodedText}`;
    }
  }

  handleCopyUrl(url: string) {    
    this.utilService.ga('', 'desk-click-share-copylink');    
    navigator.clipboard.writeText(url);
  }
}
