<div class="tw-rounded-lg tw-shadow-dashboard-card tw-border tw-border-slate-200/50 tw-h-full">
  <div class="tw-px-6 tw-py-2 tw-h-full tw-flex tw-flex-col tw-justify-between">
    <!-- Title -->
    <p class="tw-text-base tw-text-black">
      {{ 'dashboard.cards.titles.' + widget.key | translate }}
    </p>

    <!-- Content - Value -->
    <div *ngIf="widget.enable; else textProduct">
      <ng-container *ngIf="widget?.value !== null && widget?.value !== undefined; else noneValue">
        <ng-container [ngSwitch]="widget.type">
          <p *ngSwitchCase="'nps'" class="tw-text-[36px] tw-font-extrabold tw-my-1">
            <span *ngIf="widget?.value > 0">+</span>{{ widget.value }}
          </p>
          <p *ngSwitchCase="'calification'" class="tw-text-[36px] tw-font-extrabold tw-my-1">
            {{ widget.value | number }}
          </p>
          <p *ngSwitchCase="'currency'" class="tw-text-[36px] tw-font-extrabold tw-my-1">
            {{ widget.value | currency : 'USD' : 'USD$ ' }}
          </p>
          <p *ngSwitchCase="'fraction'" class="tw-text-[36px] tw-font-extrabold tw-my-1">
            <span class="tw-text-[36px]">{{ ('' + widget?.value).split('/')[0] }}</span> /
            <span class="tw-text-4xl">{{ ('' + widget?.value).split('/')[1] }}</span>
          </p>
          <p *ngSwitchCase="'percentage'" class="tw-text-[36px] tw-font-extrabold tw-my-1">
            {{ widget.value | number : '1.0-0' }}%
          </p>
          <p *ngSwitchDefault class="tw-text-[36px] tw-font-extrabold tw-my-1">
            {{ widget.value }}
          </p>
        </ng-container>
      </ng-container>
    </div>

    <div
      class="tw-flex"
      [ngClass]="widget.enable && widget.value && widget.has_variation ? 'tw-justify-between' : 'tw-justify-end'"
    >
      <p *ngIf="widget.enable && widget.value && widget.has_variation" class="tw-text-gray-400 tw-text-small">
        <ng-container *ngIf="widget?.variation else noVariation">
          <span
            class="tw-text-green-600 tw-font-bold"
            [ngClass]="{
              'tw-text-green-600': widget.variation_type === 'positive',
              'tw-text-red-600': widget.variation_type === 'negative'
            }"
          >
            <i
              class="fas fa-arrow-up"
              [ngClass]="{
                'tw-rotate-45': widget.variation_type === 'positive',
                'tw-rotate-[135deg]': widget.variation_type === 'negative'
              }"
            ></i>
            {{ widget?.variation || 0 }} {{ (['followup', 'onsite'].includes(widget.key) ? '' : '%' ) }}
          </span>
          {{ 'dashboard.cards.variation' | translate }}
        </ng-container>

        <ng-template #noVariation>
          {{ 'dashboard.cards.no_variation' | translate }}
        </ng-template>
      </p>
      <ng-container *ngIf="widget.enable; else unableWidgetButton">
        <a
          *ngIf="widget.link_type === 'link'"
          class="tw-uppercase tw-text-gray-400 tw-text-base tw-font-medium"
          [ngClass]="{ '!tw-text-action-hover': widget.key === 'cases' && !widget.value }"
          [routerLink]="[widget.url]"
        >
          {{ 'commons.see_more' | translate }}
        </a>
        <ng-container *ngIf="widget.link_type === 'action'">
          <a
            *ngIf="widget.value === '0/0'; else anotherLinkType"
            class="tw-uppercase tw-text-gray-400 tw-text-base tw-font-medium"
            [ngClass]="{ '!tw-text-action-hover': widget.key === 'cases' && !widget.value }"
            (click)="handleClick(widget.key)"
          >
            {{
              (widget.key === 'cases' && widget.value === '0/0' ? 'cases.titles.form' : 'commons.see_more') | translate
            }}
          </a>
          <ng-template #anotherLinkType>
            <a
              class="tw-uppercase tw-text-gray-400 tw-text-base tw-font-medium"
              [ngClass]="{ '!tw-text-action-hover': widget.key === 'cases' && !widget.value }"
              [routerLink]="[widget.url]"
            >
              {{
                (widget.key === 'cases' && widget.value === '0/0' ? 'cases.titles.form' : 'commons.see_more')
                  | translate
              }}
            </a>
          </ng-template>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #textProduct>
  <span class="tw-my-1 tw-text-lg">{{ 'dashboard.cards.contents.' + widget.key | translate }}</span>
</ng-template>

<ng-template #noneValue>
  <span *ngIf="widget.error" class="tw-my-1 tw-text-lg">{{ 'dashboard.cards.error' | translate }}</span>
  <span *ngIf="!widget.error" class="tw-my-1 tw-text-lg">{{
    (widget.key === 'cases' && !widget.value ? 'dashboard.cards.no_desk_data' : 'dashboard.cards.no_data') | translate
  }}</span>
</ng-template>

<ng-template #unableWidgetButton>
  <a
    href="{{ widget.inactive_url[currentLanguage] }}"
    target="_blank"
    class="tw-uppercase tw-text-border-main tw-font-semibold"
    >{{ 'dashboard.cards.more_info' | translate }}</a
  >
</ng-template>
