export default [
  { "country": "Afghanistan", "code_phone": "93", "code2": "AF", "code3": "AFG", "pattern": "^[0-9]{1,9}$" },
  { "country": "Albania", "code_phone": "355", "code2": "AL", "code3": "ALB", "pattern": "^[0-9]{8,9}$" },
  { "country": "Algeria", "code_phone": "213", "code2": "DZ", "code3": "DZA", "pattern": "^[0-9]{9}$" },
  { "country": "Andorra", "code_phone": "376", "code2": "AD", "code3": "AND", "pattern": "^[0-9]{6}$" },
  { "country": "Angola", "code_phone": "244", "code2": "AO", "code3": "AGO", "pattern": "^[0-9]{9}$" },
  { "country": "Antigua and Barbuda", "code_phone": "1-268", "code2": "AG", "code3": "ATG", "pattern": "^[0-9]{7}$" },
  { "country": "Argentina", "code_phone": "54", "code2": "AR", "code3": "ARG", "pattern": "^[0-9]{10,11}$" },
  { "country": "Armenia", "code_phone": "374", "code2": "AM", "code3": "ARM", "pattern": "^[0-9]{8}$" },
  { "country": "Australia", "code_phone": "61", "code2": "AU", "code3": "AUS", "pattern": "^[0-9]{9}$" },
  { "country": "Austria", "code_phone": "43", "code2": "AT", "code3": "AUT", "pattern": "^[0-9]{4,13}$" },
  { "country": "Azerbaijan", "code_phone": "994", "code2": "AZ", "code3": "AZE", "pattern": "^[0-9]{9,12}$" },
  { "country": "Bahamas", "code_phone": "1-242", "code2": "BS", "code3": "BHS", "pattern": "^[0-9]{7}$" },
  { "country": "Bahrain", "code_phone": "973", "code2": "BH", "code3": "BHR", "pattern": "^[0-9]{8}$" },
  { "country": "Bangladesh", "code_phone": "880", "code2": "BD", "code3": "BGD", "pattern": "^[0-9]{10}$" },
  { "country": "Barbados", "code_phone": "1-246", "code2": "BB", "code3": "BRB", "pattern": "^[0-9]{7}$" },
  { "country": "Belarus", "code_phone": "375", "code2": "BY", "code3": "BLR", "pattern": "^[0-9]{9}$" },
  { "country": "Belgium", "code_phone": "32", "code2": "BE", "code3": "BEL", "pattern": "^[0-9]{8,9}$" },
  { "country": "Belize", "code_phone": "501", "code2": "BZ", "code3": "BLZ", "pattern": "^[0-9]{7}$" },
  { "country": "Benin", "code_phone": "229", "code2": "BJ", "code3": "BEN", "pattern": "^[0-9]{8}$" },
  { "country": "Bhutan", "code_phone": "975", "code2": "BT", "code3": "BTN", "pattern": "^[0-9]{8}$" },
  { "country": "Bolivia", "code_phone": "591", "code2": "BO", "code3": "BOL", "pattern": "^[0-9]{8,9}$" },
  { "country": "Bosnia and Herzegovina", "code_phone": "387", "code2": "BA", "code3": "BIH", "pattern": "^[0-9]{8}$" },
  { "country": "Botswana", "code_phone": "267", "code2": "BW", "code3": "BWA", "pattern": "^[0-9]{7}$" },
  { "country": "Brazil", "code_phone": "55", "code2": "BR", "code3": "BRA", "pattern": "^[0-9]{10,11}$" },
  { "country": "British Virgin Islands", "code_phone": "1-284", "code2": "VG", "code3": "VGB", "pattern": "^[0-9]{7}$" },
  { "country": "Brunei", "code_phone": "673", "code2": "BN", "code3": "BRN", "pattern": "^[0-9]{7}$" },
  { "country": "Bulgaria", "code_phone": "359", "code2": "BG", "code3": "BGR", "pattern": "^[0-9]{7,9}$" },
  { "country": "Burkina Faso", "code_phone": "226", "code2": "BF", "code3": "BFA", "pattern": "^[0-9]{8}$" },
  { "country": "Burundi", "code_phone": "257", "code2": "BI", "code3": "BDI", "pattern": "^[0-9]{8}$" },
  { "country": "Cabo Verde", "code_phone": "238", "code2": "CV", "code3": "CPV", "pattern": "^[0-9]{7}$" },
  { "country": "Cambodia", "code_phone": "855", "code2": "KH", "code3": "KHM", "pattern": "^[0-9]{8,9}$" },
  { "country": "Cameroon", "code_phone": "237", "code2": "CM", "code3": "CMR", "pattern": "^[0-9]{9}$" },
  { "country": "Central African Republic", "code_phone": "236", "code2": "CF", "code3": "CAF", "pattern": "^[0-9]{8}$" },
  { "country": "Chad", "code_phone": "235", "code2": "TD", "code3": "TCD", "pattern": "^[0-9]{8}$" },
  { "country": "Chile", "code_phone": "56", "code2": "CL", "code3": "CHL", "pattern": "^[0-9]{9}$" },
  { "country": "China", "code_phone": "86", "code2": "CN", "code3": "CHN", "pattern": "^[0-9]{11}$" },
  { "country": "Colombia", "code_phone": "57", "code2": "CO", "code3": "COL", "pattern": "^[0-9]{10}$" },
  { "country": "Comoros", "code_phone": "269", "code2": "KM", "code3": "COM", "pattern": "^[0-9]{7}$" },
  { "country": "Congo", "code_phone": "242", "code2": "CG", "code3": "COG", "pattern": "^[0-9]{7,8}$" },
  { "country": "Congo, Democratic Republic of the", "code_phone": "243", "code2": "CD", "code3": "COD", "pattern": "^[0-9]{9}$" },
  { "country": "Cook Islands", "code_phone": "682", "code2": "CK", "code3": "COK", "pattern": "^[0-9]{4}$" },
  { "country": "Costa Rica", "code_phone": "506", "code2": "CR", "code3": "CRI", "pattern": "^[0-9]{8}$" },
  { "country": "Croatia", "code_phone": "385", "code2": "HR", "code3": "HRV", "pattern": "^[0-9]{8}$" },
  { "country": "Cuba", "code_phone": "53", "code2": "CU", "code3": "CUB", "pattern": "^[0-9]{8}$" },
  { "country": "Curaçao", "code_phone": "599", "code2": "CW", "code3": "CUW", "pattern": "^[0-9]{7}$" },
  { "country": "Cyprus", "code_phone": "357", "code2": "CY", "code3": "CYP", "pattern": "^[0-9]{8}$" },
  { "country": "Czech Republic", "code_phone": "420", "code2": "CZ", "code3": "CZE", "pattern": "^[0-9]{9}$" },
  { "country": "Denmark", "code_phone": "45", "code2": "DK", "code3": "DNK", "pattern": "^[0-9]{8}$" },
  { "country": "Djibouti", "code_phone": "253", "code2": "DJ", "code3": "DJI", "pattern": "^[0-9]{6,7}$" },
  { "country": "Dominica", "code_phone": "1-767", "code2": "DM", "code3": "DMA", "pattern": "^[0-9]{7}$" },
  { "country": "Dominican Republic", "code_phone": "1-809", "code2": "DO", "code3": "DOM", "pattern": "^[0-9]{7}$" },
  { "country": "Ecuador", "code_phone": "593", "code2": "EC", "code3": "ECU", "pattern": "^[0-9]{9}$" },
  { "country": "Egypt", "code_phone": "20", "code2": "EG", "code3": "EGY", "pattern": "^[0-9]{10}$" },
  { "country": "El Salvador", "code_phone": "503", "code2": "SV", "code3": "SLV", "pattern": "^[0-9]{8}$" },
  { "country": "Equatorial Guinea", "code_phone": "240", "code2": "GQ", "code3": "GNQ", "pattern": "^[0-9]{9}$" },
  { "country": "Eritrea", "code_phone": "291", "code2": "ER", "code3": "ERI", "pattern": "^[0-9]{7}$" },
  { "country": "Estonia", "code_phone": "372", "code2": "EE", "code3": "EST", "pattern": "^[0-9]{7}$" },
  { "country": "Eswatini", "code_phone": "268", "code2": "SZ", "code3": "SWZ", "pattern": "^[0-9]{7}$" },
  { "country": "Ethiopia", "code_phone": "251", "code2": "ET", "code3": "ETH", "pattern": "^[0-9]{9}$" },
  { "country": "Falkland Islands", "code_phone": "500", "code2": "FK", "code3": "FLK", "pattern": "^[0-9]{4}$" },
  { "country": "Faroe Islands", "code_phone": "298", "code2": "FO", "code3": "FRO", "pattern": "^[0-9]{6}$" },
  { "country": "Fiji", "code_phone": "679", "code2": "FJ", "code3": "FJI", "pattern": "^[0-9]{6}$" },
  { "country": "Finland", "code_phone": "358", "code2": "FI", "code3": "FIN", "pattern": "^[0-9]{5,12}$" },
  { "country": "France", "code_phone": "33", "code2": "FR", "code3": "FRA", "pattern": "^[0-9]{9}$" },
  { "country": "French Guiana", "code_phone": "594", "code2": "GF", "code3": "GUF", "pattern": "^[0-9]{9}$" },
  { "country": "French Polynesia", "code_phone": "689", "code2": "PF", "code3": "PYF", "pattern": "^[0-9]{6}$" },
  { "country": "Gabon", "code_phone": "241", "code2": "GA", "code3": "GAB", "pattern": "^[0-9]{7}$" },
  { "country": "Gambia", "code_phone": "220", "code2": "GM", "code3": "GMB", "pattern": "^[0-9]{7}$" },
  { "country": "Georgia", "code_phone": "995", "code2": "GE", "code3": "GEO", "pattern": "^[0-9]{9}$" },
  { "country": "Germany", "code_phone": "49", "code2": "DE", "code3": "DEU", "pattern": "^[0-9]{10,11}$" },
  { "country": "Ghana", "code_phone": "233", "code2": "GH", "code3": "GHA", "pattern": "^[0-9]{9}$" },
  { "country": "Gibraltar", "code_phone": "350", "code2": "GI", "code3": "GIB", "pattern": "^[0-9]{5}$" },
  { "country": "Greece", "code_phone": "30", "code2": "GR", "code3": "GRC", "pattern": "^[0-9]{10}$" },
  { "country": "Greenland", "code_phone": "299", "code2": "GL", "code3": "GRL", "pattern": "^[0-9]{6}$" },
  { "country": "Grenada", "code_phone": "1-473", "code2": "GD", "code3": "GRD", "pattern": "^[0-9]{7}$" },
  { "country": "Guadeloupe", "code_phone": "590", "code2": "GP", "code3": "GLP", "pattern": "^[0-9]{7}$" },
  { "country": "Guam", "code_phone": "1-671", "code2": "GU", "code3": "GUM", "pattern": "^[0-9]{7}$" },
  { "country": "Guatemala", "code_phone": "502", "code2": "GT", "code3": "GTM", "pattern": "^[0-9]{8}$" },
  { "country": "Guinea", "code_phone": "224", "code2": "GN", "code3": "GIN", "pattern": "^[0-9]{9}$" },
  { "country": "Guinea-Bissau", "code_phone": "245", "code2": "GW", "code3": "GNB", "pattern": "^[0-9]{8}$" },
  { "country": "Guyana", "code_phone": "592", "code2": "GY", "code3": "GUY", "pattern": "^[0-9]{7}$" },
  { "country": "Haiti", "code_phone": "509", "code2": "HT", "code3": "HTI", "pattern": "^[0-9]{8}$" },
  { "country": "Honduras", "code_phone": "504", "code2": "HN", "code3": "HND", "pattern": "^[0-9]{8}$" },
  { "country": "Hong Kong", "code_phone": "852", "code2": "HK", "code3": "HKG", "pattern": "^[0-9]{8}$" },
  { "country": "Hungary", "code_phone": "36", "code2": "HU", "code3": "HUN", "pattern": "^[0-9]{8,9}$" },
  { "country": "Iceland", "code_phone": "354", "code2": "IS", "code3": "ISL", "pattern": "^[0-9]{7}$" },
  { "country": "India", "code_phone": "91", "code2": "IN", "code3": "IND", "pattern": "^[0-9]{10}$" },
  { "country": "Indonesia", "code_phone": "62", "code2": "ID", "code3": "IDN", "pattern": "^[0-9]{10,13}$" },
  { "country": "Iran", "code_phone": "98", "code2": "IR", "code3": "IRN", "pattern": "^[0-9]{10}$" },
  { "country": "Iraq", "code_phone": "964", "code2": "IQ", "code3": "IRQ", "pattern": "^[0-9]{10}$" },
  { "country": "Ireland", "code_phone": "353", "code2": "IE", "code3": "IRL", "pattern": "^[0-9]{7,9}$" },
  { "country": "Israel", "code_phone": "972", "code2": "IL", "code3": "ISR", "pattern": "^[0-9]{9}$" },
  { "country": "Italy", "code_phone": "39", "code2": "IT", "code3": "ITA", "pattern": "^[0-9]{10}$" },
  { "country": "Jamaica", "code_phone": "1-876", "code2": "JM", "code3": "JAM", "pattern": "^[0-9]{7}$" },
  { "country": "Japan", "code_phone": "81", "code2": "JP", "code3": "JPN", "pattern": "^[0-9]{10}$" },
  { "country": "Jordan", "code_phone": "962", "code2": "JO", "code3": "JOR", "pattern": "^[0-9]{9}$" },
  { "country": "Kenya", "code_phone": "254", "code2": "KE", "code3": "KEN", "pattern": "^[0-9]{9}$" },
  { "country": "Kiribati", "code_phone": "686", "code2": "KI", "code3": "KIR", "pattern": "^[0-9]{4}$" },
  { "country": "Korea, North", "code_phone": "850", "code2": "KP", "code3": "PRK", "pattern": "^[0-9]{6,8}$" },
  { "country": "Korea, South", "code_phone": "82", "code2": "KR", "code3": "KOR", "pattern": "^[0-9]{9,11}$" },
  { "country": "Kuwait", "code_phone": "965", "code2": "KW", "code3": "KWT", "pattern": "^[0-9]{8}$" },
  { "country": "Kyrgyzstan", "code_phone": "996", "code2": "KG", "code3": "KGZ", "pattern": "^[0-9]{9}$" },
  { "country": "Laos", "code_phone": "856", "code2": "LA", "code3": "LAO", "pattern": "^[0-9]{8,9}$" },
  { "country": "Latvia", "code_phone": "371", "code2": "LV", "code3": "LVA", "pattern": "^[0-9]{8}$" },
  { "country": "Lebanon", "code_phone": "961", "code2": "LB", "code3": "LBN", "pattern": "^[0-9]{8}$" },
  { "country": "Lesotho", "code_phone": "266", "code2": "LS", "code3": "LSO", "pattern": "^[0-9]{8}$" },
  { "country": "Liberia", "code_phone": "231", "code2": "LR", "code3": "LBR", "pattern": "^[0-9]{7}$" },
  { "country": "Libya", "code_phone": "218", "code2": "LY", "code3": "LBY", "pattern": "^[0-9]{9}$" },
  { "country": "Liechtenstein", "code_phone": "423", "code2": "LI", "code3": "LIE", "pattern": "^[0-9]{8}$" },
  { "country": "Lithuania", "code_phone": "370", "code2": "LT", "code3": "LTU", "pattern": "^[0-9]{8}$" },
  { "country": "Luxembourg", "code_phone": "352", "code2": "LU", "code3": "LUX", "pattern": "^[0-9]{6,8}$" },
  { "country": "Madagascar", "code_phone": "261", "code2": "MG", "code3": "MDG", "pattern": "^[0-9]{9}$" },
  { "country": "Malawi", "code_phone": "265", "code2": "MW", "code3": "MWI", "pattern": "^[0-9]{8}$" },
  { "country": "Malaysia", "code_phone": "60", "code2": "MY", "code3": "MYS", "pattern": "^[0-9]{10}$" },
  { "country": "Maldives", "code_phone": "960", "code2": "MV", "code3": "MDV", "pattern": "^[0-9]{7}$" },
  { "country": "Mali", "code_phone": "223", "code2": "ML", "code3": "MLI", "pattern": "^[0-9]{8}$" },
  { "country": "Malta", "code_phone": "356", "code2": "MT", "code3": "MLT", "pattern": "^[0-9]{8}$" },
  { "country": "Marshall Islands", "code_phone": "692", "code2": "MH", "code3": "MHL", "pattern": "^[0-9]{5}$" },
  { "country": "Mauritania", "code_phone": "222", "code2": "MR", "code3": "MRT", "pattern": "^[0-9]{8}$" },
  { "country": "Mauritius", "code_phone": "230", "code2": "MU", "code3": "MUS", "pattern": "^[0-9]{8}$" },
  { "country": "Mexico", "code_phone": "52", "code2": "MX", "code3": "MEX", "pattern": "^[0-9]{10}$", flagClass: 'fas fa-flag-mx' },
  { "country": "Micronesia", "code_phone": "691", "code2": "FM", "code3": "FSM", "pattern": "^[0-9]{4}$" },
  { "country": "Moldova", "code_phone": "373", "code2": "MD", "code3": "MDA", "pattern": "^[0-9]{8}$" },
  { "country": "Monaco", "code_phone": "377", "code2": "MC", "code3": "MCO", "pattern": "^[0-9]{8}$" },
  { "country": "Mongolia", "code_phone": "976", "code2": "MN", "code3": "MNG", "pattern": "^[0-9]{8}$" },
  { "country": "Montenegro", "code_phone": "382", "code2": "ME", "code3": "MNE", "pattern": "^[0-9]{8}$" },
  { "country": "Morocco", "code_phone": "212", "code2": "MA", "code3": "MAR", "pattern": "^[0-9]{9}$" },
  { "country": "Mozambique", "code_phone": "258", "code2": "MZ", "code3": "MOZ", "pattern": "^[0-9]{9}$" },
  { "country": "Myanmar", "code_phone": "95", "code2": "MM", "code3": "MMR", "pattern": "^[0-9]{6,8}$" },
  { "country": "Namibia", "code_phone": "264", "code2": "NA", "code3": "NAM", "pattern": "^[0-9]{9}$" },
  { "country": "Nauru", "code_phone": "674", "code2": "NR", "code3": "NRU", "pattern": "^[0-9]{4}$" },
  { "country": "Nepal", "code_phone": "977", "code2": "NP", "code3": "NPL", "pattern": "^[0-9]{10}$" },
  { "country": "Netherlands", "code_phone": "31", "code2": "NL", "code3": "NLD", "pattern": "^[0-9]{9}$" },
  { "country": "New Caledonia", "code_phone": "687", "code2": "NC", "code3": "NCL", "pattern": "^[0-9]{6}$" },
  { "country": "New Zealand", "code_phone": "64", "code2": "NZ", "code3": "NZL", "pattern": "^[0-9]{9}$" },
  { "country": "Nicaragua", "code_phone": "505", "code2": "NI", "code3": "NIC", "pattern": "^[0-9]{8}$" },
  { "country": "Niger", "code_phone": "227", "code2": "NE", "code3": "NER", "pattern": "^[0-9]{8}$" },
  { "country": "Nigeria", "code_phone": "234", "code2": "NG", "code3": "NGA", "pattern": "^[0-9]{10}$" },
  { "country": "Niue", "code_phone": "683", "code2": "NU", "code3": "NIU", "pattern": "^[0-9]{4}$" },
  { "country": "Norfolk Island", "code_phone": "672", "code2": "NF", "code3": "NFK", "pattern": "^[0-9]{4}$" },
  { "country": "North Macedonia", "code_phone": "389", "code2": "MK", "code3": "MKD", "pattern": "^[0-9]{8}$" },
  { "country": "Northern Mariana Islands", "code_phone": "1-670", "code2": "MP", "code3": "MNP", "pattern": "^[0-9]{7}$" },
  { "country": "Norway", "code_phone": "47", "code2": "NO", "code3": "NOR", "pattern": "^[0-9]{8}$" },
  { "country": "Oman", "code_phone": "968", "code2": "OM", "code3": "OMN", "pattern": "^[0-9]{8}$" },
  { "country": "Pakistan", "code_phone": "92", "code2": "PK", "code3": "PAK", "pattern": "^[0-9]{10}$" },
  { "country": "Palau", "code_phone": "680", "code2": "PW", "code3": "PLW", "pattern": "^[0-9]{7}$" },
  { "country": "Palestine", "code_phone": "970", "code2": "PS", "code3": "PSE", "pattern": "^[0-9]{9}$" },
  { "country": "Panama", "code_phone": "507", "code2": "PA", "code3": "PAN", "pattern": "^[0-9]{8}$" },
  { "country": "Papua New Guinea", "code_phone": "675", "code2": "PG", "code3": "PNG", "pattern": "^[0-9]{7}$" },
  { "country": "Paraguay", "code_phone": "595", "code2": "PY", "code3": "PRY", "pattern": "^[0-9]{9}$" },
  { "country": "Peru", "code_phone": "51", "code2": "PE", "code3": "PER", "pattern": "^[0-9]{9}$" },
  { "country": "Philippines", "code_phone": "63", "code2": "PH", "code3": "PHL", "pattern": "^[0-9]{10}$" },
  { "country": "Pitcairn Islands", "code_phone": "870", "code2": "PN", "code3": "PCN", "pattern": "^[0-9]{4}$" },
  { "country": "Poland", "code_phone": "48", "code2": "PL", "code3": "POL", "pattern": "^[0-9]{9}$" },
  { "country": "Portugal", "code_phone": "351", "code2": "PT", "code3": "PRT", "pattern": "^[0-9]{9}$" },
  { "country": "Puerto Rico", "code_phone": "1-787", "code2": "PR", "code3": "PRI", "pattern": "^[0-9]{7}$" },
  { "country": "Qatar", "code_phone": "974", "code2": "QA", "code3": "QAT", "pattern": "^[0-9]{8}$" },
  { "country": "Réunion", "code_phone": "262", "code2": "RE", "code3": "REU", "pattern": "^[0-9]{9}$" },
  { "country": "Romania", "code_phone": "40", "code2": "RO", "code3": "ROU", "pattern": "^[0-9]{10}$" },
  { "country": "Russia", "code_phone": "7", "code2": "RU", "code3": "RUS", "pattern": "^[0-9]{10}$" },
  { "country": "Rwanda", "code_phone": "250", "code2": "RW", "code3": "RWA", "pattern": "^[0-9]{9}$" },
  { "country": "Saint Helena", "code_phone": "290", "code2": "SH", "code3": "SHN", "pattern": "^[0-9]{4}$" },
  { "country": "Saint Kitts and Nevis", "code_phone": "1-869", "code2": "KN", "code3": "KNA", "pattern": "^[0-9]{7}$" },
  { "country": "Saint Lucia", "code_phone": "1-758", "code2": "LC", "code3": "LCA", "pattern": "^[0-9]{7}$" },
  { "country": "Saint Pierre and Miquelon", "code_phone": "508", "code2": "PM", "code3": "SPM", "pattern": "^[0-9]{6}$" },
  { "country": "Saint Vincent and the Grenadines", "code_phone": "1-784", "code2": "VC", "code3": "VCT", "pattern": "^[0-9]{7}$" },
  { "country": "Samoa", "code_phone": "685", "code2": "WS", "code3": "WSM", "pattern": "^[0-9]{5}$" },
  { "country": "San Marino", "code_phone": "378", "code2": "SM", "code3": "SMR", "pattern": "^[0-9]{10}$" },
  { "country": "Sao Tome and Principe", "code_phone": "239", "code2": "ST", "code3": "STP", "pattern": "^[0-9]{6}$" },
  { "country": "Saudi Arabia", "code_phone": "966", "code2": "SA", "code3": "SAU", "pattern": "^[0-9]{9}$" },
  { "country": "Senegal", "code_phone": "221", "code2": "SN", "code3": "SEN", "pattern": "^[0-9]{9}$" },
  { "country": "Serbia", "code_phone": "381", "code2": "RS", "code3": "SRB", "pattern": "^[0-9]{9}$" },
  { "country": "Seychelles", "code_phone": "248", "code2": "SC", "code3": "SYC", "pattern": "^[0-9]{7}$" },
  { "country": "Sierra Leone", "code_phone": "232", "code2": "SL", "code3": "SLE", "pattern": "^[0-9]{9}$" },
  { "country": "Singapore", "code_phone": "65", "code2": "SG", "code3": "SGP", "pattern": "^[0-9]{8}$" },
  { "country": "Sint Maarten", "code_phone": "1-721", "code2": "SX", "code3": "SXM", "pattern": "^[0-9]{7}$" },
  { "country": "Slovakia", "code_phone": "421", "code2": "SK", "code3": "SVK", "pattern": "^[0-9]{9}$" },
  { "country": "Slovenia", "code_phone": "386", "code2": "SI", "code3": "SVN", "pattern": "^[0-9]{8}$" },
  { "country": "Solomon Islands", "code_phone": "677", "code2": "SB", "code3": "SLB", "pattern": "^[0-9]{5}$" },
  { "country": "Somalia", "code_phone": "252", "code2": "SO", "code3": "SOM", "pattern": "^[0-9]{6,9}$" },
  { "country": "South Africa", "code_phone": "27", "code2": "ZA", "code3": "ZAF", "pattern": "^[0-9]{9}$" },
  { "country": "South Sudan", "code_phone": "211", "code2": "SS", "code3": "SSD", "pattern": "^[0-9]{9}$" },
  { "country": "Spain", "code_phone": "34", "code2": "ES", "code3": "ESP", "pattern": "^[0-9]{9}$" },
  { "country": "Sri Lanka", "code_phone": "94", "code2": "LK", "code3": "LKA", "pattern": "^[0-9]{9}$" },
  { "country": "Sudan", "code_phone": "249", "code2": "SD", "code3": "SDN", "pattern": "^[0-9]{9}$" },
  { "country": "Suriname", "code_phone": "597", "code2": "SR", "code3": "SUR", "pattern": "^[0-9]{7}$" },
  { "country": "Sweden", "code_phone": "46", "code2": "SE", "code3": "SWE", "pattern": "^[0-9]{10}$" },
  { "country": "Switzerland", "code_phone": "41", "code2": "CH", "code3": "CHE", "pattern": "^[0-9]{9}$" },
  { "country": "Syria", "code_phone": "963", "code2": "SY", "code3": "SYR", "pattern": "^[0-9]{9}$" },
  { "country": "Taiwan", "code_phone": "886", "code2": "TW", "code3": "TWN", "pattern": "^[0-9]{9}$" },
  { "country": "Tajikistan", "code_phone": "992", "code2": "TJ", "code3": "TJK", "pattern": "^[0-9]{9}$" },
  { "country": "Tanzania", "code_phone": "255", "code2": "TZ", "code3": "TZA", "pattern": "^[0-9]{9}$" },
  { "country": "Thailand", "code_phone": "66", "code2": "TH", "code3": "THA", "pattern": "^[0-9]{9}$" },
  { "country": "Timor-Leste", "code_phone": "670", "code2": "TL", "code3": "TLS", "pattern": "^[0-9]{7}$" },
  { "country": "Togo", "code_phone": "228", "code2": "TG", "code3": "TGO", "pattern": "^[0-9]{8}$" },
  { "country": "Tokelau", "code_phone": "690", "code2": "TK", "code3": "TKL", "pattern": "^[0-9]{4}$" },
  { "country": "Tonga", "code_phone": "676", "code2": "TO", "code3": "TON", "pattern": "^[0-9]{4}$" },
  { "country": "Trinidad and Tobago", "code_phone": "1-868", "code2": "TT", "code3": "TTO", "pattern": "^[0-9]{7}$" },
  { "country": "Tunisia", "code_phone": "216", "code2": "TN", "code3": "TUN", "pattern": "^[0-9]{8}$" },
  { "country": "Turkey", "code_phone": "90", "code2": "TR", "code3": "TUR", "pattern": "^[0-9]{10}$" },
  { "country": "Turkmenistan", "code_phone": "993", "code2": "TM", "code3": "TKM", "pattern": "^[0-9]{8}$" },
  { "country": "Tuvalu", "code_phone": "688", "code2": "TV", "code3": "TUV", "pattern": "^[0-9]{4}$" },
  { "country": "Uganda", "code_phone": "256", "code2": "UG", "code3": "UGA", "pattern": "^[0-9]{9}$" },
  { "country": "Ukraine", "code_phone": "380", "code2": "UA", "code3": "UKR", "pattern": "^[0-9]{9}$" },
  { "country": "United Arab Emirates", "code_phone": "971", "code2": "AE", "code3": "ARE", "pattern": "^[0-9]{9}$" },
  { "country": "United Kingdom", "code_phone": "44", "code2": "GB", "code3": "GBR", "pattern": "^[0-9]{10}$" },
  { "country": "United States", "code_phone": "1", "code2": "US", "code3": "USA", "pattern": "^[0-9]{10}$" },
  { "country": "Uruguay", "code_phone": "598", "code2": "UY", "code3": "URY", "pattern": "^[0-9]{8}$" },
  { "country": "Uzbekistan", "code_phone": "998", "code2": "UZ", "code3": "UZB", "pattern": "^[0-9]{9}$" },
  { "country": "Vanuatu", "code_phone": "678", "code2": "VU", "code3": "VUT", "pattern": "^[0-9]{4}$" },
  { "country": "Venezuela", "code_phone": "58", "code2": "VE", "code3": "VEN", "pattern": "^[0-9]{10}$" },
  { "country": "Vietnam", "code_phone": "84", "code2": "VN", "code3": "VNM", "pattern": "^[0-9]{10}$" },
  { "country": "Yemen", "code_phone": "967", "code2": "YE", "code3": "YEM", "pattern": "^[0-9]{9}$" },
  { "country": "Zambia", "code_phone": "260", "code2": "ZM", "code3": "ZMB", "pattern": "^[0-9]{9}$" },
  { "country": "Zimbabwe", "code_phone": "263", "code2": "ZW", "code3": "ZWE", "pattern": "^[0-9]{9}$" }
]
