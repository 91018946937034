<div [formGroup]="parentForm" *ngIf="hotelDto">
  <div formArrayName="images" class="hotelImages">
    <div *ngFor="let image of images.controls; let i=index">
      <ng-container *ngIf="image.value.logo" >
        <mh-change-image
          [image]="image.value.path"
          [ratio]="1"
          [onlyCropp]="true"
          [disabled]="disabled"
          (changed)="updateImage($event,image)"
          (waiting)="changedImage($event)">
      </mh-change-image>
      </ng-container>
    </div>
  </div>
</div>


