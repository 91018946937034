import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mh-survey-question-date',
  templateUrl: './survey-question-date.component.html',
  styleUrls: ['./survey-question-date.component.scss']
})
export class SurveyQuestionDateComponent implements OnInit {

  @Input() questionId:number;
  @Input() alternatives:any[]
  @Input() answers:any[];
  @Input() language:string;
  @Input() conditionalId:number;
  @Input() preview = false;
  @Input() flagDefaultValue = false;

  @Output() answer:EventEmitter<any> = new EventEmitter()

  answerDisplay = '';

  constructor() { }

  ngOnChanges(changes) {
    this.answerDisplay = this.formatDate(null, true)
  }

  ngOnInit(): void {
    this.setDefaultValue();
    this.answerDisplay = this.formatDate(null, true)
  }

  setDefaultValue() {
    this.answerDisplay = (this.flagDefaultValue) ?
      this.formatDate(new Date(), true) : '';
  }

  ngOnDestroy() {
    if (!this.conditionalId || !this.getAnswer().answer_text)
      return;

    this.answer.emit(this.createAnswer());
  }

  dateChange(event:any) {
    const { target: { value } } = event
    const formattedDate = this.formatDate(value, false);
    this.answerDisplay = this.formatDate(value, true);
    

    const answer = this.createAnswer(formattedDate);
    this.answer.emit(answer);
  }

  createAnswer(value?: string) {
    const prevAnswer = this.getAnswer();

    return {
      id: prevAnswer.id,
      question_id: this.questionId,
      answer_text: value,
      answer_alternative_id: this.alternatives[0].id,
      conditional_alternative_id: this.conditionalId,
    }
  }

  getAnswer() {
    return this.answers.find(({question_id, conditional_alternative_id}) =>
      question_id === this.questionId &&
        (!this.conditionalId || this.conditionalId === conditional_alternative_id)
    ) || {};
  }

  isDisabled() {
    if (this.preview) return true;
    const answer = this.getAnswer()
    return answer.waiting;
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  formatDate(date, isForDisplay: boolean) {
    const answer = this.getAnswer();
    const splittedDate = answer.answer_text.split('-');
    const formattedDate = `${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`
    return (isForDisplay) ?
      formattedDate :
    [
      this.padTo2Digits(date.getMonth() + 1),
      this.padTo2Digits(date.getDate()),
      date.getFullYear(),
    ].join('/');
  }

}
