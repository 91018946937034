import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PrecheckinGuest } from 'app/shared/prestay/precheckin-guest/precheckin-guest.interface';

@Component({
  selector: 'mh-guest-icons',
  templateUrl: './guest-icons.component.html',
  styleUrls: ['./guest-icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuestIconsComponent {

  @Input()
  guest: PrecheckinGuest;

}
