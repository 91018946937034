import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment }  from "../../../environments/environment";
import { User, Access } from "../../shared/model/index";
import { map } from 'rxjs/operators';
@Injectable()
export class EditUserService {

  constructor(private http:HttpClient) { }

  public saveProfileData(user){
    const url=`${environment.customerUser}/basic/${user.id}`;
    return this.http.put(url,user)
      .pipe(
        map(response=>response)
      )
  }

  public createUser(user){
    const url=`${environment.customerUser}/basic`;
    return this.http.post(url,user)
      .pipe(
        map(response=>response)
      )
  }

  public getSuperAdminData(user_id:string){
    const url=`${environment.superAdminProperties}`;
    const params:HttpParams = new HttpParams()
      params.set("user_id",user_id);
    return this.http.get(url, {params: params})
      .pipe(
        map(response=>response)
      )
  }

  public usersInCustomer(customerId){
    const url=`${environment.customerUsers}`;
    const params:HttpParams = new HttpParams()
      .set("customer_id",customerId);
    return this.http.get(url, {params: params})
      .pipe(
        map(response=>response)
      );
  }

  public saveSuperAdminData(superAdminProperties){
    const url=`${environment.superAdminProperties}`;
    return this.http.put(url,superAdminProperties)
      .pipe(
        map(response=>response)
      )
  }

  public changePassword(userId,body){
    const url=`${environment.customerUser}/${userId}/profile_password`;
    return this.http.put(url,body)
      .pipe(
        map(response=>response)
      );
  }

  public resetPassword(user){
    const url=`${environment.customerUser}/${user.id}/restore/password`;
    return this.http.put(url,{})
      .pipe(
        map(response=>response)
      );
  }

  public setPassword(user){
    const url=`${environment.customerUser}/${user.id}/change/password/`;
    return this.http.put(url,user)
      .pipe(
        map(response=>response)
      );
  }


  public deleteUser(user){
    let url=`${environment.customerUser}/basic/${user.id}`;
    return this.http.delete(url)
      .pipe(
        map(response=>response)
      );
  }

  formatDataToUpdate(user){
    return {
      "company_position": user.profile.company_position,
      "email": user.email,
      "language":{
        "description": user.language,
        "id": user.languageCode
      },
      "last_name": user.lastName,
      "name": user.name,
      "phone": user.profile.phone,
      "user_name":user.userName
    }
  }

  formatDataToCreate(user:User, access:Access[], customers){
    return {
     "access": access,
     "admin": user.admin,
     "customers": customers,
     "password": "prueba",
     "profile": {
       "company_position": (user.profile && user.profile.company_position ?  user.profile.company_position : ''),
       "email": user.email,
       "language": {
         "id": 1
       },
       "last_name": user.lastName,
       "name": user.name,
       "phone": user.profile.phone
     },
     "super_admin": false,
     "user_name": user.email
    }
  }
}
