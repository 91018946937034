import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'widget-label-value-single',
  templateUrl: './label-value-single.component.html',
  styleUrls: ['./label-value-single.component.scss']
})
export class LabelValueSingleComponent implements OnInit {

  @Input()
  label: string;

  @Input()
  value: any;

  constructor() { }

  ngOnInit() {
  }

}
