import { Component, OnInit, HostListener, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Select } from '@ngxs/store';
import { UtilService } from 'app/shared/util.service';
import { FidelityDeskState } from 'app/states/fidelity-desk/fidelity-desk.state';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'mh-core-lib-cases-selector',
  templateUrl: './cases-selector.component.html',
  styleUrls: ['./cases-selector.component.scss']
})
export class CoreLibCasesSelectorComponent implements OnInit, OnDestroy {

  isOpen = false;
  cases = [];

  displayDeskOptions = (option) => {
    return `${option.title}`;
  }

  displayIdOptions = (option) => {
    return `${option.public_id}`;
  }

  autocompleteFilterFn = (options, value) => {
    return options.filter((option: any) => {
      return option.title.toLowerCase().includes(value.toLowerCase()) ||
        option.public_id.toLowerCase().includes(value.toLocaleLowerCase())
    });
  }

  @Select(FidelityDeskState.commentsSorted) cases$: Observable<any>;

  @Output()
  caseSelected = new EventEmitter();

  subscription: Subscription;

  @HostListener('document:click', ['$event'])
  onClick(event){
    const result = this.checkClickInside(event.target);
    if ( !result ) {
      this.isOpen = false;
    }
  }

  constructor(private utilService: UtilService) { }

  ngOnInit(): void {
    this.subscription = this.cases$.subscribe(cases => {
      if ( cases && cases.length > 0 ) {
        this.cases = cases;
      }
    })
  }

  handleCaseSelected(event) {
    this.isOpen = false;
    this.caseSelected.emit(event);
    this.utilService.ga('cases', 'desk-click-search');
  }

  checkClickInside(target) {
    if ( target.id === 'overlaySelectorDesk' || target.id === 'selectorDesk' || target.className?.includes('autocomplete-option') || target.id === 'autocompleteInput') {
      return true;
    } else if ( target.parentNode ) {
      return this.checkClickInside(target.parentNode)
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
