<div class="modal-header" [ngClass]="headerStyles">
  <!-- el espacio es para que ocupe el espacio de la linea respectiva -->
  <ng-container *ngIf="title">
    {{title}}
  </ng-container>
  &nbsp;
  <button type="button" class="close" aria-label="Close" (click)="onClose()">
    <span class="exit-x" aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-wrapper">
  <ng-content></ng-content>
</div>
