<article>
    <ng-container *ngFor="let item of items; let idx = index">
        <ng-container *ngIf="currentSlide === idx">
            <!-- Title -->
            <section class="tw-p-5">
                <p class="tw-font-medium tw-text-lg">{{ item?.translates[0]?.title }}</p>
            </section>
        
            <!-- Multimedia -->
            <section>
                <youtube-player *ngIf="item?.has_video; else imageContainer" [videoId]="item?.translates[0]?.banner" width="100%" loadApi="false" [playerVars]="{'autoplay': 1}" />
                <ng-template #imageContainer>
                    <img [src]="item?.translates[0]?.banner" class="tw-mx-auto tw-my-0 tw-max-h-96 tw-w-full" />
                </ng-template>
            </section>
        </ng-container>
    </ng-container>

      <!-- Slider controls -->
    <section class="tw-mt-4 tw-flex tw-justify-center tw-items-center tw-gap-2 tw-cursor-pointer tw-text-cyan-500 tw-font-semibold">
        <span *ngIf="!firstItemOnCarousel" class="tw-uppercase tw-text-xs tw-flex tw-items-center tw-gap-1" (click)="handlePreviousClick()"><i class="fas fa-angle-left"></i> {{ 'commons.previous' | translate }}</span>
        <span *ngIf="!firstItemOnCarousel && !lasItemOnCarousel">|</span>
        <span *ngIf="!lasItemOnCarousel" class="tw-uppercase tw-text-xs tw-flex tw-items-center tw-gap-1" (click)="handleNextClick()"> {{ 'commons.next' | translate }} <i class="fas fa-angle-right"></i></span>
    </section>
</article>