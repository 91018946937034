<button class="core-datepicker-btn tw-px-4 tw-py-0.5 md:tw-py-2 tw-shadow-1 tw-rounded-full tw-text-main focus:tw-outline-0 tw-h-14" [class.activated]="showCalendar" type="button" (click)="toggleCalendar()">
  <div class="core-datepicker-display-btn tw-flex tw-flex-col md:tw-px-3">
    <span class="tw-text-small md:tw-text-base tw-font-medium lg:tw-text-small">{{selectedFilter | translate}}</span>
    <span class="tw-hidden lg:tw-inline md:tw-text-xs">{{dateSelectedFormatedBtn}} - {{finalDateSelectedFormatedBtn}}</span>
  </div>
</button>

<section class="core-datepicker" *ngIf="showCalendar" 
  [class.tw-w-72]="datepickerMobileActive">

  <div class="core-datepicker-display">

    <article class="core-datepicker-display-filters lg:tw-w-1/5 lg:tw-block tw-basis-full"
      [class.tw-hidden]="datepickerMobileActive"
      >
      <ul class="tw-text-center core-datepicker-display-filters-options">
        <li *ngFor="let option of filterOptions" class="core-datepicker-filter-option" (click)="handleClickFilter(option)">
          <span [class.tw-text-main]="option.selected">
            {{option.label | translate}}
          </span>
        </li>
      </ul>
    </article>

    <article class="core-datepicker-display-picker lg:tw-block"
      [class.tw-hidden]="!datepickerMobileActive"
      [class.sm:tw-block]="datepickerMobileActive"
      >

      <div class="tw-pl-1 tw-mb-2 tw-ml-3 tw-text-lg tw-font-bold tw-cursor-pointer lg:tw-hidden tw-w-fit" (click)="toggleFiltersMobile()">
        <i class="far fa-arrow-left"></i>
      </div>

      <div class="core-datepicker-range">
        <section class="core-datepicker-display-ranges"
          *ngFor="let i of calendar; let first = first; let last = last; let index = index"
          [class.tw-w-full]="datepickerMobileActive"
          [class.sm:tw-mb-3]="first">
          <div class="core-datepicker-range-header">
            <div class="core-datepicker-range-header-display">
              <button class="core-datepicker-header-button" (click)="toggleInputs((first) ? 'first' : 'last', index)">{{((first) ? firstInputMonth : secondInputMonth) | translate}} <i class="tw-text-main far fa-angle-down"></i></button>

              <div class="core-datepicker-filter-menu" *ngIf="(first) ? inputs[0] : inputs[2]">
                <ul>
                  <li *ngFor="let month of months" class="core-datepicker-header-filter-options" (click)="setDateFromInput(month, (first) ? 'first' : 'last', index)">{{month.label | translate}}</li>
                </ul>
              </div>

            </div>
            <div>
              <button class="core-datepicker-header-button" (click)="toggleInputs((first) ? 'last' : 'first', index)">{{(first) ? firstInputYear : secondInputYear}}  <i class="tw-text-main far fa-angle-down"></i></button>

              <div class="core-datepicker-filter-menu core-datepicker-year-filter" *ngIf="(first) ? inputs[1] : inputs[3]">
                <ul>
                  <li *ngFor="let year of years" class="core-datepicker-header-filter-options" (click)="setDateFromInput(year, (first) ? 'last' : 'first', index)">{{year}}</li>
                </ul>
              </div>
            </div>

          </div>
          <article class="core-datepicker-range-body">
            <table>
              <thead>
                <tr class="tw-text-center tw-text-disabled">
                  <th *ngFor="let day of days">{{day.label | translate}}</th>
                </tr>
              </thead>
              <tbody id="calendar-body">

                <tr *ngFor="let row of i">
                  <ng-container *ngFor="let prev of row.previous">
                    <td 
                     class="core-datepicker-td-prev"
                     [class.tw-bg-second-disabled]="(prev.date | checkDateDiff:dateSelected:currentHoveredDate:finalDateSelected)"
                     [class.tw-rounded-tl-md]="(prev.date | checkDateDiff:dateSelected)"
                     [class.tw-rounded-bl-md]="(prev.date | checkDateDiff:dateSelected)"
                     [class.hover:tw-rounded-md]="!isSelectingRange"
                     [class.hover:tw-rounded-tr-md]="isSelectingRange"
                     [class.hover:tw-rounded-br-md]="isSelectingRange"
                     (click)="handleClick(prev.date, 'prev', index)"
                     (mouseenter)="compareDates(prev.date)">
                      <div class="date-content">
                        {{prev.day}}
                      </div>
                    </td>
                  </ng-container>
                  <ng-container *ngFor="let current of row.current">
                    <td 
                     class="core-datepicker-td-current"
                     [class.tw-bg-second-disabled]="(current.date | checkDateDiff:dateSelected:currentHoveredDate:finalDateSelected)"
                     [class.tw-bg-main]="(current.date | checkDateDiff: dateSelected)"
                     [class.tw-text-white]="(current.date | checkDateDiff: dateSelected)"
                     [class.tw-rounded-tl-md]="(current.date | checkDateDiff:dateSelected)"
                     [class.tw-rounded-bl-md]="(current.date | checkDateDiff:dateSelected)"
                     [class.tw-rounded-tr-md]="(current.date | checkDateDiff:finalDateSelected)"
                     [class.tw-rounded-br-md]="(current.date | checkDateDiff:finalDateSelected)"
                     [class.hover:tw-rounded-md]="!isSelectingRange"
                     [class.hover:tw-rounded-tr-md]="isSelectingRange"
                     [class.hover:tw-rounded-br-md]="isSelectingRange"
                     [ngClass]="(current.date | checkDateDiff: finalDateSelected) ? 'tw-bg-main tw-text-white' : ''"
                     (click)="handleClick(current.date, 'current', index)"
                     (mouseenter)="compareDates(current.date)"
                     >
                     <div class="date-content">
                       {{current.day}}
                     </div>
                    </td>


                  </ng-container>
                  <td *ngFor="let next of row.next"
                     class="core-datepicker-td-next"
                     [class.tw-bg-second-disabled]="(next.date | checkDateDiff:dateSelected:currentHoveredDate:finalDateSelected)"
                     [class.tw-bg-main]="(next.date | checkDateDiff: dateSelected)"
                     [class.tw-text-white]="(next.date | checkDateDiff: dateSelected)"
                     [class.tw-rounded-tl-md]="(next.date | checkDateDiff:dateSelected)"
                     [class.tw-rounded-bl-md]="(next.date | checkDateDiff:dateSelected)"
                     [class.hover:tw-rounded-md]="!isSelectingRange"
                     [class.hover:tw-rounded-tr-md]="isSelectingRange"
                     [class.hover:tw-rounded-br-md]="isSelectingRange"
                     (click)="handleClick(next.date, 'next', index)"
                     (mouseenter)="compareDates(next.date)">
                    <div class="date-content">
                      {{next.day}}
                    </div>
                  </td>
                </tr>

              </tbody>
            </table>

            <div *ngIf="first && false">
              <span *ngIf="initDate && endDate">
                <i class="fad fa-times-circle"></i> {{initDate}} - <i class="fad fa-times-circle"></i> {{endDate}}
              </span>
            </div>
          </article>
        </section>
      </div>
    </article>

  </div>
  <div class="tw-hidden lg:tw-flex lg:tw-flex-row"
    [class.sm:tw-block]="datepickerMobileActive">
    <div class="tw-basis-1/5"></div>
    <div class="tw-px-4 tw-basis-4/5 lg:tw-text-left"
      [class.sm:tw-text-center]="datepickerMobileActive">
      {{selectedDates}}
    </div>
  </div>
  <div *ngIf="showRangeError" class="tw-hidden lg:tw-flex tw-mt-2">
    <div class="tw-basis-1/5"></div>
    <div class="tw-px-4 tw-basis-4/5 lg:tw-text-left">
      <span class="tw-font-semibold"> <i class="fas fa-exclamation-triangle tw-text-red-600 tw-mr-1"></i> {{ 'datepicker.error_msg' | translate }}</span>
    </div>
  </div>
  
</section>
