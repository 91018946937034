import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, of, timer } from 'rxjs';
import { NotificationsData } from '../models/communications';
import { environment } from '../../../../environments/environment';
import { map, retry, share, takeUntil, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService implements OnDestroy {
  private emitterData$: Observable<any>;
  private stopPolling = new Subject();
  private updateNews$ = new Subject();
  public pollingInterval = 60000;
  public pollingSize = 10;

  constructor(private http: HttpClient) {}

  verifyAndRequest(payload: { page: number; pageSize?: number }, url: string) {
    if (document.hasFocus()) {
      const data: Observable<NotificationsData> = this.getNotificationsData(
        { ...payload, pageSize: this.pollingSize },
        url,
      );
      return data;
    } else {
      return of(null);
    }
  }

  getNotificationsData(payload: { page: number; pageSize?: number }, url: string): Observable<NotificationsData> {
    const { page, pageSize } = payload;
    const params = new HttpParams().set('page', page).set('page_size', pageSize || 10);
    return this.http.get<NotificationsData>(url, { params });
  }

  initPolling(url, payload: { page: number; pageSize?: number }) {
    this.emitterData$ = timer(1, this.pollingInterval).pipe(
      switchMap(() => this.verifyAndRequest(payload, url)),
      map((res: NotificationsData) => (res ? res : [])),
      retry(3),
      share(),
      takeUntil(this.stopPolling),
    );

    return this.emitterData$;
  }

  destroyPolling() {
    this.stopPolling.next();
  }

  listenUpdateOnNews() {
    return this.updateNews$.asObservable();
  }

  emitUpdateOnNews() {
    this.updateNews$.next();
  }

  changeNotificationStatus(url, requestParams = null) {
    const params = requestParams
      ? new HttpParams().set('customer_id', requestParams?.customerId).set('viewed_time', requestParams?.viewedTime)
      : null;
    return this.http.patch(url, null, { params });
  }

  markAllAsRead(userId: number, notificationType = 'ALL') {
    const url = `${environment.notificationsPath}manager/notifications/users/${userId}/status/READ`;
    const params = new HttpParams().set('notification_type', notificationType);
    return this.http.patch(url, {}, { params });
  }

  getNotificationsDataByDates(
    userId: number,
    langId: number,
    payload: { page: number; pageSize: number; startDate: string; endDate: string },
    isAlerts = true,
  ): Observable<NotificationsData> {
    const url = `${environment.notificationsPath}notification-${
      isAlerts ? 'alerts' : 'news'
    }/users/${userId}/language/${langId}/dates`;
    const { page, pageSize, startDate, endDate } = payload;

    const params = new HttpParams()
      .set('page', page)
      .set('page_size', pageSize)
      .set('start_date', startDate)
      .set('end_date', endDate);
    return this.http.get<NotificationsData>(url, { params });
  }

  pauseNotifications(userId: number, time: number): Observable<any> {
    const url = `${environment.notificationsPath}users/${userId}/pause-push`;
    const body = {
      flag_active: true,
      paused_time: time,
    };

    return this.http.put<any>(url, body);
  }

  ngOnDestroy() {
    this.destroyPolling();
  }
}
