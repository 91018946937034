import {FormGroup, FormArray} from "@angular/forms";

export function orderValidator(orderForm:FormGroup): {[key: string]: boolean} | null | undefined {
  if (!orderForm || !orderForm.parent) return;

  const sectionsForm:FormArray = orderForm.parent.parent as FormArray;
  if(sectionsForm) {
    const pagesToUpdate = [...new Set(sectionsForm.value.map(section => section.page))];
    //Si es undefined, no tiene páginas y sólo ve preguntas 
    if(pagesToUpdate[0] === undefined) {
      // Acá revisar el parent y buscar la condición para tratar la pregunta o la alternativa

      const alternativeForm = orderForm.parent as FormGroup;
      const isAlternativeForm = !!alternativeForm.get('alternative_type');
      const form = (isAlternativeForm) ? alternativeForm.parent as FormArray : sectionsForm;


      if(isAlternativeForm) {
        const orders = form.value.map(alternative => alternative.order)
        const min = Math.min(...orders);
        if(min !== 1) return { noOrderOne: true }
        if(duplicatesOrder(orders)) return { duplicatedOrder: true };
      }

      const updated = form.value.map(value => value.order).sort((a,b) => a - b);
      if(!areOrdersConsecutives(updated)) return { noConsecutive: true }
    } else {
      let noOrderOne = false;
      const isWithoutErrors = pagesToUpdate.every(page => {
        const values = [];
        for(let i = 0; i < sectionsForm.controls.length; i++) {
          const pageValue = sectionsForm.at(i).get('page').value;
          if(pageValue === page) {
            const value = sectionsForm.at(i).get('order').value;
            values.push(value);
          }
        }
        if( values.length === 1 && !values.includes(1) ) {
          noOrderOne = true;
          return false;
        } else if ( values.length === 1 && values.includes(1) ) {
          const idx = sectionsForm.controls.findIndex(control => control.get('page').value === page );
          const controls = sectionsForm.at(idx) as FormGroup;
          controls.get('order').setErrors(null);
          return true;
        }
        if (areOrdersConsecutives(values)) {
          for(let i = 0; i < sectionsForm.controls.length; i++) {
            const pageValue = sectionsForm.at(i).get('page').value;
            if(pageValue === page) {
              sectionsForm.at(i).get('order').setErrors(null);
            }
          }
        }
        return areOrdersConsecutives(values);
      })
      if(noOrderOne) return { noOrderOne: true };
      if(!isWithoutErrors) return { noConsecutive: true };
    }

    return null
  }
}

export function orderValidatorConditional(orderForm: FormGroup) {
  if (!orderForm || !orderForm.parent) return;
  const sectionsForm:FormArray = orderForm.parent.parent as FormArray;
    const pagesToUpdate = [...new Set(sectionsForm.value.map(section => section.page))];
    if(pagesToUpdate[0] === undefined) {
      const areNoOrderZero = orderForm.value > 0;
      if(areNoOrderZero) return null;
      return { areOrderZero: true }
    }
}

function areOrdersConsecutives(ordersUpdated) {
  let lastValue = Math.min(...ordersUpdated) - 1;
  return ordersUpdated.sort((a,b) => a - b).every(order => {
    if(lastValue+1 === order) {
      lastValue = order;
      return true;
    }
  })
}

function duplicatesOrder(orders) {
  return (new Set(orders)).size !== orders.length;
}
