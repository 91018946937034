import { Component, OnInit, Inject } from '@angular/core';
import { UtilService } from 'app/shared/util.service';
import { ActivatedRoute } from '@angular/router';
import { Customer } from 'app/shared/customer.model';
import { SessionService, AuthenticationService } from 'app/_services';
import { DOCUMENT } from '@angular/common';
import { LayoutsComponent } from 'app/layouts/main/layouts.component';
import { SurveysUtilService } from 'app/features/surveys/surveys-util.service';
import { SurveyService } from 'app/features/surveys/surveys.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Guest } from 'app/shared/model';

@Component({
  selector: 'mh-survey-theme',
  templateUrl: './survey-theme.component.html',
  styleUrls: ['./survey-theme.component.scss'],
  providers: [SessionService, SurveyService]
})

export class SurveyThemeComponent extends LayoutsComponent implements OnInit{

  customer: Customer;
  waiting = true;
  jwtHelper: JwtHelperService = new JwtHelperService();
  customerId: any;
  surveyId: any;

  constructor(
    private authenticationService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private surveyService: SurveyService,
    protected utilService: UtilService,
    private surveysUtilService: SurveysUtilService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(utilService)
    this.document.body.classList.add('survey-theme');
   }

  ngOnInit() {
    //http://localhost:4200/survey/d5f107bfc6c6dfdb06f6bf7b90c28dbc?guest=MTc3MjQ5NDQ=
    const surveyToken:string = this.activeRoute.snapshot.children[0].params['survey_id'];
    const guestToken: string = this.activeRoute.snapshot.queryParams['guest'];

    this.activeRoute.queryParams.subscribe(params => {
      this.currentLanguage = params['language'] || this.utilService.getCurrentLanguage();
      this.utilService.currentLanguage = this.currentLanguage;
      /* guestToken = params['guest'] || null; */
      this.customerId = params['customerId'];
      this.surveyId   = params['surveyId'];
      this.setLanguage(this.currentLanguage); 
    });

    const user = this.utilService.currentUser;

    if(user && user.isSuperAdmin && guestToken==null){
      this.loginAdmin();
    }else{
      this.authenticationService.logout();
      this.loginGuest(surveyToken, guestToken)
    }
  }

  test(){
    this.surveysUtilService.setTokensReady();
  }

  setUser(isMyHotelUser: boolean, customerId: number, surveyId: number, guest?) {
    this.surveysUtilService.surveyId = surveyId;
    this.surveysUtilService.customerId = customerId;
    this.surveysUtilService.isMyHotelUser = isMyHotelUser;
    (isMyHotelUser) ?
      this.surveysUtilService.guest.id = 1 :
      this.surveysUtilService.guest = guest;
    this.surveysUtilService.setTokensReady();
  }

  loginAdmin() {
    const isMyHotelUser = true;
    this.setUser(isMyHotelUser, this.customerId, this.surveyId);
  }

  loginGuest(surveyToken, guestToken){
    this.surveyService.validateToken(surveyToken, guestToken).subscribe(response=>{
      const token = this.jwtHelper.decodeToken(response["guestToken"]);
      localStorage.setItem('currentJWT', JSON.stringify({token: response["guestToken"]}));
      const guest = Guest.createFromApi(token.guest);
      const isMyHotelUser = false;
      this.setUser(isMyHotelUser, token.customerId, token.surveyId, guest)
    }, error=>{
      const e = error.json();
      this.surveysUtilService.errorSurvey = {
        code: e.code,
        description: e.message
      }
      this.surveysUtilService.setTokensReady();
    })
  }

}
