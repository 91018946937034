import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HtmlHelper } from 'app/_helpers';
import {UtilService}    from "app/shared/util.service";

@Component({
  selector: 'mh-executive-table',
  templateUrl: './executive-table.component.html',
  styleUrls: ['./executive-table.component.scss']
})
export class ExecutiveTableComponent implements OnInit {

  @Input()
  factor: 'brands' | 'regions' = 'brands';

  @Input()
  currentYear;

  @Input()
  previousYear;

  @Input()
  selected: string;

  headerTable:string[] = [];

  @Input()
  dataSource = new MatTableDataSource([]);

  @Input()
  matSortActive = 'average_period';

  @Input()
  areaSelected;

  @Input()
  totals = {};

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  keys = [
    'response_rate',
    'semantic_positive',
    'semantic_negative',
    'email_rate',
    'promoter_percentage',
    'detractors_percentage',
    'semantic_index'
  ]

  constructor(
    private utilService: UtilService,
    private htmlHelper: HtmlHelper) { }

  ngOnInit() {
    this.headerTable = Object.keys(this.dataSource.data[0]);
    this.dataSource.sortingDataAccessor = function (data, sortHeaderId) {
      const value: any = data[sortHeaderId];
      if (typeof value === 'string' && !value.trim()) {
        return value;
      }
      return isNaN(+value) ? value : +value;
    };
    this.dataSource.sort = this.sort;

  }

  get formatNumber(){
    if(this.keys.includes(this.selected)) return 'percent';
    if(['area'].includes(this.selected)) return 'average';
    return this.selected;
  }

  get tableAreaStyle(){
    return this.htmlHelper.getTableAreaStyle(this.utilService.screenHeight);
  }

  get tableContentStyle(){
    return this.htmlHelper.getTableContentStyle(this.headerTable,150);
  }

  get translateData(){
    return {
      currentYear: this.currentYear,
      previousYear: this.previousYear
    }
  }

  get showTotals(){
    return Object.keys(this.totals).length>0;
  }

}
