import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, ReplaySubject } from 'rxjs';
import { IHTTPResponse } from 'app/core-lib/interfaces/http-response';
import { map } from 'rxjs/operators';
import { IOpinion, IOrigin } from 'src/app/new-fs/voices/model/origin.model';
import { HttpClientMH } from 'app/shared/utils/http-myhotel-client';
import { FileData } from 'app/shared/model/file-data.model';
import { TranslateGenericRes } from 'app/shared/model/customer.model';

@Injectable({
  providedIn: 'root'
})
export class VoicesService {
  showWizard = false;
  origins$ = new ReplaySubject<IOrigin[]>(); 
  originsSelected$ = new ReplaySubject<IOrigin[]>(); 
  loadOrigins$ = new ReplaySubject<boolean>(); 

  constructor(
    private http: HttpClient,
    private httpDownload: HttpClientMH,
  ) { }

  getConfig(userId: number) {
    const url = `${environment.voicesPath}/customers/${userId}/configs`;
    return this.http.get(url);
  }

  getAllOrigins(userId: number): Observable<IOrigin[]> {
    const url = `${environment.voicesPath}/customers/${userId}/origins`;
    return this.http.get<IHTTPResponse>(url).pipe(
      map((result) => result.data),
    );
  }

  addNewOrigin(userId: number, body: {origin_name: string, language_code: string, flag_active: string }): Observable<IOrigin[]> {
    const url = `${environment.voicesPath}/customers/${userId}/origins`;
    return this.http.post<IHTTPResponse>(url, body).pipe(
      map((result) => result.data),
    );
  }

  getOriginData(token: string): Observable<IOrigin> {
    const url = `${environment.voicesPath}/origins/tokens/${token}`;
    return this.http.get<IHTTPResponse>(url).pipe(
      map((result) => result.data),
    );
  }

  uploadFile(token: string, file: FormData): Observable<FileData> {
    const url = `${environment.voicesPath}/origins/tokens/${token}/upload`;
    return this.httpDownload.uploadFile(url, file);
  }

  getOpinions(originId: number): Observable<IOpinion[]> {
    const url = `${environment.voicesPath}/origins/${originId}/opinions`;
    return this.http.get<IHTTPResponse>(url).pipe(
      map((result) => result = result.data),
    );
  }

  getOpinionsByCustomerId(customerId: number, startDate: string, endDate: string, page=0, pageSize=10, originIds=null) {
    const url = `${environment.voicesPath}/customers/${customerId}/opinions`;
    const params = new HttpParams()
      .set('start_date', startDate)
      .set('end_date', endDate)
      .set('page', page)
      .set('page_size', pageSize)
      .set('origin_ids', originIds);
    return this.http.get<IHTTPResponse>(url, { params }).pipe(
      map((result) => result = result.data),
    );
  }

  getSummary(customerId: number, startDate: string, endDate: string, originIds=null) {
    const url = `${environment.voicesPath}/customers/${customerId}/summary`;
    const params = new HttpParams()
      .set('start_date', startDate)
      .set('end_date', endDate)
      .set('origin_ids', originIds);
    return this.http.get<IHTTPResponse>(url, { params }).pipe(
      map((result) => result = result.data),
    );
  }

  setOrigins(origins: IOrigin[]) {
    this.origins$.next(origins);
  }

  setOriginsSelected(origins: IOrigin[]) {
    this.originsSelected$.next(origins);
  }

  getTranslateSourceToTarget(data: { text: string }, sourceId: string, targetIds: string) {
    let params = new HttpParams();
    params = params.append('source_language_id', sourceId);
    params = params.append('target_language_ids', targetIds);
    return this.http.post<TranslateGenericRes[]>(`${environment.customerPath}translate/generic`, data, { params })
      .toPromise();
  }
}
