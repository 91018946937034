import { Component } from '@angular/core';
import { UtilService } from './shared/util.service';

import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { VersionCheckService } from './_services/index';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [],
})
export class AppComponent {
  currentPath;
  constructor(
    private versionCheckService: VersionCheckService,
    private utilService: UtilService,
    private router: Router,
    private location: Location,
    private meta: Meta,
    private titleService: Title,
    private translate: TranslateService,
    private gtmService: GoogleTagManagerService,
  ) {
    this.router.events.subscribe(() => {
      this.currentPath = this.location.path();
      this.setTitle();
    });
    this.utilService.isLanguageChanged().subscribe(() => {
      this.setTitle();
    });
    this.listenRouteChangesForGTM();
  }

  ngOnInit() {
    if (!this.utilService.isLocal()) {
      this.versionCheckService.initVersionCheck('version.json');
    }
  }

  listenRouteChangesForGTM() {
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url,
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
  }

  setTitle() {
    if (this.utilService.currentLanguage && this.currentPath.includes('/login')) {
      this.translate
        .get(['themes.public.title_page', 'themes.meta_tags.description.content'])
        .subscribe((res: string) => {
          this.meta.addTag({ name: 'description', content: res['themes.meta_tags.description.content'] });
          this.titleService.setTitle(res['themes.public.title_page']);
        });
    } else if (this.utilService.currentLanguage && this.currentPath.includes('/collect/portal')) {
      this.translate.get(['themes.public.title_collect_portal']).subscribe((res: string) => {
        this.titleService.setTitle(res['themes.public.title_collect_portal']);
      });
    } else {
      this.titleService.setTitle('myHotel Fidelity Suite');
    }
  }
}
