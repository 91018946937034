<button class="btn btn-blue" type="button" (click)="openModal(sendEmail)">
  {{ 'prestay.configurations.general.forms.email.send_email_test' | translate }}
</button>

<ng-template #sendEmail let-dismiss="dismiss">
  <mh-modal [close]="dismiss">
    <div class="modal-header">
      <h4 class="title">{{ 'prestay.configurations.general.forms.email.send_email_test' | translate }}</h4>
    </div>
    <ng-container *ngIf="!sendingEmail; else loader">
      <div class="modal-body tw-pb-6">
        <form
          class="row general_set_form show_invalids"
          (ngSubmit)="sendTestEmail()"
          [formGroup]="testEmailForm"
          #formDir="ngForm"
        >
          <div class="col-6 wrapp_form">
            <label for="email">{{ 'forms.email' | translate }}</label>
            <input type="text" id="email" class="form-control" formControlName="email" />
            <mh-form-alerts [element]="testEmailForm.controls['email']"></mh-form-alerts>
          </div>
          <div class="col-6">
            <widget-list-options
              [label]="'language'"
              [list]="languages"
              [selected]="testEmailForm.controls['language'].value.id"
              [keyValue]="'id'"
              [keyName]="'code'"
              [prefixTranslate]="'languages.iso'"
              (select)="setSelectlValueTest($event, 'language')"
            >
            </widget-list-options>
          </div>
          <div class="col-12 dialog_options">
            <div class="fade_waiting" [hidden]="!sendingEmail">
              <mh-spinner-load></mh-spinner-load>
            </div>
            <div class="col-12 align-center" [hidden]="sendingEmail">
              <button type="submit" [disabled]="testEmailForm.invalid || sendingEmail" class="btn btn-success !tw-bg-green-600">
                {{ 'commons.send' | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </ng-container>
  </mh-modal>
</ng-template>

<ng-template #loader>
  <ng-container *ngIf="!isSentEmail; else success">
    <div class="d-flex justify-content-center mt-1">
      <mh-spinner-load></mh-spinner-load>
    </div>
  </ng-container>
</ng-template>

<ng-template #success>
  <span class="d-flex justify-content-center mt-2 mb-2 success">{{ 'commons.sent_date' | translate }}</span>
</ng-template>
