<mh-alerts-wrapper>
  <section class="flex flex-row justify-between">
    <div>
      <mh-core-lib-datepicker
        [currentDates]="currentDates"
        [currentFilter]="currentFilterDate"
        (datepickerUpdated)="handleDatepickerUpdated($event)">
      </mh-core-lib-datepicker>
    </div>
  </section>

  <mh-box-element [title]="('inter-chain.executive.title' | translate)">
    <mh-survey-wrapper
      [productId]="productId"
      [chain]="chain"
      [forceHide]="true"
      [listenCustomerSubscribe]="false">
      <mh-chain-executive
        [currentDates]="currentDates"
        [productId]="productId"
        [metrics]="filters"
        [chain]="chain"
        *ngIf="fidelityPermisions.includes('interchain_followup_executive_result')"></mh-chain-executive>
    </mh-survey-wrapper>
  </mh-box-element>

</mh-alerts-wrapper>
