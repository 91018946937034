import Section from './Section.model';
import SurveyType from './SurveyType.model';
import SurveyTranslate from './SurveyTranslate.model';
import Question from './Question.model'
import Answer from './Answer.model'
import Alternative from './Alternative.model'

export default class Survey {
  id: number;
  customer_id: number;
  version: number;
  deleted: boolean;
  flag_encuesta_principal: boolean;
  one_page: boolean;
  tripadvisor: boolean;
  product_id: number;
  token: string;
  translates: SurveyTranslate[];
  type: SurveyType;
  sections: Section[];
  conditional_questions: Question[];
  pages: {number: number, valid: boolean}[];

  valid: boolean = true;

  language_id: number;
  nps_question_id: number;
  nps_alternative_id: number;
  languages: {id: number, language: {id: number, code: string}}[];
  final_msg_show_logo: boolean;
  final_msg_show_by_myhotel: boolean;

  static createFromApi(obj: any): Survey {
    return Object.assign((new Survey()), {
      ...obj,
      sections: obj.sections.map(s => Section.createFromApi(s)),
      conditional_questions: (obj.conditional_questions || [])
        .map(q => Question.createFromApi(q)),
      pages: [...(new Set(obj.sections.map(s => s.page)))]
        .map(number => ({number, valid: false})),
    })
  }

  updateAnswers(answers: Answer[]) {
    this.sections.forEach(s => s.updateState(answers));
    const activeConditionals = this.getActiveConditionals(answers);

    activeConditionals.forEach(conditional => conditional?.updateState(answers));

    const validSections = this.sections.every(section => section.valid);
    const validConditionals = activeConditionals.every(conditional => conditional?.valid)
    this.pages.forEach(page => {
      page.valid = this.sections.filter(s => s.page === page.number).every(s => s.valid)
    })

    this.valid = validSections && validConditionals;
  }

  getActiveConditionals(answers: Answer[]) {
    const allQuestions = this.sections
      .reduce((ac, s) => [...ac, ...s.questions], [])

    // non grid alternatives
    const allAnsweredAlternatives = allQuestions
      .reduce((ac, q) => [...ac, ...q.alternatives], [])
      .filter((a: Alternative) => a.alternative_type.name !== 'grid' && a.isAnswered(answers))

    // grid sub-alternatives
    const allAnsweredSubAlternatives = allQuestions
      .reduce((ac, q) => [...ac, ...q.alternatives], [])
      .filter((a: Alternative) => a.alternative_type.name === 'grid')
      .reduce((ac: Alternative[], a: Alternative) => [...ac, ...a.sub_alternatives], [])
      .filter((s: Alternative) => s.isAnswered(answers));

    return [
      ...allAnsweredAlternatives.reduce((ac, a: Alternative) => [...ac, ...a.conditional_questions], []),
      ...allAnsweredSubAlternatives.reduce((ac, s: Alternative) => [...ac, ...s.conditional_questions], []),
    ].map(a => this.conditional_questions.find(q => q.id === a.conditional_question_id))
      .filter(Boolean)
  }
}
