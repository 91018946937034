import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { groupBy } from 'lodash';

@Component({
  selector: 'mh-precheckin-guest-detail',
  templateUrl: './precheckin-guest-detail.component.html',
  styleUrls: ['./precheckin-guest-detail.component.scss']
})
export class PrecheckinGuestDetailComponent implements OnInit {
  @Input()
  questions = [];

  @Input()
  answers;

  @Input()
  waitingDownload = false;

  @Output()
  file: EventEmitter<any> = new EventEmitter<any>();

  dataTable: any[] = []
  groupDataTable: any[] = []

  constructor() { }

  ngOnInit() {
    this.questions.forEach((question: any)=>{
      let answer          = this.answers['details'].filter((x: any)=>x.input_id === question.id);
      if(answer.length > 1) {
        answer.forEach(item => {
          this.setDataTable(item, question)
        })
      } else {
        this.setDataTable(answer[0], question)
      }

    });

    [1,2,3].forEach(elem => {
      let stepValues: any = this.dataTable.filter((d: any) => d.step === elem);

      if(elem === 3) {
        const groupStepValues = groupBy(stepValues, 'answer.companion_id')
        stepValues = []
        for(const input in groupStepValues) {
          if(groupStepValues[input]){
            const companions = groupStepValues[input].filter(c => c.answer)
            if(companions.length > 0)
              stepValues.push(companions)
          }
        }
      }
      this.groupDataTable.push({
        step: elem,
        values: stepValues
      })
    });
  }

  private setDataTable(answer, question) {
    question['answer']  = answer
    this.dataTable.push({
      number_question : question.sort,
      step: question.step,
      question,
      answer: answer,
    })
  }

  downloadFile(event) {
    this.file.emit(event);
  }

}
