import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'widget-guest-stay-nights',
  templateUrl: './guest-stay-nights.component.html',
  styleUrls: ['./guest-stay-nights.component.scss']
})
export class GuestStayNightsComponent implements OnInit {

  @Input()
  days: number;

  @Input()
  room: any;

  @Input()
  reservationId: any;

  @Input()
  showLabel = true;

  @Input()
  labelInNigths = true;

  @Input()
  isAirline = false;

  @Input()
  layoutView: 'list-view' | 'card-view'

  @Input()
  value: any

  @Input()
  text: string

  @Input()
  icon: string

  constructor() { }

  ngOnInit() {
  }

  get placeTranslate() {
    if(this.isAirline) return 'fly_number';
    return 'room';
  }

  get reservationTranslate() {
    if(this.isAirline) return 'fly_code';
    return 'id';
  }

  get isCardView() { return this.layoutView === 'card-view'}
  get classStay() {
    if(this.layoutView === 'list-view'){
      return 'text-center'
    }
    if(this.layoutView === 'card-view'){
      return 'adjust-card-view'
    }
  }
  get classText() {
    if(this.layoutView === 'card-view'){
      return 'col-5 adjust-text'
    }
  }

}
