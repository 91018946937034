<div *ngIf="waiting">
  <widget-wrapper-wait [width]="4" [drawTop]="[0,1,1,0]" [drawMiddle]="[0,1,1,0]" [drawBottom]="[0,1,1,0]" [height]="15">
    <ng-container *ngTemplateOutlet="emailPMS"></ng-container>
  </widget-wrapper-wait>
</div>

<div *ngIf="!waiting">
  <ng-container *ngTemplateOutlet="emailPMS"></ng-container>
</div>

<ng-template #emailPMS>
  <div class="email_pms_box" [class.waiting]="waiting">
    <div class="report">
    </div>
    <div class="content_graph" [class.normal-cursor]="!showModal" (click)="waiting || !showModal ? null : openDialog(modalDetail)">
      <h1 class="state_result">{{ 'qualifys.'+qualify | translate}}</h1>
      <div class="graph_draw" >
        <div class="graph_info" [hidden]="waiting">
          <div class="step">{{formatPercent}}</div>
          <div class="text">{{legend}}
            <i *ngIf="tooltip" container="body" class="description-tooltip fas fa-question-circle" [ngbTooltip]="tooltipHtml"></i>
          </div>
        </div>
        <div class="progress_box {{qualify}}">
          <mat-progress-spinner class="result_graph"
            [strokeWidth]="15"
            [diameter]="150"
            [mode]="mode"
            [value]="value">
          </mat-progress-spinner>
  
          <mat-progress-spinner class="total_graph"
            [strokeWidth]="15"
            [diameter]="150"
            [mode]="mode"
            [value]="50">
          </mat-progress-spinner>
  
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tooltipHtml>
  <div [innerHtml]="tooltip"></div>
</ng-template>


<ng-template #modalDetail>
  <div class="modal-header">
    <h4 class="title" [innerHTML]="modalTitle"></h4>
    <div class="close" (click)="closeDialog()">
      <i class="far fa-times"></i>
    </div>
  </div>

  <div class="modal-body">
    <ng-content></ng-content>
  </div>
</ng-template>
