import { Component, Input, EventEmitter,Output, ViewChild } from '@angular/core';
import 'chart.piecelabel.js';
import { BaseChartDirective } from 'ng2-charts'
import { ChartDataSets } from 'chart.js';

@Component({
  selector: 'mh-chart-pie',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['../../../../assets/scss/shared/charts/pie-chart.scss'],
})
export class PieChartComponent{

  @Input()
  waiting:boolean;

  @Input()
  pieLabels;

  @Input()
  datasets: ChartDataSets[];

  @Input()
  colors: Array<any> = [{}];

  @Input()
  positionLegend:string='top';

  @Input()
  pointer:boolean = true;

  @Output()
  pieChartClick = new EventEmitter<any>();

  @ViewChild(BaseChartDirective, { static: false }) chartComponent: BaseChartDirective;

  public pieOptions:any = {
    responsive: true,
    animation: false,
    legend: {
      position: this.positionLegend,
      fullWidth: true,
      labels: {
        boxWidth: 10,
        fontStyle : "bold",
        usePointStyle : true
      }
    },
    legendCallback: (chart) => chart.legend.legendItems,
  };

  constructor() {
    this.pieOptions['pieceLabel']= {
      render: function (args) {
        let key        = Object.keys(args["dataset"]._meta)[0]
        let controller = args["dataset"]._meta[key]
        let showing    = controller ? controller.data.filter(x=>!x.hidden) : [];
        let data       = controller ? showing.map(x=>args["dataset"].data[x._index]) : args["dataset"].data;
        let total      = data.reduce((a, b) => a + b, 0);
        let percent    = args.percentage ? args.percentage : args.value*100/total;
        
        // Redondear según si el decimal es mayor o igual a 0.5 o menor
        let roundedPercent = (Math.round(percent * 10) / 10) % 1 >= 0.5 ? Math.ceil(percent) : Math.floor(percent);

        return roundedPercent+'%';
      },
      fontColor: 'white'
    }

    this.pieOptions['tooltips']= {
      enabled: true,
      displayColors: false,
      callbacks: {
        label: function (tooltipItems, data) {
          let index   = tooltipItems.index
          let dataset = data.datasets[0]
          let total   = dataset.data.reduce((a, b) => a + b, 0);
          let percent = data.inPercent ? dataset.data[index] : Math.round(dataset.data[index]/total*1000)/10;
          return percent+'%';
        },
        title: function (tooltipItems, data) {
          let index   = tooltipItems[0].index
          let label   = data.labels[index]
          let dataset = data.datasets[0]
          let numbers = dataset.numbers || data.datasets[0].data || [];
          let count   = !data.datasets[0].inPercent ? numbers[index] : undefined;
          return count ? (label+': '+count) : label;
        },
      }
    }    
  }

  ngOnInit(){
    this.pieOptions['legend']['onHover'] = function(e) {
      e.target.style.cursor = 'pointer';
    }

    this.pieOptions['hover'] = {
      onHover: function(e) {
        const point = this.getElementAtEvent(e);
        if (point.length && this.pointer) e.target.style.cursor = 'pointer';
        else e.target.style.cursor = 'default';
      }
    }

  }

  ngOnChanges() {
    this.pieOptions['legend']['position'] = this.positionLegend;
  }

  public chartClicked(e:any):void {
    this.pieChartClick.emit(e);
  }

  public chartHovered(e:any):void {
  }

}
