<mh-box-element [title]="('commons.areas' | translate)">
  <mh-spinner-classic [hidden]="!waiting"></mh-spinner-classic>
  <ng-container *ngIf="!waiting">
    <widgets-table-periods
      [dataSource]="dataSource"
      [headerTable]="headerTable"
      [chain]="chain"
      [matSortActive]="'rating'"
      [totals]="totals"
      [tooltipHeaders]="tooltipHeaders"
    ></widgets-table-periods>
  </ng-container>
</mh-box-element>
