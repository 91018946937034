import { Component } from '@angular/core';
import {UtilService} from "../../../../shared/util.service";
import {User} from "../../../../shared/model/user.model";
import { Hotel } from 'app/shared/model/hotel';
import { DateService } from 'app/shared/services/date.service';

@Component({
  templateUrl: './summary-collect-section.component.html',
})
export class SummaryCollectSectionComponent {

  ready: boolean;
  users:User[];
  waiting:boolean = true;
  selected:User;
  productId = 5;
  isReady:boolean=false;
  headerTable: any[] = [];
  util;

  fidelityPermisions:string[] = [];
  chainFilter: Hotel[] = [];

  constructor(
    private utilService:UtilService,
    private dateService: DateService
  ) {
    this.util = this.utilService
    this.utilService.isCustomerChanged().subscribe(hotel => { this.initSummary(); });
    this.utilService.currentProductId = this.productId;
  }

  ngOnInit(){
    this.fidelityPermisions = this.utilService.getFidelityConfigurationsByProduct('interchain', 'interchain_collect');
    this.initSummary();
  }

  getHeaderTable(){
    const header = [
      'customer',
      'pms',
      'email_sent',
    ]
    return this.isRetail ? header.filter(x=>x!=='tripAdvisorRanking') : header;
  }

  initSummary(){
    this.headerTable = this.getHeaderTable();
    this.waiting = false;
  }

  setChain(chain){
    this.chainFilter = chain;
  }

  dataIsReady(state){
    setTimeout(_ => {
      this.isReady = state;
    },200);
  }

  handleDatepickerUpdated($event) {
    this.dateService.setCurrentFilter($event.currentFilter);
    this.dateService.setCurrentDates($event.currentDates)
  }

  get currentDates() {
    return this.dateService.currentDates;
  }

  get startDate() {
    return this.currentDates.startDate;
  }

  get endDate() {
    return this.currentDates.endDate;
  }

  get currentFilterDate() {
    return this.dateService.currentFilter;
  }

  get isRetail(){
    return this.utilService.customerIsRetail;
  }

}
