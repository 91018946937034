import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'onlineReviewsReasonFilter'
})
export class OnlineReviewsReasonFilterPipe implements PipeTransform {

  transform(reviews, filters) {
    let filteredReviews = [ ...reviews ];
    if(filters) {
      const filter = filters.find(filter => filter.name === 'reason');
      const filtering = filter.options.some(option => option.isFiltering);
      if(filter && filtering) {
        const options = filter.options.filter(opt => opt.isFiltering).map(option => option.id);
        filteredReviews = reviews.filter(item => {
          return options.includes(item.traveler_type?.id)
        })
      }
    }
    return filteredReviews;
  }

}
