import { Component, OnInit, Input } from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'mh-survey-question-single-pick-list',
  templateUrl: './survey-question-single-pick-list.component.html',
  styleUrls: ['./survey-question-single-pick-list.component.scss']
})
export class SurveyQuestionSinglePickListComponent implements OnInit {
  @Input() questionId:number;
  @Input() alternatives:any[]
  @Input() answers:any[]
  @Input() language:string
  @Input() conditionalId: number;
  @Input() preview = false;

  control = new FormControl({value: '', disabled: true});

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.setValue();
  }

  setValue() {
    const answer = this.answers.find(answer => answer.question_id === this.questionId);
    const alternativeAnswer = this.alternatives.find(alternative => alternative.id === answer.answer_alternative_id);
    const translateObject = alternativeAnswer.translates.find(t => t.language['code'] === this.language) || {};
    this.control.setValue(translateObject['text'])
  }

}

