import { Action, Selector, State, StateContext } from '@ngxs/store';
import { StoreCurrentDates, StoreCurrentFilter, StoreTrendCurrentDates, StoreTrendCurrentFilter } from './date.actions';
import { DateTime } from 'luxon';

const FORMAT = 'yyyy-MM-dd';

export interface DateModel {
  trendCurrentDates: { startDate: string | null; endDate: string | null };
  currentDates: { startDate: string | null; endDate: string | null };
  currentFilter: { label: any };
  trendCurrentFilter: { label: any };
  currentYearPeriod: { startDate: string | null; endDate: string | null };
}

@State<DateModel>({
  name: 'date',
  defaults: {
    trendCurrentDates: {
      startDate: DateTime.now().minus({ years: 1 }).toFormat(FORMAT),
      endDate: DateTime.now().toFormat(FORMAT),
    },
    currentDates: {
      startDate: DateTime.now().minus({ days: 30 }).toFormat(FORMAT),
      endDate: DateTime.now().toFormat(FORMAT),
    },
    currentFilter: { label: null },
    trendCurrentFilter: { label: null },
    currentYearPeriod: {
      startDate: `${DateTime.now().year}-01-01`,
      endDate: DateTime.now().toFormat(FORMAT),
    },
  },
})
export class DateState {
  @Selector()
  static currentDates(state: DateModel) {
    return state.currentDates;
  }

  @Selector()
  static currentFilter(state: DateModel) {
    return state.currentFilter;
  }

  @Selector()
  static trendCurrentDates(state: DateModel) {
    return state.trendCurrentDates;
  }

  @Selector()
  static trendCurrentFilter(state: DateModel) {
    return state.trendCurrentFilter;
  }

  @Selector()
  static currentYearPeriod(state: DateModel) {
    return state.currentYearPeriod;
  }

  constructor() {}

  @Action(StoreCurrentDates)
  storeCurrentDates(context: StateContext<DateModel>, action: StoreCurrentDates) {
    context.patchState({
      currentDates: action.currentDates,
    });
  }

  @Action(StoreTrendCurrentDates)
  storeTrendCurrentDates(context: StateContext<DateModel>, action: StoreTrendCurrentDates) {
    context.patchState({
      trendCurrentDates: action.trendCurrentDates,
    });
  }

  @Action(StoreCurrentFilter)
  storeCurrentFilter(context: StateContext<DateModel>, action: StoreCurrentFilter) {
    context.patchState({
      currentFilter: action.currentFilter,
    });
  }

  @Action(StoreTrendCurrentFilter)
  storeTrendCurrentFilter(context: StateContext<DateModel>, action: StoreTrendCurrentFilter) {
    context.patchState({
      trendCurrentFilter: action.trendCurrentFilter,
    });
  }
}
