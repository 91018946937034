import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from "../../../../environments/environment";
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PrestayLoginService {

  constructor(private http:HttpClient) { }

  validateToken(prestay_login){
    const url=`${environment.securityPath}guest/validation`
    return this.http.post(url,prestay_login)
      .pipe(
        map(response=>response)
      );
  }

  sendRegister(guest_id){
    const url=`${environment.prestayPath}guest/${guest_id}/register/entry`;

    return this.http.post(url,{})
      .pipe(
        map(response=>response)
      );
  }
}
