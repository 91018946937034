<mh-core-lib-generic-box customClass="md:tw-max-w-[380px] md:tw-min-w-[380px]">
  <div class="tw-flex tw-flex-col">
    <span class="tw-self-center tw-p-2 tw-text-main-text tw-font-medium tw-text-sm">{{'commons.area_rating' | translate}}</span>
    <div class="tw-flex tw-justify-center">
      <span class="tw-font-semibold tw-text-main tw-text-4xl">
        {{areaDetail.qualify}}
      </span>
      <div class="tw-border tw-border-main-font tw-h-5 tw-mx-3 tw-self-center"></div>
      <span class="tw-font-medium tw-text-main-text tw-text-lg tw-self-center">
        {{MAX_SCORE}}
      </span>
    </div>
  </div>
</mh-core-lib-generic-box>
