<mh-core-lib-generic-box>
  <div class="tw-p-6 tw-h-48">
    <mh-core-lib-loader-wrapper
        type="spinner"
        [loading]="loading"
        spinnerClassName="tw-text-[50px]"
        className="tw-self-center"
        >
        <form [formGroup]="form" *ngIf="!loading">
          <div class="tw-flex tw-gap-10">
            <div class="tw-flex tw-flex-col">
              <label class="tw-text-main-text tw-text-xs">
                {{ 'smart_replies.config.unlimite_responses' | translate }}
              </label>
      <mh-core-lib-toggle
          class="tw-my-2"
          [value]="unlimitedFlagCtrl.value"
          [id]="0"
          (onClick)="unlimiteResponsesChange()"
          ></mh-core-lib-toggle>
            </div>
            <div class="tw-flex tw-flex-col">
              <label class="tw-text-main-text tw-text-xs">
                {{ 'smart_replies.config.number_of_responses' | translate }}
              </label>
              <input
                  class="tw-h-[34px] tw-my-2 tw-rounded-md tw-border tw-border-border-light-gray tw-bg-fff disabled:tw-bg-main-disabled disabled:tw-text-disabled-font focus:tw-ring-transparent"
                  [ngClass]="{'tw-border-detractors': usageCtrl.errors }"
                  type="text"
                  formControlName="usage_limit"
                  (keypress)="numbersOnly($event)"
                  />
              <p
                  class="tw-text-[12px] tw-font-bold tw-text-detractors tw-text-center"
                  [ngClass]="{'tw-invisible': !usageCtrl.errors}"
                  >
                  {{ 'smart_replies.action_button.limit_error_input' | translate }}
              </p>
            </div>
          </div>
          <div class="tw-mt-3">
            <mh-core-lib-button
                [isDefault]="true"
                [isDisabled]="usageCtrl.errors || (!unlimitedFlagCtrl.value && !usageCtrl.value)"
                [text]="'commons.save_changes' | translate"
                (clicked)="saveConfig()"
                ></mh-core-lib-button>
          </div>
        </form>
    </mh-core-lib-loader-wrapper>
  </div>
</mh-core-lib-generic-box>
