import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PSGeneralConfigurationService } from 'app/prestay/configuration/general/prestay-general-configuration.service';
import { animation } from '../utils/animation-custom';
import { LINKS_UPSELL_PS } from '../utils/statics/upsell-links.static';
import { UtilService } from '../util.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mh-cover-mask',
  templateUrl: './cover-mask.component.html',
  styleUrls: ['./cover-mask.component.scss'],
  animations: [animation.fade],
})
export class CoverMaskComponent implements OnInit, OnDestroy {
  @Input() productName: string
  @Input() type: string
  @Input() isActive: boolean
  @Input() innerHtmlText = false;
  @Input() translationParams = {};
  @Input() isAdmin = false;
  @Input() withoutLink = false;
  @Input() globalLink: string;
  @Input() upsellLink;
  hasLink = false;
  show: boolean;
  subscription = new Subscription();

  constructor(
    private generalConfigurationService: PSGeneralConfigurationService,
    private router: Router,
    private utilService: UtilService,
  ) {}

  ngOnInit() {
    const langCode = this.utilService.getCurrentLanguage();
    this.hasLink = !!this.upsellLink;
    this.upsellLink = this.upsellLink
      ? this.upsellLink
      : LINKS_UPSELL_PS.find((elem) => elem.productName === this.productName)[langCode];
    this.loadPSConfig();

    if (!this.hasLink) {
      this.subscription = this.utilService.isLanguageChanged().subscribe((code) => {
        this.upsellLink = LINKS_UPSELL_PS.find((elem) => elem.productName === this.productName)[code];
      });
    }
  }

  private loadPSConfig() {
    this.generalConfigurationService.psConfig.subscribe(value => {
      if(value) {
        if(this.productName === 'checkin') {
          this.isActive = value.checkin_active
        } else if(this.productName === 'service') {
          this.isActive = value.service_active
        }
      }
    })
  }

  get translationTextTooltip() {
    if (['desk', 'collect'].includes(this.productName)) {
      return `commons.inactive_module.${this.productName}.${this.type}.tooltip`;
    }
    return `commons.inactive_module.prestay.${this.type}.${this.productName}.tooltip`;
  }

  get translationTextLink() {
    if (['desk', 'collect'].includes(this.productName)) {
      return `commons.inactive_module.${this.productName}.${this.type}.link${this.isAdmin ? '_admin' : ''}`;
    }

    return `commons.inactive_module.prestay.${this.type}.${this.productName}.link`;
  }

  get sectionStyle() {
    if (!!this.globalLink) {
      return 'cursor-pointer align-items-center font-small';
    }

    switch(this.type){
      case 'menu':
        return 'menu-style'
      case 'summary':
      case 'properties':
        return 'align-items-center font-small'
    }
  }

  get sectionContainerStyle() {
    switch(this.type){
      case 'menu':
        return 'container-style'
    }
  }

  navigateToGlobalLink() {
    this.router.navigate([this.globalLink]);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
