import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DefaultLanguageState } from 'app/states/default-language/default-language.state';
import { Observable, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';

@Component({
  selector: 'mh-case-dismiss-modal',
  templateUrl: './case-discard-modal.component.html',
  styleUrls: ['./case-discard-modal.component.scss'],
})
export class CaseDiscardModalComponent implements OnInit, OnDestroy {
  @Select(DefaultLanguageState.languageId) languageId$: Observable<number>;
  @Input() reasons;
  @Output() handleClose = new EventEmitter();
  @Output() handleDiscardCase = new EventEmitter();

  reasonForm;
  langId;
  subscription = new Subscription();

  constructor(private fb: FormBuilder, private store: Store) {}

  ngOnInit(): void {
    this.reasonForm = this.fb.group({
      reason: '',
      reasonComment: '',
    });

    this.subscription.add(
      this.languageId$.subscribe((langId) => {
        this.langId = langId;
        this.reasons = this.reasons.map((reason) => {
          return {
            ...reason,
            reason: this.displayFn(reason),
          };
        });
      }),
    );

    this.langId = this.store.selectSnapshot(DefaultLanguageState.languageId);
  }

  displayFn(option) {
    return option.translates.find((translate) => translate.language_id === this.langId)?.name ?? null;
  }

  discardCase() {
    this.handleDiscardCase.next(this.reasonForm.value);
    this.handleClose.next(true);
  }

  cancelDiscard() {
    this.handleClose.next(true);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get currentOption(): number {
    return this.reasonForm.get('reason').value;
  }
}
