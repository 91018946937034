export const OTAS_DATA = [
  {
    "id": 1,
    "name": "TripAdvisor",
    "url": "http://www.tripadvisor.com",
    "search_q": "search?q="
  },
  {
    "id": 2,
    "name": "Booking.com",
    "url": "http://www.booking.com",
    "search_q": "searchresults.en-gb.html?ss="
  },
  {
    "id": 4,
    "name": "Despegar.com",
    "url": "http://www.despegar.com/hoteles"
  },
  {
    "id": 6,
    "name": "Holidaycheck",
    "url": "https://www.holidaycheck.de",
    "search_q": "search-result/?q="
  },
  {
    "id": 7,
    "name": "Agoda",
    "url": "https://www.agoda.com"
  },
  {
    "id": 8,
    "name": "Atrapalo",
    "url": "https://www.atrapalo.com"
  },
  {
    "id": 9,
    "name": "Travelrepublic",
    "url": "https://www.travelrepublic.co.uk"
  },
  {
    "id": 30,
    "name": "Google",
    "url": "https://google.com",
    "search_q": "search?q="
  },
  {
    "id": 31,
    "name": "Facebook",
    "url": "https://www.facebook.com",
    "search_q": "search/top?q="
  },
  {
    "id": 33,
    "name": "HostelWorld",
    "url": "https://www.hostelworld.com",
    "search_q": "find/keywordsuggestions?search_keywords="
  },
  {
    "id": 34,
    "name": "Expedia Group",
    "url": "https://www.expedia.com",
    "search_q": "Hotel-Search?destination="
  }
]
