import { Component, OnInit, Input, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateCaseDialogService } from 'app/shared/core-lib-modal-services/cases/create-case-dialog.service';
import { Product } from 'app/shared/model';

@Component({
  selector: 'mh-prestay-order-guest',
  templateUrl: './order-guest.component.html',
  styleUrls: ['./order-guest.component.scss']
})
export class OrderGuestComponent implements OnInit {

  @Input()
  item;

  @Input()
  keys = [];

  @Input()
  money;

  @Input()
  language;

  @Input()
  viewType:string;

  @Input()
  products:Product[] = [];

  @Input()
  orderIdParam: number;

  @Input()
  showCases: boolean = false;

  @Input()
  currentCustomerId;

  @Input()
  currentUser;

  @ViewChild('detailModal', { static: true })
  detailModalTemplate: TemplateRef<any>;

  modal:NgbModalRef;

  isOpen = false;
  triggerOrigin: ElementRef;
  triggerActionsCardOrigin: ElementRef;
  cardActionsOpen = false;

  constructor(private modalService: NgbModal, private createCaseDialogService: CreateCaseDialogService) {}

  ngOnInit() {
    if(this.orderIdParam){
      const order = this.item.orders.find(order=>order.id===this.orderIdParam);
      if(order && !this.isOpen){
        setTimeout(_ => {
          this.openDialog();
        },1000);
      }
    }
  }

  openDialog(){
    const content = this.detailModalTemplate;
    this.modal = this.modalService.open(content, {
      windowClass: 'modal-xl modal-confirm'
    })
  }

  closeDialog(){
    if(this.modal) this.modal.close();
  }

  getProductItem(id: number) {
    return {
      type: { id: 4 },
      product_id: 4,
      item_id: id
    }
  }


  getTagName(type:string) { return `ps_${this.viewType}_${type}` }


  handleCreateCase(item) {
    this.createCaseDialogService.openModal(this.currentUser, 'order', item);
  }

  handleOverlayCasesClick(trigger) {
    this.triggerActionsCardOrigin = trigger;
    this.cardActionsOpen = !this.cardActionsOpen;
  }
}
