import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import {FilterDate} from '../../shared/model/index';

@Injectable()
export class InterChainPrestayService {

  constructor(private http:HttpClient) { }

  getSummarySales(customerIds, dates: FilterDate){
    const url=`${environment.prestayPath}interchains/summary/sales`;
    const params:HttpParams= new HttpParams()
      .set("customers_ids",customerIds)
      .set("start_date",dates.startDate)
      .set("end_date",dates.endDate);

    return this.http.get(url,{params: params})
      .pipe(
        map(response=>response)
      );
  }

  getSummaryCheckin(customerIds, dates: FilterDate){
    const url=`${environment.prestayPath}interchains/summary/checkins`;
    const params:HttpParams= new HttpParams()
      .set("customers_ids",customerIds)
      .set("start_date",dates.startDate)
      .set("end_date",dates.endDate);

    return this.http.get(url,{params: params})
      .pipe(
        map(response=>response)
      );
  }

  getSummaryEmailsRate(customerIds, dates: FilterDate){
    const url=`${environment.prestayPath}interchains/summary/emails/rate`;
    const params:HttpParams= new HttpParams()
      .set("customers_ids",customerIds)
      .set("start_date",dates.startDate)
      .set("end_date",dates.endDate);

    return this.http.get(url,{params: params})
      .pipe(
        map(response=>response)
      );
  }

  getSummaryServices(customerIds, dates: FilterDate){
    const url=`${environment.prestayPath}interchains/summary/services`;
    const params:HttpParams= new HttpParams()
      .set("customers_ids",customerIds)
      .set("start_date",dates.startDate)
      .set("end_date",dates.endDate);

    return this.http.get(url,{params: params})
      .pipe(
        map(response=>response)
      );
  }

  getTrendSales(customerIds, dates: FilterDate, segmented_by:'months' | 'weeks' | 'days'){
    const url=`${environment.prestayPath}interchains/trends/sales`;
    const params:HttpParams= new HttpParams()
      .set("customers_ids",customerIds)
      .set("start_date",dates.startDate)
      .set("end_date",dates.endDate)
      .set("segmented_by",segmented_by);

    return this.http.get(url,{params: params})
      .pipe(
        map(response=>response)
      );
  }

  getTrendOrdersRate(customerIds, dates: FilterDate, segmented_by:'months' | 'weeks' | 'days'){
    const url=`${environment.prestayPath}interchains/trends/orders/rate`;
    const params:HttpParams= new HttpParams()
      .set("customers_ids",customerIds)
      .set("start_date",dates.startDate)
      .set("end_date",dates.endDate)
      .set("segmented_by",segmented_by);

    return this.http.get(url,{params: params})
      .pipe(
        map(response=>response)
      );
  }

  getTrendEmailsRate(customerIds, dates: FilterDate, segmented_by:'months' | 'weeks' | 'days'){
    const url=`${environment.prestayPath}interchains/trends/email/rate`;
    const params:HttpParams= new HttpParams()
      .set("customers_ids",customerIds)
      .set("start_date",dates.startDate)
      .set("end_date",dates.endDate)
      .set("segmented_by",segmented_by);

    return this.http.get(url,{params: params})
      .pipe(
        map(response=>response)
      );
  }

  getTrendCheckin(customerIds, dates: FilterDate, segmented_by:'months' | 'weeks' | 'days'){
    const url=`${environment.prestayPath}interchains/trends/checkins`;
    const params:HttpParams= new HttpParams()
      .set("customers_ids",customerIds)
      .set("start_date",dates.startDate)
      .set("end_date",dates.endDate)
      .set("segmented_by",segmented_by);

    return this.http.get(url,{params: params})
      .pipe(
        map(response=>response)
      );
  }

  getActiveModules(customerIds) {
    const url=`${environment.prestayPath}customers/modules`;
    const params = new HttpParams()
      .set('customers_ids', customerIds)
    return this.http.get(url, {params});
  }
}
