import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SurveyAnswerPublicFacade } from './survey-answer-public.facade';
import { SurveyAnswerPublicService } from './survey-answer-public.service';
import { Survey, SurveyAnswer } from 'app/survey-public/models';

@Component({
  selector: 'mh-survey-answer-public',
  templateUrl: './survey-answer-public.component.html',
  styleUrls: ['./survey-answer-public.component.scss'],
})
export class SurveyAnswerPublicComponent implements OnInit {
  @Input() surveyId: number;
  @Input() answerId: number;
  @Input() surveyLanguageId: number;
  @Input() answersComments;

  @Output() data = new EventEmitter();

  survey: Survey
  surveyAnswers: SurveyAnswer
  language$ = this.surveyAnswerFacade.getLanguage$();
  languageId$ = this.surveyAnswerFacade.getLanguageId$();
  loading$ = this.surveyAnswerFacade.isLoading();

  constructor(
    private surveyAnswerFacade: SurveyAnswerPublicFacade,
     private surveyService: SurveyAnswerPublicService,
  ) { }

  ngOnInit() {
    this.surveyService.surveysAndAnswers.subscribe(values => {
      if(values) {
        const surveyAndAnswer = values.find(s => s.survey.id === this.surveyId && s.surveyAnswers.answerId === this.answerId)
        if(surveyAndAnswer) {
          this.survey = surveyAndAnswer.survey
          this.surveyAnswers = surveyAndAnswer.surveyAnswers
        } else {
          const surveyAnswer = values.find(s => s.survey.id === this.surveyId)
          if(surveyAnswer) {
            this.surveyAnswerFacade.loadAnswers(surveyAnswer.survey, this.answerId);
          } else {
            this.surveyAnswerFacade.loadAll(this.surveyId, this.answerId);
          }
        }
      } else {
        this.surveyAnswerFacade.loadAll(this.surveyId, this.answerId);
      }
    })
    this.surveyAnswerFacade.isReady$().subscribe((ready: boolean) => {
      if (ready) {
        this.emitData();
      }
    });
  }

  emitData() {
    const data = {
      generalInfo: this.surveyAnswerFacade.getGeneralInfo(),
      sections: this.surveyAnswerFacade.getSections(),
      conditionalQuestions: this.surveyAnswerFacade.getConditionalQuestions(),
      answerResponse: this.surveyAnswerFacade.getAnswerResponse(),
    };
    this.data.emit(data);
  }
}
