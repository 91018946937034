import { Component } from '@angular/core';

@Component({
  templateUrl: './summary-onsite-section.component.html',
})
export class SummaryOnsiteSectionComponent {

  constructor() {}

}
