<div class="item-rate" [class.no-details]="!showDetail" [class.waiting]="waiting">
  <div class="row no-gutters">
    <div class="col-3">
      <div class="number" [ngbTooltip]="quantityTooltipText ? tooltipQuantity : null" placement="left">
        {{quantity}}
      </div>
    </div>
    <div class="col-9">
      <div class="text" >
        <div class="title ellipsis">
          <span>{{title}}</span>
          <i *ngIf="tooltip" container="body" class="tooltip-icon fas fa-question-circle" [ngbTooltip]="tooltip ? tooltipMetric : null"></i>
        </div>
        <button class="btn btn-link detail">
          ({{'commons.see_detail' | translate}})
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #tooltipMetric>
  <div [innerHtml]="tooltip"></div>
</ng-template>

<ng-template #tooltipQuantity>
  {{quantityTooltipText}}
</ng-template>
