import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ReportPeriodBase } from 'app/configuration/profile/Profile.model';

@Component({
  selector: 'mh-core-lib-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class CoreLibAccordionComponent {
  @Input() items: ReportPeriodBase[];
  @Input() options: number;
  @Input() isSimple = false;
  @Input() isCustom = false;
  @Input() headerCustomClass = '';
  @Input() bodyCustomClass = '';
  @Output() dataChanged: EventEmitter<ReportPeriodBase[]> = new EventEmitter();

  onClick(e) {
    this.items.forEach((item: ReportPeriodBase) => {
      item.items[e.id].check = e.value;
      if (item.items.some((option) => !option.check)) item.allChecked = false;
      if (item.items.every((option) => option.check)) item.allChecked = true;
    });

    this.dataChanged.emit(this.items);
  }

  onMassiveClick(e) {
    this.items.forEach((item: ReportPeriodBase) => {
      item.allChecked = e.value;
      item.items.forEach((option) => {
        option.check = e.value;
      });
    });

    this.dataChanged.emit(this.items);
  }
}
