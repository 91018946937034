import { Component, Input, OnInit } from '@angular/core';
import { CoreLibTabsComponent } from '../tabs.component';
import { animation } from 'app/shared/utils/animation-custom';

@Component({
  selector: 'mh-core-lib-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  animations: [animation.slideInLeftFade, animation.slideInParamsFade],
})
export class CoreLibTabComponent implements OnInit {
  tabIndex;

  @Input()
  title = '';

  constructor(public parentTabs: CoreLibTabsComponent) {}

  ngOnInit(): void {}

  ngAfterContentInit() {
    this.setTabIndex();
  }

  setTabIndex() {
    this.tabIndex = this.parentTabs?.tabs?.toArray().indexOf(this) || 0;
  }

  get isActive() {
    return this.tabIndex === this.parentTabs.tabIndex;
  }

  get animationValue() {
    const length = this.parentTabs.tabs?.toArray()?.length;
    if (length === this.tabIndex + 1) {
      return '20px';
    }
    if (this.tabIndex === 0) {
      return '-20px';
    }
    return this.parentTabs.tabIndex > this.parentTabs.previousIndex ? '-20px' : '20px';
  }
}
