<section class="tw-bg-background-gray record-title-mobile tw-py-2 md:tw-hidden">
  <div class="tw-w-[50vw] tw-text-center tw-mx-auto tw-text-xl tw-text-main-text tw-font-semibold">
    {{'cases.titles.activity_section' | translate}}
  </div>
</section>


<div class="record-form">
  <label class="tw-text-main-text tw-hidden md:tw-block">{{'cases.titles.activity_section' | translate}}</label>
  <mh-core-lib-textarea-input
      [formGroup]="recordForm"
      [keyControl]="'text'"
      [placeholder]="'cases.messages.write_comment' | translate"
      [withMentions]="true"
      [users]="users"
      (mentionStateChanged)="setMentionState($event)"
      ></mh-core-lib-textarea-input>
  <section class="tw-flex tw-flex-row-reverse tw-text-main-text tw-gap-2 tw-flex-wrap">
    <mh-core-lib-actions-wrapper
        slug="cases_cases_caseattached_comment"
      >
      <i *ngIf="!isUploadingFile" class="fas fa-paperclip tw-cursor-pointer tw-ml-2" [class.tw-self-center]="attachments.length > 0" (click)="handleClickFileInput()"></i>
    </mh-core-lib-actions-wrapper>
    <i *ngIf="isUploadingFile" class="fas fa-spinner-third fa-spin tw-ml-2" [class.tw-self-center]="attachments.length > 0"></i>
    <input type="file" #file_input (change)="handleFileInput($event)" hidden multiple>
    <ng-container *ngIf="attachments.length > 0">
      <ng-container *ngFor="let attachment of attachments; let index = index">
        <div class="tw-px-3 tw-py-1 tw-border tw-bg-white tw-rounded-full tw-flex tw-gap-2">
          <span class="tw-font-medium">{{attachment.file_name}}</span>
          <i class="fas fa-times tw-self-center tw-cursor-pointer" (click)="handleRemoveFile(attachments.file_key)"></i>
        </div>
      </ng-container>
    </ng-container>
  </section>
  <section class="tw-flex tw-mt-3 tw-justify-center md:tw-justify-end">
    <mh-core-lib-button
        [isSecondary]="true"
        [text]="'forms.save' | translate | titlecase"
        [isDisabled]="isUploadingFile || recordForm.get('text')?.value?.length === 0"
        (clicked)="handleSubmit()"
        ></mh-core-lib-button>
  </section>
</div>
