import { Component, Input, OnInit } from '@angular/core';
import { animation } from 'app/shared/utils/animation-custom';

@Component({
  selector: 'mh-core-lib-review-case',
  templateUrl: './review-case.component.html',
  styleUrls: ['./review-case.component.scss'],
  animations: [
    animation.rotate,
    animation.heightExpand,
    animation.heightDefinedExpand,
  ],
})
export class CoreLibReviewCaseComponent implements OnInit {

  openAnswer = false;

  @Input()
  review;

  @Input()
  source: 'detail' | 'create' = 'create';

  @Input()
  customerId = '';

  showFullDescription = false;
  showAnswer = false;

  constructor() { }

  ngOnInit(): void {
  }

  toggleDescription() {
    this.showFullDescription = !this.showFullDescription;
  }

  toggleAnswer() {
    this.showAnswer = !this.showAnswer;
  }

  get isCreate() {
    return this.source === 'create';
  }

  get heightValueAnimation() {
    return this.showFullDescription ? 'expanded' : 'collapsed';
  }

  get myhotelLogo() {
    return 'https://statics.myhotel.io/logos/logo-classic.png';
  }

  get url() {
    return `/online/resenas?review=${this.review.id}&customer=${this.customerId}&date=${this.review.publication_date}`
  }

}
