<mh-core-lib-content>
  <ng-container head>
    <mh-core-lib-header-content [title]="'notifications.central.notifications_history' | translate">
      <section class="tw-flex tw-justify-center tw-items-center tw-flex-wrap md:tw-justify-between">
        <article class="tw-flex tw-space-x-4">
          <article>
            <mh-core-lib-datepicker
              [currentDates]="currentDates"
              [currentFilter]="currentFilterDate"
              (datepickerUpdated)="handleDatepickerUpdated($event)"
            >
            </mh-core-lib-datepicker>
          </article>
        </article>
      </section>
    </mh-core-lib-header-content>
  </ng-container>

  <ng-container main-content>
    <mh-core-lib-main-content class="tw-block tw-w-1/2 tw-bg-white tw-mx-auto tw-relative">
      <section>
        <article *ngIf="alerts?.length > 0 || news?.length > 0" class="-tw-mt-2 tw-pt-2">
            <mh-notifications 
              [alertsData]="alerts"
              [newsData]="news"
              [hasMoreAlerts]="hasMoreAlerts"
              [hasMoreNews]="hasMoreNews"
              [currentUser]="currentUser"
              [type]="notificationComponentType"
              (emitShowMore)="handleShowMore($event)"></mh-notifications>
        </article>
      </section>
    </mh-core-lib-main-content>
  </ng-container>
</mh-core-lib-content>
