import { Component, Inject, OnInit } from '@angular/core';
import { SurveyAnswerService } from 'app/shared/surveys/survey-answer.service';
import { DialogRef } from '../modal/dialog-ref';
import { DIALOG_DATA } from '../modal/dialog-tokens';
import { CoreLibModalComponent } from '../modal/modal.component';

@Component({
  selector: 'mh-core-lib-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  providers:[ SurveyAnswerService ]
})
export class CoreLibConfirmationModalComponent extends CoreLibModalComponent implements OnInit {

  survey: any;

  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private surveyAnswerService: SurveyAnswerService,
  ) {
    super(dialogRef, data);
  }

  ngOnInit(): void {
    this.setSurvey();
  }

  setSurvey() {
    this.survey = this.data.survey;
  }

  handleDelete() {
    this.surveyAnswerService.deleteAnswerSurvey(this.survey.answered_survey.id).subscribe((response:any)=>{
      if ( response && response.delete ) {
        const surveyCopy = {...this.survey}
        surveyCopy.deleted = true;
        this.close(surveyCopy)
      }
    })
  }

  handleCancel() {
    this.close();
  }

}
