import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'widget-guest-survey-info',
  templateUrl: './guest-survey-info.component.html',
  styleUrls: ['./guest-survey-info.component.scss']
})
export class GuestSurveyInfoComponent implements OnInit {

  @Input() survey

  @Input()
  layoutView: 'list-view' | 'card-view'

  @Input()
  value: any

  @Input()
  text: string

  @Input()
  icon: string

  constructor() { }

  ngOnInit() {
  }

  get isCardView() { return this.layoutView === 'card-view'}


}
