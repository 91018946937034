import { Component, OnInit } from '@angular/core';
import { Hotel } from 'app/shared/model';
import { UtilService } from 'app/shared/util.service';
import { DateService } from 'app/shared/services/date.service';

@Component({
  selector: 'mh-prestay-section-summary',
  templateUrl: './prestay-section-summary.component.html',
  styleUrls: ['./prestay-section-summary.component.scss']
})
export class PrestaySectionSummaryComponent implements OnInit {

  productId=4;
  chainFilter: Hotel[] = [];
  isReady:boolean=false;

  fidelityPermisions:string[] = [];

  constructor(
    private utilService: UtilService,
    private dateService: DateService
  ) { }

  ngOnInit() {
    this.fidelityPermisions = this.utilService.getFidelityConfigurationsByProduct('interchain', 'interchain_prestay');
  }

  setChain(chain){
    this.chainFilter = chain;
  }

  dataIsReady(state){
    setTimeout(_ => {
      this.isReady = state;
    },200);
  }

  handleDatepickerUpdated($event) {
    this.dateService.setCurrentFilter($event.currentFilter);
    this.dateService.setCurrentDates($event.currentDates)
  }

  get currentDates() {
    return this.dateService.currentDates;
  }

  get currentFilterDate() {
    return this.dateService.currentFilter;
  }
}
