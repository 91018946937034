<div class="tw-relative">
  <div [class.tw-hidden]="!canEditSurvey" class="tw-absolute tw-right-[-15px] tw-top-[-10px] tw-z-10" [@rotate]="deleteOpen ? '-180' : '0'">
    <mh-core-lib-button
        [isAction]="true"
        [icon]="'far fa-angle-down'"
        [width]="45"
        customClass="!tw-p-2 !tw-w-[30px] !tw-h-fit !tw-bg-background-action-button hover:!tw-bg-main tw-transition-all tw-group"
        customIconClass="!tw-mr-0 !tw-flex !tw-items-center !tw-justify-center !tw-h-[15px] !tw-text-main-text group-hover:!tw-text-white"
        (clicked)="deleteOpen = !deleteOpen"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
        ></mh-core-lib-button>
  </div>
  <article class="tw-rounded-lg tw-shadow-1 lg:tw-my-5 tw-flex tw-flex-col tw-bg-white guest-box tw-relative tw-overflow-hidden" [class.tw-gap-5]="isBox" [class.tw-rounded-none]="isCardLayout" [class.lg:tw-rounded-lg]="isCardLayout">
    <div
        class="tw-bg-background-managed-gray tw-top-[-5px] tw-left-[-30px] tw-w-[80px] tw-h-[40px] tw-absolute tw-rotate-[-50deg]"
        [class.!tw-bg-promoters]="isManaged"
        >
        <div class="tw-absolute tw-bottom-[1px] tw-right-[32px] tw-rotate-[50deg]">
          <i *ngIf="isManaged" mhTooltip="{{('cases.tooltips.managed_case' | translate:{ name: survey?.answered_survey?.managed?.user?.profile?.complete_name || '', date: (survey?.answered_survey?.managed?.action_date | date: 'dd-MM-y')})}}" position="right" class="fas fa-check-circle tw-text-white"></i>
          <mh-core-lib-actions-wrapper
              [slug]="managedSlug"
              >
              <i *ngIf="!isManaged" [mhTooltip]="'answer_survey.not_managed' | translate" position="right" class="fas fa-minus-circle tw-text-white"></i>
          </mh-core-lib-actions-wrapper>
        </div>
    </div>

    <section class="tw-px-8 tw-pt-4 tw-pb-1 xl:tw-pt-1 2xl:tw-pt-3 lg:tw-pb-3 xl:tw-pb-1 tw-flex tw-flex-col tw-gap-5 xl:tw-gap-2 2xl:tw-gap-3" [class.lg:tw-flex-row]="isBox" [class.xl:!tw-pb-3]="isBox" [class.xl:!tw-pt-3]="isBox">

      <section class="tw-flex tw-flex-col tw-text-main-text md:tw-min-w-[200px] md:tw-max-w-[200px] tw-self-center tw-ml-[7px]">
        <span class="md:tw-max-w-[200px] tw-font-semibold tw-text-sm tw-self-center tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis" [class.tw-text-xs]="isTight" [class.lg:tw-self-start]="isBox">{{guest?.name}} {{guest?.lastname}}</span>
        <a class="md:tw-max-w-[200px] tw-text-sm lg:tw-text-xs tw-font-medium tw-self-center tw-text-main tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis" [class.lg:tw-self-start]="isBox" href="mailto:{{guest?.email}}" target="_blank">
          {{guest?.email | lowercase}}
        </a>
        <p class="tw-text-sm lg:tw-text-xs tw-font-medium tw-self-center tw-text-main-text" [class.lg:tw-self-start]="isBox" >
        {{'forms.phone' | translate}}:
        <ng-container *ngIf="guest?.phone">
          <a class="tw-font-semibold tw-text-sm tw-text-main" [href]="'https://api.whatsapp.com/send?phone='+ guest.phone +'&text=' + ('commons.guest_greetings' | translate)" target="_blank">{{guest?.phone}}</a>
        </ng-container>
        <ng-container *ngIf="!guest?.phone">-</ng-container>
        </p>
        <p class="tw-text-xs tw-font-medium tw-hidden" [class.lg:tw-block]="isBox" >
        <ng-container>
          {{ isRestaurant ?
              ('survey.public_surveys.configurations.edit.reservation_document' | translate) :
              ('commons.fly_code' | translate)
          }}
        </ng-container>
        <span class="tw-font-semibold tw-text-sm">{{guest?.reservation_id ?? '-'}}</span>
        </p>
        <section class="flag tw-gap-1 tw-mt-1 tw-self-center lg:tw-self-start tw-flex" [class.lg:tw-self-start]="isBox" *ngIf="areCountry">
          <span [ngStyle]="{'width': 20+'px', 'height': 20+'px'}" class="flag-icon flag-icon-squared flag-icon-{{countryFlag}} tw-rounded-full"></span>
          <span class="tw-text-sm tw-font-medium tw-self-center">{{countryName}}</span>
        </section>
      </section>


      <section class="tw-flex tw-flex-col tw-grow tw-gap-2 xl:tw-gap-0.5 2xl:tw-gap-2 tw-text-main-text" [class.lg:tw-hidden]="isBox">
        <div class="tw-flex tw-w-full" [class.tw-text-small]="isTight" >
          <span class="tw-font-medium tw-basis-2/3">
            <i class="fas fa-book-user tw-self-center"></i>
            <ng-container>
              {{ isRestaurant ?
                  ('survey.public_surveys.configurations.edit.reservation_document' | translate) :
                  ('commons.fly_code' | translate)
              }}
            </ng-container>
          </span>
          <span class="tw-font-bold tw-basis-1/3 tw-self-start">
            {{guest?.reservation_id ?? '-'}}
          </span>
        </div>

        <div class="tw-flex tw-w-full" [class.tw-text-small]="isTight" *ngIf="!isRestaurant">
          <span class="tw-font-medium tw-basis-2/3">
            <i class="far fa-calendar-alt tw-self-center"></i>
            <ng-container *ngIf="!isAirline">
              <span class="tw-self-center"> {{'core-lib.widgets.guest.checkin' | translate}}</span>
            </ng-container>
            <ng-container *ngIf="isAirline">
              <span class="tw-self-center"> {{'commons.departure' | translate}}</span>
            </ng-container>
          </span>
          <span class="tw-font-bold tw-basis-1/3 tw-self-start">{{guest?.checkin | formatDate: currentLanguage }}</span>
        </div>

        <div class="tw-flex tw-w-full" [class.tw-text-small]="isTight" *ngIf="!isAirline && !isRestaurant">
          <span class="tw-font-medium tw-basis-2/3">
            <i class="far fa-calendar-alt tw-self-center"></i>
            {{'core-lib.widgets.guest.checkout' | translate}}
          </span>
          <span class="tw-font-bold tw-basis-1/3 tw-self-start">{{guest?.checkout | formatDate: currentLanguage }}</span>
        </div>

        <div class="tw-flex tw-w-full" [class.tw-text-small]="isTight" *ngIf="!isAirline && !isRestaurant">
          <span class="tw-font-medium tw-basis-2/3">
            <i class="fas fa-suitcase-rolling tw-self-center"></i>
            {{'commons.stay_nights.stay' | translate}}
          </span>
          <ng-container *ngIf="stay <= 1 ">
            <span class="tw-font-bold tw-self-start">{{'commons.stay_nights.single' | translate}}</span>
          </ng-container>
          <ng-container *ngIf="stay > 1">
            <span class="tw-font-bold tw-self-start">{{'commons.stay_nights.plural' | translate: {number: stay} }}</span>
          </ng-container>
        </div>

        <div class="tw-flex tw-w-full" [class.tw-text-small]="isTight" *ngIf="!isRestaurant">
          <span class="tw-font-medium tw-basis-2/3">
            <ng-container *ngIf="!isAirline">
              <i class="fas fa-bed tw-self-center"></i>
              <span class="tw-self-center"> {{'commons.room' | translate}}</span>
            </ng-container>
            <ng-container *ngIf="isAirline">
              <i class="fas fa-barcode tw-self-center"></i>
              <span class="tw-self-center"> {{'commons.fly_number' | translate}}</span>
            </ng-container>
          </span>
          <span class="tw-font-bold tw-basis-1/3 tw-self-start">{{guest.room}}</span>
        </div>
      </section>

      <section class="tw-flex-grow tw-flex tw-justify-between tw-mr-5">
        <section class="tw-hidden tw-flex-col tw-text-main-text tw-justify-center tw-gap-2 lg:tw-text-small 2xl:tw-text-base" [class.lg:tw-flex]="isBox" *ngIf="!isRestaurant">
          <i class="far fa-calendar-alt tw-self-center"></i>
          <ng-container *ngIf="!isAirline">
            <span class="tw-self-center">{{'core-lib.widgets.guest.checkin' | translate}}</span>
          </ng-container>
          <ng-container *ngIf="isAirline">
            <span class="tw-self-center">{{'commons.departure' | translate}}</span>
          </ng-container>
          <span class="tw-font-semibold tw-self-center">{{guest.checkin | formatDate: currentLanguage }}</span>
        </section>

        <section class="tw-hidden tw-flex-col tw-text-main-text tw-justify-center tw-gap-2 lg:tw-text-small 2xl:tw-text-base" [class.lg:tw-flex]="isBox" *ngIf="!isAirline && !isRestaurant">
          <i class="fas fa-calendar-alt tw-self-center"></i>
          <span class="tw-self-center">{{'core-lib.widgets.guest.checkout' | translate}}</span>
          <span class="tw-font-semibold tw-self-center">{{guest.checkout | formatDate: currentLanguage }}</span>
        </section>

        <section class="tw-hidden tw-flex-col tw-text-main-text tw-justify-center tw-gap-2 lg:tw-text-small 2xl:tw-text-base" [class.lg:tw-flex]="isBox" *ngIf="!isAirline && !isRestaurant">
          <i class="fas fa-suitcase-rolling tw-self-center"></i>
          <span class="tw-self-center">
            {{'commons.stay_nights.stay' | translate}}
          </span>
          <ng-container *ngIf="stay <= 1 ">
            <span class="tw-font-bold tw-self-start">{{'commons.stay_nights.single' | translate}}</span>
          </ng-container>
          <ng-container *ngIf="stay > 1">
            <span class="tw-font-bold tw-self-start">{{'commons.stay_nights.plural' | translate: {number: stay} }}</span>
          </ng-container>
        </section>

        <section class="tw-hidden tw-flex-col tw-text-main-text tw-justify-center tw-gap-2 lg:tw-text-small 2xl:tw-text-base" [class.lg:tw-flex]="isBox" *ngIf="!isRestaurant">
          <ng-container *ngIf="!isAirline">
            <i class="fas fa-bed tw-self-center"></i>
            <span class="tw-self-center">{{'commons.room' | translate}}</span>
          </ng-container>
          <ng-container *ngIf="isAirline">
            <i class="fas fa-barcode tw-self-center"></i>
            <span class="tw-self-center">{{'commons.fly_number' | translate}}</span>
          </ng-container>
          <span class="tw-font-semibold tw-self-center">{{guest.room}}</span>
        </section>

        <section class="tw-hidden tw-flex-col tw-text-main-text tw-justify-center tw-gap-2 lg:tw-text-small 2xl:tw-text-base" [class.lg:tw-flex]="isBox" >
          <i class="fas fa-calendar-check tw-self-center"></i>
          <span class="tw-self-center">{{'commons.date_answer' | translate}}</span>
          <span class="tw-font-semibold tw-self-center">{{formattedAnswerDate | formatDate: currentLanguage }}</span>
        </section>

        <section *ngIf="nps" class="tw-hidden  tw-flex-col tw-text-{{nps}}s tw-justify-center tw-gap-2 lg:tw-text-small 2xl:tw-text-base" [class.lg:tw-flex]="isBox">
          <span class="tw-self-center">
            <mh-core-lib-nps-face [nps]="nps" [size]="'lg:tw-text-xl 2xl:tw-text-3xl'"></mh-core-lib-nps-face>
          </span>
          <span class="tw-font-semibold tw-self-center lg:tw-text-small 2xl:tw-text-base">{{'collect.configuration.filters.'+nps | translate | titlecase}}</span>
        </section>

        <section class="tw-hidden tw-flex-col tw-text-main-text tw-justify-center tw-gap-2 lg:tw-text-small 2xl:tw-text-base" [class.lg:tw-flex]="isBox">
          <i class="far fa-check tw-self-center"></i>
          <span class="tw-self-center">{{'shared.survey.rate' | translate}}</span>
          <div class="tw-flex md:tw-gap-1 2xl:tw-gap-3 tw-self-center">
            <span class="tw-font-semibold tw-text-main md:tw-text-base 2xl:tw-text-2xl tw-self-center">{{score | number:'1.1' | replaceChar: ',': '.'}}</span>
            <span class="tw-font-semibold tw-border-l tw-border-r tw-border-border-main-text tw-h-3/4 tw-self-center"></span>
            <span class="tw-font-semibold tw-text-sm tw-self-center">5.0</span>
          </div>
        </section>

      </section>

      <section class="tw-flex tw-justify-between">
        <section class="tw-hidden tw-flex-col tw-gap-3 tw-flex tw-justify-center tw-items-center" [class.lg:tw-flex]="isBox">
          <mh-core-lib-button
              [isSecondary]="true"
              [text]="'shared.semantic.categories.see_survey' | translate"
              [width]="isMobileButton ? 135 : 160"
              (clicked)="handleSeeSurveyClick()"
            >
          </mh-core-lib-button>
          <div class="tw-hidden" [class.!tw-block]="(survey?.answered_survey?.cases && survey?.answered_survey?.cases.length > 0)">
            <mh-core-lib-button
              [emoji]="survey?.answered_survey?.detected_cases && showDetectedCases ? '⚠️' : null"
              [isSecondary]="true"
              [text]="'cases.commons.created_cases' | translate"
              [width]="isMobileButton ? 140 : 160"
              (clicked)="actionsOpen = !actionsOpen"
              cdkOverlayOrigin
              #triggerActions="cdkOverlayOrigin"
            >
            </mh-core-lib-button>

          </div>

          <div class="tw-hidden" [class.!tw-block]="(survey?.answered_survey?.cases && survey?.answered_survey?.cases.length === 0)">
            <mh-core-lib-actions-wrapper
                [slug]="casesSlug"
              >
              <mh-core-lib-button
                  [emoji]="survey?.answered_survey?.detected_cases && showDetectedCases ? '⚠️' : null"
                  [isSecondary]="true"
                  [text]="'cases.titles.form' | translate"
                  [width]="isMobileButton ? 135 : 160"
                  (clicked)="handleCreateCase(survey)"
                  cdkOverlayOrigin
                  #triggerActions="cdkOverlayOrigin"
                  >
              </mh-core-lib-button>
            </mh-core-lib-actions-wrapper>
          </div>
        </section>

      </section>
    </section>


    <section class="tw-w-full tw-bg-background-gray tw-px-5 tw-py-2 tw-text-main-text lg:tw-hidden" *ngIf="isCardLayout">
      <section class="tw-flex tw-justify-center">
        <div class="tw-mx-auto tw-text-lg tw-font-semibold">{{'commons.info_survey' | translate}}</div>
      </section>
    </section>

    <section class="nps-section {{nps}} tw-py-2 xl:tw-py-0.5 2xl:tw-py-2 tw-text-center tw-font-semibold tw-text-white" [class.lg:tw-hidden]="isBox">
      <span class="tw-font-semibold tw-self-center">{{nps | titlecase}}</span>
    </section>

    <section class="tw-flex tw-flex-col tw-text-main-text tw-px-6 tw-pb-3 tw-gap-2 xl:tw-gap-0.5 2xl:tw-gap-2" [class.lg:tw-hidden]="isBox">

      <section class="tw-flex tw-text-main-text">
        <div class="tw-flex tw-basis-3/5 tw-gap-2 tw-font-medium" [class.tw-text-small]="isTight">
          <i class="far fa-check tw-self-center"></i>
          <span class="tw-self-center">{{'shared.survey.rate' | translate}}</span>
        </div>
        <div class="tw-flex tw-gap-3 tw-basis-2/5">
          <span class="tw-font-semibold tw-text-main tw-text-2xl tw-self-center" [class.tw-text-xl]="isTight">{{score | number:'1.1' | replaceChar: ',': '.'}}</span>
          <span class="tw-font-semibold tw-border-l tw-border-r tw-border-border-main-text tw-h-1/2 tw-self-center"></span>
          <span class="tw-font-semibold tw-text-md tw-self-center" [class.tw-text-small]="isTight">5.0</span>
        </div>
      </section>

      <section class="tw-flex tw-text-main-text">
        <div class="tw-flex tw-basis-3/5 tw-gap-2 tw-font-medium" [class.tw-text-small]="isTight">
          <i class="fas fa-file-alt tw-self-center"></i>
          <span class="tw-self-center">{{'followup.survey.filter.survey' | translate}}</span>
        </div>
        <div class="tw-flex tw-gap-3 tw-basis-2/5" [class.tw-text-small]="isTight">
          <span class="tw-font-bold">{{survey.survey_name | translateObject: 'title'}}</span>
        </div>
      </section>

      <section class="tw-flex tw-text-main-text">
        <div class="tw-flex tw-basis-3/5 tw-gap-2 tw-font-medium" [class.tw-text-small]="isTight">
          <i class="fas fa-file-alt tw-self-center"></i>
          <span class="tw-self-center">{{'commons.survey_code' | translate}}</span>
        </div>
        <div class="tw-flex tw-gap-3 tw-basis-2/5" [class.tw-text-small]="isTight">
          <span class="tw-font-bold">{{survey?.answered_survey?.id}}</span>
        </div>
      </section>

      <section class="tw-flex tw-text-main-text">
        <div class="tw-flex tw-basis-3/5 tw-gap-2 tw-font-medium" [class.tw-text-small]="isTight">
          <i class="fas fa-scrubber tw-self-center"></i>
          <span class="tw-self-center">{{'core-lib.selectors.guest.filters_title.origin' | translate}}</span>
        </div>
        <div class="tw-flex tw-gap-3 tw-basis-2/5" [class.tw-text-small]="isTight">
          <span class="tw-font-bold tw-capitalize">{{survey?.origin_name}}</span>
        </div>
      </section>

      <section class="tw-flex tw-text-main-text">
        <div class="tw-flex tw-basis-3/5 tw-gap-2 tw-font-medium" [class.tw-text-small]="isTight">
          <i class="fas fa-draw-square tw-self-center"></i>
          <span class="tw-self-center">{{'followup.survey.filter.channel' | translate}}</span>
        </div>
        <div class="tw-flex tw-gap-3 tw-basis-2/5" [class.tw-text-small]="isTight">
          <span class="tw-font-bold">{{survey?.guest?.integration_channel?.source ?? ''}}</span>
        </div>
      </section>

      <section class="tw-flex tw-text-main-text">
        <div class="tw-flex tw-basis-3/5 tw-gap-2 tw-font-medium" [class.tw-text-small]="isTight">
          <i class="fas fa-calendar-check tw-self-center"></i>
          <span class="tw-self-center">{{'commons.date_answer' | translate}}</span>
        </div>
        <div class="tw-flex tw-gap-3 tw-basis-2/5" [class.tw-text-small]="isTight">
          <span class="tw-font-bold">{{survey?.answered_survey?.answered_date | formatDate: currentLanguage }}</span>
        </div>
      </section>

      <section class="tw-flex tw-text-main-text">
        <div class="tw-flex tw-basis-3/5 tw-gap-2 tw-font-medium" [class.tw-text-small]="isTight">
          <i class="fas fa-globe-americas tw-self-center"></i>
          <span class="tw-self-center">{{'followup.survey.filter.language' | translate}}</span>
        </div>
        <div class="tw-flex tw-gap-3 tw-basis-2/5" [class.tw-text-small]="isTight">
          <span class="tw-font-bold">{{'languages.iso.'+(survey?.answered_survey?.language_answer ? survey?.answered_survey?.language_answer?.code : guest?.language?.code) | translate}}</span>
        </div>
      </section>


      <section class="tw-mt-3">
          <section class="tw-flex tw-justify-center tw-gap-3" [class.tw-hidden]="isCardLayout">
            <mh-core-lib-button [width]="isMobileButton ? 130 : 160" [isSecondary]="true" [text]="'shared.semantic.categories.see_survey' | translate" (clicked)="handleSeeSurveyClick()">
            </mh-core-lib-button>
              <div class="tw-hidden" [class.!tw-block]="(survey?.answered_survey?.cases && survey?.answered_survey?.cases.length > 0)">
                <mh-core-lib-button
                    [isSecondary]="true"
                    [text]="'cases.commons.created_cases' | translate"
                    [width]="isMobileButton ? 140 : 160"
                    (clicked)="mobileActionsOpen= !mobileActionsOpen"
                    cdkOverlayOrigin
                    #triggerActionsMobile="cdkOverlayOrigin"
                    >
                </mh-core-lib-button>

              </div>
              <div class="tw-hidden" [class.!tw-block]="(survey?.answered_survey?.cases && survey?.answered_survey?.cases.length === 0)">
                <mh-core-lib-actions-wrapper
                    [slug]="casesSlug"
                  >
                  <mh-core-lib-button
                      [emoji]="survey?.answered_survey?.detected_cases && showDetectedCases ? '⚠️' : null"
                      [isDefault]="true"
                      [text]="'cases.titles.form' | translate"
                      [width]="isMobileButton ? 130 : 160"
                      (clicked)="handleCreateCase(survey)"
                      cdkOverlayOrigin
                      #triggerActionsMobile="cdkOverlayOrigin"
                      >
                  </mh-core-lib-button>

                </mh-core-lib-actions-wrapper>

              </div>

          </section>

          <section class="tw-flex tw-justify-center tw-gap-3" [class.tw-hidden]="isBox">
            <mh-core-lib-actions-wrapper
                [slug]="slug"
              >
              <div class="tw-mx-auto export-button">
                <mh-core-lib-button
                    [width]="isMobileButton ? 120 : 160"
                    [isSecondary]="true"
                    [text]="'shared.survey-answer-modal.export' | translate"
                    [isDisabled]="downloadingPDF"
                    [isDownloading]="downloadingPDF"
                    (clicked)="handleExportPDF()"
                    >
                </mh-core-lib-button>
              </div>
            </mh-core-lib-actions-wrapper>
            <div class="tw-mx-auto tw-hidden" [class.!tw-block]="(survey?.answered_survey?.cases && survey?.answered_survey?.cases.length === 0)">
              <mh-core-lib-actions-wrapper
                  [slug]="casesSlug"
                >
                <mh-core-lib-button
                    [width]="isMobileButton ? 120 : 160"
                    [isDefault]="true"
                    [text]="'cases.titles.form' | translate"
                    (clicked)="handleCreateCase(survey)"
                    >
                </mh-core-lib-button>
              </mh-core-lib-actions-wrapper>
            </div>
            <div class="tw-mx-auto tw-hidden" [class.!tw-block]="(survey?.answered_survey?.cases && survey?.answered_survey?.cases.length > 0)">
              <mh-core-lib-button
                [isSecondary]="true"
                [text]="'cases.commons.created_cases' | translate"
                [width]="isMobileButton ? 140 : 160"
                (clicked)="cardActionsOpen = !cardActionsOpen"
                cdkOverlayOrigin
                #triggerActionsCard="cdkOverlayOrigin"
              >
              </mh-core-lib-button>
            </div>
          </section>

          <section *ngIf="downloadingPDF" class="tw-py-2">
            <p class="tw-w-fit tw-mx-auto tw-font-semibold">{{'commons.generating_file' | translate}}</p>
          </section>
      </section>

    </section>
  </article>
</div>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="deleteOpen"
    [cdkConnectedOverlayOffsetY]="75"
    [cdkConnectedOverlayPanelClass]="'tw-mr-3'"
    (overlayOutsideClick)="deleteOpen = false"
    >
    <ul class="tw-bg-white tw-rounded-lg tw-shadow-1 tw-relative tw-p-3">
      <li>
        <button type="button" class="hover:tw-text-main-orange tw-transition-all focus:tw-outline-0" (click)="handleDeleteSurvey()">
          <span>{{'shared.survey.delete_survey' | translate }}</span>
        </button>
      </li>
    </ul>
</ng-template>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="triggerActions"
    [cdkConnectedOverlayOpen]="actionsOpen"
    [cdkConnectedOverlayOffsetY]="15"
    [cdkConnectedOverlayPanelClass]="'tw-mr-3'"
    (overlayOutsideClick)="actionsOpen = false"
    >
    <ul class="tw-bg-white tw-rounded-lg tw-shadow-1 tw-relative tw-py-3 tw-min-w-[220px] tw-divide-y">
      <li *ngFor="let cases of survey?.answered_survey?.cases" class="tw-cursor-pointer tw-px-4 tw-py-1 tw-font-semibold tw-text-main-text hover:tw-text-main-orange tw-transition-all">
        <a [href]="'/cases/'+ ((cases?.closed === true || cases?.closed === undefined) ? 'history' : 'active') +'?id='+cases.public_id" target="_blank">{{cases.title}}</a>
      </li>
      <li class="tw-px-4 tw-py-1 tw-font-semibold tw-text-main-text hover:tw-text-main-orange tw-transition-all">
        <mh-core-lib-actions-wrapper
            [slug]="casesSlug"
          >
          <span class="tw-mr-1" *ngIf="survey?.answered_survey?.detected_cases && showDetectedCase">⚠️</span>
          <button type="button" (click)="handleCreateCase(survey)">
            {{(survey?.answered_survey?.detected_cases && showDetectedCase ? 'cases.commons.see_detected_case' : 'cases.titles.form') | translate}}
          </button>
        </mh-core-lib-actions-wrapper>
      </li>
    </ul>
</ng-template>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="triggerActionsCard"
    [cdkConnectedOverlayOpen]="cardActionsOpen"
    [cdkConnectedOverlayOffsetY]="15"
    [cdkConnectedOverlayPanelClass]="['tw-mr-3', 'trigger-actions-card']"
    (overlayOutsideClick)="cardActionsOpen = false"
    >
    <ul class="tw-bg-white tw-rounded-lg tw-shadow-1 tw-relative tw-py-3 tw-min-w-[220px] tw-divide-y">
      <li *ngFor="let cases of survey?.answered_survey?.cases" class="tw-cursor-pointer tw-px-4 tw-py-1 tw-font-semibold tw-text-main-text hover:tw-text-main-orange tw-transition-all">
        <a [href]="'/cases/'+ ((cases?.closed === true || cases?.closed === undefined) ? 'history' : 'active') +'?id='+cases.public_id" target="_blank">{{cases.title}}</a>
      </li>
      <li class="tw-px-4 tw-py-1 tw-font-semibold tw-text-main-text hover:tw-text-main-orange tw-transition-all">
        <mh-core-lib-actions-wrapper
            [slug]="casesSlug"
          >
          <span class="tw-mr-1" *ngIf="survey?.answered_survey?.detected_cases && showDetectedCases">⚠️</span>
          <button type="button" (click)="handleCreateCase(survey)">
            {{(survey?.answered_survey?.detected_cases && showDetectedCases ? 'cases.commons.see_detected_case' : 'cases.titles.form') | translate}}
          </button>
        </mh-core-lib-actions-wrapper>
      </li>
    </ul>
</ng-template>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="triggerActionsMobile"
    [cdkConnectedOverlayOpen]="mobileActionsOpen"
    [cdkConnectedOverlayOffsetY]="15"
    [cdkConnectedOverlayPanelClass]="'tw-mr-3'"
    (overlayOutsideClick)="mobileActionsOpen = false"
    >
    <ul class="tw-bg-white tw-rounded-lg tw-shadow-1 tw-relative tw-py-3 tw-min-w-[220px] tw-divide-y">
      <li *ngFor="let cases of survey?.answered_survey?.cases" class="tw-cursor-pointer tw-px-4 tw-py-1 tw-font-semibold tw-text-main-text hover:tw-text-main-orange tw-transition-all">
        <a [href]="'/cases/'+ ((cases?.closed === true || cases?.closed === undefined) ? 'history' : 'active') +'?id='+cases.public_id" target="_blank">{{cases.title}}</a>
      </li>
      <li class="tw-px-4 tw-py-1 tw-font-semibold tw-text-main-text hover:tw-text-main-orange tw-transition-all">
        <mh-core-lib-actions-wrapper
            [slug]="casesSlug"
          >
          <span class="tw-mr-1" *ngIf="survey?.answered_survey?.detected_cases && showDetectedCases">⚠️</span>
          <button type="button" (click)="handleCreateCase(survey)">
            {{(survey?.answered_survey?.detected_cases && showDetectedCase ? 'cases.commons.see_detected_case' : 'cases.titles.form') | translate}}
          </button>
        </mh-core-lib-actions-wrapper>
      </li>
    </ul>
</ng-template>

