<div class="tw-flex tw-justify-end" *ngIf="!loading">
    <i class="far fa-times tw-cursor-pointer" (click)="close()"></i>
</div>
<ng-container *ngIf="!error else errorContainer">
    <ng-container *ngIf="reply else loadingContainer">
        <section class="tw-h-full">
            <section class="tw-overflow-y-auto tw-h-3/4 md:tw-h-5/6">
                <div class="tw-flex tw-flex-col tw-mx-6">
                    <div class="tw-flex tw-flex-col tw-text-black">
                        <h3 class="tw-place-self-center tw-font-semibold tw-text-lg">{{ 'smart_replies.action_button.modal_title' | translate }} <i class="tw-font-normal">(beta)</i></h3>
                        <p class="tw-place-self-center tw-mt-5">{{ 'smart_replies.action_button.original_review' | translate }} {{this.data.review.ota.name}} - {{this.data.review.ota_rating}}/{{this.data.review.ota.scale}}</p>
                        <p class="tw-place-self-center tw-mt-2 tw-text-sm">{{this.data.review.guest.username}}</p>
                    </div>
        
                    <p class="tw-mt-6 tw-mx-3 tw-italic tw-text-black">{{ this.data.review.description }}</p>
        
                    <div class="tw-mt-8">
                        <textarea class="tw-border-[0.5px] tw-border-border-gray tw-rounded-md tw-resize-none tw-w-full focus:tw-ring-0 focus:tw-border-border-gray tw-text-black" [rows]="6" [formControl]="areaCtrol"></textarea>
                    </div>
                </div>
            </section>
            <section class="tw-my-6 tw-mx-4 tw-flex tw-flex-col md:tw-flex-row tw-justify-between">
                <p class="tw-text-sm tw-font-gray tw-italic">{{ ('smart_replies.action_button.' + ((edited) ? 'edited_reply' : 'can_edit')) | translate }}</p>
                <div class="tw-flex tw-flex-col md:tw-flex-row tw-gap-2 md:tw-gap-7">
                    <mh-core-lib-button [isSecondary]="true" [width]="180" [text]="('smart_replies.action_button.feedback' | translate)" (click)="goToFeedback('modal')"></mh-core-lib-button>
                    <mh-core-lib-button [isSecondary]="true" [text]="('smart_replies.action_button.copy' | translate)" (click)="copyReplyToClipboard()"></mh-core-lib-button>
                    <mh-core-lib-button [isSecondary]="true" [text]="('online.reviews.answer' | translate)" (click)="goToComment(data.review.url)"></mh-core-lib-button>
                </div>
            </section>
        </section>
    </ng-container>
</ng-container>


<ng-template #loadingContainer>
    <div class="tw-flex tw-flex-col tw-content-center tw-flex-wrap tw-my-28">
        <p class="tw-text-2xl tw-font-bold tw-text-center">{{ 'smart_replies.action_button.loading' | translate }}</p>
        <img class="tw-w-1/5 tw-mt-4 tw-mx-auto" src="https://mh-statics-dev-new.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/img/smart-replies-bot.gif">
    </div>
</ng-template>

<ng-template #errorContainer>
    <div class="tw-flex tw-flex-col tw-content-center tw-flex-wrap tw-my-28 tw-gap-7">
        <p class="tw-text-2xl tw-font-bold tw-text-center">{{ 'smart_replies.action_button.' + ((limitExceededError) ? 'limit_exceeded_error' : 'error') | translate }}</p>
        <p class="tw-text-xl tw-text-center tw-px-1.5">{{ 'smart_replies.action_button.limit_exceeded_error_description' | translate }}</p>
        <mh-core-lib-button [isFeedback]="true" [width]="300" [text]="('smart_replies.action_button.limit_feedback' | translate)" (click)="goToFeedback('limit')"></mh-core-lib-button>
    </div>
</ng-template>
