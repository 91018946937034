<mh-survey-section *ngFor="let section of sections"
  [titleTranslates]="section.translates"
  [language]="language"
>
  <ng-container *ngFor="let question of section.questions">
    <mh-survey-question-public
      [question]="question"
      [answerId]="answerId"
      [conditionalQuestions]="survey.conditional_questions || []"
      [language]="language"
      [languageId]="languageId"
      [surveyLanguageId]="surveyLanguageId"
      [answers]="answers"
      [answered]="answered"
      [preview]="preview"
      [productId]="survey.product_id"
      [answersComments]="answersComments"
      (answer)="answer.emit($event)"
    ></mh-survey-question-public>
  </ng-container>
</mh-survey-section>
