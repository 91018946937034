<section class="progress_chain_bar" [hidden]="isReady || error">
  <mat-progress-bar
      [mode]="mode"
      [value]="value">
  </mat-progress-bar>
  <h3>{{'waiting.messages.wait_chain' | translate:({total: total, type: (type | translate )})}}</h3>
  <div class="waiting_spinner">
    <mh-spinner-load></mh-spinner-load>
  </div>
</section>

<h3 class="align-center" *ngIf="error">
  ERROR
</h3>