<mh-wait-full></mh-wait-full>
<mh-users-states-handler></mh-users-states-handler>
<div class="wrapper">

    <header>
      <mh-header [isMenuOpen]="elSidenav.opened" [isMobile]="isMobile" (menuStatus)="handleMenuStatus($event)" (openMenuEvent)="openMenuHandler()"></mh-header>
    </header>

  <mat-drawer-container class="menu-container" autosize [hasBackdrop]="true">
    <mat-drawer #drawer class="menu-sidenav" mode="over" position="end" (closedStart)="closeMenuMobile()">
      <div class="d-flex justify-content-between">
      <button class="close-menu-mobile-btn btn" (click)="closeMenuMobile()">
        <mat-icon aria-hidden="false" aria-label="Example home icon">arrow_forward</mat-icon>
      </button>
      <mh-header-button
        [type]="'languages'"
        [currentUser]="currentUser"
        [availableLanguages]="availableLanguages"
        [currentLanguage]="currentLanguage"
        [customer]="currentHotel"
        [size]="35"
        [isMobile]="isMobile"
        (updatedLanguage)="switchLanguage($event)"
      ></mh-header-button>
      </div>
      <div class="splitter"></div>
      <mh-new-menu [currentUser]="currentUser" [customer]="currentHotel" [isMobile]="true" [isRetail]="isRetail" (closeMenu)="closeMenuMobile()">
      </mh-new-menu>
    </mat-drawer>

    <mat-sidenav-container class="example-sidenav-fab-container" id="main_view" autosize>

      <mat-sidenav #sidenav class="side_menu" [mode]="navbarMode" [opened]="navbarOpen" (@collapsedState.start)="start()" (@collapsedState.done)="done()" [@collapsedState]="navState">
        <mh-navbar class="responsive" [isMenuOpen]="elSidenav.opened" [isMobile]="isMobile"></mh-navbar>
      </mat-sidenav>

      <mat-sidenav-content>

        <div #mainContent
          class="main_content {{section}}"
          [style.min-height.px]="util.screenHeight"
          >
          <div class="fade_loading"></div>
          <alert></alert>
          <router-outlet></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </mat-drawer-container>

</div>

<div *ngIf="isMobile" class="create-case-btn" [class.hidden]="isMenuOpen">
  <mh-header-button
    [type]="'cases'"
    [currentUser]="currentUser"
    [customer]="currentHotel"
    (createCaseEvent)="openNewModal()"
    >
  </mh-header-button>
</div>

<mh-socket></mh-socket>
<mh-confirmation-modal></mh-confirmation-modal>

<ng-template #alertModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <i class="close far fa-times" (click)="closeDialog()"></i>
    <h4 class="title">{{alertModalContent.title | translate }}</h4>
  </div>
  <div class="modal-body" [innerHTML]="(alertModalContent.body | translate:alertModalContent.translateParams)"></div>
  <div class="modal-small" [innerHTML]="(alertModalContent.small | translate:alertModalContent.translateParams)"></div>
</ng-template>
