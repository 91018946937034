<ng-template #modal>
  <mh-modal [close]="cancel" class='align-center'>
    <div class="text-content">
      {{translatePath | translate}}
    </div>
    <ng-container *ngIf="data">
      <ng-container *ngTemplateOutlet="selectData"></ng-container>
    </ng-container>
    <div class="actions">
      <button class="btn-white" (click)="accept()">{{ acceptTransaltion | translate}}</button>
      <button class="btn-white" (click)="cancel()">{{ cancelTranslation | translate}}</button>
    </div>
  </mh-modal>
</ng-template>

<ng-template #selectData>
  <ng-select
    class="ng-select custom my-3 text-left"
    [items]="data"
    [multiple]="true"
    bindLabel="displayName"
    bindValue="id"
    [closeOnSelect]="false"
    [formControl]="dataControl">
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.displayName}}
    </ng-template>

    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div class="ng-value" *ngFor="let item of items | slice:0:1">
          <span class="ng-value-label">{{ item.first_name }} {{ item.last_name }}</span>
          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">x</span>
      </div>
      <div class="ng-value" *ngIf="items.length > 1">
          <span class="ng-value-label">{{ items.length - 1 }} {{ 'cases.commons.more' | translate }}</span>
      </div>
    </ng-template>
  </ng-select>
</ng-template>
