import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class SocketService {

  constructor(private http:HttpClient) { }

  getChannels(type:string, id: any) {
    const url = `${environment.customerPath}notifications/channels/open/${type}/${id}`;
    const params:HttpParams = new HttpParams();

    return this.http.get(url, {params: params})
      .pipe(
        map(response=>response)
      );
  }
}
