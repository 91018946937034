import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'widget-guest-qualify',
  templateUrl: './guest-qualify.component.html',
  styleUrls: ['./guest-qualify.component.scss']
})
export class GuestQualifyComponent implements OnInit {

  @Input() survey

  @Input()
  layoutView: 'list-view' | 'card-view'

  constructor() { }

  ngOnInit() {
  }

  get isCardView() { return this.layoutView === 'card-view'}
}
