import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CasesPropertiesService {
  constructor(private http: HttpClient) {}

  getDeskCorpByStatus(customerIds, dates) {
    const url = `${environment.casesPath}corporative/cases/status?customers_ids=${customerIds}&start_date=${dates.startDate}&end_date=${dates.endDate}`;
    return this.http.get(url);
  }

  getDeskCorpByPriority(customerIds, dates) {
    const url = `${environment.casesPath}corporative/cases/priority?customers_ids=${customerIds}&start_date=${dates.startDate}&end_date=${dates.endDate}`;
    return this.http.get(url);
  }

  getDeskCorpByDistribution(customerIds, dates) {
    const url = `${environment.casesPath}corporative/cases/distribution?customers_ids=${customerIds}&start_date=${dates.startDate}&end_date=${dates.endDate}`;
    return this.http.get(url);
  }

  getDeskCorpByAreasRanking(customerIds, dates) {
    const url = `${environment.casesPath}corporative/customers/areas_ranking`;
    let params = new HttpParams();
    if (customerIds && customerIds.length > 0) {
      params = params.set('customers_ids', customerIds);
    }
    if (dates && dates.startDate && dates.endDate) {
      params = params.set('start_date', dates.startDate);
      params = params.set('end_date', dates.endDate);
    }
    return this.http.get<number[]>(url, { params }).toPromise();
  }

  getDeskCorpByNoManagement(customerIds, dates) {
    const url = `${environment.casesPath}corporative/customers/nomanagement`;
    let params = new HttpParams();
    if (customerIds && customerIds.length > 0) {
      params = params.set('customers_ids', customerIds);
    }
    if (dates && dates.startDate && dates.endDate) {
      params = params.set('start_date', dates.startDate);
      params = params.set('end_date', dates.endDate);
    }

    return this.http.get<number[]>(url, { params }).toPromise();
  }

  getDeskCorpByTypes(customerIds, dates) {
    const url = `${environment.casesPath}corporative/cases/types`;
    let params = new HttpParams();
    if (customerIds && customerIds.length > 0) {
      params = params.set('customers_ids', customerIds);
    }
    if (dates && dates.startDate && dates.endDate) {
      params = params.set('start_date', dates.startDate);
      params = params.set('end_date', dates.endDate);
    }
    return this.http.get<number[]>(url, { params }).toPromise();
  }

  getActiveHotels(customerIds: number[]): Promise<number[]> {
    const url = `${environment.casesPath}corporative/active`;
    const ids = customerIds.length ? customerIds.join(',') : null;
    let params = {};
    if (ids) {
      params = new HttpParams().set('customers_ids', ids);
    }
    return this.http.get<number[]>(url, { params }).toPromise();
  }

  getTrends(customerIds: number[]): Promise<number[]> {    
    const url = `${environment.casesPath}corporative/cases/usage/trend`;
    const ids = customerIds.length ? customerIds.join(',') : null;
    let params = {};
    if (ids) {
      params = new HttpParams().set('customers_ids', ids);
    } else {
      return;
    }
    return this.http.get<number[]>(url, { params }).toPromise();
  }

  getPerformance(customerIds, dates) {
    const url = `${environment.casesPath}corporative/customers/performance`;
    let params = new HttpParams();
    if (customerIds && customerIds.length > 0) {
      params = params.set('customers_ids', customerIds);
    }
    if (dates && dates.startDate && dates.endDate) {
      params = params.set('start_date', dates.startDate);
      params = params.set('end_date', dates.endDate);
    }
    return this.http.get<number[]>(url, { params }).toPromise();
  }
}
