import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'onlineReviewsNpsFilter'
})
export class OnlineReviewsNpsFilterPipe implements PipeTransform {

  transform(reviews, filters) {
    let filteredReviews = [...reviews];
    if ( filteredReviews && filteredReviews.length > 0 ) {
      const filter = filters.find(filter => filter.name === 'nps')
      const filtering = filter.options.some(option => option.isFiltering);
      if(filter && filtering) {
        const options = filter.options.filter(option => option.isFiltering).map(option => option.text);
        filteredReviews = filteredReviews.filter(item => {
          return options.includes(item.nps);
        })
      }
    }
    return filteredReviews;
  }

}
