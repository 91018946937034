<article class="tw-w-full tw-flex tw-flex-col tw-gap-2 tw-py-3">
    <section class="tw-flex tw-justify-center">
        <div>
            <span *ngIf="type === 'email'" class="tw-text-4xl">✉️</span>
            <img *ngIf="type === 'whatsapp'" src="https://mh-statics-dev-new.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/img/whatsapp.png" alt="whatsapp logo">
        </div>
    </section>

    <section class="tw-mt-3">
        <p class="tw-font-medium tw-text-xl tw-text-center">
            {{ (type === 'email' ? 'answer_survey.dialog.email_title' : 'answer_survey.dialog.whatsapp_title') | translate }}
        </p>
    </section>

    <section class="tw-flex tw-justify-center tw-mb-5 tw-mt-1">
        <p class="tw-text-sm tw-font-normal tw-text-center">
            {{ (type === 'email' ? 'answer_survey.dialog.email_msg' : 'answer_survey.dialog.whatsapp_msg') | translate: { email: userEmail } }}
        </p>
    </section>

    <section class="tw-flex tw-justify-center tw-gap-2">
        <mh-core-lib-button 
            [isSecondary]="true" 
            [text]="'answer_survey.dialog.cancel' | translate"
            [width]="'tw-w-fit'"
            [height]="38"
            [customClass]="'!tw-shadow-none tw-text-gray-500 tw-font-bold'"
            (clicked)="close()">
        </mh-core-lib-button>
        <mh-core-lib-button 
            [isSecondary]="true" 
            [text]="'answer_survey.dialog.confirm_' + type | translate"
            [width]="'tw-w-fit'"
            [height]="38"
            [customClass]="'!tw-shadow-none !tw-bg-sky-600 tw-text-white tw-font-bold hover:tw-text-white'"
            (clicked)="handleConfirmSend()">
        </mh-core-lib-button>
    </section>
</article>