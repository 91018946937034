import { Component, OnInit, Input } from '@angular/core';
import { Product } from 'app/shared/model';

@Component({
  selector: 'mh-prestay-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {

  @Input()
  viewType:string;

  @Input()
  orders = [];

  @Input()
  orderSelected;

  @Input()
  guest;

  @Input()
  products:Product[] = [];

  @Input()
  money;


  constructor() { }

  ngOnInit() {

  }


}
