import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class CoverageService {

  constructor(private http:HttpClient) { }

  public getRanking(dates:any,customerIds:string){
    const url=`${environment.interChaiCoverageRanking}`;
    const params:HttpParams= new HttpParams()
      .set("customer_ids",customerIds)
      .set("start_date",dates.startDate)
      .set("end_date",dates.endDate)
    return this.http.get(url,{params: params})
      .pipe(
        map(response=>response)
      )
  }

  public getPerCustomers(dates:any,customerIds:string){
    const url=`${environment.interChaiCoverageCustomers}`;
    const params:HttpParams= new HttpParams()
      .set("customer_ids",customerIds)
      .set("start_date",dates.startDate)
      .set("end_date",dates.endDate)
    return this.http.get(url,{params: params})
      .pipe(
        map(response=>response)
      )
  }

  public getGeneral(dates:any,customerId:string,customerIds:string){
    const url=`${environment.interChaiCoverageGeneral}`;
    const params:HttpParams= new HttpParams()
      .set("customer_ids",customerIds)
      .set("start_date",dates.initDate)
      .set("end_date",dates.endDate)
    return this.http.get(url,{params: params})
      .pipe(
        map(response=>response)
      )
  }
}
