import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'alertsTranslate'
})
export class AlertsTranslatePipe implements PipeTransform {

  constructor(
    private translateService: TranslateService
  ) {}

  async transform(key: any, type) {
    return await this.getTranslates(key, type);
  }

  async getTranslates(key,type) {
    if(type === 'active') type = 'status_alert';
    const translatedType = await this.translateService.get(`alerts.${type}`).toPromise();
    return await this.translateService.get(key, {alert: translatedType}).toPromise();
  }
}
