<mat-form-field class="w-100">
  <mat-label>{{'commons.date' | translate}}</mat-label>
  <input
      matInput
      [value]="answerDisplay"
      [disabled]="isDisabled()"
      (click)="picker.open()"
      />
  <input [matDatepicker]="picker" (dateChange)="dateChange($event)" hidden disabled/>
  <mat-hint>DD/MM/YYYY</mat-hint>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
