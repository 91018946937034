import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {UtilService} from "../../util.service";
import { NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import { CustomDatepickerI18n } from "../../utils/custom-datepicker-I18n"
import { ChangeDetectorRef } from "@angular/core";
import { FilterDateService } from './filter-date-service';
import { ActivatedRoute } from '@angular/router';
import { DatePickerStruct } from 'app/shared/model';
import { Store } from '@ngxs/store';
import { CustomersState } from 'app/states/customers/customers.state';

@Component({
  selector: 'mh-filter-date',
  templateUrl: './filter-date.component.html',
  styleUrls: ['./filter-date.component.scss'],
  providers:[
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}
  ]
})
export class FilterDateComponent implements OnInit {
  @Input() startDate:string;
  @Input() endDate:string;

  @Input() prevStartDate:string;
  @Input() prevEndDate:string;

  @Input() type:string = "default"
  @Input() generalDate:boolean=true;
  @Input() report:string;
  @Input() reportActiveEmail:string;
  @Input() persistDate:boolean = true;
  @Input() optionSelected;
  @Input() isSingleCustomer = false;
  @Input() currentSurveyId;
  @Input() disableReport = false;

  @Input() checkPermissions = false;
  @Input() slug;

  #_chain;

  @Input() set chain(value) {
    this.#_chain = value;
  }

  get chain() {
    return this.#_chain;
  }

  urlStartDate:string;
  urlEndDate:string;

  lastFilter;

  waiting:boolean = true;
  visible:boolean = false;
  filterSelected:string;

  filterOptions:Array<object>;
  filterWidth:string= "10%";

  dateType = 'months';

  isToday:boolean=false;
  endDateReal:string;

  filterOptionsDefault:Array<object>=[
    {name: 'last_7_days', title: 'Últimos 7 días'},
    {name: 'last_30_days', title: 'Últimos 30 días'},
    {name: 'this_month', title: 'Este Mes'},
    {name: 'last_month', title: 'Mes Pasado'},
    {name: 'last_12_weeks', title: 'Últimos 3 meses'},
    {name: 'year_to_date', title: ''},
    {name: 'custom', title: 'Personalizado'},
  ]

  filterOptionsTrends:Array<object>=[
    {name: 'last_7_days', title: ''},
    {name: 'last_14_days', title: ''},
    {name: 'last_4_weeks', title: ''},
    {name: 'last_12_weeks', title: ''},
    {name: 'last_12_month', title: ''},
    {name: 'last_24_month', title: ''},
    {name: 'year_to_date', title: ''},
    {name: 'custom', title: 'Personalizado'},
  ]

  dateString: string;
  showCalendar:boolean = false;

  @Output()
  dateUpdated = new EventEmitter<string>();

  @Output()
  endDateUpdated=new EventEmitter<string>();

  @Output()
  dateResult: EventEmitter<any> = new EventEmitter();

  constructor(
    private utilService:UtilService,
    private cdRef:ChangeDetectorRef,
    private filterDateService:FilterDateService,
    private route: ActivatedRoute,
    private store: Store
  ) { }

  toggleCalendar(){
    this.showCalendar = this.filterSelected==='custom';
  }

  toggleVisble($event){
    if(["date_selected","start_date","end_date", "bar_state"].includes($event.target.parentElement.className)){
      this.visible = !this.visible;
      this.toggleCalendar();
    }
  }

  onDateChange(date: DatePickerStruct) {
    if(date.fromDate && date.toDate){
      const formatNgdDate = (date:{day:number, month:number, year:number}) =>
        ({...date, month: date.month -1});
      this.filterDateService.fromDate = formatNgdDate(date.fromDate);
      this.filterDateService.toDate = formatNgdDate(date.toDate);
      this.toggleCalendar();
      this.updateDate();
    }
  }

  ngOnInit() {
    this.endDateReal = this.endDate;
    if(
      this.filterDateService.filterType === undefined ||
      this.filterDateService.filterType !== this.type
    ) this.filterDateService.filterType = this.type;

    this.filterOptions = this.filterDateService.filterType === "default" ? this.filterOptionsDefault : this.filterOptionsTrends;
    this.filterSelected = this.filterDateService.filterType === "default" ? "default" : "last_30_days";

    if(this.optionSelected !== undefined)
      this.filterSelected = this.optionSelected;

    if(this.filterDateService.filterRangeName === undefined && this.persistDate){
      this.filterDateService.filterRangeName = this.filterSelected
    }

    if(!this.persistDate){
      this.filterOptions = this.filterOptionsTrends;
      this.filterSelected = this.optionSelected ? this.optionSelected : "last_12_month";
    }else{
      this.filterSelected = this.filterDateService.filterRangeName;
    }

    this.waiting = false;
    this.filterWidth = this.util.isMobile() ? '100%' : (100/ this.filterOptions.length)+'%';
    this.checkDateParams();
    this.updateDate();
    this.cdRef.detectChanges();
  }


  checkDateParams(){
    this.route.queryParams.subscribe(params => {

      if(params['date'] !== undefined) {
        this.urlStartDate = params['date'];
        this.urlEndDate = params['date']
      } else {
        this.urlStartDate = params['startDate'];
        this.urlEndDate = params['endDate']
      }
    });

    if(this.urlStartDate && this.urlEndDate){
      this.filterSelected="params"
    }
  }

  filterCalculate(name:string){
    const { startDate: rawStartDate, endDate: rawEndDate } = this.getDatesFromName(name)
    const date = {
      init: this.util.formatDate(rawStartDate),
      end: this.util.formatDate(rawEndDate),
    }

    return {
      ...date,
      ...this.getPreviousDate(date.init, date.end)
    };
  }

  private getDatesFromName(name:string) {
    if(name === 'year_to_date') {
      return {
        startDate: new Date(new Date().getFullYear(), 0, 1),
        endDate: this.utilService.getYesterday(0),
      };
    } else if(name === "last_month"){
      const lastMonth = this.utilService.getLastMonth();

      return {
        startDate: lastMonth,
        endDate: this.utilService.getLastDayOnMonth(lastMonth),
      }
    }else if(name === "yesterday"){
      return {
        startDate: this.utilService.getYesterday(),
        endDate: this.utilService.getYesterday(),
      }
    }else if(name === "today"){
      return {
        startDate: this.utilService.getYesterday(0),
        endDate: this.utilService.getYesterday(0),
      }
    }else{
      let format = name.split('_');
      let quantity        = parseInt(format[1]) || 0;
      let last_time:Date  = this.utilService.getYesterday(quantity);

      if(format[2] && format[2]==="weeks") {
        last_time = this.utilService.getYesterday(7*quantity)
      } else if(format[2] && format[2]==="month" || name === "this_month") {
        last_time = this.utilService.getLastMonth(quantity,(name === "this_month"));
      } else if(format[2] && format[2]==="year"  || name === "last_year")  {
        last_time = this.utilService.getLastMonth(Math.max(12,quantity*12),(name === "last_year"));
      }

      return {
        startDate: last_time,
        endDate: new Date()
      }
    }
  }

  getPreviousDate(startDate:string,endDate:string){
    let date = {}
    let distance     = this.utilService.getDistanceDates(startDate,endDate);
    let start        = this.utilService.stringDateToDate(startDate)
    date["prevInit"] = this.utilService.formatDate(this.utilService.getYesterday(distance,start));
    date["prevEnd"]  = startDate;

    return date;
  }


  closeOutsideSidenav() { this.visible = false; }

  changeFilter(filter) {
    this.filterSelected = filter.name;

    if(this.persistDate) this.filterDateService.filterRangeName = this.filterSelected;
    if(filter.name !== 'custom') {
      this.updateDate();
    } else {
      this.toggleCalendar();
    }
  }

  updateDate(){
    this.visible = false;
    let chain = this.store.selectSnapshot(CustomersState.relativeCurrentChain);
    this.chain = this.chain ? this.chain : chain;

    if(this.filterSelected==="custom"){
      let date = this.getPreviousDate(this.startDate,this.endDate)
      this.prevStartDate = date["prevInit"]
      this.prevEndDate   = date["prevEnd"]
    }else if(this.filterSelected === "params"){
      this.filterSelected="custom";
    }else{
      let nameFilter = this.persistDate ? this.filterDateService.filterRangeName : this.filterSelected;
      let date = this.filterCalculate(nameFilter);
      this.prevStartDate = date["prevInit"]
      this.prevEndDate   = date["prevEnd"]
    }

    if(this.generalDate){
      let type = 'months';
      if(this.filterSelected && this.filterSelected.includes('day')) type = 'days';
      if(this.filterSelected && this.filterSelected.includes('week')) type = 'weeks';

      if(this.filterSelected==='custom'){
        const distance = this.utilService.getDistanceDates(this.startDate,this.endDate);
        const weeks    = distance/7;

        if(weeks <=2) type = 'days';
        if(weeks>2 && weeks <=12) type='weeks';

      }
      //SE AGREGA UN DIA ADICIONAL POR PROBLEMAS QUERY UTC CUANDO LA FECHA FIN ES HOY
      const today:Date = this.utilService.getYesterday(0);
      this.endDateReal = this.endDate;
      if (this.endDate === this.utilService.formatDate(today)){
        this.isToday = true
        this.endDateReal = this.endDate;
      }else{
        this.isToday = false;
      }

      this.utilService.setCurrentDate(this.startDate, this.endDate, type, this.filterSelected);
      //PARA TEMAS DE TRANSICION Y TRATAR DE DEJAR EL ESTADO EN UTILS
      this.utilService.emitCurrentDateRaw(this.startDate, this.endDateReal || this.endDate, type, this.filterSelected)
      this.utilService.prevStartDate = this.prevStartDate
      this.utilService.prevEndDate   = this.prevEndDate
      this.utilService.filterDate    = this.filterSelected
    }else{
      this.emitDateResult();
    }
  }

  emitDateResult(){
    let type = 'months';

    if(this.filterSelected){
      if(this.filterSelected.includes('day'))  type = 'days';
      if(this.filterSelected.includes('week')) type = 'weeks';
    }

    this.dateResult.emit({
      'startDate': this.startDate,
      'endDate': this.endDate,
      'type' : type,
      'option': this.filterSelected
    });
  }

  get util() { return this.utilService; }
}
