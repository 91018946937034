import {TranslateService} from '@ngx-translate/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import {Injectable} from '@angular/core';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  constructor(private translate: TranslateService) {
    super();

    this.translate.onDefaultLangChange.subscribe((e: Event) => {
      this.getAndInitTranslations();
    });

    this.getAndInitTranslations();
  }

  getAndInitTranslations() {
    this.translate.get(['models.paginator.items_per_page', 'models.paginator.next_page', 'models.paginator.previous_page']).subscribe(translation => {
      this.itemsPerPageLabel = translation['models.paginator.items_per_page'];
      this.nextPageLabel     = translation['models.paginator.next_page'];
      this.previousPageLabel = translation['models.paginator.previous_page'];
      this.changes.next();
    });
  }

  getRangeLabel = (page: number, pageSize: number, length: number) =>  {
    if (length === 0 || pageSize === 0) {
      return `0 / ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} / ${length}`;
  }
}