<div>
  <mat-form-field class="w-100" appearance="outline">
    <textarea matInput
      rows="5"
      [class.preview]="preview"
      [disabled]="preview"
      [value]="getAnswer().answer_text || ''"
      (blur)="handleBlur($event)"
    ></textarea>
  </mat-form-field>

  <ng-container *ngIf="surveyLanguageId!==languageId && getAnswer().answer_text">

    <div class="translate-btn" (click)="translate()">
      <i class="fal fa-language"></i>
      <span>{{'commons.translate' | translate }}</span>
    </div>

  </ng-container>

  <ng-container *ngIf="isTranslatedPressed">
    <ng-container *ngIf="waitTranslate else boxTranslated">
      <mh-spinner-load></mh-spinner-load>
    </ng-container>
  </ng-container>
</div>

<ng-template #boxTranslated>
  <mat-form-field class="w-100" appearance="outline">
    <textarea matInput
      [disabled]="preview"
      [value]="answerTranslated"
    ></textarea>
  </mat-form-field>

</ng-template>
