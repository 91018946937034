import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import * as moment from 'moment';
import { UtilService } from 'app/layouts/main';
import { DateTime } from 'luxon';

const getDateString = (date:Date) => formatDate(date, 'yyyy-MM-dd', 'en-US');
const removeTimeToDate = (date:Date) => moment(getDateString(date)).toDate();
const areEqual = (d1:Date, d2:Date) => getDateString(d1) === getDateString(d2);
const isRangeEqual = (d1:Date[], d2:Date[]) => d1.reduce((acum, d, idx) => acum && areEqual(d, d2[idx]), true)

const firstDayOfTheYear:Date = moment(`${(new Date()).getFullYear()}-01-01`).toDate();
const today:Date = removeTimeToDate(moment().toDate())
const lastTwelveWeeks:Date = moment(today).subtract(12, 'weeks').toDate()

const DEFAULTS = {
  _fromDate: lastTwelveWeeks,
  _toDate: today,
  filterType: 'default',
  filterRangeName: 'last_12_weeks',
}

@Injectable({
  providedIn: 'root'
})
export class FilterDateService {
  LOCAL_STORAGE_KEY = 'currentDateFilter';

  private _fromDate:Date = firstDayOfTheYear;
  private _toDate:Date = today;

  #_newFromDate;
  #_newToDate;

  //TODO: creo que esto es un tema mas visual, y cada luegar debiese elegir su implementacion
  // asi podemos dejar este componente mucho mas simple
  filterType:string = 'default'; // borrar cuando unifiquemos fechas
  filterRangeName:string = 'year_to_date'; // borrar cuando unifiquemos fechas

  // ESTO ES SOLO PARA COORDINAR ESTE ESTADO CON EL DE UTILS
  // EL OBJETIVO FINAL ES EXTRAER LAS FECHAS DE UTILS
  constructor(private utils:UtilService) {
    this.utils.isCurrentDateRawChange().subscribe(this.setDates.bind(this))
    this.initDates()
  }

  initDates() {
    const start_date = DateTime.now().minus({months: 3}).toFormat('kkkk-LL-dd');
    const end_date = DateTime.now().toFormat('kkkk-LL-dd');
    this.#_newFromDate = start_date;
    this.#_newToDate = end_date;
    this.setDateFromLocalStorage()
  }

  // MISMA DEUDA QUE LA DE ARRIBA
  updateUtilsDate() {
    this.filterRangeName = getLegacyLabelsFromDates(this.fromDate, this.toDate);
    const adjustedToDate = areEqual(today, this.toDate) ?
      moment(this.toDate).add(1, 'days').toDate() : this.toDate
    this.utils.setCurrentDate(this.fromDate, adjustedToDate, 'months', this.filterRangeName);
    this.setDateToLocalStorage()
  }

  // MISMA DEUDA QUE LA DE ARRIBA
  private setDates(dates:{startDate:Date, endDate:Date, option:string, type:string}) {
    Object.assign(
      this,
      {
        _fromDate: moment(dates.startDate).toDate(),
        _toDate: moment(dates.endDate).toDate(),
        filterRangeName: dates.option,
        filterType: dates.type,
      }
    )
    this.setDateToLocalStorage()
  }

  getDatesRangeString() {
    return {
      start_date: getDateString(this.fromDate),
      end_date: getDateString(this.toDate),
    };
  }
  getDatesPrevRangeString() {
    const toMoment = moment(this.toDate);
    const fromMoment = moment(this.fromDate);
    const diff = toMoment.diff(fromMoment)

    // need to bring them back one period
    return {
      start_date: getDateString(fromMoment.subtract(diff).toDate()),
      end_date: getDateString(toMoment.subtract(diff).toDate()),
    };
  }
  getFromDateString() { return getDateString(this._fromDate); }
  getToDateString() { return getDateString(this._toDate); }

  getRangeMoment() {
    return [moment(this._fromDate), moment(this._toDate)];
  }

  setDateRange({dates: momentDates}: {dates: moment.Moment[]}) {
    const [fromDate, toDate] = momentDates.map(d => d.toDate())
    Object.assign(this, {fromDate, toDate})

    //legacy
    this.updateUtilsDate()
  }

  setCustomDate(dates: {toDate: string, fromDate: string}) {
    this.filterType = 'default';
    this._fromDate = this.splitDate(dates.fromDate);
    this._toDate = this.splitDate(dates.toDate);

    //legacy
    this.updateUtilsDate()
  }

  setDateFromLocalStorage() {
    const localStoregeString = localStorage.getItem(this.LOCAL_STORAGE_KEY) ||
      JSON.stringify(DEFAULTS);

    const {_fromDate, _toDate, ...otherProperties} = JSON.parse(localStoregeString)
    Object.assign(this, {
      _fromDate: new Date(_fromDate),
      _toDate: new Date(_toDate),
      ...otherProperties,
    });
  }

  setDateToLocalStorage() {
    localStorage.setItem(
      this.LOCAL_STORAGE_KEY,
      JSON.stringify({
        _fromDate: this._fromDate,
        _toDate: this._toDate,
        filterType: this.filterType,
        filterRangeName: this.filterRangeName,
      })
    )
  }

  public get datesRange() { return { start_date: this.fromDate, end_date: this.toDate }; }
  public get fromDate() { return this._fromDate; }
  public get previousFromDate() { return this._fromDate; }
  public set fromDate(date:any) {
    this._fromDate = date.day ? new Date(date.year, date.month, date.day) : date;
  }
  public get toDate() { return this._toDate; }
  public get previousToDate() { return this._fromDate; }
  public set toDate(date:any) {
    this._toDate = date.day ? new Date(date.year, date.month, date.day) : date;
  }

  private splitDate(date: string) {
    let newDate = new Date(date);
    return new Date(newDate.getTime() + newDate.getTimezoneOffset() * 60000);
  }

  setNewFromDate(date) {
    this.#_newFromDate = date;
  }

  setNewToDate(date) {
    this.#_newToDate = date;
  }

  get newFromDate() {
    return this.#_newFromDate;
  }

  get newToDate() {
    return this.#_newToDate;
  }
}

//Esta es la cuspide de lo legacy. en orden para poder coordinar los servicios,
//el nuevo servicio tiene que tener alguna forma de obtener los labels
function getLegacyLabelsFromDates(startDate: Date, endDate: Date) {
  const ranges = {
    last_7_days: [moment(today).subtract(7, 'days').toDate(), today],
    last_30_days: [moment(today).subtract(30, 'days').toDate(), today],
    this_month: [moment(today).startOf('month').toDate(), today],
    last_month: [
      moment(today).subtract(1, 'months').startOf('month').toDate(),
      moment(today).subtract(1, 'months').endOf('month').startOf('day').toDate(),
    ],
    last_12_weeks: [moment(today).subtract(12, 'weeks').toDate(), today],
    year_to_date: [moment(today).startOf('year').toDate(), today],
  }

  const legacyLabel = Object.keys(ranges)
    .find(val => isRangeEqual([startDate, endDate], ranges[val]))

  return legacyLabel || 'custom';
}
