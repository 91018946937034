import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';

import { FilterModel } from '../models/filter-model';

@Component({
  selector: 'mh-invoice-filter',
  templateUrl: './invoice-filter.component.html',
  styleUrls: ['./invoice-filter.component.scss']
})
export class InvoiceFilterComponent implements OnInit {

  @Output()
  stateFilterUpdated: EventEmitter<FilterModel[]> = new EventEmitter<FilterModel[]>();

  public isOpen: boolean = false;
  public hasActiveFilters: boolean = false;
  public options: FilterModel[] = [
    {value: 'ALL', text: 'all', selected: false},
    {value: 'PAID', text: 'paid', selected: false},
    {value: 'PENDING', text: 'pending', selected: false},
    {value: 'EXPIRED', text: 'expired', selected: false}
  ];

  @HostListener('document:click', ['$event'])
  onClick(event){
    const result = this.checkClickInside(event.target);
    if ( !result ) {
      this.isOpen = false;
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

  updateFilter(event: FilterModel) {
    if(event.value === 'ALL') {
      this.options.map(state => state.selected = true);
      this.stateFilterUpdated.emit(this.options);
      return;
    }

    const indexEvent = this.options.findIndex((item) => item === event);
    this.options.map((opt ,idx) => {
      if(idx === indexEvent) opt.selected = !opt.selected;
      if(opt.value === 'ALL') opt.selected = false;
    });

    this.hasActiveFilters = this.options.some((item) => item.selected === true) ? true : false;

    this.stateFilterUpdated.emit(this.options);
  }

  cleanFilters() {
    this.options.forEach(opt => {
      opt.selected = false;
    });
    this.hasActiveFilters = false;

    this.stateFilterUpdated.emit(this.options);
  }

  checkClickInside(target) {
    if ( target.id === 'statusInvoiceFilterOverlay' || target.id === 'statusInvoiceFilter' || target.className?.includes('chip-no-close') || target.className?.includes('generic-option') || target.className?.includes('checkbox-option')) {
      return true;
    } else if ( target.parentNode ) {
      return this.checkClickInside(target.parentNode)
    } else {
      return false;
    }
  }
}
