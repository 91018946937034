<div class="survey-info">
  <widget-guest-stay-nights
    [icon]="'fas fa-dot-circle'"
    [value]="survey.origin_name"
    [text]="('followup.survey.filter.origin_name' | translate )"
    [layoutView]="layoutView">
  </widget-guest-stay-nights>

  <widget-guest-stay-nights
    [icon]="'fas fa-draw-square'"
    [value]="survey.integration_channel?.source"
    [text]="('followup.survey.filter.channel' | translate )"
    [layoutView]="layoutView">
  </widget-guest-stay-nights>

  <widget-guest-stay-nights
    [icon]="'fas fa-list-alt'"
    [value]="survey.answer.id"
    [text]="('commons.survey_code' | translate )"
    [layoutView]="layoutView">
  </widget-guest-stay-nights>

  <widget-calendar-date
    [label]="('commons.date_answered' | translate)"
    [date]="survey.answer.date"
    [type]="'enphasis'"
    [layoutView]="layoutView">
  </widget-calendar-date>

  <widget-guest-rate-info
    [survey]="survey"
    [icon]="'fas fa-check'"
    [value]="(survey.satisfaction.score | formatScore:2)"
    [text]="('followup.summary.survey-comments.rate'| translate )"
    [layoutView]="layoutView">
  </widget-guest-rate-info>

  <ng-container *ngIf="isLanguageCodeNumber">
    <widget-guest-stay-nights
      [icon]="'fas fa-globe'"
      [value]="('languages.id.'+languageCode | translate)"
      [text]="('commons.answer_language' | translate )"
      [layoutView]="layoutView">
    </widget-guest-stay-nights>
  </ng-container>

  <ng-container *ngIf="!isLanguageCodeNumber">
    <widget-guest-stay-nights
      [icon]="'fas fa-globe'"
      [value]="('languages.iso.'+languageCode | translate)"
      [text]="('commons.answer_language' | translate )"
      [layoutView]="layoutView">
    </widget-guest-stay-nights>
  </ng-container>

</div>
