<div class="tw-relative tw-rounded-lg tw-shadow-1">
  <div class="tw-p-5 tw-flex tw-flex-col md:tw-grid md:tw-grid-cols-[200px_3fr_1fr]">
    <!-- info user -->
    <div class="tw-flex tw-flex-col">
      <!-- product -->
      <span class="tw-font-bold tw-bg-[#d7e0ff] tw-rounded-full tw-px-2 tw-py-1 tw-w-fit tw-mb-2">{{getProductByIdFn(review.product_id) | titlecase}}</span>
      <span class="tw-font-bold">{{review.username}}</span>
      <span class="tw-font-medium" *ngIf="review.product_id === 1 || review.product_id === 2">{{'semantic.comments.item.room' | translate: {room: review.room} }}</span>
      <span class="tw-font-medium tw-w-28" [mhTooltip]="review.product_id === 1 || review.product_id === 2 ? ('semantic.comments.tables.tooltips.reply_date' | translate) : null" position="above">{{review.date | formatDate: language}}</span>
      <span class="tw-font-medium" *ngIf="review?.country_code !== 'XX' && review.product_id === 3">{{'countries.'+ review.country_code | translate}}</span>
    </div>
    <!-- review -->
    <div>
      <span class="tw-font-bold">{{review.title}}</span>
      <p class="tw-mt-5" [ngClass]="{'tw-mb-2': translatedTitle || translatedDescription}">{{review.comment}}</p>
      <ng-container *ngIf="translatedTitle || translatedDescription">
        <div class="tw-border"></div>
        <div class="tw-mt-2" >
          <span class="tw-font-bold">{{translatedTitle}}</span>
          <p class="tw-mt-5">{{translatedDescription}}</p>
        </div>
      </ng-container>
      <p class="tw-text-xs tw-text-[#979797] tw-mt-2 tw-italic tw-block md:tw-hidden">{{('semantic.comments.tables.actions.mobile_warning') | translate}}</p>
    </div>
    <!-- graph -->
    <div class="tw-absolute tw-right-4 tw-ml-auto" [mhTooltip]="'semantic.comments.tables.tooltips.positive_percentage' | translate">
      <ng-container *ngIf="reviewToDisplay.themes.length > 0">
        <div class="tw-absolute tw-inset-0 tw-w-fit tw-h-fit tw-m-auto tw-font-bold tw-text-2xl tw-z-10">{{(positiveThemeCount/reviewToDisplay.themes.length) | percent}}</div>
        <mat-progress-spinner
            class="complete tw-absolute"
            [mode]="'determinate'"
            [strokeWidth]="9.5"
            [value]="100">
        </mat-progress-spinner>

        <mat-progress-spinner
            class="value-graph tw-absolute"
            [color]="'primary'"
            [mode]="'determinate'"
            [value]="(positiveThemeCount/reviewToDisplay.themes.length) * 100">
        </mat-progress-spinner>
      </ng-container>
    </div>
  </div>
  <div class="tw-border"></div>
  <div class="tw-p-5 tw-grid tw-grid-cols-[200px_3fr] tw-relative">
    <div class="tw-flex tw-gap-3 tw-items-center" [ngClass]="{'!tw-items-start': !collapsed}">
      <div class="tw-flex tw-gap-2">
        <span class="tw-font-bold">{{positiveThemeCount}}</span>
        <i class="fas fa-smile tw-text-promoters tw-mt-[2.5px]" [mhTooltip]="'semantic.comments.tables.tooltips.positive' | translate"></i>
      </div>
      &#8901;
      <div class="tw-flex tw-gap-2">
        <span class="tw-font-bold">{{negativeThemeCount}}</span>
        <i class="fas fa-frown tw-text-detractors tw-mt-[2.5px]" [mhTooltip]="'semantic.comments.tables.tooltips.negative' | translate"></i>
      </div>
    </div>
    <div [@heightExpand]="collapsed ? 'void' : '*'">
      <div class="tw-grid tw-grid-cols-2">
        <div class="tw-grid tw-grid-cols-2 tw-gap-y-1">
          <span class="tw-font-bold">{{'commons.theme' | translate}}</span>
          <span class="tw-font-bold">{{'commons.sentiment' | translate}}</span>
          <ng-container *ngFor="let theme of reviewToDisplay.themes; let index = index">
            <span class="tw-font-medium" mhFindTranslate [language]="language" [translates]="theme.translates" [keyTranlsate]="'text'"></span>
            <div class="tw-flex tw-gap-1.5">
              <i *ngIf="theme.polarity === 'positive'" class="fas fas fa-smile tw-text-promoters tw-mt-[2.5px]" [mhTooltip]="'semantic.comments.tables.tooltips.positive' | translate"></i>
              <i *ngIf="theme.polarity === 'negative'" class="fas fas fa-frown tw-text-detractors tw-mt-[2.5px]" [mhTooltip]="'semantic.comments.tables.tooltips.negative' | translate"></i>
              <button *ngIf="edit" type="button" class="focus:tw-outline-0 tw-text-main-text hover:tw-text-main-orange tw-transition-colors tw-ml-1 tw-underline tw-text-[11px]" (click)="handleUpdatePolarity(theme, index)" [mhTooltip]="'semantic.comments.tables.tooltips.edit' | translate">{{'commons.switch' | translate}}</button>
            </div>
          </ng-container>

        </div>
        <div class="tw-grid tw-grid-cols-2 tw-gap-y-1">
          <span class="tw-font-bold">{{'commons.areas' | translate}}</span>
          <span class="tw-font-bold">{{'commons.sentiment' | translate}}</span>
          <ng-container *ngFor="let area of areas">
            <span class="tw-font-medium" mhFindTranslate [language]="language" [translates]="area.area.translates" [keyTranlsate]="'text'">
            </span>
            <div class="tw-flex tw-gap-1.5">
              <div class="tw-flex tw-gap-2">
                <span class="tw-font-bold">{{area?.positive}}</span>
                <i class="fas fa-smile tw-text-promoters tw-mt-[2.5px]" [mhTooltip]="'semantic.comments.tables.tooltips.positive' | translate"></i>
              </div>
              &#8901;
              <div class="tw-flex tw-gap-2">
                <span class="tw-font-bold">{{area?.negative}}</span>
                <i class="fas fa-frown tw-text-detractors tw-mt-[2.5px]" [mhTooltip]="'semantic.comments.tables.tooltips.negative' | translate"></i>
              </div>
            </div>
          </ng-container>

        </div>

      </div>
    </div>
  </div>
  <div class="tw-absolute tw-right-5 tw-bottom-5 tw-flex tw-gap-5 tw-font-bold" [ngClass]="{'!tw-relative tw-ml-auto tw-mt-5 tw-w-fit': !collapsed}">
    <a [href]="review.link" class="tw-text-main" target="_blank">
      <span *ngIf="review.product_id === 1 || review.product_id === 2">{{('semantic.comments.tables.actions.see_survey') | translate}} </span>
      <span *ngIf="review.product_id === 3">{{('semantic.comments.tables.actions.see_review') | translate}} </span>
    </a>
    <button *ngIf="languageId !== review.language_code" type="button" class="tw-text-main focus:tw-outline-0 tw-hidden md:tw-block" (click)="handleTranslateComment()">{{'commons.translate' | translate | titlecase}}</button>
    <mh-core-lib-actions-wrapper
        slug="semantic_comments_edit"
        >
        <button *ngIf="!collapsed" type="button" class="tw-text-main focus:tw-outline-0" (click)="toggleEdit()" [mhTooltip]="'semantic.comments.tables.tooltips.edit' | translate">{{(edit ? 'forms.save' : 'commons.edit') | translate | titlecase}}</button>
    </mh-core-lib-actions-wrapper>
    <button type="button" class="tw-text-main focus:tw-outline-0 tw-hidden md:tw-block" (click)="toggleDetails()" *ngIf="negativeThemeCount > 0 || positiveThemeCount > 0">{{(!collapsed ? 'semantic.comments.tables.actions.hide_details' : 'commons.see_detail') | translate | titlecase}}</button>
  </div>
</div>
