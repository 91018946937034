import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {UtilService} from "../../util.service";
import {FilterSearchItem,FilterReview} from "../../model/index"
import {OtasService} from "../../../online/otas/otas.service";


@Component({
  selector: 'mh-filter-search',
  templateUrl: './filter-search.component.html',
  styleUrls: ['../../../../assets/scss/shared/filters/filter-search.scss'],
  providers:[OtasService]
})
export class FilterSearchComponent implements OnInit {

  filterOptions: FilterReview[]=[];
  filters:FilterSearchItem[]=[];
  selectedFilters = {}
  showFilters = false;
  customerId;
  otas;

  @Output() onFilters = new EventEmitter<any[]>();

  @Input()
  count:number=0;

  @Input()
  languageIds = [];


  readonly AVAILABLE_LANGUAGES = [
    new FilterSearchItem('language', 1, 'Español', true),
    new FilterSearchItem('language', 2, 'Inglés', true),
    new FilterSearchItem('language', 3, 'Portugés', true),
    new FilterSearchItem('language', 4, 'Francés', true),
    new FilterSearchItem('language', 5, 'Chino', true),
    new FilterSearchItem('language', 6, 'Bengali', true),
    new FilterSearchItem('language', 7, 'Portugues', true),
    new FilterSearchItem('language', 8, 'Ruso', true),
    new FilterSearchItem('language', 9,  'Japones', true),
    new FilterSearchItem('language', 10, 'Aleman', true),
    new FilterSearchItem('language', 11, 'Frances', true),
    new FilterSearchItem('language', 12, 'Italiano', true),
    new FilterSearchItem('language', 15, 'Croata', true),
    new FilterSearchItem('language', 16, 'Albanés', true),
    new FilterSearchItem('language', 17, 'Griego', true),
    new FilterSearchItem('language', 18, 'Turco', true),
    new FilterSearchItem('language', 19, 'Búlgaro', true),
    new FilterSearchItem('language', 20, 'Macedonio', true),
    new FilterSearchItem('language', 21, 'Rumano', true),
    new FilterSearchItem('language', 22, 'Húngaro', true),
    new FilterSearchItem('language', 23, 'Eslovaco', true),
    new FilterSearchItem('language', 24, 'Esloveno', true),
    new FilterSearchItem('language', 25, 'Checo', true),
    new FilterSearchItem('language', 26, 'Ucraniano', true),
    new FilterSearchItem('language', 27, 'Polaco', true),
    new FilterSearchItem('language', 28, 'Lituano', true),
    new FilterSearchItem('language', 29, 'Letón', true),
    new FilterSearchItem('language', 30, 'Estonio', true),
    new FilterSearchItem('language', 31, 'Finlandés', true),
    new FilterSearchItem('language', 32, 'Sueco', true),
    new FilterSearchItem('language', 33, 'Noruego', true),
    new FilterSearchItem('language', 34, 'Hebreo', true),
    new FilterSearchItem('language', 35, 'Vietnamita', true),
    new FilterSearchItem('language', 36, 'Filipino', true),
    new FilterSearchItem('language', 37, 'Danés', true),
    new FilterSearchItem('language', 38, 'Holandés', true),
    new FilterSearchItem('language', 39, 'Africano', true),
    new FilterSearchItem('language', 40, 'Swahili', true),
    new FilterSearchItem('language', 41, 'Malayalam', true),
    new FilterSearchItem('language', 42, 'Coreano', true),
    new FilterSearchItem('language', 43, 'Tailandés', true),
    new FilterSearchItem('language', 14, 'Otros', true),
  ];

  constructor(private utilService:UtilService, private otasService:OtasService,) {
    this.customerId = this.utilService.getCurrentCustomer().id;
    this.otas = this.otasService;
  }

  addFilter(){
    let filterOptions = this.filterOptions;
    let allOptions = filterOptions.map(function(d){
      let options = d["options"];
      let checkedOptions = options.map(function(val){
        if(val.checked) {
          return val
        }
      })
      checkedOptions = checkedOptions.filter(function(n){ return n !== undefined });
      return checkedOptions;
    });


    for(let filter of filterOptions){
      for(let option of filter.options){
        if(!option.checked) filter.checked = false;
      }
    }


    this.filters = [].concat.apply([], allOptions);
    this.onFilters.emit(filterOptions);
    this.utilService.ga('filter','click','online-reviews');
  }

  groupFilter(filter){
    for(let option of filter.options){
      option.checked = filter.checked
    }
    this.addFilter();
  }

  ngOnInit(){

    this.otas.getOtasByConfigClient(this.customerId).subscribe((response: any) => {
      let otasFilters:FilterSearchItem[] = [];
      for(let ota of response){
        if (ota.active) otasFilters.push(new FilterSearchItem("ota",ota.id,ota.name,true,false))
      }
      this.filterOptions.push(
        new FilterReview("answered", [
            new FilterSearchItem("answered","answered","shared",true),
            new FilterSearchItem("answered","notAnswered","notAnswered",true),
            new FilterSearchItem("answered","notComentable","notComentable",true)
          ]
        ),
        new FilterReview("otas", otasFilters),
        new FilterReview("score", [
          new FilterSearchItem("promoter","promoter","promoter",true),
          new FilterSearchItem("passive","passive","passive",true),
          new FilterSearchItem("detractor","detractor","detractor",true),
        ]),
        new FilterReview("typeTraveler", [
            new FilterSearchItem("typeTraveler",1,"Trabajo",true),
            new FilterSearchItem("typeTraveler",2,"Familia",true),
            new FilterSearchItem("typeTraveler",3,"Parejas",true),
            new FilterSearchItem("typeTraveler",4,"Solo",true),
            new FilterSearchItem("typeTraveler",5,"Amigos",true),
            new FilterSearchItem("typeTraveler",6,"Otros",true),
          ]
        ),
        new FilterReview("language", this.getAvailableLanguages()),
      )//push

      for(let filter of this.filterOptions){
        this.selectedFilters[filter.slug] = "all"
      }

    });
    this.addFilter();
  }

  toggleFilters(){
    this.showFilters = !this.showFilters
  }

  getAvailableLanguages(): FilterSearchItem[] {
    return  this.languageIds.reduce((prev, curr) => {
      const language = this.AVAILABLE_LANGUAGES.find((lang) => lang.value === curr);
      return [...prev, language];
    }, []);
  }
}



