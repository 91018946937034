<p ><a href="{{reportLink}}">{{reportLink}}</a></p>
<div id="reportGenerator">

  <label>Reporte:</label>
  <select [(ngModel)]="endpoint">
    <option value="comment" >Comment</option>
    <option value="kpi" >KPI</option>
    <option value="quality-price" >Quality Price</option>
    <option value="search-ranking" >Search Ranking</option>
  </select>

  <label>Hoteles:</label>
  <select  [(ngModel)]="idhotel" style="width: 150px">
    <option  *ngFor="let hotel of hotelList" [ngValue]="hotel.id" > {{hotel.customerName}}</option>
  </select>

    <label>Fecha Inicio:</label> <input type="date" #initdate/>
    <label>Fecha Fin:</label> <input type="date" #enddate/>




</div>


