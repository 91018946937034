import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {environment} from "../../../../environments/environment";
import { map } from 'rxjs/operators';

@Injectable()
export class CategoriesService {
  constructor(private http:HttpClient) { }

  public getAreas(dates:any,customerId:string, product_id,languageId,surveysIds: any=null){
    const url = parseInt(product_id) === 3 ? `${environment.onlinePath}semantic/areas` : `${environment.followupPath}semantic/areas`;
    const params:HttpParams = new HttpParams()
      .set("start_date",dates.startDate)
      .set("end_date",dates.endDate)
      .set("customer_id",customerId)
      .set("product_id",product_id)
      .set("language_id", languageId)
      .set("surveys_ids", surveysIds);

    return this.http.get(url, {params: params})
      .pipe(
        map(response=>response)
      );
  }

  public getAreaDetail(dates:any,customerId:string,areaId, product_id:string,sentimentPolarity="all", languageId,surveysIds: any=null){

    const url = parseInt(product_id) === 3 ? `${environment.onlinePath}semantic/area` : `${environment.followupPath}semantic/area`;
    const params:HttpParams = new HttpParams()
      .set("start_date",dates.startDate)
      .set("end_date",dates.endDate)
      .set("customer_id",customerId)
      .set("area_id",areaId)
      .set("product_id",product_id)
      .set("sentiment_polarity", sentimentPolarity)
      .set("language_id", languageId)
      .set("surveys_ids", surveysIds);

    return this.http.get(url, {params: params})
    .pipe(
      map(response=>response)
    );
  }
}
