import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class MentionsService {
  constructor(private http:HttpClient) { }

  public getSemantic(type,dates:any,customerId:string,product_id){
    const url: any= this.getEndpointUrl(type,product_id)
    const params:HttpParams = new HttpParams()
      .set("start_date",dates.startDate)
      .set("end_date",dates.endDate)
      .set("customer_id",customerId)
      .set("product_id",product_id);

    return this.http.get(url, {params: params})
      .pipe(
        map(response=>response)
      );
  }

  getEndpointUrl(type, product_id){
    if(type==="themes")   return product_id==='3' ? `${environment.semanticOnlineTheme}` : `${environment.semanticFollowupTheme}`;
    if(type==="entities") return product_id==='3' ? `${environment.semanticOnlineEntity}` : `${environment.semanticFollowupEntity}`;
    if(type==="phrases")  return product_id==='3' ? `${environment.semanticOnlinePhrase}` : `${environment.semanticFollowupPhrase}`;
  }
}
