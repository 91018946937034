<div *ngIf="loading" class="tw-absolute tw-h-full tw-w-full tw-bg-white/50 tw-z-50">
  <mh-core-lib-loader-wrapper 
    [type]="'spinner'"
    [loading]="loading"
    className="tw-self-center" 
    spinnerClassName="tw-text-[50px]">
  </mh-core-lib-loader-wrapper>
</div>
<div class="tw-ml-3 tw-h-full tw-p-5">
  <div class="transparent-background"></div>
  <p class="tw-font-semibold tw-text-lg tw-text-main-text tw-mb-5">
    {{ greeting | translate : { user: currentUser?.name } }} 👋🏻
  </p>
  <p class="tw-text-lg tw-text-main-text tw-mt-5 tw-mb-6" *ngIf="availableWidgets?.length > 0">{{ 'dashboard.summary' | translate }}</p>

  <section class="tw-flex tw-flex-wrap -tw-mx-2">
    <ng-container *ngIf="availableWidgets && availableWidgets.length > 0">
      <p class="tw-w-full tw-uppercase tw-text-gray-500 tw-text-sm tw-pl-2">
        {{ 'dashboard.active_modules' | translate }}
      </p>
      <ng-container *ngFor="let widget of availableWidgets">
        <mh-core-lib-dashboard-widget
          class="tw-w-full sm:tw-w-1/2 lg:tw-w-1/2 xl:tw-w-1/3 tw-px-3 tw-py-4"
          [widget]="widget"
          [currentLanguage]="currentLanguage"
          (widgetClicked)="handleWidgetClick($event)"
        >
        </mh-core-lib-dashboard-widget>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="unavailableWidgets && unavailableWidgets.length > 0">
      <p class="tw-w-full tw-uppercase tw-text-gray-500 tw-text-sm tw-mt-3 tw-pl-2">
        {{ 'dashboard.inactive_modules' | translate }}
      </p>
      <ng-container *ngFor="let widget of unavailableWidgets">
        <mh-core-lib-dashboard-widget
          class="tw-w-full sm:tw-w-1/2 lg:tw-w-1/2 xl:tw-w-1/3 tw-px-3 tw-py-4"
          [widget]="widget"
          [currentLanguage]="currentLanguage"
        >
        </mh-core-lib-dashboard-widget>
      </ng-container>
    </ng-container>
  </section>
</div>
