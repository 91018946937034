import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mh-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Output()
  closeEvent = new EventEmitter();

  @Input()
  title = null;

  @Input()
  headerStyles: any = [];

  @Input()
  close: Function = () => null

  constructor() { }

  ngOnInit() {
  }

  onClose() {
    this.close()
    this.closeEvent.emit();
  }
}
