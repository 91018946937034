import { OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';

export class DialogRef {
  private afterClosedSubject = new Subject<any>();
  private eventsSubject = new Subject<any>();

  constructor(private overlayRef: OverlayRef) {}

  close(result?: any) {
    this.overlayRef.dispose();
    this.afterClosedSubject.next(result);
    this.afterClosedSubject.complete();
  }

  afterClosed() {
    return this.afterClosedSubject.asObservable();
  }

  receiveEvent() {
    return this.eventsSubject.asObservable();
  }

  emitEvent(event) {
    this.eventsSubject.next(event);
  }

  closeOnEscapeKey() {
    this.overlayRef.keydownEvents().subscribe((response) => {
      if (response && response.key === 'Escape') {
        this.close();
      }
    });
  }
}
