import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'widget-card-nps-item',
  templateUrl: './card-nps-item.component.html',
  styleUrls: ['./card-nps-item.component.scss']
})
export class CardNpsItemComponent implements OnInit {

  @Input()
  type: 'promoters' | 'passives' | 'detractors';

  @Input()
  quantity = 0;

  @Input()
  tooltipPlacement: 'bottom' | 'left' | 'right' | 'bottom' = 'bottom';

  @Input()
  tooltipText;


  constructor() { }

  ngOnInit() {
  }

}
