import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'app/notifications';
import { UtilService } from 'app/shared/util.service';

@Component({
  selector: 'mh-core-lib-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class CoreLibContentComponent implements OnInit {

  isPaid: boolean;
  isBlocking: boolean;

  constructor(
    private utilService: UtilService,
    private notificationService: NotificationsService
  ) { }

  ngOnInit(): void {
    this.isPaid = this.utilService.getIsPaid();
    this.utilService.isPaid().subscribe(paid => {
      this.isPaid = paid;
      const alert = JSON.parse(
        this.notificationService.getResponseAlert()
      );
      const current = this.utilService.getProductByName(
        this.utilService.currentSection
      )
      this.isBlocking = alert[0].products_ids.includes(Number(current)) && alert[0].alert_property_type.name === 'blocking'
    });
  }

}
