<ng-container *ngIf="!loading else loader">
  <div class="loaded tw-h-full">
    <ng-content></ng-content>
  </div>
</ng-container>


<ng-template #loader>
  <div class="tw-flex tw-h-full">
    <section class="tw-grid tw-gap-3 tw-w-full tw-grid-cols-7 tw-animate-pulse {{className}} tw-items-center">

      <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="'generic'">
          <ng-container *ngTemplateOutlet="generic"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'genericOneLine'">
          <ng-container *ngTemplateOutlet="genericOneLine"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'graphFollowupOnsite'">
          <ng-container *ngTemplateOutlet="graphFollowupOnsite"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'invertedT'">
          <ng-container *ngTemplateOutlet="invertedT"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'collect'">
          <ng-container *ngTemplateOutlet="collect"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'spinner'">
          <ng-container *ngTemplateOutlet="spinner"></ng-container>
        </ng-container>
      </ng-container>

    </section>
  </div>
</ng-template>

<ng-template #graphFollowupOnsite>
  <div class="tw-col-start-3 tw-col-span-3 tw-h-3 tw-bg-main tw-rounded-full tw-self-end"></div>
  <div class="tw-col-start-3 tw-col-span-3 tw-self-center">
    <div class="-tw-rotate-90 background-graph">
      <mat-progress-spinner
        [strokeWidth]="15"
        [diameter]="150"
        [value]="50">
      </mat-progress-spinner>
    </div>
  </div>
</ng-template>

<ng-template #generic>
  <div class="tw-col-start-3 tw-col-span-3 tw-h-3 tw-bg-main tw-rounded-full"></div>
  <ng-container *ngFor="let loader of [1,2,3]">
    <div class="tw-col-start-2 tw-col-span-2 tw-h-3 tw-bg-main tw-rounded-full"></div>
    <div class="tw-col-start-5 tw-col-span-2 tw-h-3 tw-bg-main tw-rounded-full"></div>
  </ng-container>
</ng-template>

<ng-template #genericOneLine>
  <div class="tw-col-start-2 tw-col-span-5 tw-h-3 tw-bg-main tw-rounded-full"></div>
</ng-template>

<ng-template #invertedT>
  <div class="tw-col-start-3 tw-col-span-3 tw-h-3 tw-bg-main tw-rounded-full"></div>
  <div class="tw-col-start-4 tw-col-span-1 tw-h-3 tw-bg-main tw-rounded-full"></div>
  <div class="tw-col-start-4 tw-col-span-1 tw-h-3 tw-bg-main tw-rounded-full"></div>
  <div class="tw-col-start-2 tw-col-span-1 tw-h-3 tw-bg-main tw-rounded-full"></div>
  <div class="tw-col-start-4 tw-col-span-1 tw-h-3 tw-bg-main tw-rounded-full"></div>
  <div class="tw-col-start-6 tw-col-span-1 tw-h-3 tw-bg-main tw-rounded-full"></div>
</ng-template>

<ng-template #collect>
  <div class="tw-col-start-3 tw-col-span-3 tw-h-3 tw-bg-main tw-rounded-full"></div>
  <div class="tw-col-start-2 tw-col-span-1 tw-h-10 tw-w-10 tw-bg-main tw-rounded-full"></div>
  <div class="tw-col-start-4 tw-col-span-1 tw-h-10 tw-w-10 tw-bg-main tw-rounded-full"></div>
  <div class="tw-col-start-6 tw-col-span-1 tw-h-10 tw-w-10 tw-bg-main tw-rounded-full"></div>

  <div class="tw-col-start-2 tw-col-span-1 tw-h-3 tw-bg-main tw-rounded-full"></div>
  <div class="tw-col-start-4 tw-col-span-1 tw-h-3 tw-bg-main tw-rounded-full"></div>
  <div class="tw-col-start-6 tw-col-span-1 tw-h-3 tw-bg-main tw-rounded-full"></div>
</ng-template>

<ng-template #spinner>
  <div class="tw-col-start-4 tw-flex tw-justify-center tw-text-main loaded">
    <i class="fas fa-spinner-third fa-spin {{spinnerClassName}}"></i>
  </div>
</ng-template>
