import { Component, Input } from '@angular/core';


@Component({
  selector: 'mh-wait-graph',
  templateUrl: './wait_graph.component.html',
  styleUrls: ['../../../../../../assets/scss/shared/elements/wait_graph.scss'],
})
export class WaitGraphComponent{
  @Input()
  isHeavyQuery:boolean;

  @Input()
  messages:string[]= [];

  @Input()
  index:number= 0;
}
