import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mh-core-lib-nps-face',
  templateUrl: './nps-face.component.html',
  styleUrls: ['./nps-face.component.scss']
})
export class CoreLibNpsFaceComponent implements OnInit {

  @Input()
  nps;

  @Input()
  size = '';

  constructor() { }

  ngOnInit(): void {
  }

  get isPromoter() {
    return 'promoters'.includes(this.nps?.toLowerCase() || '');
  }

  get isPassive() {
    return 'passives'.includes(this.nps?.toLowerCase() || '');
  }

  get isDetractor() {
    return 'detractors'.includes(this.nps?.toLowerCase() || '');
  }
}
