import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'mh-strength-password',
  templateUrl: './strength-password.component.html',
})
export class StrengthPasswordComponent {
  @Input() possibleErrors: string[];
  @Input() newPassControl: FormControl;
  @Input() confirmControl: FormControl;
}
