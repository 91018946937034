<section class="tw-flex tw-flex-col tw-flex-wrap tw-justify-around tw-content-center tw-w-full tw-h-full">
  <p class="tw-font-semibold tw-text-2xl">{{ 'cases.detail_modal.enter_a_reason' | translate }}</p>

  <form [formGroup]="reasonForm">
    <section class="tw-flex tw-flex-col tw-justify-center tw-content-center tw-gap-5">
      <ng-container *ngFor="let reason of reasons">
        <label class="tw-text-xl tw-flex tw-items-center tw-normal-case">
          <input
            class="tw-mr-2 tw-border-2 tw-h-6 tw-w-6 tw-border-[#0F93CC]"
            type="radio"
            formControlName="reason"
            [value]="reason.id"
          />
          {{ reason.reason }}
        </label>
        <mh-core-lib-textarea-input
          class="tw-w-full"
          [placeholder]="'cases.detail_modal.insert_reason' | translate"
          [formGroup]="reasonForm"
          [keyControl]="'reasonComment'"
          [rows]="5"
          [inputClass]="'!tw-font-[Rubik] tw-border-gray-400 tw-resize-none'"
          [style.visibility]="currentOption !== 5 ? 'hidden' : 'visible'"
          *ngIf="reason.id === 5"
        ></mh-core-lib-textarea-input>
      </ng-container>
    </section>
  </form>

  <section class="tw-flex tw-gap-3 tw-justify-center">
    <mh-core-lib-button
      [isSecondary]="true"
      [text]="'commons.cancel' | translate"
      [customClass]="'!tw-shadow-none !tw-px-2'"
      [textClass]="'tw-font-[Rubik] tw-text-sm'"
      (clicked)="cancelDiscard()"
    ></mh-core-lib-button>

    <mh-core-lib-button
      [isPrimary]="true"
      [isDisabled]="(currentOption === 5 && reasonForm.get('reasonComment').value?.length <= 5) || !reasonForm.get('reason').value"
      [text]="'cases.detail_modal.discard_case' | translate"
      [customClass]="'!tw-shadow-none !tw-px-2'"
      [textClass]="'tw-font-[Rubik] tw-text-sm'"
      (clicked)="discardCase()"
    ></mh-core-lib-button>
  </section>
</section>
