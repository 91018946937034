export class Ota{
  id: number;
  name: string;
  scale: number;
  logoName: string;
  customerUrl:string = "";

  constructor(id, name, scale, logoName)
  constructor(id, name, scale, logoName,customerUrl)
  constructor(id?, name?, scale?, logoName?)
  constructor(id?, name?, scale?, logoName?,customerUrl?) {
    this.id = id;
    this.name = name;
    this.scale = scale;
    this.logoName = logoName;
    this.customerUrl = customerUrl;
  }

}
