import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpClientMH } from 'app/shared/utils/http-myhotel-client';

type param = number | string;

@Injectable()
export class PrecheckinGuestService {

  constructor(
    private httpDownload:HttpClientMH,
    private http: HttpClient,
  ) { }

  getForm(customerId: param) {
    const url=`${environment.prestayPath}customer/${customerId}/checkin/form`;

    return this.http.get(url)
  }

  getAnswers(guestId: param){
    const url=`${environment.prestayPath}guest/${guestId}/checkin`;

    return this.http.get(url)
  }

  downloadPrecheckin(customerId: param, guestId: param ){
    const url=`${environment.prestayPath}customer/${customerId}/guest/${guestId}/checkin/pdf`;
    this.httpDownload.downloadFile(url,`precheckin_${guestId}_${customerId}`);
    return this.httpDownload.getPDF();
  }

  downloadFile(customerId, key) {
    const url = `${environment.customerPath}customers/${customerId}/files/private?key=${key}`;
    const isPdf = false;
    this.httpDownload.downloadFile(url, key, isPdf)
    return this.httpDownload.getFile();
  }
}
