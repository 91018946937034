import {Component, Input, Output, EventEmitter } from '@angular/core';
import {UtilService}    from "../../../shared/util.service";
import {TrendsService} from "./trends.service";
import {Subscription, forkJoin}   from "rxjs";
import {Hotel}   from "../../../shared/model/index";
import { ChartsDataSet } from "../../../shared/utils/charts-data-set";
import {TranslateService} from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CustomersState } from 'app/states/customers/customers.state';
import { DateService } from 'app/shared/services/date.service';

@Component({
  selector: 'mh-inter-chain-trends',
  templateUrl: './trends.component.html',
  styleUrls: ['./trends.component.scss'],
  providers:[TrendsService, ChartsDataSet]
})


export class TrendsComponent{

  @Select(CustomersState.relativeCurrentChain) customersReady$: Observable<any[]>;

  @Input()
  chain : Hotel[] = [];

  @Input()
  productId = 1;

  @Input()
  showAreas = true;

  @Input()
  showFilters = true;

  @Input()
  trend = 'nps';

  @Input()
  showDefaultLegends = false;

  @Input()
  isCorporate = true;

  @Input()
  currentDates = null;

  segmentedBy = 'months';

  actives:any[] = [];
  data;

  areas: any[] = [];
  areaSelected: any = '';
  waiting = true;

  actions   = {
    nps :          'getHistoricNPS',
    ranking:       'getTripAdvisor',
    pms:           'getHistoricPMS',
    sent:          'getEmailsArea',
    answered:      'getEmailsArea',
    rate:          'getEmailsArea',
    email_surveys: 'getEmailsArea',
    open_surveys:  'getEmailsArea',
    ranking_areas: 'getHistoricArea',
    total_guests: 'getHistoricPMS',
    promoters: 'getHistoricNPS',
    passives: 'getHistoricNPS',
    detractors: 'getHistoricNPS',
    survey_total_score: 'getScore',
    semantic_index: 'getSemanticIndex',
  };

  @Output()
  isReady: EventEmitter<boolean> = new EventEmitter();
  customersStateSubscribe: Subscription;

  // lineChart
  parserData = {};
  parserDataLabels:string[];
  lineChartData:Array<any> = [];
  lineChartLabels:Array<any> = [];
  lineChartColors:Array<any> = [];

  polarChartData:Array<any> = [];

  lists: string[] = [];
  areasFound = {};
  surveySubscribe: Subscription;
  firstCall = true;

  averageLabel: string;

  constructor(
    private utilService: UtilService,
    private trendsService:TrendsService,
    private translate: TranslateService,
    private chartsDataSet: ChartsDataSet,
    private store: Store,
    private dateService: DateService
  ) {

    this.surveySubscribe = this.utilService.isCurrentSurveyChanged().subscribe(surveId => {
      if ( this.currentDates ) {
        this.init();
      }
    })

    this.translate.get(['commons.average_corporate']).subscribe((result) => {
      this.averageLabel = result['commons.average_corporate'];
    })

    this.customersStateSubscribe = this.customersReady$.subscribe(customers => {
      if ( this.currentDates ) {
        this.init();
      }
    });
  }

  ngOnInit(): void {  }

  ngOnChanges({currentDates}): void {
    if ( currentDates ) {
      this.init();
    }
  }


  ngOnDestroy(){
    this.surveySubscribe.unsubscribe();
    this.customersStateSubscribe.unsubscribe();
  }

  reset(){
    this.data = {};
    this.areas  = [];
    this.areaSelected = null;
    this.actives = this.chain.map(x=>x.id);
  }

  init(){
    this.reset();
    this.setList();
    this.waiting = true;
    this.isReady.emit(false);
    this.segmentedBy = this.dateService.getSegmentation();
    this.loadData();
  }

  setList(){
    this.lists = Object.keys(this.actions);
    if(!this.showAreas) {
      this.lists = this.lists.filter((trend) => trend !== 'ranking_areas');
    }
    if(this.utilService.customerIsRetail || this.utilService.customerIsClinic){
      this.lists = this.lists.filter(x=>x!=='ranking')
    }
  }

  loadData(){
    const language  = this.utilService.getLanguageId();
    const survey    = this.utilService.getCurrentSurvey(this.chain.map(x=>parseInt(x.id)));
    const surveyIds = survey?.ids || '';
    const version = survey?.survey[0] && survey?.survey[0].version;

    const chain     = survey?.chain || this.chain.map(x=>x.id).join(',');

    const customers = !this.isCorporate ?
      chain :
      (this.chain ? this.chain.map((c) => c.id).join(',') : this.store.selectSnapshot(CustomersState.relativeCurrentChain).map((c) => c.id).join(','));

    if (customers.length) {
      forkJoin([
        this.trendsService[this.actions[this.trend]](this.date, customers,this.productId, language, this.segmentedBy, surveyIds, version)
      ]).subscribe((result:any[])=>{
        this.data = result[0];
        if(this.trend === 'ranking_areas') this.calculateTotalsAreas();
        this.setGraph();
        this.isReady.emit(true);
        this.waiting = false;
        this.firstCall = false;
      },error=>{
        console.error(error);
      })
    }

  }

  canLoadWidget(){
    if(!this.utilService.currentUser.isSuperAdmin()) return true;
    if(this.utilService.currentUser.fakeUser) return true;
    return false;
  }

  setGraph(){

    const dataValue = this.trend ==='ranking' ? this.data : this.data['values'] ;
    const dataLabels      = this.utilService.getIntervalLabels(this.utilService.stringDateToDate(this.currentDates.startDate),this.utilService.stringDateToDate(this.currentDates.endDate),this.segmentedBy);
    this.parserData = {}
    dataValue.forEach(data=>{
      if(this.actives.includes(data.customer_id)){
        const customer: any = this.chain.find(x=>parseInt(x.id)===data.customer_id);
        this.parserData[customer?.name] = {data: this.getDataTrend(data), selector: data.customer_id }
      }
    });

    if(this.data['average'] && this.chain.length > 1){
      this.parserData[this.averageLabel] = {data: this.getDataTrend(this.data['average'], true), selector: 'average' }
    }

    this.parserDataLabels = dataLabels.map((x: any) => x.format);
    const lineDataSet      = this.chartsDataSet.getLineData(this.parserData, this.parserDataLabels);

    this.lineChartData     = this.orderDataSet(lineDataSet["data"]);
    this.lineChartLabels   = lineDataSet["labels"];
    this.lineChartColors   = this.orderColorsDataSet(lineDataSet["colors"]);

  }

  orderDataSet(lineChartData) {
    lineChartData.sort((x, y) => {
      return x.isAverage ? -1 : 1;
    });
    return lineChartData;
  }

  orderColorsDataSet(colors) {
    colors.sort((x, y) => {
      return x.borderDash > 0 ? 1 : -1;
    });
    return colors;
  }

  calculateTotalsAreas(){
    const dataPeriods = this.data.values.map(x=>x.periods);
    dataPeriods.forEach(periods=>{
      this.getAveragePeriods(periods);
    })

    const dataAverage = this.data.average.periods
    this.getAveragePeriods(dataAverage)

  }

  getAveragePeriods(periods){
    periods.forEach(period=>{
      const data = period.data.ranking_areas;
      let weightedSum: any   = null;
      let qtyAnswer     = null;

      data.areas.forEach(area=>{
        const weightVal = area.note && area.answers ? area.note * area.answers : null;
        weightedSum     = weightVal ? weightedSum + weightVal : weightedSum;
        qtyAnswer       = area.answers ? qtyAnswer + area.answers : qtyAnswer;
      })

      data.areas.push({
        id_area: -1,
        note: weightedSum && qtyAnswer ? weightedSum / qtyAnswer : null,
        answers: qtyAnswer
      })

    })
  }

  setSelectlValue(option, key){
    if(key==='areas'){
      this.areaSelected = option.value;
      this.setGraph();
    }else{
      this[key] = option.value;
      this.init();
    }
  }

  filterCustomers($event){
    this.actives = $event;
  }

  getDataTrend(data, isAverage=false){
    let keyData = this.trend;

    if(keyData==='pms'){
      return data.periods.map(x=>{
        const percentage = x.data[keyData].percentage;
        return percentage ? percentage.value : null;
      });
    } else if (keyData === 'total_guests') {
      return data.periods.map((period: any) => period.data?.pms?.total_count ?? null);
    } else if(keyData==='ranking_areas'){
      const allData = data.periods.map(x=>x.data[keyData]);
      if(this.areas.length===0){
        let areas = [];
        allData.forEach(period=>{
          areas = areas.concat(period.areas.map(x=>x.id_area));
        })
        this.areas        = this.utilService.uniqArray(areas).sort((a, b) => a - b);
        this.areaSelected = this.areas[0];
      }
      return allData.map(x=>x.areas.find(a=>a.id_area===this.areaSelected)).map(x=>{
        return x && x['note'] ? x.note : null
      });
    } else if (['rate', 'answered', 'sent', 'email_surveys', 'open_surveys'].includes(keyData)) {
      return data.periods.map((period) => {
        let rate;
        if (keyData === 'sent') {
          rate = period?.data?.rates?.email_surveys[keyData] || null;
        } else if (['email_surveys', 'open_surveys'].includes(keyData)) {
          rate = period?.data?.rates[keyData]?.rate || null
        } else {
          rate = period?.data?.rates[keyData] || null
        }

        return rate?.actual || null;
      });
    } else if (['promoters', 'passives', 'detractors'].includes(keyData)) {
      return data.periods.map((period: any) => period.data?.details?.[keyData]?.count ?? null);
    } else if (keyData === 'survey_total_score') {
      return data.periods.map((period: any) => period.data?.qualification ?? null);
    } else if (keyData === 'semantic_index') {
      return data.periods.map((period: any) => {
        const semanticIndex = period.data?.semantic_index_data.find(
          semanticIndexByProduct => semanticIndexByProduct.product_id === this.productId,
        );
        return semanticIndex?.semantic_index ?? null
      });
    } else {
      return data.periods.map(x=>x.data[keyData]);
    }
  }

  get date() {
    return {
      startDate: this.currentDates.startDate,
      endDate: this.currentDates.endDate
    }
  }

}
