import { Component, Inject } from '@angular/core';
import { DialogRef } from '../modal/dialog-ref';
import { CoreLibModalComponent } from '../modal/modal.component';
import { DIALOG_DATA } from '../modal/dialog-tokens';

@Component({
  selector: 'mh-core-lib-empty-modal',
  templateUrl: './empty-modal.component.html',
  styleUrls: ['./empty-modal.component.scss'],
})
export class CoreLibEmptyModalComponent extends CoreLibModalComponent {
  constructor(protected dialogRef: DialogRef, @Inject(DIALOG_DATA) public data: any) {
    super(dialogRef, data);
  }
}
