<section class="tw-my-5 tw-px-8 tw-flex tw-flex-row tw-justify-between" *ngIf="type === 'menu'">
  <h3 class="tw-text-lg	tw-font-semibold">{{ 'notifications.central.notifications' | translate }}</h3>
  <article>
    <mh-core-lib-button
      [isAction]="true"
      (click)="openActionMenu = !openActionMenu"
      customClass="!tw-p-0 !tw-w-2 !tw-h-fit !tw-bg-transparent !tw-shadow-none"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      ><i class="fas fa-ellipsis-v tw-text-gray-500 tw-cursor-pointer"></i
    ></mh-core-lib-button>

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="openActionMenu"
      [cdkConnectedOverlayOffsetY]="35"
      [cdkConnectedOverlayPanelClass]="'tw-mr-3'"
      (overlayBackdropClick)="openActionMenu = false"
      (overlayOutsideClick)="openActionMenu = false"
    >
      <ul class="tw-bg-white tw-rounded-lg tw-shadow-5 tw-relative tw-py-4 tw-px-5" id="overlayNotificationItem">
        <li class="tw-cursor-pointer">
          <a (click)="openMuteNotificationsModal()">
            {{ 'notifications.central.mute_notifications' | translate }}
          </a>
        </li>
        <li class="tw-cursor-pointer">
          <a (click)="markAllAsRead()">
            {{ 'notifications.central.mark_all_as_read' | translate }}
          </a>
        </li>
        <li class="tw-cursor-pointer">
          <a [routerLink]="['/notifications-central']" (click)="closeCentral()">
            {{ 'notifications.central.history' | translate }}
          </a>
        </li>
      </ul>
    </ng-template>
  </article>
</section>

<article class="tw-w-full">
  <mh-core-lib-tabs [className]="'!tw-px-10 !tw-py-3 tw-w-1/2 tw-text-center'" [selectedTab]="tabIndex" (tabChanged)="handleChangeTab($event)">
    <mh-core-lib-tab [title]="('notifications.central.alerts' | translate) + ((type === 'menu') ? ' (' + (alertsData?.length > 0 ? '' + alertsData[0]?.total_unread : '0') + ')': '')">
      <ng-container *ngIf="alertsData?.length > 0; else noAlertsData">
        <ng-container *ngFor="let alert of alertsData; let last = last; trackBy: trackByFn;">
          <div class="tw-cursor-pointer">
            <mh-core-lib-notifications
              [data]="alert"
              [type]="type"
              typeNotification='alert'
              (emitNotificationData)="handleNotificationStatus($event)"
              (emitStatusChanged)="handleNotificationStatus($event)"
            ></mh-core-lib-notifications>
            <span
              class="tw-block tw-w-[90%] tw-bg-gray-400 tw-mx-auto tw-h-px -tw-mt-px"
              [ngClass]="{ 'tw-hidden': last }"
            ></span>
          </div>
        </ng-container>
        <div *ngIf="hasMoreAlerts" class="mt-4 tw-text-center">
          <mh-core-lib-button
            [isSecondary]="true"
            [height]="32"
            [text]="'notifications.central.show_more' | translate"
            [icon]="'far fa-angle-down'"
            customIconClass="tw-align-text-top"
            (click)="emitShowMore.emit('alert')"
          ></mh-core-lib-button>
        </div>
      </ng-container>
      <ng-template #noAlertsData>
        <div class="tw-py-6">
          <p class="tw-text-center" [innerHTML]="('notifications.central.activate_alerts' | translate)"></p>
        </div>
      </ng-template>
    </mh-core-lib-tab>
    <mh-core-lib-tab [title]="('notifications.central.news' | translate) + ((type === 'menu') ? ' (' + (newsData?.length > 0 ? '' + newsData[0]?.total_unread : '0') + ')' : '')">
      <ng-container *ngIf="newsData?.length > 0; else noNotifications">
        <div *ngFor="let comunication of newsData; let last = last; trackBy: trackByFn">
          <mh-core-lib-notifications
            [data]="comunication"
            [type]="type"
            typeNotification='new'
            (emitNotificationData)="handleNotificationStatus($event)"
            (emitStatusChanged)="handleNotificationStatus($event)"
          ></mh-core-lib-notifications>
          <span
            class="tw-block tw-w-[90%] tw-bg-gray-400 tw-mx-auto tw-h-px -tw-mt-px"
            [ngClass]="{ 'tw-hidden': last }"
          ></span>
        </div>
        <div *ngIf="hasMoreNews" class="mt-4 tw-text-center">
          <mh-core-lib-button
            [isSecondary]="true"
            [height]="32"
            [text]="'notifications.central.show_more' | translate"
            [icon]="'far fa-angle-down'"
            customIconClass="tw-align-text-top"
            (click)="emitShowMore.emit('new')"
          ></mh-core-lib-button>
        </div>
      </ng-container>
      <ng-template #noNotifications>
        <div class="tw-m-8">
          <p class="tw-w-full tw-text-center tw-font-medium">{{ 'notifications.central.no_notifications' | translate }}</p>
        </div>
      </ng-template>
    </mh-core-lib-tab>  
  </mh-core-lib-tabs>  
</article>  


