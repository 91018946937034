import { Pipe, PipeTransform } from '@angular/core'

import { Survey } from "app/shared/model/index";
import { FollowupOnsiteGuestFilter } from 'app/states/followup-onsite/followup-onsite.state';

@Pipe({ name: 'followupOnsiteChannelFilter' })
export class FollowupOnsiteChannelFilterPipe implements PipeTransform {

  transform(value: Survey[], filters: FollowupOnsiteGuestFilter): Survey[] {
    if(filters.channels) {
      const filtered = value.filter(survey => !!filters.channels.find(channel => channel.id === survey.integration_channel.id) )
      if(filtered?.length > 0) return filtered
      else return value
    } else
      return value
  }
}
