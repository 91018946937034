<mh-box-element class="box" [title]="('followup.summary.summary-survey.title' | translate)">

  <div class="content_summary" [class.waiting]="waiting">
    <div class="flex-row d-flex no-gutters justify-content-around">
      <div class="mt-3 d-flex justify-content-center" (mouseover)="setTooltip('rate')" [ngbTooltip]="tooltip">
        <ng-container *ngIf="!waiting">
          <div class="mt-1 icon">
            <i class="fas fa-tachometer"></i>
          </div>
          <div class="mx-3 number">{{ data?.rate !== '-' ? (data.rate.actual | number:'1.0-0') : '-' }}%</div >
          <div class="title" [innerHtml]="translate + 'title_box.rate' | translate"></div>
        </ng-container>
        <div class="waiting-response-rate" *ngIf="waiting"></div>
      </div>

    </div>

    <div class="flex-row mt-3 d-flex justify-content-around">

      <ng-container *ngFor="let metric of metrictsWithoutRate; let i = index">
        <div
        class="d-flex flex-column"
        [style.filter]="data && !data[metric]['active'] ? ('blur(5px)' | safeStyle) : null"
        [ngbTooltip]="data && !data[metric]['active'] ? tootlipNotActive : null"
        (mouseover)="setTooltip(metric)">
          <ng-container *ngIf="!waiting">
            <div class="flex-row ml-2 d-flex" [placement]="metric === 'email_surveys' ? 'end' : 'start'">
              <div class="survey-percent-type">{{ data[metric] && data[metric]['rate'] !== '-' ? (data[metric]['rate'].actual | number:'1.0-0') + '%': '-'}}</div>
              <div class="survey-percent-label" [ngbTooltip]="data[metric]['active'] ? tooltip : null">{{(translate + 'title_box.' + metric | translate )}}</div>
            </div>
            <div class="flex-row mt-1 submetrics d-flex justify-content-around">
              <ng-container *ngFor="let submetric of subMetrictsWithoutRate">
                <div class="d-flex flex-column">
                  <span class="survey-status-number">{{ (data[metric][submetric]?.actual | shortNumber ) || 0 }}</span>
                  <span class="survey-status-label">{{ (translate + 'title_box.' + submetric | translate ) }}</span>
                </div>
              </ng-container>
            </div>
            <div class="waiting-metric" *ngIf="waiting"></div>
          </ng-container>
          <div class="waiting-metric" *ngIf="waiting"></div>
        </div>
          <div class="separator-rate" *ngIf="i === 0 && !waiting"></div>
      </ng-container>
    </div>
  </div>
</mh-box-element>

<ng-template #tooltip>
  <div [innerHtml]="(translate + 'tooltips.' + tooltipLabel | translate:paramsTooltip)"></div>
</ng-template>

<ng-template #tootlipNotActive>
  <div [innerHtml]="(translate + 'tooltips.not_active.' + currentTooltip | translate)"></div>
</ng-template>
