import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  public modalAlertSubject = new Subject<string>();
  modalBussy = false;
  type: boolean;
  _responseAlert = '';

  checkModalAlert(from) {
    this.modalAlertSubject.next(from);
  }

  hasModalAlerts(): Observable<any> {
    return this.modalAlertSubject.asObservable();
  }

  setTypeAlert(value) {
    this.type = value;
  }

  getTypeAlert() {
    return this.type;
  }

  setResponseAlert(value) {
    this._responseAlert = JSON.stringify(value);
    localStorage.setItem('responseAlert', JSON.stringify(value));
  }

  getResponseAlert(): any {
    return localStorage.getItem('responseAlert');
  }

  deleteResponseAlert() {
    localStorage.removeItem('responseAlert');
  }
}
