import { Component } from '@angular/core';
import {UtilService} from 'app/shared/util.service';
import { Hotel } from 'app/shared/model';
import { DateService } from 'app/shared/services/date.service';
import { Router } from '@angular/router';

@Component({
  selector: 'mh-followup-executive-section',
  templateUrl: './followup-executive.component.html',
  styleUrls: ['./followup-executive.component.scss']
})
export class FollowupExecutiveSectionComponent{

  productId = 1;

  filters = [
    'nps',
    'area',
    'sent_emails',
    'email_rate',
    'rate',
    'answered',
    'email_surveys',
    'open_surveys',
    'semantic_positive',
    'semantic_negative',
    'semantic_index'
  ];

  fidelityPermisions:string[] = [];
  chain: Hotel[] = [];

  constructor(
    private utilService: UtilService,
    private dateService: DateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getProductById();
    this.chain = this.utilService.getInnerChain(false,'followup');
    this.fidelityPermisions = this.utilService.getFidelityConfigurationsByProduct('interchain', 'interchain_followup');
  }

  getProductById() {
    this.productId = this.router.url.includes('followup') ? 1 : 2;
  }


  handleDatepickerUpdated($event) {
    this.dateService.setCurrentFilter($event.currentFilter);
    this.dateService.setCurrentDates($event.currentDates)
  }

  get currentDates() {
    return this.dateService.currentDates;
  }

  get currentFilterDate() {
    return this.dateService.currentFilter;
  }

  get reportSlug() {
    return this.productId === 1 ? 'corporative_followup_ejecutive_report' : 'corporative_onsite_ejecutive_report';
  }
}
