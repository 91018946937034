import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, SessionService } from '../_services/index';
import { Hotel } from '../shared/model/hotel';
import { User } from '../shared/model/user.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UtilService } from '../shared/util.service';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../configuration/profile/profile.service';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CustomerService } from 'app/shared/customer.service';
import { DemographyService } from 'app/online/demography/demography.service';
import { AdminAlertsService } from 'app/admin/alerts/admin-alerts.service';
import { NotificationsService } from 'app/notifications/notifications.service';
import { FilterDateService } from 'app/shared/filters/date/filter-date-service';
import { WaitFullComponent } from 'app/material/widgets/elements/waiting/wait_full/wait_full.component';
import { ErrorCodes } from 'app/shared/errors.enums';
import { QueryParams } from './models/query-params.model'
import { NavbarService } from 'app/_services/navbar.service';

@Component({
  moduleId: module.id,
  templateUrl: 'login.component.html',
  styleUrls: ['../../assets/scss/login/login.scss'],
  providers: [ProfileService, CustomerService, DemographyService],
})
export class LoginComponent implements OnInit {
  MY_HOTEL_ID = 12;
  errorCodes = ErrorCodes;
  model: any = {};
  loading = false;
  returnUrl: string;
  jwtHelper: JwtHelperService = new JwtHelperService();
  tranlsates;
  currentLanguage = 'es';
  availableLanguages: string[] = [];
  queryParams;
  demoUrl;
  needHelpUrl;
  customerParam;
  inputType = 'password';
  remainingAttempts = 3;
  displayErrorCredentials = false;
  errorMessage = '';

  @ViewChild('waitFull', { static: true })
  waitFullComponent: WaitFullComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sessionService: SessionService,
    private authenticationService: AuthenticationService,
    private utilService: UtilService,
    private translate: TranslateService,
    private customerService: CustomerService,
    private demographyService: DemographyService,
    private profileService: ProfileService,
    private adminAlertsService: AdminAlertsService,
    private notificationsService: NotificationsService,
    private datesFilter: FilterDateService,
    private navbarService: NavbarService,
  ) {
    this.availableLanguages = this.utilService.translateLanguages;
    this.route.queryParams.subscribe((params: QueryParams) => {
      this.currentLanguage = params.language || this.utilService.getCurrentLanguage();
      this.customerParam = params.customer;
      this.setLanguage(this.currentLanguage);
      if (params.token_id && params.user_id) {
        this.autoLogin(params.token_id, params.user_id);
      }
    });
  }

  ngOnInit() {
    // reset login status
    this.authenticationService.logout();
    this.navbarService.unsubscribeNavigationEvent();
    this.sessionService.removeCurrentHotelPSConfig();
    // get return url from route parameters or default to '/'

    this.queryParams = this.route.snapshot.queryParams;
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.translate.get(['errors.login.invalid_value', 'errors.login.something_went_wrong']).subscribe((res: string) => {
      this.tranlsates = res;
    });
    this.route.queryParams.subscribe((params: QueryParams) => {
      if (!params.token_id) {
        this.waitFullComponent.setReady();
      }
    });
  }

  isLanguage(iso) {
    return iso === this.utilService.getCurrentLanguage();
  }

  setLanguage(iso) {
    this.setDemoUrl(iso);
    this.setNeedHelpUrl(iso);
    this.utilService.ga('language', 'click', iso);
    this.utilService.setCurrrentLanguage(iso);
  }

  setDemoUrl(iso) {
    this.demoUrl = `${environment.widgets}solicita-tu-demo/`;
    if (iso === 'en') this.demoUrl = `${environment.widgets}en/request-a-demo/`;
    if (iso === 'pt') this.demoUrl = `${environment.widgets}pt/solicite-um-contato/`;
  }

  setNeedHelpUrl(iso) {
    this.needHelpUrl = `${environment.widgets}necesitas-ayuda/`;
    if (iso === 'en') this.needHelpUrl = `${environment.widgets}en/need-help/`;
    if (iso === 'pt') this.needHelpUrl = `${environment.widgets}pt/precisa-de-ajuda/`;
  }

  trackForgot() {
    this.utilService.ga('forgot-password', 'click', this.currentLanguage);
  }

  ngOnchange() {}

  async autoLogin(tokenId: string, userId: string) {
    try {
      const res: { code: number; refreshToken: string; token: string } = await this.authenticationService
        .loginFromTokenId(userId, tokenId)
        .toPromise();
      const jwt = res.code !== 200 ? false : res;
      if (jwt) {
        localStorage.setItem('currentJWT', JSON.stringify(jwt));
        this.sessionService.removeCurrentHotelPSConfig();
        this.afterLogin();
        this.waitFullComponent.setReady();
      } else {
        this.loading = false;
        this.handleInvalidCredentials();
        this.waitFullComponent.setReady();
      }
    } catch (error) {
      console.error(error);
      this.waitFullComponent.setReady();
    }
  }

  login() {
    this.loading = true;

    this.authenticationService.login(this.model.username, this.model.password).subscribe(
      (response: any) => {
        const recoveryToken = response?.recoveryToken;
        if (recoveryToken) {
          this.router.navigate(['/recovery'], {
            queryParams: {
              token: recoveryToken,
              language: this.utilService.getCurrentLanguage(),
            },
          });
        }

        const jwt = response['message'] ? false : response;
        if (jwt) {
          localStorage.setItem('currentJWT', JSON.stringify(jwt));
          this.sessionService.removeCurrentHotelPSConfig();
          this.afterLogin();
        } else {
          this.loading = false;
          this.handleInvalidCredentials();
        }
      },
      (error) => {
        this.errorLogin(error, 'credentials');
      },
    );
  }

  afterLogin() {
    const currentJWT: any = localStorage.getItem('currentJWT');
    const data = this.jwtHelper.decodeToken(JSON.parse(currentJWT).token);

    this.authenticationService.profile(data.user_details.id).subscribe(
      (profile: any[]) => {
        const currentUser = User.createFromApi(profile);

        this.checkLastHotelUsed(currentUser);

        this.getUserProperties(currentUser).subscribe(async () => {
          const lastHotelUsed: any = localStorage.getItem('lastHotelUsed');
          const currentHotel = JSON.parse(lastHotelUsed);

          const customerId = currentHotel.id.toString();

          if (this.utilService.getAferCustomerId() !== customerId) {
            this.adminAlertsService.getAlert(customerId, this.utilService.getLanguageId()).subscribe((response) => {
              this.notificationsService.setResponseAlert(response);
            });

            this.utilService.setAfterCustomerId(customerId);
          }

          this.utilService.clearChache();
          this.utilService.currentUser = currentUser;
          this.utilService.customerChanged(currentHotel, !currentUser.isSuperAdmin());
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
          // this feels weird here, but is good enough for now
          this.datesFilter.initDates();

          const url = `${this.returnUrl}`;
          this.router.navigate([url], { queryParams: this.queryParams });
          this.utilService.setCurrrentLanguage(currentUser.language);
          this.utilService.refreshHeader();
          this.utilService.ga('user', currentUser.email);
        });
        this.utilService.ga('login', 'click', 'success');
      },
      (error) => {
        this.errorLogin(error, 'profile');
      },
    );
  }

  errorLogin(error, type) {
    this.utilService.ga('login', 'click', 'error_' + type);

    if (error.details.includes(String(this.errorCodes.INVALID_CREDENTIALS))) {
      const attemps = error.message;
      const regex = /(\d+) attempts left/;
      const match = regex.exec(attemps);
      if (match?.length > 0) {
        const attemptsLeft = parseInt(match[1]);
        this.remainingAttempts = attemptsLeft;
      }
      return this.handleInvalidCredentials();
    }
    if (error.details.includes(String(this.errorCodes.BLOCKED_USER))) {
      return this.handleBlockedUser();
    }
    this.loading = false;
  }

  handleInvalidCredentials() {
    if (this.remainingAttempts === 0) {
      return this.handleBlockedUser();
    }
    this.errorMessage = 'errors.login.invalid_value';
    this.displayErrorCredentials = true;
    this.loading = false;
    return;
  }

  handleBlockedUser() {
    this.errorMessage = 'errors.login.blocked';
    this.displayErrorCredentials = true;
    this.loading = false;
    return;
  }

  getUserProperties(user: User): Observable<any> {
    const lastHotelUsed: any = localStorage.getItem('lastHotelUsed');
    const currentHotel = JSON.parse(lastHotelUsed);
    // logic for getting hotel_id from nick_id should be somewhere here
    const hotelId = this.customerParam || (currentHotel && currentHotel.id) || 12;

    const commonsObservable = [
      this.demographyService.reviewNpsScale(),
      this.authenticationService.fidelityConfiguration(hotelId),
      this.customerService.findHotel(hotelId),
    ];

    // here I should do something with the find/fetch for the nick_id
    const superAdminObservables = !user.isSuperAdmin()
      ? []
      : [this.profileService.getSuperAdminData(user.id.toString())];

    return forkJoin([...commonsObservable, ...superAdminObservables]).pipe(
      map(([npsScale, fidelityConfig, rawHotel, adminData]) => {
        user.superAdminProperties = adminData && adminData[0];

        if (hotelId !== this.MY_HOTEL_ID && rawHotel && rawHotel['id']) {
          const hotel = Hotel.createFromApi(rawHotel);

          if (currentHotel.id !== hotel.id) this.setLastHotelUsed(hotel);
        }

        this.readyProperties(npsScale, fidelityConfig);
      }),
    );
  }

  readyProperties(nps, fidelityConfiguration) {
    this.setFormulaNPS(nps);
    this.sessionService.setFidelityProperties(fidelityConfiguration);
  }

  setFormulaNPS(formula) {
    this.utilService.setScoreNps(formula);
  }

  checkLastHotelUsed(currentUser: any) {
    const lastHotel: Hotel = this.getLastHotelFromStorage();
    const hotelToSet: Hotel =
      lastHotel && this.userHasAccess(currentUser, lastHotel) ? lastHotel : currentUser.getAccessHotels()[0];

    // What happens when superAdmin? getAccessHotels won't have it
    const hotelFromParams = currentUser.getAccessHotels().find((x) => parseInt(x.id) === parseInt(this.customerParam));

    const finalHotel = hotelFromParams || hotelToSet;

    // I don't like methods setting AND returning shit
    this.setLastHotelUsed(finalHotel);

    return finalHotel;
  }

  userHasAccess(currentUser: User, lastHotel: Hotel): boolean {
    return (
      currentUser.isSuperAdmin() ||
      !!currentUser.getAccessHotels().some((x: Hotel) => parseInt(x.id) === parseInt(lastHotel.id))
    );
  }

  getLastHotelFromStorage() {
    try {
      const lastHotelUsed: any = localStorage.getItem('lastHotelUsed');
      return JSON.parse(lastHotelUsed);
    } catch (e) {
      localStorage.removeItem('lastHotelUsed');
    }
  }

  getErrorType(error) {
    this.translate.get(['errors.login.invalid_value', 'errors.login.something_went_wrong']).subscribe((res: string) => {
      this.tranlsates = res;
    });
    if (error || error._body.includes('INVALID_VALUE')) return 'invalid_value';
    return 'something_went_wrong';
  }

  setLastHotelUsed(hotel) {
    localStorage.setItem('lastHotelUsed', JSON.stringify(hotel));
  }

  togglePasswordType() {
    this.inputType = this.inputType === 'password' ? 'text' : 'password';
  }
}
