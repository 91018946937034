import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Guest, Survey } from 'app/shared/model';
import { UtilService } from 'app/shared/util.service';
import { FollowupOnsiteState } from 'app/states/followup-onsite/followup-onsite.state';
import { DateTime } from 'luxon';

@Component({
  selector: 'mh-core-lib-guest-zone',
  templateUrl: './core-lib-guest-zone.component.html',
  styleUrls: ['./core-lib-guest-zone.component.scss'],
})
export class CoreLibGuestZoneComponent {
  @Input() guest: Guest;
  @Input() survey: Survey;

  constructor(private utilService: UtilService, private store: Store, private translate: TranslateService) {}

  openWhatsapp(phone: string) {
    const msg = this.translate.instant('commons.guest_greetings');
    const url = `https://api.whatsapp.com/send?phone=${phone}&text=${msg}`;
    window.open(url, '_blank');
  }

  get stay() {
    const checkinDate = new Date(this.guest.checkin);
    const checkoutDate = new Date(this.guest.checkout);
    const luxonCheckin = DateTime.fromJSDate(checkinDate);
    const luxonCheckout = DateTime.fromJSDate(checkoutDate);
    const days = luxonCheckout.diff(luxonCheckin, 'days').toObject().days;
    return Math.round(days);
  }

  get surveyName() {
    const surveys = this.store.selectSnapshot(FollowupOnsiteState.surveys);
    const selectedSurvey = surveys.find((s) => s.id === this.survey?.survey_id);
    const translates = selectedSurvey.translates;
    const lang = this.utilService.currentLanguage;
    return translates.find((translate) => translate.language.code === lang)?.title;
  }

  get isCasino() {
    return this.utilService.customerIsCasino;
  }

  get isRestaurant() {
    return this.utilService.customeriIsOnlyRestaurant;
  }
}
