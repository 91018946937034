import { Pipe, PipeTransform } from '@angular/core'

import { Survey } from "app/shared/model/index";
import { FollowupOnsiteGuestFilter } from 'app/states/followup-onsite/followup-onsite.state';

@Pipe({ name: 'followupOnsiteNPSFilter' })
export class FollowupOnsiteNPSFilterPipe implements PipeTransform {

  transform(value: Survey[], filters: FollowupOnsiteGuestFilter): Survey[] {
    if(filters.nps) {
      const filtered = value.filter(survey =>
        (survey.nps === 'promoter' && filters.nps.promoters) ||
        (survey.nps === 'passive' && filters.nps.passives) ||
        (survey.nps === 'detractor' && filters.nps.detractors) ||
        (!survey.nps && filters.nps.others)
      )
      if(!filters.nps.promoters && !filters.nps.passives && !filters.nps.detractors && !filters.nps.others) return value
      if(filtered?.length >= 0) return filtered
      else return value
    } else
      return value
  }
}
