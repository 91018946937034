import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  StoreCurrentDates,
  StoreCurrentFilter,
  StoreTrendCurrentDates,
  StoreTrendCurrentFilter,
} from 'app/states/date/date.actions';
import { DateState } from 'app/states/date/date.state';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor(private store: Store) {}

  setDateLocal(dates: any) {}

  getSegmentation(startDate = null, endDate = null) {
    const start = DateTime.fromISO(startDate || this.trendCurrentDates.startDate);
    const end = DateTime.fromISO(endDate || this.trendCurrentDates.endDate);
    const diff = end.diff(start, ['days']).toObject();
    if (diff.days >= 93) {
      return 'months';
    } else if (diff.days < 92 && diff.days > 31) {
      return 'weeks';
    } else if (diff.days <= 31) {
      return 'days';
    } else {
      return 'months';
    }
  }

  setCurrentDates(dates: any) {
    this.store.dispatch(new StoreCurrentDates(dates));
  }

  setCurrentFilter(filter: any) {
    this.store.dispatch(new StoreCurrentFilter(filter));
  }

  setTrendCurrentDates(dates: any) {
    this.store.dispatch(new StoreTrendCurrentDates(dates));
  }

  setTrendCurrentFilter(filter: any) {
    this.store.dispatch(new StoreTrendCurrentFilter(filter));
  }

  getPreviousPeriod(startDate, endDate) {
    // const filter = this.currentFilter.label;

    // const calculateByFixedDays = (days) => {
    //   const newISOEndDate = isoStartDate.minus({ days: 1 }).toISODate();
    //   const newISOStartDate = DateTime.fromISO(newISOEndDate).minus({ days }).toISODate();
    //   return {
    //     startDate: newISOStartDate,
    //     endDate: newISOEndDate,
    //   };
    // };
    // console.log(filter);
    // const isoStartDate = DateTime.fromISO(startDate);
    // const isoEndDate = DateTime.fromISO(endDate);
    // const diff = isoEndDate.diff(isoStartDate, 'days');
    // const labels = {
    //   // 'filters.dates.lastSevenDays': { days: 7 },
    //   'filters.dates.lastSevenDays': calculateByFixedDays(7),
    //   // 'filters.dates.lastThirtyDays': { days: 30 },
    //   'filters.dates.lastThirtyDays': calculateByFixedDays(30),
    //   'filters.dates.thisMonth': { days: diff.days }, // cuánto debería calcular hacia atrás, lo llevado hasta el mes o el mes completo?
    //   'filters.dates.lastMonth': { days: 30 },
    //   'filters.dates.lastThreeMonths': { days: 90 },
    //   'filters.dates.yearToDate': 'days',
    //   'filters.dates.custom': 'days',
    // };
    // const newISOEndDate = isoStartDate.minus({ days: 1 }).toISODate();
    // const newISOStartDate = DateTime.fromISO(newISOEndDate).minus({ days: diff.days }).toISODate();
    // return {
    //   startDate: newISOStartDate,
    //   endDate: newISOEndDate,
    // };
  }

  get currentDates() {
    return this.store.selectSnapshot(DateState.currentDates);
  }

  get currentFilter() {
    return this.store.selectSnapshot(DateState.currentFilter);
  }

  get trendCurrentDates() {
    return this.store.selectSnapshot(DateState.trendCurrentDates);
  }

  get trendCurrentFilter() {
    return this.store.selectSnapshot(DateState.trendCurrentFilter);
  }

  get currentYearPeriod() {
    return this.store.selectSnapshot(DateState.currentYearPeriod);
  }
}
