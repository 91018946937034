<div id="forgot-password">
  <div class="explian">{{'forgot_password.explain' | translate}}</div>
  <form class="general_set_form" (ngSubmit)="saveData()" [formGroup]="form" #formDir="ngForm">
    <div class="row no-gutters align-items-center">
      <ng-container *ngFor="let key of validates">
        <div class="col-12" >
          <div  class="form-group">
            <label for="{{key}}">{{'models.user_profile.'+key | translate}}</label>
            <input type="{{getInputType(key)}}" id="{{key}}" class="form-control"  formControlName="{{key}}" >
            <small class="alert-danger">
              <ng-container  *ngIf="form.controls[key].invalid && (form.controls[key].dirty || form.controls[key].touched)" >
                <div *ngIf="form.controls[key].errors.required">
                  {{'forms.attributes.not_empty' | translate}}
                </div>
                <div *ngIf="form.controls[key].errors.minlength">
                  {{'forms.attributes.min_lenght' | translate:({length: form.controls[key].errors.minlength.requiredLength})}}
                </div>
                <div *ngIf="form.controls[key].errors.maxlength">
                  {{'forms.attributes.max_lenght' | translate:({length: form.controls[key].errors.maxlength.requiredLength})}}
                </div>
              </ng-container>
            </small>
          </div>
        </div>
      </ng-container>

      <div class="col-12">
        <div class="bottom">
          <button type="submit" [disabled]="form.invalid" class="btn btn-orange">{{'forgot_password.recovery_password' | translate}}</button>
        </div>
        <div class="wait" [class.show]="waiting">
          <mh-spinner-classic></mh-spinner-classic>
        </div>
        <div class="go_home">
          <a routerLink="/login">{{'forgot_password.go_home' | translate}}</a>
        </div>
      </div>
    </div>
  </form>
</div>