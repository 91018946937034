import { Component, OnInit, Input } from '@angular/core';
import { Hotel } from 'app/shared/model/hotel';
import { Select, Store } from '@ngxs/store';
import { FollowupPropertiesService } from '../../properties/followup-properties.service';
import { Observable, Subscription } from 'rxjs';
import { UtilService } from 'app/shared/util.service';
import { CustomersState } from 'app/states/customers/customers.state';

@Component({
  selector: 'mh-chain-followup-semantic-index',
  templateUrl: './chain-followup-semantic-index.component.html',
  styleUrls: ['./chain-followup-semantic-index.component.scss'],
  providers: [FollowupPropertiesService]
})
export class ChainFollowupSemanticIndexComponent implements OnInit {
  @Input() 
  productId: number;
  @Input() 
  startDate: string;
  @Input() 
  endDate: string;
  @Input()
  chain: Hotel[] = [];

  surveySubscribe: Subscription;
  customersStateSubscribe: Subscription;
  @Select(CustomersState.relativeCurrentChain) customersReady$: Observable<any[]>;

  public waiting: boolean = false;
  public semanticIndex: number = 0;
  public sentimentsScore: any[] = [];
  public totalMentions: number = 0;

  constructor(
    private store: Store,
    private utilService: UtilService,
    private followupPropertiesService: FollowupPropertiesService) {
      this.surveySubscribe = this.utilService.isCurrentSurveyChanged().subscribe(surveId => {
        if ( this.startDate || this.endDate ) {
          this.init();
        }
      })
      this.customersStateSubscribe = this.customersReady$.subscribe(customers => {
        if ( this.startDate || this.endDate ) {
          this.init();
        }
      });
    }

  ngOnChanges({startDate, endDate}) {
    if ( startDate || endDate ) {
      this.init();
    }
  }

  ngOnInit(): void {
  }

  init() {
    this.waiting = true;
    const date = {
      startDate: this.startDate,
      endDate: this.endDate
    }
    const survey    = this.utilService.getCurrentSurvey(this.chain.map(x=>parseInt(x.id)));
    const surveyIds = survey.ids;

    const customers = this.chain ?this.chain.map((c) => c.id).join(',') : this.store.selectSnapshot(CustomersState.relativeCurrentChain).map((c) => c.id).join(',');
    
    this.followupPropertiesService.getSemanticIndex(date, customers, this.productId, surveyIds).subscribe((response:any)=>{
      const { semantic_index, sentiment_scores, total_mentions} = response.average;
      this.semanticIndex = semantic_index.actual;
      this.totalMentions = total_mentions.actual;
      this.sentimentsScore = sentiment_scores;
      this.waiting = false;
    });
  }

  ngOnDestroy(){
    this.surveySubscribe.unsubscribe();
    this.customersStateSubscribe.unsubscribe();
  }

}
