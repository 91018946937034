import { Component, OnInit, Input } from '@angular/core';
import { UtilService } from 'app/shared/util.service';
import { InvoiceService } from '../services/invoice.service';

@Component({
  selector: 'mh-total-amount-invoice-widget',
  templateUrl: './total-amount-invoice-widget.component.html',
  styleUrls: ['./total-amount-invoice-widget.component.scss']
})
export class TotalAmountInvoiceWidgetComponent implements OnInit {

  @Input() 
  currentDates = null;

  @Input() 
  currentHotelId: string = null;

  constructor(private invoiceService: InvoiceService, private utilService: UtilService) { }

  public amountInfo = {
    amount: 0,
    symbol: '$',
    code: 'USD'
  };
  public amountStateColor: string = null;
  public waiting: boolean = false;

  ngOnInit() {}

  ngOnChanges({currentDates}) {
    if(currentDates) {
      this.getTotalAmount();
    }
  }

  async getTotalAmount() {
    this.waiting = true;
    const payload = {
      start_date: this.currentDates.startDate,
      end_date: this.currentDates.endDate
    }

    try {
      const res = await this.invoiceService.getTotalAmount(this.currentHotelId, payload).toPromise();
      const { owed_amount, fidelity_invoice_status, currency_symbol, currency_code } = res;
      this.amountInfo = {
        amount: owed_amount,
        symbol: currency_symbol,
        code: currency_code
      };
      this.amountStateColor = fidelity_invoice_status;
    } catch (error) {
      console.error(error);
      this.waiting = false;
    }
    
    this.waiting = false;
  }
}
