<section class="tw-rounded-xl tw-border tw-border-border-light-gray tw-p-2 tw-mb-3 tw-min-h-[50px] tw-cursor-pointer tw-bg-white {{ parentCustomClass }}" (click)="inputTag.focus(); boxOpen = true" [class.tw-border-border-main]="boxOpen" [class.tw-border-2]="boxOpen">
  <input id="inputTag" #inputTag [formControl]="inputControl" [class.tw-hidden]="!showAutocompleteInput" class="focus:tw-outline-0 tw-px-2 tw-w-full tw-mb-2 {{customClasses}}" [placeholder]="placeholder" (focus)="boxOpen = true"/>
  <ng-container *ngFor="let chip of chips; trackBy: trackByItem">
    <div class="tw-m-1 tw-rounded-full tw-py-1 tw-px-2 tw-font-medium tw-text-sm tw-bg-white tw-text-[#274453] tw-border tw-border-[#274453] tw-max-w-fit tw-inline-block">
      <ng-container *ngIf="idType === 'code'">{{displayChipsFn(chip) | translate}}</ng-container>
      <ng-container *ngIf="idType !== 'code'">{{displayChipsFn(chip) | titlecase}}</ng-container>

      <span *ngIf="showCloseBtnFn(chip) && showCloseBtn" class="tw-ml-1 tw-bg-white tw-text-[#274453] tw-rounded-full tw-w-fit tw-px-1 tw-cursor-pointer chip-no-close" (click)="handleClick(chip)">
        <i class="fas fa-times tw-text-3xs"></i>
      </span>
    </div>
  </ng-container>
</section>

<section id="menuInputTag" class="tw-rounded-lg tw-shadow-xl tw-px-5 tw-py-3 tw-bg-white tw-max-h-36 tw-overflow-y-scroll {{ menuCustomClass }}" *ngIf="boxOpen">
  <ul class="tw-max-h-36">
    <ng-container *ngIf="displayCheckInput">
      <li class="generic-option tw-cursor-pointer tw-py-2 input-tag-option" (click)="handleClick(allOption)">
        <input type="checkbox" class="tw-rounded-md focus:tw-ring-0 focus:tw-ring-offset-0 tw-text-main tw-mr-2 tw-cursor-pointer" [checked]="checkedAllOption">
        {{'commons.alls' | translate}}
      </li>
      <ng-container *ngFor="let option of optionsCopy; trackBy: trackByItem">
        <li class="generic-option tw-cursor-pointer tw-py-2" (click)="handleClick(option)">
          <input type="checkbox" class="tw-rounded-md focus:tw-ring-0 focus:tw-ring-offset-0 tw-text-main tw-mr-2 tw-cursor-pointer" [checked]="option.checked">
          {{displayChipsListFn(option) | translate}}
        </li>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!displayCheckInput">
      <ng-container *ngFor="let option of optionsCopy; trackBy: trackByItem">
        <li *ngIf="!option.checked" class="generic-option tw-cursor-pointer tw-text-main-text hover:tw-text-main-orange tw-py-2 tw-transition-all input-tag-option" (click)="handleClick(option)">
          {{displayChipsListFn(option) | translate}}
        </li>
      </ng-container>

    </ng-container>
  </ul>
</section>
