import { Pipe, PipeTransform } from "@angular/core";

import { Survey } from "app/shared/model/index";

@Pipe({
  name: "followupGuestFilter",
})
export class FollowupGuestFilterPipe implements PipeTransform {
  transform(list: Survey[], input: string, findGuest = false): any {
    if (typeof input !== 'string') return [];
    input &&= input.replace(/([\ \t]+(?=[\ \t])|^\s+|\s+$)/g, "");
    input &&= input.trim().toLowerCase();
    if(findGuest) return findGuestByInput(list, input)
    if (input) {
      return list.filter((state: any) => {
        let firstName = "";
        let seconName = "";
        let lastName = "";
        let seconlastName = "";

        const guest = state.guest ? state.guest : state;
        const country = guest.country ? guest.country.name : guest.country_code;
        const lastname =
          (guest.lastName ? guest.lastName : guest.lastname) || "";
        const fullName = guest.name
          .replace(/([\ \t]+(?=[\ \t])|^\s+|\s+$)/g, "")
        const fulllastName = lastname
          .replace(/([\ \t]+(?=[\ \t])|^\s+|\s+$)/g, "")
        const phone = guest.phone?.split(" ").join("") || '';
        const reservationId = guest.reservation_id?.toString() || '';
        const answerId = state.answer?.id.toString()

        if (fullName.length === 2) {
          firstName = fullName[0];
          seconName = fullName[1];
        } else {
          firstName = guest.name;
        }
        if (fulllastName.length === 2) {
          lastName = fulllastName[0];
          seconlastName = fulllastName[1];
        } else {
          lastName = lastname;
        }
        const full = `${fullName} ${fulllastName}`;
        if(full && full.toLowerCase().indexOf(input) >= 0) return true
        return (
          firstName.toLowerCase().indexOf(input) >= 0 ||
          seconName.toLowerCase().indexOf(input) >= 0 ||
          lastName.toLowerCase().indexOf(input) >= 0 ||
          seconlastName.toLowerCase().indexOf(input) >= 0 ||
          guest.email.toLowerCase().indexOf(input) >= 0 ||
          country.toLowerCase().indexOf(input) >= 0 ||
          phone.toLowerCase().indexOf(input) >= 0 ||
          reservationId.toLowerCase().indexOf(input) >= 0 ||
          answerId?.toLowerCase().indexOf(input) >= 0
        );
      });
    } else return list;
  }
}

function findGuestByInput(list, input) {
    if (input) {
      return list.find((state: any) => {
        let firstName = "";
        let seconName = "";
        let lastName = "";
        let seconlastName = "";
        const guest = state.guest ? state.guest : state;
        const country = guest.country ? guest.country.name : guest.country_code;
        const lastname =
          (guest.lastName ? guest.lastName : guest.lastname) || "";
        const fullName = guest.name
          .replace(/([\ \t]+(?=[\ \t])|^\s+|\s+$)/g, "")
        const fulllastName = lastname
          .replace(/([\ \t]+(?=[\ \t])|^\s+|\s+$)/g, "")
        const phone = guest.phone?.split(" ").join("") || '';
        const reservationId = guest.reservation_id?.toString() || '';
        const answerId = state.answer?.id.toString()
        if (fullName.length === 2) {
          firstName = fullName[0];
          seconName = fullName[1];
        } else {
          firstName = guest.name;
        }
        if (fulllastName.length === 2) {
          lastName = fulllastName[0];
          seconlastName = fulllastName[1];
        } else {
          lastName = lastname;
        }
        const full = `${fullName} ${fulllastName}`;
        const inputLowCase = input.toLowerCase();
        if(full && full.toLowerCase().indexOf(inputLowCase) >= 0) return true
        return (
          firstName.toLowerCase().indexOf(inputLowCase) >= 0 ||
          seconName.toLowerCase().indexOf(inputLowCase) >= 0 ||
          lastName.toLowerCase().indexOf(inputLowCase) >= 0 ||
          seconlastName.toLowerCase().indexOf(inputLowCase) >= 0 ||
          guest.email.toLowerCase().indexOf(inputLowCase) >= 0 ||
          country.toLowerCase().indexOf(inputLowCase) >= 0 ||
          phone.toLowerCase().indexOf(inputLowCase) >= 0 ||
          reservationId.toLowerCase().indexOf(inputLowCase) >= 0 ||
          answerId.toLowerCase().indexOf(inputLowCase) >= 0
        );
      });
    } else return list;
}
