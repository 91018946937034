import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as RecordRTC from 'recordrtc';
import { VoicesService } from '../voices.service';
import { UtilService } from 'app/shared/util.service';

@Component({
  selector: 'mh-public-portal',
  templateUrl: './public-portal.component.html',
  styleUrls: ['./public-portal.component.scss']
})
export class PublicPortalComponent implements OnInit {
  @ViewChild('player') player;
  recording = false;
  loading = false;
  recorder;
  stream;
  audioURL;
  audioBlob;
  token: string;
  originId: number;
  originToken: string;
  fileUploaded = false;
  language = 'es';

  limitInSeconds = 60;
  countSeconds = 0;
  interval;
  error;

  constructor(
    private route: ActivatedRoute,
    private voicesService: VoicesService,
    private utilService: UtilService
  ) {}

  ngOnInit() {
    this.loadOriginData();
  }

  loadOriginData() {
    this.loading = true;
    this.token = this.route.snapshot.paramMap.get('token');
    this.voicesService.getOriginData(this.token).subscribe((result) => {
      if (result?.flag_active) {
        this.originId = result.origin_id;
        this.originToken = result.token;
        this.language = result.language_code;
      }
      this.setLanguage();
      this.loading = false;
    });
  }

  recordAudio(): Promise<any> {
    return new Promise(async resolve => {
      const recorder = RecordRTC(this.stream, {
        type: 'audio',
        mimeType: 'audio/wav',
      });

      const start = () =>  recorder.startRecording();

      const stop = () =>
        new Promise(resolve => {
          recorder.stopRecording(() => {
            const audioBlob = recorder.getBlob()
            const audioUrl = URL.createObjectURL(audioBlob);
            resolve({ audioBlob, audioUrl });
          });
        });

      resolve({ start, stop });
    });
  }

  async stopRecord() {
    if (this.recording) {
      const { audioBlob, audioUrl } = await this.recorder.stop();
      this.player.nativeElement.src = audioUrl;
      this.audioURL = audioUrl;
      this.audioBlob = audioBlob;
      this.recording = false;
      this.stream.stop();
      this.stream = null;
      clearInterval(this.interval);
      this.countSeconds = 0;
    }
  }

  async startRecord() { 
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch(err) {
      console.error(err);
      this.error = err.message;
    }
    this.recording = true;
    this.recorder = await this.recordAudio();
    this.recorder.start();

    this.interval = setInterval(() => {
      this.countSeconds += 1;
      if (this.countSeconds > this.limitInSeconds) {
        this.stopRecord();
      }
    }, 1000);
  }

  sendRecording() {
    this.loading = true;
    const data: FormData = new FormData();
    data.append('file', this.audioBlob);
    this.voicesService.uploadFile(this.originToken, data)
      .subscribe((result) => {
        if (result.status === 'OK')  {
          this.fileUploaded = true;
        }
        this.loading = false;
      });
  }

  setLanguage() {
    this.language = this.route.snapshot.queryParams.lang ?? this.language;
    this.utilService.setCurrrentLanguage(this.language || 'es');
  }
}
