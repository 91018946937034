import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store'
import { Observable } from "rxjs";

import { FollowupOnsite } from 'app/states/followup-onsite/followup-onsite.actions'
import { FollowupOnsiteState } from 'app/states/followup-onsite/followup-onsite.state'

@Component({
  selector: 'mh-layout-view',
  templateUrl: './layout-view.component.html',
  styleUrls: ['./layout-view.component.scss']
})
export class LayoutViewComponent implements OnInit {

  @Select(FollowupOnsiteState.layoutView) layoutView$: Observable<string>
  @Input() isMobile = false;
  @Output() layoutEvent = new EventEmitter<any>();

  constructor(
    private store: Store,
  ) { }

  ngOnInit() {
    if(this.isMobile) this.setLayoutView('card-view');
  }

  setLayoutView(type: 'list-view' | 'card-view') {
    this.store.dispatch(new FollowupOnsite.ChangeLayoutView(type))
    this.layoutEvent.emit(type);
  }

}
