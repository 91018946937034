import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UtilService } from '../../../shared/util.service';
import { getAverageAreas } from 'app/shared/helpers';
import { FollowupPropertiesService } from './followup-properties.service';
import { CustomerService } from '../../../shared/customer.service';
import { Hotel, FilterDate, Period } from '../../../shared/model/index';

// TABLE IMPORTS
import { MatTableDataSource } from '@angular/material/table';

import { ChainService } from '../../inter-chain.service';
import { Subscription, forkJoin } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CustomersState } from 'app/states/customers/customers.state';

@Component({
  selector: 'mh-followup-properties',
  templateUrl: './followup-properties.component.html',
  styleUrls: ['./followup-properties.component.scss'],
  providers: [FollowupPropertiesService, CustomerService, ChainService],
})
export class FollowupPropertiesComponent {
  @Select(CustomersState.relativeCurrentChain) customersReady$: Observable<any[]>;

  @Input()
  chain: Hotel[] = [];

  @Input()
  productId = 1;

  @Input()
  headerKeys = [];

  @Input()
  currentDates = null;

  chainActives: any[] = [];
  headerTable: any[] = [];
  totals = {};
  data;

  dataTable: any[] = [];
  dataSource: any = new MatTableDataSource([]);
  waiting = true;

  @Output()
  isReady: EventEmitter<boolean> = new EventEmitter();

  firstCall = true;
  surveySubscribe: Subscription;
  customersStateSubscribe: Subscription;

  tooltipHeaders: any[] = [];

  @Output()
  filteredChain = new EventEmitter();

  constructor(
    private utilService: UtilService,
    private followupPropertiesService: FollowupPropertiesService,
    private store: Store,
  ) {
    this.surveySubscribe = this.utilService.isCurrentSurveyChanged().subscribe((surveId) => {
      if (this.currentDates) {
        this.init();
      }
    });

    this.customersStateSubscribe = this.customersReady$.subscribe((customers) => {
      if (this.currentDates) {
        this.init();
      }
    });
  }

  ngOnDestroy() {
    this.surveySubscribe.unsubscribe();
    this.customersStateSubscribe.unsubscribe();
  }

  ngOnChanges({ currentDates }): void {
    this.chainActives = this.chain;
    if (currentDates && !this.utilService.changingCurrentSurvey) {
      this.init();
    }
  }

  reset() {
    this.dataTable = [];
    this.isReady.emit(false);
    this.dataSource = new MatTableDataSource([]);
    this.headerTable = [].concat(this.headerKeys);
    this.totals = {};
  }

  init() {
    this.waiting = true;
    const language = this.utilService.getLanguageId();
    const date = new FilterDate(this.currentDates.startDate, this.currentDates.endDate);
    const survey = this.utilService.getCurrentSurvey(this.chain.map((x) => parseInt(x.id)));
    const surveyIds = survey.ids;
    const version = survey.survey[0].version;

    const customers = this.chainActives?.length
      ? this.chainActives.map((hotel) => hotel.id).join(',')
      : this.store
          .selectSnapshot((state) => state.customers.relativeCurrentChain)
          .map((c) => c.id)
          .join(',');

    this.setHeaderTooltipsOnsite();

    if (customers.length) {
      forkJoin([
        this.followupPropertiesService.getRankignAreas(date, customers, this.productId, language, surveyIds, version),
        this.followupPropertiesService.getNPS(date, customers, this.productId, surveyIds, version),
        this.followupPropertiesService.getPMS(date, customers, this.productId, surveyIds),
        this.followupPropertiesService.getRankingTripadvisor(date, customers, this.productId, surveyIds),
        this.followupPropertiesService.getSemanticIndex(date, customers, this.productId, surveyIds),
        this.followupPropertiesService.getRate(date, customers, this.productId, surveyIds, true, version),
      ]).subscribe((result) => {
        this.data = result;
        this.createTable();
        this.waiting = false;
        this.firstCall = false;
        this.isReady.emit(true);
        this.filteredChain.next(this.chainActives);
      });
    }
  }

  setHeaderTooltipsOnsite() {
    this.tooltipHeaders = [
      'nps',
      'pms',
      'answers_quantity',
      'mh_rate_response',
      'email_surveys',
      'open_surveys',
      'guests_quantity',
      'valid_guests_quantity',
      'total_mentions',
      'semantic_index',
    ];
  }

  createTable() {
    this.reset();
    const dataAreas = this.data[0];
    const dataNPS = this.data[1];
    const dataPMS = this.data[2];
    const dataRankingTA = this.data[3];
    const dataSemantic = this.data[4];
    const dataRates = this.data[5];
    //DM: LO DEL FILTER ES UN HACK! en alguna parte se agrega el area -1!
    //ESTO NO VIENE DEL ENDPOINT
    const areas =
      dataAreas && dataAreas.average && this.productId === 1
        ? dataAreas.average.areas.map((x) => x.area_id).filter((id) => id !== -1)
        : [];

    this.chainActives.forEach((customer, index) => {
      const nps = dataNPS.values.find((x) => x.customer_id === customer.id);
      const tripAdvisorRanking = dataRankingTA.values.find((x) => x.customer_id === customer.id);
      const pms = dataPMS.values.find((x) => x.customer_id === customer.id);
      const rates = dataRates.values.find((x) => x.customer_id === customer.id);
      const semantic = dataSemantic.values.find((x) => x.customer_id === customer.id);
      const area = dataAreas.values.find((x) => x.customer_id === customer.id);      

      const cell = {
        customer: customer,
        nps: nps ? nps.nps : new Period(),
        tripAdvisorRanking: tripAdvisorRanking
          ? this.utilService.convertFormatRankingTA(tripAdvisorRanking['ranking'], tripAdvisorRanking)
          : new Period(),
        pms: pms ? pms['email_rate'] : new Period(),
        answers_quantity: rates ? rates['answered'] : new Period(),
        mh_rate_response: rates ? rates['rate'] : new Period(),
        email_surveys: rates ? rates['email_surveys'].rate : new Period(),
        open_surveys: rates ? rates['open_surveys'].rate : new Period(),
        guests_quantity: pms ? pms['total_guests'] : new Period(),
        valid_guests_quantity: pms ? pms['valid_emails'] : new Period(),
        total_mentions: semantic ? semantic['total_mentions'] : new Period(),
        semantic_index: semantic ? semantic['semantic_index'] : new Period(),
        all_areas_average_rating: area ? area['all_areas_average_rating'] : new Period(),
      };
      getAverageAreas(area?.areas || []);

      if (this.productId === 1) {
        const allAreas = dataAreas.values.reduce((acc, curr) => {
          curr.areas.forEach((ar) => {
            const foundArea = acc.find((el) => el['area_id'] === ar['area_id']);
            if (!foundArea) {
              acc = [...acc, ar];
            }
          });
          return acc;
        }, []);

        areas.forEach((idArea) => {
          const key = this.getAreaKey(idArea);
          const foundArea = allAreas.find((ar) => ar['area_id'] === idArea);
          const translates = foundArea ? foundArea?.translates : {};
          const data = area ? area.areas.find((x) => x.area_id === idArea) : { rating: new Period(), translates };
          cell[key] = data;
        });
      }

      this.dataTable.push(cell);
    });

    this.totals = {
      nps: dataNPS.average['nps'],
      pms: dataPMS.average['email_rate'],
      answers_quantity: dataRates.average['answered'],
      mh_rate_response: dataRates.average['rate'],
      email_surveys: dataRates.average['email_surveys'].rate,
      open_surveys: dataRates.average['open_surveys'].rate,
      guests_quantity: dataPMS.average['total_guests'],
      valid_guests_quantity: dataPMS.average['valid_emails'],
      total_mentions: dataSemantic.average['total_mentions'],
      semantic_index: dataSemantic.average['semantic_index'],
      all_areas_average_rating: dataAreas.average['all_areas_average_rating'],
    };

    getAverageAreas(dataAreas.average.areas);
    if (this.productId === 1) {
      areas.forEach((idArea) => {
        const key = this.getAreaKey(idArea);
        const data = dataAreas.average.areas.find((x) => x.area_id === idArea);
        this.totals[key] = data;
      });
    }

    this.dataSource = new MatTableDataSource(this.dataTable);

    const dataSourceKeys = this.dataSource.data.reduce((acc, curr) => {
      Object.keys(curr).forEach((key) => {
        if (!acc.includes(key)) {
          acc.push(key);
        }
      });
      return acc;
    }, []);

    this.headerTable = dataSourceKeys;
    this.isReady.emit(true);
  }

  getAreaKey(idArea) {
    return `area_${idArea}`;
  }

  filterCustomers($event) {
    this.chainActives = $event;
    this.filteredChain.next($event);
    this.init();
  }
}
