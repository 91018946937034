import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CurrentSurveyModel, CurrentSurvey } from './models';
import { StoreCurrentSurvey } from './survey.actions';

const currentSurvey = new CurrentSurvey();

@State<CurrentSurveyModel>({
  name: 'currentSurvey',
  defaults: {
    currentSurvey: currentSurvey
  }
})
export class CurrentSurveyState {

  @Selector()
  static currentSurvey(state: CurrentSurveyModel) {
    return state.currentSurvey;
  }

  constructor() {}

  @Action(StoreCurrentSurvey)
  storePublicConfig(context: StateContext<CurrentSurveyModel>, action: StoreCurrentSurvey) {
    context.patchState({
      currentSurvey: action.currentSurvey
    })
    
  }

}
