import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { FilterDate } from 'app/shared/model';

@Injectable()
export class SurveyCommentsService {

  constructor(private http:HttpClient) { }

  public getComments(dates:FilterDate,customerId:any, surveyId: any, questionId:any){

    const url=`${environment.followupPath}surveys/${surveyId}/questions/${questionId}/comments`;
    const params:HttpParams = new HttpParams()
      .set('start_date',dates.startDate)
      .set('end_date',dates.endDate)
      .set('customer_id',customerId.toString());

    return this.http.get(url, {params: params})
    .pipe(
      map(response=>response)
    );
  }
}
