import { Pipe, PipeTransform } from '@angular/core'

import { Survey } from "app/shared/model/index";
import { FollowupOnsiteGuestFilter } from 'app/states/followup-onsite/followup-onsite.state';

@Pipe({ name: 'followupOnsiteSurveysFilter' })
export class FollowupOnsiteSurveysFilterPipe implements PipeTransform {

  transform(value: Survey[], filters: FollowupOnsiteGuestFilter): Survey[] {
    if (filters.surveys?.length === 0) return value
    if (filters.surveys) {
      const filtered = value.filter(survey => !!filters.surveys.find(s => s.id === survey.id) )
      return filtered
    } else
      return value
  }
}
