// Modules
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InputTrimModule } from 'ng2-trim-directive';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ColorPickerModule } from 'ngx-color-picker';
import { StyleBaseModule } from './../material/style-base/style-base.module';

import { ClickOutsideModule } from 'ng-click-outside';
import { TagInputModule } from 'ngx-chips';
import { CountdownModule } from 'ngx-countdown';
import { YouTubePlayerModule } from '@angular/youtube-player';

// Components
import { SharedComponent } from './shared.component';

import {
  FilterCurrencyComponent,
  FilterCustomerComponent,
  FilterDateComponent,
  FilterSearchComponent,
  FilterGuestComponent,
  FilterCompetitorsComponent,
  FindCustomerComponent,
} from './filters/index';

import { NavbarComponent } from './navbar/navbar.component';
import { NavbarItemComponent } from './navbar/navbar-item.component';
import { NavbarActionsDirective } from './navbar/navbar.directive';
import { HeaderComponent } from './header/header.component';
import { PieChartComponent, BarChartComponent } from './charts/index';
import {
  SurveySummaryComponent,
  SurveyInfoComponent,
  SurveysAnswerModal,
  SurveyWrapperComponent,
  SurveyCommentsComponent,
  SurveyGuestCommentComponent,
  SurveyComponent,
  SurveySectionComponent,
  SurveyQuestionComponent,
  SurveyQuestionGridComponent,
  SurveyQuestionRadioComponent,
  SurveyQuestionCheckboxComponent,
  SurveyQuestionCommentComponent,
  SurveyQuestionTextComponent,
} from './surveys';

import {
  AlertService,
  AuthenticationService,
  UserService,
  AdminUtilService,
  CustomerService,
} from '../_services/index';

import { DownloadExcelComponent, DownloadExcelModal } from '../reports/index';

// Directives
import { FlotChartDirective } from './flot-chart.directive';

import { SearchPipe } from './search.pipe';
import {
  SafeUrlPipe,
  SafeStylePipe,
  TranslateObjectPipe,
  ShortNumberPipe,
  TranslatesTableHeaderPipe,
  HoursToDays,
  FilterDeletedPipe,
  ConsoleLoopPipe,
  HasErrorOnCheckboxPipe,
  DiffDaysPipe,
  CheckDownloadStatusPipe,
  GetOtaLogoPipe,
  ReplaceCharPipe,
  ReplaceAllCharPipe,
  FormatDatePipe,
  CalculateDaysPipe,
  TypeofPipe,
} from './pipe';

// Services
import { NavbarService } from './navbar/navbar.service';
import { HttpClientMH } from './utils/http-myhotel-client';
import { UtilService } from './util.service';

import {
  SessionThemeComponent,
  PublicThemeComponent,
  PrestayThemeComponent,
  CollectThemeComponent,
} from '../layouts/themes/index';

import { FlagsComponent, FlagsModalComponent, AlertComponent } from '../notifications/index';

import { TreeModule } from '@circlon/angular-tree-component';

import { OnlineSummaryComponent, TripadvisorComponent, TrendsComponent } from '../inter-chain/index';

import {
  SemanticMentionsComponent,
  SemanticMentionComponent,
  SemanticCategoriesComponent,
  SemanticCategoriesModalComponent,
  CategoryModal,
} from './semantic/index';

// prestay
import { PrecheckinGuestComponent } from './prestay/precheckin-guest/precheckin-guest.component';
import { PrecheckinGuestDetailComponent } from './prestay/precheckin-guest-detail/precheckin-guest-detail.component';
import { OrderGuestComponent } from 'app/shared/prestay/orders/order-guest/order-guest.component';
import { OrderDetailComponent } from 'app/shared/prestay/orders/order-detail/order-detail.component';
import { OrderDetailReservationComponent } from 'app/shared/prestay/orders/order-detail-reservation/order-detail-reservation.component';
import { PrestayProductPriceComponent } from 'app/shared/prestay/product/price/product-price.component';

import { ReviewComponent } from './review/review.component';
import {
  CaseProductActionsComponent,
  CaseDetailComponent,
  CaseActivityComponent,
  NewCaseFormComponent,
  CaseProductComponent,
  ActivitiesService,
  CaseHeaderInfoComponent,
} from './cases';
import { ModalComponent } from './modal/modal.component';
import { ConfirmationModalComponent, ConfirmationModalService } from './confirmation-modal';

import { FilterTrendsComponent } from './filters/trends/filter-trends.component';
import { WidgetsModule } from '../material/widgets/widgets.module';
import { ChainZoneComponent } from './filters/chain-zone/chain-zone.component';

import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

import { SocketComponent } from './socket/socket.component';
import { AlertsWrapperComponent } from './alerts/alerts-wrapper/alerts-wrapper/alerts-wrapper.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { LogsListComponent } from './logs/logs-list/logs-list.component';
import { LogCardComponent } from './logs/log-card/log-card.component';
import { LogWrapperComponent } from './logs/log-wrapper/log-wrapper.component';
import { SurveyAnswerPublicComponent } from './surveys/survey-answer-public/survey-answer-public.component';
import { SurveyMessagesComponent } from './surveys/survey-messages/survey-messages.component';
import { EmailTestModalComponent } from './email-test-modal/email-test-modal.component';
import { ModalCheckSetupComponent } from './modal-check-setup/modal-check-setup.component';
import { PrestayService } from 'app/prestay/prestay.service';
import { SurveyService } from 'app/features/surveys/surveys.service';
import { TableSetupComponent } from './modal-check-setup/table-setup/table-setup.component';
import { OnlineService } from 'app/online/online.service';
import { CheckSetupButtonComponent } from './check-setup-button/check-setup-button.component';
import { ModalOtasComponent } from './modal-otas/modal-otas.component';
import { NewMenuComponent } from './new-menu/new-menu.component';
import { EditorHtmlComponent } from './editor-html/editor-html.component';
import { SemanticHeaderComponent } from './semantic/header/semantic-header.component';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { CoverMaskComponent } from './cover-mask/cover-mask.component';
import { PrecheckinGuestDetailTableComponent } from './prestay/precheckin-guest-detail/precheckin-guest-detail-table/precheckin-guest-detail-table.component';
import { OtasErrorsComponent } from './otas-errors/otas-errors.component';
import { ConfigurationOtasComponent } from './configuration-otas/configuration-otas.component';
import { CoverMaskLoadingComponent } from './cover-mask-loading/cover-mask-loading.component';
import { SelectMultipleSurveysComponent } from 'app/shared/select-multiple-surveys/select-multiple-surveys.component';
import { LayoutViewComponent } from 'app/shared/layout-view/layout-view.component';
import { PipesModule } from 'app/pipes/pipes.module';
import { MentionModule } from 'angular-social-mentions';
import { UserMentionComponent } from './cases/case-detail/components/user-mention/user-mention.component';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  CustomerHotelChainFormComponent,
  CustomerHotelFormComponent,
  CustomerHotelImagesFormComponent,
} from 'app/shared/hotel/form';
import { HotelFinderComponent } from './hotel-finder/hotel-finder.component';
import { HeaderButtonComponent } from './header-button/header-button.component';
import { ModalReplyReviewComponent } from './modal-reply-review/modal-reply-review.component';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { OverlayModule } from '@angular/cdk/overlay';
import { CoreLibModule } from 'app/core-lib/core-lib.module';
import { StatesHandlersModule } from 'app/states-handlers/states-handlers.module';
import { CoreLibDatepickerComponent } from 'app/core-lib/core-lib-datepicker/core-lib-datepicker.component';
import { CoreLibButtonComponent } from 'app/core-lib/buttons/button/button.component';
import { CoreLibSelectorUsersComponent } from 'app/core-lib/buttons/selector-users/selector-users.component';
import { CoreLibSearcherComponent } from 'app/core-lib/searcher/searcher.component';
import { CoreLibTableComponent } from 'app/core-lib/tables/table/table.component';
import { CoreLibPaginationSelectorComponent } from 'app/core-lib/pagination-selector/pagination-selector.component';
import { CoreLibMainContentComponent } from 'app/core-lib/layout/main-content/main-content.component';
import { CoreLibHeaderContentComponent } from 'app/core-lib/layout/header-content/header-content.component';
import { CoreLibContentComponent } from 'app/core-lib/layout/content/content.component';
import { CoreLibFollowupOnsiteWidgetsComponent } from 'app/core-lib/boxes/followup-onsite-widgets/followup-onsite-widgets.component';
import { CoreLibLoaderWrapperComponent } from 'app/core-lib/boxes/loader-wrapper/loader-wrapper.component';
import { CoreLibGuestWidgetComponent } from 'app/core-lib/boxes/core-lib-guest-widget/core-lib-guest-widget.component';
import { CoreLibModalComponent } from 'app/core-lib/dialogs/modal/modal.component';
import { CorecLibSuccessModalComponent } from 'app/core-lib/dialogs/success-modal/success-modal.component';
import { CoreLibResponseReplyModalComponent } from 'app/core-lib/dialogs/response-reply-modal/response-reply-modal.component';
import { CoreLibRankingAreaModalComponent } from 'app/core-lib/dialogs/ranking-area-modal/ranking-area-modal.component';
import { SurveyQuestionDateComponent } from './surveys/survey-question/survey-question-date/survey-question-date.component';
import { SurveyQuestionSinglePickListComponent } from './surveys/survey-question/survey-question-single-pick-list/survey-question-single-pick-list.component';
import { CoreLibToggleComponent } from 'app/core-lib/toggle/toggle.component';
import { CoreLibWidgetQualificationComponent } from 'app/core-lib/dialogs/ranking-area-modal/widget-qualification/widget-qualification.component';
import { CoreLibGenericBoxComponent } from 'app/core-lib/boxes/core-lib-generic-box/core-lib-generic-box.component';
import { CoreLibWidgetQuestionQualificationComponent } from 'app/core-lib/dialogs/ranking-area-modal/widget-question-qualification/widget-question-qualification.component';
import { CoreLibSurveySelectorComponent } from 'app/core-lib/selectors/survey-selector/survey-selector.component';
import { CoreLibViewGuestsSelectorComponent } from 'app/core-lib/selectors/view-guests-selector/view-guests-selector.component';
import { CoreLibTagInputComponent } from 'app/core-lib/inputs/tag-input/tag-input.component';
import { CoreLibGuestSelectorComponent } from 'app/core-lib/selectors/guest-selector/guest-selector.component';
import { CoreLibAutocompleteInputComponent } from 'app/core-lib/inputs/autocomplete-input/autocomplete-input.component';
import { CoreLibGuestsFilterComponent } from 'app/core-lib/filters/guests-filter/guests-filter.component';
import { CoreLibCheckboxInputComponent } from 'app/core-lib/inputs/checkbox-input/checkbox-input.component';
import { CoreLibGenericSelectorComponent } from 'app/core-lib/selectors/generic-selector/generic-selector.component';
import { CoreLibGuestBoxComponent } from 'app/core-lib/boxes/core-lib-guest-box/core-lib-guest-box.component';
import { CoreLibAnswerSurveyComponent } from 'app/core-lib/boxes/core-lib-answer-survey/core-lib-answer-survey.component';
import { CoreLibGuestZoneComponent } from 'app/core-lib/boxes/core-lib-guest-zone/core-lib-guest-zone.component';
import { CoreLibNpsFaceComponent } from 'app/core-lib/utils/nps-face/nps-face.component';
import { CoreLibCreateCaseModalComponent } from 'app/core-lib/dialogs/create-case-modal/create-case-modal.component';
import { CoreLibReportButtonComponent } from 'app/core-lib/buttons/report-button/report-button.component';
import { CoreLibSurveyDetailModalComponent } from 'app/core-lib/dialogs/survey-detail-modal/survey-detail-modal.component';
import { CoreLibConfirmationModalComponent } from 'app/core-lib/dialogs/confirmation-modal/confirmation-modal.component';
import { ConfirmationAnswerSurveyDialogComponent } from 'app/core-lib/dialogs/confirmation-answer-survey-dialog/confirmation-answer-survey-dialog.component';
import { CoreLibTextareaInputComponent } from 'app/core-lib/inputs/textarea-input/textarea-input.component';
import { CoreLibEmailRateModalComponent } from 'app/core-lib/dialogs/email-rate-modal/email-rate-modal.component';
import { CoreLibSenderVariablesModalComponent } from 'app/core-lib/dialogs/sender-variables-modal/sender-variables-modal.component';
import { CoreLibTextInputComponent } from 'app/core-lib/inputs/text-input/text-input.component';
import { CoreLibSelectInputComponent } from 'app/core-lib/inputs/select-input/select-input.component';
import { CoreLibReviewCaseComponent } from 'app/core-lib/dialogs/create-case-modal/review-case/review-case.component';
import { CoreLibNpsCaseComponent } from 'app/core-lib/dialogs/create-case-modal/nps-case/nps-case.component';
import { CoreLibPrestayCaseComponent } from 'app/core-lib/dialogs/create-case-modal/prestay-case/prestay-case.component';
import { CoreLibOrderCaseComponent } from 'app/core-lib/dialogs/create-case-modal/order-case/order-case.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CoreLibTooltipDirective } from 'app/core-lib/directives/tooltip.directive';
import { CoreLibLimitCasesModalComponent } from 'app/core-lib/dialogs/limit-cases/limit-cases.component';
import { CoreLibDeskFilterComponent } from 'app/core-lib/filters/desk-filter/desk-filter.component';
import { CoreLibDeskWidgetComponent } from 'app/core-lib/boxes/core-lib-desk-widget/core-lib-desk-widget.component';
import { CompetitorsSelectorComponent } from 'app/core-lib/selectors/competitors-selector/competitors-selector.component';
import { CoreLibNotificationModalComponent } from 'app/core-lib/dialogs/notification-modal/notification-modal.component';
import { CoreLibCaseDetailModalComponent } from 'app/core-lib/dialogs/case-detail-modal/case-detail-modal.component';
import { CoreLibProductCaseComponent } from 'app/core-lib/dialogs/create-case-modal/product-case/product-case.component';
import { CoreLibCaseDetailFormComponent } from 'app/core-lib/dialogs/case-detail-modal/case-detail-form/case-detail-form.component';
import { CoreLibGenericConfirmationModalComponent } from 'app/core-lib/dialogs/generic-confirmation-modal/generic-confirmation-modal.component';
import { CoreLibRecordCaseDetailComponent } from 'app/core-lib/dialogs/case-detail-modal/record-case-detail/record-case-detail.component';
import { CoreLibTabsComponent } from 'app/core-lib/tabs/tabs.component';
import { CoreLibTabComponent } from 'app/core-lib/tabs/tab/tab.component';
import { CoreLibActivitiesRecordsChangesComponent } from 'app/core-lib/dialogs/case-detail-modal/activities-records-changes/activities-records-changes.component';
import { CoreLibCasesSelectorComponent } from 'app/core-lib/selectors/cases-selector/cases-selector.component';
import { CoreLibReviewsFilterComponent } from 'app/core-lib/filters/reviews-filter/reviews-filter.component';
import { CoreLibNewReviewsFilterComponent } from 'app/core-lib/filters/new-reviews-filter/new-reviews-filter.component';
import { CoreLibDashboardWidgetComponent } from 'app/core-lib/boxes/core-lib-dashboard-widget/core-lib-dashboard-widget.component';
import { CoreLibDoubtCollectionModalComponent } from 'app/core-lib/dialogs/doubt-collection-modal/doubt-collection-modal.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CoreLibAccordionComponent } from 'app/core-lib/accordions/accordion/accordion.component';
import { CoreLibNestedAccordionComponent } from 'app/core-lib/accordions/nested-accordion/nested-accordion.component';
import { CoreLibIntegrationStatusComponent } from 'app/core-lib/utils/integration-status/integration-status.component';
import { CoreLibPmsIntegrationStatusModalComponent } from 'app/core-lib/dialogs/pms-integration-status-modal/pms-integration-status-modal.component';
import { CoreLibOtasIntegrationStatusModalComponent } from 'app/core-lib/dialogs/otas-integration-status-modal/otas-integration-status-modal.component';
import { CoreLibNotificationsComponent } from 'app/core-lib/boxes/core-lib-notifications/core-lib-notifications.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { CoreLibSemanticBoxComponent } from 'app/core-lib/boxes/core-lib-semantic-box/core-lib-semantic-box.component';
import { MuteNotificationsModalComponent } from './notifications/components/mute-notifications-modal/mute-notifications-modal.component';
import { CoreLibErrorWidgetComponent } from 'app/core-lib/errors/error-widget/error-widget.component';
import { StrengthPasswordComponent } from './validators/strength-password/strength-password.component';
import { CoreLibActionsWrapperComponent } from 'app/core-lib/utils/actions-wrapper/actions-wrapper.component';
import { CoreLibProgressBarComponent } from 'app/core-lib/loaders/progress-bar/progress-bar.component';
import { CoreLibProductForCaseComponent } from 'app/core-lib/boxes/core-lib-product-for-case/core-lib-product-for-case.component';
import { CoreLibFileUploaderComponent } from 'app/core-lib/uploaders/file-uploader/file-uploader.component';
import { CoreLibCircularDescChartComponent } from 'app/core-lib/charts/circular-desc-chart/circular-desc-chart.component';
import { CoreLibGenericCircularChartComponent } from 'app/core-lib/charts/generic-circular-chart/generic-circular-chart.component';
import { CoreLibShareModalComponent } from 'app/core-lib/dialogs/share-modal/share-modal.component';
import { CoreLibCasesAlertsComponent } from 'app/core-lib/alerts/cases-alerts/cases-alerts.component';
import { CoreLibEmptyModalComponent } from 'app/core-lib/dialogs/empty-modal/empty-modal.component';
import { CaseDiscardModalComponent } from 'app/core-lib/dialogs/case-detail-modal/case-discard-modal/case-discard-modal.component';
import { SelectComponent } from 'app/core-lib/inputs/select/select.component';
import { CoreLibNotificationsPopupModalComponent } from 'app/core-lib/dialogs/notifications-popup-modal/notifications-popup-modal.component';
import { CoreLibVideoImageSliderComponent } from 'app/core-lib/carousel/video-image-slider/video-image-slider.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

export const DATE_PICKER_FORMAT = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    RouterModule,
    StyleBaseModule,
    TagInputModule,
    InputTrimModule,
    ClickOutsideModule,
    CKEditorModule,
    ColorPickerModule,
    CountdownModule,
    WidgetsModule,
    TreeModule,
    SwiperModule,
    MatCardModule,
    MatButtonModule,
    MatExpansionModule,
    MatGridListModule,
    MatSnackBarModule,
    PerfectScrollbarModule,
    PipesModule,
    MentionModule,
    NgSelectModule,
    StatesHandlersModule,
    CoreLibModule,
    MatDatepickerModule,
    MatNativeDateModule,
    OverlayModule,
    CdkStepperModule,
    ClipboardModule,
    CdkAccordionModule,
    YouTubePlayerModule,
    // BrowserAnimationsModule,
  ],
  declarations: [
    SharedComponent,
    FilterDateComponent,
    FilterCurrencyComponent,
    FilterSearchComponent,
    FilterGuestComponent,
    FilterCompetitorsComponent,
    FilterCustomerComponent,
    FindCustomerComponent,
    FlotChartDirective,
    NavbarComponent,
    NavbarItemComponent,
    HeaderComponent,
    PieChartComponent,
    BarChartComponent,
    SurveySummaryComponent,
    SurveyInfoComponent,
    SurveysAnswerModal,
    SearchPipe,
    SafeUrlPipe,
    SafeStylePipe,
    TranslateObjectPipe,
    ShortNumberPipe,
    AlertComponent,
    PublicThemeComponent,
    PrestayThemeComponent,
    CollectThemeComponent,
    SessionThemeComponent,
    DownloadExcelComponent,
    NavbarActionsDirective,
    FlagsComponent,
    FlagsModalComponent,
    DownloadExcelModal,
    OnlineSummaryComponent,
    TripadvisorComponent,
    TrendsComponent,
    SemanticMentionsComponent,
    SemanticMentionComponent,
    SemanticCategoriesComponent,
    SemanticCategoriesModalComponent,
    CategoryModal,
    ReviewComponent,
    CaseProductActionsComponent,
    CaseDetailComponent,
    CaseActivityComponent,
    NewCaseFormComponent,
    CaseProductComponent,
    ModalComponent,
    PrecheckinGuestComponent,
    PrecheckinGuestDetailComponent,
    OrderGuestComponent,
    OrderDetailComponent,
    OrderDetailReservationComponent,
    PrestayProductPriceComponent,
    FilterTrendsComponent,
    ChainZoneComponent,
    SurveyWrapperComponent,
    SurveyCommentsComponent,
    SurveyGuestCommentComponent,
    SocketComponent,
    AlertsWrapperComponent,
    LogsListComponent,
    LogCardComponent,
    ConfirmationModalComponent,
    CaseHeaderInfoComponent,
    LogWrapperComponent,
    SurveyComponent,
    SurveySectionComponent,
    SurveyQuestionComponent,
    SurveyQuestionGridComponent,
    SurveyQuestionRadioComponent,
    SurveyQuestionCheckboxComponent,
    SurveyQuestionCommentComponent,
    SurveyQuestionTextComponent,
    SurveyAnswerPublicComponent,
    SurveyMessagesComponent,
    EmailTestModalComponent,
    ModalCheckSetupComponent,
    TableSetupComponent,
    CheckSetupButtonComponent,
    ModalOtasComponent,
    NewMenuComponent,
    EditorHtmlComponent,
    SemanticHeaderComponent,
    CoverMaskComponent,
    HoursToDays,
    PrecheckinGuestDetailTableComponent,
    TranslatesTableHeaderPipe,
    OtasErrorsComponent,
    ConfigurationOtasComponent,
    FilterDeletedPipe,
    CoverMaskLoadingComponent,
    ConsoleLoopPipe,
    SelectMultipleSurveysComponent,
    LayoutViewComponent,
    HasErrorOnCheckboxPipe,
    DiffDaysPipe,
    UserMentionComponent,
    CheckDownloadStatusPipe,
    CustomerHotelFormComponent,
    CustomerHotelImagesFormComponent,
    CustomerHotelChainFormComponent,
    HotelFinderComponent,
    HeaderButtonComponent,
    ModalReplyReviewComponent,
    GetOtaLogoPipe,
    ReplaceCharPipe,
    ReplaceAllCharPipe,
    FormatDatePipe,
    CalculateDaysPipe,
    TypeofPipe,
    CoreLibDatepickerComponent,
    CoreLibButtonComponent,
    CoreLibSearcherComponent,
    CoreLibSelectorUsersComponent,
    CoreLibTableComponent,
    CoreLibMainContentComponent,
    CoreLibHeaderContentComponent,
    CoreLibContentComponent,
    CoreLibPaginationSelectorComponent,
    CoreLibFollowupOnsiteWidgetsComponent,
    CoreLibLoaderWrapperComponent,
    CoreLibGuestWidgetComponent,
    SurveyQuestionDateComponent,
    SurveyQuestionSinglePickListComponent,
    CoreLibModalComponent,
    CorecLibSuccessModalComponent,
    CoreLibResponseReplyModalComponent,
    CoreLibToggleComponent,
    CoreLibRankingAreaModalComponent,
    CoreLibWidgetQualificationComponent,
    CoreLibGenericBoxComponent,
    CoreLibWidgetQuestionQualificationComponent,
    CoreLibSurveySelectorComponent,
    CoreLibViewGuestsSelectorComponent,
    CoreLibTagInputComponent,
    CoreLibGuestSelectorComponent,
    CoreLibAutocompleteInputComponent,
    CoreLibGuestsFilterComponent,
    CoreLibCheckboxInputComponent,
    CoreLibGenericSelectorComponent,
    CoreLibGuestBoxComponent,
    CoreLibAnswerSurveyComponent,
    CoreLibGuestZoneComponent,
    CoreLibNpsFaceComponent,
    CoreLibCreateCaseModalComponent,
    CoreLibReportButtonComponent,
    CoreLibSurveyDetailModalComponent,
    CoreLibConfirmationModalComponent,
    ConfirmationAnswerSurveyDialogComponent,
    CoreLibTextareaInputComponent,
    CoreLibEmailRateModalComponent,
    CoreLibSenderVariablesModalComponent,
    CoreLibTextInputComponent,
    CoreLibSelectInputComponent,
    SelectComponent,
    CoreLibReviewCaseComponent,
    CoreLibNpsCaseComponent,
    CoreLibTooltipDirective,
    CoreLibPrestayCaseComponent,
    CoreLibOrderCaseComponent,
    CoreLibLimitCasesModalComponent,
    CoreLibDeskWidgetComponent,
    CoreLibDeskFilterComponent,
    CoreLibCaseDetailModalComponent,
    CaseDiscardModalComponent,
    CoreLibProductCaseComponent,
    CompetitorsSelectorComponent,
    CoreLibNotificationModalComponent,
    CoreLibCaseDetailFormComponent,
    CoreLibGenericConfirmationModalComponent,
    CoreLibRecordCaseDetailComponent,
    CoreLibTabsComponent,
    CoreLibTabComponent,
    CoreLibActivitiesRecordsChangesComponent,
    CoreLibCasesSelectorComponent,
    CoreLibReviewsFilterComponent,
    CoreLibNewReviewsFilterComponent,
    CoreLibDashboardWidgetComponent,
    CoreLibDoubtCollectionModalComponent,
    CoreLibAccordionComponent,
    CoreLibNestedAccordionComponent,
    CoreLibIntegrationStatusComponent,
    CoreLibPmsIntegrationStatusModalComponent,
    CoreLibOtasIntegrationStatusModalComponent,
    CoreLibNotificationsComponent,
    NotificationsComponent,
    CoreLibSemanticBoxComponent,
    MuteNotificationsModalComponent,
    CoreLibErrorWidgetComponent,
    StrengthPasswordComponent,
    CoreLibActionsWrapperComponent,
    CoreLibProgressBarComponent,
    CoreLibProductForCaseComponent,
    CoreLibFileUploaderComponent,
    CoreLibCircularDescChartComponent,
    CoreLibGenericCircularChartComponent,
    CoreLibShareModalComponent,
    CoreLibCasesAlertsComponent,
    CoreLibEmptyModalComponent,
    CoreLibNotificationsPopupModalComponent,
    CoreLibVideoImageSliderComponent,
  ],
  exports: [
    TagInputModule,
    NgbModule,
    CountdownModule,
    CommonModule,
    CKEditorModule,
    ColorPickerModule,
    FilterDateComponent,
    FilterCurrencyComponent,
    FilterSearchComponent,
    FilterGuestComponent,
    FilterCompetitorsComponent,
    FilterCustomerComponent,
    FindCustomerComponent,
    FlotChartDirective,
    NavbarComponent,
    NavbarItemComponent,
    HeaderComponent,
    PieChartComponent,
    BarChartComponent,
    SurveySummaryComponent,
    SurveyInfoComponent,
    SurveysAnswerModal,
    SearchPipe,
    SafeUrlPipe,
    SafeStylePipe,
    TranslateObjectPipe,
    ShortNumberPipe,
    AlertComponent,
    PublicThemeComponent,
    PrestayThemeComponent,
    CollectThemeComponent,
    SessionThemeComponent,
    DownloadExcelComponent,
    MatAutocompleteModule,
    MatStepperModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatButtonModule,
    MatExpansionModule,
    MatGridListModule,
    MatSnackBarModule,
    FlagsComponent,
    FlagsModalComponent,
    InputTrimModule,
    ClickOutsideModule,
    TreeModule,
    OnlineSummaryComponent,
    TripadvisorComponent,
    TrendsComponent,
    SemanticMentionsComponent,
    SemanticMentionComponent,
    SemanticCategoriesComponent,
    SemanticCategoriesModalComponent,
    ReviewComponent,
    CaseProductActionsComponent,
    CaseDetailComponent,
    CaseActivityComponent,
    NewCaseFormComponent,
    ModalComponent,
    FilterTrendsComponent,
    WidgetsModule,
    ChainZoneComponent,
    SwiperModule,
    StyleBaseModule,
    SurveyWrapperComponent,
    SurveyCommentsComponent,
    SocketComponent,
    AlertsWrapperComponent,
    PrecheckinGuestComponent,
    PrecheckinGuestDetailComponent,
    PrestayProductPriceComponent,
    OrderGuestComponent,
    LogsListComponent,
    ConfirmationModalComponent,
    CaseHeaderInfoComponent,
    LogWrapperComponent,
    SurveyGuestCommentComponent,
    SurveyComponent,
    SurveySectionComponent,
    SurveyQuestionComponent,
    SurveyQuestionGridComponent,
    SurveyQuestionRadioComponent,
    SurveyQuestionCheckboxComponent,
    SurveyQuestionCommentComponent,
    SurveyQuestionTextComponent,
    SurveyQuestionComponent,
    EmailTestModalComponent,
    ModalCheckSetupComponent,
    CheckSetupButtonComponent,
    ModalOtasComponent,
    EditorHtmlComponent,
    SemanticHeaderComponent,
    CoverMaskComponent,
    HoursToDays,
    TranslatesTableHeaderPipe,
    OtasErrorsComponent,
    ConfigurationOtasComponent,
    FilterDeletedPipe,
    CoverMaskLoadingComponent,
    ConsoleLoopPipe,
    TranslateModule,
    SelectMultipleSurveysComponent,
    LayoutViewComponent,
    HasErrorOnCheckboxPipe,
    DiffDaysPipe,
    NgSelectModule,
    CheckDownloadStatusPipe,
    CustomerHotelFormComponent,
    CustomerHotelImagesFormComponent,
    CustomerHotelChainFormComponent,
    GetOtaLogoPipe,
    FormatDatePipe,
    CalculateDaysPipe,
    TypeofPipe,
    ReplaceCharPipe,
    ReplaceAllCharPipe,
    CoreLibDatepickerComponent,
    CoreLibButtonComponent,
    CoreLibSearcherComponent,
    CoreLibSelectorUsersComponent,
    CoreLibTableComponent,
    CoreLibMainContentComponent,
    CoreLibHeaderContentComponent,
    CoreLibContentComponent,
    CoreLibPaginationSelectorComponent,
    CoreLibFollowupOnsiteWidgetsComponent,
    CoreLibLoaderWrapperComponent,
    CoreLibGuestWidgetComponent,
    CoreLibModalComponent,
    CorecLibSuccessModalComponent,
    CoreLibResponseReplyModalComponent,
    CoreLibToggleComponent,
    CoreLibRankingAreaModalComponent,
    CoreLibWidgetQualificationComponent,
    CoreLibGenericBoxComponent,
    CoreLibWidgetQuestionQualificationComponent,
    CoreLibSurveySelectorComponent,
    CoreLibViewGuestsSelectorComponent,
    CoreLibTagInputComponent,
    CoreLibGuestSelectorComponent,
    CoreLibGuestsFilterComponent,
    CoreLibCheckboxInputComponent,
    CoreLibGenericSelectorComponent,
    CoreLibGuestBoxComponent,
    CoreLibAnswerSurveyComponent,
    CoreLibGuestZoneComponent,
    CoreLibAutocompleteInputComponent,
    CdkStepperModule,
    CoreLibNpsFaceComponent,
    CoreLibCreateCaseModalComponent,
    CoreLibReportButtonComponent,
    CoreLibSurveyDetailModalComponent,
    CoreLibConfirmationModalComponent,
    ConfirmationAnswerSurveyDialogComponent,
    CoreLibTextareaInputComponent,
    CoreLibEmailRateModalComponent,
    CoreLibSenderVariablesModalComponent,
    CoreLibTextInputComponent,
    CoreLibSelectInputComponent,
    SelectComponent,
    CoreLibReviewCaseComponent,
    CoreLibNpsCaseComponent,
    OverlayModule,
    CoreLibTooltipDirective,
    CoreLibPrestayCaseComponent,
    CoreLibOrderCaseComponent,
    CoreLibLimitCasesModalComponent,
    CoreLibDeskWidgetComponent,
    CoreLibDeskFilterComponent,
    CompetitorsSelectorComponent,
    CoreLibNotificationModalComponent,
    CoreLibCaseDetailModalComponent,
    CaseDiscardModalComponent,
    CoreLibProductCaseComponent,
    CoreLibCaseDetailFormComponent,
    CoreLibGenericConfirmationModalComponent,
    CoreLibRecordCaseDetailComponent,
    CoreLibTabsComponent,
    CoreLibTabComponent,
    CoreLibActivitiesRecordsChangesComponent,
    CoreLibCasesSelectorComponent,
    CoreLibReviewsFilterComponent,
    CoreLibNewReviewsFilterComponent,
    CoreLibDashboardWidgetComponent,
    CoreLibDoubtCollectionModalComponent,
    CoreLibAccordionComponent,
    CoreLibNestedAccordionComponent,
    CoreLibIntegrationStatusComponent,
    CoreLibPmsIntegrationStatusModalComponent,
    CoreLibOtasIntegrationStatusModalComponent,
    CoreLibNotificationsComponent,
    NotificationsComponent,
    CdkAccordionModule,
    CoreLibSemanticBoxComponent,
    CoreLibErrorWidgetComponent,
    StrengthPasswordComponent,
    CoreLibActionsWrapperComponent,
    CoreLibProgressBarComponent,
    CoreLibProductForCaseComponent,
    CoreLibFileUploaderComponent,
    CoreLibCircularDescChartComponent,
    CoreLibGenericCircularChartComponent,
    CoreLibShareModalComponent,
    CoreLibCasesAlertsComponent,
    CoreLibEmptyModalComponent,
    CoreLibNotificationsPopupModalComponent,
    CoreLibVideoImageSliderComponent,
  ],
  providers: [
    NavbarService,
    HttpClientMH,
    AlertService,
    AuthenticationService,
    UserService,
    AdminUtilService,
    CustomerService,
    ActivitiesService,
    TranslateObjectPipe,
    TranslatePipe,
    PrestayService,
    SurveyService,
    OnlineService,
    DatePipe,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'es-GB' },
    { provide: MAT_DATE_FORMATS, useValue: DATE_PICKER_FORMAT },
  ],
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [UtilService, ConfirmationModalService],
    };
  }
}
