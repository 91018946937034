import Translate from './Translate.model'
import Question from './Question.model'
import Answer from './Answer.model'

export default class Alternative {
  // BE structure
  alternative_type: { id: number, name: string }
  required:boolean;
  conditional_questions: Question[] //TODO
  created: string; // Date
  id: number;
  min_range?: string;
  max_range?: string;
  order: number;
  score: number;
  sub_alternatives: Alternative[];
  tags: any[]; // TODO
  translates: Translate[];

  answered = false;
  valid = true;

  static createFromApi(obj: any) {
    return Object.assign((new Alternative()), {
      ...obj,
      sub_alternatives: obj.sub_alternatives.map(a => Alternative.createFromApi(a)),
    })
  }

  updateState(answers: Answer[]) {
    if (this.sub_alternatives)
      this.sub_alternatives.map(s => s.updateState(answers));

    this.answered = this.isAnswered(answers)
    this.valid = this.isValid(answers)
  }

  getActiveConditionals(answers: Answer[]) {
    if (this.alternative_type.name === 'grid') {
      return this.sub_alternatives.reduce(
        (acum, a) => [...acum, ...a.getActiveConditionals(answers)],
        [],
      );
    }

    return this.isAnswered(answers) ? this.conditional_questions : [];
  }

  isAnswered(answers: Answer[]):boolean {
    return this.alternative_type.name === 'grid' ?
      this.sub_alternatives.some(a => a.answered) :
      this.findAnswer(answers).isAnswered();
  }

  isValid(answers: Answer[]) {
    return (this.alternative_type.name === 'grid')
      ? this.sub_alternatives.some(s => s.answered)
      : true; // las preguntas normales o filas grillas son las validas, no las alternativas mismas
  }

  findAnswer(answers: Answer[]):Answer {
    return answers.find(a => (
      a.sub_alternative_id === this.id ||
        a.answer_alternative_id === this.id
    )) || new Answer({});
  }

  get type() { return this.alternative_type.name; }
  get typeId() { return this.alternative_type.id; }
  get alternatives() { return this.sub_alternatives; }
}
