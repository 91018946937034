export class AddSemantic{
  allThemes:TypeTheme;
  semanticRating:string;
  semanticAreas:any[]=[];
  cut=150;
  excerpt:string;
  themeList:string[]=[];
  has_excerpt:boolean = false;

  constructor(){
    this.allThemes = new TypeTheme();
  }

  hasSemantic(){
    return this.semanticRating && this.semanticRating !== 'none';
  }

  hasSentimentInside(type='negative'){
    return this.allThemes['negative'].length>0;
  }

  getThemes(){
    if(this.hasSemantic()){
      let themes    = {}
      let positives = {};
      let neutrals  = {};
      let negatives = {};
      for(let area of this.semanticAreas){
        for(let category of area.categories){
          themes[category.category] = category.category;
          if(category.sentiment_polarity==="neutral")  neutrals[category.category] = category.category;
          if(category.sentiment_polarity==="positive") positives[category.category] = category.category;
          if(category.sentiment_polarity==="negative") negatives[category.category] = category.category;
        }
      }
      this.allThemes['positive'] = Object.keys(positives)
      this.allThemes['neutral']  = Object.keys(neutrals)
      this.allThemes['negative'] = Object.keys(negatives)
      return Object.keys(themes);
    }
    return [];
  }

  setExcerpt(comment){
    this.has_excerpt = comment && (comment.length > this.cut && (comment.length - this.cut) > 80);
    this.excerpt     = this. has_excerpt ? comment.substring(0,this.cut)+'...' : comment;
  }

  hasExcerpt(){
    return this.has_excerpt;
  }
}

export class TypeTheme{
  positive:string[] = [];
  neutral:string[]  = [];
  negative:string[] = [];
  constructor(){}
}