import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'fidelityDeskTeam',
})
export class FidelityDeskTeamPipe implements PipeTransform {
  transform(cases, filters) {
    let filteredCases = cases;
    if (filters) {
      const filter = filters.find((filterState) => filterState.name === 'team');
      const filtering = filter.options.some((option) => option.isFiltering);
      if (filter && filtering) {
        const options = filter.options.filter((option) => option.isFiltering).map((option) => option.id);
        filteredCases = cases.filter((item) => item.team_details && options.includes(item.team_details.id));
      }
    }
    return filteredCases;
  }
}
