<div>
  <section class="tw-w-full tw-py-3 tw-text-main-text tw-bg-background-modal-title tw-relative">
    <i class="far fa-times tw-cursor-pointer tw-absolute tw-right-4 tw-top-3" (click)="close()"></i>
    <section class="tw-flex tw-justify-center">
      <div class="tw-mx-auto tw-text-lg tw-font-semibold" >{{'cases.messages.monthly_limit_title' | translate}}</div>
    </section>
  </section>
  <section class="tw-px-5 tw-py-3">
    <p [innerHtml]="'cases.messages.monthly_limit' | translate:{ userName: user.name, hotelName: hotel.name, monthlyLimit: monthlyLimit }"></p>
    <p class="tw-my-3">{{ (!user?.admin ? 'cases.messages.improve_plan' : 'cases.messages.improve_plan_admin') | translate }}</p>
    <mh-core-lib-button
      class="tw-flex tw-justify-center"
      [isDefault]="true"
      [text]="!user?.admin ? 'cases.messages.monthly_limit_button' : 'cases.messages.improve_plan_button' | translate"
      (clicked)="goToUpsellLink()"
    >
    </mh-core-lib-button>
  </section>

</div>
