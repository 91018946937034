<div class="box_review" [class.active]="equal_guest" [attr.data-id]="review.id" [hidden]="review.deleted">
  <div class="top_review">
    <div class="mb-2 row no-gutters">
      <div class="flex-row-reverse col-12 d-flex justify-content-between">
        <div class="top_actions" *ngIf="can_edit_survey">
          <i class="far fa-angle-down" mat-icon-button [matMenuTriggerFor]="menu"></i>
          <mat-menu #menu="matMenu">
            <div class="action_top check" mat-menu-item (click)="checkReview(review)" class="btn" [hidden]="review.isAnswered() || !review.isCommentable()">
              <i class="fa fa-check"></i>
              <span>{{'online.reviews.check_read' | translate }}</span>
            </div>

            <div class="action_top delete"  mat-menu-item (click)="openDialog(show_dialog)">
              <i class="far fa-minus-circle"></i>
              <span>{{'shared.reviews.delete_review' | translate }}</span>
            </div>

          </mat-menu>
        </div>
        <span *ngIf="isSuperAdmin" class="text-muted"><b><small>ID: {{review.id}}</small></b></span>
      </div>
      <div class="col-sm-12 col-md-2 col-lg-2 align-center">
        <div class="country">
          <ng-container *ngIf="review.guest.country.code==''; else countryTemplate">
            <div class="without_country">mH</div>
          </ng-container>
          <ng-template #countryTemplate>
            <span class="flag-icon flag-icon-squared flag-icon-{{review.guest.country.code | lowercase}}"></span>
            <div class="name_country ellipsis">{{review.guest.country.name}}</div>
          </ng-template>
        </div>
        <div class="box_info_user">
          <div class="name">{{review.guest.username}}</div>
          <div class="travel_type" [hidden]="(review.typeTraveler.id==0)">{{'commons.type_traveler.type_'+review.typeTraveler.id | translate}}</div>
          <div class="box_date">
            <i class="fal fa-clock"></i>
            <span class="date" [innerHtml]="(review.publicationDate | formatDate: false:showDay)"></span>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-8 col-lg-8">
        <div class="box_commet">
          <div class="comment" [class.excerpt]="showingMore">

            <div class="review_title">{{review.title}} </div>
            <div class="review_body">
              <span>{{ (showingMore ? review.description : review.excerpt ) }}</span>
              <div class="see_all" [hidden]="showingMore" (click)="seeAll()"><span >{{ 'online.reviews.see_all_review' | translate }}</span><i class="fal fa-chevron-down"></i></div>
            </div >

            <div class="result_comment">
              <div class="wait_translate">
                <mh-spinner-load [hidden]="!waitTranslate"></mh-spinner-load>
              </div>
              <div class="review_title">{{translateReview?.title}} </div>
              <div class="review_body">{{translateReview?.description}}</div>
            </div>


          </div>
          <div *ngIf="review.hasSentimentInside()" class="semantic {{review.semanticRating}}">
            <mh-semantic-header [productId]="3" [commentId]="review.id"></mh-semantic-header>
          </div>

        </div>
      </div>

      <div class="col-sm-12 col-md-2 col-lg-2 align-right">

        <div class="content_scores">
          <div class="score_result">
            <div class="legend" [innerHtml]="('online.reviews.qualify_in' | translate:({name: 'myHotel'}) )" ></div>
            <div class="qualify">{{ review.myHotelRaiting | validateShowData }} | <span class="scale">5.0</span></div>
          </div>

          <div class="ota_qualify">
            <div class="ota_info">
              <div class="ota_name">{{review.ota.name}}</div>
              <div class="qualify">{{review.otaRaiting | formatBy:'rating'}} | <span class="scale">{{review.ota.scale | formatBy:'rating'}}</span></div>
            </div>
            <div class="ota_logo">
              <img [src]="review.ota.id | getOtaLogo" alt="{{review.ota.name}}">
            </div>
          </div>

        </div>

      </div>
      <div class="col-md-8 col-lg-8 offset-sm-2 divide" *ngIf="areAnswer"></div>
    </div>
    <div class="mt-2 row no-gutters" *ngIf="areAnswer">
      <div class="col-md-8 offset-sm-2">
        <div class="toggle pointer" (click)="toggleAnswer()">
          <span class="icon"><i class="fas fa-comment"></i></span>
          <span class="text">{{ 'online.reviews.see_answer' | translate }}</span>
          <span class="icon"><i [@rotate]="rotateAnimationState" class="far fa-angle-up"></i></span>
        </div>
      </div>
    </div>
    <div class="mt-2 row no-gutters" *ngIf="areAnswer && showAnswer" [@fade]>
      <div class="col-md-8 offset-sm-2">
        <span>{{reviewAnswer}}</span>
      </div>
    </div>
  </div>
  <div class="actions" *ngIf="!isCompetitor()">
    <div class="row">
      <div class='col-12 col-md-8'>


        <ng-container *ngIf="review.isCommentable(); else notComentable">
          <div class="action_box answered_box" [ngClass]="{'answered' : review.isAnswered(), 'not_answered' : !review.isAnswered()}">
            <i class="fa fa-{{review.isAnswered() ? 'check' : 'exclamation'}}"></i>
            <span>
              {{'online.reviews.answereds.' + (review.isAnswered() ? '' : 'not_' ) + 'answered' | translate}}
            </span>
          </div>

          <div class="mr-1 action_box" *ngIf="isGoogleReview && !areAnswer">
            <a href="javascript:void(0)" (click)="handleReplyReviewFromFS()">
              <img [src]="review.ota.id | getOtaLogo" [width]="30">
              <span>{{'online.reviews.reply_in' | translate: {ota: otaName} }}</span>
            </a>
          </div>

          <div class="action_box" *ngIf="!isGoogleReview">
            <a [href]="review.url" target="_blank">
              <i class="fa fa-{{(review.isAnswered() ? 'comment' : 'reply')}}"></i>
              <span>{{'online.reviews.'+(review.isAnswered() ? 'go_comment' : 'reply_in') | translate:({ota: review.ota.name})}}</span>
            </a>
          </div>

          <ng-container *ngTemplateOutlet="showTranslationsActions"></ng-container>
        </ng-container>

        <ng-template #notComentable>
          <div class="action_box">
            {{'online.reviews.not_comentable' | translate }}
          </div>
          <div class="action_box">
            <a [href]="review.url" target="_blank">
              <i class="fa fa-comment"></i>
              <span>{{'online.reviews.go_comment' | translate:({ota: review.ota.name})}}</span>
            </a>
          </div>
          <ng-container *ngTemplateOutlet="showTranslationsActions"></ng-container>
        </ng-template>
      </div>

      <div class='col-12 col-md-4 align-right'>
        <div class="tw-flex tw-justify-end" *ngIf="showCases">
          <mh-core-lib-button 
            [isDefault]="true" 
            [text]="'cases.commons.create_case' | translate"
            (clicked)="handleCreateCase()"
          >
          </mh-core-lib-button>

          <mh-core-lib-button 
            class="tw-ml-2" 
            [hidden]="review.cases.length === 0" 
            [isSecondary]="true"
            [text]="'cases.commons.created_cases' | translate"
            (clicked)="actionsOpen = !actionsOpen"
            cdkOverlayOrigin 
            #trigger="cdkOverlayOrigin"
          >
          </mh-core-lib-button>
          <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="actionsOpen"
            [cdkConnectedOverlayPositions]="positions"
            [cdkConnectedOverlayOffsetY]="50"
            (overlayOutsideClick)="actionsOpen = !actionsOpen"
          >
            <div class="tw-bg-white tw-rounded-lg tw-shadow-1 tw-relative tw-py-3 tw-min-w-[220px]">
              <ul class="tw-divide-y">
                <li *ngFor="let cases of review.cases" class="tw-cursor-pointer tw-px-4 tw-py-1 tw-font-semibold tw-text-main-text hover:tw-text-main-orange tw-transition-all">
                  <a [href]="'/cases/'+ ((cases?.closed === true || cases?.closed === undefined) ? 'history' : 'active') +'?id='+cases.public_id" target="_blank">{{cases.title}}</a>
                </li>
              </ul>
            </div>
          </ng-template>
        </div>
        <mh-case-product-actions *ngIf="showCases && false"
          [productItem]="getProductItem(review.id)"
          [cases]="review.cases"
          [sourceWindow]="sourceWindow"
          [createCase]="createCase"
        ></mh-case-product-actions>
      </div>
    </div>
  </div>
</div>


<ng-template #tooltipPositive>
  <div [innerHtml]="('shared.semantic.sentiment.positive' | translate)"></div>
</ng-template>
<ng-template #tooltipNegative>
  <div [innerHtml]="('shared.semantic.sentiment.negative' | translate)"></div>
</ng-template>
<ng-template #tooltipNeutral>
  <div [innerHtml]="('shared.semantic.sentiment.neutral' | translate)"></div>
</ng-template>


<ng-template #show_dialog let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class='title' [innerHtml]="('shared.reviews.confirm_delete' | translate:({user: util.currentUser }) )"></h4>
  </div>
  <div class="modal-body">
    <div class="dialog_options">
      <div class="fade_waiting" [hidden]="!waitDelete">
        <mh-spinner-load></mh-spinner-load>
      </div>

      <div class="row" [hidden]="waitDelete">
        <div class="col-6">
          <button (click)="deleteReview()" class="btn btn-success">{{'commons.yes' | translate }}</button>
        </div>
        <div class="col-6">
          <button (click)="closeDialog()" class="btn btn-secondary">{{'commons.no' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #showTranslationsActions>
  <div class="action_box">
    <widget-translate-options
      [review]="review"
      [showTranslations]="showTranslations"
      (reviewTranslated)="setTranslatedReview($event)"
      (waitTranslate)="setTranslateWaiting($event)">
    </widget-translate-options>
  </div>
</ng-template>
