// Modules
import { NgModule }                     from '@angular/core';
import { SharedModule}                  from "../shared/shared.module";

// Routigns
import { ConfigurationRoutingModule }           from './configuration-routing.module';

import { ConfigurationService } from './configuration.service';

// Components
import {
  ConfigurationAlertsComponent,
  AlertDisplayModal,
  ConfigurationSummaryComponent,
  ProfileComponent,
  SuperAdminPropertiesComponent,
  EditUserProfile,
  EditUserPassword,
  UserProductShowComponent,
  UserCustomersShowComponent,
  EditProductAccess,
  EditProductAlerts,
  EditUserSummaries,
  UserEditComponent,
  NewUser,
  UsersComponent,
  UserProductEditComponent
}                                          from "./index";
import { SetPasswordComponent } from './edit/user/set-password/set-password.component';
import { ProfileConfigurationComponent } from './profile/profile-configuration/profile-configuration.component';
import { AlertsTranslatePipe } from './edit/product/alerts/alerts-translate.pipe';

@NgModule({
    imports: [
        ConfigurationRoutingModule,
        SharedModule,
    ],
    declarations: [
        ConfigurationAlertsComponent,
        AlertDisplayModal,
        ConfigurationSummaryComponent,
        ProfileComponent,
        SuperAdminPropertiesComponent,
        EditUserProfile,
        EditUserPassword,
        UserProductShowComponent,
        UserCustomersShowComponent,
        EditProductAccess,
        EditProductAlerts,
        EditUserSummaries,
        UserEditComponent,
        NewUser,
        UsersComponent,
        UserProductEditComponent,
        SetPasswordComponent,
        ProfileConfigurationComponent,
        AlertsTranslatePipe,
    ],
    exports: [
        SuperAdminPropertiesComponent,
        EditUserProfile,
        EditUserPassword,
        UserProductShowComponent,
        UserCustomersShowComponent,
        EditProductAccess,
        EditProductAlerts,
        EditUserSummaries,
        UserEditComponent,
        NewUser,
        UserProductEditComponent
    ],
    providers: [
        ConfigurationService
    ]
})
export class ConfigurationModule { }




