import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SurveyAnswerPublicService {

  surveysAndAnswers = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) { }

  getSections(survey_id: number) {
    const url = `${environment.surveysPath}${survey_id}/sections`;
    return this.http.get(url);
  }

  getConditionalQuestions(survey_id: number) {
    const url = `${environment.surveysPath}${survey_id}/conditional/questions`;
    return this.http.get(url);
  }

  getAnswerPublic(surveyId: any, answerId: any): any {
    const url = `${environment.surveysPath}${surveyId}/answered/${answerId}/answers`
    return this.http.get(url)
  }

  getGeneralInfo(survey_id: number) {
    const url = `${environment.surveysPath}${survey_id}/general`;
    return this.http.get(url);
  }

  getSurveysAndAnswersValue() {
    return this.surveysAndAnswers.getValue()
  }

}
