<article class="tw-relative">
  <section class="tw-w-full tw-p-5 xl:tw-p-2 2xl:tw-p-3 tw-text-main-text">
    <section class="tw-flex tw-justify-center">
      <div class="tw-mx-auto tw-text-xl tw-font-semibold">{{'core-lib.sender.variables' | translate}}</div>
      <span class="hover:tw-cursor-pointer" (click)="handleClose()">
        <i class="far fa-times"></i>
      </span>
    </section>
  </section>

  <section class="tw-mt-5">
    <p class="tw-text-main-text tw-font-medium">
    {{'core-lib.sender.note' | translate}}
    </p>
  </section>

  <section class="tw-mt-5">

    <mh-core-lib-table [showFooter]="false" [showPaginator]="false" class="tw-w-full">
      <ng-container thead>
        <ng-container *ngTemplateOutlet="head"></ng-container>
      </ng-container>
      <ng-container tbody>
        <ng-container *ngTemplateOutlet="body"></ng-container>
      </ng-container>
    </mh-core-lib-table>

  </section>
</article>

<ng-template #head>
  <tr>
    <th>{{'core-lib.sender.variable' | translate}}</th>
    <th>{{'core-lib.sender.replace' | translate}}</th>
  </tr>
</ng-template>

<ng-template #body>
  <tr *ngFor="let key of variables" class="!tw-h-[45px]">
    <td>
      <div class="tw-flex tw-gap-3">
        <span>
          <button type="button" class="focus:tw-outline-0 active:tw-text-main-font tw-text-main-text" (click)="copyToClipboard(key)">
            <i class="fas fa-copy"></i>
          </button>
        </span>
        <span>
          &lt;var&gt;{{key}}&lt;/var&gt;
        </span>
      </div>
    </td>
    <td>{{'core-lib.sender.'+key | translate}}</td>
  </tr>
</ng-template>
