export default class Translate {
  id: number;
  language: { id: number, code: string};
  text: string;
  titile?: string;
  min_text?: string;
  max_text?: string;
  header_msg: string;

  constructor(obj: any) {
    Object.assign(this, obj)
  }
}
