import { Component, HostListener, Input } from '@angular/core';
import { UsersService } from 'app/configuration/users/users.service';
import { ModalService } from 'app/core-lib/dialogs/modal/modal.service';
import { ReportsService } from 'app/reports/reports.service';
import { SummarySemanticService } from 'app/semantic/services/summary-semantic.service';
import { DateService } from 'app/shared/services/date.service';
import { UtilService } from 'app/shared/util.service';
import * as FileSaver from 'file-saver';
import { CorecLibSuccessModalComponent } from 'app/core-lib/dialogs/success-modal/success-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mh-core-lib-report-button',
  templateUrl: './report-button.component.html',
  styleUrls: ['./report-button.component.scss'],
  providers: [ReportsService],
})
export class CoreLibReportButtonComponent {
  @Input()
  type;

  @Input()
  currentSurveyId;

  @Input()
  chain;

  @Input()
  isFromResponseRate = false;

  @Input()
  isFromSemantic = false;

  @Input()
  isFromUserEntries = false;

  @Input()
  isSingleCustomer = false;

  @Input() activeEmail = true;
  selectedCompetitor: any;
  competitorSubscription: Subscription;

  @Input() height;
  @Input() customClass;

  isOpen = false;
  waiting = false;

  @HostListener('document:click', ['$event'])
  onClick(event) {
    const result = this.checkClickInside(event.target);
    if (!result) {
      this.isOpen = false;
    }
  }

  constructor(
    private semanticService: SummarySemanticService,
    private utilService: UtilService,
    private dateService: DateService,
    private usersService: UsersService,
    private reportsService: ReportsService,
    private coreLibModalService: ModalService,
    private translateService: TranslateService,
  ) {
    this.competitorSubscription = this.utilService.isCompetitorsChanged().subscribe( competitor => {
      this.selectedCompetitor = competitor
    })
  }

  ngOnDestroy(): void {
    this.competitorSubscription.unsubscribe()
  }

  checkClickInside(target) {
    if (target.className?.includes('report-button')) {
      return true;
    } else if (target.parentNode) {
      return this.checkClickInside(target.parentNode);
    } else {
      return false;
    }
  }

  getReport(sendEmail = false, isForOnlineReview = false, isTimeoutError = false) {    
    this.utilService.ga('', 'download', 'deskReportType');
    
    this.waiting = true;
    if (this.isFromSemantic) {
      const email = sendEmail ? this.utilService.currentUser?.email || '' : '';
      this.semanticService
        .getReportSemantic(
          this.initDate,
          this.endDate,
          this.utilService.getCurrentLanguageId(),
          this.type,
          this.utilService.currentHotel.id,
          email,
        )
        .subscribe(
          (response) => {
            this.handleReportServiceResponse(response, email, isTimeoutError);
          },
          () => {
            this.handleReportTimeoutError();
            // this.errorAlert();
          },
        );
    } else if (this.isFromUserEntries) {
      this.utilService.ga('report', 'download', this.type);
      this.usersService
        .getReportUserEntries(this.initDate, this.endDate, this.utilService.getCurrentLanguageId().toString())
        .subscribe(
          (response) => {
            this.downloadFile(response);
            this.waiting = false;
          },
          () => {
            this.errorAlert();
          },
        );
    } else if (this.isFromResponseRate) {
      let email: string = null;
      if (sendEmail) {
        email = this.utilService.currentUser.email;
      }
      this.utilService.ga('report', 'download', this.type);
      this.reportsService
        .responseRate(
          { startDate: this.initDate, endDate: this.endDate },
          this.utilService.getCurrentLanguageId().toString(),
          this.currentSurveyId,
          email,
        )
        .subscribe(
          (response) => {
            this.handleReportServiceResponse(response, email, isTimeoutError);
            this.waiting = false;
          },
          () => {
            this.handleReportTimeoutError();
          },
        );
    } else {
      const chain = this.chain || [];

      const dates = {
        startDate: this.initDate,
        endDate: this.endDate,
      };

      let email: any = undefined;

      if (sendEmail) {
        email = this.utilService.currentUser.email;
      }

      this.utilService.ga('report', 'download', this.type);
      const VERSION = 2;
      if (this.isSingleCustomer && sendEmail) {
        this.reportsService[this.type](
          dates,
          this.utilService.currentHotel.id,
          this.utilService.getLanguageId(),
          email,
          VERSION,
          2,
        ).subscribe(
          (response) => {
            this.handleReportServiceResponse(response, email, isTimeoutError);
          },
          () => {
            this.errorAlert();
          },
        );
      } else if (!this.isSingleCustomer && chain.length > 1) {        
        const customerIds = chain.map((x: any) => x.id).join(',');
        const type = isForOnlineReview ? 'chainOnlineSummaryReview' : this.type;
        this.reportsService[type](
          dates,
          this.selectedCompetitor?.id ?? this.utilService.currentHotel.id,
          this.utilService.getLanguageId(),
          customerIds,
          email,
          VERSION,
        ).subscribe(
          (response) => {
            this.handleReportServiceResponse(response, email, isTimeoutError);
          },
          () => {
            this.handleReportTimeoutError();
          },
        );
      } else {        
        this.reportsService[this.type](
          dates,
          this.utilService.currentHotel.id,
          this.utilService.getLanguageId(),
          null,
          VERSION,
          this.currentSurveyId,
        ).subscribe(
          (response) => {
            this.downloadFile(response);
          },
          () => {
            this.handleReportTimeoutError();
            // this.errorAlert();
          },
        );
      }
    }
  }

  handleReportServiceResponse(response, email, isError) {
    if (email) {
      this.waiting = false;
      const classes = ['overlay-panel', 'py-4', 'fit-height-content'];
      const config = {
        data: {
          title: this.translateService.instant('reports.alerts.send.success.success_title'),
          body: this.translateService.instant(
            isError ? 'reports.alerts.send.success.error_body' : 'reports.alerts.send.success.success_body',
            { email: email },
          ),
        },
      };
      const dialogRef = this.coreLibModalService.open(CorecLibSuccessModalComponent, config, classes);
    } else this.downloadFile(response);
  }

  downloadFile(response) {
    const url = response.awsUrl;
    const filename = response.name;
    let self = this;

    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';

    xhr.onreadystatechange = function () {
      setTimeout(() => {
        self.waiting = false;
      }, 0);
      if (xhr.readyState === 4 && xhr.status === 200) {
        const blob = new Blob([this.response], { type: 'application/vnd.ms-excel' });
        FileSaver.saveAs(blob, filename);
      }
    };

    xhr.send();
  }

  errorAlert() {
    this.utilService.sendAlert('errors.login.something_went_wrong');
    this.waiting = false;
  }

  handleReportTimeoutError() {
    this.getReport(true, false, true);
  }

  get currentDate() {
    return this.dateService.currentDates;
  }

  get initDate() {
    return this.currentDate.startDate;
  }

  get endDate() {
    return this.currentDate.endDate;
  }
}