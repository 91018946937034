<section
  class="autocomplete-input tw-relative autocomplete-container-input"
  [class.autocomplete-input]="showGlassIcon"
>
  <input
    #autocompleteInput
    id="autocompleteInput"
    autocomplete="off"
    type="text"
    class="focus:tw-ring-0 focus-visible:tw-border-2 focus-visible:tw-border-border-main tw-text-main-text tw-w-full tw-min-w-[300px] tw-py-2 tw-pr-8 tw-pl-8 tw-rounded-xl tw-border-border-light-gray focus:tw-border-border-light-gray disabled:tw-bg-second-disabled {{customClassInput}}"
    [placeholder]="placeholder"
    [formControl]="autocompleteControl"
    cdkOverlayOrigin #trigger="cdkOverlayOrigin"
    (focus)="handleFocus()"
  >

  <div *ngIf="filteredOptions && showOptions && showIcon" class="tw-absolute tw-flex tw-justify-center tw-right-[10px] tw-top-3 tw-w-[20px] tw-h-[20px] tw-cursor-pointer tw-text-main" [@rotate]="openBox ? '180' : 0" (click)="toggleBox()">
    <i class="{{ iconClass }}"></i>
  </div>

  <div *ngIf="((filteredOptions | async) && (filteredOptions | async).length !== 0 ) && showOptions" id="menuAutocomplete" [class.tw-hidden]="!openBox" class="tw-w-full tw-mt-1.5 {{ customClassBox }}">
    <mh-core-lib-generic-box customClass='tw-mt-3'>
      <section class="tw-py-3 tw-max-h-[160px] tw-overflow-auto">
        <ng-container *ngFor="let option of filteredOptions | async">
          <article class="tw-p-1 tw-flex tw-flex-col tw-text-main-text hover:tw-text-main hover:tw-cursor-pointer hover:tw-bg-background-gray/50 autocomplete-option" (click)="selectOption(option)">
            <span class="tw-font-medium tw-text-base {{ customClassOptions }}">{{displayOptionsFn(option)}}</span>
            <span class="tw-text-[14px] {{ customClassOptions }}" *ngIf="displayOptionsEmailFn">{{displayOptionsEmailFn(option)}}</span>
          </article>
        </ng-container>
      </section>
    </mh-core-lib-generic-box>
  </div>

</section>
