<article class="tw-flex tw-space-x-4">
  <button
      class="tw-rounded-full md:tw-w-fit tw-w-14 tw-h-14 tw-bg-white tw-text-main tw-shadow-1 tw-flex tw-justify-center md:tw-px-7 md:tw-py-2 focus:tw-outline-0"
      (click)="isOpen = !isOpen"
      cdkOverlayOrigin #trigger="cdkOverlayOrigin"
      [class.!tw-bg-main]="isOpen"
      [class.!tw-text-white]="isOpen"
      >
      <i class="fas fa-filter tw-self-center"></i>
      <span class="tw-hidden md:tw-inline-block tw-ml-2 tw-text-base tw-font-medium tw-self-center" [class.!tw-text-white]="isOpen">
        {{'core-lib.selectors.guest.filter_by' | translate}}
      </span>
  </button>

  <button class="tw-rounded-full tw-bg-white tw-text-main tw-shadow-1 tw-w-14 tw-h-14 hover:tw-text-white hover:tw-bg-main tw-transition-all focus:tw-outline-0" (click)="clearFilters()" *ngIf="areFiltersApplied">
    <div class="tw-relative">
      <i class="fas fa-filter tw-self-center"></i>
      <i class="fas fa-times-circle tw-absolute tw-text-red-500 tw-text-small tw-right-3.5"></i>
    </div>
  </button>
</article>


<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayPanelClass]="['!tw-inset-x-0', 'tw-mx-auto', 'tw-absolute', 'overlay-centered']"
>

<div class="tw-bg-white tw-shadow-1 tw-rounded tw-flex tw-flex-col tw-mt-3" id="overlayFiltersGuest">

  <div class="tw-flex tw-flex-col xl:tw-flex-row xl:tw-border-b-2 xl:tw-border-border-light-gray">

      <div class="tw-min-w-[240px] xl:tw-min-h-[180px] tw-max-w-[240px] tw-max-h-[180px] xl:tw-border-r-2 xl:tw-border-border-light-gray tw-pb-1 tw-pt-3 tw-px-3 xl:tw-p-3">
        <div class="tw-flex" (click)="toggleMobileFilter('nps')">
          <span class="tw-mr-2 tw-font-medium tw-text-main-text">{{'core-lib.selectors.guest.filters_title.nps' | translate}}</span>
          <i [@rotate]="npsFilterStatus ? '-180' : '0'" class="far fa-angle-down tw-text-main tw-self-center xl:tw-hidden"></i>
        </div>
        <div  [@collapsedHeightState]="npsFilterStatus ? 'expand' : 'collapse'">
          <mh-core-lib-checkbox-input
              [options]="npsOptions"
              [translatePrefix]="'qualifys.'"
              (optionClicked)="updateGenericCheckboxFilter($event, npsOptions, 'ChangeNPSFilter')">
          </mh-core-lib-checkbox-input>
        </div>
      </div>

      <div class="tw-min-w-[240px] xl:tw-min-h-[180px] tw-max-w-[240px] tw-max-h-[180px] xl:tw-border-r-2 xl:tw-border-border-light-gray tw-py-1 tw-px-3 xl:tw-p-3">
        <div class="tw-flex" (click)="toggleMobileFilter('country')">
          <span class="tw-mr-2 tw-font-medium tw-text-main-text">{{'core-lib.selectors.guest.filters_title.country' | translate}}</span>
          <i [@rotate]="countryFilterStatus ? '-180' : '0'" class="far fa-angle-down tw-text-main tw-self-center xl:tw-hidden"></i>
        </div>
        <div [@collapsedHeightState]="countryFilterStatus ? 'expand' : 'collapse'">
          <mh-core-lib-generic-selector
              id="filterCountryGuest"
              [translatePrefix]="'countries.'"
              [verifyClickId]="'filterCountryGuest'"
              [options]="countryOptions"
              [selectedOptionsFromParent]="countryOptions"
              [placeholder]="'core-lib.selectors.guest.filters_placeholder.select_country' | translate"
              [isCountry]="true"
              (optionClicked)="updateGenericFilter($event, countryOptions, 'ChangeCountriesFilter')"
              ></mh-core-lib-generic-selector>
        </div>
      </div>

      <div *ngIf="!isRestaurant" class="tw-min-w-[240px] xl:tw-min-h-[180px] tw-max-w-[240px] tw-max-h-[180px] xl:tw-border-r-2 xl:tw-border-border-light-gray tw-py-1 tw-px-3 xl:tw-p-3">
        <div class="tw-flex" (click)="toggleMobileFilter('room')">
          <span class="tw-mr-2 tw-font-medium tw-text-main-text">
            <ng-container *ngIf="!isAirline">
              {{'core-lib.selectors.guest.filters_title.room' | translate}}
            </ng-container>
            <ng-container *ngIf="isAirline">
              {{'commons.fly_number' | translate}}
            </ng-container>
          </span>
          <i [@rotate]="roomFilterStatus ? '-180' : '0'" class="far fa-angle-down tw-text-main tw-self-center xl:tw-hidden" ></i>
        </div>
        <div [@collapsedHeightState]="roomFilterStatus ? 'expand' : 'collapse'">
          <mh-core-lib-generic-selector
              id="filterRoomGuest"
              [verifyClickId]="'filterRoomGuest'"
              [options]="roomOptions"
              [selectedOptionsFromParent]="roomOptions"
              [placeholder]="!isAirline ? ('core-lib.selectors.guest.filters_placeholder.room_number' | translate) : ('core-lib.selectors.guest.filters_placeholder.select' | translate)"
              (optionClicked)="updateGenericFilter($event, roomOptions, 'ChangeRoomsFilter')"
              ></mh-core-lib-generic-selector>
        </div>
      </div>

      <div class="tw-min-w-[240px] xl:tw-min-h-[180px] tw-max-w-[240px] tw-max-h-[180px] xl:tw-border-r-2 xl:tw-border-border-light-gray tw-py-1 tw-px-3 xl:tw-p-3">
        <div class="tw-flex" (click)="toggleMobileFilter('language')">
          <span class="tw-mr-2 tw-font-medium tw-text-main-text">{{'core-lib.selectors.guest.filters_title.language' | translate}}</span>
          <i [@rotate]="languageFilterStatus ? '-180' : '0'" class="far fa-angle-down tw-text-main tw-self-center xl:tw-hidden"></i>
        </div>
        <div [@collapsedHeightState]="languageFilterStatus ? 'expand' : 'collapse'">
          <mh-core-lib-checkbox-input
              [options]="languageOptions"
              [translatePrefix]="'languages.id.'"
              (optionClicked)="updateGenericCheckboxFilter($event, languageOptions, 'ChangeLanguageFilter')">
          </mh-core-lib-checkbox-input>
        </div>
      </div>
  </div>
  <div class="tw-flex tw-flex-col xl:tw-flex-row">
    <div class="tw-min-w-[240px] xl:tw-min-h-[180px] tw-max-w-[240px] tw-max-h-[180px] tw-py-1 tw-px-3 xl:tw-p-3 xl:tw-border-r-2 xl:tw-border-border-light-gray tw-overflow-auto">
      <div class="tw-flex" (click)="toggleMobileFilter('origin')">
        <span class="tw-mr-2 tw-font-medium tw-text-main-text">{{'core-lib.selectors.guest.filters_title.origin' | translate}}</span>
        <i [@rotate]="originFilterStatus ? '-180' : '0'" class="far fa-angle-down tw-text-main tw-self-center xl:tw-hidden"></i>
      </div>
      <div [@collapsedHeightState]="originFilterStatus ? 'expand' : 'collapse'">
        <mh-core-lib-checkbox-input
            [options]="originOptions"
            [needTranslate]="false"
            (optionClicked)="updateGenericCheckboxFilter($event, originOptions, 'ChangeOriginNameFilter')">
        </mh-core-lib-checkbox-input>
      </div>
    </div>
    <div class="tw-min-w-[240px] xl:tw-min-h-[180px] tw-max-w-[240px] tw-max-h-[180px] xl:tw-border-r-2 xl:tw-border-border-light-gray tw-p-1 tw-px-3 xl:tw-p-3">
      <div class="tw-flex" (click)="toggleMobileFilter('integration')">
        <span class="tw-mr-2 tw-font-medium tw-text-main-text">{{'core-lib.selectors.guest.filters_title.integration_channel' | translate}}</span>
        <i [@rotate]="integrationFilterStatus ? '-180' : '0'" class="far fa-angle-down tw-text-main tw-self-center xl:tw-hidden"></i>
      </div>
      <div [@collapsedHeightState]="integrationFilterStatus ? 'expand' : 'collapse'">
        <mh-core-lib-generic-selector
            id="filterIntegrationGuest"
            [verifyClickId]="'filterIntegrationGuest'"
            [placeholder]="'core-lib.selectors.guest.filters_placeholder.select' | translate"
            [options]="channelOptions"
            [selectedOptionsFromParent]="channelOptions"
            [displaySelectedValue]="'text'"
            (optionClicked)="updateGenericFilter($event, channelOptions, 'ChangeChannelFilter')"
            ></mh-core-lib-generic-selector>
      </div>
    </div>

    <div class=" tw-min-w-[240px] xl:tw-min-h-[180px] tw-max-w-[240px] tw-max-h-[180px] xl:tw-border-r-2 xl:tw-border-border-light-gray tw-p-1 tw-px-3 xl:tw-p-3">
      <div class="tw-flex" (click)="toggleMobileFilter('cases')">
        <span class="tw-mr-2 tw-font-medium tw-text-main-text">{{'core-lib.selectors.guest.filters_title.cases' | translate}}</span>
        <i [@rotate]="casesFilterStatus ? '-180' : '0'" class="far fa-angle-down tw-text-main tw-self-center xl:tw-hidden"></i>
      </div>
      <div [@collapsedHeightState]="casesFilterStatus ? 'expand' : 'collapse'">
        <mh-core-lib-checkbox-input
            [options]="deskOptions"
            [translatePrefix]="'followup.survey.filter.'"
            (optionClicked)="updateCasesFilter($event)">
        </mh-core-lib-checkbox-input>
      </div>
    </div>

    <div class="tw-min-w-[240px] xl:tw-min-h-[180px] tw-max-w-[240px] tw-max-h-[180px] xl:tw-border-r-2 xl:tw-border-border-light-gray tw-pt-1 tw-pb-3 tw-px-3 xl:tw-p-3">
      <div class="tw-flex" (click)="toggleMobileFilter('guests')">
        <span class="tw-mr-2 tw-font-medium tw-text-main-text">
          <ng-container *ngIf="!isAirline && !isRestaurant">
            {{'core-lib.selectors.guest.filters_title.contacted_guests' | translate}}
          </ng-container>
          <ng-container *ngIf="isAirline">
            {{'core-lib.selectors.guest.filters_title.passenger_contacted' | translate}}
          </ng-container>
          <ng-container *ngIf="isRestaurant">
            {{'core-lib.selectors.guest.filters_title.customer_contacted' | translate}}
          </ng-container>
        </span>
        <i [@rotate]="guestsFilterStatus ? '-180' : '0'" class="far fa-angle-down tw-text-main tw-self-center xl:tw-hidden"></i>
      </div>
      <div [@collapsedHeightState]="guestsFilterStatus ? 'expand' : 'collapse'">
        <mh-core-lib-checkbox-input
            [options]="contactedOptions"
            [translatePrefix]="isAirline ? 'followup.survey.filter.passenger_' : isRestaurant ? 'followup.survey.filter.customer_' : 'followup.survey.filter.guest_'"
            (optionClicked)="updateContactedFilter($event)">
        </mh-core-lib-checkbox-input>
      </div>
    </div>
  </div>
</div>

</ng-template>
