import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'mh-survey-question-text',
  templateUrl: './survey-question-text.component.html',
  styleUrls: ['./survey-question-text.component.scss']
})
export class SurveyQuestionTextComponent implements OnInit {
  @Input() questionId:number;
  @Input() alternatives:any[]
  @Input() answers:any[];
  @Input() language:string;
  @Input() conditionalId:number;
  @Input() preview = false;

  @Output() answer:EventEmitter<any> = new EventEmitter()

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (!this.conditionalId || !this.getAnswer().answer_text)
      return;

    this.answer.emit(this.createAnswer());
  }

  handleBlur(event:any) {
    const { target: { value } } = event

    this.answer.emit(this.createAnswer(value));
  }

  createAnswer(value?: string) {
    const prevAnswer = this.getAnswer();

    return {
      id: prevAnswer.id,
      tempId: prevAnswer.tempId || uuid(),
      question_id: this.questionId,
      answer_text: value,
      answer_alternative_id: this.alternatives[0].id,
      conditional_alternative_id: this.conditionalId,
    }
  }

  getAnswer() {
    return this.answers.find(({question_id, conditional_alternative_id}) =>
      question_id === this.questionId &&
        (!this.conditionalId || this.conditionalId === conditional_alternative_id)
    ) || {};
  }
}
