import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'mh-core-lib-desk-widget',
  templateUrl: './core-lib-desk-widget.component.html',
  styleUrls: ['./core-lib-desk-widget.component.scss'],
})
export class CoreLibDeskWidgetComponent {
  overlayPositions = [{ originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' }];
  isOpen = false;

  @Input()
  case: any;

  @Input()
  isHistoryPage = false;

  @Input()
  currentUser = null;

  @Output()
  clicked = new EventEmitter();

  @Output()
  deleteEvent = new EventEmitter();

  @HostListener('document:click', ['$event'])
  onClick(event) {
    const result = this.checkClickInside(event.target);
    if (!result) {
      this.isOpen = false;
    }
  }

  checkClickInside(target) {
    if (target.id === 'deleteMenu' || target.className?.includes('delete-trigger')) {
      return true;
    } else if (target.parentNode) {
      return this.checkClickInside(target.parentNode);
    } else {
      return false;
    }
  }

  handleBoxClick() {
    this.isOpen = false;
    this.clicked.emit(this.case);
  }

  handleDeleteCase() {
    this.deleteEvent.emit(this.case);
  }

  get isSuperAdmin() {
    return this.currentUser.superAdmin;
  }
}
