<div class="timestamp">{{action.timestamp | date:'medium':'-400':'en-US'}} UTC</div>

<mat-expansion-panel *ngIf="expandable; else nonExpandable;">
  <mat-expansion-panel-header>
    <ng-container *ngTemplateOutlet="thumbnail"></ng-container>
    <ng-container *ngTemplateOutlet="nameAndTitle"></ng-container>
    <ng-container *ngTemplateOutlet="customerAndTarget"></ng-container>
  </mat-expansion-panel-header>
  <ng-content select="[body]"></ng-content>
</mat-expansion-panel>

<ng-template #nonExpandable>
  <mat-card class="mat-card">
    <ng-container *ngTemplateOutlet="thumbnail"></ng-container>
    <ng-container *ngTemplateOutlet="nameAndTitle"></ng-container>
  </mat-card>
</ng-template>


<ng-template #thumbnail>
  <mh-user-thumbnail [user]="action[userKey]" class="thumbnail">
  </mh-user-thumbnail>
</ng-template>

<ng-template #nameAndTitle>
  <div>
    <div>
      <b>{{action[userKey]?.firstName || action[userKey]?.first_name}}
        {{action[userKey]?.lastName || action[userKey]?.last_name}}</b> -
      {{action[userKey] ? action[userKey][titleKey] : ''}}
    </div>
    <ng-content *ngIf="expandable; else ngContent" select="[header]"></ng-content>
    <ng-template #ngContent>
      <ng-content></ng-content>
    </ng-template>
  </div>
</ng-template>

<ng-template #customerAndTarget>
  <div class="d-flex flex-column align-items-end mr-3 mb-3 col">
    <span container="body" [ngbTooltip]="('logs.tooltips.customer' | translate)">{{action[customer]?.name}}</span>
    <span *ngIf="action[userTargetKey]" class="text-right user-target" container="body" [ngbTooltip]="('logs.tooltips.user_target' | translate)">
      {{action[userTargetKey].firstName || action[userTargetKey].first_name}}
      {{action[userTargetKey].lastName || action[userTargetKey].last_name}}<br>
      <small class="user-target-email">({{action[userTargetKey].email}})</small>
    </span>
  </div>
</ng-template>
