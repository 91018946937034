<ng-container *ngTemplateOutlet="productItemPreview"></ng-container>

<div class="main-content">
  <h3>{{'cases.titles.form' | translate}}</h3>
  <form (ngSubmit)="save()" [formGroup]="caseForm">
    <div class="row">
      <div class="col-12">
        <mat-form-field>
          <mat-label>Asignar a</mat-label>
          <input type="text"
            class="focus:tw-ring-0"
            matInput
            formControlName="assigned"
            [readonly]="disableAssignedInput"
            [matAutocomplete]="auto"/>
          <button
            *ngIf="caseForm.get('assigned')?.value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="resetAndEnableAssignedControl()">
            <mat-icon>close</mat-icon>
          </button>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="getOptionText" (optionSelected)="setAssignedControl($event)">
            <mat-option class="multiline-mat-option" *ngFor="let option of filteredOptions" [value]="option">
              <div>
                <span class="font-weight-bold">{{option.first_name}} {{option.last_name}}</span>
                  <span [class.current-user]="currentUser.id === option.id">
                    ({{currentUser.id === option.id ?
                      ('cases.commons.current_user' | translate) :
                      option.company_position
                    }})
                  </span>
              </div>
              <div>
                <small class="text-muted">{{ option.email }}</small>
              </div>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <ng-container
        *ngTemplateOutlet="input; context: {name: 'title', labelPath: 'cases.commons.title', type: 'input'}"
      ></ng-container>
      <div class="mb-3 col-12" *ngIf="caseForm.get('title').errors?.maxlength">
        <small class="error-validator">
          {{'cases.errors.maxlength' | translate : { max: requiredLengthTitleError, current: currentLengthTitleError} }}
        </small>
      </div>
      <ng-container
        *ngTemplateOutlet="input; context: {name: 'description', labelPath: 'cases.commons.description', type: 'textarea'}"
      ></ng-container>
      <div class="col-12" *ngIf="caseForm.get('description').errors?.maxlength">
        <small class="error-validator">
          {{'cases.errors.maxlength' | translate : { max: requiredLengthDescriptionError, current: currentLengthDescriptionError} }}
        </small>
      </div>
      <ng-container
        *ngTemplateOutlet="dropdown; context: {name: 'case_type', labelPath: 'cases.commons.case_types', options: caseTypesOptions}"
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="dropdown; context: {name: 'priority', labelPath: 'cases.commons.priority', options: priorityOptions}"
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="areas"
      >
      </ng-container>
      <div class="text-center col-12">
        <button
          type="submit"
          class="btn btn-orange"
          [disabled]="savingCase"
        >Crear</button>
      </div>
    </div>
  </form>
</div>

<ng-template
  #input
  let-name="name"
  let-labelPath="labelPath"
  let-type="type"
>
  <ng-container [formGroup]="caseForm">
    <div class="col-12">
      <mat-form-field [appearance]="type === 'textarea' && 'outline'">
        <mat-label>{{labelPath | translate}}</mat-label>
        <ng-container [ngSwitch]="type">
          <ng-container *ngSwitchCase="'input'">
            <input [formControlName]="name" matInput/>
          </ng-container>
          <ng-container *ngSwitchCase="'textarea'">
            <textarea class="focus:tw-ring-0" [formControlName]="name" matInput [mentionConfig]="mentionConfig" (input)="onInputText($event)" (itemSelected)="selectMention($event)"></textarea>
          </ng-container>
        </ng-container>
      </mat-form-field>
    </div>
  </ng-container>
</ng-template>

<ng-template
  #dropdown
  let-name="name"
  let-labelPath="labelPath"
  let-options="options"
>
  <ng-container [formGroup]="caseForm">
    <div class="col-12">
      <mat-form-field>
        <mat-label>{{labelPath | translate}}</mat-label>
        <mat-select [formControlName]="name">
          <mat-option> - </mat-option>
          <mat-option *ngFor="let option of options" [value]="option">
            <div mhFindTranslate
              [language]="language"
              [translates]="option.translates"
              [keyTranlsate]="'name'"
              [capitalize]="true"
            ></div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>
</ng-template>

<ng-template #productItemPreview>
  <h4 *ngIf="productItem">{{'cases.titles.assigned_product' | translate}}</h4>
  <mh-case-product
    [productItem]="productItem"
    [survey]="survey"
    ></mh-case-product>
</ng-template>

<ng-template #areas>
  <div class="col-12">
    <mat-form-field [ngClass]="{
      'mat-form-field-invalid': areaFieldInvalid
      }">
      <mat-label>{{'areas.title' | translate}}</mat-label>

      <mat-chip-list #chipList aria-label="Area selection">
        <mat-chip
          *ngFor="let area of areasChips"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="remove(area)">
            <ng-container *ngIf="language === 'es'">
              {{area.clasification_text}}
            </ng-container>
            <ng-container *ngIf="language === 'en'">
              {{area.clasification_text_en}}
            </ng-container>
            <ng-container *ngIf="language === 'pt'">
              {{area.clasification_text_pt}}
            </ng-container>
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
          placeholder="Nueva Área"
          #areaInput
          [formControl]="areasControl"
          [matAutocomplete]="autoArea"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)">
      </mat-chip-list>

      <mat-autocomplete #autoArea="matAutocomplete"
          (optionSelected)="selected($event)"
          >
          <mat-option *ngFor="let option of filteredAreaOptions | async" [value]="option">
            <ng-container *ngIf="language === 'es'">
              {{option.clasification_text}}
            </ng-container>
            <ng-container *ngIf="language === 'en'">
              {{option.clasification_text_en}}
            </ng-container>
            <ng-container *ngIf="language === 'pt'">
              {{option.clasification_text_pt}}
            </ng-container>
          </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #limitCasesModal let-dismiss="dismiss">
  <mh-modal
    [title]="'cases.messages.monthly_limit_title' | translate"
    [close]="dismiss"
    [headerStyles]="['custom-header']"
    >
    <div class="my-3">
      <p [innerHtml]="'cases.messages.monthly_limit' | translate:{ userName: user.name, hotelName: hotel.name, monthlyLimit: monthlyLimit }"></p>
      <p class="mt-2">{{ (!user?.admin ? 'cases.messages.improve_plan' : 'cases.messages.improve_plan_admin') | translate }}</p>
      <a class="link" [href]="upsellLink" target="_blank">
        <button type="submit" class="btn btn-orange">
            {{ (!user?.admin ? 'cases.messages.monthly_limit_button' : 'cases.messages.improve_plan_button') | translate }}
        </button>
      </a>
    </div>
  </mh-modal>
</ng-template>
