<div class="tw-bg-white tw-shadow tw-rounded-md">
  <mh-box-element
    class="tw-px-3 tw-py-6 tw-block tw-mx-auto tw-w-2/4"
    [title]="'configuration.profile.super_admin.title' | translate"
  >
    <div class="align-center">
      <mh-spinner-load *ngIf="superAdminWaiting" [size]="60"></mh-spinner-load>
    </div>
    <div *ngIf="!superAdminWaiting" class="super_admin_options">
      <div class="box_editable">
        <div class="demo_option">
          <label>{{ 'configuration.profile.super_admin.demo_label' | translate }}</label>

          <mat-slide-toggle class="example-margin" (change)="setLiveDemo($event)" [color]="color" [checked]="liveDemo">
            {{ 'configuration.profile.super_admin.demo_active' | translate }}
          </mat-slide-toggle>
        </div>

        <div class="actions">
          <button (click)="saveSuperAdminOptions()" class="btn-action btn-orange">
            {{ 'commons.save_changes' | translate }}
          </button>
          <div class="save_spinner">
            <mh-spinner-load *ngIf="saveDataSuperAdmin" [size]="40"></mh-spinner-load>
          </div>
        </div>
      </div>
    </div>
  </mh-box-element>
</div>
