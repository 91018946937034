<widget-period-indicator
  [currentValue]="period?.actual"
  [currentFormat]="(period?.actual | formatBy:valueFormat)"
  [previousValue]="period?.previous"
  [previousFormat]="(period?.previous | formatBy:valueFormat)"
  [variation]="variation"
  [variationFormat]="(period?.variation | formatBy:variationFormat)"
  [tooltip]="tooltip"
  [format]="format"
></widget-period-indicator>
