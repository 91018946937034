import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { UtilService } from 'app/shared/util.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'filter-trends',
  templateUrl: './filter-trends.component.html',
  styleUrls: ['./filter-trends.component.css']
})
export class FilterTrendsComponent implements OnInit{

  @Output()
  filter: EventEmitter<any> = new EventEmitter();

  startDate:string;

  trendsTypes= [
    'normal',
    'last_12_weeks',
    'last_15_days'
  ];

  trendTypeView = 'months';
  trendType = 'normal';

  dateSubs: Subscription;

  constructor(private utilService:UtilService) {
    this.dateSubs = this.utilService.isCurrentDateChange().subscribe(reponse=>{
      this.setTrendType('months')
    })
  }

  ngOnDestroy(){
    this.dateSubs.unsubscribe();
  }

  ngOnInit(){
    this.reset();
  }

  reset(){
    this.trendType = 'normal';
    this.trendTypeView = 'months';
  }

  setTrendType(type){
    this.trendType = type;
    let date = this.utilService.stringDateToDate(this.utilService.endDate);
    if(type.includes('weeks')){
      this.trendTypeView = 'weeks';
      this.startDate = this.utilService.formatDate(this.utilService.getLastWeeks(12,date));
    }else if(type.includes('days')){
      this.trendTypeView = 'days';
      this.startDate = this.utilService.formatDate(this.utilService.getLastDays(15,date));
    }else{
      this.trendTypeView = 'months';
      this.startDate = this.utilService.startDate;
    }

    this.filter.emit({
      startDate: this.startDate,
      trendTypeView : this.trendTypeView
    })

  }

}
