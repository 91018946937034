// Modules
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

import { SharedModule } from './shared/shared.module';
import { ConfigurationModule } from './configuration/configuration.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Components
import { AppComponent } from './app.component';
import { ReportsComponent } from './reports/reports.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { RootDasboardComponent } from './dashboard/root-dashboard.component';
import { NewRootDasboardComponent } from './dashboard/new-root-dashboard/new-root-dashboard.component';
import { SupportComponent, FeedbackComponent } from './support/index';
import { LogsTimelineComponent } from './logs/logs-timeline/logs-timeline.component';

import { PrestayUtilService } from './layouts/themes/index';

import { GoogleAnalyticsService } from './_services/google-analytics.service';

// i18n
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// used to create fake backend
import { HtmlHelper } from './_helpers/index';

import { AuthGuard, AuthPrestayGuard } from './_guards/index';
import {
  AlertService,
  AuthenticationService,
  UserService,
  ProductsService,
  VersionCheckService,
  SessionService,
} from './_services/index';

import { NotificationsService } from './notifications/index';

import { LoginComponent, RecoveryComponent } from './login/index';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RegisterComponent } from './register/index';

import { FilterDateService } from './shared/filters/date/filter-date-service';

export function HttpLoaderFactory(http: HttpClient) {
  const cacheBuster = new Date().getTime(); // this is used to refresh translation files 
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${cacheBuster}`)
}

import { TreeModule } from '@circlon/angular-tree-component';

import { LayoutsComponent } from './layouts/main/layouts.component';

//import { DeviceDetectorModule } from 'ngx-device-detector';

import { DemographyService } from './online/demography/demography.service';
import { SurveyThemeComponent } from './layouts/themes/survey/survey-theme/survey-theme.component';
import { SurveysUtilService } from './features/surveys/surveys-util.service';
import { SupportFieldProductTagComponent } from './support/fields/support-field-product-tag/support-field-product-tag.component';
import { SupportFieldProductComponent } from './support/fields/support-field-product/support-field-product.component';
import { SupportFieldAttachedFilesComponent } from './support/fields/support-field-attached-files/support-field-attached-files.component';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { CustomHttpInterceptorService } from './_services/http_interceptor.service';

import { PubNubAngular } from 'pubnub-angular2';
import { AirbrakeErrorHandler } from './airbrake-error-handler';
import { environment } from 'src/environments/environment';
import { PrivateDownloadComponent } from 'src/app/prestay/private-download/private-download.component';
import { MhLibComponent } from './mh-lib/mh-lib.component';
import { registerLocaleData } from '@angular/common';
import localeCL from '@angular/common/locales/es-CL';

import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule } from '@ngxs/store';
import { SemanticState } from './states/semantic/semantic.state';
import { FollowupOnsiteState } from './states/followup-onsite/followup-onsite.state';
import { PublicConfigState } from './states/public-survey/public-survey.state';
import { DefaultLanguageState } from './states/default-language/default-language.state';
import { FidelityDeskState } from './states/fidelity-desk/fidelity-desk.state';
import { CurrentSurveyState } from './states/survey/survey.state';
import { CustomersState } from './states/customers/customers.state';
import { UsersState } from './states/users/users.state';
import { DateState } from './states/date/date.state';
import { AutoCaseState } from 'src/app/states/auto-case-config/auto-case-config.state';

import { PipesModule } from 'app/pipes/pipes.module';
import { DownloadPageComponent } from './download-page/download-page.component';
import { HubspotSupportComponent } from './support/hubspot-support/hubspot-support.component';
import { OnlineReviewsState } from './states/online-reviews/online-reviews.state';
import { MenusState } from './states/menus/menus.state';
import { SmartRepliesModule } from 'app/new-fs/smart-replies/smart-replies.module';
import { OtasState } from './states/otas/otas.state';
import { OnlineComponent } from 'app/new-fs/online/online.component';
import { NotificationsCentralComponent } from './notifications-central/notifications-central.component';
import { UsersActionsState } from './states/users-actions/users-actions.state';

registerLocaleData(localeCL);

@NgModule({
  declarations: [
    AppComponent,
    ReportsComponent,
    NotFoundComponent,
    SupportComponent,
    FeedbackComponent,
    RootDasboardComponent,
    NewRootDasboardComponent,
    LoginComponent,
    RecoveryComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    LayoutsComponent,
    SurveyThemeComponent,
    SupportFieldProductTagComponent,
    SupportFieldProductComponent,
    SupportFieldAttachedFilesComponent,
    LogsTimelineComponent,
    PrivateDownloadComponent,
    MhLibComponent,
    DownloadPageComponent,
    HubspotSupportComponent,
    OnlineComponent,
    NotificationsCentralComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ConfigurationModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgxPageScrollCoreModule,
    TreeModule,
    SharedModule,
    //DeviceDetectorModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'es',
    }),
    NgxsModule.forRoot(
      [
        SemanticState,
        FollowupOnsiteState,
        PublicConfigState,
        DefaultLanguageState,
        FidelityDeskState,
        CurrentSurveyState,
        CustomersState,
        UsersState,
        DateState,
        OnlineReviewsState,
        OtasState,
        MenusState,
        UsersActionsState,
        AutoCaseState,
      ],
      { developmentMode: !environment.production },
    ),
    NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
    HammerModule,
    PipesModule,
    AppRoutingModule,
    SmartRepliesModule,
  ],
  providers: [
    PrestayUtilService,
    AuthPrestayGuard,
    AuthGuard,
    AlertService,
    AuthenticationService,
    UserService,
    ProductsService,
    FilterDateService,
    NotificationsService,
    GoogleAnalyticsService,
    VersionCheckService,
    // providers used to create fake backend
    HtmlHelper,
    SessionService,
    DemographyService,
    SurveysUtilService,
    { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptorService, multi: true },
    // provide error handler only on production
    // this is F****** the logs
    ...(environment.production ? [{ provide: ErrorHandler, useClass: AirbrakeErrorHandler }] : []),
    PubNubAngular,
    { provide: LOCALE_ID, useValue: 'es_CL' },
    { provide: 'googleTagManagerId', useValue: environment.gtmContainerId },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
