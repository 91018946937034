import {Component, OnInit, OnChanges, Input} from '@angular/core';
import {UtilService} from "../util.service";
import {Review, Hotel, HotelDto} from "../model/index";
import {ReviewService} from "./review.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from '@angular/router';
import { HotelService } from 'app/shared/hotel/hotel.service';
import {animation} from '../utils/animation-custom';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalReplyReviewComponent} from '../modal-reply-review/modal-reply-review.component';
import { CreateCaseDialogService } from 'app/shared/core-lib-modal-services/cases/create-case-dialog.service';
import { ConnectionPositionPair } from '@angular/cdk/overlay';

@Component({
  selector: 'mh-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
  providers:[ReviewService,HotelService],
  animations: [
    animation.rotate,
    animation.fade
  ]
})
export class ReviewComponent implements OnInit, OnChanges{

  @Input()
  review:Review;

  @Input()
  customer:Hotel;

  @Input()
  translateAll = false;

  @Input()
  hotelDto:HotelDto;

  @Input()
  showCases: boolean = false;

  @Input() sourceWindow: string;

  util;

  showingMore:boolean = false;

  waitDelete: boolean    = false;
  waitTranslate: boolean = false;
  modal;
  translateReview:TranslateReview;
  currentLanguage;
  reviewLanguage;
  can_edit_survey: boolean = false;
  id_guest;
  equal_guest: boolean = false;
  showDay: boolean = true;
  isSuperAdmin: boolean
  showAnswer: boolean  = false;
  actionsOpen: boolean  = false

  idOtasNoShowDay = [
    4
  ];

  positions = [
    new ConnectionPositionPair(
      { originX: 'end', originY: 'top' },
      { overlayX: 'end', overlayY: 'top' }
    )
  ];

  showTranslations = false;
  createCase = false;

  replyOtaForm: FormGroup;

  constructor(
    private utilService:UtilService,
    private reviewsService:ReviewService,
    private modalService:NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private createCaseDialogService: CreateCaseDialogService
  ) {
    this.currentLanguage = this.utilService.getLanguageId();
    this.util = this.utilService;
    this.utilService.isLanguageChanged().subscribe(response=>{
      this.currentLanguage = this.utilService.getLanguageId();
    })
  }

  get currentUser() {
    return this.utilService.getCurrentUser();
  }

  ngOnChanges(){
    if(this.translateAll) {
      this.translate();
    }
  }

  ngOnInit(){
    if(this.currentUser) this.isSuperAdmin = this.currentUser.superAdmin
    this.setShowDay()
    this.canEditReview()

    this.reviewLanguage =  this.review  && this.review.guest && this.review.guest.language && this.review.guest.language.id ? this.review.guest.language.id : 0;
    this.id_guest = this.router.parseUrl(this.router.url).queryParams['guest'];

    this.setTranslateType();

    if (this.id_guest === this.review.id){
      this.equal_guest = true;
    }else{
      this.equal_guest = false;
    }

    if(this.translateAll) {
      this.translate();
    }

    this.showingMore = !this.review.hasExcerpt();
    this.checkToCreateCase();
    this.setReplyForm();
  }

  setReplyForm() {
    if (this.review.ota.id === 30) {
      this.createReplyReviewForm();
    }
  }

  setShowDay(){
    if(this.idOtasNoShowDay.includes(this.review.ota.id))
      this.showDay = false;
  }

  isCompetitor(){
    if(this.customer===undefined) return false;
    return this.customer.id !== this.utilService.currentHotel.id;
  }

  checkReview(review){
    this.reviewsService.setReview(review.id).subscribe((response:any)=>{
      this.review.flagAnswered = response.update
    })
  }

  seeAll(){
    this.showingMore = true;
  }

  getIcon(type){
    let style = '';
    if(type=== 'positive') style =  'far fa-smile-wink';
    if(type=== 'negative') style =  'far fa-frown';
    if(type=== 'neutral') style =  'far fa-meh';
    return style+' '+type;
  }

  openDialog(content){
    this.modal = this.modalService.open(content, {
      windowClass: 'modal-confirm'
    })
  }

  closeDialog(){
    this.modal.close();
  }

  deleteReview(){
    this.waitDelete = true;
    this.reviewsService.deleteReview(this.review.id).subscribe((response:any)=>{
      if(response.delete) this.review.deleted = response.delete===1;
      this.waitDelete = false;
      this.closeDialog()
    })
  }

  canEditReview(){
    if(this.currentUser) this.can_edit_survey =  ['customer-success','full-admin'].includes(this.currentUser.role);
  }

  translate(){

    const languageId = (this.review.guest.language.id === this.reviewLanguage)? this.reviewLanguage : 0;


    this.waitTranslate = true;
    this.reviewsService.translateReview(this.review.id, languageId ,this.currentLanguage,'3', this.review.title, this.review.description).subscribe((response:any)=>{
      this.translateReview = response;
      this.waitTranslate = false;
    })
  }

  setTranslateType(){
    if(this.hotelDto) this.showTranslations = this.hotelDto.flag_show_translations;
  }

  setTranslateWaiting($event){
    this.waitTranslate = $event;
  }

  setTranslatedReview($event){
    this.translateReview = $event;
    this.waitTranslate = false;
  }

  hasDescription(){
    return this.review.description && this.review.description.length > 1;
  }

  getProductItem(id: number) {
    return {
      type: { id: 2 },
      product_id: 3,
      item_id: id
    }
  }

  toggleAnswer() {
    this.showAnswer = !this.showAnswer;
  }

  checkToCreateCase() {
    this.route.queryParams.subscribe((params: any) => {
      if (Number(params.review) === this.review.id) {
        this.createCase = params?.createCase?.toString() || false;
      }
    });
  }

  handleReplyReviewFromFS() {
    this.openDialog(ModalReplyReviewComponent);
    this.modal.componentInstance.formGroup = this.replyOtaForm;
    this.modal.componentInstance.review = this.review;
    this.handleEventFromReplyReview();
  }

  handleEventFromReplyReview() {
    this.modal.componentInstance.reviewReplied.subscribe(($event: any) => {
      const comment = $event.comment;
      this.reviewsService.replyReview(this.review.id, comment, this.hotelDto.id).subscribe(response => {
        if (response) {
          // Esta lógica podríamos mejorarla si cambiamos la respuesta que nos trae el back
          this.review.answer = { description: comment };
          this.modal.componentInstance.replying = false;
          this.modal.componentInstance.success = true;
          setTimeout(_ => {
            this.modal.close();
            this.replyOtaForm.reset();
          }, 3000)
        }
      }, (error) => {
          this.modal.componentInstance.replying = false;
          this.modal.componentInstance.failed = true;
          setTimeout(_ => {
            this.modal.close();
          }, 5000)

      });
    });
  }

  createReplyReviewForm() {
    // Esta estructura podría variar según la ota
    this.replyOtaForm = this.fb.group({
      comment: ['', Validators.required]
    });
  }

  handleCreateCase() {
    this.createCaseDialogService.openModal(
      this.utilService.currentUser,
      'review',
      this.review
    );
  }

  get reviewAnswer() {
    return this.review.answer.description;
  }

  get areAnswer() {
    return !!this.review.answer && this.review.flagAnswered;
  }

  get rotateAnimationState() {
    return (!this.showAnswer) ?
      '0' :
      '180'
  }

  get otaName() {
    return this.review.ota.name;
  }

  get isGoogleReview() {
    return this.review.ota.id === 30;
  }

}

export interface TranslateReview{
  title:string;
  description: string;
}

