<mh-core-lib-content>
    <ng-container head>
        <mh-core-lib-header-content [title]="'configuration.invoice.title' | translate">
            <section class="tw-flex tw-justify-center tw-items-center tw-flex-wrap md:tw-justify-between">
                <article class="tw-flex tw-space-x-4">
                  <article>
                    <mh-core-lib-datepicker
                        [currentDates]="currentDates"
                        [currentFilter]="currentFilterDate"
                        (datepickerUpdated)="handleDatepickerUpdated($event)">
                    </mh-core-lib-datepicker>
                  </article>
                  <article>
                    <mh-invoice-filter 
                      id="statusInvoiceFilter"
                      (stateFilterUpdated)="handleStateFilter($event)"
                    >
                    </mh-invoice-filter>
                  </article> 
                </article>

                <article class="tw-flex tw-space-x-4">
                  <mh-total-amount-invoice-widget
                    [currentDates]="currentDates" 
                    [currentHotelId]="currentHotelId"
                  >
                  </mh-total-amount-invoice-widget>
                </article>
              </section>
        </mh-core-lib-header-content>
    </ng-container>

    <ng-container main-content>
      <mh-core-lib-main-content>
        <div class="tw-flex tw-flex-row tw-justify-center tw-w-full tw-p-7" [hidden]="!waiting">
          <mh-spinner-load></mh-spinner-load>
        </div>

        <mh-invoice-table 
          [currentDates]="currentDates" 
          [currentHotelId]="currentHotelId"
          [state]="state"
        >
        </mh-invoice-table>
      </mh-core-lib-main-content>
    </ng-container>
</mh-core-lib-content>