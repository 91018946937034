<div *ngIf="hasIcon; else noIconTemplate" [formGroup]="formGroup" class="tw-relative">
  <i class="{{ iconClass }} tw-absolute tw-right-3 tw-top-1/3 tw-cursor-pointer tw-text-gray-500" (click)="handleClick()"></i>
  <input [formControlName]="keyControl" class="focus-visible:tw-border-2 focus-visible:tw-outline-0 focus-visible:tw-border-border-main tw-text-main-text tw-rounded-xl tw-py-2 tw-pl-5 tw-pr-8 tw-border tw-border-border-light-gray tw-w-full focus:tw-ring-0 {{className}}" [placeholder]="placeholder"/>
</div>

<ng-template #noIconTemplate>
  <div [formGroup]="formGroup">
    <input
      [formControlName]="keyControl"
      [type]="type"
      [attr.min]="minDate ? minDate : null"
      [OnlyNumber]="onlyNumber"
      class="focus-visible:tw-border-2 focus-visible:tw-outline-0 focus-visible:tw-border-border-main tw-text-main-text tw-rounded-xl tw-py-2 tw-px-5 tw-border tw-border-border-light-gray tw-w-full focus:tw-ring-0 {{className}}"
      [placeholder]="placeholder"
      [attr.maxLength]="maxLength ? maxLength : null"
    />
  </div>
</ng-template>
