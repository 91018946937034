import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Communications } from 'app/shared/notifications/models/communications';
import { NotificationsService } from 'app/shared/notifications/services/notifications.service';
import { DateService } from 'app/shared/services/date.service';
import { UtilService } from 'app/shared/util.service';
import { DefaultLanguageState } from 'app/states/default-language/default-language.state';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mh-notifications-central',
  templateUrl: './notifications-central.component.html',
  styleUrls: ['./notifications-central.component.scss'],
})
export class NotificationsCentralComponent implements OnInit, OnDestroy {
  @Select(DefaultLanguageState) languageReady$: Observable<{ code: string; languageId: number }>;
  public state: any = null;
  public currentUser = this.utilService.getCurrentUser();
  public currentLanguageId = this.utilService.getCurrentLanguageId();
  public alerts: Communications[] = [];
  public news: Communications[] = [];
  public hasMoreAlerts = false;
  public hasMoreNews = false;
  public pageSizeAlerts = 10;
  public pageSizeNews = 10;
  public notificationComponentType = 'history';
  public totalElements: number;
  public currentPage = 0;
  public subscription = new Subscription();
  public newsUrl: string;
  public alertsUrl: string;
  private isFirstApiCallDone = false;

  constructor(
    private dateService: DateService,
    private notificationService: NotificationsService,
    private utilService: UtilService,
  ) {}

  ngOnInit(): void {
    this.newsUrl = `${environment.notificationsPath}notification-news/users/${this.currentUser.id}/language/${this.currentLanguageId}`;
    this.alertsUrl = `${environment.notificationsPath}notification-alerts/users/${this.currentUser.id}/language/${this.currentLanguageId}`;
    this.initLanguageSubscriber();
  }

  handleDatepickerUpdated($event) {
    this.dateService.setCurrentFilter($event.currentFilter);
    this.dateService.setCurrentDates($event.currentDates);
    this.pageSizeAlerts = 10;
    this.pageSizeNews = 10;
    this.getNotifications(true, true);
  }

  handleStateFilter(event) {
    this.state = [...event];
  }

  initLanguageSubscriber() {
    this.subscription.add(
      this.languageReady$.subscribe((languageCode) => {
        if (languageCode.languageId && this.isFirstApiCallDone) {
          this.currentLanguageId = languageCode.languageId;
          this.getNotifications(true, true);
        }
      }),
    );
  }

  getNotifications(loadAlerts = false, loadNews = false) {
    if (!loadAlerts && !loadNews) {
      return;
    }

    const userId = +this.currentUser.id;
    const langId = +this.currentLanguageId;
    const payload = {
      page: 0,
      startDate: this.currentDates.startDate,
      endDate: this.currentDates.endDate,
    };

    if (loadAlerts) {
      this.notificationService
        .getNotificationsDataByDates(userId, langId, { ...payload, pageSize: this.pageSizeAlerts }, true)
        .subscribe((res) => {
          this.alerts = res.info;
          this.hasMoreAlerts = res.has_next;
          this.totalElements = res.total_of_elements;
          this.isFirstApiCallDone = true;
        });
    }

    if (loadNews) {
      this.notificationService
        .getNotificationsDataByDates(userId, langId, { ...payload, pageSize: this.pageSizeNews }, false)
        .subscribe((res) => {
          this.news = res.info;
          this.hasMoreNews = res.has_next;
          this.totalElements = res.total_of_elements;
          this.isFirstApiCallDone = true;
        });
    }
  }

  get currentDates() {
    return this.dateService.currentDates;
  }

  get currentFilterDate() {
    return this.dateService.currentFilter;
  }

  async handleShowMore(type: string) {
    const url = type === 'alert' ? this.alertsUrl : this.newsUrl;
    if (type === 'alert') {
      this.pageSizeAlerts += 10;
    } else {
      this.pageSizeNews += 10;
    }

    const pageSize = type === 'alert' ? this.pageSizeAlerts : this.pageSizeNews;

    try {
      const res = await this.notificationService.getNotificationsData({ page: 0, pageSize }, url).toPromise();
      if (res && res.info) {
        if (type === 'alert') {
          this.alerts = res.info;
          this.hasMoreAlerts = res.has_next;
        } else {
          this.news = res.info;
          this.hasMoreNews = res.has_next;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  ngOnDestroy(): void {
    this.pageSizeAlerts = 10;
    this.pageSizeNews = 10;
    this.subscription.unsubscribe();
  }
}
