export class NavbarNode{
  id:any;
  name:string;
  children: NavbarNode[] = [];
  icon:string;
  matIcon:boolean=false;
  flag:string;
  slug: string;
  depth:number;
  product:string;
  link;
  beta = false;
  new = false;
  mobile = false;
  superAdmin = false;
  constructor(id,name,icon,matIcon,flag,depth,product, new_tag){
    let isFull = id.indexOf("/*")
    this.id = id;
    this.name = name;
    this.icon = icon;
    this.matIcon = matIcon;
    this.flag = flag;
    this.depth = depth;
    this.product = product;
    this.link = isFull>0 ? id.substring(0,isFull) : id;
    this.new = new_tag;
  }

  isParent(){
    return this.children.length>0;
  }

  isRoot() {
    return this.depth === 0 || this.depth === 1
  }
}
