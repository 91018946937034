<div class="tw-flex tw-w-full tw-border-b tw-border-slate-300 {{mainClassName}}">
  <ng-container *ngFor="let tab of tabs; let index = index">
      <div
        class="tw-cursor-pointer tw-px-3 tw-py-1.5 tw-text-main-text {{className}} tw-font-semibold"
        [class.tw-font-bold]="tab.tabIndex === tabIndex"
        [class.!tw-text-main]="tab.tabIndex === tabIndex"
        [class.tw-border-b-2]="tab.tabIndex === tabIndex"
        [class.tw-border-main]="tab.tabIndex === tabIndex"
        (click)="toggleTab(index)">
        {{tab.title}}
      </div>
  </ng-container>
</div>
<div class="tab-content">
  <ng-content></ng-content>
</div>
