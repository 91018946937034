import { Component } from '@angular/core';
import { UtilService } from "../../../../shared/util.service";
import { Hotel } from 'app/shared/model';
import { DateService } from 'app/shared/services/date.service';


@Component({
  selector: 'mh-online-tripadvisor-section',
  templateUrl: './online-tripadvisor-section.component.html',
})
export class OnlineTripadvisorSectionsComponent{

  chainFilter: Hotel[] = [];
  isReady:boolean=false;
  productId = 3;
  util;
  fidelityPermisions:string[] = [];

  constructor(
    private utilService:UtilService,
    private dateService: DateService
  ) {
    this.util = this.utilService
  }

  ngOnInit(){
    this.fidelityPermisions = this.utilService.getFidelityConfigurationsByProduct('interchain', 'interchain_online');
  }

  setChain(chain){
    this.chainFilter = chain;
  }

  dataIsReady(state){
    setTimeout(_ => {
      this.isReady = state;
    },200);
  }

  handleDatepickerUpdated($event) {
    this.dateService.setCurrentFilter($event.currentFilter);
    this.dateService.setCurrentDates($event.currentDates)
  }

  get currentDates() {
    return this.dateService.currentDates;
  }

  get startDate() {
    return this.currentDates.startDate;
  }

  get endDate() {
    return this.currentDates.endDate;
  }

  get currentFilterDate() {
    return this.dateService.currentFilter;
  }
}
