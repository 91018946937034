import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from "../../../../environments/environment";
import { map } from 'rxjs/operators';

@Injectable()
export class SummaryService {

  constructor(private http:HttpClient) { }

  public getFollowupAlerts(customerId:string){
    const url=`${environment.followupAlert}`;
    const params:HttpParams = new HttpParams()
      .set("customer_id",customerId)
      .set("alert_type_id",'1');

    return this.http.get(url, {params: params})
      .pipe(
        map(response=>response)
      );
  }

  public getOnlineAlerts(customerId:string){
    const url=`${environment.onlineAlert}`;
    const params:HttpParams = new HttpParams()
      .set("customer_id",customerId)
      .set("alert_type_id",'1');

    return this.http.get(url, {params: params})
      .pipe(
        map(response=>response)
      );
  }
}
