import { Component, Input } from '@angular/core';

@Component({
  selector: 'mh-core-lib-loader-wrapper',
  templateUrl: './loader-wrapper.component.html',
  styleUrls: ['./loader-wrapper.component.scss'],
})
export class CoreLibLoaderWrapperComponent {
  @Input()
  loading = true;

  @Input()
  type: 'graphFollowupOnsite' | 'collect' | 'invertedT' | 'generic' | 'genericOneLine' | 'spinner' = 'generic';

  @Input()
  className = '';

  @Input()
  spinnerClassName = '';
}
