import { Hotel, Precheckin } from 'app/shared/model';
import { UtilService } from 'app/shared/util.service';
import { Component, OnInit, Input, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { PrecheckinGuest } from './precheckin-guest.interface';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrecheckinGuestService } from './precheckin-guest.service';
import { forkJoin } from 'rxjs';
import { HotelService } from 'app/shared/hotel/hotel.service';
import { CreateCaseDialogService } from 'app/shared/core-lib-modal-services/cases/create-case-dialog.service';


@Component({
  selector: 'mh-prestay-precheckin-guest',
  templateUrl: './precheckin-guest.component.html',
  styleUrls: ['./precheckin-guest.component.scss'],
  providers: [HotelService,PrecheckinGuestService]
})
export class PrecheckinGuestComponent implements OnInit {

  @Input()
  guest:PrecheckinGuest;

  @Input()
  guestIdParam:number;

  @Input()
  showCases:boolean = false;

  @ViewChild('detailModal', { static: true })
  detailModalTemplate: TemplateRef<any>;

  waiting = true;
  downloadingPDF = false;
  downloadingFile = false;
  hotel: Hotel;

  modal:NgbModalRef;

  precheckin: Precheckin;
  answers;
  base    = {};
  questions: any[] = [];
  triggerOrigin: ElementRef;
  triggerActionsCardOrigin: ElementRef;
  cardActionsOpen = false;
  keys = [
    'response_date',
    'checkin',
    'checkout',
    'checkin_done',
    'stay_nights',
    'checkin_hour',
    'reservation_id'
  ];

  dateKeys = ['response_date', 'checkin','checkout'];
  conditionalKeys = ['checkin_hour'];

  constructor(
    private utilService: UtilService,
    private modalService: NgbModal,
    private precheckinService: PrecheckinGuestService,
    private hotelService: HotelService,
    private createCaseDialogService: CreateCaseDialogService
  ) { }

  ngOnInit() {
    if(this.guestIdParam && this.guestIdParam===this.guest.id){
      setTimeout(_ => {
        this.openDialog();
      },1000);
    }
    this.conditionalKeys.forEach((key) => this.validateKey(key));
  }

  openDialog(){
    const content = this.detailModalTemplate;
    this.modal = this.modalService.open(content, {
      windowClass: 'modal-xl modal-confirm'
    })
    this.getPrecheckin();
  }

  closeDialog(){
    if(this.modal) this.modal.close();
  }

  getPrecheckin(){
    this.waiting = true;
    this.hotel = this.utilService.currentHotel;

    forkJoin([
      this.precheckinService.getForm(this.hotel.id),
      this.precheckinService.getAnswers(this.guest.id),
      this.hotelService.getBase(),
      this.hotelService.getHotel(this.hotel.id),
    ]).subscribe(result=>{

      this.precheckin = this.setSteps(this.hotel,result[0]);
      this.answers               = result[1];
      this.base['countries']     = result[2]['countries'];
      this.base['country']       = result[3]['country']
      this.utilService.countries = this.base['countries'];
      this.setAnswers();
    })
  }

  async setAnswers(){
    this.questions = [];
    [1,2,3].forEach(step_number=>{
      let step        = this.precheckin.inputs.filter(x=>x.step===step_number);
      step            = this.utilService.sortArray(step,'sort');
      this.questions  = this.questions.concat(step);
    });

    const cityQuestion = this.questions.find(x=>x.input_type.type==='city');

    if(cityQuestion){
      const answer = this.answers['details'].find(x=>x.input_id === cityQuestion.id);
      if(answer && answer.answer) {
        const cityId = answer.answer;
        const response = await this.hotelService.getCity(cityId).toPromise();
        this.utilService.cities = [response];
      }
    }
    this.waiting = false;
  }

  downloadPDF(){
    this.downloadingPDF = true;
    this.precheckinService.downloadPrecheckin(this.utilService.currentHotel.id, this.guest.id).subscribe(response=>{
      this.downloadingPDF = false;
    })
  }

  downloadFile($event) {
    this.downloadingFile = true;
    this.precheckinService.downloadFile(this.customerId, $event).subscribe(response => {
      this.downloadingFile = false;
    })
  }

  getProductItem(id) {
    return {
      type: { id: 3 },
      product_id: 4,
      item_id: id
    }
  }

  setSteps(hotel:Hotel, response: any){
    let precheckin = (new Precheckin(hotel.id)).createFromApi(response);
    for(let step of [1,2,3]){
      precheckin['step_'+step] = this.utilService.sortArray(precheckin.inputs.filter(x=>x.step===step), 'sort');
    }
    return precheckin;
  }

  getTagName(type:string) { return `ps_precheckin_${type}` }

  get customerId() {
    return this.utilService.getCurrentCustomer().id;
  }

  validateKey(keyToCheck: string): void {
    if (!Object.keys(this.guest).find((key) => key.toLowerCase() === keyToCheck && !!this.guest[key])) {
      this.keys = this.keys.filter((key) => key.toLowerCase() !== keyToCheck)
      this.dateKeys = this.dateKeys.filter((key) => key.toLowerCase() !== keyToCheck);
    }
  }

  handleCreateCase(item) {
    this.createCaseDialogService.openModal(this.utilService.currentUser, 'precheckin', item);
  }

  handleOverlayCasesClick(trigger) {
    this.triggerActionsCardOrigin = trigger;
    this.cardActionsOpen = !this.cardActionsOpen;
  }
}
