<div *ngFor="let alternative of alternatives">
  <mat-checkbox
    [value]="alternative.value"
    [checked]="isChecked(alternative)"
    [disabled]="isDisabled(alternative)"
    (change)="handleChange(alternative, $event)"
  >
    {{alternative.translates | translateObject:'text':language}}
  </mat-checkbox>
</div>
