import { Component, OnInit } from '@angular/core';
import { UtilService } from "../../../../shared/util.service";
import { Hotel } from 'app/shared/model';


@Component({
  selector: 'mh-online-summary-section',
  templateUrl: './online-summary-section.component.html',
  styleUrls: ['./online-summary-section.component.scss']
})
export class OnlineSummarySectionsComponent implements OnInit{

  util;
  chainFilter: Hotel[] = [];
  isReady:boolean=false;
  productId=3;
  headerTable: any[] = [];

  fidelityPermisions:string[] = [];

  constructor(private utilService:UtilService) {
    this.util = this.utilService
    this.utilService.currentProductId = this.productId;
  }

  ngOnInit(){
    this.fidelityPermisions = this.utilService.getFidelityConfigurationsByProduct('interchain', 'interchain_online');
    this.headerTable = this.getHeaderTable();
  }

  getHeaderTable(){
    const headers = [
      'customer',
      'general',
      'accumulated',
      'nps',
      'coverage_comments',
      'quantity_comments',
      'promoter_comments',
      'detractor_comments'
    ];
    return headers;
  }

  setChain(chain){
    this.chainFilter = chain;
  }

  dataIsReady(state){
    setTimeout(_ => {
      this.isReady = state;
    },200);
  }


}
