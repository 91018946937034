import {Component, Input} from '@angular/core';

@Component({
  selector: 'mh-form-alerts',
  templateUrl: './form-alerts.component.html',
  styleUrls: ['./form-alerts.component.scss'],
  providers:[]
})

export class FormAlertComponent{

  @Input()
  element;

  @Input()
  class: 'normal' | 'top' = 'normal';

  constructor() { }

  ngOnInit(){

  }


}
