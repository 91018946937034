<span  class="admin_option set_password" (click)="openDialog(confirmModal)">
  <i class="fab fa-expeditedssl"></i><span>{{'configuration.users.set_password.title' | translate}}</span>
</span>

<ng-template #confirmModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="title">{{'configuration.users.set_password.title' | translate}}</h4>
  </div>

  <div class="modal-body">
    <div class="dialog_options">
      <div class="fade_waiting" [hidden]="!waitSending">
        <mh-spinner-load></mh-spinner-load>
      </div>

      <div class="new_password" [hidden]="!passChanged">
        <p [hidden]="passChanged">{{'configuration.users.set_password.congrats' | translate}}</p>
        <div class="show_passowrd">
          <span class="password">{{showPassword ? newPassword : encryptPassword }}</span>
          <div class="show" (click)="togglePassword()"><i class="fal fa-eye"></i>{{'commons.show_password' | translate }}</div>
        </div>
      </div>


      <form class="general_set_form show_invalids" (ngSubmit)="saveData()" [formGroup]="controlForm" #formDir="ngForm" [hidden]="passChanged" >

        <ng-container *ngFor="let key of inputs" >
          <div class="col-12 wrapp_form">
            <label for="{{key}}">{{'forms.'+key | translate}}</label>
            <input type="password" id="{{key}}" class="form-control"  formControlName="{{key}}" >
            <ng-container *ngTemplateOutlet="formAlerts;context:{element: controlForm.controls[key]}"></ng-container>
          </div>
        </ng-container>


        <div class="bottom">
          <button type="submit" [disabled]="controlForm.invalid || sendingData || !equalPass" class="btn btn-orange">{{'forms.save_changes' | translate}}</button>
          <mh-spinner-classic [hidden]="!sendingData"></mh-spinner-classic>
        </div>

      </form>

      <div class="row" [hidden]="!passChanged">
        <div class="offset-8 col-4 align-right">
          <button (click)="closeDialog()" class="btn btn-secondary">{{'commons.close' | translate }}</button>
        </div>
      </div>

    </div>
  </div>
</ng-template>

<ng-template #formAlerts let-element="element">
    <small class="alert-danger">
    <ng-container  *ngIf="element.invalid && (element.dirty || element.touched)" >
      <div *ngIf="element.errors.required">
        {{'forms.attributes.not_empty' | translate}}
      </div>
      <div *ngIf="element.errors.minlength">
        {{'forms.attributes.min_lenght' | translate:({length: element.errors.minlength.requiredLength})}}
      </div>

      <div *ngIf="element.errors.maxlength">
        {{'forms.attributes.max_lenght' | translate:({length: element.errors.maxlength.requiredLength})}}
      </div>
    </ng-container>
  </small>
</ng-template>
