<div class="tw-flex tw-flex-col">
  <div class="tw-flex tw-justify-end tw-items-start">
    <i class="far fa-times tw-cursor-pointer tw-text-2xl" (click)="close()"></i>
  </div>

    <div class="tw-flex tw-justify-center tw-items-center tw-flex-col tw-mt-3">
        <p class="tw-font-bold tw-text-2xl tw-w-3/4 tw-text-center">{{ title }}</p>
        <p *ngIf="subtitle" class="tw-font-bold tw-text-2xl">{{ subtitle }}</p>
    </div>

    <div class="tw-flex tw-justify-center tw-my-5">
        <img class="tw-max-w-xs" [src]="image">
    </div>

    <div class="tw-flex tw-justify-center tw-font-medium text-center tw-mb-4">
        <p class="tw-font-normal tw-text-lg tw-w-7/12 tw-leading-6" [innerHTML]="body"></p>
    </div>

    <div class="tw-flex tw-justify-center" *ngIf="type === 'integration'">
        <mh-core-lib-button [isDefault]="true" [width]="200" [text]="('online.reviews.dialogs.configure_integration' | translate)" (click)="goToIntegrations()" customClass="tw-bg-product-prestay"></mh-core-lib-button>
    </div>

    <div class="tw-flex tw-justify-center" *ngIf="type === 'booking-reply'">
        <mh-core-lib-button [isDefault]="true" [width]="200" [text]="('online.integrations.ota.booking.go_to_ota' | translate)" (click)="goToBooking()" customClass="!tw-bg-product-prestay hover:!tw-bg-[#007AAE]"></mh-core-lib-button>
    </div>

    <div class="tw-flex tw-justify-center tw-mt-9" *ngIf="type === 'booking-reply' || type === 'integration'">
        <p class="text-sm tw-text-center">{{ 'online.integrations.ota.booking.date_id_error' | translate: { id: review.id, date} }}</p>
    </div>
</div>
