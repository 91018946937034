import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import { CoreLibTabComponent } from './tab/tab.component';

@Component({
  selector: 'mh-core-lib-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class CoreLibTabsComponent implements OnInit, OnChanges, AfterContentInit {
  @Input()
  mainClassName = '';
  @Input()
  className = '';
  @Input()
  selectedTab: number;
  @Output() tabChanged: EventEmitter<number> = new EventEmitter<number>();

  @ContentChildren(CoreLibTabComponent) tabs: QueryList<CoreLibTabComponent>;
  tabIndex = 0;
  previousIndex = 0;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedTab && changes.selectedTab.currentValue !== this.tabIndex) {
      this.tabIndex = changes.selectedTab.currentValue;
    }
  }

  ngOnInit(): void {
    this.tabIndex = this.selectedTab || 0;
  }

  ngAfterContentInit(): void {
    // Function to set the tabIndex of each tab when the tabs are loaded or changed in runtime
    this.setTabsOnChange();
  }

  toggleTab(index) {
    this.previousIndex = this.tabIndex;
    this.tabIndex = index;
    this.tabChanged.emit(index);
  }

  setTabsOnChange() {
    this.tabs.changes.subscribe((tabs) => {
      this.tabs = tabs;
      setTimeout(() => {
        this.tabs.forEach((tab, index) => {
          tab.tabIndex = index;
        });
      }, 0);
    });
  }
}
