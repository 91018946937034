import { UtilService } from 'app/layouts/main';
import { Component, Input, Output, EventEmitter, ElementRef,HostListener, ViewChild } from '@angular/core';
import { NgbDateStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DatePickerStruct } from 'app/shared/model';
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;

@Component({
  selector: 'mh-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['../../../../../assets/scss/shared/elements/datepicker.scss'],
})
export class DatePickerElementComponent{

  @Input()
  startDate;

  @Input()
  endDate;

  @Input()
  maxDate:NgbDateStruct;

  @Input()
  displayMonths = 2;

  @Input()
  type = 'range'; //or single

  @Input()
  minDate:NgbDateStruct={year: 2015, month: 1, day: 1}

  @Output()
  dateChange: EventEmitter<DatePickerStruct> = new EventEmitter();

  @Output()
  dateSelection: EventEmitter<DatePickerStruct> = new EventEmitter();

  @Output()
  isCalendarClosed: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('dp', { static: false })
  datePicker;

  #showCalendar = false;
  today: NgbDateStruct;
  toDate: NgbDateStruct | any;
  fromDate: NgbDateStruct;
  hoveredDate: NgbDateStruct;
  clickInside;
  clickOutside;
  clickCounter=0;

  @Input() set showCalendar(value: boolean) {
    this.#showCalendar = value;
  }

  get showCalendar(): boolean {
    return this.#showCalendar;
  }

  constructor(private utilService:UtilService,private eRef: ElementRef){
    let next_year  = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    this.maxDate   = this.structDate(next_year);
  }

  ngOnInit() {

  }



  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(this.type==='single'){
      if(this.eRef.nativeElement.contains(event.target)) {
        this.clickInside = true;
      } else if((this.clickInside || (!this.clickInside && this.clickOutside===undefined)) && this.clickInside!==undefined){
        this.clickOutside = true;
        if(this.showCalendar){
          //this.closeCalendar()
        };
      }else{
        if(this.showCalendar){
          //this.closeCalendar();
        }
      }

    }
  }

  ngOnChanges() {
    this.refreshCustomDate()
  }

  closeCalendar(){
    this.showCalendar = false;
    this.isCalendarClosed.emit(true);
  }

  onDateChange(date: NgbDateStruct) {
    this.clickCounter = 0;
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && (after(date, this.fromDate) || equals(date, this.fromDate))) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    if(this.type === 'single') {
      this.fromDate = date;
      this.toDate = this.fromDate;
      this.showCalendar = false;
    }
    this.dateChange.emit({
      toDate: this.toDate,
      fromDate: this.fromDate
    })

  }


  onDateSelection(date: NgbDate) {
    this.clickCounter++;
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    }else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    if(this.clickCounter>1){
      this.clickCounter = 0;
      if(this.type !== 'single'){
        this.dateChange.emit({
          toDate: date,
          fromDate: date
        })
      }

    }


    this.dateSelection.emit({
      toDate: this.toDate,
      fromDate: this.fromDate
    })
  }

  structDate(date:Date){
    let year  = date.getFullYear();
    let month = (date.getMonth()+1);
    month     = month>12 ? 12 : month;
    let day   = date.getUTCDate()
    return {year: year, month: month, day: day}
  }

  refreshCustomDate(){
    if(this.startDate && this.endDate){
      this.fromDate = this.structDate(this.utilService.stringDateToDate(this.startDate));
      this.toDate   = this.structDate(this.utilService.stringDateToDate(this.endDate));
    }
  }

  isHovered   = date => this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate);
  isInside    = date => after(date, this.fromDate) && before(date, this.toDate);
  isFrom      = date => equals(date, this.fromDate);
  isTo        = date => equals(date, this.toDate);

}
