<ng-container *ngIf="isCreate else itemDetail">
  <ng-container *ngTemplateOutlet="itemCreate">
  </ng-container>
</ng-container>


<ng-template #itemCreate>
  <div class="tw-relative">

    <article class="tw-px-6 tw-py-4 tw-flex tw-flex-col tw-text-main-text">

      <section class="tw-self-center tw-flex tw-flex-col tw-items-center tw-gap-22">
        <span class="tw-font-medium">{{review.guest.username}}</span>
        <span class="tw-flex tw-items-center tw-gap-3" *ngIf="review.guest.country.code">
          <span [ngStyle]="{'width': 20+'px', 'height': 20+'px'}" class="flag-icon flag-icon-squared flag-icon-{{review.guest.country.code.toLowerCase()}} tw-rounded-full"></span>
          <span class="tw-text-sm tw-font-medium tw-self-center">{{review.guest.country.name}}</span>
        </span>
      </section>

      <section class="tw-flex tw-flex-col">
        <div class="tw-flex">
          <span class="tw-basis-3/5 tw-font-medium"><i class="fas fa-portrait tw-mr-5"></i> {{'cases.product.subTypes.review' | translate}}</span>
          <span class="tw-basis-2/5 tw-font-bold">{{review.id}}</span>
        </div>
        <div class="tw-flex tw-justify-between">
          <span class="tw-basis-3/5 tw-font-medium"><i class="far fa-calendar-alt tw-mr-5"></i> {{'commons.date' | translate}}</span>
          <span class="tw-basis-2/5 tw-font-bold">{{(review.publicationDate || review.publication_date) | formatDate: 'es'}}</span>
        </div>
        <div class="tw-flex tw-justify-between">
          <span class="tw-basis-3/5 tw-font-medium"><i class="fas fa-plane-alt tw-mr-5"></i>Viajo en</span>
          <span class="tw-basis-2/5 tw-font-bold">{{(review.typeTraveler?.name_es || review.traveler_type.name_es)}}</span>
        </div>
      </section>

      <section class="tw-flex tw-flex-col tw-gap-2 tw-mt-5">
        <div class="tw-self-start tw-font-semibold">{{review.title}}</div>
        <p>{{review.description}}</p>
      </section>

      <section class="tw-flex tw-flex-col tw-mt-5">
        <article class="tw-flex tw-justify-between">
          <span class="tw-basis-3/4 tw-font-medium"><i class="fas fa-check tw-w-6 tw-mr-4"></i> Calificación myHotel</span>
          <div class="tw-basis-1/4 tw-flex tw-gap-3 tw-items-center">
            <span class="tw-font-bold tw-text-2xl tw-text-main">{{review.my_hotel_rating | number: '1.1-1' : 'en'}}</span>
            <span class="tw-font-bold">|</span>
            <span class="tw-font-bold">5.0</span>
          </div>
        </article>
        <article class="tw-flex tw-justify-between">
          <span class="tw-basis-3/4 tw-font-medium"><img class="tw-rounded-full tw-w-6 tw-inline tw-mr-4" [src]="review.ota.id | getOtaLogo" alt="{{review.ota.name}}"> {{review.ota.name}}</span>
          <div class="tw-basis-1/4 tw-flex tw-gap-3 tw-items-center">
            <span class="tw-font-bold tw-text-2xl tw-text-main">{{review.ota_rating | number: '1.1-1' : 'en'}}</span>
            <span class="tw-font-bold">|</span>
            <span class="tw-font-bold">5.0</span>
          </div>
        </article>
      </section>

      <div class="tw-border tw-border-border-medium-gray tw-w-full tw-my-5" *ngIf="review.answer"></div>

      <section class="tw-flex tw-flex-col" *ngIf="review.answer">
        <span class="tw-self-end tw-font-bold tw-cursor-pointer" (click)="openAnswer = !openAnswer">{{'online.reviews.see_answer' | translate}} <i class="fas fa-angle-up tw-text-main" [@rotate]="openAnswer ? '180' : '0'"></i></span>
        <span [@heightExpand]="openAnswer ? '*' : 'void'">{{review.answer.description}}</span>
      </section>

    </article>

  </div>
</ng-template>

<ng-template #itemDetail>
  <div class="tw-hidden md:tw-block">
    <ng-container *ngTemplateOutlet="desktopDetail"></ng-container>
  </div>
  <div class="md:tw-hidden">
    <ng-container *ngTemplateOutlet="mobileDetail"></ng-container>
  </div>
</ng-template>

<ng-template #mobileDetail>
  <div class="tw-px-5 tw-py-6 tw-flex tw-flex-col tw-h-fit tw-relative tw-overflow-hidden">

    <!-- contiene respuesta -->
    <div
       class="tw-bg-background-managed-gray tw-top-[-5px] tw-left-[-30px] tw-w-[80px] tw-h-[40px] tw-absolute tw-rotate-[-50deg]"
       [class.!tw-bg-promoters]="review.answer"
       >
       <div class="tw-absolute tw-bottom-[1px] tw-right-[32px] tw-rotate-[50deg]">
         <i *ngIf="review.answer" class="fas fa-check-circle tw-text-white"></i>
         <i *ngIf="!review.answer" class="fas fa-minus-circle tw-text-white"></i>
       </div>
    </div>

    <!-- info huesped -->
    <section class="tw-flex tw-flex-col tw-gap-0.5 tw-text-main-text tw-mt-5">
      <span class="tw-font-semibold tw-text-sm">{{review.guest.username}}</span>
      <span class="tw-text-sm">{{'online.reviews.id' | translate}} <span class="tw-font-semibold">{{review.id}}</span></span>
      <span class="tw-text-sm">{{'commons.date' | translate}}: {{review.publication_date | formatDate}}</span>
      <span class="tw-text-sm">{{'commons.type_traveler.type_'+review.traveler_type.id | translate | titlecase}}</span>
      <div class="tw-flex tw-gap-3" *ngIf="review.guest.country.code">
        <span [ngStyle]="{'width': 20+'px', 'height': 20+'px'}" class="flag-icon flag-icon-squared flag-icon-{{review.guest.country.code.toLowerCase()}} tw-rounded-full"></span>
        <span class="tw-text-sm tw-font-medium tw-self-center">{{'countries.'+review.guest.country.code | translate}}</span>
      </div>
      <a href="{{url}}" target="_blank" class="tw-text-main tw-font-semibold">{{'commons.see_detail' | translate}}</a>
    </section>

    <!-- info review -->
    <section class="tw-flex tw-flex-col tw-text-main-text tw-mt-3">
      <span class="tw-font-medium tw-mb-3">{{review.title}}</span>
      <p class="tw-mb-1" [@heightDefinedExpand]="{value: heightValueAnimation, params: {height: '100px'}}">{{review.description}}</p>
      <button type="button" class="focus:tw-outline-0 tw-text-main tw-font-semibold tw-self-start hover:tw-text-main-orange tw-transition-all" *ngIf="review.description.length > 150" (click)="toggleDescription()">
        {{(showFullDescription  ? 'commons.see_less' : 'commons.see_more') | translate}}
      </button>
      <p class="tw-mb-1" *ngIf="review.answer" [@heightExpand]="showAnswer ? 'expanded' : 'void'">{{review.answer?.description}}</p>
      <button *ngIf="review.answer" type="button" class="focus:tw-outline-0 tw-text-main tw-font-semibold tw-self-start hover:tw-text-main-orange tw-transition-all" (click)="toggleAnswer()">
        {{(showAnswer ? 'navbar.collapse_button' : 'online.reviews.see_answer') | translate}}
      </button>
    </section>

    <!-- info rating -->
    <section class="tw-flex tw-flex-col tw-text-main-text tw-ml-5 tw-gap-5">
      <span class="tw-font-semibold tw-self-center">{{'commons.qualify' | translate}}</span>
      <div class="tw-flex tw-justify-evenly">
        <div class="tw-flex tw-flex-col">
          <div class="tw-flex tw-gap-1.5 tw-items-end tw-relative">
            <img class="tw-rounded-full tw-shadow-1 tw-py-[9px]" [src]="myhotelLogo" [width]="30">
            <span class="tw-font-semibold tw-text-2xl tw-text-main tw-inline-block tw-relative tw-top-[2px]">{{review.my_hotel_rating}}</span>
            <span class="tw-text-xl">|</span>
            <span class="tw-font-semibold">5.0</span>
          </div>
        </div>
        <div class="tw-flex tw-flex-col">
          <div class="tw-flex tw-gap-1.5 tw-items-end tw-relative">
            <img class="tw-rounded-full" [src]="review.ota.id | getOtaLogo" [width]="30">
            <span class="tw-font-semibold tw-text-2xl tw-text-main tw-inline-block tw-relative tw-top-[2px]">{{review.ota_rating}}</span>
            <span class="tw-text-xl">|</span>
            <span class="tw-font-semibold">5.0</span>
          </div>
      </div>

      </div>
    </section>


  </div>

</ng-template>

<ng-template #desktopDetail>
  <div class="tw-px-5 tw-py-6 tw-flex tw-h-fit tw-relative tw-overflow-hidden">
    <!-- contiene respuesta -->
    <div
       class="tw-bg-background-managed-gray tw-top-[-5px] tw-left-[-30px] tw-w-[80px] tw-h-[40px] tw-absolute tw-rotate-[-50deg]"
       [class.!tw-bg-promoters]="review.answer"
       >
       <div class="tw-absolute tw-bottom-[1px] tw-right-[32px] tw-rotate-[50deg]">
         <i *ngIf="review.answer" class="fas fa-check-circle tw-text-white"></i>
         <i *ngIf="!review.answer" class="fas fa-minus-circle tw-text-white"></i>
       </div>
    </div>

    <!-- info huesped -->
    <section class="tw-min-w-[200px] tw-flex tw-flex-col tw-gap-0.5 tw-text-main-text tw-mt-5">
      <span class="tw-font-semibold tw-text-sm">{{review.guest.username}}</span>
      <span class="tw-text-sm">{{'online.reviews.id' | translate}} <span class="tw-font-semibold">{{review.id}}</span></span>
      <span class="tw-text-sm">{{'commons.date' | translate}}: {{review.publication_date | formatDate}}</span>
      <span class="tw-text-sm">{{'commons.type_traveler.type_'+review.traveler_type.id | translate | titlecase}}</span>
      <div class="tw-flex tw-gap-3" *ngIf="review.guest.country.code">
        <span [ngStyle]="{'width': 20+'px', 'height': 20+'px'}" class="flag-icon flag-icon-squared flag-icon-{{review.guest.country.code.toLowerCase()}} tw-rounded-full"></span>
        <span class="tw-text-sm tw-font-medium tw-self-center">{{'countries.'+review.guest.country.code | translate}}</span>
      </div>
      <a href="{{url}}" target="_blank" class="tw-text-main tw-font-semibold">{{'commons.see_detail' | translate}}</a>
    </section>


    <!-- info review -->
    <section class="tw-flex tw-flex-col tw-text-main-text tw-min-w-[430px] tw-max-w-[430px]">
      <span class="tw-font-medium tw-mb-3">{{review.title}}</span>
      <p class="tw-mb-1" [@heightDefinedExpand]="{value: heightValueAnimation, params: {height: '100px'}}">{{review.description}}</p>
      <button type="button" class="focus:tw-outline-0 tw-text-main tw-font-semibold tw-self-start hover:tw-text-main-orange tw-transition-all" *ngIf="review.description.length > 150" (click)="toggleDescription()">{{'commons.see_more' | translate}}</button>
      <p class="tw-mb-1" [@heightExpand]="showAnswer ? 'expanded' : 'void'">{{review.answer?.description}}</p>
      <button *ngIf="review.answer" type="button" class="focus:tw-outline-0 tw-text-main tw-font-semibold tw-self-start hover:tw-text-main-orange tw-transition-all" (click)="toggleAnswer()">{{'online.reviews.see_answer' | translate}}</button>
    </section>

    <!-- info rating -->
    <section class="tw-flex tw-flex-col tw-text-main-text tw-ml-5 tw-gap-5">
      <span class="tw-font-semibold tw-self-center">{{'commons.rating' | translate}}</span>
      <div class="tw-flex tw-flex-col">
        <div class="tw-flex tw-gap-1.5 tw-items-end tw-relative">
          <img class="tw-rounded-full tw-shadow-1 tw-py-[9px]" [src]="myhotelLogo" [width]="30">
          <span class="tw-font-semibold tw-text-2xl tw-text-main tw-inline-block tw-relative tw-top-[2px]">{{review.my_hotel_rating}}</span>
          <span class="tw-text-xl">|</span>
          <span class="tw-font-semibold">5.0</span>
        </div>
      </div>
      <div class="tw-flex tw-flex-col">
        <div class="tw-flex tw-gap-1.5 tw-items-end tw-relative">
          <img class="tw-rounded-full" [src]="review.ota.id | getOtaLogo" [width]="30">
          <span class="tw-font-semibold tw-text-2xl tw-text-main tw-inline-block tw-relative tw-top-[2px]">{{review.ota_rating}}</span>
          <span class="tw-text-xl">|</span>
          <span class="tw-font-semibold">5.0</span>
        </div>
      </div>
    </section>
  </div>
</ng-template>

