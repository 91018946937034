import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MentionConfig } from 'angular-social-mentions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mh-core-lib-textarea-input',
  templateUrl: './textarea-input.component.html',
  styleUrls: ['./textarea-input.component.scss'],
})
export class CoreLibTextareaInputComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('textAreaInput', { static: false }) textarea: ElementRef;

  private _users;
  @Input() set users(newValue) {
    if (newValue) {
      this._users = newValue;
      this.createItemsForMentions();
    }
  }
  get users() {
    return this._users;
  }

  @Input()
  formGroup: FormGroup;

  @Input()
  keyControl;

  @Input()
  placeholder = '';

  @Input()
  rows: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 = 3;

  @Input()
  withMentions = false;

  @Input()
  inputClass;

  @Input()
  value;

  @Input()
  maxLength: number;

  @Input()
  customContainerClass: string;

  @Input()
  autoRezise = false;

  @Output()
  mentionStateChanged = new EventEmitter();

  @Output()
  textareaReady = new EventEmitter();

  mentionConfig: MentionConfig | null = null;
  mentionState = [];
  subscription = new Subscription();

  ngOnInit(): void {
    if (this.withMentions) {
      this.createItemsForMentions();
      this.formatContent(null, this.formGroup.get(this.keyControl).value )
    }
  }

  ngAfterViewInit(): void {
    this.textareaReady.emit(this.textarea);
    if (this.autoRezise) {
      this.subscription = this.formGroup.get(this.keyControl).valueChanges.subscribe((result) => {
        if (result) {
          this.textarea.nativeElement.style.height = this.textarea.nativeElement.scrollHeight + 'px';
        } else {
          this.textarea.nativeElement.style.height = '40px';
        }
      });
    }
  }

  createItemsForMentions(): void {
    const usersToMention = this.users.map(({ id, first_name, last_name, email, company_position }) => ({
      email,
      id,
      mentionId: `@[${email}:${btoa(String(id))}]`,
      name: `${first_name} ${last_name} (${company_position})`,
    }));
    this.mentionConfig = {
      mentions: [
        {
          items: usersToMention,
          labelKey: 'name',
          triggerChar: '@',
          mentionSelect: this.formatMention,
          dropUp: false,
        },
      ],
    };
  }

  formatContent(event?, initialContent = null): void {
    const value = event?.target?.value ?? initialContent;
    if (value && value.length > 0) {
      const controlText = this.formGroup.get(this.keyControl);
      const matches = value.match(/\@\[(.*?)\]/g) || [];
      let newValue;
      matches.forEach((match: string) => {
        try {
          let match_hash = match.replace(/\[|\]|^\@/g, '');
          const hashContent = match_hash?.split(':') || '';
          const hashId = hashContent[1];
          if( hashId ) {
            const base64Id = +atob(hashId);
            const hash = match;
            const user = this.users.find((findedUser) => findedUser.id === base64Id);
            newValue = user && value.replace(hash, `@[${user.first_name} ${user.last_name}]`);
            const key = `@[${user.first_name} ${user.last_name}]`
            const hashValue = `@[${match_hash}]`

            if (!this.mentionState.find((findedMention) => findedMention.key === key)) {
              this.mentionState = [...this.mentionState, { key, value: hashValue }];
            }
          }

        } catch(error) {
          const user = match;
          newValue = !this.mentionState.find((mention) => mention.key === user) ? value.replace(user, '') : null;
        }
      });

      if (newValue !== null && newValue !== undefined) {
        controlText.setValue(newValue);
        this.mentionStateChanged.emit(this.mentionState);
      }
    }
  }

  formatMention(item: any): string {
    return `@[${item.email}:${btoa(String(item.id))}]`;
  }

  selectMention(mention): void {
    const value = mention.mentionId;
    const user = this.users.find((findedUser) => findedUser.id === mention.id);
    const key = `@[${user.first_name} ${user.last_name}]`;
    if (!this.mentionState.find((findedMention) => findedMention.key === key)) {
      this.mentionState = [...this.mentionState, { key, value }];
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
