import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateService } from 'app/shared/services/date.service';
import { VoicesService } from './voices.service';
import { Select, Store } from '@ngxs/store';
import { CustomersState } from 'app/states/customers/customers.state';
import { Observable, Subscription } from 'rxjs';
import { UtilService } from 'app/shared/util.service';
import { ModalService } from 'app/core-lib/dialogs/modal/modal.service';
import { OriginModalComponent } from './opinions/origin-modal/origin-modal.component';
import { filter, take } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'mh-voices',
  templateUrl: './voices.component.html',
})
export class VoicesComponent implements OnInit, OnDestroy {
  @Select(CustomersState.currentCustomer) customers$: Observable<any>;
  subs$: Subscription;
  isConfig = false;
  origins = [];
  customer;
  filterOriginIds;
  loading = false;
  firstCallAPI = false;
  showBetaAlert = false;

  constructor(
    private dateService: DateService,
    private utilService: UtilService,
    private store: Store,
    private modalService: ModalService,
    public voicesService: VoicesService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.showBetaAlert = this.router.url.includes('messages');
    this.loadOrigins();
    this.initListeners();
  }

  handleDatepickerUpdated($event) {
    this.dateService.setCurrentFilter($event.currentFilter);
    this.dateService.setCurrentDates($event.currentDates);
  }

  filterByOrigin(origins) {
    this.filterOriginIds = origins;
    this.voicesService.setOriginsSelected(origins);
  }

  loadOrigins() {
    this.loading = true;
    this.customer = this.store.selectSnapshot(CustomersState.currentCustomer);
    this.origins = [];
    this.voicesService
      .getAllOrigins(this.customer?.id ?? this.utilService.getCurrentCustomer()?.id)
      .subscribe((result) => {
        this.origins = result;
        this.voicesService.setOrigins(result);
        this.loading = false;
        this.firstCallAPI = true;
      });
  }

  initListeners() {
    this.subs$ = this.customers$.subscribe((_) => {
      if (this.firstCallAPI) {
        this.loadOrigins();
      }
    });
    this.subs$.add(this.voicesService.loadOrigins$.subscribe(() => this.loadOrigins()));
    this.subs$.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationStart))
        .subscribe((route: NavigationStart) => {
          this.showBetaAlert = route?.url?.includes('messages');
        }),
    );
  }

  ngOnDestroy(): void {
    if (this.subs$) {
      this.subs$.unsubscribe();
    }
  }

  openOrigins() {
    const modalClass = ['overlay-panel', 'xxlg', '!tw-h-auto', '!tw-overflow-y-auto'];
    const dialogRef = this.modalService.open(OriginModalComponent, null, modalClass);
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((data) => {
        this.loadOrigins();
      });
  }

  get currentDates() {
    return this.dateService.currentDates;
  }

  get currentFilterDate() {
    return this.dateService.currentFilter;
  }

  get isConfigPage() {
    return window.location.href.includes('configuracion');
  }
}
