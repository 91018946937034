<div class="row">
  <div class="d-none d-sm-block col-sm-auto">
    <div class="arrows">
      <span mhFindTranslate
        [language]="language"
        [translates]="case.state.translates"
        [keyTranlsate]="'name'"
        [capitalize]="true"
        class="state-arrow-{{backgroundColor}}">
      </span>
      <span mhFindTranslate
        [language]="language"
        [translates]="case.priority.translates"
        [keyTranlsate]="'name'"
        [capitalize]="true"
        class="priority_arrow_{{case.priority.id}}-{{backgroundColor}}">
      </span>
      <ng-container *ngIf="case.product_item else noProduct">
        <span
          class="{{getProductName(case.product_item.product_id)}}-arrow-{{backgroundColor}}">
          {{'cases.product.subTypes.'+case.product_item.type.name | translate}}
        </span>
        <span
          class="{{getProductName(case.product_item.product_id)}}-arrow-{{backgroundColor}}">
          {{'navbar.product.default.' + getProductName(case.product_item.product_id) | translate}}
        </span>
      </ng-container>
      <ng-template #noProduct>
        <span class="no-product-arrow-{{backgroundColor}}">{{'cases.commons.no_product_tag' | translate}}</span>
      </ng-template>
    </div>
  </div>
  <div class="col text-sm-right">
    <span
      class="stagnant"
      *ngIf="isStagnant"
      placement="top"
      ngbTooltip="{{'cases.tooltips.stagnant' | translate}}"
    >
      <i class="fas fa-octagon"></i>
    </span>
    <strong class="identifier">
      {{('cases.commons.case' | translate) | uppercase}}: {{case.public_id}}
    </strong>
    <div class="inline">
      <div>
        <i class="far fa-calendar-alt"></i>
        {{case.created_at | date:'dd-MM-y'}}
      </div>
      <div class="closed-case" *ngIf="isClosed">
        <i class="fal fa-calendar-check"></i>
        {{case.last_state_change | date:'dd-MM-y'}}
      </div>
    </div>
  </div>
</div>
