import { Injectable } from '@angular/core';
import { TeamsData } from 'src/app/new-fs/gestor-usuarios/teams/model/teams.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TeamsService {
  teams = [];
  constructor(private http: HttpClient) {}

  getHierarchies() {
    const url = `${environment.customerPath}teams/hierarchy`;
    return this.http.get(url);
  }

  getTeamsByCustomerId(customerId, page = 0, pageSize = 10, order_column = 'created', asc = false) {
    const params = new HttpParams()
      .set('page_size', pageSize)
      .set('page', page)
      .set('order_column', order_column)
      .set('asc', asc);
    const url = `${environment.customerPath}teams/customer/${customerId}`;
    return this.http.get<TeamsData>(url, { params });
  }

  checkTeamName(customerId, teamName) { 
    const url = `${environment.customerPath}teams/customer/${customerId}/checkname/${teamName}`;
    return this.http.get<boolean>(url);
  }

  saveTeam(body) {
    const url = `${environment.customerPath}teams/create`;
    return this.http.post(url, body);
  }

  deleteTeam(teamId) {
    const url = `${environment.customerPath}teams/delete/${teamId}`;
    return this.http.delete(url);
  }

  editData(body) {
    const url = `${environment.customerPath}teams/edit`;
    return this.http.patch(url, body);
  }

  editUsers(body) {
    const url = `${environment.customerPath}teams/edit/users`;
    return this.http.patch(url, body);
  }

  editAreas(body) {
    const url = `${environment.customerPath}teams/edit/areas`;
    return this.http.patch(url, body);
  }
}
