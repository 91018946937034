import { Component, Input } from '@angular/core';
import { UtilService } from 'app/shared/util.service';

@Component({
  selector: 'mh-box-score',
  templateUrl: './box_score.component.html',
  styleUrls: ['../../../../assets/scss/shared/elements/box_score.scss'],
})

export class BoxScoreComponent{

  @Input() type: string='up';
  @Input() type_number: string = 'previous';
  @Input() previous_value_percent: string='';
  @Input() previous_value: string='';
  @Input() previous: string = '0%';
  @Input() value: string|number = 0;
  @Input() subValue:string|number;
  @Input() style ='';
  util;

  constructor(private utilService:UtilService){
    this.util = this.utilService;
  }
}
