import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'mh-confirm-create',
  templateUrl: './confirm-create.component.html',
  styleUrls: ['./confirm-create.component.scss'],
})
export class ConfirmCreateComponent implements OnInit {
  checkboxes = [false, false];
  toggleColors = ['tw-bg-main', 'tw-bg-main'];
  showPassword = false;
  passwordMask = '';

  @Input() data;
  @Output() emitConfirm = new EventEmitter();

  ngOnInit(): void {
    if (this.data?.new_password) {
      this.data.new_password = atob(this.data?.new_password);
      this.passwordMask = '*'.repeat(this.data.new_password.length);
    }
  }

  confirm() {
    this.emitConfirm.next({ checkboxes: this.checkboxes, userId: this.data.id });
  }
}
