import { Component, Input } from '@angular/core';
import { UtilService } from "../../../shared/util.service";
import { AlertsService } from "./alerts.service";
import { NgbModal, NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-configuration-benchmark',
  templateUrl: './alerts.component.html',
  styleUrls: ['../../../../assets/scss/configuration/alerts.scss'],
  providers:[AlertsService]
})
export class ConfigurationAlertsComponent {

  followupUsers:User[] = [];
  followupAlertsType:any[] = [];

  onlineUsers:User[] = [];
  onlineAlertsType:any[] = [];
  onlineOtas:any[] = [];

  color     = 'red';
  checked   = true;
  disabled  = false;

  constructor(private utilService:UtilService, private alertsService:AlertsService, private modalService: NgbModal) {
    this.utilService.isCustomerChanged().subscribe(hotel => { this.initAlerts(); });
  }

  ngAfterContentInit(){
    if(typeof this.utilService.currentHotel !== "undefined"){
      this.initAlerts();
    }
  }



  initAlerts(){
    this.alertsService.getFollowupAlerts(this.utilService.currentHotel.id).subscribe((response: any) => {
      this.followupAlertsType = response.alerts;
      for(let user of response.users){
        let new_user = new User(user["user"].profile.name, user["user"].profile.last_name, user["user"].profile.email);
        new_user.alerts=user.alerts_id;
        this.followupUsers.push(new_user)
      }
    });

    this.alertsService.getOnlineAlerts(this.utilService.currentHotel.id).subscribe((response: any) => {
      this.onlineAlertsType = response.alerts;
      this.onlineOtas = response.otas;
      for(let user of response.users){

        let new_user = new User(user["user"].profile.name, user["user"].profile.last_name, user["user"].profile.email);
        new_user.alertsByOtas=user.alerts_by_ota;
        this.onlineUsers.push(new_user)
      }
    });
  }

  hasFollowupAlert(user:User, alert_id:number):boolean{
    return user.alerts.find(x => x === alert_id);
  }

  hasOnlineOtaAlert(user:User, ota_id:number):boolean{
    return user.alertsByOtas.find(x => x.ota_id === ota_id);
  }

  showModal(user, ota){
    const modalRef = this.modalService.open(AlertDisplayModal,{
      size: 'lg'
    });
    let alerts = user.alertsByOtas.find(x => x.ota_id === ota.id);
    modalRef.componentInstance.user     = user;
    modalRef.componentInstance.ota      = ota;
    modalRef.componentInstance.alerts   = alerts.alerts_id;
    modalRef.componentInstance.allAlerts   = this.onlineAlertsType;
  }

}

export class User{
  name:string;
  last_name:string;
  email:string;
  alerts:any[] = [];
  alertsByOtas:any[] = [];

  constructor(name?:string, last_name?:string, email?:string)
  constructor(name:string, last_name:string, email:string){
    this.name      = name.toLowerCase();
    this.last_name = last_name.toLowerCase();
    this.email     = email.toLowerCase();
  }
}

@Component({
  templateUrl: './display-modal.html',
  styleUrls: ['../../../../assets/scss/configuration/alerts.scss'],
})
export class AlertDisplayModal{
  @Input()
  user: User;
  @Input()
  ota;

  @Input()
  alerts=[];

  @Input()
  allAlerts;

  hasOnlinceOtaActiveAlert(alert_id){
    return this.allAlerts.find(x => x === alert_id);
  }

  constructor(public activeModal: NgbActiveModal) {}
}