import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class FollowupPropertiesService {
  constructor(private http: HttpClient) {}

  getRankignAreas(dates: any, customerIds: string, productId, languageId, surveysIds, version = 1) {
    const url =
      version === 1
        ? `${environment.followupPath}interchains/ranking/areas`
        : `${environment.followupPath}corporative/areas`;
    const params: HttpParams = new HttpParams()
      .set('start_date', dates.startDate)
      .set('end_date', dates.endDate)
      .set('customers_ids', customerIds)
      .set('product_id', productId)
      .set('language_id', languageId)
      .set('surveys_ids', surveysIds);
    return this.http.get(url, { params: params }).pipe(map((response) => response));
  }

  getNPS(dates: any, customerIds: string, productId: any, surveysIds, version = 1) {
    const url =
      version === 1
        ? `${environment.followupPath}interchains/nps/metricts`
        : `${environment.followupPath}corporative/nps`;
    const params: HttpParams = new HttpParams()
      .set('start_date', dates.startDate)
      .set('end_date', dates.endDate)
      .set('customers_ids', customerIds)
      .set('product_id', productId)
      .set('surveys_ids', surveysIds);
    return this.http.get(url, { params: params }).pipe(map((response) => response));
  }

  public getPMS(dates: any, customerIds: string, productId, surveysIds) {
    const url = `${environment.followupPath}interchains/pms/emails`;
    const params: HttpParams = new HttpParams()
      .set('start_date', dates.startDate)
      .set('end_date', dates.endDate)
      .set('customers_ids', customerIds)
      .set('product_id', productId)
      .set('surveys_ids', surveysIds);
    return this.http.get(url, { params: params }).pipe(map((response) => response));
  }

  public getRankingTripadvisor(dates: any, customerIds: string, productId, surveysIds) {
    const url = `${environment.onlinePath}interchains/rankings`;
    const params: HttpParams = new HttpParams()
      .set('start_date', dates.startDate)
      .set('end_date', dates.endDate)
      .set('customer_ids', customerIds)
      .set('ota_id', '1')
      .set('product_id', productId)
      .set('surveys_ids', surveysIds);
    return this.http.get(url, { params: params }).pipe(map((response) => response));
  }

  public getRankingSemantic(dates: any, customerIds: string, productId, surveysIds) {
    const url = `${environment.followupPath}interchains/semantic/metricts`;
    const params: HttpParams = new HttpParams()
      .set('start_date', dates.startDate)
      .set('end_date', dates.endDate)
      .set('customers_ids', customerIds)
      .set('product_id', productId)
      .set('surveys_ids', surveysIds);
    return this.http.get(url, { params: params }).pipe(map((response) => response));
  }

  public getRate(dates: any, customerIds: string, productId, surveysIds, previousPeriod = false, version) {
    const url =
      version === 1
        ? `${environment.followupPath}interchains/response/rate`
        : `${environment.followupPath}corporative/surveys/response/rate`;
    const params: HttpParams = new HttpParams()
      .set('start_date', dates.startDate)
      .set('end_date', dates.endDate)
      .set('customers_ids', customerIds)
      .set('product_id', productId)
      .set('previous_period', previousPeriod.toString())
      .set('surveys_ids', surveysIds);
    return this.http.get(url, { params: params }).pipe(map((response) => response));
  }

  // New semantic index
  public getSemanticIndex(dates: any, customerIds: string, productId: number, surveysIds: any) {
    const url = `${environment.customerPath}customers/semantic/index`;
    const params: HttpParams = new HttpParams()
      .set('start_date', dates.startDate)
      .set('end_date', dates.endDate)
      .set('customers_ids', customerIds)
      .set('product_id', productId)
      .set('surveys_ids', surveysIds)
      .set('previous_period', true);
    return this.http.get(url, { params: params }).pipe(map((response) => response));
  }
  public getAllSemanticIndex(dates: any, customerIds: string) {
    const url = `${environment.customerPath}customers/semantic/index`;
    const params: HttpParams = new HttpParams()
      .set('start_date', dates.startDate)
      .set('end_date', dates.endDate)
      .set('customers_ids', customerIds)
      .set('previous_period', true);
    return this.http.get(url, { params: params }).pipe(map((response) => response));
  }
}
