import {Component, Input, Output, EventEmitter} from '@angular/core';
import {UtilService}    from "../../../shared/util.service";
import {OnlineSummaryService} from "./interchain-online-summary.service";
import {Hotel}   from "../../../shared/model/hotel";
import {AppComponent} from "../../../layouts/app/app-component";
import {CustomerService} from "../../../shared/customer.service";
import {HtmlHelper} from '../../../_helpers/html-helper.service';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CustomersState } from 'app/states/customers/customers.state';
import { Subscription } from 'rxjs';

// TABLE IMPORTS
import {ViewChild} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'mh-inter-chain-online-summary',
  templateUrl: './interchain-online-summary.compontent.html',
  styleUrls: ['./interchain-online-summary.component.scss'],
  providers:[OnlineSummaryService, CustomerService]
})
export class OnlineSummaryComponent extends AppComponent{

  @Select(CustomersState.relativeCurrentChain) customersReady$: Observable<any[]>;

  @Input()
  startDate;
  @Input()
  endDate;
  @Input()
  chain;

  @Input()
  showFilter:boolean=true;

  @Input()
  headerKeys:string[] = [];

  data;

  currentYear;
  actives:number[] = [];
  chainActives = [];

  customers:any[]=[];

  public months: any[] = [];

  headerTable: string[] = ["customer","general","accumulated","nps","coverage_comments","quantity_comments","promoter_comments","detractor_comments"];
  dataTable: CellTable[] = [];
  dataSource: any = new MatTableDataSource([]);
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Output()
  isReady: EventEmitter<boolean> = new EventEmitter();
  customersStateSubscribe: Subscription;

  constructor(
    private utilService: UtilService,
    private onlineSummaryService:OnlineSummaryService,
    private customerService: CustomerService,
    private htmlHelper: HtmlHelper,
    private store: Store
  ) {
    super();
    this.months = utilService._monthNames["es"];
    this.customersStateSubscribe = this.customersReady$.subscribe(customers => this.initSummary());
  }

  ngOnChanges(): void {
    if(typeof this.utilService.currentHotel !== "undefined") this.initSummary();
    this.chainActives = this.chain;
  }

  ngOnDestroy() {
    this.customersStateSubscribe.unsubscribe();
  }

  reset(){
    this.dataTable = []
    this.customers = [];
    this.resetInterchain();
  }

  filterCustomers($event){
    this.actives = $event;
    this.createTable()
  }

  initSummary(){
    const date = this.utilService.stringDateToDate(this.endDate);
    this.currentYear = date.getFullYear()
    this.setWaiting(true);
    this.isReady.emit(false);
    this.reset();
    this.actives = this.chain.map(x=>x.id)
    this.startChunkInterchain(this.actives)
    if(this.actives.length>0) this.loadData();
  }

  loadData(){
    const customers = this.store.selectSnapshot(state => state.customers.relativeCurrentChain).map(c => c.id).join(',');
    if (customers.length) {
      this.onlineSummaryService.getSummary({"startDate": this.startDate,"endDate": this.endDate}, customers).subscribe((response: any[]) => {
        this.data = response;
        console.log(this.data)
        this.nextChain();
        this.customers = this.customers.concat(response);
        if(this.callingInterchain === this.chunkInterchain.length){
          this.createTable()
          this.customerService.findByIds(this.actives.join(',')).subscribe(response=>{
            this.setCustomerDetail(response)
            this.setWaiting(false);
            this.isReady.emit(true);
          })
        }else{
          setTimeout(_ => {
            this.loadData();
          },100);

        }
      })
    }
  }

  createTable(){
    this.dataTable = this.dataTable.sort(function(a,b) {return (a['general']['actual'] < b['general']['actual']) ? 1 : ((b['general']['actual'] < a['general']['actual']) ? -1 : 0);} );

    this.dataSource = new MatTableDataSource(this.dataTable);
    this.dataSource.sortingDataAccessor = function (data, sortHeaderId) {
      let value = data[sortHeaderId];
      switch (sortHeaderId) {
        case "customer":
          value = data[sortHeaderId].name;
          break;
        default:
          value = data[sortHeaderId] ? data[sortHeaderId].actual : 0;
          break;
      }
      if (typeof value === 'string' && !value.trim()) {
        return value;
      }
      return isNaN(+value) ? value : +value;
    };
    this.dataSource.sort = this.sort;
  }

  cellClassCompare(actualValue, previousValue, invert=false){
    if( actualValue === previousValue ||
        actualValue === undefined ||
        previousValue === undefined ||
        actualValue === 0 ||
        previousValue === 0) return 'no_change';
    return ((actualValue > previousValue) && !invert) ? 'up' : 'down';
  }

  cellDiffNumber(element, inPercent=false){
    if(element.actual===element.previous) return '(-)';
    let number = element.variation

    let percent = parseFloat(element.actual) > 0 ? (100 - parseFloat(element.previous)*100/parseFloat(element.actual)) : 0;
    let result  = percent > 0 ? '+'+percent.toFixed(1) : percent.toFixed(1);

    return inPercent ? result+'%' : number;
  }

  npsFormat(number){
    return number>0 ? ('+'+number): number;
  }

  getVariation(element,key){
    let variation = 0;
    if(key === 'nps'){
      variation = element[key].actual - element[key].previous;
    }else{
      variation = (element[key].actual - element[key].previous)/element[key].previous*100
    }

    return variation;
  }

  getTotal(key){
    let result = {
      actual: 0,
      previous: 0,
    };
    let periods = ['actual', 'previous']
    let data = this.dataTable.map(x=>x[key]);

    let period_negatives_actual:number = 0;
    let period_negatives_previous:number = 0;

    data.forEach(element=>{
      periods.forEach(period=>{

        result[period]   = result[period] + element[period];

        if(period === 'actual'){
          if(element.actual === 0) period_negatives_actual++;
        }
        if(period === 'previous'){
          if(element.previous === 0) period_negatives_previous++;
        }


      })
    })

    periods.forEach(period=>{
      let numberFormat:any = key==='quantity_comments' ? result[period] : result[period]/data.length;

      if(period === 'actual' && key !== 'quantity_comments'){
          numberFormat = result[period]/(data.length-period_negatives_actual);
      }
      if(period === 'previous' && key !== 'quantity_comments'){
          numberFormat = result[period]/(data.length-period_negatives_previous);
      }


      if(key==='nps'){
        numberFormat = Math.floor(numberFormat);
        numberFormat = this.utilService.formatNPS(numberFormat);
      }

      result[period]   = result[period] > 0   ? Math.floor(numberFormat*100)/100 : 0;
    })



    //if(this.dataTable.length>0) debugger;

    return result;
  }

  get tableAreaStyle(){
    return this.htmlHelper.getTableAreaStyle(this.utilService.screenHeight);
  }

  get tableContentStyle(){
    return this.htmlHelper.getTableContentStyle(this.headerTable);
  }

}

export interface DataHistoric{
  actual:number;
  previous:number;
  variation:number;
}

export class CellTable{
  customer:Hotel;
  general:DataHistoric
  accumulated:DataHistoric
  coverage_comments:DataHistoric
  quantity_comments:DataHistoric
  promoter_comments:DataHistoric
  detractor_comments:DataHistoric
  nps:DataHistoric

  constructor(
    customer,
    general,
    accumulated,
    coverage_comments,
    quantity_comments,
    promoter_comments,
    detractor_comments,
    nps
    ){
    this.customer = customer;
    this.general = general;
    this.accumulated = accumulated;
    this.coverage_comments = coverage_comments;
    this.quantity_comments = quantity_comments;
    this.promoter_comments = promoter_comments;
    this.detractor_comments = detractor_comments;
    this.nps = nps;
  }
}


