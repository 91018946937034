import { Directive, ElementRef, Input} from '@angular/core';
import { UtilService } from 'app/shared/util.service';

@Directive({
  selector: '[mhFindAlternatives]'
})
export class FindAlternativesDirective {

  @Input() field;

  @Input() input;

  @Input() answer;

  @Input()
  language:string='es';

  element$: ElementRef;

  constructor(el: ElementRef, private utilService:UtilService) {
    this.element$ = el;
  }

  ngOnChanges(){
    const input = this.field ? this.field : this.createInputObject(this.input, this.answer, this.input.input_alternatives);

    const type = input.input.input_type.type;
    let answer = input.answer;
    if(['radiobutton','select'].includes(type) && answer){
      const alternatives = input.input.input_alternatives;
      const selected     = parseInt(answer);
      const alternative_selected = alternatives.filter(x=>x.id === selected)
      this.utilService.findInputTranslate(alternative_selected[0].translates,this.language);
      answer = this.utilService.findInputTranslate(alternative_selected[0].translates,this.language);
    }

    if(type==='city' && answer){
      if(this.utilService.cities.length===0){

      }else{
        const city = this.utilService.cities.find((x: any) => x && x.id === parseInt(answer));
        answer = city && city['name'];
      }

    }

    if(['checkbox'].includes(type) && answer){
      const trans: any[] = [];
      const alternatives         = input.alternatives;
      const selected             = answer.split(',').map(x=>parseInt(x));
      const alternative_selected = alternatives.filter(x=>selected.includes(x.id))
      alternative_selected.forEach(element => {
        const translates = element.alternative ? element.alternative.translates : element.translates;
        trans.push(this.utilService.findInputTranslate(translates,this.language))
      });
      answer = trans.join(', ')
    }

    this.element$.nativeElement.innerText = answer;
  }

  createInputObject(input, inputAnswer, alternatives) {
    const answer = (inputAnswer) ? inputAnswer.answer : '';
    return { input, answer, alternatives };
  }

}
