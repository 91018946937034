import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mh-core-lib-order-case',
  templateUrl: './order-case.component.html',
  styleUrls: ['./order-case.component.scss']
})
export class CoreLibOrderCaseComponent implements OnInit {

  @Input()
  order;

  @Input()
  source: 'create' | 'detail' = 'create';

  @Input()
  customerId = null;

  constructor() { }

  ngOnInit(): void {
  }

  get isCreate() {
    return this.source === 'create';
  }

  get url() {
    const id = this.order.orders[0].id;
    const date = this.order.orders[0].created_at.split(' ')[0];
    return `/prestay/ordenes?order=${id}&customer=${this.customerId}&date=${date}`;
  }


}
