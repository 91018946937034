import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpClientMH } from "../../shared/utils/http-myhotel-client";
import { FilterDate} from '../../shared/model/index';
@Injectable()
export class OrdersService {

  constructor(
    private httpDownload:HttpClientMH,
    private http: HttpClient) { }

  getOrders(customerId, dates: FilterDate, languageId){
    const url=`${environment.prestayPath}customer/${customerId}/view/orders`;
    const params:HttpParams= new HttpParams()
      .set("start_date",dates.startDate)
      .set("end_date",dates.endDate)
      .set("language_id",languageId);

    return this.http.get(url,{params: params})
      .pipe(
        map(response=>response)
      )
  }

  getMockOrders() {
    return this.http.get<any[]>('assets/dummies/prestay/servicios.json')
  }

  downloadOrder(customerId, guestId, orderId){
    const url=`${environment.prestayPath}customer/${customerId}/guest/${guestId}/order/${orderId}/pdf`;
    this.httpDownload.downloadFile(url,`order_MH-${orderId}_${guestId}_${customerId}`);
    return this.httpDownload.getPDF();
  }
}
