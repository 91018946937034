<div [ngbTooltip]="tooltip" class="box_spinner hover {{has_modal ? 'clickeable' : 'not_clickeable'}} {{color_class}}" [class.waiting]="waiting" (click)="open(show_modal)">

  <div class="icon_box">
    <span class="i-s i-s-2x pull-left m-r-sm"  container="body">
      <i class="i i-hexagon2 i-s-base" [class.hover-rotate]="has_modal"></i>
      <i class="i {{icon}} i-1x text-white"></i>
    </span>
  </div>

  <div class="info_box">
    <div class="title m-t-xs" >
      {{title}}
    </div>
    <div class="second_title text-u-c">
      {{text_line_top}}<span>{{text_line_bottom}}</span>
    </div>
  </div>

</div>

<ng-template #show_modal let-c="close" let-d="dismiss">
 <ng-content></ng-content>
</ng-template>