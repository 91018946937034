import { Injectable } from '@angular/core';
import { PeriodItem, PeriodItemDetails } from "../shared/model/index";

export class PercentageData{
  count:number;
  value:number;
  constructor(count=0,value=0){
    this.count = count;
    this.value = value;
  }
}

export class PeriodData{
  value:number=0;
  originalData:any;

  constructor(value, originalData){
    this.value = value;
    this.originalData = originalData;
  }
}

export class DataHistoric{

  actualPeriod:any={}
  customerId:number;
  percentage:any={}
  previousPeriod:any={}

  constructor(actualPeriod, customerId, percentage, previousPeriod){
    this.actualPeriod = actualPeriod;
    this.customerId = customerId;
    this.percentage = percentage;
    this.previousPeriod = previousPeriod;
  }
}

@Injectable()
export class ChainService {
  formatRankingTA(response){
    let periods: any[] = [];
    let emptyTA = {created: 0,last_updated: 0,positions: 0,ranking: 0}
    for(let period of response["periods"]){
      let actual   = period["actualPeriod"]   ? period["actualPeriod"] : emptyTA;
      let previous = period["previousPeriod"] ? period["previousPeriod"] : emptyTA;
      period["actualPeriod"]   = actual;
      period["previousPeriod"] = previous;
      periods.push(period)
    }
    return periods;
  }

  formatPMS(data,actives){
    let pms: any[] = [];
    for(let customer of actives){
      let current = data.find(x=>x["customerId"]===customer)
      if(current===undefined){
        let empty = {
          percentage:{
            count: 0,
            value: 0
          }
        }
        let actualPeriod   = new PeriodData(0,empty)
        let prevPeriod     = new PeriodData(0,empty)
        let percentageData = new PercentageData(0,0)
        let pmsItem        = new DataHistoric(actualPeriod, customer, percentageData,prevPeriod);
        pms.push(pmsItem);
      }else{
        let actualPeriod   = current.actualPeriod ? (new PeriodData(current.actualPeriod[this.getSelectorData("pms")], current.actualPeriod))       : (new PeriodData(0,current.actualPeriod));
        let prevPeriod     = current.previousPeriod  ? (new PeriodData(current.previousPeriod[this.getSelectorData("pms")], current.previousPeriod))   : (new PeriodData(0,current.previousPeriod));
        let percentageData = current.actualPeriod.percentage     ? (new PercentageData(current.actualPeriod.percentage.count, current.actualPeriod.percentage.value)) : (new PercentageData(0,0));
        let pmsItem        = new DataHistoric(actualPeriod, current.customerId, percentageData,prevPeriod);
        pms.push(pmsItem);
      }

    }
    return pms;
  }

  createData(allData,flag, customer, aditionalInfo=0):DataHistoric{
    let key_customer = flag.includes('semantic') || ['rates'].includes(flag) ? 'customer_id' : 'customerId';
    let data         = allData[flag].find(x=>x[key_customer] === customer["id"]);
    let actualPeriod;
    let prevPeriod;
    let percentageData;
    let dataHistoric;

    if(flag==='coverage')

    switch (flag) {
      case "rankingAreas":
        let current    = data.actualPeriod.areas.find(x=>x.id_area === aditionalInfo);
        let prev       = data.previousPeriod.areas.find(x=>x.id_area === aditionalInfo);
        actualPeriod   = current && current.note   ? (new PeriodData(current["note"], current))       : (new PeriodData(0,current));
        prevPeriod     = prev && prev.note   ? (new PeriodData(prev["note"], prev))       : (new PeriodData(0,prev));
        percentageData = new PercentageData(0,0);
        dataHistoric   = new DataHistoric(actualPeriod,data.customerId,data.percentage,prevPeriod);
        break;
      case "pms":
        dataHistoric = data;
        dataHistoric.actualPeriod.value   = dataHistoric.actualPeriod.value || 0;
        dataHistoric.previousPeriod.value = dataHistoric.previousPeriod.value || 0;
        break;
      case "semantic_actual":
        let type_semantic = aditionalInfo === 0 ? 'negative' : 'positive';
        let key_semantic  = type_semantic+"_percentage"
        let prevData   = allData['semantic_previous'].find(x=>x[key_customer] === customer["id"]);
        actualPeriod   = new PeriodData(data[key_semantic], data)
        prevPeriod     = new PeriodData(prevData[key_semantic], prevData)
        percentageData = new PercentageData(0,0);
        dataHistoric   = new DataHistoric(actualPeriod,data["customer_id"],percentageData,prevPeriod);
        break
      case "rates":
        dataHistoric = data;
        break
      default:
        data           = data ? data : {actualPeriod: {}, previousPeriod:{}};
        actualPeriod   = data.actualPeriod   ? (new PeriodData(data.actualPeriod[this.getSelectorData(flag)], data.actualPeriod))       : (new PeriodData(0,data.actualPeriod));
        prevPeriod     = data.previousPeriod ? (new PeriodData(data.previousPeriod[this.getSelectorData(flag)], data.previousPeriod))   : (new PeriodData(0,data.previousPeriod));
        percentageData = data.percentage     ? (new PercentageData(data.percentage.count, data.percentage.value)) : (new PercentageData(0,0));
        dataHistoric   = new DataHistoric(actualPeriod,data.customerId,data.percentage,prevPeriod);
        break;
    }


    return dataHistoric;
  }

  createPeriodData(allData,flag, customer){
    let periodItem = allData.find(x=>x['customer_id'] === customer["id"]) || new PeriodItem(customer["id"]);
    let details    = periodItem.items[flag] || new PeriodItemDetails(flag);
    return details;
  }

  getSelectorData(flag): any {
    switch (flag) {
      case "nps":
        return "nps";
      case "pms":
        return "valid_email_count";
      case "tripAdvisorRanking":
        return "ranking";
    }
  }

  cellClassCompare(actualValue, previousValue, invert=false,key=undefined){
    if( actualValue===previousValue ||
        actualValue===undefined ||
        previousValue===undefined ||
        actualValue === 0 ||
        previousValue === 0) return 'no_change';
    if(key==='semantic_negative') invert=true;
    let response = ((actualValue > previousValue)) ? 'up' : 'down';
    if(invert) response  = response==='up' ? 'down' : 'up';
    return response;
  }
}
