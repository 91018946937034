<mh-box-element [title]="title">
  <div class="box" [class.waiting]="waiting">
    <div *ngIf="waiting">
      <widget-wrapper-wait
        [width]="3"
        [drawTop]="[0, 1, 0]"
        [drawMiddle]="[0, 0, 0]"
        [drawBottom]="[1, 1, 1]"
        [height]="20"
      >
        <ng-container *ngTemplateOutlet="cardNPS"></ng-container>
      </widget-wrapper-wait>
    </div>
    <div *ngIf="!waiting">
      <ng-container *ngTemplateOutlet="cardNPS"></ng-container>
    </div>
  </div>
</mh-box-element>

<ng-template #cardNPS>
  <div
    class="generalSatisfaction"
    [ngbTooltip]="tooltipNps"
    container="body"
    placement="bottom"
    [class.positive]="satisfaction > 0"
  >
    <div class="text">{{ titleWidget | translate }}</div>
    <div class="number">{{ satisfaction | formatNPS }}</div>
    <div class="download" [class.invisible]="!externalLink">
      <a routerLink="." (click)="handleEbookClick()">
        {{ externalLinkText }}
      </a>
    </div>
  </div>
  <div class="row no-gutters details">
    <div class="col-4">
      <widget-card-nps-item [type]="'promoters'" [quantity]="promotersValue"></widget-card-nps-item>
    </div>
    <div class="col-4">
      <widget-card-nps-item [type]="'passives'" [quantity]="passivesValue"></widget-card-nps-item>
    </div>
    <div class="col-4">
      <widget-card-nps-item [type]="'detractors'" [quantity]="detractorsValue"></widget-card-nps-item>
    </div>
  </div>
</ng-template>

<ng-template #tooltipNps>
  <div
    [innerHtml]="
      customerType === 'restaurant'
        ? ('followup.summary.nps.tooltips.nps_restaurant' | translate)
        : ('followup.summary.nps.tooltips.nps'
          | translate : { guests: (guestTranslateType | translate), store: (customerTranslateType | translate) })
    "
  ></div>
</ng-template>
