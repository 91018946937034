import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from "rxjs";
import { tap } from 'rxjs/operators';

import {environment} from "../../../../environments/environment";
import { PSPropertiesForm } from 'app/shared/model/prestay/ps-properties-form.model';

@Injectable({
  providedIn: 'root'
})

export class PSGeneralConfigurationService {

  psConfig = new BehaviorSubject<any>(null);

  constructor(private http:HttpClient) { }

  getEmail(customerId){
    const url=`${environment.prestayPath}customer/${customerId}/mailing`;
    const params:HttpParams= new HttpParams();
    return this.http.get(url,{params: params})
  }

  saveEmail(customerId,formEmail){
    const url=`${environment.prestayPath}customer/${customerId}/mailing`;
    return this.http.put(url,formEmail)
  }

  createEmail(customerId,formEmail){
    const url=`${environment.prestayPath}customer/${customerId}/mailing`;
    return this.http.post(url,formEmail)
  }

  getProperties(customerId){
    const url=`${environment.prestayPath}customer/${customerId}/configuration`;
    const params:HttpParams = new HttpParams();
    return this.http.get<PSPropertiesForm>(url,{params: params})
      .pipe(
        tap(resp => {
          if(resp) {
            this.refreshPSConfig(resp)
          }
        })
      )
  }

  saveProperties(customerId,formProperties){
    const url=`${environment.prestayPath}customer/${customerId}/configuration`;
    return this.http.put<PSPropertiesForm>(url,formProperties)
      .pipe(
        tap(resp => {
          if(resp) {
            this.refreshPSConfig(resp)
          }
        })
      )
  }

  sendTestEmail(customerId,languageId,email){
    const url=`${environment.prestayPath}customer/${customerId}/mailing/test`;
    const params:HttpParams= new HttpParams()
      .set("language_id",languageId)
      .set("email",email);
    return this.http.get(url,{params: params})
  }

  cloneEmail(customerId:number | string, sourceCustomerId:number | string) {
    const url = `${environment.prestayPath}customer/${customerId}/mailing/clone/${sourceCustomerId}`;
    return this.http.put(url, {})
  }

  private refreshPSConfig(data: PSPropertiesForm) {
    this.psConfig.next(data)
  }

  getPSConfigValue() {
    return this.psConfig.getValue()
  }
}
