<section class="tw-flex tw-flex-col tw-justify-center tw-text-center">
    <span class="tw-my-7 tw-font-semibold tw-text-xl">{{ 'support.title' | translate }}</span>
    <div id="hubspotForm" class="tw-w-80 sm:tw-w-[40rem] tw-mx-auto"></div>

    <mh-core-lib-button 
        *ngIf="showResetButton"
        class="tw-mt-10"
        [width]="250"
        [isDefault]="true" 
        [text]="'support.reset_form' | translate" 
        (clicked)="resetForm()"
    >
    </mh-core-lib-button>
</section>