<button mat-menu-item *ngIf="showActionButton"
  type="button" class="btn btn-action {{actionClass}}"
  [disabled]="disabled"
  (click)="openDialog(confirmModal)"
>
  <i class="{{actionIcon}}"></i><br *ngIf="breakTextbtn">
  <span>{{actionText}}</span>
</button>


<ng-template #confirmModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="title">{{title}}</h4>
    <i class="close far fa-times" (click)="closeDialog()"></i>
  </div>

  <div class="modal-body">
    <div class="dialog_options">
      <div class="fade_waiting" [hidden]="!waitDelete && ready">
        <mh-spinner-load></mh-spinner-load>
      </div>



      <div class="ng-content" *ngIf="ready" >
        <ng-content></ng-content>
      </div>

      <div class="row" [hidden]="waitDelete">

        <div class="col" [hidden]="!showButtonNo">
          <button (click)="buttonAction('no')" class="btn btn-secondary">{{textButtonNo === 'no' ? ('commons.no' | translate) : textButtonNo }}</button>
        </div>

        <div class="col" [hidden]="!showButtonYes">
            <button (click)="buttonAction('yes')" class="btn btn-success">{{textButtonYes === 'yes' ? ('commons.yes' | translate) : textButtonYes }}</button>
          </div>
      </div>
    </div>
  </div>
</ng-template>
