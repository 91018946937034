import { UtilService } from "app/shared/util.service";
import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, FormArray } from "@angular/forms";
import { Subscription } from "rxjs";

import { Ota } from "app/shared/model/admin/_index";
import { AdminCustomerService } from "app/admin/customers/admin-customer.service";
import { Competitor } from "../model/competitor.model";
import { animation } from "../utils/animation-custom";
import { OTAS_DATA } from "../utils/statics/otas-urls.static";
import { HotelService } from "../hotel/hotel.service";
import { OtasService } from "app/online/otas/otas.service";

@Component({
  selector: "admin-configuration-otas",
  templateUrl: "./configuration-otas.component.html",
  styleUrls: ["./configuration-otas.component.scss"],
  providers: [AdminCustomerService, HotelService],
  animations: [animation.widthExpand, animation.fade],
})
export class ConfigurationOtasComponent implements OnInit {
  NON_CONFIGURABLE_OTA_IDS = [3]; // ESTAS SON OTAS QUE SE "MERGEARON" en expedia group
  HOTEL_INFO = ["name", "chain_property", "address1", "city", "country"];

  @Input() otasConfigurations: Ota[];
  @Input() competitor: Competitor;
  @Input() hotel = null ;
  controlForm: FormGroup;
  customerSubscribe: Subscription;
  waiting = true;
  sendingData = false;
  allOtas = [];
  currentCustomerId;
  currentClient;
  hotelInfo: any[] = [];
  message: string | null;
  messageClass: string;
  indexOta: number
  errorFormatUrl = '';
  subscription: Subscription;
  isScraperLaunched = false;
  scraperResult = false;

  constructor(
    private utilService: UtilService,
    private adminCustomerService: AdminCustomerService,
    private hotelService: HotelService,
    private fb: FormBuilder,
    private otasService: OtasService,
  ) {
    this.customerSubscribe = this.utilService
      .isCustomerChanged()
      .subscribe((response) => {
        this.init();
      });
  }

  ngOnInit() {
    this.init();

  }

  ngOnDestroy() {
    this.customerSubscribe.unsubscribe();
    if (this.subscription) { 
      this.subscription.unsubscribe();
    }
  }

  init() {
    this.waiting = true;
    this.utilService.setGeneralFade(false);

    this.currentCustomerId = this.utilService.currentHotel.id;

    if (!this.otasConfigurations) {
      this.otasConfigurations = [];
      this.loadOtasConfig();
    } else {
      this.initForm();
    }
    this.loadHotel();
  }

  private async loadHotel() {
    if (this.competitor) {
      this.currentClient = await this.hotelService
        .getHotel(this.competitor.id)
        .toPromise();
      this.processCurrentClient();
    } else {
      const hotelId = (this.hotel !== null) ? this.hotel.id : this.currentCustomerId;
      this.currentClient = await this.hotelService
        .getHotel(hotelId)
        .toPromise();
      this.processCurrentClient();
    }
  }

  private processCurrentClient() {
    if (this.currentClient) {
      this.HOTEL_INFO.forEach((elem) => {
        let value;
        switch (elem) {
          case "city":
            value = this.currentClient.city.name;
            break;
          case "country":
            value = this.currentClient.country.name;
            break;
          case "chain_property": {
            elem = elem + ".chain";
            value = this.currentClient.chain_property
              ? this.currentClient.chain_property.chain.name
              : null;
            break;
          }
          default:
            value = this.currentClient[elem];
            break;
        }
        const hotel = { elem, value };
        this.hotelInfo.push(hotel);
      });
    }
  }

  private loadOtasConfig() {
    const hotelId = (this.hotel !== null) ? this.hotel.id : this.utilService.currentHotel.id;
    this.adminCustomerService
      .getOtas(hotelId, true)
      .subscribe(
        (response: Ota[]) => {
          this.otasConfigurations = response;
          this.initForm();
        },
        (error) => {
          this.utilService.sendAlert();
        }
      );
  }

  initForm() {
    this.addOtasUrlHome()
    this.controlForm = this.fb.group({
      otas: this.fb.array([]),
    });

    const control = <FormArray>this.otasCtr;

    this.otasConfigurations.forEach((ota, idx) => {
      control.push(
        this.fb.group(
          {
            active: [ota.active],
            oauth: [ota.oauth],
            id: [ota.id],
            logo: [ota.logo],
            name: [ota.name],
            param: [ota.param],
            get_reviews: [ota.get_reviews],
            enabled: !this.NON_CONFIGURABLE_OTA_IDS.includes(ota.id),
            index: idx,
            customerType: [
              this.utilService.getCurrentCustomer().property_type.description,
            ],
            url_home: [ota.url_home],
            search_q: [ota.search_q],
            isEdit: [false]
          }
        )
      );
    });

    this.waiting = false;
    this.listenChangesOnForm()
  }

  private addOtasUrlHome() {
    if(this.otasConfigurations) {
      this.otasConfigurations.forEach((elem: any) => {
        const otaUrl = OTAS_DATA.find(o => o.id === elem.id)
        if(otaUrl) {
          elem.url_home = otaUrl.url
          elem.search_q = otaUrl.search_q
        }
      })
    }
  }

  private listenChangesOnForm() {
    this.subscription = this.otasCtr.valueChanges.subscribe(changes => {
      let newValue
      this.otasValue.forEach(o => {
        changes.forEach((value,index) => {
          if(o.id === value.id) {
            if(o !== value) {
              newValue = {index,value}
            }
            return
          }
        })
      })
      if(newValue && newValue.index >= 0) {
        this.otasCtr.at(newValue.index).get('isEdit')?.setValue(true,{emitEvent: false})
      }
    })
  }

  get otasCtr() {
    return <FormArray>this.controlForm.get("otas")
  }

  get otas() {
    return (<FormArray>this.controlForm.get("otas")).controls.filter(
      (o) => o.value.enabled
    );
  }

  get otasValue() {
    return this.controlForm.value.otas
  }

  get currentClientId() {
    if (this.competitor) {
      return this.competitor.id;
    } else {
      return this.utilService.currentHotel.id !== this.currentCustomerId
          ? this.currentCustomerId
          : this.utilService.currentHotel.id;
    }
  }

  getSearchClient(ota: Ota) {
    if(this.currentClient && ota.search_q) {
      const url = `${ota.url_home}/${ota.search_q}`+encodeURIComponent(this.currentClient.name)
      const urlGoogle =  url+encodeURIComponent(`, ${this.currentClient.city.name}, ${this.currentClient.country.name}`)
      return ota.id === 30 ? urlGoogle : url
    }
  }

  onCancel(index) {
    this.resetIsEdit(index)
    this.loadOta(index, this.otasCtr.at(index).value)

  }

  private resetIsEdit(index) {
    this.otasCtr.at(index).get('isEdit')?.setValue(false,{emitEvent: false})
  }

  private async loadOta(index, ota)  {
    const response = await this.adminCustomerService.getOta(this.currentClientId, ota.id).toPromise()
    if(response) {
      const currentOta = {...ota, ...response}
      this.otasCtr.at(index).setValue(currentOta,{emitEvent: false})
    }

  }

  async saveData(index: number) {
    this.indexOta = index
    this.sendingData = true;
    const otas: Ota[] = this.otasValue;
    const ota = otas.find((val, idx) => index === idx);

    if (!ota.param) {
      this.saveAfterValidation(ota, index);
      return;
    }
    try {
      const validation: any = await this.adminCustomerService.validateOta(this.currentClient, ota.id, ota.param).toPromise();
      if (validation.valid) {
        this.saveAfterValidation(ota, index);
      } else if( validation.corrected ) {
        const urlValidated = validation.valid_url;
        const otasControl = this.otas;
        otasControl[index].get('param').setValue(urlValidated, { emitEvent: false });
        ota.param = urlValidated;
        this.saveAfterValidation(ota, index);
      } else {
        this.errorFormatUrl = validation.url_example;
        throw validation;
      };
    } catch (err) {
      const msg = (!!this.errorFormatUrl) ? 'errors.url_ota.error-url' : 'errors.default';
      this.sendingData = false;
      this.handleMessage(msg, "danger");
      this.resetMessage()
    }
  }

  async saveAfterValidation(ota, index) {
    const response = await this.adminCustomerService.saveOta(this.currentClientId, ota).toPromise();
    if (response) {
      this.handleMessage("success.update_data", "success");
    } else {
      this.handleMessage("errors.data.not_exist", "warning");
    }
    this.sendingData = false;
    this.resetIsEdit(index)
    this.resetMessage()
  }

  private resetMessage() {
    setTimeout(() => {
      this.message = null
      this.errorFormatUrl = '';
    },5000)
  }

  private handleMessage(msgText: string, msgClass: string) {
    this.message = msgText;
    this.messageClass = `alert alert-${msgClass}`;
  }

  launchScraper(otaId) {
    this.otasService.launchScraper(this.currentCustomerId, otaId)
      .subscribe((result: boolean) => {
        this.isScraperLaunched = true;
        this.scraperResult = result;
      },
      () => {
        this.isScraperLaunched = true;
        this.scraperResult = false;
      });
  }

}
