import { Pipe, PipeTransform } from '@angular/core';

import { SemanticComment } from 'app/shared/model/semantic-comment.model';
import { SemanticCommentFilter } from 'app/states/semantic/semantic.state';

@Pipe({ name: 'semanticMatchFilter' })
export class SemanticMatchFilterPipe implements PipeTransform {
  transform(value: SemanticComment[], filters: SemanticCommentFilter): SemanticComment[] {
    if (filters.themes?.length > 0 && filters.notations) {
      const notation = filters.notations.positive ? 'positive' : 'negative';
      return value.filter((comment) =>
        comment.themes.some((themeInput) =>
          filters.themes.some(
            (theme) => theme.name.toLowerCase() === themeInput.name.toLowerCase() && themeInput.polarity === notation,
          ),
        ),
      );
    } else return value;
  }
}
