<div *ngFor="let alternative of alternatives">
  <mat-radio-button
    [name]="questionId.toString()"
    [value]="alternative.score"
    [checked]="isSelected(alternative) && !isDisabled()"
    [disabled]="isDisabled()"
    (change)="handleChange(alternative)"
    class="alternative-container"
  >
    <i *ngIf="isSelected(alternative) && isDisabled()" class="icon-fas-circle"></i>
    {{alternative.translates | translateObject:'text':language}}
  </mat-radio-button>
</div>
