import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

export class DataResponse{
  troublesOptions: any[] = [];
  constructor() {}
}

@Injectable()

export class SupportService {

  constructor(private http:HttpClient) { }

  getProblemsList(){
    const url='assets/dummies/support/problem_description.json';
    const params:HttpParams= new HttpParams();
    return this.http.get(url,{params: params})
      .pipe(
        map(response=>response)
      )
  }

  getInconveniencesList(){
    const url="assets/dummies/support/inconveniences_list.json";
    const params:HttpParams = new HttpParams();
    return this.http.get(url,{params: params})
      .pipe(
        map(response=>response)
      )
  }

  getTroublesList(){
    const url="assets/dummies/support/troubles_list.json";
    const params:HttpParams = new HttpParams();
    return this.http.get(url,{params: params})
      .pipe(
        map(response=>response)
      )
  }
}
