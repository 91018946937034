import { Component, OnInit, Input } from '@angular/core';
import { FollowupPropertiesService } from '../../properties/followup-properties.service';
import { FilterDate } from 'app/shared/model/filters/filter-date.model';
import { UtilService } from 'app/shared/util.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { Hotel, Period } from 'app/shared/model';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CustomersState } from 'app/states/customers/customers.state';
import { FollowupOnsiteState } from 'app/states/followup-onsite/followup-onsite.state';

export interface RateResponse {
  details: { actual: NpsResponse; previous: NpsResponse };
  nps: Period;
}

export interface NpsResponse {
  promoters: number;
  passives: number;
  detractors: number;
}

@Component({
  selector: 'mh-chain-followup-nps',
  templateUrl: './chain-followup-nps.component.html',
  styleUrls: ['./chain-followup-nps.component.scss'],
  providers: [FollowupPropertiesService],
})
export class ChainFollowupNpsComponent implements OnInit {
  @Select(CustomersState.relativeCurrentChain) customersReady$: Observable<any[]>;
  @Select(FollowupOnsiteState.surveys) surveys$: Observable<any>;

  @Input()
  productId = 1;

  @Input()
  chain: Hotel[] = [];

  @Input()
  currentDates = null;

  waiting = true;
  data: RateResponse;
  customerType = 'hotel';

  firstCall = true;
  surveySubscribe: Subscription;
  dateSubscribe: Subscription;
  customersStateSubscribe: Subscription;

  constructor(
    private utilService: UtilService,
    private followupPropertiesService: FollowupPropertiesService,
    private store: Store,
  ) {
    this.surveySubscribe = this.surveys$.subscribe((_) => {
      if (this.currentDates) {
        this.init();
      }
    });

    this.customersStateSubscribe = this.customersReady$.subscribe((customers) => {
      if (this.currentDates) {
        this.init();
      }
    });
  }

  ngOnDestroy() {
    this.surveySubscribe.unsubscribe();
    this.customersStateSubscribe.unsubscribe();
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges({ currentDates }) {
    if (currentDates) {
      this.init();
    }
  }

  handleEbookClick() {
    this.utilService.ga('e-book', 'click', 'chain-nps');
  }

  init() {
    this.customerType = this.store
      .selectSnapshot(CustomersState.currentCustomer)
      ?.property_type.description.toLowerCase();
    this.waiting = true;
    const date = new FilterDate(this.currentDates.startDate, this.currentDates.endDate);
    const survey = this.utilService.getCurrentSurvey(this.chain.map((x) => parseInt(x.id)));
    const surveyIds = this.store
      .selectSnapshot((state) => state.followup_onsite?.surveys ?? [])
      .map((survey: any) => `${survey?.id}`);

    const version = survey?.survey[0]?.version || 2;
    const customers = this.chain ?this.chain.map((c) => c.id).join(',') : this.store.selectSnapshot(CustomersState.relativeCurrentChain).map((c) => c.id).join(',');

    if (customers.length && surveyIds.length) {
      this.followupPropertiesService
        .getNPS(date, customers, this.productId, surveyIds, version)
        .subscribe((response: any) => {
          this.data = response.average;
          this.firstCall = false;
          this.waiting = false;
        });
    }
  }
}
