import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mh-log-wrapper',
  templateUrl: './log-wrapper.component.html',
  styleUrls: ['./log-wrapper.component.scss']
})
export class LogWrapperComponent implements OnInit {
  @Input() iconClass:string = 'fa-pencil';

  constructor() { }

  ngOnInit() {
  }

}
