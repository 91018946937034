import { Component, Input } from '@angular/core';
import { UtilService } from 'app/shared/util.service';
import { Product, Money } from "app/shared/model/index";


@Component({
  selector: 'mh-prestay-product-price',
  templateUrl: './product-price.component.html',
  styleUrls: ['../../../../../assets/scss/prestay/product-price.scss'],
  providers:[]
})
export class PrestayProductPriceComponent{


  @Input()
  product:Product;

  @Input()
  properties;

  @Input()
  reservation_unit_price:number=0;

  @Input()
  currency='USD';

  @Input()
  quantity:number=1;


  moneys:Money[] = [];
  money = new Money('USD',1,'');
  isOrdersPage: boolean= false;


  constructor(private utilService:UtilService) {}

  ngOnInit() {
    let hotel = this.utilService.currentHotelDto ? this.utilService.currentHotelDto : this.utilService.currentHotel;
    let currency = hotel.currency.code || 'USD';
    this.moneys = this.utilService.getMoneys(currency);
    if (this.utilService.getFullUrl() === '/prestay/ordenes'){
      this.isOrdersPage = true
    }else{
      this.isOrdersPage = false;
    }
  }

  transformPrice(value){
    this.money  = this.moneys['currencies'].find(x=>x.code===this.currency);
    const price = value*this.money.value*this.quantity;
    return Math.trunc(price)
  }


}
