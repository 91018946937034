import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ICollectSummary, ICustomerConfig, IOtaCollect, IOtaCustomer, IOtasCollect } from 'app/collect/model/index'
import { AuthenticationService } from 'app/_services';

interface IGuestToken { guestToken: string };
@Injectable({
  providedIn: 'root'
})
export class CollectService {

  #guestToken: string;
  #hotel;
  #language:string;

  readonly HOTEL = 'collect_hotel';
  readonly GUEST_TOKEN = 'collect_guest_token';
  readonly GUEST_LANGUAGE = 'currentLanguage';

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
  ) { }

  getOtas(): Promise<IOtaCollect[]> {
    const url = `${environment.collectPath}base`;
    return this.http.get(url)
      .pipe(map((result: IOtasCollect) => result.otas))
      .toPromise();
  }

  getConfig(customerId: string): Promise<ICustomerConfig> {
    const url = `${environment.collectPath}customers/${customerId}/configuration`;
    return this.http.get<ICustomerConfig>(url).toPromise();
  }

  putConfig(payload: ICustomerConfig): Promise<ICustomerConfig> {
    const { customer_id } = payload.customer_config;
    const url = `${environment.collectPath}customers/${customer_id}/configuration`;
    return this.http.put<ICustomerConfig>(url, payload).toPromise();
  }

  getOtasByCustomer(customerId, params={}, fromCollect = false): Promise<IOtaCustomer[]> {
    const url = `${environment.collectPath}customer/${customerId}/otas`;

    let httpParams = new HttpParams();
    let httpOptions = {}

    Object.keys(params).forEach(k => {
      httpParams = httpParams.set(k, params[k]);
    });

    let token;
    if (fromCollect) {
      token = this.getGuestToken();
    } else {
      const jwt = this.authService.getJWT();
      const { token: jwtToken } = jwt;
      token = jwtToken;
    }

    httpOptions = {
      params: httpParams,
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };

    return this.http.get<IOtaCustomer[]>(url,httpOptions).toPromise();
  }

  putConfigOtas(customerId, configId, body) {
    const url = `${environment.collectPath}customers/${customerId}/surveys/public/${configId}`;

    return this.http.put<IOtaCustomer[]>(url, body).toPromise();
  }

  saveRedirection(customerId: string, otaId: number, guestId=null) {
    const url = `${environment.collectPath}customer/${customerId}/ota/${otaId}`;
    let httpOptions = {
      params: new HttpParams()
        .set("guest_id", guestId || null),
      headers: null,
    };

    if (!guestId) {
      httpOptions = {
        ...httpOptions,
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.getGuestToken()}`,
        }),
      }
    }

    return this.http.post<IOtaCustomer[]>(url, {}, httpOptions).toPromise();
  }

  sendEmailTest(customerId: string, userId: number, languageId: string, email: string) {
    const url = `${environment.collectPath}customers/${customerId}/user/${userId}/test`;
    const params = new HttpParams()
      .set('language', languageId)
      .set('email', email);
    return this.http.get(url, { params });
  }

  guestValidation(customerId: string, token: string): Promise<IGuestToken> {
    const url = `${environment.securityPath}collect/guest/validation`;
    const payload = {
      token,
      customer_id: customerId
    };
    return this.http.post<IGuestToken>(url, payload).toPromise();
  }

  getCollectSummary(customerId, startDate, endDate): Promise<ICollectSummary[]> {
    const url = `${environment.collectCustomers}/${customerId}/summary`;

    const params:HttpParams= new HttpParams()
      .set("start_date", startDate)
      .set("end_date", endDate);

    return this.http.get<ICollectSummary[]>(url, { params }).toPromise();
  }

  setGuestToken(token: string): void {
    localStorage.setItem(this.GUEST_TOKEN, JSON.stringify(token));
    this.#guestToken = token;
  }

  getGuestToken(): string {
    return this.#guestToken || JSON.parse(localStorage.getItem(this.GUEST_TOKEN));
  }

  setHotel(hotel) {
    localStorage.setItem(this.HOTEL, JSON.stringify(hotel));
    this.#hotel = hotel;
  }

  getHotel() {
    return this.#hotel || JSON.parse(localStorage.getItem(this.HOTEL));
  }

  setlanguage(language) {
    localStorage.setItem(this.GUEST_LANGUAGE, language);
    this.#language = language;
  }

  getlanguage() {
    return this.#language || localStorage.getItem(this.GUEST_LANGUAGE);
  }

  findHotelIdByNickname(nickname) {
    const url = `${environment.customerPath}nick-id/` + nickname;
    return this.http.get(url).toPromise();
  }
}
