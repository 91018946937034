import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Survey } from 'app/shared/model';
import { animation } from 'app/shared/utils/animation-custom';
import { FollowupOnsiteState } from 'app/states/followup-onsite/followup-onsite.state';
import { DateTime } from 'luxon';

const ACCEPTABLE_WIDTH = 768;
const MIN_HEIGHT = 800;

@Component({
  selector: 'mh-core-lib-guest-box',
  templateUrl: './core-lib-guest-box.component.html',
  styleUrls: ['./core-lib-guest-box.component.scss'],
  animations: [animation.rotate],
})
export class CoreLibGuestBoxComponent implements OnInit {
  deleteOpen = false;
  actionsOpen = false;
  mobileActionsOpen = false;
  cardActionsOpen = false;
  surveyForCreateCase: Survey;
  storedSurveys;

  @Input()
  guest;

  @Input()
  answerDate;

  @Input()
  nps = '';

  @Input()
  survey;

  @Input()
  score;

  @Input()
  currentUser;

  @Input()
  layout: 'card' | 'box' = 'box';

  @Input()
  currentLanguage = '';

  @Input()
  isSuperAdmin = false;

  @Input()
  isAirline = false;

  @Input()
  isRestaurant = false;

  @Input()
  downloadingPDF = false;

  @Input()
  showDetectedCases = false;

  @Output()
  seeSurveyClicked = new EventEmitter();

  @Output()
  exportPDF = new EventEmitter();

  @Output()
  deleteSurvey = new EventEmitter();

  @Output()
  manageSurvey = new EventEmitter();

  @Output()
  createCase = new EventEmitter();

  @ViewChild('trigger')
  actionButton;

  constructor(private router: Router, private store: Store) {}

  ngOnInit(): void {
    this.storedSurveys = this.store.selectSnapshot(FollowupOnsiteState.surveys);
  }

  handleSeeSurveyClick(type = 'show') {
    const data = { survey: this.survey, type };
    this.seeSurveyClicked.emit(data);
  }

  handleExportPDF() {
    this.downloadingPDF = true;
    this.exportPDF.emit();
  }

  handleDeleteSurvey() {
    this.deleteOpen = false;
    this.deleteSurvey.emit(this.survey);
  }

  handleCreateCase(survey) {
    this.surveyForCreateCase = survey;
    this.surveyForCreateCase.guest = {
      ...survey.guest,
      show_checkin: survey.show_checkin,
      show_checkout: survey.show_checkout,
      cases: survey.answered_survey.cases,
      managed: survey.answered_survey.managed,
    };
    const surveyId = survey.answered_survey.survey_id;
    const version = 2;
    const surveyForModal: Survey = Survey.createFromApi({ ...this.surveyForCreateCase, version, surveyId });
    const surveySelected = this.storedSurveys.find((s) => s.id === surveyId);
    surveyForModal.setSurveyName(surveySelected?.translates);
    this.actionsOpen = false;
    this.mobileActionsOpen = false;
    this.createCase.emit(surveyForModal);
  }

  get isManaged() {
    return this.survey?.answered_survey?.managed;
  }

  get canEditSurvey() {
    return this.isBox && ['customer-success', 'full-admin'].includes(this.currentUser?.role);
  }

  get countryFlag() {
    return this.guest?.country?.code2?.toLowerCase() || '';
  }

  get countryName() {
    return this.guest?.country?.name || '';
  }

  get areCountry() {
    return this.countryName !== 'xx';
  }

  get stay() {
    const checkinDate = new Date(this.guest.checkin);
    const checkoutDate = new Date(this.guest.checkout);
    const checkin = {
      day: checkinDate.getDate(),
      month: checkinDate.getMonth() + 1,
      year: checkinDate.getFullYear(),
    };
    const checkout = {
      day: checkoutDate.getDate(),
      month: checkoutDate.getMonth() + 1,
      year: checkoutDate.getFullYear(),
    };
    const luxonCheckin = DateTime.fromObject(checkin);
    const luxonCheckout = DateTime.fromObject(checkout);
    const days = luxonCheckout.diff(luxonCheckin, 'days').toObject().days;
    return Math.ceil(days);
  }

  get isCardLayout() {
    return this.layout === 'card';
  }

  get isBox() {
    return this.layout === 'box';
  }

  get formattedAnswerDate() {
    if (typeof this.answerDate === 'string') {
      const splitted = this.answerDate.split(' ')[0].split('-');
      return new Date(+splitted[0], +splitted[1] - 1, +splitted[2]);
    } else {
      return this.answerDate;
    }
  }

  get isMobile() {
    return ACCEPTABLE_WIDTH > window.innerWidth;
  }

  get isMobileButton() {
    return 1024 >= window.innerWidth;
  }

  get isTight() {
    return window.innerHeight <= MIN_HEIGHT;
  }

  get maintenanceTooltip() {
    const msg = {
      es: 'Funcionalidad en mantenimiento',
      en: 'Maintenance functionality',
      pt: 'Funcionalidade na manutenção',
    };
    return msg[this.currentLanguage];
  }

  get slug() {
    return this.router.url.includes('followup') ? 'followup_guests_pdf' : 'onsite_guests_pdf';
  }

  get casesSlug() {
    return this.router.url.includes('followup') ? 'followup_guests_case_create' : 'onsite_guests_case_create';
  }

  get managedSlug() {
    return this.router.url.includes('followup') ? 'followup_guests_contacted' : 'onsite_guests_contacted';
  }
}
