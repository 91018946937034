export class PrecheckinAnswer{
  id:number;
  guest_id:number = 0;
  response_date: Date = new Date()
  details: PrecheckinAnswerData[] = [];
}

export class PrecheckinAnswerData{
  id:number;
  input_id:number;
  answer:string = '';
  constructor(input_id){
    this.input_id = input_id;
  }
}
