import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { MailingService } from 'app/features/surveys/features/mailing/services/mailing.service';
import { PSGeneralConfigurationService } from 'app/prestay/configuration/general/prestay-general-configuration.service';
import { GoogleAnalyticsService } from 'app/_services';
import { CollectService } from 'app/collect/collect.service';

@Component({
  selector: 'mh-email-test-modal',
  templateUrl: './email-test-modal.component.html',
  styleUrls: ['./email-test-modal.component.scss'],
  providers: [PSGeneralConfigurationService]
})
export class EmailTestModalComponent implements OnInit {

  @Input()
  languages

  @Input()
  currentLanguage

  @Input()
  product;

  @Input()
  surveyId;

  @Input()
  hotelId;

  @Input()
  userId;

  @Input()
  email;

  modal: NgbModalRef;

  controlForm
  testEmailForm;
  sendingEmail = false;
  sentEmail = false;

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private mailSurveyService: MailingService,
    private psMailService: PSGeneralConfigurationService,
    private ga: GoogleAnalyticsService,
    private collectService: CollectService,
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if(this.languages) this.setForm();
  }

  openModal(content) {
    this.modal = this.modalService.open(content, {});
    this.ga.emitEvent(`email-test-${this.productName}`, 'open');
  }

  setForm() {
    const email = this.email || '';
    this.testEmailForm = this.fb.group({
      email:    [email, [Validators.required, Validators.email]],
      language: this.languages.find(x=>x.code===this.currentLanguage)
    });
  }

  setSelectlValueTest($event,key){
    this.testEmailForm.controls[key].setValue($event.original,{emitEvent: true});
  }

  sendTestEmail() {
    this.sendingEmail = true;

    const languageCode = this.testEmailForm.value.language.id;
    const language = this.testEmailForm.value.language.code;
    const email = this.testEmailForm.value.email;
    this.ga.emitEvent(`email-test-${this.productName}`, 'send');

    switch(this.product) {
      case '1':
      case '2':
        this.mailSurveyService.sendMailTest(this.surveyId, languageCode, email).subscribe(response => this.handleCallback(response) );
        break;
      case '4':
        this.psMailService.sendTestEmail(this.hotelId, languageCode, email).subscribe(response => this.handleCallback(response));
        break;
      case '5':
        this.collectService.sendEmailTest(this.hotelId, this.userId, language, email).subscribe(response => this.handleCallback(response));
        break;
    }
  }

  handleCallback(response) {
    if(response) {
      this.sentEmail = true;
      setTimeout(_ => {
        this.modal.close();
        this.sendingEmail = false;
        this.sentEmail = false;
      }, 2000);
    } else {
      this.modal.close();
      this.sentEmail = false;
      this.sendingEmail = false;
    }
  }

  get isSentEmail() {
    return this.sentEmail;
  }

  get productName() {
    const products = { '1': 'followup', '2': 'onsite', '4': 'prestay' };
    return products[this.product]
  }
}
