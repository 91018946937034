import { Component, Input } from '@angular/core';

@Component({
  selector: 'mh-not-image',
  templateUrl: './not-image.component.html',
  styleUrls: ['../../../../../assets/scss/shared/elements/not-image.scss'],
})
export class NotImageComponent{

  @Input()
  size:number=50;
  constructor(){}
}
