import { Component, Input } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'mh-box-spinner',
  templateUrl: './box_spinner.component.html',
  styleUrls: ['../../../../assets/scss/shared/elements/box_spinner.scss'],
})
export class BoxSpinnerComponent{

  @Input() title:string;
  @Input() icon:string;
  @Input() color_class:string;
  @Input() text_line_top:string;
  @Input() text_line_bottom:string;
  @Input() tooltip:string;
  @Input() tooltipTranslate:string|boolean = false;
  @Input() has_modal: boolean = true;
  @Input() waiting: boolean = true;


  constructor(private modalService: NgbModal) { }

  open(content) {
    if(this.has_modal){
      this.modalService.open(content, {
        windowClass: 'modal-xl modal-tables'
      });
    }
  }


}
