import { UtilService } from '../../../../shared/util.service';
import { Hotel } from 'app/shared/model';
import { Component, OnInit, Input } from '@angular/core';
import { Period } from '../../../../shared/model/index';

@Component({
  selector: 'widget-period-simple',
  templateUrl: './period-simple.component.html',
  styleUrls: ['./period-simple.component.scss']
})
export class PeriodSimpleComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  period:Period;

  @Input()
  numberType = "quantity"; // quantity | currency

  @Input()
  type = "normal"; //indicator

  @Input()
  waiting = true;

  @Input()
  hotel: Hotel;

  @Input()
  noData = false;

  @Input()
  tooltip;

  currencyCode = 'CLP';
  currencySymbol = '$';
  constructor(private utilService: UtilService) { }

  ngOnInit() {

  }

  ngOnChanges() {
    this.currencyCode   = this.utilService.currentHotel.currency.code;
    this.currencySymbol = this.utilService.currentHotel.currency.symbol;
    if(this.noData) this.period = new Period();
  }

}
