import { Component, Input, Output, EventEmitter, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { UtilService } from './../util.service';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { AuthenticationService, SessionService } from '../../_services/index';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../model/user.model';
import { IntegrationStatus } from '../model/hotel';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../../configuration/profile/profile.service';
import { CustomerService } from '../customer.service';
import { HtmlHelper } from 'app/_helpers';
import { UserProfile } from 'app/shared/model/index';
import { NavbarService } from 'app/_services/navbar.service';
import { HotelService } from 'app/shared/hotel/hotel.service';
import { PSGeneralConfigurationService } from 'app/prestay/configuration/general/prestay-general-configuration.service';
import * as moment from 'moment';
import { FilterDateService } from '../filters/date/filter-date-service';
import { animation } from 'app/shared/utils/animation-custom';
import { Select, Store } from '@ngxs/store';
import { UsersState } from 'app/states/users/users.state';
import { CreateCaseDialogService } from 'app/shared/core-lib-modal-services/cases/create-case-dialog.service';
import { MenusState } from 'app/states/menus/menus.state';
import { CasesService } from '../cases';
import { DetectedCasesByProduct } from 'app/core-lib/dialogs/create-case-modal/model/detected-case.model';
import { AutoCaseState } from 'app/states/auto-case-config/auto-case-config.state';

@Component({
  selector: 'mh-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [ProfileService, CustomerService, HotelService],
  animations: [animation.widthExpandHeader],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output()
  menuStatus: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  openMenuEvent = new EventEmitter<any>();

  @ViewChild('matSelect', { static: false }) matSelect;

  @Select(MenusState.currentMenuTopbar) menuState$: Observable<any>;
  @Select(AutoCaseState.currentAutoCaseConfig) autoCaseConfig$: Observable<any>;

  nameWeight = '';
  nameLight = '';
  waiting = true;
  currentUser: User;
  hotel: any;
  liveDemo = false;
  waitUpdateCustomer = false;
  availableLanguages: any[] = [];
  currentLanguage;
  currentLanguageId;
  util;
  paramCustomerSetteable = true;
  show_admin_chain = false;
  is_super_admin = false;
  is_admin = false;
  waitingIntegrationStatus = true;
  activityLogCustomer;
  user_role;
  customerParam;
  findClientType = 'client';
  userProfile;
  currentProfile;
  subscriptions = new Subscription();
  chain = null;
  myhotel_logo = '';
  default_user_ip = '0.0.0.0';
  headersButtons = ['menu', 'notifications', 'languages'];
  detectedCases: DetectedCasesByProduct[];

  @Input() isMenuOpen;
  @Input() isMobile;

  constructor(
    private utilService: UtilService,
    private authenticationService: AuthenticationService,
    private router: Router,
    public _htmlHelper: HtmlHelper,
    private translate: TranslateService,
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private sessionService: SessionService,
    private navbarService: NavbarService,
    private generalConfigurationService: PSGeneralConfigurationService,
    private hotelService: HotelService,
    private filterDateService: FilterDateService,
    private store: Store,
    private createCaseDialogService: CreateCaseDialogService,
    private casesService: CasesService,
  ) {
    this.util = this.utilService;
    this.availableLanguages = this.utilService.translateLanguages;
    this.currentLanguage = this.utilService.getCurrentLanguage();
    this.currentLanguageId = this.utilService.getCurrentLanguageId();
    this.translate.setDefaultLang(this.utilService.getCurrentLanguage());
    this.subscriptions.add(this.utilService.isDemoSetted().subscribe((state) => this.setDemo(state)));
    this.subscriptions.add(
      this.utilService.isCustomerChanged().subscribe((response) => {
        this.setNameHotel(response);
        this.updateUserProfile();
      }),
    );
    this.subscriptions.add(
      this.utilService.isHeaderRefeshed().subscribe((response: any) => this.setNameHotel(response)),
    );
    this.subscriptions.add(this.utilService.isLanguageChanged().subscribe((lang) => (this.currentLanguage = lang)));
    this.liveDemo = this.utilService.isLiveDemo();
    this.user_role = this.utilService.currentUser.role;
    this.updateUserProfile();
  }

  updateUserProfile(): void {
    this.currentProfile = this.utilService.getCurrentUser();
    this.userProfile = new UserProfile(
      this.currentProfile.id,
      this.currentProfile.admin,
      this.currentProfile.customers,
      this.currentProfile.superAdmin,
      this.currentProfile.userName,
      this.currentProfile.profile,
      this.currentProfile.send_mail_new_user,
      this.currentProfile.profile.profile_photo,
    );
    this.userProfile.profile.company_position = this.currentProfile.companyPosition;
    this.userProfile.profile.language_code = this.currentProfile.languageCode || 1;
  }

  setNameHotel(response = undefined) {
    this.hotel = response ? response : this.utilService.getCurrentHotel();
    this.getChainName();
    if (this.isLiveDemo())
      this.hotel =
        (this.utilService.demoInterChainHotels &&
          this.utilService.demoInterChainHotels.find((x) => x.id === this.hotel.id)) ||
        this.hotel;
    if (response?.id !== this.utilService.getCurrentHotel()?.id) this.checkCustomerParam();
    this.logActivity();
  }

  async logActivity(isTokenRefreshed = false) {
    if (this.utilService.currentUser && !this.utilService.currentUser.isSuperAdmin()) {
      if (
        this.activityLogCustomer === undefined ||
        this.activityLogCustomer.id !== this.utilService.currentHotel.id ||
        isTokenRefreshed
      ) {
        const userIP = (await this.getIp()) ?? this.default_user_ip;
        this.activityLogCustomer = this.utilService.currentHotel;
        this.profileService
          .logUser(this.utilService.currentUser.id, this.utilService.currentHotel.id, userIP)
          .subscribe(() => {});
      }
    }
  }

  checkCustomerParam() {
    const currentCustomers = <any[]>this.utilService.getInnerChain(false);
    const ids = currentCustomers.map((x) => x.id);
    this.route.queryParams.subscribe(async (params) => {
      this.customerParam = params['customer'];
      if (params['customer'] && params['guest'] && params['date']) {
        const date = params['date'];
        const fromDate = moment(date).format('YYYY-MM-DD');
        const toDate = moment(date).format('YYYY-MM-DD');

        this.filterDateService.setCustomDate({ fromDate, toDate });
      }

      if (
        this.customerParam &&
        (ids.includes(parseInt(this.customerParam)) || this.currentUser.isSuperAdmin()) &&
        parseInt(this.hotel.id) !== parseInt(this.customerParam) &&
        this.paramCustomerSetteable
      ) {
        this.paramCustomerSetteable = false;
        let hotel;
        if (this.currentUser.isSuperAdmin()) {
          const response = await this.customerService.findHotel(this.customerParam).toPromise();
          hotel = response;
        } else {
          hotel = currentCustomers.find((x) => x.id === parseInt(this.customerParam));
        }
        this.hotel = hotel;
        this.updateCustomer(hotel, true);
      }
    });
  }

  isReady(event) {
    this.waiting = false;
    this.utilService.loadCustomerReady();
    if (this.utilService.currentUser.isSuperAdmin() && this.utilService.currentUser.fakeUser === undefined) {
      if (event) this.updateCustomer(this.utilService.currentHotel);
    }
  }

  toggleMenu() {
    if (!this.isMenuOpen) {
      this.menuStatus.emit(true);
    }
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  switchLanguage(language: string) {
    this.utilService.setCurrrentLanguage(language);
    this.utilService.ga('language', 'click', language);
  }

  isLiveDemo() {
    return this.liveDemo;
  }

  setDemo(state) {
    this.liveDemo = state;
  }

  openLanguageMenu() {
    this.matSelect.open();
  }

  async getIp() {
    try {
      const response = await this.profileService.getClientIp().toPromise();
      if (response) {
        return response['ip'];
      }
    } catch (error) {
      console.error(error);
    }
  }

  ngOnInit() {
    this.currentUser = this.utilService.getCurrentUser();
    this.show_admin_chain =
      (this.currentUser.superAdmin && !this.utilService.isLiveDemo()) || this.currentUser.hasInterChain();
    this.is_super_admin = this.currentUser.superAdmin;
    this.is_admin = this.currentUser.admin;
    if (this.is_super_admin || this.is_admin) {
      this.checkIntegrationStatus(this.utilService.currentHotel, false);
    }
    this.setNameHotel();
    this.setLogo();

    this.subscriptions.add(
      this.authenticationService.tokenRefreshed().subscribe((value) => {
        if (value) {
          this.logActivity(true);
        }
      }),
    );

    this.subscriptions.add(
      this.menuState$.subscribe((menu) => {
        this.headersButtons = ['menu', 'notifications', 'languages'];
        const createCase = menu.find((item) => item.slug === 'desk_external_case');
        if (createCase) {
          this.headersButtons.push('cases');
        }
        const support = menu.find((item) => item.slug === 'support');
        if (support) {
          this.headersButtons.splice(1, 0, 'support');
        }
      })
    );

    this.getDetectedCasesByProduct();

    this.subscriptions.add(
      this.utilService.isCustomerChanged().subscribe(() => this.getDetectedCasesByProduct())
    );

    this.subscriptions.add(
      this.autoCaseConfig$.subscribe((result) => {
        const someActive = result.some((item) => item.smart_cases && item.generation_type_id === 3);
        if (!someActive) {
          this.detectedCases = undefined;
        } else {
          this.getDetectedCasesByProduct();
        }
      }),
    );

    this.subscriptions.add(
      this.createCaseDialogService.isCaseCreated().subscribe((value) => {
        if (value) {
          this.getDetectedCasesByProduct();
        }
      })
    )
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async updateCustomer(hotel, emit = true) {
    this.generalConfigurationService.getProperties(hotel.id).subscribe();
    this.utilService.changingCurrentSurvey = true;
    this.waitUpdateCustomer = true;
    let hotel_id =
      this.isLiveDemo() && hotel.id === 12
        ? this.utilService.currentUser.superAdminProperties.id_fake_customer
        : hotel.id;
    hotel_id = this.customerParam ? parseInt(this.customerParam) : hotel_id;

    if (this.currentUser.isSuperAdmin()) {
      forkJoin([
        this.profileService.usersInCustomer(hotel_id),
        this.authenticationService.fidelityConfiguration(hotel_id),
      ]).subscribe((result: any) => {
        const response = result[0];
        this.sessionService.setFidelityProperties(result[1]);

        let users = response.map((x) => new User().createFromApi(x));
        users.sort(function (a, b) {
          return a.customers.length < b.customers.length ? 1 : b.customers.length < a.customers.length ? -1 : 0;
        });
        users = users.filter((x) => !x.superAdmin);
        const user = users.length === 0 ? new User() : users[0];

        this.utilService.setCurrenFakeUser(user);
        const customersList = this.isLiveDemo()
          ? this.utilService.demoInterChainHotels
          : this.utilService.currentUser.fakeUser.customers;
        let customer = customersList.find((x) => x.id === hotel.id);
        if (customer === undefined) {
          let i = 1;
          while (i < users.length) {
            let try_user = users[i];
            customer = try_user.customers.find((x) => x.id === hotel.id);
            if (customer) {
              this.utilService.setCurrenFakeUser(try_user);
              break;
            }
            i++;
          }
        }
        this.utilService.refreshNavbar();
        customer = customer || hotel;
        const customerToCheck = { ...customer, country_code: hotel.country_code };
        this.updateCustomerReady(customerToCheck, emit);
        this.checkIntegrationStatus(customerToCheck, emit);
      });
    } else {
      this.authenticationService.fidelityConfiguration(hotel_id).subscribe((result) => {
        this.profileService.usersInCustomer(hotel_id).subscribe((users: any) => {
          // Esta lógica hay que actualizarla después de conversar con gabriel sobre la solución
          users = users.filter((x) => !x.superAdmin);
          const user = users[0];
          const customersList = user.customers;
          let customer = customersList.find((x) => x.id === hotel.id);
          if (customer === undefined) {
            let i = 1;
            while (i < users.length) {
              let try_user = users[i];
              customer = try_user.customers.find((x) => x.id === hotel.id);
              if (customer) {
                break;
              }
              i++;
            }
          }
          customer.country_code = hotel.country_code;
          this.sessionService.setFidelityProperties(result);
          this.updateCustomerReady(customer, emit);
          this.checkIntegrationStatus(customer, emit);
        });
      });
    }
  }

  updateCustomerReady(hotel, emit = true) {
    this.utilService.customerChanged(hotel, emit);
    this.waitUpdateCustomer = false;
  }

  ga(type) {
    this.utilService.ga('header', 'click', type);
  }

  toggleNavbar() {
    this.menuStatus.emit(!this.isMenuOpen);
  }

  checkIntegrationStatus(customer, emit) {
    this.waitingIntegrationStatus = true;
    if (this.utilService.currentUser.isSuperAdmin() || this.utilService.currentUser.isAdmin()) {
      this.customerService.checkIntegrationStatus(customer.id).subscribe((response: any) => {
        const integration = response
          ? new IntegrationStatus(response.flag_ok, response.last_update)
          : new IntegrationStatus(true, new Date());
        customer = {
          ...customer,
          integration_status_dto: integration.toPlainObj(),
        };
        this.sessionService.setCurrentCustomer(customer);
        this.waitingIntegrationStatus = false;
      });
    }
  }

  getChainName() {
    this.hotelService.getHotel(this.hotel.id).subscribe((hotel: any) => {
      this.chain = hotel && hotel.chain_property ? hotel.chain_property.chain.name : null;
    });
  }

  setLogo(error = false) {
    const urlBase = 'https://mh-statics.s3.sa-east-1.amazonaws.com/documents/Fidelity-logos/dashboard-logo';
    this.myhotel_logo = `${urlBase}.png`;
    if (error) {
      this.myhotel_logo = `${urlBase}.gif`;
    }
  }

  isActive(language: any) {
    return language === this.currentLanguage;
  }

  public openNewModal() {
    this.createCaseDialogService.openModal(this.utilService.currentUser);
  }

  openMenuHandler($event) {
    this.openMenuEvent.emit($event);
  }

  getDetectedCasesByProduct() {
    this.casesService.getDetectedCaseByProduct(this.hotel.id).subscribe((result) => {
      const autoCaseConfig = this.store.selectSnapshot(AutoCaseState.currentAutoCaseConfig);
      const activeConfig = autoCaseConfig.filter((item) => item.generation_type_id === 3 && item.smart_cases).map((item) => item.product_id);
      this.detectedCases = result.filter((item) => activeConfig.find((productId) => productId === item.product_id));
    });
  }

  get navState() {
    return !this.isMenuOpen ? 'void' : '*';
  }

  get isHotelDemoMY() {
    return parseInt(this.hotel?.id || 0) === 12;
  }

  get htmlHelper() {
    return this._htmlHelper;
  }
  get isRetail() {
    return this.utilService.customerIsRetail;
  }
  get isCorporative() {
    return this.navbarService.getStatus() === 3;
  }
  get users() {
    return this.store.selectSnapshot(UsersState.currentUsers);
  }
}
