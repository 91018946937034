import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { animation } from 'app/shared/utils/animation-custom';

@Component({
  selector: 'mh-core-lib-pagination-selector',
  templateUrl: './pagination-selector.component.html',
  styleUrls: ['./pagination-selector.component.scss'],
  animations: [ animation.rotate ]
})
export class CoreLibPaginationSelectorComponent implements OnInit {
  Math = Math;
  readonly PAGINATION_VALUES = [10, 25, 50, 100, 200, 0];

  paginationSelectorOpen = false;
  @Input() pagination = this.PAGINATION_VALUES[0];
  #currentPage = 0

  @Output() paginationSize = new EventEmitter();
  @Output() pageChanged = new EventEmitter();

  @Input()
  totalElements: number;

  @Input() set currentPage(value) {
    this.#currentPage = +value;
  };

  get currentPage() {
    return this.#currentPage;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.paginationSelectorOpen = false;
    }
  }
  constructor(private eRef: ElementRef) { }

  ngOnInit(): void {
  }

  setPagination(paginationValue) {
    this.pagination = paginationValue;
    this.paginationSize.emit(paginationValue);
    this.currentPage = 0;
    this.paginationSelectorOpen = !this.paginationSelectorOpen;
  }

  handlePageChanged(currentPage, direction) {
    switch(direction) {
      case 'first':
        this.currentPage = currentPage;
        break;
      case 'last': 
        this.currentPage = (Math.ceil(this.totalElements / this.pagination)) - 1 
        break;
      case 'forward':
        if (currentPage < Math.ceil(this.totalElements / this.pagination) - 1) {
          this.currentPage = currentPage + 1
        }
        break;
      case 'back':
        if ( currentPage > 0 )
          this.currentPage = currentPage - 1;
        break;
    }
    this.pageChanged.emit(this.currentPage);
  }

}
