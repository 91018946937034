import { Component, Input } from '@angular/core';
import { UserProfile,CustomerProfile } from '../../../../shared/model/index';
import {  ProductsService } from '../../../../_services/index'
import { Action } from '../action.model';

@Component({
  selector: 'mh-user-products-show',
  templateUrl: './user-product-show.component.html',
  styleUrls: ['../../../../../assets/scss/configuration/product-show.scss'],
  providers:[]
})
export class UserProductShowComponent{

  products: any[] = [];
  actions:Action[] = [];
  customerProducts=["followup","online", "onsite"];
  activesProducts: any[] = [];

  @Input()
  customer:CustomerProfile;

  @Input()
  user:UserProfile;

  constructor(private productsService:ProductsService){
    this.products = this.productsService.dataNavbar;
    //new Action("access", "far fa-unlock-alt"),
    this.actions = [
      new Action("alerts", "far fa-alarm-clock")
    ];
  }

  ngOnChanges() {
    this.listActivePorducts();
  }

  getConfigProduct(flag){
    return this.products.find(x=>x.flag === flag);
  }

  listActivePorducts(){
    let products: any[] = [];
    for(let product of ["onsite","followup","online"]){
      if(this.customer[product]) products.push(product);
    }
    this.activesProducts = products;
  }

}

