import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { filter, timeout, retryWhen, concatMap, delay, catchError, take, switchMap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';
import { PrestayLoginService } from 'app/prestay/public/login/prestay-public-login.service';

@Injectable()
export class CustomHttpInterceptorService implements HttpInterceptor {
  private NON_REFRESH_URL = [`${environment.securityPath}refresh`];

  private NON_ADD_TOKEN_URL = [
    'https://mybusinessaccountmanagement.googleapis.com/v1/accounts',
    `https://graph.facebook.com/${environment.facebookApiVersion}/oauth`,

    'https://graph.facebook.com/',
  ];

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private timeout: number = environment.production ? 60000 : 120000;
  private maxRetry = 3;

  constructor(
    private authService: AuthenticationService,
    private prestayLoginService: PrestayLoginService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.setHeaders(request, this.authService.getJWT());

    if (request.url.includes('reports') && !request.params.get('noEmail')) {
      return this.handleReportsCalls(request, next);
    }
    if (request.headers.get('skip')) {
      request = request.clone({ headers: request.headers.delete('skip') });
      return next.handle(request).pipe(
        catchError((error) => {
          return throwError(error.error);
        }),
      );
    }

    return next.handle(request).pipe(
      timeout(this.timeout),
      retryWhen((errors) =>

        errors.pipe(
          concatMap((error, count) => {
            const errCode = [400, 404, 419, 429, 500, 501, 503, 504];
            if (count < this.maxRetry && (errCode.includes(error.status) || error.name === 'TimeoutError')) {
              return of(error.status);
            } else {
              return throwError(error);
            }
          }),
          delay(0),
        ),
      ),
      catchError((error) => {
        console.error(error);
        if (error.status === 401 && error?.error?.code === 6002) {
          return throwError(error.error);
        } else if (error.status === 401 && !this.NON_REFRESH_URL.includes(request.url)) {
          return this.renovateToken(request, next);
        } else {
          return throwError(error.error);
        }
      }),
    );
  }

  private renovateToken(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const token = this.authService.getJWT();
    if (!token) {
      this.logout();
      throwError('No Token');
      return next.handle(request);
    }
    if (!this.isRefreshing && (token?.refreshToken || token?.token)) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.router.url.includes('/prestay/precheckin') || this.router.url.includes('/prestay/services')
        ? this.renovateGuestToken(request, next)
        : this.renovateTokenFn(request, next, token);
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.setHeaders(request, jwt));
        }),
      );
    }
  }

  renovateTokenFn = (request, next, token) => {
    return this.authService.refreshToken(token.refreshToken).pipe(
      switchMap((token: any) => {
        this.isRefreshing = false;
        this.refreshTokenSubject.next(token);
        return next.handle(this.setHeaders(request, token)).pipe(
          catchError((error) => {
            this.logout();
            return of(error);
          }),
        );
      }),
      catchError((error) => {
        this.logout();
        return throwError(error);
      }),
    );
  }

  renovateGuestToken = (request, next) => {
    const token = localStorage.getItem('currentGuestLoginToken');
    const payload = {
      customer_id: 1068,
      reservation_code: null,
      token,
    };
    return this.prestayLoginService.validateToken(payload).pipe(
      switchMap((token: any) => {
        this.isRefreshing = false;
        localStorage.setItem('currentGuestToken', token.guestToken);
        this.refreshTokenSubject.next(token.guestToken);
        return next.handle(this.setHeaders(request, token, true)).pipe(
          catchError((error) => {
            this.logout();
            return of(error);
          }),
        );
      }),
      catchError((error) => {
        this.logout();
        return throwError(error);
      }));
  }

  logout() {
    this.isRefreshing = false;
    this.router.navigate(['logout']);
  }

  setHeaders(request: HttpRequest<any>, token: any, fromGuestPrestay = false) {
    const contentType = this.getContentType(request);
    const setHeaders = this.NON_ADD_TOKEN_URL.some((url) => {
      return request.url.includes(url);
    })
      ? {
          Accept: 'application/json',
        }
      : {
          'x-api-key': `${environment.myhotel_api_key}`,
          Accept: 'application/json',
        };

    if (request.url.includes('private')) {
      // This is a custom header for the file download
      setHeaders.Accept = '*/*';
    }

    if (contentType) {
      Object.assign(setHeaders, { 'Content-Type': contentType });
    }

    if (
      token &&
      !this.NON_ADD_TOKEN_URL.some((url) => {
        return request.url.includes(url);
      }) &&
      !this.isCollectPortalURl(request.url)
    ) {
      setHeaders['Authorization'] = `Bearer ${fromGuestPrestay ? token.guestToken : token.token}`;
    }
    return request.clone({ setHeaders });
  }

  getContentType(request) {
    const urlsHtmlType = [/\/emails\/setup\/preview/];

    const urlsOctetType = [
      /\/customers\/\d*\/files\/private/,
      /\/customers\/\d*\/files\/upload\/private/,
      /\/commons\/\d*\/files\/upload/,
      /\/customers\/\d*\/surveys\/\d*\/files\/upload/,
      /\/api\/pdf/,
    ];

    const urlAudioFileUrlRegex = /\/customers\/voices\/origins\/tokens\/[\da-z\.-]+\/upload/;

    if (request.url.match(urlAudioFileUrlRegex)) return null;
    if (urlsHtmlType.some((regex) => request.url.match(regex))) return 'text/html';
    if (urlsOctetType.some((regex) => request.url.match(regex))) return null;
    if (
      this.NON_ADD_TOKEN_URL.some((url) => {
        return request.url.includes(url);
      })
    ) {
      return '';
    }
    return 'application/json';
  }

  isCollectPortalURl(url: string): boolean {
    const regexArray = ['/.*/collect/customer/[0-9]+/ota/[0-9]+', '/.*/collect/customer/[0-9]+/otas'];
    return regexArray.some((regex) => url.match(regex));
  }

  handleReportsCalls(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 504) {
          return throwError(err);
        }
      }),
    );
  }
}
