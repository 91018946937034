import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class CasesHelperService {

  constructor(
    private http: HttpClient
  ) { }

  getOnlineReview(reviewId) {
    const url = `${environment.onlinePath}reviews/${reviewId}`
    const params = {language_id: '1'}

    return this.http.get(url, { params });
  }

  getOnsiteAnswer(answerId) {
    const url = `${environment.followupPath}surveys/answereds/${answerId}/guest-info`;
    const params = { product_id: '2' };

    return this.http.get(url, { params });
  }

  getFollowupAnswer(answerId) {
    const url = `${environment.followupPath}surveys/answereds/${answerId}/guest-info`;
    const params = { product_id: '1' };

    return this.http.get(url, { params });
  }

  getPrestayPrecheckin(customerId, checkinId) {
    const url = `${environment.prestayPath}customer/${customerId}/view/checkins/${checkinId}`;
    const params = {language_id: '1'} //TODO: REPLACE

    return this.http.get(url, { params });
  }

  getPrestayOrder(customerId, orderId) {
    const url = `${environment.prestayPath}customer/${customerId}/view/orders/${orderId}`;
    const params = {language_id: '1'} //TODO: REPLACE

    return this.http.get(url, { params });
  }
}
