<button *ngIf="includeDates" #trigger="matMenuTrigger"
  [matMenuTriggerFor]="datesMenu"
  class="btn btn-filter btn-date"
  (click)="onMenuOpen('dates')"
  >
  {{getDatesFilterTitle()}}
  <div class="small">{{selectedDatesTranslatePath | translate}}</div>
</button>
<button *ngIf="filters?.length"
  [matMenuTriggerFor]="optionsMenu"
  (click)="onMenuOpen('checkbox')"
  class="btn btn-filter"
>
  <i class="fas fa-filter"></i><span>{{'filters.title' | translate}}</span>
</button>
<button *ngIf="hasFilterActive"
  (click)="cleanFilters()"
  class="btn btn-cleaner"
>
  {{'semantic.shared.filter.clean_filters' | translate}}
</button>

<mat-menu [class]="'date-menu'" #datesMenu>
  <ng-container *ngTemplateOutlet="datesFilter"></ng-container>
</mat-menu>

<mat-menu [class]="'filters-menu'" #optionsMenu>
  <div class="filter-containers" (click)="$event.stopPropagation()">
    <div class="col-filters" *ngFor="let filter of filters">
      <ng-container *ngIf="filter.name === 'areas'">
        <ng-container *ngTemplateOutlet="areaOptions; context: {filter: filter}"></ng-container>
      </ng-container>
      <ng-container *ngIf="filter.name !== 'areas'">
        <ng-container *ngTemplateOutlet="optionsFilter; context: {filter: filter}"></ng-container>
      </ng-container>
    </div>
  </div>
</mat-menu>

<!-- TODO: Add param at [locale] customRangeLabel: ('filters.dates.custom' | translate) -->
<ng-template
  let-filter="filter"
  let-value="value"
  #datesFilter>
  <ngx-daterangepicker-material
    [startDate]="selectedDates.startDate"
    [endDate]="selectedDates.endDate"
    [ranges]="ranges"
    [showCustomRangeLabel]="true"
    [showRangeLabelOnInput]="true"
    [keepCalendarOpeningWithRange]="true"
    [alwaysShowCalendars]="true"
    [locale]="{applyLabel: ('filters.actions.options.accept' | translate)}"
    [showDropdowns]="true"
    (rangeClicked)="handleRangeClick($event)"
    (datesUpdated)="handleCustomDates($event)"
  ></ngx-daterangepicker-material>
</ng-template>

<ng-template #optionsFilter
  let-filter="filter"
>
  <h3>{{filter.titleTranslate | translate}}</h3>
  <mat-selection-list #filterList
    (selectionChange)="handleUpdate(filter.name, $event)"
  >
    <mat-list-option *ngFor="let option of filter.options"
      [value]="optionValue(option)"
      [selected]="filter | checkSelectedGenericFilter: option: values"
    >
      <span *ngIf="!option.translates; else translatesSpan">
       {{(filter.optionTranslatePrefix || '') + optionText(option) + (filter.optionTranslateSuffix || '') | translate}}
      </span>
      <ng-template #translatesSpan>
        <span mhFindTranslate
          [language]="language"
          [translates]="option.translates"
          [keyTranlsate]="'name'"
          [capitalize]="true"
        ></span>
      </ng-template>
    </mat-list-option>
  </mat-selection-list>
</ng-template>

<ng-template
  #areaOptions
  let-filter="filter"
>
  <h3>{{filter.titleTranslate | translate}}</h3>
  <mat-form-field class="example-chip-list">
  <mat-label>{{'areas.title' | translate}}</mat-label>
  <mat-chip-list #chipList aria-label="Fruit selection">
    <mat-chip
      *ngFor="let option of options"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="remove(option)">
      <ng-container *ngIf="language === 'es'">
        {{option.clasification_text}}
      </ng-container>
      <ng-container *ngIf="language === 'en'">
        {{option.clasification_text_en}}
      </ng-container>
      <ng-container *ngIf="language === 'pt'">
        {{option.clasification_text_pt}}
      </ng-container>
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input
      #optionInput
      [formControl]="optionControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      <ng-container *ngIf="language === 'es'">
        {{option.clasification_text}}
      </ng-container>
      <ng-container *ngIf="language === 'en'">
        {{option.clasification_text_en}}
      </ng-container>
      <ng-container *ngIf="language === 'pt'">
        {{option.clasification_text_pt}}
      </ng-container>
    </mat-option>
  </mat-autocomplete>
  </mat-form-field>
</ng-template>
